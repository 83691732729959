import { Box, Button, ButtonGroup, IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import robotImage from "../../../assets/images/AIJudge.png";
import check from "../../../assets/images/checkmark.svg";
import view from "../../../assets/images/Icon-feather-eye.svg";
import micBlack from "../../../assets/images/micBlack.svg";
import Retweet from "../../../assets/images/retweet.svg";
import Thumbs from "../../../assets/images/thumbs.svg";
import ThumbsActive from "../../../assets/images/thumbsActive.svg";
// import useChat from "../../Common/Chat/useChat";
import logoImage from "../../../assets/images/VurbilBlue.png";
// import logoImage from "../../../assets/images/logo.png";
import Chat from "../../Common/Chat/Chat";
import {
  API_Path,
  getDecryptedHashWithSecret,
  numberFormatter,
} from "../../Common/Const";
import CustomDebateHeader from "../../Common/CustomDebateHeader/index";
import CustomImage from "../../Common/CustomImage";
import Loader from "../../Common/Loader";
import OpenTokVideo from "../../Common/openTok/OpenTokVideo";
import ReportAbuse from "../../Common/ReportAbuse";
import ShowMoreLessText from "../../Common/ShowMoreLessText";
import useDebateSocket from "../../Common/Sockets/useDebateSocket";
import AudienceAdInjection from "./AudienceAdInjection";
import LikeUserCompoent from "../../Common/CommonPostCard/LikeDisLikeUsers";
import SnackBar from "../../../components/SnackBar";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  leftSection: {
    width: "100%",
    padding: "24px",
    [theme.breakpoints.up("md")]: {
      padding: "36px",
    },
  },
  rightSection: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 400px",
      maxWidth: "400px",
    },

    "& .messages": {
      display: "flex",
      flexFlow: "column",
      height: "auto",
      overflow: "auto",
      marginTop: "auto",
    },
    "& h4": {
      margin: "0 0 40px 0",
      paddingBottom: "16px",
      borderBottom: "solid 1px #aeb7c6",
    },
  },
  stickySection: {
    backgroundColor: "#f6f7f8",
    padding: "36px 36px 0",
    zIndex: 10,
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: "0",
      height: "calc(100vh - 133px)",
      display: "flex",
      flexFlow: "column",
      overflow: "auto",
    },
    "& .chatContainer": {
      width: "100%",
    },
  },
  flexRow: {
    display: "flex",
    flexFlow: "row",
  },

  badge: {
    fontSize: "12px",
    lineHeight: "1.5",
    color: "#fff",
    padding: "3px 14px 4px 11px",
    borderRadius: "6px",
    marginLeft: "16px",
  },
  paper: {
    boxShadow: "none",
    border: "solid 1px #cdd1d8",
    borderRadius: "12px",
    padding: "20px 24px",
    marginTop: "12px",
    display: "flex",
    flexFlow: "column",
    height: "100%",
  },
  divider: {
    borderBottom: "solid 1px #cdd1d8",
    margin: "26px 0 18px 0",
  },

  lrrSection: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginTop: "18px",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
    },
  },
  likeRetweetRobot: {
    display: "flex",
    flexFlow: "column",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "row-reverse",
      alignItems: "center",
      marginBottom: "16px",
      width: "100%",
      justifyContent: "space-between",
      "& > div:last-child": {
        marginTop: "0!important",
      },
    },
  },
  speakersContainer: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: "12px",
    left: "20px",
    right: "20px",
    [theme.breakpoints.down("md")]: {
      flexFlow: "column",
      alignItems: "center",
    },
    // [theme.breakpoints.down('sm')]: {
    //     flexFlow: 'column',
    //     alignItems: 'center',
    //     position: 'relative',
    //     marginTop: '-82px',
    // },
    "& h4": {
      lineHeight: "1",
      color: "#fff",
      fontWeight: "normal",
    },
    "& h5": {
      lineHeight: "1",
      color: "#ffc003",
    },
  },
  speakerDetails: {
    display: "flex",
    alignItems: "center",
    borderRadius: "30px",
    padding: "10px 15px",
    backgroundColor: "rgba(0, 0, 0, 0.42)",
    zIndex: 999,
    [theme.breakpoints.up("lg")]: {
      width: "calc(50% - 65px)",
    },
    "& .speackersMic, & .listenerMic": {
      width: "30px",
      maxWidth: "30px",
      flex: "0 0 30px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      background: "#fff",
    },
    "& .speackersMic": {
      background: "#f6c100",
      marginRight: "24px",
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        border: "1px solid #f6c100",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
      },
      "&:after": {
        width: "50px",
        height: "50px",
        border: "1px solid rgba(255, 192, 3, .45)",
      },
    },
    "& .listenerMic": {
      marginLeft: "auto",
    },
    "& .remainingSec": {
      fontSize: "10px",
      color: "#fff",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      marginLeft: "30px",
      width: "40px",
      maxWidth: "40px",
      flex: "0 0 40px",
      height: "40px",
      borderRadius: "50%",
      justifyContent: "center",
      border: "1px solid #fff",
      lineHeight: "1",
      fontWeight: "600",
      "& span": {
        fontSize: "9px",
        fontWeight: "400",
      },
    },
  },
  listenerDetails: {
    padding: "8px 15px 8px 25px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
    },
    // [theme.breakpoints.down('sm')]: {
    //     margin: '22px auto 0',
    // },
    "& h6": {
      color: "#fff",
      fontWeight: "400",
      lineHeight: "1.25",
      marginRight: "16px",
      "& span": {
        fontWeight: "600",
      },
    },
  },
  flagView: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontSize: "12px",
    position: "absolute",
    top: "12px",
    left: "12px",
    right: "12px",
    // zIndex: '9',
    "& .flag, & .finish, & .view": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .flag": {
      width: 26,
      height: 26,
      backgroundColor: "rgb(49, 50, 54, .55)",
      borderRadius: 6,
    },
    "& .finish": {
      backgroundColor: "rgba(255, 255, 255, .3)",
      borderRadius: "13px",
      padding: "4px 12px 4px 6px",
      cursor: "pointer",
      "&[disabled]": {
        pointerEvents: "none",
        cursor: "not-allowed",
      },
    },
    "& .finish, & .view": {
      "& img": {
        marginRight: "6px",
      },
    },
  },
  likesDislikes: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
    "& .active": {
      background: "#ffc003",
    },
    "&.activeAnimate": {
      "& .likeBtn": {
        animation: `$voteEffect 2000ms infinite`,
      },
      "& .disLikeBtn": {
        animation: `$dislikeEffect 2000ms infinite`,
      },
    },
  },
  countStart: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    // backgroundColor: 'rgba(50,93,124,.9)',
    // backgroundImage: 'linear-gradient(to right bottom, #e4ac08, #ffc003, #e4ac08, #ffe80d, #e4ac08)',
    // backgroundColor: "rgba(255, 255, 255, .9)",
    // color: '#fff',
    padding: "20px",
    display: "flex",
    flexFlow: "column",
    // alignItems: 'center',
    // justifyContent: 'center',
    zIndex: "999",
    // fontSize: '40px',
    // "& h4, & ul": {
    //     color: '#fff',
    // },
    "& ul": {
      padding: 0,
      margin: 0,
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "2",
      listStyle: "none",
    },
    "& .countStartLoader": {
      position: "absolute",
      fontSize: "18px",
      right: 15,
      top: 15,
      "& > span": {
        border: "1px solid #fff",
        width: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
      },
    },
    "& > div:not(:first-child)": {
      position: "absolute !important",
    },
  },
  endCallVoteAlert: {
    position: "absolute",
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    background: "#ffe38e",
    width: "100px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "500",
    borderRadius: "50%",
    zIndex: 999,
    animation: `$voteEffect 2000ms infinite`,
    "&:before": {
      content: '""',
      position: "absolute",
      width: "110px",
      height: "110px",
      border: "20px solid #ffc003",
      borderRadius: "50%",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "-5px",
      border: "20px solid transparent",
      borderTop: "20px solid #f6c100",
      right: "-15px",
      transform: "rotate(-45deg)",
      transformOrigin: "right",
    },
  },
  liveScreen: {
    marginTop: "10px",
    position: "relative",
    height: "415px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .react-player": {
      // paddingTop: '75%',
      "& video": {
        position: "absolute",
        top: 0,
        objectFit: "cover",
      },
    },
  },
  "@keyframes likeEffect": {
    "0%": {
      transform: "scale(1.05)",
      boxShadow: "0px 0px 5px -2px rgba(0,0,0,0.75)",
    },
    "50%": {
      transform: "scale(1)",
      boxShadow: "inset 0px 0px 5px -2px rgba(0,0,0,0.75)",
    },
    "100%": {
      transform: "scale(1.05)",
      boxShadow: "0px 0px 5px -2px rgba(0,0,0,0.75)",
    },
  },
  "@keyframes dislikeEffect": {
    "0%": {
      transform: "scale(1)",
      boxShadow: "inset 0px 0px 5px -2px rgba(0,0,0,0.75)",
    },
    "50%": {
      transform: "scale(1.05)",
      boxShadow: "0px 0px 5px -2px rgba(0,0,0,0.75)",
    },
    "100%": {
      transform: "scale(1)",
      boxShadow: "inset 0px 0px 5px -2px rgba(0,0,0,0.75)",
    },
  },
  "@keyframes voteEffect": {
    "0%": {
      transform: "scale(1.05)",
      boxShadow: "0px 0px 5px -2px rgba(0,0,0,0.75)",
      opacity: ".5",
    },
    "50%": {
      transform: "scale(1)",
      boxShadow: "inset 0px 0px 5px -2px rgba(0,0,0,0.75)",
      opacity: "1",
    },
    "100%": {
      transform: "scale(1.05)",
      boxShadow: "0px 0px 5px -2px rgba(0,0,0,0.75)",
      opacity: ".5",
    },
  },
  videoLogo: {
    position: "absolute",
    right: "5%",
    bottom: "10%",
    width: "6em",
    opacity: ".75",
  },
  activeStatus: {
    position: "absolute",
    right: "6px",
    top: "-2px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "20px",
    },
  },
  btn: {
    border: "1px solid #acb4c1",
    display: "flex",
    borderRadius: "8px",
    padding: "8px 24px",
  },
}));

// const ppDetails = [
//     {
//         ppdTeamHeading: 'Proposition Details',
//         ppdTeam: 'Team 1',
//         ppdTeamUserImage: useImage,
//         ppdTeamUserName: 'John David',
//         ppdTeamUserUTC: 'UTC : 16 30 PM',
//         ppdTeamUserProfileLink: '#',
//         ppdTeamSubHeading: 'Description',
//         ppdTeamDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'
//     },
//     {
//         ppdTeamHeading: 'Opposition Details',
//         ppdTeam: 'Team 2',
//         ppdTeamUserImage: useImage,
//         ppdTeamUserName: 'Bradley Cooper',
//         ppdTeamUserUTC: 'UTC : 16 30 PM',
//         ppdTeamUserProfileLink: '#',
//         ppdTeamSubHeading: 'Description',
//         ppdTeamDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'
//     }
// ]

const customDebateHeaderDatas = [
  {
    heading: "Who will won US Election…!",
    date: "20",
    month: "Nov",
    post: "Public",
    hostBy: "John David",
    timeUTC: "UTC +05:30 ",
    startAt: "6 30 PM Start",
    interestIn: ["Political", "Entertainment", "Other"],
    likes: "52,340",
    retweets: "3400",
  },
];
var audience_ads = {};
const LiveDebate = (props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const location_state = getDecryptedHashWithSecret(
    localStorage.getItem("location.state")
  );
  const {
    hlsURL,
    upordownVoteClick,
    upordownvoteObj,
    likes_Click,
    micMuteAndUnMuteTimerObj,
    updatedDebateObj,
  } = useDebateSocket(props);
  const [voteObj, setUpordownvoteObj] = useState({});
  const [debateObj, setDebateObj] = React.useState(location_state.debateObj);
  const [oppositionUsers, setoppositionUsers] = React.useState(false);
  const [propositionUsers, setpropositionUsers] = React.useState(false);
  const [hlsStreamURL, sethlsStreamURL] = React.useState("");
  const [adsDetails, setadsDetails] = useState({});
  const [isJoin, setIsJoin] = useState(true);
  const [micMuteAndUnMuteObj, setMicMuteAndUnMuteObj] = React.useState({
    type: "fix",
    proposition: "unmute",
    opposition: "mute",
    alertSeconds: 0,
  });
  const [snackbarState, setSnackbarState] = useState({ open: false });
  // const [currentProgressValue, setCurrentProgressValue] = useState(0)
  // const [progressValue, setProgressValue] = useState(0)
  // const [circularValue, setCircularValue] = useState(0)
  var historyDebateObj = location_state.debateObj;

  useEffect(() => {
    setLoader(true);
    generateRandomId();
    // setDebateObj(historyDebateObj);
    GetDebateVideoAdList();
    getDebateList();
    //getupordownvotes();
    attendeesView();
    let activeInterval;
    if (historyDebateObj) {
      let propositionUser = false,
        oppositionUser = false;
      if (historyDebateObj.opposition_users && historyDebateObj.opposition_users.length > 0) {
        historyDebateObj.opposition_users.forEach((opu) => {
          if (opu.user._id === props.user._id) {
            oppositionUser = true;
          }
        });
      }
      if (
        historyDebateObj.proposition_users &&
        historyDebateObj.proposition_users.length > 0
      ) {
        historyDebateObj.proposition_users.forEach((pu) => {
          if (pu.user._id === props.user._id) {
            propositionUser = true;
          }
        });
      }
      if (propositionUser || oppositionUser) {
        activeInterval = setInterval(() => {
          updateUserActive();
        }, 4000);
      }
    }
    return () => {
      clearInterval(activeInterval);
    };
  }, []);
  useEffect(() => {
    // callTestSocket();
    if (debateObj) {
      let propositionUser = false,
        oppositionUser = false;
      //     setDebateObj(location_state.debateObj);
      if (debateObj.broadcast_channel_url) {
        sethlsStreamURL(debateObj.broadcast_channel_url);
      }
      if (debateObj.opposition_users && debateObj.opposition_users.length > 0) {
        debateObj.opposition_users.forEach((opu) => {
          if (opu.user._id === props.user._id) {
            setoppositionUsers(true);
            oppositionUser = true;
          }
        });
      }
      if (
        debateObj.proposition_users &&
        debateObj.proposition_users.length > 0
      ) {
        debateObj.proposition_users.forEach((pu) => {
          if (pu.user._id === props.user._id) {
            setpropositionUsers(true);
            propositionUser = true;
          }
        });
      }
    }
  }, [debateObj]);

  useEffect(() => {
    sethlsStreamURL(hlsURL);
  }, [hlsURL]);

  useEffect(() => {
    setUpordownvoteObj(upordownvoteObj);
  }, [upordownvoteObj]);

  useEffect(() => {
    if (updatedDebateObj != null) {
      setDebateObj(updatedDebateObj);
    }
  }, [updatedDebateObj]);

  useEffect(() => {
    // micMuteAndUnMuteTimerObj
    if (micMuteAndUnMuteTimerObj != null) {
      //counter,alert,fix,closingalert,endcallalert,endcall
      setMicMuteAndUnMuteObj(micMuteAndUnMuteTimerObj);
    }
  }, [micMuteAndUnMuteTimerObj]);
  // useEffect(() => {
  //     if (seconds > 0) {
  //         setTimeout(() => setSeconds(seconds - 1), 1000);
  //     } else {
  //         setSeconds('');
  //     }
  // });
  const generateRandomId = () => {
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      length = 12;
    let uid = sessionStorage.getItem("uid");
    if (!uid) {
      uid = "";
      for (let i = 0; i < length; i++) {
        uid += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      sessionStorage.setItem("uid", uid);
    }
    checkUserJoin(uid);
  };
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };
  const getDebateList = async () => {
    var debateId = historyDebateObj._id;
    axios
      .get(API_Path + `api/debate/get/${debateId}`, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          if (response.data != null && response.data.result != null) {
            setDebateObj(response.data.result);
            setLoader(false);
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const checkUserJoin = (uid) => {
    let userJoinAPI = API_Path + "api/check_join_user";

    let inputObj = {
      debate_id: historyDebateObj._id,
      user_id: props.user._id,
      uid,
    };
    axios
      .post(userJoinAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          if (response.data.result.is_join) {
            setIsJoin(true);
          } else if (response.data.result.is_audience) {
            setIsJoin(false);
            setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "info",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateUserActive = () => {
    let userJoinAPI = API_Path + "api/active_user_date";

    let inputObj = {
      debate_id: historyDebateObj._id,
      user_id: props.user._id,
    };
    axios
      .post(userJoinAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      // .then((response) => {
      //   console.log(response)
      // })
      .catch((error) => {
        console.error(error);
      });
  };

  const calltoVote = (type, supportfor) => {
    var inputObject = {
      debateId: debateObj._id,
      userId: props.user._id,
      voteSuppportFor: supportfor,
      type: type,
      isUpdateWonBy: false,
    };
    upordownVoteClick(inputObject);
  };

  const getupordownvotes = async () => {
    var DebatesDetails = API_Path + "getTotalVotes/" + historyDebateObj._id;
    if (props.auth && props.auth.accessToken) {
      axios
        .get(DebatesDetails, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          // response.data.result ? setLikes(response.data.result[0].likes) : setLikes(0)
          setUpordownvoteObj(response.data.result[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onLikesClick = () => {
    likes_Click({
      debateId: debateObj._id,
      userId: props.user._id,
      likeOrDisLike: "like",
    });
  };

  const onDisLikesClick = () => {
    likes_Click({
      debateId: debateObj._id,
      userId: props.user._id,
      likeOrDisLike: "dislike",
    });
  };

  const constructMicDisabledMessage = (name, seconds, isclosingremarks) => {
    return (
      <div className={classes.speakerDetails}>
        <div className="speackersMic">
          <img alt="" src={micBlack} />
        </div>
        <div>
          <h5 className="m-0">
            Your mic will be disabled automatically in next{" "}
          </h5>
        </div>
        <div className="remainingSec">
          {seconds}
          <span>Sec</span>
        </div>
      </div>
    );
  };
  const constructMicEnabledMessage = (name, seconds, isclosingremarks) => {
    return (
      <div className={clsx(classes.speakerDetails, classes.listenerDetails)}>
        <h6 className="m-0">
          Your mic will be enabled automatically in next{" "}
          <span>{seconds} Sec</span>
          {isclosingremarks ? " for closing remarks" : ""}
        </h6>
        <div className="listenerMic">
          <img alt="" src={micBlack} />
        </div>
      </div>
    );
  };
  const constructEndCallAlertMsg = (name, seconds) => {
    return (
      <div className={clsx(classes.speakerDetails, classes.listenerDetails)}>
        <h6 className="m-0">
          Debate will ends in <span>{seconds} Sec</span>
        </h6>
        <div className="listenerMic">
          <img alt="" src={micBlack} />
        </div>
      </div>
    );
  };

  var left_side_alert_message = "";
  var right_side_alert_message = "";
  var left_side_text_message = "";
  var right_side_text_message = "";
  var propositionname =
    debateObj &&
    debateObj.proposition_users &&
    debateObj.proposition_users.length > 0 &&
    debateObj.proposition_users[0].user
      ? debateObj.proposition_users[0].user.first_name
      : "";
  var oppositionname =
    debateObj &&
    debateObj.opposition_users &&
    debateObj.opposition_users.length > 0 &&
    debateObj.opposition_users[0].user
      ? debateObj.opposition_users[0].user.first_name
      : "";
  // left_side_alert_message=constructMicDisabledMessage(propositionname,0,false);
  // right_side_alert_message=constructMicEnabledMessage(propositionname,0,false);
  // right_side_alert_message=constructEndCallAlertMsg(propositionname,0);

  if (propositionUsers && micMuteAndUnMuteTimerObj != null) {
    if (micMuteAndUnMuteTimerObj.type === "alert") {
      if (micMuteAndUnMuteTimerObj.proposition === "unmute") {
        left_side_alert_message = constructMicDisabledMessage(
          propositionname,
          micMuteAndUnMuteTimerObj.alertSeconds,
          false
        );
        left_side_text_message = "proposition =>alert=>mic disable";
      }
      if (micMuteAndUnMuteTimerObj.proposition === "mute") {
        right_side_alert_message = constructMicEnabledMessage(
          oppositionname,
          micMuteAndUnMuteTimerObj.alertSeconds,
          false
        );
        right_side_text_message = "proposition =>alert=>mic enable";
      }
    } else if (micMuteAndUnMuteTimerObj.type === "closingalert") {
      if (micMuteAndUnMuteTimerObj.proposition === "unmute") {
        left_side_alert_message = constructMicDisabledMessage(
          propositionname,
          micMuteAndUnMuteTimerObj.alertSeconds,
          true
        );
        left_side_text_message = "proposition =>closingalert=>mic disable";
      }
      if (micMuteAndUnMuteTimerObj.proposition === "mute") {
        right_side_alert_message = constructMicEnabledMessage(
          oppositionname,
          micMuteAndUnMuteTimerObj.alertSeconds,
          true
        );
        right_side_text_message = "proposition =>closingalert=>mic enable";
      }
    } else if (micMuteAndUnMuteTimerObj.type === "endcallalert") {
      left_side_alert_message = "";
      left_side_text_message = "";
      right_side_alert_message = constructEndCallAlertMsg(
        "",
        micMuteAndUnMuteTimerObj.alertSeconds
      );
      right_side_text_message = "proposition =>endcallalert=>endCall";
    }
  }

  if (oppositionUsers && micMuteAndUnMuteTimerObj != null) {
    if (micMuteAndUnMuteTimerObj.type === "alert") {
      if (micMuteAndUnMuteTimerObj.opposition === "unmute") {
        left_side_text_message = "opposition =>alert=>mic disable";
        left_side_alert_message = constructMicDisabledMessage(
          propositionname,
          micMuteAndUnMuteTimerObj.alertSeconds,
          false
        );
      }
      if (micMuteAndUnMuteTimerObj.opposition === "mute") {
        right_side_alert_message = constructMicEnabledMessage(
          oppositionname,
          micMuteAndUnMuteTimerObj.alertSeconds,
          false
        );
        right_side_text_message = "opposition =>alert=>mic enable";
      }
    } else if (micMuteAndUnMuteTimerObj.type === "closingalert") {
      if (micMuteAndUnMuteTimerObj.opposition === "unmute") {
        left_side_text_message = "opposition =>closingalert=>mic disable";
        left_side_alert_message = constructMicDisabledMessage(
          propositionname,
          micMuteAndUnMuteTimerObj.alertSeconds,
          true
        );
      }
      if (micMuteAndUnMuteTimerObj.opposition === "mute") {
        right_side_alert_message = constructMicEnabledMessage(
          oppositionname,
          micMuteAndUnMuteTimerObj.alertSeconds,
          true
        );
        right_side_text_message = "opposition =>closingalert=>mic enable";
      }
    } else if (micMuteAndUnMuteTimerObj.type === "endcallalert") {
      left_side_alert_message = "";
      left_side_text_message = "";
      right_side_alert_message = constructEndCallAlertMsg(
        "",
        micMuteAndUnMuteTimerObj.alertSeconds
      );
      right_side_text_message = "opposition =>endcallalert=>endcall";
    }
  }

  // let time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const propositionDescription = (
    <>
      <div className={classes.divider}></div>
      <h5 style={{ margin: "0 0 5px 0" }}>Description</h5>
      <h6 className="font-weight-normal" style={{ margin: "0" }}>
        {debateObj && debateObj.proposition_details ? (
          <ShowMoreLessText description={debateObj.proposition_details} />
        ) : (
          ""
        )}
      </h6>
    </>
  );

  const oppositionDescription = (
    <>
      <div className={classes.divider}></div>
      <h5 style={{ margin: "0 0 5px 0" }}>Description</h5>
      <h6 className="font-weight-normal" style={{ margin: "0" }}>
        {debateObj && debateObj.opposition_details ? (
          <ShowMoreLessText description={debateObj.opposition_details} />
        ) : (
          ""
        )}
      </h6>
    </>
  );

  const attendeesView = () => {
    let attendeesView = API_Path + "api/debate/attendees";
    let inputObj = {
      inputObject: {
        debateId: debateObj._id,
        userId: props.user._id,
        debateType: "live",
      },
    };
    axios
      .post(attendeesView, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        getupordownvotes();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetDebateVideoAdList = async () => {
    var DebateVideoAdList = API_Path + "api/debatevideoads/list";
    let inputObj = {
      inputObject: {
        debateId: debateObj._id,
      },
    };

    if (
      props.is_disable_ads === 1 ||
      (props.currentPackage &&
        props.currentPackage.length > 0 &&
        props.currentPackage.findIndex((e) => {
          return e.package_name === "Ad free payment";
        }) > -1)
    ) {
      // alert("if");
      // no need to show ad
    } else {
      await axios
        .post(DebateVideoAdList, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            response.data.result.length > 0
          ) {
            setadsDetails(response.data.result[0]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const endedaudienceview = () => {
    window.location.href = "/recorded";
  };

  const finishedMyTurn = () => {
    if (
      micMuteAndUnMuteTimerObj != null &&
      micMuteAndUnMuteTimerObj.trackingId != null &&
      ((propositionUsers &&
        micMuteAndUnMuteTimerObj.proposition === "unmute") ||
        (oppositionUsers && micMuteAndUnMuteTimerObj.opposition === "unmute"))
    ) {
      let finishapi = API_Path + "api/debateTrackingHistory/finishmyturn";
      let inputObj = {
        inputObject: {
          debateId: debateObj._id,
          trackingId: micMuteAndUnMuteTimerObj.trackingId,
        },
      };
      axios
        .post(finishapi, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let progressValue = 0;
  let currentProgressValue = 0;
  let circularValue = 0;
  let minutesProgress = 0;
  let secondsProgress = 0;
  if (
    (propositionUsers === true &&
      micMuteAndUnMuteTimerObj &&
      (micMuteAndUnMuteTimerObj.proposition === "unmute" ||
        micMuteAndUnMuteTimerObj.proposition === "mute")) ||
    (oppositionUsers === true &&
      micMuteAndUnMuteTimerObj &&
      (micMuteAndUnMuteTimerObj.opposition === "unmute" ||
        micMuteAndUnMuteTimerObj.opposition === "mute"))
  ) {
    //show timer
    progressValue =
      micMuteAndUnMuteTimerObj && micMuteAndUnMuteTimerObj.timercount;
    currentProgressValue =
      micMuteAndUnMuteTimerObj && micMuteAndUnMuteTimerObj.speakingTime;
    minutesProgress = Math.floor(progressValue / 60);
    secondsProgress = progressValue - minutesProgress * 60;
    if (progressValue >= 0) {
      // Reverse
      let value = Math.round((progressValue * 100) / currentProgressValue);
      circularValue = value;
      // Forward
      // if (value >= 0) {
      //     circularValue = (100 - value)
      // }
    }
  }

  const alertRemainingSeconds = (
    <Box
      textAlign="right"
      position="absolute"
      top="45px"
      left="15px"
      right="15px"
    >
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          size={40}
          value={circularValue}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* <p style={{fontSize: '12px', fontWeight: '600'}}>{progressValue}s</p> */}
          <p style={{ fontSize: "12px", fontWeight: "600" }}>
            {minutesProgress > 0 && minutesProgress + "m : "}
            {secondsProgress > 0 && secondsProgress + "s"}
          </p>
        </Box>
      </Box>
    </Box>
  );

  let propositionUpvoteUsers = voteObj && voteObj.proposition_upvote_users;
  let oppositionUpvoteUsers = voteObj && voteObj.opposition_upvote_users;
  let conPropOppUsers =
    Array.isArray(propositionUpvoteUsers) &&
    Array.isArray(oppositionUpvoteUsers)
      ? propositionUpvoteUsers.concat(oppositionUpvoteUsers)
      : [];

  if (
    audience_ads &&
    !audience_ads.url
    // &&!hlsStreamURL) || (!audience_ads.url&&hlsStreamURL&& micMuteAndUnMuteTimerObj != null && micMuteAndUnMuteTimerObj.type == 'counter' && micMuteAndUnMuteTimerObj.alertSeconds > 0)
  ) {
    audience_ads = adsDetails;
  }

  return (
    <div className={classes.pageContainer}>
      {/* <Spinner /> */}
      <div className={classes.leftSection}>
        <CustomDebateHeader
          month={debateObj.debate_planned_start_datetime}
          date={debateObj.debate_planned_start_datetime}
          heading={debateObj.title}
          interestIn={customDebateHeaderDatas[0].interestIn}
          debateDetail={debateObj}
          debateType={debateObj.debate_type}
          liveBtn={true}
          showOnLive={true}
        />
        <div style={{ position: "relative" }}>
          {(oppositionUsers === true || propositionUsers === true) &&
          micMuteAndUnMuteTimerObj != null &&
          micMuteAndUnMuteTimerObj.type === "counter" &&
          micMuteAndUnMuteTimerObj.alertSeconds > 0 ? (
            <div className={classes.countStart}>
              {/* <Box
                margin="auto"
                textAlign="center"
                position="relative!important"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: props.reminder_content }}
                ></div>
              </Box> */}
              {/* <Box margin="auto" textAlign="center" position='relative!important'>
                                <h4 style={{ margin: '0 0 10px 0' }}>Brief Reminder:</h4>
                                <ul>
                                    <li>1) No derogatory comments or cursing towards each other/audience members *
                                    </li>
                                    <li>2) No racial or cultural insensitivities *</li>
                                    <li>3) Prepare to have a good debate *</li>
                                    <li>***Full rules/guidelines can be found in your Terms of Service agreement</li>
                                </ul>
                            </Box> */}
              {/* <Loader /> */}
              <Box className="countStartLoader">
                <span style={{ position: "relative" }}>
                  {micMuteAndUnMuteTimerObj.alertSeconds}
                </span>
              </Box>
            </div>
          ) : (
            ""
          )}
          {isJoin && micMuteAndUnMuteTimerObj != null &&
            micMuteAndUnMuteTimerObj.type === "rule_timer" &&
            micMuteAndUnMuteTimerObj.count > 0 && (
              <div className={classes.liveScreen}>
                <div
                  dangerouslySetInnerHTML={{ __html: props.reminder_content }}
                ></div>
              </div>
            )}

          {isJoin && (oppositionUsers === true || propositionUsers === true) ? (
            <div style={{ position: "relative", height: "415px" }}>
              {micMuteAndUnMuteTimerObj != null &&
              (!micMuteAndUnMuteTimerObj.count ||
                !(micMuteAndUnMuteTimerObj.count > 4)) &&
              debateObj != null &&
              debateObj.ot_sessionId != null &&
              debateObj.ot_sessionToken != null &&
              debateObj.ot_sessionId.trim().length > 0 &&
              debateObj.ot_sessionToken.trim().length > 0 ? (
                <>
                  <OpenTokVideo
                    debateObj={debateObj}
                    isProposition={propositionUsers}
                    isOpposition={oppositionUsers}
                    micmuteunmuteobj={micMuteAndUnMuteObj}
                  />
                  <img
                    alt="Logo"
                    src={logoImage}
                    className={classes.videoLogo}
                  />
                </>
              ) : (
                ""
              )}
              <div className={classes.flagView}>
                <div className="flag">
                  {/* <img alt="" src={flag} /> */}
                  {debateObj != null && (
                    <ReportAbuse
                      listItem="imageItem"
                      debateDetail={debateObj}
                    />
                  )}
                </div>
                <div
                  className="finish"
                  disabled={
                    micMuteAndUnMuteTimerObj != null &&
                    micMuteAndUnMuteTimerObj.trackingId != null &&
                    ((propositionUsers &&
                      micMuteAndUnMuteTimerObj.proposition === "unmute") ||
                      (oppositionUsers &&
                        micMuteAndUnMuteTimerObj.opposition === "unmute"))
                      ? false
                      : true
                  }
                >
                  <span onClick={finishedMyTurn} style={{ display: "block" }}>
                    <img alt="" src={check} />
                    Finish
                  </span>
                </div>
                <div className="view">
                  <img alt="" src={view} />
                  {debateObj != null &&
                  debateObj.audiance_attended != null &&
                  debateObj.audiance_attended.length > 0
                    ? debateObj.audiance_attended.length
                    : 0}
                </div>
              </div>

              {circularValue > 0 ? alertRemainingSeconds : ""}

              <div className={classes.speakersContainer}>
                {left_side_alert_message}
                {right_side_alert_message}
                {/*                                 
                                <div className={classes.speakerDetails}>
                                    <div className="speackersMic">
                                        <img alt="" src={micBlack} />
                                    </div>
                                    <div>
                                        <h5 className="m-0">{debateObj && debateObj.proposition_users && debateObj.proposition_users.length > 0 &&
                                            debateObj.proposition_users[0].user ? debateObj.proposition_users[0].user.first_name : ""}</h5>
                                        <h4 className="m-0">Speaking…</h4>
                                    </div>
                                    <div className="remainingSec">
                                        10
                                        <span>Sec</span>
                                    </div>
                                </div>

                                <div className={clsx(classes.speakerDetails, classes.listenerDetails)}>
                                    <h6 className="m-0">
                                        {debateObj && debateObj.opposition_users && debateObj.opposition_users.length > 0 &&
                                            debateObj.opposition_users[0] ? debateObj.opposition_users[0].user.first_name : ""} Turn will start in <br />
                                        <span>10 Sec</span> and Mic will be muted <br />
                                        automatically
                                    </h6>
                                    <div className="listenerMic">
                                        <img alt="" src={micBlack} />
                                    </div>
                                </div> */}
              </div>
            </div>
          ) : (
            <div className={classes.liveScreen}>
              {/* <Reacthlsplayer hlsStreamURL={hlsStreamURL} /> */}
              <div className={classes.flagView} style={{ left: "auto" }}>
                <div className="view">
                  <img alt="" src={view} />
                  {debateObj != null &&
                  debateObj.audiance_attended != null &&
                  debateObj.audiance_attended.length > 0
                    ? debateObj.audiance_attended.length
                    : 0}
                </div>
              </div>
              {(micMuteAndUnMuteTimerObj && micMuteAndUnMuteTimerObj.type) ===
                "endcallalert" &&
                !conPropOppUsers.includes(props.user._id) && (
                  <div className={classes.endCallVoteAlert}>Vote</div>
                )}
              {/* <ReactPlayer className='react-player'
                                url={hlsStreamURL}
                                width='100%'
                                height='100%'
                                style={{ maxWidth: '100%' }}
                                // url="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8"
                                playing
                                controls
                                config={{
                                    file: {
                                        forceHLS: true,
                                    }
                                }}
                            /> */}
              <AudienceAdInjection
                endedaudienceview={endedaudienceview}
                videoUrl={
                  micMuteAndUnMuteTimerObj != null &&
                  micMuteAndUnMuteTimerObj.type === "counter"
                    ? null
                    : hlsStreamURL
                }
                adsList={audience_ads}
              />
              <img alt="Logo" src={logoImage} className={classes.videoLogo} />
            </div>
          )}
        </div>
        <div className={classes.lrrSection}>
          <div className={classes.likeRetweetRobot}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "18px",
                }}
              >
                {voteObj &&
                voteObj.liked_users &&
                voteObj.liked_users.includes(props.user._id) ? (
                  <img alt="" src={ThumbsActive} onClick={onLikesClick} />
                ) : (
                  <img alt="" src={Thumbs} onClick={onLikesClick} />
                )}
                <h5 style={{ margin: "0 0 0 10px" }}>
                  {/* {voteObj != null && voteObj.likes != null ? voteObj.likes : 0} */}
                  <LikeUserCompoent
                    count={
                      !!voteObj && !!voteObj.likes && voteObj.likes !== null
                        ? numberFormatter(voteObj.likes)
                        : 0
                    }
                    active={0}
                    debate={true}
                    auth={props.auth}
                    id={historyDebateObj ? historyDebateObj._id : ""}
                  />
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "18px",
                }}
              >
                {voteObj &&
                voteObj.disliked_users &&
                voteObj.disliked_users.includes(props.user._id) ? (
                  <img
                    alt=""
                    src={ThumbsActive}
                    onClick={onDisLikesClick}
                    style={{ transform: "rotate(180deg)" }}
                  />
                ) : (
                  <img
                    alt=""
                    src={Thumbs}
                    onClick={onDisLikesClick}
                    style={{ transform: "rotate(180deg)" }}
                  />
                )}
                <h5 style={{ margin: "0 0 0 10px" }}>
                  <LikeUserCompoent
                    count={
                      !!voteObj &&
                      !!voteObj.dislikes &&
                      voteObj.dislikes !== null
                        ? numberFormatter(voteObj.dislikes)
                        : 0
                    }
                    active={1}
                    debate={true}
                    auth={props.auth}
                    id={historyDebateObj ? historyDebateObj._id : ""}
                  />
                </h5>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img alt="" src={Retweet} />{" "}
                <h5 style={{ margin: "0 0 0 10px" }}>
                  {debateObj && debateObj.share_count}
                </h5>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "18px",
              }}
            >
              <div
                style={{ width: "45px", height: "45px", margin: "0 10px 0 0" }}
              >
                <img
                  alt=""
                  src={robotImage}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <div>
                <h6 className="font-weight-normal" style={{ margin: "0" }}>
                  Judge
                </h6>
                <h5 style={{ margin: "0" }}>AI ROBOT</h5>
              </div>
            </div>
          </div>
          {/* <div className={classes.likesDislikes + " " + ((voteObj === undefined || voteObj === null) && micMuteAndUnMuteTimerObj.type == 'endcallalert' ? 'activeAnimate' : '')}> */}
          <div
            className={
              classes.likesDislikes +
              " " +
              ((micMuteAndUnMuteTimerObj && micMuteAndUnMuteTimerObj.type) ===
                "endcallalert" && !conPropOppUsers.includes(props.user._id)
                ? "activeAnimate"
                : "")
            }
          >
            <div style={{ marginRight: "20px" }}>
              <h5 style={{ margin: "0" }}>
                {debateObj &&
                debateObj.proposition_users &&
                debateObj.proposition_users.length > 0 ? (
                  <>
                    {debateObj.proposition_users[0] &&
                    debateObj.proposition_users[0].user
                      ? debateObj.proposition_users[0].user.first_name
                      : "-"}
                  </>
                ) : (
                  "-"
                )}
              </h5>
              {debateObj &&
              debateObj.proposition_users[0].user.user_tagging &&
              debateObj.proposition_users[0].user.user_tagging.length > 0 ? (
                <p
                  style={{
                    margin: "0 0 5px 0",
                    color: "#758194",
                    fontWeight: "700",
                    fontSize: "12px",
                  }}
                >
                  @ {debateObj.proposition_users[0].user.user_tagging}
                </p>
              ) : (
                <p style={{ margin: "23px 0" }}></p>
              )}
              <ButtonGroup
                size="large"
                aria-label="large outlined button group"
              >
                {/* <Button
                  disabled={
                    voteObj &&
                    voteObj.opposition_upvote_users &&
                    voteObj.opposition_upvote_users.includes(props.user._id)
                  }
                  className={
                    voteObj &&
                    voteObj.proposition_upvote_users &&
                    voteObj.proposition_upvote_users.includes(props.user._id)
                      ? "active likeBtn"
                      : "likeBtn"
                  }
                  onClick={calltoVote.bind(this, "increment", "proposition")}
                >
                  <img alt="" src={Thumbs} style={{ height: "18px" }} />
                  <h6 style={{ margin: "0 0 0 5px" }}>
                    {voteObj && voteObj.proposition_upvote != null
                      ? voteObj.proposition_upvote
                      : 0}
                  </h6>
                </Button> */}
                <div
                  className={`${classes.btn} ${
                    voteObj &&
                    voteObj.proposition_upvote_users &&
                    voteObj.proposition_upvote_users.includes(props.user._id)
                      ? "active likeBtn"
                      : "likeBtn"
                  } `}
                >
                  <IconButton
                    disabled={
                      voteObj &&
                      voteObj.opposition_upvote_users &&
                      voteObj.opposition_upvote_users.includes(props.user._id)
                    }
                    style={{ padding: "0" }}
                    onClick={calltoVote.bind(this, "increment", "proposition")}
                  >
                    <img alt="" src={Thumbs} style={{ height: "18px" }} />
                  </IconButton>{" "}
                  <h6 style={{ margin: "0 0 0 5px" }}>
                    {/* {voteObj && voteObj.proposition_upvote !== null
                          ? numberFormatter(voteObj.proposition_upvote)
                          : 0} */}
                    <LikeUserCompoent
                      count={
                        voteObj &&
                        !!voteObj.proposition_upvote &&
                        voteObj.proposition_upvote !== null
                          ? numberFormatter(voteObj.proposition_upvote)
                          : 0
                      }
                      active={0}
                      debate={true}
                      vote={true}
                      auth={props.auth}
                      id={historyDebateObj ? historyDebateObj._id : ""}
                    />
                  </h6>
                </div>
                {/* <Button className="disLikeBtn" onClick={calltoVote.bind(this, 'decrement', 'proposition')}><img alt="" src={Thumbs} style={{ height: '18px', transform: 'rotate(180deg)' }} /> <h6 style={{ margin: '0 0 0 5px' }}>{voteObj && voteObj.proposition_downvote != null ? voteObj.proposition_downvote : 0}</h6></Button> */}
              </ButtonGroup>
            </div>
            <div>
              <h5 style={{ margin: "0" }}>
                {debateObj &&
                debateObj.opposition_users &&
                debateObj.opposition_users.length > 0 ? (
                  <>
                    {debateObj.opposition_users[0] &&
                    debateObj.opposition_users[0].user
                      ? debateObj.opposition_users[0].user.first_name
                      : "-"}
                  </>
                ) : (
                  "-"
                )}
              </h5>
              {debateObj &&
              debateObj.opposition_users[0].user.user_tagging &&
              debateObj.opposition_users[0].user.user_tagging.length > 0 ? (
                <p
                  style={{
                    margin: "0 0 5px 0",
                    color: "#758194",
                    fontWeight: "700",
                    fontSize: "12px",
                  }}
                >
                  @{debateObj.opposition_users[0].user.user_tagging}
                </p>
              ) : (
                <p style={{ margin: "23px 0" }}></p>
              )}
              <ButtonGroup
                size="large"
                aria-label="large outlined button group"
              >
                {/* <Button
                  disabled={
                    voteObj &&
                    voteObj.proposition_upvote_users &&
                    voteObj.proposition_upvote_users.includes(props.user._id)
                  }
                  className={
                    voteObj &&
                    voteObj.opposition_upvote_users &&
                    voteObj.opposition_upvote_users.includes(props.user._id)
                      ? "active likeBtn"
                      : "likeBtn"
                  }
                  onClick={calltoVote.bind(this, "increment", "opposition")}
                >
                  <img alt="" src={Thumbs} style={{ height: "18px" }} />
                  <h6 style={{ margin: "0 0 0 5px" }}>
                    {voteObj && voteObj.opposition_upvote != null
                      ? voteObj.opposition_upvote
                      : 0}
                  </h6>
                </Button> */}
                <div
                  className={`${classes.btn} ${
                    voteObj &&
                    voteObj.opposition_upvote_users &&
                    voteObj.opposition_upvote_users.includes(props.user._id)
                      ? "active likeBtn"
                      : "likeBtn"
                  } `}
                >
                  <IconButton
                    disabled={
                      voteObj &&
                      voteObj.proposition_upvote_users &&
                      voteObj.proposition_upvote_users.includes(props.user._id)
                    }
                    style={{ padding: "0" }}
                    onClick={calltoVote.bind(this, "increment", "opposition")}
                  >
                    <img alt="" src={Thumbs} style={{ height: "18px" }} />
                  </IconButton>{" "}
                  <h6 style={{ margin: "0 0 0 5px" }}>
                    {/* {voteObj && voteObj.proposition_upvote !== null
                          ? numberFormatter(voteObj.proposition_upvote)
                          : 0} */}
                    <LikeUserCompoent
                      count={
                        voteObj &&
                        !!voteObj.opposition_upvote &&
                        voteObj.opposition_upvote !== null
                          ? numberFormatter(voteObj.opposition_upvote)
                          : 0
                      }
                      active={1}
                      debate={true}
                      vote={true}
                      auth={props.auth}
                      id={historyDebateObj ? historyDebateObj._id : ""}
                    />
                  </h6>
                </div>
                {/* <Button className="disLikeBtn" onClick={calltoVote.bind(this, 'decrement', 'opposition')}><img alt="" src={Thumbs} style={{ height: '18px', transform: 'rotate(180deg)' }} /> <h6 style={{ margin: '0 0 0 5px' }}>{voteObj && voteObj.opposition_downvote != null ? voteObj.opposition_downvote : 0}</h6></Button> */}
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "36px" }}>
          <Grid container spacing={3}>
            {/* {ppDetails.map((ppDetail) => {
                            return ( */}
            <Grid container item md={6} style={{ flexFlow: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4 style={{ margin: "0" }}>Proposition Details</h4>
                <div
                  className={classes.badge}
                  style={{ backgroundColor: "#ffc003" }}
                >
                  Team 1{" "}
                </div>
              </div>
              <Paper className={classes.paper}>
                {debateObj &&
                  debateObj.proposition_users.map((team, index) => {
                    return (
                      <>
                        <Box key={index} display="flex" width="100%">
                          <div style={{ position: "relative" }}>
                            <CustomImage
                              userImage={
                                team && team.user && team.user.profile_image
                              }
                              size="46px"
                            />
                            {team && team.user && !!team.user.badge_status && (
                              <span
                                className={classes.activeStatus}
                                title="Verified User/Public Figure"
                              >
                                <VerifiedUserRoundedIcon />
                              </span>
                            )}
                          </div>
                          <Box
                            display="flex"
                            flexDirection={{ xs: "column", sm: "row" }}
                            justifyContent="space-between"
                            alignItems={{ sm: "center" }}
                            width="100%"
                          >
                            <div>
                              <h5 style={{ margin: "0" }}>
                                {team && team.user && team.user.full_name}
                              </h5>
                              {team &&
                                team.user &&
                                team.user.user_tagging &&
                                team.user.user_tagging.length > 0 && (
                                  <span
                                    style={{
                                      margin: "0",
                                      color: "#758194",
                                      fontWeight: "700",
                                      fontSize: "12px",
                                    }}
                                  >
                                    @{""}
                                    {team.user.user_tagging}
                                  </span>
                                )}
                            </div>
                            {/* <h6 style={{ margin: '0', fontWeight: 'normal', color: '#758194' }}>{"UTC " + time}</h6> */}
                            <Box mt={{ xs: 1, sm: 0 }}>
                              {team &&
                              team.user &&
                              team.user._id === props.user._id ? (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  component={Link}
                                  to={`/userDashboard`}
                                  target="_blank"
                                >
                                  View profile
                                </Button>
                              ) : (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  component={Link}
                                  to={`/friendsProfilePage/${
                                    team && team.user._id
                                  }`}
                                  target="_blank"
                                >
                                  View profile
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        {propositionDescription}
                      </>
                    );
                  })}
              </Paper>
            </Grid>
            <Grid container item md={6} style={{ flexFlow: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4 style={{ margin: "0" }}>Opposition Details</h4>
                <div
                  className={classes.badge}
                  style={{ backgroundColor: "#61cbc9" }}
                >
                  Team 2{" "}
                </div>
              </div>
              <Paper className={classes.paper}>
                {debateObj &&
                  debateObj.opposition_users.map((team, index) => {
                    return (
                      <>
                        <Box key={index} display="flex" width="100%">
                          <div style={{ position: "relative" }}>
                            <CustomImage
                              userImage={
                                team && team.user && team.user.profile_image
                              }
                              size="46px"
                            />
                            {team && team.user && !!team.user.badge_status && (
                              <span
                                className={classes.activeStatus}
                                title="Verified User/Public Figure"
                              >
                                <VerifiedUserRoundedIcon />
                              </span>
                            )}
                          </div>
                          <Box
                            display="flex"
                            flexDirection={{ xs: "column", sm: "row" }}
                            justifyContent="space-between"
                            alignItems={{ sm: "center" }}
                            width="100%"
                          >
                            <div>
                              <h5 style={{ margin: "0" }}>
                                {team && team.user && team.user.full_name}
                              </h5>
                              {team &&
                                team.user &&
                                team.user.user_tagging &&
                                team.user.user_tagging.length > 0 && (
                                  <span
                                    style={{
                                      margin: "0",
                                      color: "#758194",
                                      fontWeight: "700",
                                      fontSize: "12px",
                                    }}
                                  >
                                    @{""}
                                    {team.user.user_tagging}
                                  </span>
                                )}
                            </div>
                            {/* <h6 style={{ margin: '0', fontWeight: 'normal', color: '#758194' }}>{"UTC " + time}</h6> */}
                            <Box mt={{ xs: 1, sm: 0 }}>
                              {team &&
                              team.user &&
                              team.user._id === props.user._id ? (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  component={Link}
                                  to={`/userDashboard`}
                                  target="_blank"
                                >
                                  View profile
                                </Button>
                              ) : (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  component={Link}
                                  to={`/friendsProfilePage/${
                                    team && team.user._id
                                  }`}
                                  target="_blank"
                                >
                                  View profile
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        {oppositionDescription}
                      </>
                    );
                  })}
              </Paper>
            </Grid>
            {/* )
                        })} */}
          </Grid>
        </div>
      </div>
      <div className={classes.rightSection}>
        <div className={classes.stickySection}>
          <h4>Live Comments</h4>
          {debateObj ? (
            <Chat roomName={debateObj._id} chatType="debate" toUserId={null} />
          ) : (
            ""
          )}
        </div>
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      {loader && <Loader />}
    </div>
  );
};

// export default LiveDebate
const mapStateToProps = ({
  loginDetails: {
    user,
    auth,
    reminder_content,
    is_disable_ads,
    currentPackage,
  },
}) => {
  return {
    user,
    auth,
    reminder_content,
    is_disable_ads,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(LiveDebate));
