import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Box, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";

const useStyles = makeStyles((theme) => ({
  featuredDebaterList: {
    display: "flex",
    position:"relative",
    flexDirection: "column",
    textAlign: "center",
    width: 180,
    height: 180,
    "& h5":{
        marginBottom:"0px"
    },
    "& p":{
        fontSize:'14px'
    },
    "& img, & svg": {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "50%",
    },
    "& .image": {
      width: 180,
      height: 180,
      margin: "auto",
      padding: 10,
      overflow: "hidden",
      borderRadius: "50%",
      // backgroundImage: 'linear-gradient(to right bottom, #fefe00, #dfdf00, #c0c000, #a3a300, #868600)',
      backgroundImage:
        "linear-gradient(to right bottom, #e4ac08, #ffc003, #e4ac08, #ffe80d, #e4ac08)",
    },
  },
  activeStatus: {
    position: "absolute",
    right: "25px",
    top: "0px",
    width:'44px',
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "18px",
    },
  },
}));

const FeaturedDebaters = (props) => {
  const classes = useStyles();
  const [featuredDebaters, setFeaturedDebaters] = useState([]);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  useEffect(() => {
    featuredDebatersList();
  }, []);

  const featuredDebatersList = async () => {
    axios
      .get(API_Path + "api/user/boostedProfiles", {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          if (response.data !== null && response.data.result !== null) {
            setFeaturedDebaters(response.data.result);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onRedirect = (url) => {
    let link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };

  return (
    <>
      <h5 style={{ margin: "10px 0px 24px" }}>Featured Debaters</h5>
      <Slider {...settings}>
        {/* <img src={sliderImage1} style={{maxWidth: '100%'}} />
                <img src={sliderImage2} style={{maxWidth: '100%'}} /> */}
        {featuredDebaters && featuredDebaters.length > 0 ? (
          featuredDebaters.map((featuredDebater, index) => {
            let Userurl =  featuredDebater._id ? `/friendsProfilePage/${featuredDebater._id}` : "";
            return (
              <Box key={index} className={classes.featuredDebaterList}>
                <Box className="image" style={{cursor:"pointer"}} onClick={() => onRedirect(Userurl)}>
                  {featuredDebater && featuredDebater.profile_image ? (
                    <img
                      src={featuredDebater.profile_image}
                      alt="Featured Debaters"
                      style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <AccountCircle style={{ fill: "#fff" }} />
                  )}
                  
                </Box>
                {!!featuredDebater.badge_status && (
                    <span
                      className={classes.activeStatus}
                      title="Verified User/Public Figure"
                    >
                      <VerifiedUserRoundedIcon />
                    </span>
                  )}
                <h5 className="mb-0">{featuredDebater && featuredDebater.full_name}</h5>
                {featuredDebater && featuredDebater.user_tagging &&<p className="m-0">@{featuredDebater.user_tagging}</p>}
              </Box>
            );
          })
        ) : (
          <Typography variant="h6">No Featured Debaters Available</Typography>
        )}
      </Slider>
    </>
  );
};

// export default FeaturedDebaters
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(FeaturedDebaters));
