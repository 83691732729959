// @flow

import isArray from 'lodash/isArray'
import map from 'lodash/map'
import Compressor from 'compressorjs';
import s3FileUpload from "react-s3";
import aes256 from 'aes256';
import { getDecryptedHashWithSecret } from '../screens/Common/Const';
function uriEncodeArray(key, array) {
  return map(array, (value) => `${key}[]=${encodeURIComponent(value)}`).join(
    '&',
  )
}

function uriEncodeObject(object: *) {
  return Object.keys(object)
    .sort() // For stable spec assertions.
    .map((key) => {
      const unencodedValue = object[key]

      if (!unencodedValue) {
        return null
      }
      if (isArray(unencodedValue)) {
        return uriEncodeArray(key, unencodedValue)
      }
      const value = encodeURIComponent(object[key])

      return `${key}=${value}`
    })
    .filter((val) => val)
    .join('&')
}
export const checkUrl = (data) => {
  let val = data
  var matches = !!val && val.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi);
  var result='';
  if(!!matches && !!matches.length){
    for(let url of matches){
          val = !!val && val.replace(url,`<a href= '${url}' target = '_blank'>${url}</a>`)
    }
    return val
  }else{
    return val
  }
}
const rule = /([@|@][^\s]+)/g;

export const parseTag = (value, renderer, action,createElement) => {

    return value.split(rule).map((chunk) => {

        if (chunk.match(rule)) {
          // console.log(value.split(rule))
          return chunk
            // return `<span style="color:red">${chunk}</span>`
        }

        // return chunk;
    })
};
// Image Size Compress
export   function ImageSizecompress(file) {
  return new Promise((resolve, reject) => {
     new Compressor(file, {
       quality: .6,
       success: (result)=>{
        resolve(result)
      },
       error: reject
    });
 });
}
export const AWSfileDelete = async (url, type, hashed) => {
  let { REACT_APP_AWS_S3_IMAGE_BUGKET_NAME, REACT_APP_AWS_S3_REGINE, REACT_APP_AWS_S3_ACCESSID, REACT_APP_AWS_S3_SECRET_ACCESSID, REACT_APP_AWS_S3_VIDEO_BUGKET_NAME } = process.env
  let data = getDecryptedHashWithSecret(hashed)
  // let config = {
  //   bucketName: REACT_APP_AWS_S3_IMAGE_BUGKET_NAME,
  //   region: REACT_APP_AWS_S3_REGINE,
  //   accessKeyId: REACT_APP_AWS_S3_ACCESSID,
  //   secretAccessKey: REACT_APP_AWS_S3_SECRET_ACCESSID,
  // };
  let config = {
    bucketName: data.vurbImageBucket,
    region: REACT_APP_AWS_S3_REGINE,
    accessKeyId: data.profileApiKey,
    secretAccessKey: data.profileApiSecret,
  };
  // Aws Video Bugket Name Change based conditon 
  if (type === "video") {
    // config.bucketName = REACT_APP_AWS_S3_VIDEO_BUGKET_NAME
    config.bucketName = data.videoBucket
  }
  const fileName = url.split("/")[url.split("/").length - 1];
  await s3FileUpload
    .deleteFile(fileName, config)
    .then((data) => console.log(data))
    .catch((e) => console.log("err", e));
};
export default uriEncodeObject

export const key = process.env.REACT_APP_CHAT_ENCRYPT_DECRYPT_KEY;

const state = "ON"

export const encrypt = (plaintext) => {
    if (state === "ON") {
        const secretJson = JSON.stringify(plaintext);
        const encrypted = aes256.encrypt(key, secretJson);
        return { "data": encrypted }
    } else {
        return plaintext
    }
}

export const decrypt = (crypted) => {
    if (state === 'ON') {
        const decrypted = aes256.decrypt(key, crypted);
        const decrypt = JSON.parse(decrypted)
        return decrypt
    } else return crypted
}