import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { getDecryptedHashWithSecret } from "../../Common/Const";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
const useDebateTimerSocket = (props) => {
  const location_state=getDecryptedHashWithSecret(localStorage.getItem("location.state"));
  const roomId = props.roomName ? props.roomName : (location_state.roomName ? location_state.roomName : null)
  const debateObj =(location_state.debateObj ? location_state.debateObj : null);
  const [debateTimerObj, setDebateTimerObj] = useState(null);
  const [onlineUsersObj, setOnlineUsersObj] = useState([]);
  
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { 
                // roomId:roomId,
                roomId:debateObj._id+"_"+props.user._id,
                type:"Debate Timer",
                debateId:debateObj?debateObj._id:null,
                // debateObj:JSON.stringify(debateObj),
                loggedInUserId:props.user._id
            },
    });

    socketRef.current.on('resetDebateTimer', function (data) {
        setDebateTimerObj(data);
    });

    socketRef.current.on('resetOnlineUsers', function (data) {
      setOnlineUsersObj(data);
    });
    
    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);
  
  return { debateTimerObj,onlineUsersObj};
};

export default useDebateTimerSocket;
