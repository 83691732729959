import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { getDecryptedHashWithSecret } from "../../Common/Const";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
const useDebateExtendedTimerSocket = (props) => {
  const location_state=getDecryptedHashWithSecret(localStorage.getItem("location.state"));
  const roomId = props.roomName ? props.roomName : (location_state.roomName ? location_state.roomName : null)
  const debateObj =(location_state.debateObj ? location_state.debateObj : null);
  const [extendedTimerObj, setExtendedTimerObj] = useState(null);
  const [updatedDebateObj, setUpdatedDebateObj] = useState(null);
  const [isReloadActiveDebateUI, setReloadActiveDebateUI] = useState(null);

  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { 
               roomId:debateObj._id,
               type:"Debate Extended",
               debateId:debateObj?debateObj._id:null,
               loggedInUserId:props.user._id
             },
    });
    socketRef.current.on('resetDebateTimer', function (data) {
      setExtendedTimerObj(data);
    });
    socketRef.current.on('resetDebateObj', function (data) {
      setUpdatedDebateObj(data);
    });
    socketRef.current.on('reloadActiveDebateUI', function (data) {
      if(isReloadActiveDebateUI!==null){
        var updateisReloadActiveDebateUI=!isReloadActiveDebateUI;
        setReloadActiveDebateUI(updateisReloadActiveDebateUI);        
      }else{
        setReloadActiveDebateUI(false);        
      }
    });
    
    
  
    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);
  
           
  return {extendedTimerObj,updatedDebateObj,isReloadActiveDebateUI};
};

export default useDebateExtendedTimerSocket;
