import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
  colors,
  makeStyles,
  LinearProgress,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Filter from "../../../../assets/images/filter.svg";
import Filtered from "../../../../assets/images/filtered.svg";
import axios from "axios";
import { API_Path } from "../../../Common/Const";
import Loader from "../../../Common/Loader";

import {
  Cell,
  Label,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Cancel, FiberManualRecordRounded } from "@material-ui/icons";
import FilterModal from "./FilterModal";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#ffc003",
  },
}))(LinearProgress);
const useStyles = makeStyles((theme) => ({
  filterIcon: {
    border: "1px solid #fbdd88",
    borderRadius: "0 !important",
    backgroundColor: "white",
    margin: "0px 13px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "7px",
    borderRadius: "14px",
    fontSize: "30px",
    right: "7px",
  },
  adsFilterLable: {
    textAlign: "left",
    float: "left",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    justifyContent: "center",
  },
  topFiveAds: {
    display: "flex",
    justifyContent: "space-between",
  },
  LableDiv: {
    display: "flex",
    gap: "3px",
    fontSize: "13px",
    paddingBottom: "5px",
  },
  noData: {
    height: "310px",
    justifyContent: "center",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    fontSize: "20px",
    color: "#888",
  },
  name: {
    margin: "0px",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

const AdsPerformance = (props) => {
  const [loading, setLoader] = useState(false);
  const [PerformanceList, setPerFormanceList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filterData, setFilterData] = useState({
    adsMonth: null,
    adsYear: null,
  });
  let colors = ["#D80032", "#55DA6A", "#00D9C0", "#F9DEC9", "#EA9010"];
  const classes = useStyles();
  useEffect(() => {
    getPeformanceList();
  }, []);
  const getPeformanceList = (data = false) => {
    let api = API_Path + `api/dashboard/get_AdPerformanceGraph`;
    let req = {
      month: data ? null : filterData?.adsMonth?.value,
      year: data ? null : filterData?.adsYear?.value,
    };
    setLoader(true);
    setOpenModal(false);
    axios
      .post(api, req, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setPerFormanceList(response.data.result);
        } else {
          setPerFormanceList([]);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const handleChange = (event, name) => {
    setFilterData({ ...filterData, [name]: event });
    if (
      name === "adsYear" &&
      event?.value === new Date().getFullYear() &&
      filterData?.adsMonth?.value > new Date().getMonth() + 1
    ) {
      setFilterData({ adsYear: event, adsMonth: null });
    }
  };

  const Clear = () => {
    let clear = true;
    setOpenModal(false);

    setFilterData({
      adsMonth: null,
      adsYear: null,
    });
    getPeformanceList(clear);
  };

  return (
    <>
      {loading && <Loader />}
      <Paper elevation={3} style={{ padding: "18px" }}>
        <div className={classes.header}>
          <div>
            <h4 className={classes.name}>Ad Performance</h4>
          </div>
          <div>
            <IconButton
              size="medium"
              className={classes.filterIcon}
              onClick={() => setOpenModal(true)}
            >
              {filterData.adsMonth !== null || filterData.adsYear !== null ? (
                <img alt="" width="18px" src={Filtered}></img>
              ) : (
                <img alt="" width="18px" src={Filter}></img>
              )}
            </IconButton>
          </div>
        </div>
        <Grid container spacing={2}>
          {PerformanceList.length > 0 ? (
            <>
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <ResponsiveContainer width="100%" height={350}>
                  <PieChart>
                    <Pie
                      data={PerformanceList}
                      dataKey="percentage"
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      isAnimationActive={false}
                      style={{ outline: "none" }}
                    >
                      {PerformanceList.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      ))}
                      <Label
                        value="TOP 5"
                        position="center"
                        fontSize="x-large"
                        fontFamily="Poppins"
                        fontWeight="700"
                      />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sm={6}
                style={{ paddingTop: "50px" }}
              >
                {PerformanceList.map((ads, i) => (
                  <div key={i} style={{ paddingBottom: "15px" }}>
                    <div className={classes.topFiveAds}>
                      <div className={classes.LableDiv}>
                        <FiberManualRecordRounded
                          style={{ color: colors[i], width: "15px" }}
                        />
                        <span>{ads.ad_title}</span>
                      </div>
                      <div>
                        <span>{ads.percentage}%</span>
                      </div>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={ads.percentage}
                    />
                  </div>
                ))}
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <p className={classes.noData}>No Data Available</p>
            </Grid>
          )}
        </Grid>
      </Paper>
      <FilterModal
        open={openModal}
        setOpenModal={setOpenModal}
        auth={props.auth}
        filterData={filterData}
        setFilterData={setFilterData}
        handleChange={handleChange}
        getList={getPeformanceList}
        Clear={Clear}
        performance={true}
      />
    </>
  );
};

export default AdsPerformance;
