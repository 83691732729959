import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    paginationDesign: {
        "& .MuiTablePagination-toolbar": {
            minHeight: 'auto',
            fontSize: '12px',
            fontWeight: '500',
        },
        "& .MuiTablePagination-input + .MuiTablePagination-caption, & .MuiTablePagination-actions": {
            display: 'none',
        },
        "& .MuiInputBase-root": {
            minWidth: '50px'
        }
    }
}))
const CommonPagination = (props) => {
    const classes = useStyles();
    return (
        <Box display="flex" justifyContent={props.JusCont} className={classes.paginationDesign}>
            {/* <TablePagination
                component="div"
                count={props.count}
                page={props.page}
                onChangePage={props.handleChange}
                rowsPerPage={props.rowsPerPage}
                onChangeRowsPerPage={props.handleChangeRowsPerPage}
            /> */}
            <Pagination
                 count={props.count}
                variant="outlined"
                shape="rounded"
                color="primary"
                size='small'
                page={props.page}
                onChange={props.handleChange} boundaryCount={1}
            />
        </Box>
    )
}

export default CommonPagination
