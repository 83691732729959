import {
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { API_Path } from "../../Common/Const";
import VurbCommentList from "./VurbCommentList";
import ConfirmationModal from "../ConfirmationModal";
import SnackBar from "../../../components/SnackBar";
import CommentEditor from "./CommentEditor";
const useStyles = makeStyles((theme) => ({
  commentSection: {
    marginTop: "20px",
  },
  videoModal: {
    width: "50%",
  },
  gifModal: {
    width: "60%",
  },
  paperPostInput: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.13)",
    borderRadius: "12px",
    backgroundColor: "#fff",
    margin: "20px 0",
    position: "relative",
    display: "flex",
    padding: "10px",
  },
  subCommentAccordion: {
    backgroundColor: "#fff",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      margin: "0 7% 0 8%!important",
    },
    "&:before": {
      opacity: "0",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      justifyContent: "flex-start",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      flexGrow: "inherit",
      "& .hideAll": {
        display: "none",
      },
      "&.Mui-expanded": {
        "& .hideAll": {
          display: "block",
        },
        "& .viewAll": {
          display: "none",
        },
      },
      "& p": {
        color: "#758194",
        fontSize: "12px",
      },
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0",
    },
  },
  quillEditor: {
    margin: "10px 0",
    maxHeight: theme.spacing(6),
    borderBottom: "1px solid",
    "& .ql-container": {
      "& .ql-editor": {
        backgroundColor: "#fff",
        paddingTop: "6px",
      },
      "& .ql-tooltip": {
        display: "none",
      },
    },
  },
  subCommentSection: {
    backgroundColor: "#f6f7f8",
    padding: "16px 20px",
    borderRadius: "8px",
    width: "100%",
  },
  comments: {
    "&.mainComments, &.subComments": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexFlow: "column",
      },
    },
    "&.subComments": {
      "& + &": {
        marginTop: 5,
        paddingTop: 15,
        borderTop: "solid 1px #e2e2e2;",
      },
    },
    "& .mainCommentsContainer": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "20px",
        width: "100%",
      },
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  textField: {
    marginBottom: theme.spacing(3),
    // maxHeight: theme.spacing(6),
    "& .MuiInputBase-input": {
      padding: "14px 0",
      fontSize: "12px",
      color: "#758194",
    },
  },
}));
const limit = 5;
let totalCount = 0;
const VurbComment = (props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [commentDatas, setCommentDatas] = React.useState([]);
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  const [skip, setSkip] = React.useState(0);
  const [loading, setLoader] = React.useState(true);
  useEffect(() => {
    // console.log(props.commentDataList)
    let commentDataList = props.commentDataList;
    if (
      !!commentDataList  &&
      commentDataList.parent_comment_id === null
    ) {
      let arraylist = [...commentDatas];
      let index = arraylist.findIndex((a) => a._id === commentDataList._id);
      if (index < 0) {
        arraylist.unshift({ ...commentDataList, child_comments: [] });
        setCommentDatas(arraylist);
      }
    }
    // Socket sub comment update code
    //   else{
    //     if (commentDataList&&commentDataList.length > 0&&commentDataList[0].parent_comment_id!==null) {
    //         let arraylist = [...commentDatas],index;

    //         index=arraylist.findIndex(val=>val._id===commentDataList[0].parent_comment_id)
    //         console.log('index',index)
    //         if(index!==-1){
    //             let subcomments=[...arraylist[index].child_comments]
    //             subcomments.push(commentDataList[0]);
    //             console.log('subcomments',subcomments)
    //             console.log()
    //             // arraylist.splice(index,1,{...arraylist[index],child_comments:subcomments})
    //             setCommentDatas(_.set(arraylist,`[${index}.child_comments]`,subcomments));
    //         }
    //     }
  }, [
    props.reload || (props.commentDataList && props.commentDataList.length > 0),
  ]);

  useEffect(() => {
    postComment("");
  }, []);
  const addComment = async (obj) => {
    props.UpdateComment({
      inputObject: {
        vurb_id: props.vurbId,
        device: "web",
        comment: obj.content,
        user_id: props.user._id,
        parent_comment_id: null,
        gif: obj.gif,
        video: obj.video,
        mentionIds: obj.mentionIds,
      },
    });
    setSkip(skip + 1)
    props.onSuccess("Commented Successfully");
    props.vurbObject.user_id._id !== props.user._id && setOpenDialog(true);
  };

  const postComment = (id, skipCount = 0) => {
    var VurbDetails = API_Path + "vurbs/comments/list";
    let inputObj = {
      inputObject: {
        vurb_id: props.vurbId,
        parent_comment_id: id ? id : null,
        limit: limit,
        skip: skipCount
      },
    };
    axios
      .post(VurbDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        const { data: { result = [], totalCount: totalRecord } } = response;
        // console.log('response',response)
        (skipCount === 0) ? setCommentDatas(result) : setCommentDatas(p => [...p, ...result]);
        totalCount = totalRecord;
        setSkip(skipCount + limit);
        setLoader(false);
        // setCommentDatas(result);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const onDelete = (index) => {
    let tempPost = [...commentDatas];
    tempPost.splice(index, 1);
    setCommentDatas(tempPost);
  };
  const observer = useRef()
  const lastCommentElement = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (totalCount > commentDatas.length) {
          setLoader(true)
          postComment('', skip)
        }
      }
    })
    if (node) observer.current.observe(node)
  })
  const reloadComments = (type, index, data) => {
    let tempCommentList = [...commentDatas]
    if (type === 'Delete') {
      tempCommentList.splice(index, 1);
      setCommentDatas(tempCommentList)
      setSkip(skip - 1)
    } else if(type === 'Edit'){
      let editedComment = {...tempCommentList[index],video:data.video,gif:data.gif,comment:data.comment}
      tempCommentList.splice(index,1,editedComment);
      setCommentDatas(tempCommentList)
    } else if(type ==="Add") {
      let editedComment = tempCommentList[index]
      editedComment.comments_count += 1;
      tempCommentList.splice(index,1,editedComment)
      setCommentDatas(tempCommentList)
    }else if(type ==="Like"){
      let editedComment = {...tempCommentList[index],dislike_users:data.dislike_users,like_users:data.like_users}
      tempCommentList.splice(index,1,editedComment)
      setCommentDatas(tempCommentList)
    }else if(type ==="UpdateChild"){
      let editedComment = {...tempCommentList[index],comments_count:data}
      tempCommentList.splice(index,1,editedComment)
      setCommentDatas(tempCommentList)
    }else{
      console.log('else')
    }
  }
  let commentList = commentDatas.length > 0 ? commentDatas : [];
  let fullName =
    props.vurbObject &&
    props.vurbObject.user_id &&
    props.vurbObject.user_id.full_name &&
    props.vurbObject.user_id.full_name;
  let { vurbObject: { comment_show } = {} } = props;
  return (
    <React.Fragment>
      <div>
        <div className={classes.flexJCSB} style={{ alignItems: "center" }}>
          <h4 className="m-0">Comments</h4>
        </div>
        {comment_show === true &&
          <CommentEditor user={props.user} auth={props.auth} result={addComment} setLoader={props.setLoader} setSnackbarState={setSnackbarState} />
        }</div>

      {commentList && commentList.length > 0 ? (
        <div>
          {commentList.map((commentObj, i) => {
            return (
              <VurbCommentList
                setLoader={props.setLoader}
                key={i}
                commentObj={commentObj}
                index={i}
                reloadComments={reloadComments}
                UpdateComment={props.UpdateComment}
                childSocketComment={props.commentDataList}
                delete={onDelete}
                comment_show={comment_show}
                creator={props.creator}
                ref={commentList.length - 1 === i ? lastCommentElement : null}
              />
            );
          })}
        </div>
      ) : (
        <Box mt={3}>
          <Typography align="center" variant="h6">
            {(!comment_show) ? "Comments are turned off" : !loading ? "No Comments Available" : ''}
          </Typography>
        </Box>
      )}
      {loading && <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}
      <ConfirmationModal
        openDialog={openDialog}
        modalHeading="Debate invite"
        handleDialogClose={handleDialogClose}
        handleDelete={() => props.createDebate(true)}
        modalMessage={`Would you like to send a debate invite to ${fullName}?`}
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default connect(mapStateToProps)(VurbComment);
