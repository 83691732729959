import React, { useRef, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import VDotsMenu from "../../../assets/images/VDotsMenu.svg";
import { withRouter } from "react-router-dom";
import { API_Path } from "../../Common/Const/index";
import axios, { CancelToken, isCancel } from "axios";
import { connect } from "react-redux";
import SnackBar from "../../../components/SnackBar";
import { Box, Button, Hidden } from "@material-ui/core";
import ConfirmationModal from "../ConfirmationModal";
import CopyLink from "../CopyLink";
import DebateStatus from "../CustomDebateHeaderComponents/DebateStatus";
import DebateDetail from "../CustomDebateHeaderComponents/DebateDetail";
import DebateDMY from "../CustomDebateHeaderComponents/DebateDMY";
import CustomSocialFab from "../CustomSocialFab";
import squareShare from "../../../assets/images/actionIcons/squareShare.svg";
import Facebook from "../../../assets/images/socialIcons/facebook.svg";
import Twitter from "../../../assets/images/socialIcons/twitter.svg";
import Linkedin from "../../../assets/images/socialIcons/linkedin.svg";
import Whatsapp from "../../../assets/images/socialIcons/whatsapp.svg";
import Loader from "../Loader";
import ReportAbuse from "../ReportAbuse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Report from "../ReportAbuse/Report";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexFlow: "row",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
      position: "relative",
      "& > div": {
        display: "flex",
      },
    },
    "& .premiumUser": {
      right: "-18px",
      bottom: "-26px",
      position: "absolute",
      background: "#ffc003",
      padding: "5px 15px 5px 5px",
      fontSize: "12px",
      lineHeight: "1",
      fontWeight: "500",
      animation: `$evenEffect 2000ms infinite`,
      "&:before": {
        content: '""',
        position: "absolute",
        left: "-22px",
        top: 0,
        border: "11px solid #ffc003",
        borderLeft: "11px solid transparent",
      },
    },
  },
  dateMonthField: {
    [theme.breakpoints.up("sm")]: {
      borderRight: "solid 1px #cdd1d8",
      marginRight: "24px",
      paddingRight: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
  },

  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerActionSection: {
    display: "flex",
    flexFlow: "column",
    width: "105px",
    maxWidth: "105px",
    flex: "0 0 105px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
  },
  downloadSection: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    background: "rgba(255, 255, 255, .95)",
    zIndex: "1",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  "@keyframes evenEffect": {
    "0%": {
      padding: "5px 15px 5px 5px",
    },
    "50%": {
      padding: "5px 25px 5px 5px",
    },
    "100%": {
      padding: "5px 15px 5px 5px",
    },
  },
}));
const CustomDebateHeader = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  const anchorRef = React.useRef(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [enableDownload, setEnableDownload] = useState(false);
  const cancelFileUpload = useRef(null);
  const path = props.debateDetail && props.debateDetail.share_link;

  const actions = [
    {
      icon: <img alt="" src={Facebook} />,
      name: "Facebook",
      href:
        "http://www.facebook.com/sharer/sharer.php?s=100&p[url]=" +
        encodeURIComponent(path),
    },
    {
      icon: <img alt="" src={Twitter} />,
      name: "Twitter",
      href: "https://twitter.com/intent/tweet?url=" + encodeURIComponent(path),
    },
    {
      icon: <img alt="" src={Linkedin} />,
      name: "Linkedin",
      href:
        "https://www.linkedin.com/cws/share?url=" + encodeURIComponent(path),
    },
    {
      icon: <img alt="" src={Whatsapp} />,
      name: "Whatsapp",
      href: "whatsapp://send?text=" + path,
    },
  ];
  const handleClickOpen = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  // const handleToggle = () => {
  //     setOpen((prevOpen) => !prevOpen);
  // };

  // const handleClose = (event) => {
  //     if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //         return;
  //     }
  //     setOpen(false);
  // };
  const handleButtonCancel = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("Download Canceled");
      setProgressValue(0);
      setEnableDownload(false);
    }
  };
  const handleSaveVideo = async (val) => {
    setAnchorEl(null);
    setOpen(false);
    setEnableDownload(true);
    await axios({
      url: API_Path + "api/debate/downloadDebateVideo?debateId=" + val._id, //your url
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + props.auth.accessToken,
      },
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setProgressValue(percentCompleted);
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    })
      .then((response) => {
        if (response !== null && response.data !== null) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          // link.setAttribute('download', new Date().getTime() + '.mp4'); //or any other extension
          link.setAttribute("download", val.title + ".mp4");
          document.body.appendChild(link);
          link.click();
          setSnackbarState({
            open: true,
            message: "File downloaded",
            severity: "Success",
          });
          setEnableDownload(false);
        } else {
          setSnackbarState({
            open: true,
            message: "Error in file download",
            severity: "error",
          });
          setEnableDownload(false);
        }
      })
      .catch((error) => {
        if (isCancel(error)) {
          setSnackbarState({
            open: true,
            message: error.message,
            severity: "error",
          });
          setLoader(false);
        } else {
          setSnackbarState({
            open: true,
            message: "Error in file download",
            severity: "error",
          });
          setLoader(false);
        }
        console.error(error);
      });

    // .get(API_Path + "api/debate/downloadDebateVideo?debateId="+val, {
    //     headers: {
    //         'Authorization': "Basic " + props.auth.accessToken
    //     }
    // })
    // .then((response) => {
    //     window.open(response, 'Download')
    // })
    // .catch((error) => {
    //     console.error(error);
    // });
  };
  const handleJoin = (val) => {
    window.open(val, "_blank");
    setOpen(false);
    setAnchorEl(null);
  };
  // function handleListKeyDown(event) {
  //     if (event.key === 'Tab') {
  //         event.preventDefault();
  //         setOpen(false);
  //     }
  // }
  const handleEdit = () => {
    setAnchorEl(null);
    props.editDebate();
  };
  const handleReportItem = () => {
    setAnchorEl(null);
    // setOpen(false);
  };
  const handleReportOption = () => {
    setAnchorEl(null);
  };
  const handleSnackbarReport = () => {
    setSnackbarState({
      open: true,
      message: "Reported Successfully",
      severity: "success",
    });
  };
  const handleDelete = () => {
    var Deletedebates = API_Path + "debates/delete/" + props.debateDetail._id;
    axios
      .delete(Deletedebates, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setOpen(false);
          props.getDebateDetails();
          setOpenDialog(false);
          props.handleDeleteDebate();
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "success",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  // let time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  // const mydebateObj = {
  //     debateEdit: true,
  //     debateObj: props.debateDetail
  // }
  const handleCopyLink = () => {
    setAnchorEl(null);
    setSnackbarState({
      open: true,
      message: "Link Copied!",
      severity: "success",
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    // console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const actionSectionHeader = (
    <div className={classes.headerActionSection}>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img alt="" src={VDotsMenu} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props &&
            props.debateDetail &&
            props.debateDetail.created_by &&
            props.debateDetail.created_by._id === props.user._id &&
            props.debateDetail &&
            (props.debateDetail.status === 0 ||
              props.debateDetail.status === 5) && (
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
            )}
          {props &&
            props.debateDetail &&
            props.debateDetail.created_by &&
            props.debateDetail.created_by._id === props.user._id &&
            props.debateDetail && (
              <MenuItem onClick={handleClickOpen}>Delete</MenuItem>
            )}

          {props.debateDetail &&
            props.debateDetail.is_blocked !== 1 &&
            (props.debateDetail.status === 0 ||
              (props.debateDetail && props.debateDetail.status === 2)) && (
              <MenuItem
                onClick={handleJoin.bind(
                  this,
                  props.debateDetail && props.debateDetail.share_link
                )}
              >
                View
              </MenuItem>
            )}

          {props.debateDetail &&
            props.debateDetail.status === 2 &&
            props.debateDetail.video_history_url !== null && (
              <MenuItem
                onClick={handleSaveVideo.bind(this, props.debateDetail)}
              >
                Save Video
              </MenuItem>
            )}
          <CopyLink
            field="text"
            value={props.debateDetail && props.debateDetail.share_link}
            handleCopyLink={handleCopyLink}
          />
          {props.debateDetail &&
            (props.debateDetail.status === 1 ||
              (props.debateDetail && props.debateDetail.status === 2)) && (props.debateDetail.created_by._id !== props.user._id) && (
              <ReportAbuse
                listItem="listItem"
                debateDetail={props.debateDetail}
                handleReportItem={handleReportItem}
              />
            )}
          {props.debateDetail &&
            (props.debateDetail.status !== 1 ||
              props.debateDetail.status !== 5) && (props.debateDetail.created_by._id !== props.user._id) && (
              <Report
                showType="Menu"
                type="Reporting Debate"
                primaryKey={props.debateDetail && props.debateDetail._id}
                handleReportOption={handleReportOption}
                handleSnackbarReport={handleSnackbarReport}
              />
            )}
        </Menu>
        {props.showOnLive && (
          <CustomSocialFab
            actions={actions}
            direction="down"
            icon={squareShare}
            debateId={props.debateDetail && props.debateDetail._id}
          />
        )}
      </Box>
      <DebateStatus
        debateBlocked={props.debateDetail && props.debateDetail.is_blocked}
        debateStatusList={props.debateDetail && props.debateDetail.status}
      />
    </div>
  );

  return (
    <>
      {enableDownload === true ? (
        <Box className={classes.downloadSection}>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              size={80}
              value={progressValue}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <h3>{progressValue}</h3>
            </Box>
          </Box>
          <p>Video Downloading...</p>
          <Button
            variant="contained"
            color="primary"
            className={classes.circleBtn}
            onClick={handleButtonCancel}
          >
            Cancel Download
          </Button>
        </Box>
      ) : (
        ""
      )}
      <div className={classes.headerContainer}>
        {props.debateDetail && props.debateDetail.sort_number > 0 && (
          <div className="premiumUser">Boosted</div>
        )}
        <div>
          <div className={classes.dateMonthField}>
            <DebateDMY listDMY={props} normalDMY={true} />
            <Hidden smUp>{actionSectionHeader}</Hidden>
          </div>
        </div>
        <Box
          className={classes.flexJCSB}
          width={{ xs: "100%", sm: "calc(100% - 95px)" }}
        >
          <DebateDetail debateDetailValues={props} />
          <Hidden xsDown>{actionSectionHeader}</Hidden>
        </Box>
        <SnackBar
          open={snackbarState.open}
          message={snackbarState.message}
          severity={snackbarState.severity}
          onClose={onClose}
        />
        <ConfirmationModal
          openDialog={openDialog}
          modalHeading="Message"
          handleDialogClose={handleDialogClose}
          handleDelete={handleDelete}
          modalMessage="Are you sure to delete the debate?"
          btnCancelName="No"
          btnNoConfirmName="Yes"
          maxWidth="xs"
        />
        {loader && <Loader />}
      </div>
    </>
  );
};

// export default withRouter()
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(CustomDebateHeader));
