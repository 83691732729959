import { IconButton, Tooltip } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  copyClipboard: {
    opacity: 0,
    height: 0,
    width: 10,
    padding: 0,
    position: "absolute",
    zIndex: -1,
  },
}));
const CopyLink = (props) => {
  const classes = useStyles();
  const myRef = useRef(null);
  //const [snackbarState, setSnackbarState] = useState({ open: false, })
  const copyClipboard = () => {
    var copyText = myRef.current;
    copyText.select();
    document.execCommand("copy");
    props.handleCopyLink();
  };

  return (
    <>
      <input
        ref={myRef}
        defaultValue={props.value}
        className={classes.copyClipboard}
      />
      {props.field === "text" ? (
        <MenuItem onClick={copyClipboard}>Copy link</MenuItem>
      ) : (
        <Tooltip
          title="Copy"
          placement={props.tooltipPlacement === "bottom" ? "bottom" : "top"}
        >
          <IconButton aria-label="delete" size="small" onClick={copyClipboard}>
            <img src={props.icon} alt="" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default CopyLink;
