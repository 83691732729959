import { Box, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import imgSend from "../../../assets/images/Icon-feather-send.svg";
import { API_Path } from "../../Common/Const";
import CustomDropdown from "../../Common/CustomDropdown";
import ParentCommentList from "./ParentCommentList";

import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  commentSection: {
    marginTop: "20px",
  },
  subCommentAccordion: {
    backgroundColor: "#fff",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      margin: "0 7% 0 8%!important",
    },
    "&:before": {
      opacity: "0",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      justifyContent: "flex-start",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      flexGrow: "inherit",
      "& .hideAll": {
        display: "none",
      },
      "&.Mui-expanded": {
        "& .hideAll": {
          display: "block",
        },
        "& .viewAll": {
          display: "none",
        },
      },
      "& p": {
        color: "#758194",
        fontSize: "12px",
      },
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0",
    },
  },
  subCommentSection: {
    backgroundColor: "#f6f7f8",
    padding: "16px 20px",
    borderRadius: "8px",
    width: "100%",
  },
  comments: {
    "&.mainComments, &.subComments": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexFlow: "column",
      },
    },
    "&.subComments": {
      "& + &": {
        marginTop: 5,
        paddingTop: 15,
        borderTop: "solid 1px #e2e2e2;",
      },
    },
    "& .mainCommentsContainer": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "20px",
        width: "100%",
      },
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    marginBottom: theme.spacing(3),
    // maxHeight: theme.spacing(6),
    "& .MuiInputBase-input": {
      padding: "14px 0",
      fontSize: "12px",
      color: "#758194",
    },
  },
}));

const CustomComments = (props) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [message, setMessage] = React.useState("");

  const [commentDatas, setCommentDatas] = React.useState([]);

  const handleMenuItemClick = (event, index, val) => {
    setSelectedIndex(index);
  };
  useEffect(() => {
    postComment("");
  },[]);
  const addComment = async (parentId, type) => {
    var DebatesDetails = API_Path + "api/comments/add";
    let inputObj = {
      inputObject: {
        debate_id: props.debateObject._id,
        comments: message,
        created_by: props.user._id,
        parent_comment_id: parentId ? parentId : null,
      },
    };
    axios
      .post(DebatesDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then(async (response) => {
        setMessage("");
        await postComment(parentId ? parentId : "");
        // await postComment("");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const postComment = (id) => {
    var DebatesDetails = API_Path + "api/comments/list";
    let inputObj = {
      inputObject: {
        debate_id: props.debateObject._id,
        parent_comment_id: id ? id : null,
      },
    };
    axios
      .post(DebatesDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        // if (id === "") {
        setCommentDatas(response.data.result ? response.data.result : []);
        // } else {
        //     setChildCommentsList(response.data.result ? response.data.result : [])
        // }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const onPressSend = () => {
    if (message !== "") {
      addComment("", "PrimaryCmt");
    }
  };

  // const onEnterPress = (e) => {
  //     if (e.keyCode === 13 && e.shiftKey === false) {
  //         if (e.target.value !== "") {
  //             addComment("", "PrimaryCmt");
  //             //postComment(null);
  //         }
  //     }
  // }

  // const onEnterReply = async (list, e) => {
  //     if (e.keyCode === 13 && e.shiftKey === false) {
  //         if (e.target.value !== "") {
  //             //postComment(list._id);
  //             await addComment(list._id, "ReplyCmt");
  //             postComment(null);
  //         }
  //     }
  // }

  // let sortedList = _.sortBy(object,[function(o) { return o.obj; }]);
  // console.log("commentDatas",commentDatas);
  let commentList = commentDatas;
  if (commentList && commentList.length > 0) {
    if (selectedIndex === 0) {
      commentList = _.orderBy(commentDatas, ["created_at"], ["desc"]);
    } else if (selectedIndex === 1) {
      commentList = _.orderBy(commentDatas, ["like"], ["desc"]);
    }
  }
  return (
    <React.Fragment>
      <div className={classes.flexJCSB} style={{ alignItems: "center" }}>
        <h4 className="m-0">Comments</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h5 className="m-0" style={{ marginRight: "15px" }}>
            Sort by
          </h5>
          <CustomDropdown
            options={props.options}
            selectedIndex={selectedIndex}
            handleMenuItemClick={handleMenuItemClick}
          />
        </div>
      </div>
      {/* <TextField id="standard-basic" label="" placeholder="Type your comments" fullWidth className={classes.textField}
                    onChange={handleChange} onKeyDown={onEnterPress} value={message} /> */}
      <Box display="flex" alignItems="center">
        <TextField
          multiline
          id="standard-basic"
          label=""
          placeholder="Type your comments"
          fullWidth
          className={classes.textField}
          onChange={handleChange}
          value={message}
        />
        <IconButton>
          <img
            alt=""
            src={imgSend}
            onClick={onPressSend}
            style={{ cursor: "pointer" }}
          />
        </IconButton>
      </Box>
      {commentList && commentList.length > 0 ? (
        <div>
          {commentList.map((commentObj, i) => {
            return (
              <ParentCommentList
                key={i}
                debateObject={props.debateObject}
                commentObj={commentObj}
                index={i}
                reloadComments={postComment}
              />
            );
          })}
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default connect(mapStateToProps)(CustomComments);
