import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { NavLink } from "react-router-dom";
//import ChatBubble from "../../screens/Common/Chat/ChatBubble";

const useStyles = makeStyles((theme) => ({
  appFooter: {
    backgroundColor: "#f6f7f8",
    padding: "0px 46px 0px",
    position: "relative",
    zIndex: "1000",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 30px 0px",
    },
    "& .footerContainer": {
      padding: "16px 0px 26px",
      borderTop: "1px solid #cdd1d8",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexFlow: "column",
      },
    },
    "& .footerLink": {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& a": {
        fontSize: "12px",
        color: "#758194",
        padding: "0 10px",
        borderRight: "1px solid #758194",
        textDecoration: "none",
        "&.active": {
          color: "#313236",
        },
        "&:last-child": {
          paddingRight: "0",
          borderRight: "0",
        },
      },
    },
    "& p": {
      margin: "0",
      fontSize: "12px",
      color: "#313236",
    },
  },
}));

const AppFooter = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.appFooter}>
        <div className="footerContainer">
          <p>
            © {new Date().getFullYear()} Vurbil. All rights reserved.
            Patent Pending
          </p>
          <div className="footerLink">
            <NavLink
              to="/privacyPolicy"
              target="_blank"
              activeClassName="active"
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/termsAndConditions"
              target="_blank"
              activeClassName="active"
            >
              Terms of Service
            </NavLink>
            <NavLink to="/aboutUs" target="_blank" activeClassName="active">
              About Us
            </NavLink>
            <NavLink to="/contactUs" target="_blank" activeClassName="active">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
      {/* <ChatBubble /> */}
    </>
  );
};

export default AppFooter;
