import React, { useState,forwardRef } from "react";
import { Box, IconButton, Menu, MenuItem, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_Path } from "../../Common/Const";
import LikeSmall from "../../../assets/images/likeSmall.svg";
import DislikeSmall from "../../../assets/images/dislikeSmall.svg";
import ReplySmall from "../../../assets/images/replyAllSmall.svg";
import CustomImage from "../CustomImage";
import Report from "../ReportAbuse/Report";
import VurbChildComment from "./VurbChildComment";
import ConfirmationModal from "../ConfirmationModal";
import CommentEditor from "./CommentEditor";
import _ from "lodash";
import SnackBar from "../../../components/SnackBar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReactPlayer from "react-player";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import { numberFormatter } from "../../Common/Const";

const useStyles = makeStyles((theme) => ({
  commentSection: {
    marginTop: "20px",
  },
  videoModal: {
    width: "50%",
  },
  paperPostInput: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.13)",
    borderRadius: "12px",
    backgroundColor: "#fff",
    margin: "20px 0",
    position: "relative",
    display: "flex",
    padding: "10px",
  },
  subCommentAccordion: {
    backgroundColor: "#fff",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      margin: "0 7% 0 8%!important",
    },
    "&:before": {
      opacity: "0",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      justifyContent: "flex-start",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      flexGrow: "inherit",
      "& .hideAll": {
        display: "none",
      },
      "&.Mui-expanded": {
        "& .hideAll": {
          display: "block",
        },
        "& .viewAll": {
          display: "none",
        },
      },
      "& p": {
        color: "#758194",
        fontSize: "12px",
      },
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0",
    },
  },
  headerActionSection: {
    display: "flex",
    flexFlow: "column",
    flex: "0 0 105px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
  },
  subCommentSection: {
    backgroundColor: "#f6f7f8",
    padding: "16px 20px",
    borderRadius: "8px",
    width: "100%",
  },
  comments: {
    "&.mainComments, &.subComments": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexFlow: "column",
      },
    },
    "&.subComments": {
      "& + &": {
        marginTop: 5,
        paddingTop: 15,
        borderTop: "solid 1px #e2e2e2;",
      },
    },
    "& .mainCommentsContainer": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "20px",
        width: "100%",
      },
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  quillEditor: {
    margin: "10px 0",
    maxHeight: theme.spacing(6),
    borderBottom: "1px solid",
    "& .ql-container": {
      "& .ql-editor": {
        backgroundColor: "#fff",
        paddingTop: "6px",
      },
      "& .ql-tooltip": {
        display: "none",
      },
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
    maxHeight: theme.spacing(6),
    "& .MuiInputBase-input": {
      padding: "14px 0",
      fontSize: "12px",
      color: "#758194",
    },
  },
  disabled: {
    opacity: ".5",
    pointerEvents: "none",
    "& svg": {
      fontSize: "20px",
    },
  },
  replayText: {
    display: "flex",
    "& .quill": {
      width: "100%",
    },
  },
  report: {
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
    cursor: "pointer",
    "& svg": {
      fontSize: "20px",
    },
  },
  activeStatus: {
    position: "absolute",
    right: "8px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "16px",
    },
  },
}));

const VurbCommentList = forwardRef((props, ref) => {
// const VurbCommentList = (props) => {
  const classes = useStyles();
  const {reloadComments} = props
  // const editerRef = useRef()
  const [showReplyField, setShowReplyField] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [indexVal, childIndex] = useState(-1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setDeleteDialog] = useState(false);
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const {sliderImage} = props
  // const config = {
  //   bucketName: "vurbs-images",
  //   region: "us-west-2",
  //   accessKeyId: "AKIASHCVMOE6WD2T4P4H",
  //   secretAccessKey: "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",
  // };
  const like =
    props.commentObj && props.commentObj.like?props.commentObj.like:
    props.commentObj.like_users !== null &&
    props.commentObj.like_users.length > 0
      ? props.commentObj.like_users.length
      : "0";
  const disLike =
    props.commentObj &&
    props.commentObj.dislike_users !== null &&
    props.commentObj.dislike_users.length > 0
      ? props.commentObj.dislike_users.length
      : "0";
  const handleReply = (index) => {
    setShowReplyField(!showReplyField);
    childIndex(Math.random());
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const addComment = async (obj) => {
    let api= !!sliderImage ? "api/vurb/img/comment":"api/vurbs/comments/add";
    var VurbDetails = API_Path + api  ;
    let inputObj = {
      inputObject: {
        vurb_id: props.commentObj.vurb_id,
        comment: obj.content,
        user_id: props.user._id,
        parent_comment_id: props.commentObj._id,    
        gif: obj.gif,
        video:obj.video,
        mentionIds: obj.mentionIds,
        device: "web",
      },
    };
    if(!!sliderImage){
      inputObj.inputObject['vurbImage_id']=props.vurb_imgs && (props.vurb_imgs._id || null)
    }
    axios
      .post(VurbDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then(async (response) => {
        // console.log(response)
        setSnackbarState({
          open: true,
          message: "Commented Successfully",
          severity: "success",
        });
        // setOpenDialog(true);
        setShowReplyField(false);
        reloadComments("Add",props.index,response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleEdit = async (obj) => {
    let EditApi = API_Path + `vurbs/comments/update/${props.commentObj._id}`;
    let inputObj = {
        "inputObject":{
            vurb_id: props.commentObj.vurb_id,
            comment: obj.content,
            _id:props.commentObj._id,
            parent_comment_id: null,
            gif: obj.gif,
            video:obj.video,
            mentionIds: obj.mentionIds,
            device: "web",
            
        }
    }
    await axios.put(EditApi, inputObj,{
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data && response.data.status === "SUCCESS") {
          reloadComments("Edit",props.index,response.data.result);
          setEdit(false);
          setSnackbarState({
            open: true,
            message: "Edited Successfully",
            severity: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getupordownvotes = async (type) => {
    let likeAPI = API_Path + "vurbs/comments/likes";

    let inputObj = {
      inputObject: {
        userId: props.user._id,
        commentId: props.commentObj._id,
        type: type,
      },
    };
    axios
      .post(likeAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        // getDebateList();
        // childRating === true ? reloadComments(commentId) : reloadComments("")
        if (response.data && response.data.status === "SUCCESS") {
          reloadComments("Like",props.index,response.data.result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  const clearEdit = () => {
    setEdit(false);
  };

  const onRedirect = (url) => {
    let link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };
  const handleLikeDislike = (val) => {
    getupordownvotes(val);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };
  const handleDelete = async () => {
    var deletePost = API_Path + "vurbs/comments/delete";
    let inputObj = {
      id: props.commentObj._id,
      parent_id: props.commentObj.parent_comment_id,
    };

    await axios
      .post(deletePost, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setOpenDialog(false);
          setSnackbarState({
            open: true,
            message: "Deleted Successfully",
            severity: "success",
          });
          reloadComments('Delete',props.index);
          setDeleteDialog(false);
          props.commentDelete && props.commentDelete("delete")
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleClickOpen = () => {
    setAnchorEl(null);
    setDeleteDialog(true);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const actionSectionHeader = (
    <div className={classes.headerActionSection} >
      <Box>
        <IconButton aria-controls="simple-menu" aria-haspopup="true"  onClick={handleClick}>
          <MoreVertIcon
            style={{ position: "absolute", top: "-3px" }}
           
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {!(props.commentObj.gif || props.commentObj.video) &&
            props.user._id === (props.commentObj.created_by && props.commentObj.created_by._id) && (
              <MenuItem
                onClick={() => {
                  setEdit(true);
                  setAnchorEl(null);
                }}
              >
                {" "}
                Edit
              </MenuItem>
            )}
          <MenuItem onClick={handleClickOpen}> Delete</MenuItem>
        </Menu>
      </Box>
    </div>
  );

  let commentDuration = "";
  if (props.commentObj && props.commentObj.created_at) {
    let newDate = new Date(props.commentObj.created_at);
    let eventDate = moment([
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
    ]).fromNow();
    // setCommentDuration(eventDate)
    commentDuration = eventDate;
  }

  let { created_by } = props.commentObj ? props.commentObj : {},
    Userurl =
      !!created_by && created_by._id
        ? `/friendsProfilePage/${created_by._id}`
        : "";
  let { comment_show } = props;
  return (
    <div key={props.index} className={classes.commentSection} ref={ref}>
      <div className={classes.comments + " mainComments"}>
        {/* <div style={{ width: '40px', height: '40px', flex: '0 0 40px', maxWidth: '40px', borderRadius: '50%', overflow: 'hidden' }}>
                    <img alt="" src={props.commentObj && props.commentObj.created_by && props.commentObj.created_by.profile_image} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div> */}
        <div style={{ position: "relative" }}>
          <CustomImage
            userImage={
              props.commentObj &&
              props.commentObj.created_by &&
              props.commentObj.created_by.profile_image
                ? props.commentObj &&
                  props.commentObj.created_by &&
                  props.commentObj.created_by.profile_image
                : ""
            }
            size="40px"
            marginRight="0"
            link={
              !!props.commentObj.created_by &&
              props.commentObj.created_by.active_status !== 4
                ? Userurl
                : null
            }
            onRedirect={onRedirect}
          />
          {!!props.commentObj.created_by &&
            !!props.commentObj.created_by.badge_status && (
              <span
                className={classes.activeStatus}
                title="Verified User/Public Figure"
              >
                <VerifiedUserRoundedIcon />
              </span>
            )}
        </div>
        <div className=" mainCommentsContainer">
          <div style={sliderImage ? { display: "flex", marginBottom: "4px", justifyContent:"space-between"}:{ display: "flex", marginBottom: "4px"}}>
            <div style={{ display: "flex" }}>
              <h5
                className="m-0"
                style={
                  !!props.commentObj.created_by &&
                  props.commentObj.created_by.active_status !== 4
                    ? {
                        cursor: "pointer",
                      }
                    : null
                }
                onClick={
                  !!props.commentObj.created_by
                    ? props.commentObj.created_by.active_status !== 4 &&
                      (() => onRedirect(Userurl))
                    : undefined
                }
              >
                {props.commentObj &&
                  props.commentObj.created_by &&
                  props.commentObj.created_by.full_name}
              </h5>
              {props.commentObj.created_by.user_tagging && (
                <span
                  style={{ fontWeight: "500", paddingLeft: "4px" }}
                  className="userTagging"
                >
                  @{_.get(props.commentObj, "created_by.user_tagging", "")}
                </span>
              )}
            </div>
            { !sliderImage && <h6
              className="m-0"
              style={{
                whiteSpace: "nowrap",
                fontWeight: "normal",
                color: "#758194",
                marginLeft: "auto",
              }}
            >
              {commentDuration}
            </h6>}
            <div>
              {(props.user._id === props.commentObj.created_by._id ||
                props.creator) &&
                actionSectionHeader}
            </div>
     
          </div>
        { sliderImage && <h6
              className="m-0"
              style={{
                whiteSpace: "nowrap",
                fontWeight: "normal",
                color: "#758194",
                marginLeft: "auto",
              }}
            >
              {commentDuration}
            </h6>}
          <div
            className="m-0"
            style={{
              fontSize: "14px",
              color: "#313236",
              wordBreak: "break-all",
            }}
           >
            {props.commentObj.is_blocked === 1 ? (
              <span>***</span>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: props.commentObj.comment }}
              ></div>
            )}
          </div>
         
          {edit && (
            <>
              <CommentEditor
                user={props.user}
                auth={props.auth}
                result={handleEdit}
                setLoader={props.setLoader}
                setSnackbarState={setSnackbarState}
                edit={props.commentObj.comment}
                slidercomment={sliderImage}
              />
              <Button onClick={clearEdit}>Cancel</Button>
            </>
          )}
          {props.commentObj.gif ? (
            <div>
              <img
                src={props.commentObj.gif}
                style={{ width: "140px", height: "105px" }}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
          {props.commentObj.video ? (
            <div style={{ position: "relative" }}>
              <ReactPlayer
                className="react-player"
                url={props.commentObj.video}
                width= {sliderImage ? "50%":"25%"}
                height="25%"
                controls
              />
            </div>
          ) : (
            " "
          )}
          <div style={{ display: "flex", margin: "8px 0" }}>
            {props.commentObj.created_by &&
            props.commentObj.created_by._id === props.user._id ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                  onClick={handleLikeDislike.bind(this, "like")}
                >
                  <img alt="" src={LikeSmall} />
                  <h6 className="m-0" style={{ fontWeight: "normal" }}>
                    {numberFormatter(like)}
                  </h6>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                  onClick={handleLikeDislike.bind(this, "dislike")}
                >
                  <img alt="" src={DislikeSmall} />
                  <h6 className="m-0" style={{ fontWeight: "normal" }}>
                    {numberFormatter(disLike)}
                  </h6>
                </div>
                { comment_show && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                    onClick={handleReply.bind(this, props.index)}
                  >
                    <img
                      alt=""
                      src={ReplySmall}
                      style={{ width: "20px", height: "20px" }}
                    />{" "}
                    <h6
                      className="m-0"
                      style={{ fontWeight: "normal", paddingLeft: "4px" }}
                    >
                      Reply
                    </h6>
                  </div>
                )}
                {/* <div
                  className={classes.disabled}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  <Report showType="Icon" type="Reporting Comment" />
                </div> */}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                  onClick={handleLikeDislike.bind(this, "like")}
                >
                  <img alt="" src={LikeSmall} />
                  <h6 className="m-0" style={{ fontWeight: "normal" }}>
                    {like}
                  </h6>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                  onClick={handleLikeDislike.bind(this, "dislike")}
                >
                  <img alt="" src={DislikeSmall} />
                  <h6 className="m-0" style={{ fontWeight: "normal" }}>
                    {disLike}
                  </h6>
                </div>
                {comment_show && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                    onClick={handleReply.bind(this, props.index)}
                  >
                    <img
                      alt=""
                      src={ReplySmall}
                      style={{ width: "20px", height: "20px" }}
                    />{" "}
                    <h6
                      className="m-0"
                      style={{ fontWeight: "normal", paddingLeft: "4px" }}
                    >
                      Reply
                    </h6>
                  </div>
                )}
                <div className={classes.report}>
                  <Report
                    showType="Icon"
                    type="Reporting Comment"
                    primaryKey={props.commentObj._id}
                  />
                </div>
              </>
            )}
          </div>
          {showReplyField ?  (
            <>
              <CommentEditor
                slidercomment={sliderImage}
                user={props.user}
                auth={props.auth}
                result={addComment}
                setLoader={props.setLoader}
                setSnackbarState={setSnackbarState}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* // {props.commentObj !== null && */}
      {/* // props.commentObj.child_comments.length > 0 ? ( */}
      {props.commentObj !== null &&props.commentObj.comments_count > 0 ? (
        <VurbChildComment
        vurb_imgs={props.vurb_imgs}
         sliderImage={sliderImage}
         vurbImage_id={props.commentObj.vurbImage_id}
          child_comments_id={props.user._id}
          vurbId={props.commentObj.vurb_id}
          commentObj={props.commentObj}
          pIndex={props.index}
          indexVal={indexVal}
          reloadComments={reloadComments}
          creator={props.creator}
          setLoader={props.setLoader}
        />
      ) : (
        ""
      )}
      <ConfirmationModal
        openDialog={openDialog}
        modalHeading="Message"
        handleDialogClose={handleDialogClose}
        // handleDelete={handleDeleteAccount}
        modalMessage={`Would you like to send a debate invite to ${
          props.commentObj &&
          props.commentObj.created_by &&
          props.commentObj.created_by.full_name
        }?`}
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      <ConfirmationModal
        openDialog={openDeleteDialog}
        modalHeading="Message"
        handleDialogClose={handleDeleteDialogClose}
        handleDelete={handleDelete}
        modalMessage="Are you sure to delete the comment?"
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
    </div>
  );
});
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default connect(mapStateToProps,null,null,{forwardRef: true})(VurbCommentList);
// export default withRouter(connect(mapStateToProps,null,{forwardRef: true})(VurbCommentList));
