import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import maskImage from "../../../assets/images/profile/banner.png";

const useStyles = makeStyles((theme) => ({
  startIn: {
    color: "#000",
    backgroundImage:
      "url(" +
      maskImage +
      "), linear-gradient(to right, #2272bd 0%,#274688 100%)",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    borderRadius: "12px",
    margin: "30px 0 18px 0",
    padding: "36px 36px 16px",
    position: "relative",
    zIndex: "0",
    overflow: "hidden",
    "&:before": {
      content: '""',
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      backgroundColor: "#ffffff4d",
      zIndex: "-1",
    },
  },
  robotInfo: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
  },
  robotText: {
    "&.robotTextVideo": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const LiveScheduleBanner = (props) => {
  const classes = useStyles();
  const timerRef = useRef(null);
  const [timer, setTimer] = useState({
    day: "",
    hour: "",
    minute: "",
    second: "",
  });

  useEffect(() => {
    clearTimer(new Date(props.startTime));

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [props.startTime]);

  const clearTimer = (e) => {
    // // If you adjust it you should also need to
    // // adjust the Endtime formula we are about
    // // to code next
    // setTimer("00:00:10");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    if (new Date(props.startTime) > new Date()) {
      const intervalid = setInterval(() => {
        startTimer(e);
      }, 1000);
      timerRef.current = intervalid;
    }
  };
  const startTimer = (e) => {
    let { total, days, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer({
        day: days > 9 ? days : "0" + days,
        hour: hours > 9 ? hours : "0" + hours,
        minute: minutes > 9 ? minutes : "0" + minutes,
        second: seconds > 9 ? seconds : "0" + seconds,
      });
    }
  };
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const days = Math.floor(total / (24 * 60 * 60 * 1000));
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  return (
    <div className={classes.startIn}>
      <Grid container direction="column" alignItems="center">
        <h4 className="m-0 font-weight-normal" style={{ marginBottom: "20px" }}>
          Live Will Start In
        </h4>
        <Box mb={1.5} width="100%">
          <Grid container justify="center" spacing={5}>
            <Grid item>
              <h1 className="m-0" style={{ textAlign: "center" }}>
                {timer.day || "00"}{" "}
              </h1>
              <h4 className="m-0 font-weight-normal">
                {timer.day === 1 ? "Day" : "Days"}
              </h4>
            </Grid>
            <Grid item>
              <h1 className="m-0" style={{ textAlign: "center" }}>
                {timer.hour || "00"}{" "}
              </h1>
              <h4 className="m-0 font-weight-normal">
                {timer.hour === 1 ? "Hour" : "Hours"}
              </h4>
            </Grid>
            <Grid item>
              <h1 className="m-0" style={{ textAlign: "center" }}>
                {timer.minute || "00"}{" "}
              </h1>
              <h4 className="m-0 font-weight-normal">
                {timer.minute === 1 ? "Minute" : "Minutes"}
              </h4>
            </Grid>
            <Grid item>
              <h1 className="m-0" style={{ textAlign: "center" }}>
                {timer.second || "00"}{" "}
              </h1>
              <h4 className="m-0 font-weight-normal">
                {timer.second === 1 ? "Second" : "Seconds"}
              </h4>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box className={classes.robotInfo}>
        <p
          style={{
            fontSize: "11px",
            color: "#00000080",
            margin: "0",
          }}
        >
          Note: Please prepare camera. headset or microphone.
        </p>
      </Box>
    </div>
  );
};

export default LiveScheduleBanner;
