import React from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import BadgeImage from '../../../assets/images/filled.svg'
import Bookmark from '../../../assets/images/bookmark.svg'
import ConfirmationModal from '../ConfirmationModal'
import { API_Path } from '../../Common/Const/index';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import ActivePlanIcon from "../../../assets/images/Other-active-plans.svg";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
    dropdownMenuSelect: {
        display: 'flex',
        alignItems: 'center',
        "& img": {
            width: '18px',
            margin: '-5px 5px 0 0'
        },
        "& h5": {
            color: '#313236',
            margin: '0',
        },
        "& h6": {
            // color: '#758194',
            fontWeight: 'normal',
            lineHeight: '1',
            margin: '0',
        }
    },
    headingPopup: {
        fontSize: '20px',
        color: '#313236',
        margin: '0 0 12px 0',
    },
}))

const OtherActivePlans = (props) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [currentPurchasedPlans, setCurrentPurchasedPlans] = React.useState([]);
    const [upcomingPurchasedPlans, setUpcomingPurchasedPlans] = React.useState([]);
    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    const activePlanDialog = () => {
        handlePayment()
        setOpenDialog(true);
    }
    
    const handlePayment = () => {
        var ClearNotification = API_Path + "api/paymenthistory/activeandfutureplans";
        let inputObj = {
            "inputObject": {
                userId: props.user._id,
            }
        };
        axios
            .post(ClearNotification, inputObj, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                setCurrentPurchasedPlans(response.data.result[0].current_purchased_plans);
                setUpcomingPurchasedPlans(response.data.result[0].upcoming_purchased_plans)
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const otherActivePlansContent = (
        <>
            {currentPurchasedPlans.length > 0 ?
                <Box>
                    <h4 className={classes.headingPopup}>Active Plan</h4>
                    <Box display="flex" flexWrap="wrap" m={-1}>
                        {currentPurchasedPlans.map((currentPurchasedPlan, index) => {
                            return (
                                <Box key={index} className={classes.dropdownMenuSelect} width={{xs: '100%', sm: '50%'}} p={1}>
                                    <img src={BadgeImage} alt="" />
                                    <div>
                                        <h5>{currentPurchasedPlan.package_name === 'Ad free payment' ? 'Ad Free Subscription' : currentPurchasedPlan.package_name}</h5>
                                        <h6>
                                            Expires {moment(currentPurchasedPlan.expiry_date).fromNow()}
                                        </h6>
                                    </div>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                :
                <p style={{ textAlign: 'center' }}>No active plans available</p>
            }
            <Box style={{ borderTop: '1px solid #dfdfdf' }} my={2}></Box>
            {upcomingPurchasedPlans.length > 0 ?
                <Box>
                    <h4 className={classes.headingPopup}>Upcoming Plans</h4>
                    <Box display="flex" flexWrap="wrap" m={-1}>
                        {upcomingPurchasedPlans.map((upcomingPurchasedPlan, index) => {
                            return (
                                <Box key={index} className={classes.dropdownMenuSelect} width={{xs: '100%', sm: '50%'}} p={1}>
                                    <img src={Bookmark} alt="" />
                                    <div>
                                        <h5>{upcomingPurchasedPlan.package_name === 'Ad free payment' ? 'Ad Free Subscription' : upcomingPurchasedPlan.package_name}</h5>
                                        <h6>
                                            Start on {moment(upcomingPurchasedPlan.start_date).format("DD-MMM-YYYY")}
                                    </h6>
                                    </div>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                :
                <p style={{ textAlign: 'center' }}>No upcoming plans available</p>
            }

        </>
    )
    return (
        <>
            <Button style={{fontSize:"11px",fontFamily:"Poppins",fontWeight:"500",paddingRight:"5px"}} onClick={activePlanDialog}><img src={ActivePlanIcon} alt="" style={{ marginRight: '9px',width:"14px" }} /> Other Active Plans</Button>
            <ConfirmationModal openDialog={openDialog} modalHeading="" handleDialogClose={handleDialogClose} handleDelete="" modalContent={true} modalMessage={otherActivePlansContent}
                dialogButtons={false} maxWidth="xs" />
        </>
    )
}

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(OtherActivePlans));
// export default OtherActivePlans
