import React from 'react'
import LikeSmall from '../../../assets/images/likeSmall.svg';
import DislikeSmall from '../../../assets/images/dislikeSmall.svg';
import ReplySmall from '../../../assets/images/replyAllSmall.svg';
import axios from "axios";
import { API_Path,numberFormatter } from "../../Common/Const";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Report from '../ReportAbuse/Report';

const useStyles = makeStyles((theme) => ({
    disabled: {
        opacity: '.5',
        pointerEvents: 'none',
    }
}))

const LikeDislikeReply = ({ ChildCommentList, commentId, like, disLike, handleReply, reloadComments, commentCreatedById, ...props }) => {
    const classes = useStyles();
    const [childRating, setChildRating] = React.useState(false);
    const [showReplyField, setShowReplyField] = React.useState(true);

    React.useEffect(() => {
        setChildRating(props.childRating);
    }, [props.childRating]);

    React.useEffect(() => {
        setShowReplyField(!props.showReplyField);
    }, [props.showReplyField])

    const getupordownvotes = async (type) => {

        let activeDebateRmoveAPI = API_Path + "api/comments/likes";

        let inputObj = {
            "inputObject": {
                userId: props.user._id,
                commentId: commentId,
                type: type
            }
        };
        axios
            .post(activeDebateRmoveAPI, inputObj, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                // getDebateList();
                childRating === true ? reloadComments(commentId) : reloadComments("")

            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleLikeDislike = (val) => { 
        getupordownvotes(val)
    }

    return (
        <div style={{ display: 'flex', margin: '8px 0' }}>
            {commentCreatedById === props.user._id ?
                <>
                
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px' , cursor: 'pointer' }} onClick={handleLikeDislike.bind(this, "like")}>
                        <img alt="" src={LikeSmall} />
                        <h6 className="m-0" style={{ fontWeight: 'normal' }}>{numberFormatter(like)}</h6>
                    </div>
                    <div  style={{ display: 'flex', alignItems: 'center', marginRight: '8px', cursor: 'pointer' }}onClick={handleLikeDislike.bind(this, "dislike")}>
                        <img alt="" src={DislikeSmall} />
                        <h6 className="m-0" style={{ fontWeight: 'normal' }}>{numberFormatter(disLike)}</h6>
                    </div>
                    <div className={classes.disabled} style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                        <Report showType="Icon" type="Reporting Comment" />
                    </div>
                </>
                :
                <>
               
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px', cursor: 'pointer' }} onClick={handleLikeDislike.bind(this, "like")}>
                        <img alt="" src={LikeSmall} />
                        <h6 className="m-0" style={{ fontWeight: 'normal' }}>{numberFormatter(like)}</h6>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px', cursor: 'pointer' }} onClick={handleLikeDislike.bind(this, "dislike")}>
                        <img alt="" src={DislikeSmall} />
                        <h6 className="m-0" style={{ fontWeight: 'normal' }}>{numberFormatter(disLike)}</h6>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px', cursor: 'pointer' }}>
                        <Report showType="Icon" type="Reporting Comment" primaryKey={commentId} />
                    </div>
                </>
            }

            {showReplyField && handleReply ?
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleReply.bind(this, props.index)}>
                    <img alt="" src={ReplySmall} /> <h6 className="m-0" style={{ fontWeight: 'normal' }}>Reply</h6>
                </div>
                :
                ""
            }
        </div>
    )
}

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default connect(mapStateToProps)(LikeDislikeReply);
