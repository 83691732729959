import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    debateProfileList: {
        margin: '0',
        padding: '0',
        display: 'flex',
        flexWrap: 'wrap',
        "& > div": {
            listStyleType: 'none',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            marginBottom: '35px',
            paddingBottom: '35px',
            position: 'relative',
            borderBottom: 'solid 1px #cdd1d8',
            justifyContent: 'center',
            flex: '0 0 50%',
            maxWidth: '50%',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 20%',
                maxWidth: '20%',
                "&:after": {
                    content: '""',
                    borderRight: '1px solid #707070',
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    height: '70px',
                },
                "&:nth-child(5n)": {
                    "&:after": {
                        borderRightColor: 'transparent',
                    }
                },
                "&:first-child, &:nth-child(5n+6)": {
                    alignItems: 'flex-start',
                },
                "&:nth-child(n+6)": {
                    marginBottom: '0',
                    paddingBottom: '0',
                    borderBottomColor: 'transparent',
                },
            },
            "& > div": {
                width: '150px',
            },

        },
        "& + &": {
            marginTop: '35px',
            paddingTop: '35px',
            borderTop: '1px solid #cdd1d8',
        }
    },

}));
const CustomUserDebateProfile = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.debateProfileList}>
            {/* {props.debateProfiles.map((debateProfile) => {
                return (
                    <div>
                        <h5 className="m-0 font-weight-normal">{debateProfile.header}</h5>
                        <h1 className="m-0">{debateProfile.value}</h1>
                    </div>
                )
            })
            } */}
            <div>
                <h5 className="m-0 font-weight-normal">{"My Debates"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.myDebateCount ? props.debateProfiles.myDebateCount : "0"}</h1>
            </div>
            <div>
                <h5 className="m-0 font-weight-normal">{"Debates Won"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.debateTotalWon ? props.debateProfiles.debateTotalWon : "0"}</h1>
            </div>
            <div>
                <h5 className="m-0 font-weight-normal">{"Debate Hours"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.debateTotalHours ? props.debateProfiles.debateTotalHours : "0"}</h1>
            </div>
            <div>
                <h5 className="m-0 font-weight-normal">{"Debate Views"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.debateTotalViews ? props.debateProfiles.debateTotalViews : "0"}</h1>
            </div>
            <div>
                <h5 className="m-0 font-weight-normal">{"Debate Votes"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.debateTotalVotestCount ? props.debateProfiles.debateTotalVotestCount : "0"}</h1>
            </div>
            <div>
                <h5 className="m-0 font-weight-normal">{"Debate Likes"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.debateTotalLikesCount ? props.debateProfiles.debateTotalLikesCount : "0"}</h1>
            </div>
            <div>
                <h5 className="m-0 font-weight-normal">{"My Vurbs"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.vurbsCount ? props.debateProfiles.vurbsCount : "0"}</h1>
            </div>
            <div>
                <h5 className="m-0 font-weight-normal">{"Vurbs Likes"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.vurbsLikeCount ? props.debateProfiles.vurbsLikeCount : "0"}</h1>
            </div>
            <div>
                <h5 className="m-0 font-weight-normal">{"Vurbs Promoted"}</h5>
                <h1 className="m-0">{props.debateProfiles && props.debateProfiles.vurbsPromotedCount ? props.debateProfiles.vurbsPromotedCount : "0"}</h1>
            </div>
        </div>
    )
}

export default CustomUserDebateProfile
