import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import { Button, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  contentMargin: {
    margin: "4px 0",
  },
  main: {
    display: "flex",
    rowGap: "12px",
    flexDirection: "column",
  },
  date: {
    color: "#868686",
    fontSize: "12px",
  },
  upcomeing: {
    width: "100%",
    border: "solid 1px #cdd1d8",
    padding: "13px",
    borderRadius: "8px",
  },
  profileimage: {
    width: "33px",
    border: "1px solid #cdd1d8",
    height: "30px",
    overflow: "hidden",
    marginRight: "14px",
    borderRadius: "50%",
  },
  livetitle: {
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "160px",
    height: "1.2em",
    whiteSpace: "nowrap",
    fontSize: "14px",
    color: " #6f757f",
    cursor: "pointer",
    " & : hover h3": {
      color: "blue",
    },
  },
}));
export default function UpComingEvent(props) {
  const classes = useStyles();
  const [list, SetList] = useState([]);
  const history = useHistory()
  useEffect(() => {
    getPostDetails();
  }, []);
  const getPostDetails = async () => {
    try {
      axios
        // .get(
        //   API_Path +
        //     `api/vurb/get?user_id=${props.user._id}&limit=5&tab=V Live&type=scheduled`,
        //   {
        //     headers: {
        //       Authorization: "Basic " + props.auth.accessToken,
        //     },
        //   }
        // )
        .post(
          API_Path +
          `api/vurb/get`,
          {
            user_id: props.user._id,
            limit: 5,
            tab: 'V Live',
            type: 'scheduled',
            allBlockedUserList:props.allBlockedUsers
          },
          {
            headers: {
              Authorization: "Basic " + props.auth.accessToken,
            },
          }
        )
        .then((response) => {
          SetList(response.data.result.data);
        })
        .catch((e) => console.log("err", e));
    } catch (e) {
      console.log('error', e)
    }
  };
  const redirectDetailPage = (data) => {
    history.push({
      pathname: "/vurbsDetail",
      state: { postObj: { post: data } },
    });
  };
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5 style={{ fontSize: "14px" }}>Upcoming Events</h5>
        <Button>
          <span
            style={{
              paddingLeft: "19px",
              fontSize: "10px",
              padding: "6px 8px",
            }}
            onClick={() => {
              props.setValue(3);
            }}
          >
            view all
          </span>
        </Button>
      </div>
      <div className={classes.main}>
        {list && list.length > 0 ?
          list.map((data, index) => {
            return (
              <Fragment key={index}>
                <Paper className={classes.upcomeing}>
                  <div style={{ display: "flex", height: "20px" }}>
                    <Tooltip title={data.live.title} placement="top">
                      <div
                        className={classes.livetitle}
                        onClick={() => redirectDetailPage(data)}
                      >
                        {data.live.title}
                      </div>
                    </Tooltip>
                  </div>
                  <div>
                    <div style={{ fontSize: "12px" }}>
                      {data.user_id.full_name}
                    </div>
                    <div className={classes.date}>
                      {data
                        ? `${moment(data.created_at).format(
                            "MMM D, YYYY"
                          )} at ${moment(data.created_at).format(" h:mm a")}`
                        : "-"}
                    </div>
                  </div>
                </Paper>
              </Fragment>
            );
          }):<Typography align="center">No Upcoming Events</Typography>}
      </div>
    </Fragment>
  );
}
