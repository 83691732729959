import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffc003",
    },
    secondary: {
      main: "#61cbc9 !important",
    },
    text: {
      primary: "#313236",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    htmlFontSize: 16,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: 30,
      fontWeight: 600,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
    },
    subtitle1: "h2",
    subtitle2: "h2",
    body1: {
      fontSize: 12,
      fontWeight: 400,
      color: "#313236",
    },
    body2: "span",
  },
  overrides: {
    MuiButton: {
      textTransform: "capitalize",
      root: {
        "&+&": {
          marginLeft: 10,
        },
      },
      contained: {
        borderRadius: 6,
        // textTransform: 'none',
        padding: "8px 36px",
        lineHeight: "1.5",
        fontSize: "14px",
        boxShadow: "none",
        textTransform: "capitalize",
        fontWeight: "normal",
      },
      outlined: {
        // fontSize: '12px',
        color: "#758194",
        borderColor: "#acb4c1",
        borderRadius: 6,
        // textTransform: 'none',
        padding: "8px 15px",
        lineHeight: "1.5",
        fontSize: "12px",
        boxShadow: "none",
        textTransform: "capitalize",
        fontWeight: "normal",
      },
      sizeSmall: {
        "&+&": {
          marginLeft: 5,
        },
      },
      outlinedSizeSmall: {
        padding: "3px 15px",
        fontSize: "12px",
        lineHeight: "1.5",
        borderRadius: "6px",
      },
    },
    MuiTypography: {
      subtitle1: {
        fontSize: "14px",
        fontWeight: 600,
      },
      body1: {
        // fontSize: "14px",
      },
    },
    MuiTextField: {
      root: {
        minWidth: 200,
        // maxHeight: 35,
      },
    },
    MuiInputBase: {
      root: {
        minWidth: 200,
        // maxHeight: 35,
      },
    },
    MuiTableContainer: {
      root: {
        boxShadow:
          "0px 2px 1px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      },
    },
    MuiInputLabel: {
      root: {
        // marginTop: "-10px",
        fontSize: "12px",
        color: "#758194",
      },
      shrink: {
        // marginTop: 0,
      },
    },
    MuiAutocomplete: {
      root: {
        maxHeight: "initial",
        alignItems: "center",
        display: "block",
      },
      tagSizeSmall: {
        alignItems: "center",
        height: "25px !important",
      },
      inputRoot: {
        maxHeight: "fit-content",
      },
    },
  },
});
