import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_Path } from "../../Common/Const";
import CustomImage from "../CustomImage";
import ChildCommentList from "./ChildCommentList";
import LikeDislikeReply from "./LikeDislikeReply";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  commentSection: {
    marginTop: "20px",
  },
  subCommentAccordion: {
    backgroundColor: "#fff",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      margin: "0 7% 0 8%!important",
    },
    "&:before": {
      opacity: "0",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      justifyContent: "flex-start",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      flexGrow: "inherit",
      "& .hideAll": {
        display: "none",
      },
      "&.Mui-expanded": {
        "& .hideAll": {
          display: "block",
        },
        "& .viewAll": {
          display: "none",
        },
      },
      "& p": {
        color: "#758194",
        fontSize: "12px",
      },
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0",
    },
  },
  subCommentSection: {
    backgroundColor: "#f6f7f8",
    padding: "16px 20px",
    borderRadius: "8px",
    width: "100%",
  },
  comments: {
    "&.mainComments, &.subComments": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexFlow: "column",
      },
    },
    "&.subComments": {
      "& + &": {
        marginTop: 5,
        paddingTop: 15,
        borderTop: "solid 1px #e2e2e2;",
      },
    },
    "& .mainCommentsContainer": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "20px",
        width: "100%",
      },
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    marginBottom: theme.spacing(3),
    maxHeight: theme.spacing(6),
    "& .MuiInputBase-input": {
      padding: "14px 0",
      fontSize: "12px",
      color: "#758194",
    },
  },
  activeStatus: {
    position: "absolute",
    right: "4px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "18px",
    },
  },
}));

const ParentCommentList = (props) => {
  const classes = useStyles();
  const [showReplyField, setShowReplyField] = React.useState(false);
  const [replyMsg, setReplyMsg] = React.useState("");
  // const [ChildCommentsList, setChildCommentsList] = React.useState([]);
  // const [commentDuration, setCommentDuration] = useState(0);
  const [indexVal, childIndex] = useState(0);
  // const location_state = getDecryptedHashWithSecret(
  //   localStorage.getItem("location.state")
  // );

  const like =
    props.commentObj &&
    props.commentObj.like_users !== null &&
    props.commentObj.like_users.length > 0
      ? props.commentObj.like_users.length
      : "0";
  const disLike =
    props.commentObj &&
    props.commentObj.dislike_users !== null &&
    props.commentObj.dislike_users.length > 0
      ? props.commentObj.dislike_users.length
      : "0";
  const handleReply = (index) => {
    setShowReplyField(true);
    childIndex(index);
  };
  const handleReplyCmt = (event) => {
    setReplyMsg(event.target.value);
  };

  const addComment = async (parentId, type) => {
    var DebatesDetails = API_Path + "api/comments/add";
    let inputObj = {
      inputObject: {
        debate_id: props.debateObject._id,
        comments: replyMsg,
        created_by: props.user._id,
        parent_comment_id: parentId ? parentId : null,
      },
    };
    axios
      .post(DebatesDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then(async (response) => {
        setReplyMsg("");
        props.reloadComments();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onEnterReply = async (list, e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      setShowReplyField(false);
      if (e.target.value !== "") {
        await addComment(list._id, "ReplyCmt");
      }
    }
  };
  const onRedirect = (url) => {
    let link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };
  // const postComment = (id) => {
  //     var DebatesDetails = API_Path + "api/comments/list";
  //     let inputObj = {
  //         "inputObject": {
  //             "debate_id": props.debateObject._id,
  //             "parent_comment_id": null
  //         }
  //     }
  //     axios
  //         .post(DebatesDetails, inputObj, {
  //             headers: {
  //                 'Authorization': "Basic " + props.auth.accessToken
  //             }
  //         })
  //         .then((response) => {
  //             // if (id === "") {
  //             //     setCommentDatas(response.data.result ? response.data.result : [])
  //             // } else {
  //             setChildCommentsList(response.data.result ? response.data.result : [])
  //             // }
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }

  // var historyDebateObj = location_state.debateObj;
  // const getTimeDifference = () => {
  //     let newDate = new Date(props.commentObj.created_at);
  //     let eventDate = historyDebateObj && moment([newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newDate.getHours(), newDate.getMinutes()]).fromNow();
  //     setCommentDuration(eventDate)
  // }

  // useEffect(() => {
  //     getTimeDifference();
  //     setInterval(() => getTimeDifference(), 1000);
  // }, []);
  let commentDuration = "";
  if (props.commentObj && props.commentObj.created_at) {
    let newDate = new Date(props.commentObj.created_at);
    let eventDate = moment([
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
    ]).fromNow();
    // setCommentDuration(eventDate)
    commentDuration = eventDate;
  }
  let { created_by } = props.commentObj ? props.commentObj : {},
    Userurl = created_by._id ? `/friendsProfilePage/${created_by._id}` : "";
  return (
    <div key={props.index} className={classes.commentSection}>
      <div className={classes.comments + " mainComments"}>
        {/* <div style={{ width: '40px', height: '40px', flex: '0 0 40px', maxWidth: '40px', borderRadius: '50%', overflow: 'hidden' }}>
                    <img alt="" src={props.commentObj && props.commentObj.created_by && props.commentObj.created_by.profile_image} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div> */}
        <div style={{ position: "relative" }}>
          <CustomImage
            userImage={
              props.commentObj &&
              props.commentObj.created_by &&
              props.commentObj.created_by.profile_image
                ? props.commentObj &&
                  props.commentObj.created_by &&
                  props.commentObj.created_by.profile_image
                : ""
            }
            size="40px"
            marginRight="0"
            link={
              props.commentObj.created_by.active_status !== 4 ? Userurl : null
            }
            onRedirect={onRedirect}
          />
          {props.commentObj &&
            props.commentObj.created_by &&
            !!props.commentObj.created_by.badge_status && (
              <span
                className={classes.activeStatus}
                title="Verified User/Public Figure"
              >
                <VerifiedUserRoundedIcon />
              </span>
            )}
        </div>
        <div className=" mainCommentsContainer">
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
            <h5
              className="m-0"
              style={
                props.commentObj.created_by.active_status !== 4
                  ? {
                      cursor: "pointer",
                    }
                  : null
              }
              onClick={
                props.commentObj.created_by.active_status !== 4 &&
                (() => onRedirect(Userurl))
              }
            >
              {props.commentObj &&
                props.commentObj.created_by &&
                props.commentObj.created_by.full_name}
            </h5>
            {props.commentObj.created_by.user_tagging && (
                <span
                  style={{ fontWeight: "500", paddingLeft: "4px" }}
                  className="userTagging"
                >
                  @{_.get(props.commentObj, "created_by.user_tagging", "")}
                </span>
              )}
            </div>
            <h6
              className="m-0"
              style={{
                whiteSpace: "nowrap",
                fontWeight: "normal",
                color: "#758194",
                marginLeft: "auto",
              }}
            >
              {/* {props.commentObj.created_at} */}
              {commentDuration}
            </h6>
            {/* <img alt="" src={hMenu} style={{ marginLeft: '18px' }} /> */}
          </div>
          <p
            className="m-0"
            style={{
              fontSize: "14px",
              color: "#313236",
              wordBreak: "break-all",
            }}
          >
            {props.commentObj.is_blocked === 1 ? (
              <span>***</span>
            ) : (
              props.commentObj.comments
            )}
          </p>
          <LikeDislikeReply
            index={props.index}
            commentId={props.commentObj._id}
            like={like}
            disLike={disLike}
            handleReply={handleReply}
            reloadComments={props.reloadComments}
            showReplyField={showReplyField}
            commentCreatedById={
              props.commentObj &&
              props.commentObj.created_by &&
              props.commentObj.created_by._id
            }
          />
          {showReplyField ? (
            <TextField
              key={props.index}
              id="standard-basic"
              label=""
              placeholder="Type your comments"
              fullWidth
              className={classes.textField}
              onChange={handleReplyCmt}
              onKeyDown={onEnterReply.bind(this, props.commentObj)}
              value={replyMsg}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {props.commentObj !== null &&
      props.commentObj.child_comments.length > 0 ? (
        <ChildCommentList
          debateId={props.debateObject._id}
          commentObj={props.commentObj}
          pIndex={props.index}
          indexVal={indexVal}
        />
      ) : (
        ""
      )}
    </div>
  );
};
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(ParentCommentList));
