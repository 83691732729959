import { Box, Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axios from "axios";
import clsx from "clsx";
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import flag from '../../../assets/images/flag.svg';
import SnackBar from "../../../components/SnackBar";
import { API_Path } from '../../Common/Const/index';
import ConfirmationModal from '../ConfirmationModal';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: theme.spacing(2),
        maxHeight: theme.spacing(5),
        minWidth: 'inherit',
        "& .MuiInputBase-formControl": {
            maxHeight: theme.spacing(5),
            minWidth: 'inherit',
            "& .MuiOutlinedInput-input": {
                height: theme.spacing(5),
                padding: '10.5px 14px',
                fontSize: '12px',
                color: '#758194',
                boxSizing: 'border-box'
            }
        },
        "& .MuiInputLabel-outlined": {
            fontSize: '14px',
            color: '#313236',
            fontWeight: '500',
            transform: 'translate(0px, -22px) scale(1)'
        },
        "& fieldset": {
            top: '0',
        },
        "& legend": {
            display: 'none',
        },
        "& .MuiFormHelperText-contained": {
            margin: '0',
            position: 'absolute',
            bottom: '-17px',
        }
    },
    textFieldArea: {
        maxHeight: 'inherit',
        "& .MuiInputBase-formControl": {
            maxHeight: 'inherit',
        },
    },
}))

const ReportAbuse = (props) => {
    const classes = useStyles();
    const [reportOpen, setReportOpen] = React.useState(false);
    const [abuseUser, setabuseUser] = React.useState('');
    const [abuseWord, setabuseWord] = React.useState('');
    const [reason, setreason] = React.useState('');
    //const [error, setError] = React.useState(false);
    const [error_abuseUser, setErrorAbuseUser] = React.useState(false);
    const [error_abuseWord, setErrorAbuseWord] = React.useState(false);
    const [error_abuseReason, setErrorAbuseReason] = React.useState(false);
    const handleabuseWords = (event) => { setabuseWord(event.target.value) }
    const handleabuseReason = (event) => { setreason(event.target.value) }
    const { opposition_users, proposition_users } = props.debateDetail
    const [HelperTextAbuseUser, setHelperTextAbuseUser] = React.useState('');
    const [helperTextAbuseWord, setHelperTextAbuseWord] = React.useState('');
    const [helperTextAbuseReason, setHelperTextAbuseReason] = React.useState('');
    const [snackbarState, setSnackbarState] = React.useState({ open: false, })
    const onClose = (event, reason) => {
        setSnackbarState({ open: false });
    };

    const handleReport = (val) => {
        setReportOpen(true);
        if (val === "text") {
            props.handleReportItem()
        }
    }
    const handleReportClose = () => {
        setReportOpen(false)
    }
    const reportSubmit = () => {
        setHelperTextAbuseUser('');
        setHelperTextAbuseWord('');
        setHelperTextAbuseReason('');
        if (abuseUser === '' || abuseWord === '' || reason === '') {
            if (abuseUser === '') {
                setHelperTextAbuseUser('Select a user');
                setErrorAbuseUser(true);
            } else {
                setErrorAbuseUser(false);
            }
            if (abuseWord === '') {
                setHelperTextAbuseWord('Abuse Word Required');
                setErrorAbuseWord(true);
            } else {
                setErrorAbuseWord(false);
            }
            if (reason === '') {
                setHelperTextAbuseReason('Abuse Reason Required');
                setErrorAbuseReason(true);
            } else {
                setErrorAbuseReason(false);
            }
            // setError(true);
            return false;
        }

        var URL = API_Path + "api/abuse/add";
        let input = {
            "inputObject": {
                "debate_id": props.debateDetail && props.debateDetail._id,
                "debate_state": props.debateDetail && props.debateDetail.title,
                "host_by": props.debateDetail && props.debateDetail.created_by && props.debateDetail.created_by._id,
                "abuse_user_id": abuseUser,
                "reported_by": props.user._id,
                "abuse_word": abuseWord,
                "reason": reason,
                "status": 1,
                "is_valid": 1,
            }
        }
        axios
            .post(URL, input, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                setSnackbarState({
                    open: true,
                    message: "Report Submitted",
                    severity: "success",
                });
                setabuseUser(""); setabuseWord(""); setreason("");
                setReportOpen(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const handleAbuseUser = (event) => {
        setabuseUser(event.target.value);
    }

    const reportAbuse = (
        <>
            <h3 style={{ margin: "0px", color: '#313236' }}>Debate Title: {props.debateDetail && props.debateDetail.title}</h3>
            <h5 style={{ margin: "0px" }}>{props.debateDetail && props.debateDetail.created_by && "Hosted by: " + props.debateDetail.created_by.full_name}</h5>
            <Box marginTop={2} marginBottom={2}>
                <FormControl component="fieldset" error={error_abuseUser}>
                    <RadioGroup aria-label="gender" name="gender1" value={abuseUser} onChange={handleAbuseUser}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FormLabel component="legend"><b>{"Proposition Users"}</b></FormLabel>
                                <FormControlLabel
                                    value={proposition_users && proposition_users.length > 0 && proposition_users[0].user && proposition_users[0].user._id}
                                    control={<Radio required={true} />}
                                    label={proposition_users && proposition_users.length > 0 && proposition_users[0].user && proposition_users[0].user.full_name}
                                    labelPlacement="end" />
                            </Grid>
                            <Grid item>
                                <FormLabel component="legend"><b>{"Opposition Users"}</b></FormLabel>
                                <FormControlLabel
                                    value={opposition_users && opposition_users.length > 0 && opposition_users[0].user && opposition_users[0].user._id}
                                    control={<Radio required={true} />}
                                    label={opposition_users && opposition_users.length > 0 && opposition_users[0].user && opposition_users[0].user.full_name}
                                    labelPlacement="end" />
                            </Grid>
                        </Grid>
                        <FormHelperText>{HelperTextAbuseUser}</FormHelperText>
                    </RadioGroup>
                </FormControl>
            </Box>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <FormControl error={error_abuseWord} fullWidth>
                        <TextField
                            id="Abuse-Word"
                            label="Abuse Word"
                            multiline
                            fullWidth
                            row={4} rowsMax={4}
                            variant="outlined"
                            onChange={handleabuseWords}
                            className={clsx(classes.textField, classes.textFieldArea)}
                        />
                        <FormHelperText>{helperTextAbuseWord}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl error={error_abuseReason} fullWidth>
                        <TextField
                            id="Reason"
                            label="Reason"
                            multiline
                            fullWidth
                            row={4} rowsMax={4}
                            variant="outlined"
                            onChange={handleabuseReason}
                            className={clsx(classes.textField, classes.textFieldArea)}
                        />
                        <FormHelperText>{helperTextAbuseReason}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            {/* <br />
            <FormLabel component="legend">
                Abuse Word
		    </FormLabel>
            <TextareaAutosize placeholder="" onChange={handleabuseWords} />
            <br />
            <FormLabel component="legend">
                Reason
		    </FormLabel>
            <TextareaAutosize placeholder="" onChange={handleabuseReason} /> */}
        </>
    )
    return (
        <>
            {
                props.listItem === "listItem" ?
                    <MenuItem onClick={handleReport.bind(this, "text")}>Report Abuse</MenuItem>
                    :
                    <img alt="" src={flag} onClick={handleReport.bind(this, "img")} style={{ cursor: "pointer" }} />
            }
            <ConfirmationModal openDialog={reportOpen} modalHeading="Report Abuse" handleDialogClose={handleReportClose} handleDelete={reportSubmit} modalContent={true} modalMessage={reportAbuse} btnCancelName="Cancel" btnNoConfirmName="Submit" maxWidth="sm" />
            <SnackBar open={snackbarState.open} message={snackbarState.message} severity={snackbarState.severity} onClose={onClose} />
        </>
    )
}

// export default ReportAbuse
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(ReportAbuse));