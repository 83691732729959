import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { API_Path } from "../Const";
import ChildComments from "./ChildComments";

const useStyles = makeStyles((theme) => ({
  commentSection: {
    marginTop: "20px",
  },
  subCommentAccordion: {
    backgroundColor: "#fff",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      margin: "0 7% 0 8%!important",
    },
    "&:before": {
      opacity: "0",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      justifyContent: "flex-start",
      zIndex: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      flexGrow: "inherit",
      "& .hideAll": {
        display: "none",
      },
      "&.Mui-expanded": {
        "& .hideAll": {
          display: "block",
        },
        "& .viewAll": {
          display: "none",
        },
      },
      "& p": {
        color: "#758194",
        fontSize: "12px",
        paddingRight: "24px",
      },
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0",
      marginLeft: "-24px",
      zIndex: -1,
    },
  },
  subCommentSection: {
    backgroundColor: "#f6f7f8",
    padding: "16px 20px",
    borderRadius: "8px",
    width: "100%",
  },
  comments: {
    "&.mainComments, &.subComments": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexFlow: "column",
      },
    },
    "&.subComments": {
      "& + &": {
        marginTop: 5,
        paddingTop: 15,
        borderTop: "solid 1px #e2e2e2;",
      },
    },
    "& .mainCommentsContainer": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "20px",
        width: "100%",
      },
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    marginBottom: theme.spacing(3),
    maxHeight: theme.spacing(6),
    "& .MuiInputBase-input": {
      padding: "14px 0",
      fontSize: "12px",
      color: "#758194",
    },
  },
}));

const ChildCommentList = (props) => {
  const classes = useStyles();
  const [ChildCommentsList, setChildCommentsList] = React.useState([]);
  // useEffect(() => {
  //     props.ChildCommentsList && setChildCommentsList(props.ChildCommentsList)
  // }, [props.ChildCommentsList])
  // useEffect(()=>{
  //     postComment();
  // },[])
  const [pindex, setpIndex] = React.useState(0);
  const [cindex, setcIndex] = React.useState(0);

  useEffect(() => {
    setpIndex(props.pIndex);
    setcIndex(props.indexVal);
    if (props.indexVal === props.pIndex) {
      var DebatesDetails = API_Path + "api/comments/list";
      let inputObj = {
        inputObject: {
          debate_id: props.debateId,
          parent_comment_id: props.commentObj._id,
        },
      };
      axios
        .post(DebatesDetails, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          setChildCommentsList(
            response.data.result ? response.data.result : []
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [
    props.pIndex,
    props.indexVal,
    props.debateId,
    props.commentObj,
    props.auth.accessToken,
  ]);
  const handleHide = () => {
    setpIndex(-1);
    setcIndex(0);
  };
  const postComment = async () => {
    setpIndex(0);
    setcIndex(0);
    var DebatesDetails = API_Path + "api/comments/list";
    let inputObj = {
      inputObject: {
        debate_id: props.debateId,
        parent_comment_id: props.commentObj._id,
      },
    };
    await axios
      .post(DebatesDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setChildCommentsList(response.data.result ? response.data.result : []);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Accordion
        className={classes.subCommentAccordion}
        expanded={pindex === cindex}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <React.Fragment>
            <Typography
              className={classes.heading + " viewAll"}
              onClick={postComment}
            >
              View All Replies
            </Typography>
            <Typography
              className={classes.heading + " hideAll"}
              onClick={handleHide}
            >
              Hide Replies
            </Typography>
          </React.Fragment>
        </AccordionSummary>
        {ChildCommentsList && ChildCommentsList.length > 0 ? (
          <AccordionDetails>
            <div className={classes.subCommentSection}>
              {ChildCommentsList &&
                ChildCommentsList.map((lst, index) => {
                  return (
                    <ChildComments
                      key={index}
                      parentCommentsReload={props.reloadComments}
                      reloadComments={postComment}
                      childObj={lst}
                    />
                  );
                })}
            </div>
          </AccordionDetails>
        ) : (
          ""
        )}
      </Accordion>
    </>
  );
};
// export default ChildComments
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default connect(mapStateToProps)(ChildCommentList);
