import {
  Button,
  makeStyles,
  AppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import SnackBar from "../../../components/SnackBar";
import Advertisement from "../../Common/Advertisement/index";
import CustomSearch from "../../Common/CustomSearch";
import "react-quill/dist/quill.snow.css";
import TrendingVurbs from "./TrendingVurbs";
import useCommonVurbsSocket from "./useCommonVurbsSocket";
import CreateVurb from "./CreateVurb";
import CommonVurb from "../../Common/CommonVurb";
import { TabPanel } from "../Dashboard";
import UpComingEvent from "./UpcomingEvent";
import { useLocation } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    // padding: '30px',
    padding: "18px 0 40px 0",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      // padding: '30px 40px',
      padding: "18px 18px 40px 18px",
    },
  },

  leftSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 260px",
      maxWidth: "260px",
    },
    "& + div": {
      [theme.breakpoints.up("lg")]: {
        width: "calc(100% - 260px)",
      },
    },
  },
  contentSection: {
    width: "100%",
    padding: "0 18px",
    
  },
  tabPanel: {
    
    '& .boxPanel':{
      padding:'0px !important'
    }
  },
  rightSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 364px",
      maxWidth: "364px",
    },
  },

  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
  },
  tabsNav: {
    top:'0',
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #cdd1d8",
    marginTop:'10px',
    justifyContent:'space-around',
    zIndex:"1",
    "& button": {
      padding: "0",
      minWidth: "inherit",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#758194",
      fontWeight: "600",
      fontFamily: "Poppins",
      // marginRight: "42px",
      textTransform: "capitalize",
      "&.Mui-selected": {
        color: "#313236",
      },
    },
    "& .MuiTabs-indicator": {
      height: "4px",
    },
    "& .MuiTabs-flexContainer":{
      justifyContent:"space-evenly"
    }
  },
  searchWhiteBg: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  // completedVideoSection: {
  //     // height: '255px',
  //     position: 'relative',
  //     overflow: 'hidden',
  //     color: '#fff',
  //     "& .fullscreen, & video": {
  //         width: '100% !important',
  //         height: '100% !important',
  //         margin: '0',
  //     },
  // },

  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  contentRightSection: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > div": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 364px)",
      },
      "&:only-child": {
        [theme.breakpoints.up("md")]: {
          width: "100%",
          "&.leftCreateSection": {
            width: "calc(100% + 36px)",
            margin: "-18px -18px -40px -18px",
          },
        },
      },
    },
  },
  paperPostInput: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.13)",
    borderRadius: "12px",
    backgroundColor: "#fff",
    margin: "20px 0",
    position: "relative",
    display: "flex",
    padding: "10px",
  },
  boxPostInput: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  quillEditor: {
    margin: "10px 0",
    // height: "100px",
    maxHeight: "100px",
    "& .ql-container": {
      // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
      // borderRadius: '12px',
      // backgroundColor: '#fff',
      // margin: '20px 0',
      "& .ql-editor": {
        backgroundColor: "#fff",
        paddingTop: "6px",
      },
      "& .ql-tooltip": {
        display: "none",
      },
    },
  },
  inputImage: {
    position: "absolute",
    color: "#ffc003",
    top:"4px",
    right:"8px",
    borderRadius:"14px",
    fontSize:"16px"
  },
  InputAttach: {
    minWidth: "34px",
    opacity: "0",
    "& input": {
      width: "28px",
    },
    "& button": {
      cursor: "pointer",
    },
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,

  };
}
let searchEnable=false
const Vurbs = (props) => {
  const classes = useStyles();
  const [postList, setPostList] = useState([]);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const myRef = useRef(null);
  const [searchVal, setSearchVal] = React.useState("");
  const [value, setValue] = React.useState(0) 
  const myRefAdvertisment = useRef("");
  const source = axios.CancelToken.source();
  const [skip, setSkip] = useState(0);
  const { globalSearch } = props;
  const { UpdateLike, vurb_like_data } = useCommonVurbsSocket({ user: props.user })
  const location = useLocation();
  const dispatch =useDispatch();
  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, []);
  useEffect(() =>{
    if(location.state){
      setSnackbarState(location.state)
    }
  },[location.state && location.state.message])

  // Socket Like data Update to previous Array Using Vurb Id
  useEffect(() => {
    if (Object.keys(vurb_like_data).length > 0) {
      let temparray = [...postList]
      let index = temparray.findIndex(val => val._id === vurb_like_data._id);
      if (index > -1) {
        temparray.splice(index, 1, {
          ...temparray[index], dislike_users: vurb_like_data.dislike_users, dislike: vurb_like_data.dislike, like: vurb_like_data.like, like_users: vurb_like_data.like_users
        })
        setPostList(temparray)
      }
    }
  }, [vurb_like_data])
  useEffect(() => {
    if (searchVal &&searchVal) {
        searchEnable=true;
    }else{
      if(searchEnable){
      }
    }
}, [searchVal])
  
useEffect(() => {
  if (globalSearch &&globalSearch) {
    setSearchVal(globalSearch)
  }
}, [globalSearch])
const handleChange = (event, newValue) => {
  setValue(newValue);
};

  const handleSearch = (event) => {
    setSearchVal(event.target.value);
    // setPage(1);
    dispatch({
        type: "AD_HASHTAG_SEARCH",
        data: "",
      });
    // myRefAdvertisment.current.getAdvertisementList();
  };
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  const UpdatePostLike=(data)=>{
    UpdateLike(data)
  }
  const AdvertisementSection = (
    <div className={classes.rightSection}>
      <Advertisement innerRef={myRefAdvertisment} type="vurbs"/>
    </div>
  );
    const onSuccess = (res,msg) => {
      if(!!res){
        if(value===0&&res.type===0){
          setValue(4)
        }else if(value===4&&res.type===0){
          myRef&&myRef.current&&myRef.current.reload();
        }else if(value===0&&res.type===1){
          // myRef?.current?.reload();
          myRef&&myRef.current&&myRef.current.reload();
        }else{
          setValue(0)
        }
        // setPostList(p => [res,...p]);
        // setSkip(skip + 1)
        setSnackbarState({
          open: true,
          message: msg,
          severity: "success", 
        });
      }
    }
    const onDelete = (index) =>{
      // console.log(index)
      let tempPost = [...postList]
      tempPost.splice(index,1);
      setPostList(tempPost)
      setSkip(skip - 1)
    }
  return (
    <div className={classes.pageContainer}>
      <div className={classes.leftSection}>
      <div style={{ display: 'flex', justifyContent: "space-between", }}><h5 style={{ fontSize: "14px" }}>Trending Vurbs</h5>
        <Button><span style={{ paddingLeft: "19px", fontSize: "10px",padding: "6px 8px" }} onClick={() => { setValue(1) }}>view all</span></Button>
      </div>
        <TrendingVurbs auth={props.auth} user={props.user} searchHash={setSearchVal} setValue={setValue} interref={myRefAdvertisment} />
       {props.vurbApiLoading && <UpComingEvent setValue={setValue} {...props} />}
      </div>
      <div className={classes.contentRightSection}>
        <div className={classes.contentSection}>
          <div className={classes.flexJCSB}>
            <CustomSearch
              className={classes.searchWhiteBg}
              handleSearch={handleSearch}
              value={searchVal}
            />
          </div>
         
            <CreateVurb onSuccess = {onSuccess} />
         
          <AppBar position="sticky" className={classes.tabsNav}>
                <Tabs
                   value={value}
                   indicatorColor="primary"
                   onChange={handleChange}
                   aria-label="disabled tabs example"
                  //  style={{paddingLeft:'30px'}}
                >
                  <Tab label="My Vurbers" {...a11yProps(0)}></Tab>
                  <Tab label="Trending" {...a11yProps(1)}></Tab>
                  <Tab label="Promoted" {...a11yProps(2)}></Tab>
                  <Tab label="Vurbil Live" {...a11yProps(3)}></Tab>
                  <Tab label="Public" {...a11yProps(4)}></Tab>
                  <Tab label="V Recorded" {...a11yProps(5)}></Tab>
                </Tabs>
          </AppBar>
        {props.vurbApiLoading&& <Fragment> <TabPanel value={value} index={0} className={classes.tabPanel}>
            <CommonVurb ref={myRef} user = {props.user} auth = {props.auth} hashed={props.hashed} UpdatePostLike = {UpdatePostLike}  searchVal = {searchVal} globalSearch={globalSearch} vurb_like_data={vurb_like_data} allBlockedUsers={props.allBlockedUsers} tab = "My Vurbers"/>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanel}>
          <TrendingVurbs tranding={true} auth={props.auth} user={props.user} hashed={props.hashed} searchHash={setSearchVal} searchVal = {searchVal} globalSearch={globalSearch}/>    
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabPanel}>
            <CommonVurb user = {props.user} auth = {props.auth} hashed={props.hashed} UpdatePostLike = {UpdatePostLike}  searchVal = {searchVal} globalSearch={globalSearch} vurb_like_data={vurb_like_data} allBlockedUsers={props.allBlockedUsers} tab = "Promoted Vurbs"/>
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.tabPanel}>
            <CommonVurb user = {props.user} auth = {props.auth} hashed={props.hashed} UpdatePostLike = {UpdatePostLike}  searchVal = {searchVal} globalSearch={globalSearch} vurb_like_data={vurb_like_data} allBlockedUsers={props.allBlockedUsers} tab = "V Live"/>
        </TabPanel>
          <TabPanel value={value} index={4} className={classes.tabPanel}>
            <CommonVurb ref={myRef} user = {props.user} hashed={props.hashed} auth = {props.auth} UpdatePostLike = {UpdatePostLike}  searchVal = {searchVal} globalSearch={globalSearch} vurb_like_data={vurb_like_data} allBlockedUsers={props.allBlockedUsers} tab = "Public"/>
        </TabPanel>
        <TabPanel value={value} index={5} className={classes.tabPanel}>
        <CommonVurb user = {props.user} auth = {props.auth} hashed={props.hashed} UpdatePostLike = {UpdatePostLike} onDelete = {onDelete} searchVal = {searchVal} globalSearch={globalSearch} vurb_like_data={vurb_like_data} allBlockedUsers={props.allBlockedUsers} tab = "V Recorded"/>
        </TabPanel>
         </Fragment>}
          {/* <CustomPagination
            page={page}
            count={pageTotalCount}
            handleChange={handlePaginationChange}
            JusCont="flex-end"
          /> */}
        </div>
        {props.is_disable_ads !== 1 &&
        props.currentPackage &&
        props.currentPackage.length === 0 ? (
          <>{AdvertisementSection}</>
        ) : (
          <>
            {props.is_disable_ads === 1 ||
            (props.currentPackage &&
              props.currentPackage.length > 0 &&
              props.currentPackage.findIndex((e) => {
                return e.package_name === "Ad free payment";
              }) > -1) ? (
                <div className={classes.rightSection}></div>
            ) : (
              <>{AdvertisementSection}</>
            )}
          </>
        )}
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </div>
  );
};

const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage,allBlockedUsers,vurbApiLoading, hashed },
  GlobalSearch: globalSearch,
}) => {
  return {
    user,
    auth,
    hashed,
    is_disable_ads,
    currentPackage,
    allBlockedUsers,
    vurbApiLoading,
    globalSearch:globalSearch.globalSearch,
  };
};

export default connect(mapStateToProps)(Vurbs);
