import React from 'react'
import StarRatings from 'react-star-ratings';
// import Rating from '@material-ui/lab/Rating';

const CustomRating = (props) => {
  return (
    <StarRatings
      rating={props.rating}
      starRatedColor="#ffc003"
      changeRating={props.changeRating}
      numOfStars={props.numberOfStars}
      name={props.name}
      starEmptyColor="#758194"
      starHoverColor="#ffc003"
      starDimension="13px"
      starSpacing="5px"
    />
    
    // <Rating
    //   size="small"
    //   name={props.name}
    //   value={props.rating}
    //   max={props.numberOfStars}
    //   onChange={props.changeRating}
    // />
  )
}

export default CustomRating
