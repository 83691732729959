import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_Path, Make_Base_Auth } from "../../Common/Const/index";

const ShareAd = (props) => {
  const params = useParams();
  useEffect(() => {
    const checkValidateAdToken = () => {
      var ValidateAdAPI =
        API_Path + "api/noauth/validateAdToken/" + params.adToken;
      axios
        .get(ValidateAdAPI, {
          headers: {
            Authorization: Make_Base_Auth(),
          },
        })
        .then((response) => {
          if (
            response.data.status === "SUCCESS" &&
            response.data.result &&
            response.data.result.link
          ) {
            window.location.href = response.data.result.link;
          } else {
            alert("Not a valid ad url");
            // history.push("/signin");
          }
        })
        .catch((error) => {
          alert("Not a valid ad url");
          console.error(error);
        });
    };

    if (params.adToken && params.adToken !== "") {
      checkValidateAdToken();
    }
  }, [params.adToken]);

  return (
    <div style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
      ...redirecting
    </div>
  );
};

export default ShareAd;
