import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Paper, Modal } from "@material-ui/core";
import s3FileUpload from "react-s3";
import { Picker } from "emoji-mart";
import { YouTube, Gif } from "@material-ui/icons";
import imgSend from "../../../assets/images/Icon-feather-send.svg";
import imgEmoji from "../../../assets/images/smile.svg";
import CommentBox from "../../Users/Vurbs/Editer";
import UploadVideo from "../UploadVideo";
import CommonGif from "../CommonGif";
import { Uploader } from "../../../lib/upload";

const useStyles = makeStyles((theme) => ({
  quillEditor: {
    margin: "10px 0",
    maxHeight: theme.spacing(6),
    borderBottom: "1px solid",
    "& .ql-container": {
      "& .ql-editor": {
        backgroundColor: "#fff",
        paddingTop: "6px",
      },
      "& .ql-tooltip": {
        display: "none",
      },
    },
  },
  quillEditorSlider: {
    margin: "10px 0",
    borderBottom: "1px solid",
    display: "grid",
    overflow: "hidden",
    maxHeight: theme.spacing(6),
    borderRadius: "120px",
    width: "234px",
    "& .ql-container": {
      "& .ql-editor": {
        backgroundColor: "#fff",
        paddingTop: "6px",
        borderRadius: "20px",
      },
      "& .ql-tooltip": {
        display: "none",
      },
    },
  },
  paperPostInput: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.13)",
    borderRadius: "12px",
    backgroundColor: "#fff",
    margin: "20px 0",
    position: "relative",
    display: "flex",
    padding: "10px",
  },
  videoModal: {
    width: "50%",
  },
}));
const CommentEditor = (props) => {
  const editerRef = useRef();
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [cameraModal, setCameraModal] = useState(false);
  const [addTextEmoji, setAddTextEmoji] = useState(false);
  const [addGif, setAddGif] = useState(false);
  const [fieldValue, setFieldValue] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  let {
    REACT_APP_AWS_S3_REGINE,
    REACT_APP_AWS_S3_ACCESSID,
    REACT_APP_AWS_S3_SECRET_ACCESSID,
    REACT_APP_AWS_S3_VIDEO_BUGKET_NAME,
  } = process.env;
  // const config = {
  //   bucketName: REACT_APP_AWS_S3_VIDEO_BUGKET_NAME,
  //   region: REACT_APP_AWS_S3_REGINE,
  //   accessKeyId: REACT_APP_AWS_S3_ACCESSID,
  //   secretAccessKey: REACT_APP_AWS_S3_SECRET_ACCESSID,
  // };
  useEffect(() => {
    editerRef &&
      editerRef.current.editor.setContents(
        editerRef && editerRef.current.editor.clipboard.convert(props.edit)
      );
  }, [props.edit]);
  const addComment = (type, url) => {
    let editor = editerRef.current.unprivilegedEditor;
    let mentionIds = [],
      hashtags = [],
      content = comment,
      obj = {
        content,
        gif: type === "gif" ? url : "",
        video: type === "video" ? url : "",
        mentionIds,
      };
    editor.getContents().ops.map((op) => {
      if (typeof op.insert === "string") {
        op.insert.split(" ").map((val) => {
          if (val.includes("#") && val.length > 1) {
            let hashvalue = val.replace("\n", "");
            let html = `<span class="mention"><span><a href="${
              process.env.REACT_APP_API_PATH
            }deeplink?type=hashtag&amp;token=${hashvalue.substring(
              1
            )}" target="_blank">${hashvalue}</a></span></span>`;
            content = content.replace(hashvalue, html);
            html = "";
            hashtags.push(hashvalue);
          }
        });
        return op;
      }
      if (op.insert.mention) {
        if (op.insert.mention.denotationChar === "@") {
          mentionIds.push(op.insert.mention.id);
        }
        if (op.insert.mention.denotationChar === "#") {
          hashtags.push(op.insert.mention.id);
        }
        return op;
      }
    });
    props.result(obj);
    setComment("");
    editerRef &&
      editerRef.current.editor.deleteText(
        0,
        editerRef.current.editor.getLength()
      );
    setAddTextEmoji(false);
    setAddGif(false);
  };
  const handleChange = (event) => {
    const quill = editerRef.current && editerRef.current.getEditor();
    let limit = 200;
    quill.enable();
    quill.on("text-change", (delta, old, source) => {
      if (quill.getLength() > limit) {
        quill.deleteText(limit, quill.getLength());
      }
    });
    setComment(event);
  };
  const addTextEmojiClick = () => {
    setAddTextEmoji(!addTextEmoji);
    setAddGif(false);
    props.commentAddCount && props.commentAddCount("add");
  };
  const addEmoji = (event) => {
    let emoji = event.native;
    let text = editerRef && editerRef.current.editor.getSelection();
    editerRef &&
      editerRef.current.editor.insertText(text ? text.index : 0, emoji);
  };

  const addGifClick = () => {
    setAddGif(!addGif);
    setAddTextEmoji(false);
  };
  const postGif = (gif) => {
    addComment("gif", gif);
  };
  // const handleRecordVid = async () => {
  //   setCameraModal(false);
  //   props.setLoader && props.setLoader(true);
  //   let result = await s3FileUpload
  //     .uploadFile(fieldValue, config)
  //     .then((data) => data)
  //     .catch((e) => console.log("err", e));
  //   addComment("video", result.location);
  //   props.setLoader && props.setLoader(false);
  //   props.commentAddCount && props.commentAddCount("add")
  // };

  const handleRecordVid = async (type) => {
    // config.bucketName = "vurbil-video-inputs";
    // config.bucketName = videoBucket;
    setCameraModal(false);
    const videoUploaderOptions = {
      file: fieldValue,
      fileName: `${fieldValue.name}`,
      token: props.auth.accessToken,
    };

    let percentage = undefined;
    props.setLoader && props.setLoader(true);
    const uploader = new Uploader(videoUploaderOptions);
    // setUploader(uploader)

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          // console.log(`${percentage}%`)
        }
      })
      .onError((error) => {
        props.setLoader && props.setLoader(false);
        console.error(error);
      });
    await uploader.start();
    uploader.onComplete((responseData) => {
      // console.log("oncomplete responseData",responseData);
      if (responseData && responseData.data) {
        addComment("video", responseData.data.Location);
        props.setLoader && props.setLoader(false);
        props.commentAddCount && props.commentAddCount("add");
      } else {
        props.setLoader && props.setLoader(false);
      }
    });
  };
  const onPressSend = () => {
    if (editerRef.current.editor.getText().length > 1) {
      addComment();
      props.commentAddCount && props.commentAddCount("add");
    } else {
      props.setSnackbarState({
        open: true,
        message: "Please enter the comment",
        severity: "error",
      });
    }
  };

  const closeModal = () => {
    setCameraModal(false);
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={
          props.slidercomment ? { flexDirection: "column", width: "90%" } : {}
        }
        className="commentEditor"
      >
        <div style={{ width: "100%" }}>
          <CommentBox
            theme="bubble"
            className={
              props.sliderImage && props.sliderImage
                ? classes.quillEditorSlider
                : classes.quillEditor
            }
            placeholder="Type your comments"
            newref={editerRef}
            onChange={handleChange}
            user={props.user}
            auth={props.auth}
            commentEdit={true}
          />
        </div>
        {/* <TextField multiline id="standard-basic" label="" placeholder="Type your comments" fullWidth className={classes.textField}
                              onChange={handleChange} value={message} /> */}
        <div
          style={
            props.slidercomment
              ? {
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                }
              : { display: "flex" }
          }
        >
          <IconButton>
            <span title="Gif">
              <Gif
                style={{ border: "1px solid #d7dbe0", borderRadius: "6px" }}
                onClick={addGifClick}
              />
            </span>
          </IconButton>
          {!props.host && (
            <IconButton>
              <span title="Video">
                <YouTube onClick={() => setCameraModal(true)} />
              </span>
            </IconButton>
          )}
          <Modal open={cameraModal} className={classes.videoModal}>
            <div>
              <Paper
                className={classes.paperPostInput}
                style={{ transform: "translate(50%,12%)" }}
              >
                <UploadVideo
                  commenttimer={20}
                  setPreviewVideo={setPreviewVideo}
                  setIsFileUploaded={setIsFileUploaded}
                  setFieldValue={setFieldValue}
                  previewVideo={previewVideo}
                  isFileUploaded={isFileUploaded}
                  onClose={closeModal}
                  onSubmit={handleRecordVid}
                  userId={props.user._id}
                />
              </Paper>
            </div>
          </Modal>
          <IconButton>
            <span title="Emoji">
              <img
                alt=""
                src={imgEmoji}
                onClick={addTextEmojiClick}
                style={{ cursor: "pointer" }}
              />
            </span>
          </IconButton>
          <IconButton>
            <span title="Send">
              <img
                alt=""
                src={imgSend}
                onClick={onPressSend}
                style={{ cursor: "pointer" }}
              />
            </span>
          </IconButton>
        </div>
      </Box>
      <div
        style={props.slidercomment && { width: "342px" }}
        className={addTextEmoji === true ? "showEmoji" : "hideEmoji"}
      >
        <Picker
          onSelect={addEmoji}
          title="Pick your emoji…"
          emoji=""
          search={false}
          showPreview={false}
          style={
            props.sliderImage
              ? { position: "relative", width: "100%", bottom: "220px" }
              : { position: "relative", width: "100%" }
          }
        />
      </div>
      {addGif && (
        <div
          style={
            props.sliderImage
              ? { position: "absolute", bottom: "85px" }
              : props.slidercomment && { width: "342px" }
          }
        >
          {" "}
          <CommonGif
            onSelect={postGif}
            slidercommentGif={props.slidercomment}
          />
        </div>
      )}
    </>
  );
};

export default CommentEditor;
