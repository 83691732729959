import axios from "axios";
import React from "react";
import { Redirect } from "react-router-dom";
var base64 = require("base-64");
var cryptLib = require("cryptlib");

// export const API_Path="http://44.241.201.86:8080/";
export const API_Path = process.env.REACT_APP_API_PATH;

export const DB_DateFormat = "YYYY-MM-DD";
export const UI_DateFormat = "dd/MMM/yyyy";
// export const UI_DateFormat="MM/dd/yyyy";

export const Make_Base_Auth = () => {
  return (
    "Basic " +
    base64.encode(
      process.env.REACT_APP_API_SECRET_USERNAME +
        ":" +
        process.env.REACT_APP_API_SECRET_PASSWORD
    )
  );
};
const hasmicrophoneaccess = async () => {
  return await new Promise(async (resolve, reject) => {
    if (
      navigator.permissions !== null &&
      navigator.permissions !== undefined &&
      navigator.permissions.query !== null &&
      navigator.permissions.query !== undefined
    ) {
      navigator.permissions
        .query({ name: "microphone" })
        .then((permissionObj) => {
          if (permissionObj.state === "granted") {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    } else if (
      navigator.mediaDevices !== null &&
      navigator.mediaDevices !== undefined &&
      navigator.mediaDevices.enumerateDevices !== null &&
      navigator.mediaDevices.enumerateDevices !== undefined
    ) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          var hasmicaccess = false;
          devices.forEach(function (device) {
            if (
              device.kind === "audioinput" &&
              device.deviceId !== null &&
              device.deviceId !== undefined &&
              device.deviceId !== ""
            ) {
              hasmicaccess = true;
            }
          });
          resolve(hasmicaccess);
        })
        .catch(function (err) {
          resolve(false);
        });
    } else {
      resolve(true);
    }
  });
};

const hascameraaccess = async () => {
  return await new Promise(async (resolve, reject) => {
    if (
      navigator.permissions !== null &&
      navigator.permissions !== undefined &&
      navigator.permissions.query !== null &&
      navigator.permissions.query !== undefined
    ) {
      navigator.permissions
        .query({ name: "camera" })
        .then((permissionObj) => {
          if (permissionObj.state === "granted") {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    } else if (
      navigator.mediaDevices !== null &&
      navigator.mediaDevices !== undefined &&
      navigator.mediaDevices.enumerateDevices !== null &&
      navigator.mediaDevices.enumerateDevices !== undefined
    ) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          var hascamaccess = false;
          devices.forEach(function (device) {
            if (
              device.kind === "videoinput" &&
              device.deviceId !== null &&
              device.deviceId !== undefined &&
              device.deviceId !== ""
            ) {
              hascamaccess = true;
            }
          });
          resolve(hascamaccess);
        })
        .catch(function (err) {
          resolve(false);
        });
    } else {
      resolve(true);
    }
  });
};

export const Allow_Camera_Mic = async () => {
  return await new Promise(async (resolve, reject) => {
    var hascamera = await hascameraaccess();
    var hasmicrophone = await hasmicrophoneaccess();
    if (hascamera && hasmicrophone) {
      resolve(true);
    } else {
      if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then(function (stream) {
            var tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            resolve(true);
          })
          .catch(function (err) {
            // alert("You should enable mic and camera");
            console.error("getUserMedia===========", err);
            resolve(false);
          });
      } else {
        console.error("getUserMedia is not supported in this browser.");
        resolve(false);
      }
    }
  });
};

export const generateEncryptedHashWithSecret = (inputText) => {
  // var KP_SECRET_KEY = process.env.REACT_APP_KP_SECRET_KEY;
  const {
    REACT_APP_KP_SECRET_KEY: KP_SECRET_KEY,
    REACT_APP_KP_CRYPTLIB_IV_NUMBER: KP_IV_NUMBER,
  } = process.env;
  var key = cryptLib.getHashSha256(KP_SECRET_KEY, 32);
  var encryptedText = cryptLib.encrypt(
    JSON.stringify(inputText),
    key,
    KP_IV_NUMBER
  );
  return encryptedText;
};

export const getDecryptedHashWithSecret = (encryptedString) => {
  // var KP_SECRET_KEY = process.env.REACT_APP_KP_SECRET_KEY;
  const {
    REACT_APP_KP_SECRET_KEY: KP_SECRET_KEY,
    REACT_APP_KP_CRYPTLIB_IV_NUMBER: KP_IV_NUMBER,
  } = process.env;
  var key = cryptLib.getHashSha256(KP_SECRET_KEY, 32);
  var decryptedText = cryptLib.decrypt(encryptedString, key, KP_IV_NUMBER);
  return JSON.parse(decryptedText);
};

export const redirectTo = (redirectpath, stateObject) => {
  // console.log(stateObject, redirectpath);
  localStorage.setItem(
    "location.state",
    generateEncryptedHashWithSecret(stateObject)
  );
  return (
    <React.Fragment>
      <Redirect
        to={{
          pathname: redirectpath,
          state: stateObject,
        }}
      />
    </React.Fragment>
  );
};

export const clearStateLocation = () => {
  localStorage.removeItem("location.state");
};

export const numberFormatter = (number) => {
  if (number < 1000) {
    return `${number}`;
  } else if (number >= 1000 && number < 1_000_000) {
    return `${(number / 1000).toFixed(1).replace(/\.0$/, "") + "K"}`;
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return `${(number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M"}`;
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return `${(number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B"}`;
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return `${
      (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T"
    }`;
  }
};

export const dateConvertor = (date) => {
  let part = date.split("-"),
    converted = new Date(part[0], part[1] - 1, part[2]);
  return converted
}

export const getKeys = async (token) => {
  let path = API_Path + "api/auth/getS3Info";
  return await axios.get(path, {
    headers: {
      Authorization: "Basic " + token,
    },
  });
};
