import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccountCircle, Close } from "@material-ui/icons";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import squareShare from "../../../assets/images/actionIcons/squareShare.svg";
import ChangePasswordIcon from "../../../assets/images/changepassword.svg";
import DeactivateIcon from "../../../assets/images/deactivateaccount.svg";
import DeleteIcon from "../../../assets/images/delete.svg";
import VDotsMenu from "../../../assets/images/dotMenu.svg";
import editIcon from "../../../assets/images/editIcon.svg";
import chat from "../../../assets/images/friendsProfile/chat.png";
import chatHeader from "../../../assets/images/friendsProfile/chatHeader.png";
import shareProfile from "../../../assets/images/friendsProfile/shareProfile.svg";
import stingFriend from "../../../assets/images/friendsProfile/stingFriend.svg";
import unstingFriend from "../../../assets/images/friendsProfile/unstingFriend.svg";
import banner from "../../../assets/images/profile/banner.png";
import Facebook from "../../../assets/images/socialIcons/facebook.svg";
import Linkedin from "../../../assets/images/socialIcons/linkedin.svg";
import Twitter from "../../../assets/images/socialIcons/twitter.svg";
import Whatsapp from "../../../assets/images/socialIcons/whatsapp.svg";
import Chat from "../../Common/Chat/Chat";
import { API_Path } from "../../Common/Const";
import CustomRating from "../../Common/CustomRating";
import CustomSocialFab from "../../Common/CustomSocialFab";
import Report from "../../Common/ReportAbuse/Report";
import UpgradeButton from "../../Common/UpgradeButton";
import VurbsProfile from "../Dashboard/Vurbs";
import DebateTab from "./DebateTab";
import MyVurbers from "./MyVurbers";

import {
  Block,
  Email,
  LocationOn,
  Phone,
  Star,
  SupervisorAccount,
  VerifiedUserRounded,
} from "@material-ui/icons";

import { Link, useHistory, useLocation } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import ConfirmationModal from "../../Common/ConfirmationModal";
import CustomImage from "../../Common/CustomImage";
import Loader from "../../Common/Loader";
import OtherActivePlans from "../../Common/OtherActivePlans";
import MediaVurbs from "../Dashboard/MediaVurbs";
import MembersCard from "./MembersCard";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    // padding: '30px',
    // padding: "0px 0 40px 0",
    marginTop: "14px",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      // padding: '30px 40px',
      padding: "0px 24px 0px 24px",
    },
  },
  paper: {
    padding: theme.spacing(1),
    boxShadow: "none",
    border: "solid 1px #cdd1d8",
    borderRadius: "8px",
    height: "100%",
    "& > div": {
      "& [class*='makeStyles-root'], & > div:first-child": {
        width: "85px",
        flex: "0 0 85px",
        maxWidth: "85px",
        marginRight: "5px",
        textAlign: "center",
        height: "auto",
        "& img": {
          width: "60px",
          height: "60px",
        },
        "& .userStatus": {
          fontSize: "10px",
          lineHeight: "1.6",
          padding: "0 5px",
          whiteSpace: "nowrap",
        },
        "& .userRating": {
          margin: "0",
          "& svg": {
            width: "9px",
            height: "9px",
          },
        },
        "& span": {
          "& svg": {
            width: ".75em",
            height: ".75em",
          },
        },
      },
      "& button, & a": {
        fontSize: "10px",
        padding: "4px 10px",
        lineHeight: "1",
        minWidth: "inherit",
        borderRadius: "5px",
        boxShadow: "none",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        "&.btnwhite": {
          color: "#fff",
        },
        "&.statusFrientBtn": {
          padding: "7px 14px",
          minWidth: "102px",
          margin: "0",
          boxShadow: "none !important",
        },
      },
      "& .btnCenter": {
        textAlign: "center",
      },
      "& .unstringBtnShow": {
        position: "relative",
        "& .showOnHoverBtn": {
          backgroundColor: "#f57777!important",
        },
        "&:hover": {
          "& .hideOnHover": {
            opacity: "0",
            visibility: "hidden",
            position: "absolute",
            top: "0",
            left: "0",
          },
        },
        "&:not(:hover)": {
          "& .showOnHoverBtn": {
            opacity: "0",
            height: "0",
            visibility: "hidden",
            position: "absolute",
            left: "0",
          },
        },
      },
    },
  },
  contentRightSection: {
    // columnGap: "20px",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > div": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 400px)",
      },
      "&:only-child": {
        [theme.breakpoints.up("md")]: {
          width: "100%",
          "&.leftCreateSection": {
            width: "calc(100% + 36px)",
            margin: "-18px -18px -40px -18px",
          },
        },
      },
    },
  },
  mediaPaper: {
    // [theme.breakpoints.up("lg")]:{
    //   width:'100%'
    // },
    width: "100%",
    border: "solid 1px #cdd1d8",
    padding: "8px 0",
    borderRadius: "8px",
    height: "81%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    rowGap: "3px",
    columnGap: "3px",
  },
  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "4px",
    borderRadius: "14px",
    fontSize: "27px",
    right: "160px",
  },
  mediaImage: {
    width: "31%",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      height: "160px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100px",
    },
    objectFit: "cover",
  },
  contentSection: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "20px",
    },
    width: "100%",
    padding: "0 0 0 20px",
    "& .header": {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        rowGap: "14px",
      },
      paddingLeft: "8px",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  rightSection: {
    backgroundColor: "#f6f7f8",
    padding: "0 18px 18px 18px",
    // maxHeight:'1400px',
    [theme.breakpoints.up("md")]: {
      // display: "flex",
      // flexFlow: "column",
      // flex: "0 0 364px",
      maxWidth: "380px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
    "& h2": {
      fontSize: "16px",
    },
  },
  upgradeBtn: {
    color: "black",
    fontWeight: "500",
    padding: "6px 15px",
    borderRadius: "20px",
    border: "1px solid black",
    backgroundColor: "transparent",
  },
  stingBtns: {
    margin: "-5px",
    display: "flex",
    alignItems: "flex-start",
    "& button, & a": {
      margin: "5px!important",
    },
    "& a": {
      lineHeight: "21px",
    },
  },
  socialProfileShare: {
    position: "relative",
    display: "flex",
    "& .MuiSpeedDial-root": {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      "& > button": {
        width: "100%",
        height: "100%",
        opacity: 0,
      },
    },
  },
  banner: {
    position: "relative",
    "& .bannerEdit": {
      position: "absolute",
      right: "20px",
      bottom: "20px",
      width: "48px",
      backgroundColor: "rgba(255, 255, 255, 0.35)",
      boxShadow: "0 2px 4px 0 rgb(0 0 0 / 13%)",
    },
    "& .bannerImage":{
      objectFit:'cover'
    },
    // "& .bannerImage:hover":{
    //   objectFit:'contain',
    //   transform: 'scale(1)'
    // },
    "& .bannerDefault":{
      objectFit:'fill'
    }
  },
  headerActionSection: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    // width: '105px',
    maxWidth: "64px",
    // flex: '0 0 105px',
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
  },
  badgeList: {
    margin: "20px 0",
    padding: "0",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "8px",
    "& li": {
      listStyleType: "none",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#758194",
      marginRight: "5px",
      border: "1px solid #758194",
      borderRadius: "6px",
      padding: "5px 15px",
    },
  },
  ModelBackground: {
    width: "60%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    // "& img": {
    //   width: "100%",
    //   maxHeight: "700px",
    // },
    "& img": {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "63%",
    },
  },
  slider: {
    " & .slick-prev:before": {
      fontSize: "50px",
      position: "absolute",
      right: "0%",
    },

    " & .slick-next:before": {
      fontSize: "50px",
    },
    " & .slick-track": {
      display: "flex",
      alignItems: "center",
    },
  },
  leftSection: {
    position: "relative",
    backgroundColor: "#f6f7f8",
    padding: "28px 18px 18px 18px",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 372px",
      maxWidth: "25%",
    },
    "& + div": {
      [theme.breakpoints.up("lg")]: {
        width: "calc(100% - 260px)",
      },
    },
    "& .image-part": {
      // [theme.breakpoints.up("lg")]: {
      //   left: "35%",
      // },
      position: "relative",
      // top: "-75px",
      // left: "2%",
      "& img": {
        backgroundColor: "white",
      },
    },
    "& .imgHolder": {
      border: "3px solid #ffc003",
    },
    "& .address": {
      display: "flex",
      flexDirection: "column",
      rowGap: "8px",
    },
    "& .address-content": {
      display: "flex",
      alignItems: "center",
      columnGap: "8px",
      "& svg": {
        fontSize: "22px",
      },
    },
    "& h4": {
      [theme.breakpoints.up("lg")]: {
        textAlign: "center",
      },
      textAlign: "start",
      margin: "8px 0px",
    },
    "& p": {
      [theme.breakpoints.up("lg")]: {
        textAlign: "center",
      },
      textAlign: "start",
      fontSize: "18px",
      fontWeight: "normal",
    },
  },
  userRating: {
    margin: "0 0 0 20px",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    color: "#758194",
    "& svg": {
      color: "#ffc003",
      fontSize: "16px",
      marginRight: "4px",
    },
    "& .applyRating": {
      display: "none",
      position: "absolute",
      top: "-30px",
      left: "0",
      width: "154px",
      height: "29px",
      margin: "0 0 8px",
      padding: "8px 12px",
      borderRadius: "8px",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff",
      "&:after": {
        content: '""',
        width: "14px",
        height: "12px",
        margin: "25px 133px 0 7px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#ffffff",
        position: "absolute",
        left: "5px",
        bottom: "-5px",
        transform: "rotate(-45deg)",
        zIndex: "-1",
      },
    },
    "&:hover": {
      "& .applyRating": {
        display: "block",
      },
    },
  },
  tabsNav: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #cdd1d8",
    "& button": {
      padding: "0",
      minWidth: "inherit",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#758194",
      fontWeight: "600",
      fontFamily: "Poppins",
      // marginRight: "42px",
      textTransform: "capitalize",
      "&.Mui-selected": {
        color: "#313236",
      },
    },
    "& .MuiTabs-indicator": {
      height: "4px",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
  },
  stats: {
    padding: "10px 10px 0",
    "& h6": {
      margin: "0",
    },
    "& h1": {
      margin: "4px 0",
    },
  },
  iconsStyles: {
    paddingRight: "10px",
    width: "25px",
  },
  activeStatus: {
    position: "absolute",
    [theme.breakpoints.up("lg")]: {
      right: "35%",
    },
    [theme.breakpoints.down("md")]: {
      right: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      right: "43%",
    },
    [theme.breakpoints.down("xs")]: {
      right: "35%",
    },
    right: "112px",
    top: "0",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "25px",
    },
  },
  friendsChat: {
    position: "fixed",
    right: "15px",
    bottom: "68px",
    width: "350px",
    height: "410px",
    backgroundColor: "#fff",
    boxShadow: "-3px 9px 18px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "6px 6px 0 0",
    zIndex: 1000,
    "& .friendsChatHeader": {
      padding: "18px 12px",
      backgroundColor: "#ffc003",
      color: "#fff",
      fontSize: "18px",
      display: "flex",
      alignItems: "center",
      borderRadius: "6px 6px 0 0",
    },
    "& .userImg": {
      width: 35,
      height: 35,
      borderRadius: "50%",
      objectFit: "cover",
      marginRight: 15,
    },
    "& .friendsChatContent": {
      padding: "24px 18px",
      overflow: "auto",
      "& .chatSection": {
        marginBottom: "10px",
        "& .chatImage": {
          flex: "0 0 34px",
          maxWidth: "34px",
          width: "34px",
          height: "34px",
        },
        "& h5, & h6, & .MuiSvgIcon-root": {
          display: "none",
        },
        "& p": {
          fontSize: "12px",
          color: "#121213",
          backgroundColor: "#dce7ff",
          padding: "6px 10px",
          borderRadius: "20px",
          minWidth: "60px",
          position: "relative",
          "&:after": {
            content: '""',
            position: "absolute",
            bottom: "-5px",
            left: "8px",
            backgroundColor: "#dce7ff",
            width: "10px",
            height: "10px",
            borderRadius: "0px 0px 10px 0px",
            transform: "rotate(15deg)",
          },
        },
        "&.sender": {
          display: "flex",
          flexFlow: "row-reverse",
          "& .chatImage": {
            margin: "0 0 0 12px",
          },
          "& p": {
            // backgroundColor: '#ffc003',
            // color: '#fff',
            "&:after": {
              left: "auto",
              right: 8,
              borderRadius: "0px 0px 0px 10px",
              // backgroundColor: '#ffc003',
              transform: "rotate(-15deg)",
            },
          },
        },
      },
      "& .MuiOutlinedInput-multiline": {
        minHeight: "36px",
        backgroundColor: "#f6f7f8",
        border: "none",
        boxShadow: "none",
        "& .MuiOutlinedInput-inputMultiline": {
          fontSize: "10px",
          color: "#758194",
        },
      },
      "& .chatSend": {
        "& img": {
          width: "18px",
        },
      },
      "& .hideEmoji": {
        height: "0",
      },
    },
  },
  acccess_denied:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="boxPanel">
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewDebateProfile = (props) => {
  const classes = useStyles();
  const { user, auth,BlockedUserEnable } = props;
  const [loading, setLoader] = useState(true);
  const [userData, setUserDatas] = useState();
  const [rating, setRating] = useState(0);
  const [newRating, setNewRating] = useState(0);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [metrics, setmetrics] = useState({});
  const [allMembersList, setAllMembersList] = useState([]);
  const [mediaList, setMediaList] = useState();
  const [viewAll, setViewAll] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [disableRating, setDisableRating] = useState(false);
  const [blockUnblockUser, setBlockUnblockUser] = useState(null);
  const [blockOpen, setBlockOpen] = useState(false);
  const [userVal, setUserVal] = useState([]);
  const [chatEnable, setChatEnable] = useState(false);
  const [chatRoom, setChatRoom] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { friendProfile, friendId } = props;
  let src = userData && userData.banner ? userData.banner : banner;
  const blockedUser = !!friendProfile
    ? userData &&
      userData.blocked_users &&
      userData.blocked_users.includes(props.user._id)
    : false;
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const redirectTo = params.get("redirectTo");
  const shareFriendsProfile =
    process.env.REACT_APP_API_PATH +
    "deeplink?" +
    "type=friendsProfile" +
    "&token=" +
    (userData && userData._id);
  const actions = [
    {
      icon: <img alt="" src={Facebook} />,
      name: "Facebook",
      href:
        "http://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(shareFriendsProfile),
    },
    {
      icon: <img alt="" src={Twitter} />,
      name: "Twitter",
      href:
        "https://twitter.com/intent/tweet?url=" +
        encodeURIComponent(shareFriendsProfile),
    },
    {
      icon: <img alt="" src={Linkedin} />,
      name: "Linkedin",
      href:
        "https://www.linkedin.com/cws/share?url=" +
        encodeURIComponent(shareFriendsProfile),
    },
    {
      icon: <img alt="" src={Whatsapp} />,
      name: "Whatsapp",
      href: "whatsapp://send?text=" + shareFriendsProfile,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!!friendProfile) {
        handleFriendProfile(friendId);
        if (user && user._id) {
          getUserViewdata(user._id);
        }
    } else {
      handleMedia();
      if (user && user._id) {
        getUserViewdata(user._id);
        friendListDatas("", "All Members");
      }
      handleProfile();
    }
  }, []);
  useEffect(() => {
    if (!!friendProfile) {
      userData &&
        userData.rating_users !== null &&
        userData &&
        userData.rating_users.find(function (id) {
          if (id.userId === props.user._id) {
            setDisableRating(true);
            setNewRating(userData.rating);
            return true;
          } else {
            setDisableRating(false);
            setNewRating(0);
            return false;
          }
        });
    }
  }, [userData]);
  React.useEffect(() => {
    if (!friendProfile) {
      if (localStorage["handleDashboard"]) {
        if (localStorage["handleDashboard"] === "friendgroupprofile") {
          setValue(2);
        }
      }
    }
  }, [redirectTo]);

  const getUserViewdata = (id) => {
    axios
      .get(API_Path + `api/auth/user/${id}`, {
        headers: {
          Authorization: "Basic " + auth.accessToken,
        },
      })
      .then((response) => {
        if (!!friendProfile) {
          setBlockUnblockUser(
            response.data.result.blocked_users.includes(friendId)
          );
        } else {
          setUserDatas(response.data.result);
          setRating(response.data.result.rating);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleFriendProfile = (friendListID) => {
    let friendView = API_Path + "api/friends/view";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
        friendId: friendListID,
      },
    };
    axios
      .post(friendView, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setUserDatas(response.data.result);
        const { metrics, rating } = response.data.result;
        setmetrics(metrics);
        setRating(rating);
        setLoader(false);
        // if (setFriendsProfileData && setFriendsProfileData.friends && setFriendsProfileData.friends.length > 0) {
        //     setFriendsProfileData.friends[0].status === 0 || setFriendsProfileData.friends[0].status === 1 ? setString(false) : setString(true)
        // }
        // setAfterStingUnsting(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleReport = (val) => {
    setBlockOpen(true);
    setUserVal(val);
  };
  const handleClose = () => {
    setBlockOpen(false);
  };
  const handleChat = () => {
    // setChatEnable((chatEnable) => !chatEnable)
    var ratindDetails = API_Path + "api/friendschatroom/create";
    let inputObj = {
      inputObject: {
        user1_id: props.user._id,
        user2_id: friendId,
        chat_type: "friends",
      },
    };
    axios
      .post(ratindDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setChatRoom(response.data.result.room_name);
        setChatEnable((chatEnable) => !chatEnable);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleBlockedUser = () => {
    let friendListAPI = API_Path + "api/user/updateUserBlockStatus";
    var inputObj = {
      inputObject: {
        fromUserId: props.user._id,
        toUserId: userVal._id,
        status: blockUnblockUser === true ? "unblock" : "block",
      },
    };
    axios
      .post(friendListAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setBlockOpen(false);
        setUserVal([]);
        getUserViewdata(user._id);
        setSnackbarState({
          open: true,
          message: response.data.message,
          severity: "success",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const setBannerOnError = () =>{
    setUserDatas({...userData,banner:""})
  }
  const changeRating = (newRating) => {
    var ratindDetails = API_Path + "api/profile/rating";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
        ratingUserId: friendId,
        rating: newRating,
      },
    };
    axios
      .post(ratindDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (
          response !== null &&
          response.data !== null &&
          response.data.result !== null
        ) {
          handleFriendProfile(friendId);
        }

        setSnackbarState({
          open: userData.rating === 0 ? true : false,
          message: "Rated Successfully",
          severity: "success",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const acceptDeclineFriendsRequest = (adRequest, list) => {
    let statusAction = false;
    // setFriendsStatus(list);
    if (adRequest === "Accept") {
      statusAction = true;
    } else {
      statusAction = false;
    }
    let adFriendsRequestAPI = API_Path + "api/friends/approve";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
        friendId: userData._id,
        approve: statusAction,
      },
    };
    axios
      .post(adFriendsRequestAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleMedia = () => {
    axios
      .get(API_Path + `api/vurb/user?user_id=${props.user._id}&limit=9`, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setMediaList(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleProfile = () => {
    let myDebateView = API_Path + "api/auth/debateProfile";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
      },
    };
    axios
      .post(myDebateView, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setmetrics(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const friendListDatas = (searchText, type, currentPageVal) => {
    let friendListAPI = API_Path + "api/friends/search";
    if (searchText.length >= 3 || searchText === "") {
      let inputObj = {
        inputObject: {
          type: type,
          userId: props.user._id,
          searchText: searchText,
          pagination: {
            currentpage: currentPageVal ? currentPageVal : 1,
            recordsperpage: 10,
          },
        },
      };
      axios
        .post(friendListAPI, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          setAllMembersList(response.data.result.data);
          // setDebateTotalCount(response.data.result.totalCount);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const viewMembers = () => {
    setValue(2);
    setViewAll(true);
  };
  const viewMedia = () => {
    setValue(3);
    setViewAll(true);
  };
  const handleSuspendedAccount = async () => {
    var DebatesDetails = API_Path + "api/auth/suspendUser";
    let inputObj = {
      email: props.user.email,
    };
    await axios
      .post(DebatesDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setSnackbarState({
          open: true,
          message: "Accounts Deactivated successfully",
          severity: "success",
        });
        setTimeout(() => {
          dispatch({
            type: "LOGOUT",
            data: {},
          });
          history.push("/signin");
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDeleteAccount = async () => {
    var DebatesDetails = API_Path + "user/delete/" + props.user._id;

    await axios
      .delete(DebatesDetails, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setSnackbarState({
          open: true,
          message: "Accounts Deleted successfully",
          severity: "success",
        });
        setTimeout(() => {
          dispatch({
            type: "LOGOUT",
            data: {},
          });
          history.push("/signin");
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDeleteClickOpen = () => {
    setOpenDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };
  const handleFriend = (lst) => {
    const { _id, friendStatus } = lst ? lst : [];
    let friendListAPI = API_Path + "api/friends/sting";
    let statusAction = false;

    friendStatus === "Already Friends" || friendStatus === "Invited"
      ? (statusAction = false)
      : (statusAction = true);
    var inputObj = {
      inputObject: {
        userId: props.user._id,
        friendId: _id,
        sting: statusAction,
      },
    };
    axios
      .post(friendListAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        handleFriendProfile(friendId);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const { first_name = "" } = userData || {};
  const actionSectionHeader = (
    <div className={classes.headerActionSection}>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton
          style={{ padding: "0px 8px 0px" }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img alt="" src={VDotsMenu} />
        </IconButton>
        {!friendProfile ? (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem style={{ padding: "0 6px" }}>
              <OtherActivePlans />
            </MenuItem>
            <MenuItem component={Link} to={"/accountProfile"}>
              <img src={editIcon} alt="" className={classes.iconsStyles} /> Edit
              Profile
            </MenuItem>
            <MenuItem component={Link} to="/changePassword">
              {" "}
              <img
                alt=""
                className={classes.iconsStyles}
                src={ChangePasswordIcon}
              />
              Change Password
            </MenuItem>
            <MenuItem onClick={handleClickOpen}>
              <img
                className={classes.iconsStyles}
                src={DeactivateIcon}
                alt=""
              />{" "}
              Deactivate Account
            </MenuItem>
            <MenuItem onClick={handleDeleteClickOpen}>
              {" "}
              <img className={classes.iconsStyles} src={DeleteIcon} alt="" />
              Delete Account
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {((userData && userData.friendStatus === "Already Friends") ||
              (userData && userData.friendStatus === "Invited")) && (
              <MenuItem onClick={handleFriend.bind(this, userData)}>
                <img
                  src={unstingFriend}
                  alt=""
                  style={{ marginRight: "5px" }}
                />
                Unsting Friend
              </MenuItem>
            )}
            <MenuItem onClick={handleReport.bind(this, userData)}>
              <Block
                style={{
                  color: "#758194",
                  fontSize: "20px",
                  marginRight: "5px",
                }}
              />
              {blockUnblockUser === true ? "Unblock User" : "Block User"}
            </MenuItem>
            <Report
              showType="Menu"
              type="Reporting Account"
              primaryKey={userData && userData._id}
              icon={true}
            />
          </Menu>
        )}
      </Box>
    </div>
  );
  return (
    <Fragment>
      <div className={classes.pageContainer}>
        <div className={classes.leftSection}>
          <div className="image-part">
            <CustomImage
              userImage={userData && userData.profile_image}
              size="120px"
              margin="0 auto 0 auto"
            />
            {userData && !!userData.badge_status && (
              <span
                className={classes.activeStatus}
                title="Verified User/Public Figure"
              >
                <VerifiedUserRounded />
              </span>
            )}
          </div>
          {userData && userData.user_tagging && (
            <h4>{`@${userData.user_tagging}`}</h4>
          )}
          <p style={{ fontSize: "14px", marginTop: "0px",textAlign:'left' }}>
            {" "}
            {userData && userData.user_bio}
          </p>
          <div className="address">
            {userData &&
              userData.full_address &&
              (!!friendProfile ? (
                <div className="address-content">
                  {(userData.city_yn === 1 ||
                    userData.state_yn === 1 ||
                    userData.country_yn === 1) && <LocationOn />}
                  <div>
                    <h6
                      // className={classes.capitalizeText}
                      style={{
                        margin: "0",
                        color: "#758194",
                        fontWeight: "normal",
                      }}
                    >
                      <span>
                        {userData.city_yn === 1 &&
                          userData.full_address.city &&
                          userData.full_address.city + ", "}
                      </span>
                      <span
                        className={`${
                          userData.full_address.state.length <= 3
                            ? "text-uppercase"
                            : ""
                        }`}
                      >
                        {userData.state_yn === 1 &&
                          userData.full_address.state &&
                          userData.full_address.state + ", "}
                      </span>
                      <span>
                        {userData.country_yn === 1 &&
                          userData.full_address.country &&
                          userData.full_address.country}
                      </span>
                    </h6>
                  </div>
                </div>
              ) : (
                <div className="address-content">
                  <LocationOn />{" "}
                  <div>
                    <h6
                      // className={classes.capitalizeText}
                      style={{
                        margin: "0",
                        color: "#758194",
                        fontWeight: "normal",
                      }}
                    >
                      <span>
                        {userData.full_address.city &&
                          userData.full_address.city + ", "}
                      </span>
                      <span
                        className={`${
                          userData.full_address.state.length <= 3
                            ? "text-uppercase"
                            : ""
                        }`}
                      >
                        {userData.full_address.state &&
                          userData.full_address.state + ", "}
                      </span>
                      <span>
                        {userData.full_address.country &&
                          userData.full_address.country}
                      </span>
                    </h6>
                  </div>
                </div>
              ))}
            <div className="address-content">
              <SupervisorAccount />
              <div>
                <h6
                  style={{
                    margin: "0",
                    color: "#758194",
                    fontWeight: "normal",
                  }}
                >
                  {userData && userData.followerCount
                    ? userData.followerCount
                    : 0}{" "}
                  Followers
                </h6>
              </div>
            </div>
            {userData &&
              userData.email &&
              (!!friendProfile ? (
                userData.email_yn === 1 && (
                  <div className="address-content">
                    <Email />
                    <div>
                      <h6
                        style={{
                          margin: "0",
                          color: "#758194",
                          fontWeight: "normal",
                        }}
                      >
                        {userData.email}
                      </h6>
                    </div>
                  </div>
                )
              ) : (
                <div className="address-content">
                  <Email />
                  <div>
                    <h6
                      style={{
                        margin: "0",
                        color: "#758194",
                        fontWeight: "normal",
                      }}
                    >
                      {userData.email}
                    </h6>
                  </div>
                </div>
              ))}
            {userData &&
              userData.mobile &&
              (!!friendProfile ? (
                userData.mobile_yn === 1 && (
                  <div className="address-content">
                    <Phone />
                    <div>
                      <h6
                        style={{
                          margin: "0",
                          color: "#758194",
                          fontWeight: "normal",
                        }}
                      >
                        {userData.mobile}
                      </h6>
                    </div>
                  </div>
                )
              ) : (
                <div className="address-content">
                  <Phone />
                  <div>
                    <h6
                      style={{
                        margin: "0",
                        color: "#758194",
                        fontWeight: "normal",
                      }}
                    >
                      {userData.mobile}
                    </h6>
                  </div>
                </div>
              ))}
          </div>
          <div>
            <h5>Interests</h5>
            <ul className={classes.badgeList}>
              {userData && userData.tags && userData.tags.length > 0
                ? userData.tags.map((list, index) => {
                    return <li key={index}>{list.name}</li>;
                  })
                : " "}
            </ul>
          </div>
        </div>
        <div className={classes.contentSection}>
          <div className={classes.contentRightSection}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={classes.banner}>
                <img
                  alt=""
                  src={src}
                  width="100%"
                  height="250px"
                  className={userData && userData.banner ?"bannerImage":"bannerDefault"}
                  // style={{ objectFit: "fill" }}
                  onError={setBannerOnError}
                />
              </div>
              <div className="header">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h3 style={{ marginBottom: "0px" }}>
                    {userData && userData.full_name}
                  </h3>
                  {!!friendProfile && blockedUser === true ? (
                    ""
                  ) : (
                    <div style={{ display: "flex", marginTop: "4px" }}>
                      <h6
                        style={{
                          margin: "0",
                          color: "#758194",
                          fontWeight: "normal",
                        }}
                      >
                        Member since{" "}
                        {userData &&
                          moment(userData.created_at).format("MMM YYYY")}
                      </h6>
                      <h6 className={classes.userRating}>
                        <Star /> {rating}
                        {!!friendProfile && !disableRating && (
                          <div className="applyRating">
                            <CustomRating
                              name="friendRating"
                              /* rating={friendsProfileData.rating} */ rating={
                                newRating
                              }
                              numberOfStars="5"
                              changeRating={changeRating}
                              starRatedColor="#ffc003"
                            />
                          </div>
                        )}
                      </h6>
                    </div>
                  )}
                </div>

                {!friendProfile && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UpgradeButton />
                    {actionSectionHeader}
                  </div>
                )}
                {!!friendProfile && userData && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Box className={classes.stingBtns}>
                      {(BlockedUserEnable !== true && blockedUser !== true) && (
                        <>
                          {userData.friendStatus === "Already Friends" ? (
                            <React.Fragment>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ borderRadius: "20px" }}
                                onClick={handleChat}
                              >
                                <img
                                  src={chat}
                                  alt=""
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                Chat
                              </Button>
                            </React.Fragment>
                          ) : userData.friendStatus === "Accept" ? (
                            <>
                              <Button
                                variant="outlined"
                                style={{ borderRadius: "20px" }}
                                onClick={acceptDeclineFriendsRequest.bind(
                                  this,
                                  "Decline",
                                  userData
                                )}
                              >
                                {"Decline"}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ borderRadius: "20px" }}
                                onClick={acceptDeclineFriendsRequest.bind(
                                  this,
                                  "Accept",
                                  userData
                                )}
                              >
                                {"Accept"}
                              </Button>
                            </>
                          ) : userData.friendStatus === "Invited" ? (
                            ""
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ borderRadius: "20px" }}
                              onClick={handleFriend.bind(this, userData)}
                            >
                              <img
                                src={stingFriend}
                                alt=""
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Sting Friend
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                    {/* <Box className={classes.socialProfileShare}>
                      <Button
                        variant="outlined"
                        style={{ marginLeft: "10px", borderRadius: "20px" }}
                      >
                        <img
                          src={shareProfile}
                          alt=""
                          style={{ marginRight: "5px" }}
                        />
                        Share profile
                      </Button>
                      <CustomSocialFab
                        actions={actions}
                        direction="down"
                        icon={squareShare}
                      />
                    </Box> */}
                    {actionSectionHeader}
                  </div>
                )}
              </div>

              <div>
                {BlockedUserEnable && <div className={classes.acccess_denied}>This account has been restricted</div>}
                {!BlockedUserEnable && <AppBar position="static" className={classes.tabsNav}>
                  {!!friendProfile ? (
                    <Tabs
                      value={value}
                      indicatorColor="primary"
                      onChange={handleChange}
                      aria-label="disabled tabs example"
                    >
                      <Tab label={`${first_name}'s Stats`} {...a11yProps(0)} />
                      <Tab label={`${first_name}'s Vurbs`} {...a11yProps(1)} />
                      <Tab
                        label={`${first_name}'s Debates`}
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  ) : (
                    <Tabs
                      value={value}
                      indicatorColor="primary"
                      onChange={handleChange}
                      // onClick={getUserImg}
                      aria-label="disabled tabs example"
                    >
                      <Tab label="My Stats" {...a11yProps(0)} />
                      <Tab label="My Vurbs" {...a11yProps(1)} />
                      <Tab label="My Vurbers" {...a11yProps(2)} />
                      <Tab label="My Media" {...a11yProps(3)} />
                    </Tabs>
                  )}
                </AppBar>}
                <TabPanel value={value} index={1}>
                  <VurbsProfile
                    auth={props.auth}
                    user={props.user}
                    hashed={props.hashed}
                    userData={userData}
                    active={value}
                  />
                </TabPanel>
                {!BlockedUserEnable && <TabPanel value={value} index={0}>
                  <h5>Debate</h5>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Count</h6>
                        <h1>
                          {metrics &&
                            (metrics.myDebateCount
                              ? metrics.myDebateCount
                              : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Hours</h6>
                        <h1>
                          {metrics &&
                            (metrics.debateTotalHours
                              ? metrics.debateTotalHours
                              : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Views</h6>
                        <h1>
                          {metrics &&
                            (metrics.debateTotalViews
                              ? metrics.debateTotalViews
                              : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Votes</h6>
                        <h1>
                          {metrics &&
                            (metrics.debateTotalVotestCount
                              ? metrics.debateTotalVotestCount
                              : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Won</h6>
                        <h1>
                          {metrics &&
                            (metrics.debateTotalWon
                              ? metrics.debateTotalWon
                              : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Likes</h6>
                        <h1>
                          {metrics &&
                            (metrics.debateTotalLikesCount
                              ? metrics.debateTotalLikesCount
                              : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                  </Grid>
                  <h5 style={{ marginTop: "80px" }}>Vurbs</h5>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Count</h6>
                        <h1>
                          {metrics &&
                            (metrics.vurbsCount ? metrics.vurbsCount : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Likes</h6>
                        <h1>
                          {metrics &&
                            (metrics.vurbsLikeCount
                              ? metrics.vurbsLikeCount
                              : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Paper className={classes.stats}>
                        <h6>Promoted</h6>
                        <h1>
                          {metrics &&
                            (metrics.vurbsPromotedCount
                              ? metrics.vurbsPromotedCount
                              : "0")}
                        </h1>
                      </Paper>
                    </Grid>
                  </Grid>
                </TabPanel>}
                {!!friendProfile ? (
                  <TabPanel value={value} index={2}>
                    <DebateTab
                      friendId={friendId}
                      blockedUser={blockedUser}
                      auth={props.auth}
                      user={props.user}
                      allBlockedUsers={props.allBlockedUsers}
                    />
                  </TabPanel>
                ) : (
                  <TabPanel value={value} index={2}>
                    <MyVurbers
                      auth={props.auth}
                      user={props.user}
                      userData={userData}
                      viewAll={viewAll}
                    />
                  </TabPanel>
                )}
                <TabPanel value={value} index={3}>
                  <MediaVurbs
                    classes={classes}
                    auth={props.auth}
                    user={props.user}
                  />
                </TabPanel>
              </div>
            </div>
            {!!friendProfile ? (
              ""
            ) : (
              <div className={classes.rightSection}>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3>Media</h3>
                    <Button>
                      <span
                        style={{ fontWeight: "500" }}
                        onClick={() => viewMedia()}
                      >
                        view all
                      </span>
                    </Button>
                  </div>
                  <Paper className={classes.mediaPaper}>
                    {mediaList && mediaList.length > 0 ? (
                      mediaList.map((list, index) => {
                        return (
                          <Fragment key={index}>
                            {list.image_url && (
                              <img
                                className={classes.mediaImage}
                                src={list.image_url}
                                alt=""
                              />
                            )}
                            {list.video_url && (
                              <video
                                src={list.video_url}
                                width="100"
                                height="75"
                              />
                            )}
                          </Fragment>
                        );
                      })
                    ) : (
                      <div>
                        <Typography align="center" variant="body1">
                          {" "}
                          No Media Available{" "}
                        </Typography>
                      </div>
                    )}
                  </Paper>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2>All Members</h2>
                  <Button
                    style={{ fontWeight: "500" }}
                    onClick={() => viewMembers()}
                  >
                    <span>view all</span>
                  </Button>
                </div>
                <Grid container spacing={2}>
                  {allMembersList.length > 0 &&
                    allMembersList.map((list, index) => {
                      const blockedUser =
                        list &&
                        list.blocked_users &&
                        list.blocked_users.includes(props.user._id);
                      const blockedByMe =
                        props.userData &&
                        props.userData.blocked_users &&
                        props.userData.blocked_users.includes(list._id);
                      return (
                        <Grid key={index} item xs={12} sm={6} md={12} lg={12}>
                          <MembersCard
                            list={list}
                            blockedUser={blockedUser}
                            blockedByMe={blockedByMe}
                            auth={props.auth}
                            user={props.user}
                            refresh={() => friendListDatas("", "All Members")}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {chatEnable !== null && chatEnable !== "" && chatEnable ? (
        <Box
          className={classes.friendsChat}
          display="flex"
          flexDirection="column"
        >
          <Box className="friendsChatHeader" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              {userData && !!userData.profile_image ? (
                <img src={userData.profile_image} alt="" className="userImg" />
              ) : (
                <AccountCircle
                  style={{
                    width: "40",
                    height: "40",
                    marginRight: "10px",
                    backgroundColor: "none",
                  }}
                />
              )}

              {userData && userData.full_name}
            </Box>
            <Close style={{ cursor: "pointer" }} onClick={handleChat} />
            {/* <img src={chatHeader} alt="" style={{ marginRight: "10px" }} /> Chat */}
          </Box>
          <Box
            className="friendsChatContent"
            display="flex"
            flexDirection="column"
            height="100%"
          >
            {chatRoom !== null ? (
              <Chat
                roomName={chatRoom}
                chatType="friends"
                toUserId={friendId}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}
      <ConfirmationModal
        openDialog={blockOpen}
        modalHeading={blockUnblockUser === true ? "Unblock User" : "Block User"}
        handleDialogClose={handleClose}
        handleDelete={handleBlockedUser}
        modalMessage={`Are you sure you want to ${
          blockUnblockUser === true ? "Unblock" : "Block"
        } this user`}
        btnCancelName={"Cancel"}
        btnNoConfirmName={"Submit"}
        maxWidth="xs"
      />
      <ConfirmationModal
        openDialog={openDialog}
        modalHeading="Message"
        handleClickOpen={handleClickOpen}
        handleDialogClose={handleDialogClose}
        handleDelete={handleSuspendedAccount}
        modalMessage="Are you sure, you want to deactivate your account?"
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
      <ConfirmationModal
        openDialog={openDeleteDialog}
        modalHeading="Message"
        handleClickOpen={handleDeleteClickOpen}
        handleDialogClose={handleDeleteDialogClose}
        handleDelete={handleDeleteAccount}
        modalMessage="Are you sure, you want to delete your account? Doing so will erase all your data, debates, stats, chats, etc."
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </Fragment>
  );
};
const mapStateToProps = ({ loginDetails: { user, auth,allBlockedUsers, hashed } }) => {
  return {
    user,
    auth,
    hashed,
    allBlockedUsers
  };
};
export default connect(mapStateToProps)(NewDebateProfile);
