import React, { useEffect, useState } from "react";
import {
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  Button,
  Hidden,
  Grid,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { TabPanel } from "../Dashboard";
import CreateAd from "./CreateAd";
import AdsList from "./AdsList";
import PaymentHistory from "./PaymentHistory";
import SnackBar from "../../../components/SnackBar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AdsDashboard from "./Dashboard";
import { ArrowDropDown, MoreVert } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    // padding: '30px',
    padding: "18px 0 40px 0",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      // padding: '30px 40px',
      padding: "18px 18px 40px 18px",
    },
    leftSection: {
      padding: "0 18px 18px 18px",
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        flexFlow: "column",
        flex: "0 0 260px",
        maxWidth: "260px",
      },
      "& + div": {
        [theme.breakpoints.up("lg")]: {
          width: "calc(100% - 260px)",
        },
      },
    },
  },
  contentSection: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& header": {
      alignItems: "center",
    },
  },
  tabPanel: {
    "& .boxPanel": {
      padding: "0px !important",
    },
  },
  rightSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 364px",
      maxWidth: "364px",
    },
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    padding: "9px 32px !important",
    margin: "16px 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      whiteSpace: "nowrap",
      padding: "9px 20px !important",
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
  },
  tabsNav: {
    flexDirection: "row",
    backgroundColor: "#FFF8E6",
    boxShadow: "none",
    justifyContent: "space-between",
    zIndex: "1",
    padding: "0 20px 0 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
    "& button": {
      padding: "0",
      minWidth: "inherit",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#313236",
      fontWeight: "500",
      fontFamily: "Poppins",
      textTransform: "capitalize",
    },
    "& .MuiTabs-root": {
      "& .MuiTabs-scrollButtons": {
        width: "30px",
      },
    },
    "& .MuiTabs-indicator": {
      height: "4px",
      borderRadius: "4px",
      backgroundColor: "#ffc003",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-evenly",
      margin: "16px 0 0",
    },
  },
  header: {
    padding: "8px 40px",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 24px",
    },
  },
  adslist: {
    height: "100%",
    backgroundColor: "#f6f7f8",
    "& .boxPanel": {
      padding: "0px !important",
      "& table  tbody.p-datatable-tbody tr td,& table  .p-datatable-thead tr th":
        {
          whiteSpace: "nowrap",
        },
    },
  },
  splitButton: {
    whiteSpace: "nowrap",
    marginLeft: "10px",

    "& button ,& button:hover": {
      padding: "10px",
      border:"none",
      fontWeight: "bold",
    },

    "& button:first-child .MuiButton-label": {
      color:"black",
      border:"none",
    },
  },
  dropDown: {
    borderBottom :"3px solid #ffc003",
    "& .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded": {
      position: "absolute",
      right: "-67px",
      width: "160px",
     
      "& li": {
        fontWeight: "bold",
      },
    },
  },
}));
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
const options = ["My Ad Dashboard", "Manage Ads", "Payment Activity"];
const VurbilAds = (props) => {
  const classes = useStyles();
  const {
    adDetails: { isOpen },
  } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [createAd, setCreateAd] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  useEffect(() => {
    if (isOpen) {
      setCreateAd(true);
    }
  }, [isOpen]);
  const location = useLocation();
  // const [createAd, setCreateAd] = useState(false);
  useEffect(() => {
    if (location && location.state && location.state === "wallet" || location.search.includes("paymentActivity")) {
      setValue(2);
    }else if(location.search.includes("manageAds")){
      setValue(1);
    }
  }, []);
  const handleClose = () => {
    setCreateAd(false);
    dispatch({
      type: "AD_CLOSE",
    });
    setEditData(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setOpen(false);
  };
  const handleCreate = () => {
    setCreateAd(true);
    // dispatch({
    //   type: "AD_POPUP",
    //   data: { isOpen: true },
    // });
  };
  const onSuccess = (msg) => {
    handleClose();
    setValue(1);
    setSnackbarState({
      open: true,
      message: msg,
      severity: "success",
    });
  };
  const onError = (msg) => {
    setSnackbarState({
      open: true,
      message: msg,
      severity: "error",
    });
  };
  const editAd = (data) => {
    setEditData(data);
    setCreateAd(true);
  };
  const onClose = () => {
    setSnackbarState({ open: false });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleSplitClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <h4 className={classes.header}>Vurbil Ads</h4>
      {createAd ? (
        <CreateAd
          handleClose={handleClose}
          onSuccess={onSuccess}
          onError={onError}
          editData={editData}
          {...props}
        />
      ) : (
        <div className={classes.contentSection}>
          <AppBar position="sticky" className={classes.tabsNav}>
            <Hidden xsDown>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                aria-label="disabled tabs example"
              >
                {options.map((option, index) => (
                  <Tab
                    key={index}
                    label={
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          margin: "10px",
                        }}
                      >
                        {option}
                      </span>
                    }
                  />
                ))}
              </Tabs>
            </Hidden>
            <Hidden smUp>
              {/* <Grid container direction="column" alignItems="start">
              <Grid item xs={12}> */}
              <div className={classes.dropDown}>
                <ButtonGroup
                  variant="outlined"
                  color="primary"
                   className={classes.splitButton}
                  fullWidth
                  ref={anchorRef}
                  aria-label="split button"
                >
                  <Button>{options[value]}</Button>
                  <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <MoreVert />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleSplitClose}>
                          <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === value}
                                onClick={(event) => handleChange(event, index)}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              {/* </Grid>
            </Grid> */}
            </Hidden>
            <Button
              variant="contained"
              color="primary"
              className={clsx(classes.circleBtn)}
              onClick={() => handleCreate()}
              style={{ fontWeight: "bold", color: "black" }}
            >
              Create Ad
            </Button>
          </AppBar>

          {/* TabPanel components */}
          <TabPanel className={classes.adslist} value={value} index={0}>
            <AdsDashboard auth={props.auth} />
          </TabPanel>
          <TabPanel className={classes.adslist} value={value} index={1}>
            <AdsList auth={props.auth} onEdit={editAd} />
          </TabPanel>
          <TabPanel className={classes.adslist} value={value} index={2}>
            <PaymentHistory auth={props.auth} />
          </TabPanel>
        </div>
      )}
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </div>
  );
};
// export default VurbilAds;
const mapStateToProps = ({ loginDetails: { user, auth }, adDetails }) => {
  return {
    user,
    auth,
    adDetails,
  };
};
export default withRouter(connect(mapStateToProps)(VurbilAds));
