import React, { useEffect, useState } from "react";
import {
  API_Path,
  Make_Base_Auth,
} from "../../../../src/screens/Common/Const/index";
import axios from "axios";
import { Box, Typography } from "@material-ui/core";
import "../../../assets/css/quill.css";

const EULA = () => {
  const [page, setPage] = useState("");
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    getStaticPage();
  }, []);
  const getStaticPage = () => {
    let getStaticPageData = API_Path + "api/auth/getStaticPage";
    let inputObj = {
      inputObject: {
        type: "EULA",
      },
    };
    axios
      .post(getStaticPageData, inputObj, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        if (
          response !== null &&
          response.data !== null &&
          response.data.result !== null
        ) {
          setPage(response.data.result.html_content);
          setTimeout(() => {
            setLoaded(true);
          }, 500);
        } else {
          setPage("");
          setTimeout(() => {
            setLoaded(true);
          }, 500);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      {loaded ? (
        !!page ? (
          <div
            className="ql-editor"
            style={{ opacity: loaded ? "1" : "0" }}
            dangerouslySetInnerHTML={{ __html: page }}
          ></div>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Typography variant="h5">No Content Available</Typography>
          </Box>
        )
      ) : (
        // <div
        //   className="ql-editor"
        //   style={{  whiteSpace: "inherit" }}
        //   dangerouslySetInnerHTML={{ __html: page }}
        // ></div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography variant="h5">Loading...</Typography>
        </Box>
      )}
    </>
  );
};

export default EULA;
