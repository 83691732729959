import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import userImage from "../../../assets/images/defaultProfile.svg";
import ProfileEditIcon from "../../../assets/images/profileEditIcon.svg";
import SnackBar from "../../../components/SnackBar";
import { API_Path } from "../../Common/Const/index";
import ImageCropper from "../../Common/ImageCropper";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import { ImageSizecompress } from "../../../lib/uriUtils";

const useStyles = makeStyles({
  root: {
    width: 136,
    // height: 160,
    "& .userStatus": {
      borderRadius: "13px",
      backgroundColor: "#61cbc9",
      fontSize: "12px",
      color: "#ffffff",
      lineHeight: "1.5",
      display: "inline-block",
      padding: "5px 15px",
    },
    "& .userRating": {
      margin: "10px 0 0 0",
      padding: "0 10px",
      fontWeight: "bold",
      borderRadius: "13px",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& svg": {
        color: "#ffc003",
      },
    },
  },
  userImage: {
    width: "100%",
    height: "136px",
    objectFit: "cover",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  editIcon: {
    position: "absolute",
    bottom: "0px",
    right: "-5px",
    overflow: "hidden",
    "& input": {
      width: "44px",
      height: "44px",
      position: "absolute",
      opacity: "0",
      cursor: "pointer",
    },
  },
  activeStatus: {
    position: "absolute",
    right: "5px",
    top: "5px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "25px",
    },
  },
});

const defaultSrc = userImage;

const MemberStatus = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  // const pixeDivider = 1024;
  const [snackbarState, setSnackbarState] = useState({
    open: false,
  });

  // const handleClickOpen = () => {
  //     setOpenDialog(true);
  // };
  const handleDialogClose = () => {
    setImagePreviewUrl(props.profileImage);
    document.getElementById("uploadCaptureInputFile").value = "";
    setOpenDialog(false);
  };
  const onChange = (e) => {
    e.preventDefault();
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      (e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg")
    ) {
      setImagePreviewUrl("");
      setOpenDialog(true);
      // setFileErrorMsg(false);
      // setSizeErrorMsg(false);

      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(files[0]);
    } else {
      e.target.value = null;
      // setSnackbarState({
      //     open: true,
      //     message: "Upload Image File",
      //     severity: "error",
      // });
    }
  };

  // const _sizeError = () => {
  //     setSizeErrorMsg(true);
  //     setTimeout(() => {
  //         setSizeErrorMsg(false);
  //     }, 3000);
  // }
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };
  // const _showFileErrorMsg = () => {
  //     setFileErrorMsg(true);
  //     setTimeout(() => {
  //         setFileErrorMsg(false);
  //     }, 3000);
  // }

  const croppedResult = (croppedCanvasobj) => {
    if (croppedCanvasobj) {
      croppedCanvasobj.getCroppedCanvas().toBlob(function (blob) {
        blob.lastModifiedDate = new Date();
        blob.name = props.user._id + ".png";
        //setFile(blob)
        // setFile(blob)
        //setImagePreviewUrl(imagePreviewUrl)
        uploadImage(blob);
        document.getElementById("uploadCaptureInputFile").value = "";
        setOpenDialog(false);
        if (document.getElementById("profile-img")) {
          document.getElementById("profile-img").src =
            URL.createObjectURL(blob);
        }
        setSnackbarState({
          open: true,
          message: "User Image Updated",
          severity: "success",
        });
      });
    }
  };
  React.useEffect(() => {
    setImagePreviewUrl(props.profileImage);
  }, [props.profileImage]);
  const uploadImage = async(blob) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Basic " + props.auth.accessToken,
      },
    };
    var form = new FormData();
    console.log('profile image before upload size====>', blob.size / (1024 ** 2))
    const file = await ImageSizecompress(blob);
    console.log('profile image after upload size====>', file.size / (1024 ** 2))
    form.append("files", file);
    form.append("userId", props.user._id);
    var DebatesDetails = API_Path + "api/user/uploadPhoto";
    axios.post(DebatesDetails, form, config).then((response) => {
      props.handleProfile(
        response.data && response.data.result
          ? response.data.result.Location
          : ""
      );
      //setImagePreviewUrl(response.data && response.data.result ? response.data.result.Location : '')
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreviewUrl(
          response.data && response.data.result
            ? response.data.result.Location
            : ""
        );
      };
      if (
        response.data &&
        response.data.result &&
        response.data.result.Location
      ) {
        let userData = props.user;
        userData["profile_image"] =
          response.data.result.Location + "?v=" + Math.random();
        dispatch({
          type: "LOGIN_GLOBAL_DETAILS",
          data: {
            authenticated: props.authenticated,
            auth: props.auth,
            user: userData,
          },
        });
      }
    });
  };
  return (
    <div className={classes.root}>
      <div style={{ position: "relative" }}>
        {props.uploadDisable === false ? (
          <>
            {props.list && props.list.profile_image ? (
              <>
                {/* <CustomImage userImage={props.list.profile_image} size="136px" /> */}
                <img
                  alt=""
                  src={props.list.profile_image}
                  className={classes.userImage}
                />
              </>
            ) : (
              <img alt="" src={defaultSrc} className={classes.userImage} />
            )}
          </>
        ) : (
          <>
            {props.profileImage ? (
              <>
                {/* <CustomImage userImage={props.list.profile_image} size="136px" /> */}
                <img
                  alt=""
                  src={imagePreviewUrl}
                  className={classes.userImage}
                />
              </>
            ) : (
              <img alt="" src={defaultSrc} className={classes.userImage} />
            )}
          </>
        )}

        {props &&
          !(props.editIconShow === true) &&
          props.list &&
          !!props.list.badge_status &&
          props.blockedUser !== true && (
            <span
              className={classes.activeStatus}
              title="Verified User/Public Figure"
            >
              {/* <CheckCircleOutlineIcon /> */}
              <VerifiedUserRoundedIcon />
              {/* <VerifiedUserOutlinedIcon /> */}
            </span>
          )}

        {props.editIconShow === true ? (
          <span className={classes.editIcon}>
            <input
              id="uploadCaptureInputFile"
              accept="image/x-png,image/jpg,image/jpeg"
              type="file"
              title="Upload PNG or JPG file"
              onChange={onChange}
            />
            <img alt="" src={ProfileEditIcon} />
          </span>
        ) : (
          ""
        )}
      </div>
      {props.blockedUser !== true && (
        <div
          style={{
            textAlign: "center",
            position: "relative",
            marginTop: "-15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* {props.list ? <span className="userStatus">{active_status === 0 ? "Inactive"
                        : active_status === 1 ? "Active" : active_status === 2 ? "Blocked by admin" : active_status === 3 ? "Deactivated" : ""}</span> : ""} */}
          {/* {props.ratingED === true ? <h6 className="userRating"><StarIcon /> {props && props.list && props.list.rating}</h6> : ""} */}
          {/* {props.showMembersince &&
                        <h6 style={{ margin: '0', color: '#758194', fontWeight: 'normal' }}>
                            Member since <br /> {props.list && moment(props.list.created_at).format("MMM YYYY")}
                        </h6>
                    } */}
        </div>
      )}
      <ImageCropper
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        imagePreviewUrl={imagePreviewUrl}
        setOpenDialog={setOpenDialog}
        croppedResult={croppedResult}
        ratio={1 / 1}
      />
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </div>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth, authenticated } }) => {
  return {
    user,
    auth,
    authenticated,
  };
};
export default withRouter(connect(mapStateToProps)(MemberStatus));
