import React from "react";
import { API_Path } from "../Const";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import CustomImage from "../CustomImage";
import LikeSmall from "../../../assets/images/likeSmall.svg";
import DislikeSmall from "../../../assets/images/dislikeSmall.svg";
import Report from "../ReportAbuse/Report";
import { Box, IconButton, Menu, MenuItem, Button } from "@material-ui/core";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ConfirmationModal from "../ConfirmationModal";
import SnackBar from "../../../components/SnackBar";
import CommentEditor from "./CommentEditor";
import { get } from "lodash";
import ReactPlayer from "react-player";
import { numberFormatter } from "../Const";

const useStyles = makeStyles((theme) => ({
  comments: {
    "&.mainComments, &.subComments": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexFlow: "column",
      },
    },
    "&.subComments": {
      "& + &": {
        marginTop: 5,
        paddingTop: 15,
        borderTop: "solid 1px #e2e2e2;",
      },
    },
    "& .mainCommentsContainer": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "20px",
        width: "100%",
      },
    },
  },
  disabled: {
    opacity: ".5",
    pointerEvents: "none",
    "& svg": {
      fontSize: "20px",
    },
  },
  report: {
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
    cursor: "pointer",
    "& svg": {
      fontSize: "10px",
    },
  },
  activeStatus: {
    position: "absolute",
    right: "8px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "16px",
    },
  },
  replayText: {
    display: "flex",
  },
}));

const VurbChildCommentList = ({
  childObj,
  index,
  auth,
  user,
  reload,
  creator,
  setLoader,
  sliderImage,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  const [edit, setEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const like =
    childObj && childObj.like?childObj.like: childObj.like_users !== null && childObj.like_users.length > 0
      ? childObj.like_users.length
      : "0";

  const disLike =
    childObj &&
    childObj.dislike_users !== null &&
    childObj.dislike_users.length > 0
      ? childObj.dislike_users.length
      : "0";
  let commentDuration = "";
  if (childObj && childObj.created_at) {
    let newDate = new Date(childObj.created_at);
    let eventDate = moment([
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
    ]).fromNow();
    commentDuration = eventDate;
  }
  const handleLikeDislike = (val) => {
    getupordownvotes(val);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = async (obj) => {
    let EditApi = API_Path + `vurbs/comments/update/${childObj._id}`;
    let inputObj = {
      inputObject: {
        vurb_id: childObj.vurb_id,
        comment: obj.content,
        _id: childObj._id,
        parent_comment_id: childObj.parent_comment_id,
        gif: obj.gif,
        video: obj.video,
        mentionIds: obj.mentionIds,
        device: "web",
      },
    };
    await axios
      .put(EditApi, inputObj, {
        headers: {
          Authorization: "Basic " + auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data && response.data.status === "SUCCESS") {
          reload();
          setEdit(false);
          setSnackbarState({
            open: true,
            message: "Edited Successfully",
            severity: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getupordownvotes = async (type) => {
    let likeAPI = API_Path + "vurbs/comments/likes";

    let inputObj = {
      inputObject: {
        userId: user._id,
        commentId: childObj._id,
        type: type,
      },
    };
    axios
      .post(likeAPI, inputObj, {
        headers: {
          Authorization: "Basic " + auth.accessToken,
        },
      })
      .then((response) => {
        // getDebateList();
        // childRating === true ? reloadComments(commentId) : reloadComments("")

        if (response.data && response.data.status === "SUCCESS") {
          reload();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  const clearEdit = () => {
    setEdit(false);
  };
  const handleDelete = async (index) => {
    var deletePost = API_Path + "vurbs/comments/delete";
    let inputObj = {
      id: childObj._id,
      parent_id: childObj.parent_comment_id,
    };
    await axios
      .post(deletePost, inputObj, {
        headers: {
          Authorization: "Basic " + auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setOpenDialog(false);
          setSnackbarState({
            open: true,
            message: "Deleted Successfully",
            severity: "success",
          });
          reload("Delete");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const onRedirect = (url) => {
    let link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };
  const actionSectionHeader = (
    <div className={classes.headerActionSection}>
      <Box>
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVertIcon
            style={{ position: "absolute", top: "-3px" }}
            
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {!(childObj.gif || childObj.video) &&
            user._id === childObj.created_by._id && (
              <MenuItem
                onClick={() => {
                  setEdit(true);
                  setAnchorEl(null);
                }}
              >
                {" "}
                Edit
              </MenuItem>
            )}
          <MenuItem onClick={handleClickOpen}> Delete</MenuItem>
        </Menu>
      </Box>
    </div>
  );
  let { created_by } = childObj ? childObj : {},
    Userurl =
      !!created_by && created_by._id
        ? `/friendsProfilePage/${created_by._id}`
        : "";
  return (
    // return <ChildComments key={index} parentCommentsReload={props.reloadComments} reloadComments={postComment} childObj={lst} />
    <div key={index} className={classes.comments + " subComments"}>
      <div style={{ position: "relative" }}>
        <CustomImage
          userImage={
            !!childObj.created_by &&
            childObj.created_by.full_name &&
            childObj.created_by.profile_image
              ? childObj.created_by.full_name &&
                childObj.created_by.profile_image
              : ""
          }
          size="40px"
          marginRight="0"
        />
        {!!childObj.created_by && !!childObj.created_by.badge_status && (
          <span
            className={classes.activeStatus}
            title="Verified User/Public Figure"
          >
            <VerifiedUserRoundedIcon />
          </span>
        )}
      </div>
      <div className=" mainCommentsContainer" style={sliderImage?{width:"80%"}:{}}>
        <div style={{ display: "flex", marginBottom: "4px" }}>
          <div style={{ display: "flex" }}>
            <h5
              className="m-0"
              style={
                !!childObj.created_by && childObj.created_by.active_status !== 4
                  ? {
                      cursor: "pointer",
                    }
                  : null
              }
              onClick={
                !!childObj.created_by
                  ? childObj.created_by.active_status !== 4 &&
                    (() => onRedirect(Userurl))
                  : undefined
              }
            >
              {!!childObj.created_by &&
                childObj.created_by.full_name &&
                childObj.created_by.full_name}
            </h5>
            {childObj.created_by.user_tagging && (
              <span
                style={{ fontWeight: "500", paddingLeft: "4px" }}
                className="userTagging"
              >
                @{get(childObj, "created_by.user_tagging", "")}
              </span>
            )}
          </div>
          {!sliderImage && <h6
            className="m-0"
            style={{
              whiteSpace: "nowrap",
              fontWeight: "normal",
              color: "#758194",
              marginLeft: "auto",
            }}
          >
            {commentDuration}
          </h6> }
          {(user._id === childObj.created_by._id || creator) &&
            actionSectionHeader}
        </div>
        {sliderImage && <h6
            className="m-0"
            style={{
              whiteSpace: "nowrap",
              fontWeight: "normal",
              color: "#758194",
              marginLeft: "auto",
            }}
          >
            {commentDuration}
          </h6> }
        <div
          className="m-0"
          style={{ fontSize: "14px", color: "#313236", wordBreak: "break-word" }}
        >
          {childObj && childObj.is_blocked === 1 ? (
            <span>***</span>
          ) : (
            childObj.comment && (
              <div dangerouslySetInnerHTML={{ __html: childObj.comment }}></div>
            )
          )}
        </div>
        
        {edit && (
          <>
            <CommentEditor
              slidercomment={sliderImage}
              user={user}
              auth={auth}
              result={handleEdit}
              setLoader={setLoader}
              setSnackbarState={setSnackbarState}
              edit={childObj.comment}
            />
            <Button onClick={clearEdit}>Cancel</Button>
          </>
        )}
        {childObj.gif && (
          <div>
            {" "}
            <img
              src={childObj.gif}
              style={{ width: "90px", height: "64px" }}
              alt=""
            />
          </div>
        )}
        {childObj.video ? (
          <div style={{ position: "relative" }}>
            <ReactPlayer
              className="react-player"
              url={childObj.video}
              width="28%"
              height="25%"
              controls
            />
          </div>
        ) : (
          " "
        )}
        <div style={{ display: "flex", margin: "8px 0" }}>
          {childObj.created_by && childObj.created_by._id === user._id ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={handleLikeDislike.bind(this, "like")}
              >
                <img alt="" src={LikeSmall} />
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  {numberFormatter(like)}
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={handleLikeDislike.bind(this, "dislike")}
              >
                <img alt="" src={DislikeSmall} />
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  {numberFormatter(disLike)}
                </h6>
              </div>
              <div
                className={classes.disabled}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                }}
              >
                {/* <Report showType="Icon" type="Reporting Comment" /> */}
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={handleLikeDislike.bind(this, "like")}
              >
                <img alt="" src={LikeSmall} />
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  {numberFormatter(like)}
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={handleLikeDislike.bind(this, "dislike")}
              >
                <img alt="" src={DislikeSmall} />
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  {numberFormatter(disLike)}
                </h6>
              </div>
              <div className={classes.report}>
                <Report
                  showType="Icon"
                  type="Reporting Comment"
                  primaryKey={childObj._id}
                />
              </div>
            </>
          )}
        </div>
        {/* <LikeDislikeReply commentId={props.childObj._id} like={like} disLike={disLike} reloadComments={props.reloadComments} childRating={true} 
    commentCreatedById={props.childObj && props.childObj.created_by && props.childObj.created_by._id}/> */}
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      <ConfirmationModal
        openDialog={openDialog}
        modalHeading="Message"
        handleDialogClose={handleDialogClose}
        handleDelete={() => handleDelete(index)}
        modalMessage="Are you sure to delete the comment?"
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
    </div>
  );
};
export default VurbChildCommentList;
