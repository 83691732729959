import { Slider, withStyles } from "@material-ui/core";

const CommonSlider = withStyles({
  root: {
    color: "#FFC003",
    height: 8,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#FFC003",
    border: "2px solid currentColor",
    marginTop: -6,
    marginLeft: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "0px 0px 4px 0px #00000040",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    color: "#EDE3C7",
  },
})(Slider);

export default CommonSlider;
