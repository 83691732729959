import React, {useState} from 'react'
import clsx from 'clsx';
import * as yup from 'yup';
import { Grid } from '@material-ui/core'
import { Formik, Field, Form } from 'formik';
import FormikInput from '../../Common/FormikComponents/FormikInput';
import { Button, makeStyles } from '@material-ui/core';
import {useHistory } from "react-router-dom";
import logoImage from '../../../assets/images/Vurbil - Final-02.png';
import axios from "axios";
import { connect } from "react-redux";
import { Make_Base_Auth, API_Path } from '../../Common/Const/index';
import { withRouter } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: '30px',
        [theme.breakpoints.up('md')]: {
            padding: '30px 40px',
        },
    },
    headingWithBorderBottom: {
        margin: '0 0 40px 0',
        paddingBottom: '14px',
        borderBottom: 'solid 1px #cdd1d8',
        fontWeight:'400'
    },
    textField: {
        marginTop: theme.spacing(2),
        maxHeight: theme.spacing(5),
        minWidth: 'inherit',
        "& .MuiInputBase-formControl": {
            maxHeight: theme.spacing(5),
            minWidth: 'inherit',
            "& .MuiOutlinedInput-input": {
                height: theme.spacing(5),
                padding: '10.5px 14px',
                fontSize: '12px',
                color: '#758194',
                boxSizing: 'border-box'
            }
        },
        "& .MuiInputLabel-outlined": {
            fontSize: '14px',
            color: '#313236',
            fontWeight: '500',
            transform: 'translate(0px, -22px) scale(1)'
        },
        "& fieldset": {
            top: '0',
        },
        "& legend": {
            display: 'none',
        },
        "& .MuiFormHelperText-contained": {
            margin: '0',
            position: 'absolute',
            bottom: '-17px',
        }
    },
    textFieldArea: {
        maxHeight: 'inherit',
        "& .MuiInputBase-formControl": {
            maxHeight: 'inherit',
        },
    },
    circleBtn: {
        borderRadius: 30,
        fontWeight: 'bold',
    },
}))

const AdEnquiry = (props) => {
  const history = useHistory()
      const classes = useStyles();
    // const [loginPage, setloginPage] = useState(false);
    const [snackbarState, setSnackbarState] = useState({ open: false })
    const initState = {
        firstName: (props.user&&props.user.firstName) || '', lastName: (props.user&&props.user.lastName) ||"", email: (props.user&&props.user.email) || "", phone: "", subject: (props.user&&props.user.firstName&&"I would like to place an ad or ads on Vurbil")||"", company: "", message: ""
    }
    const validationSchema = yup.object({
        firstName: yup.string().required('First Name is Required'),
        lastName: yup.string().required('Last Name is Required'),
        email: yup.string().email('Invalid email').required('Email is Required'),
        phone: yup.string().matches(/^[0-9]*$/, 'Invalid Number').min(10, 'Number should be atleast 10 digits').max(11, 'Number should be atleast 11 digits').required("Phone number is mandatory"),
        subject: yup.string().required('Subject is Required'),
        company: yup.string(),
        message: yup.string().required('Message is Required'),
    })
    const submitAdEnquiry = (state) => {
        let URL = API_Path + "api/ad_enquiry/add";
        let inputObj = {
            "inputObject": {
                "first_name": state.firstName,
                "last_name": state.lastName,
                "email": state.email,
                "contact_number": state.phone,
                "subject": state.subject,
                "company": state.company,
                "message": state.message,
                "status": 1
            }
        };

        axios
            .post(URL, inputObj, {
                headers: {
                    'Authorization': Make_Base_Auth()
                }
            })
            .then((response) => {
                setSnackbarState({
                    open: true,
                    message: "Ad Inquiry sent successfully",
                    severity: "success",
                  }); 
                  setTimeout(() => {
                    history.goBack()
                  }, 1000);
                 
            })
            .catch((error) => {
                console.error(error);
            });
           
            
    }
    const onClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarState({ open: false });
      };
    return (
        <div className={classes.pageContainer}>
           {window.location.pathname === '/adEnquiry' && <img alt="" src={logoImage} style={{ marginBottom: '20px',  maxWidth: "151px",maxHeight: "84px" }} />}
            <Grid container spacing={5}>
                <Grid item xs={12} md={9}>
                    <Formik
                        initialValues={{ ...initState }}
                        onSubmit={submitAdEnquiry}
                        validationSchema={validationSchema}
                        validateOnBlur={true}>
                        {({ isSubmitting }) => {
                            return <Form autoComplete="off">
                                <h2 style={{marginBottom:'8px'}}>Ad Inquiry</h2>
                                <h6 className={classes.headingWithBorderBottom}>All businesses big and small can advertise on Vurbil. We use proprietary non-evasive tools to match up our Vurbil users to your specific ads. For complete Ad criteria information, click on the link below</h6>
                                <div className={classes.centerDiv}>
                                    <Grid container className={classes.root} spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <Field name="firstName" label="First Name" placeholder="First Name" type="text" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name="lastName" label="Last Name" placeholder="Last Name" type="text" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name="email" label="Email " placeholder="sample@gmail.com" type="email" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name="phone" label="Phone" placeholder="Phone Number" type="phone" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name="subject" label="Subject" placeholder="Subject" type="text" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name="company" label="Company Name" placeholder="Company Name" type="text" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name="message" label="Message" placeholder="Please type here.." multiline row={6} rowsMax={6} as={FormikInput} className={clsx(classes.textField, classes.textFieldArea)} variant="outlined" />
                                        </Grid>
                                        <Grid item container xs={12} justify="flex-end">
                                            <Button onClick={()=>history.goBack()}>
                                                Cancel
                                            </Button>
                                            <Button variant="contained" color="primary" type="submit" className={clsx(classes.circleBtn)} >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                                <span >For more details click <span onClick={()=> {history.push({ pathname: '/faq/Adenquiry'})}} style={{color:"blue",cursor:"pointer"}}>here</span>.</span>
                            </Form>
                        }}
                    </Formik>
                </Grid>
                <Grid item xs={12} md={3}> </Grid>
            </Grid>
            <SnackBar open={snackbarState.open} message={snackbarState.message} severity={snackbarState.severity} onClose={onClose} />
        </div>
    )
}



const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(AdEnquiry));
