import makeStyles from "@material-ui/core/styles/makeStyles";
import PublicIcon from "@material-ui/icons/Public";
import ScheduleIcon from "@material-ui/icons/Schedule";
import React from "react";
import { Allow_Camera_Mic, redirectTo } from "../../Common/Const";
import DebateCategory from "../CustomDebateHeaderComponents/DebateCategory";
const useStyles = makeStyles((theme) => ({
  debateHeader: {
    [theme.breakpoints.up("sm")]: {
      width: "calc(100% - 105px )",
    },
    [theme.breakpoints.down("xs")]: {
      borderTop: "solid 1px #cdd1d8",
      paddingTop: 5,
      width: "100%",
    },
    "& > div": {
      overflow: "hidden",
    },
    "& h2": {
      margin: "0",
      lineHeight: "35px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  debateSubHeader: {
    margin: "8px -7px",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#758194",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > span": {
      margin: "2px 7px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        marginBottom: "6px",
      },
      "&:first-child": {
        paddingRight: "14px",
        borderRight: "1px solid #cdd1d8",
      },
      "& svg": {
        verticalAlign: "text-top",
        width: "15px",
        height: "15px",
        marginRight: "5px",
      },
    },
  },
  timeUTC: {
    backgroundColor: "#e8eaed",
    padding: "1px 7px",
    borderRadius: "40px",
    color: "#313236",
  },
}));

const DebateDetail = (props) => {
  const classes = useStyles();
  const [redirectToLiveDebate, setRedirectToLiveDebate] = React.useState(false);
  const [redirectToCompletedDebate, setRedirectToCompletedDebate] =
    React.useState(false);
  const [startTime, setStartTime] = React.useState("");
  React.useEffect(() => {
    if (
      props.debateDetailValues &&
      props.debateDetailValues.debateDetail &&
      props.debateDetailValues.debateDetail.debate_planned_start_datetime
    ) {
      let d = new Date(
        props.debateDetailValues.debateDetail.debate_planned_start_datetime
      );
      let dt = d.toString();
      let startTime = dt.split(" ");
      setStartTime(startTime[4]);
    }
  }, [props.debateDetailValues]);
  const redirectToHostURL = (hostURL) => {
    window.open(hostURL, "_blank");
  };
  const toLiveDebate = async () => {
    if (props.debateDetailValues.screen === "ActiveDebateList") {
      let isAllowCameraMic = await Allow_Camera_Mic();
      if (isAllowCameraMic === true) {
        setRedirectToLiveDebate(true);
      } else {
        alert("You should enable mic and camera");
      }
    } else if (props.debateDetailValues.screen === "Recorded") {
      setRedirectToCompletedDebate(true);
    } else if (props.debateDetailValues.screen === "MyDebates") {
      if (
        props.debateDetailValues.debateDetail.status === 0 ||
        props.debateDetailValues.debateDetail.status === 1
      ) {
        let isAllowCameraMic = await Allow_Camera_Mic();
        if (isAllowCameraMic === true) {
          setRedirectToLiveDebate(true);
        } else {
          alert("You should enable mic and camera");
        }
      } else if (props.debateDetailValues.debateDetail.status === 2) {
        setRedirectToCompletedDebate(true);
      }
    }
  };
  let hostedByURL = "";
  let hostedUserId =
    props.debateDetailValues &&
    props.debateDetailValues.debateDetail &&
    props.debateDetailValues.debateDetail.created_by
      ? props.debateDetailValues.debateDetail.created_by._id
      : null;
  if (hostedUserId && props.debateDetailValues.user._id) {
    if (hostedUserId === props.debateDetailValues.user._id) {
      hostedByURL = "/userDashboard";
    } else {
      hostedByURL = "/friendsProfilePage/" + hostedUserId;
    }
  }

  return (
    <>
      {redirectToLiveDebate
        ? redirectTo("/activeDebate", {
            debateObj: props.debateDetailValues.debateDetail,
            roomName: props.debateDetailValues.debateDetail._id,
          })
        : ""}
      {redirectToCompletedDebate &&
      props.debateDetailValues !== null &&
      props.debateDetailValues.debateDetail !== null
        ? redirectTo("/completedDebate", {
            debateObj: props.debateDetailValues.debateDetail,
            roomName: props.debateDetailValues.debateDetail._id,
          })
        : ""}
      <div className={classes.debateHeader}>
        {/* {props.debateDetailValues.liveBtn === true ?
                    <h2 title={props.debateDetailValues && props.debateDetailValues.debateDetail && props.debateDetailValues.debateDetail.title}>
                        {props.debateDetailValues && props.debateDetailValues.debateDetail && props.debateDetailValues.debateDetail.title}
                    </h2>
                    :
                    <h2 style={{ cursor: "pointer" }} onClick={toLiveDebate} title={props.debateDetailValues && props.debateDetailValues.debateDetail && props.debateDetailValues.debateDetail.title}>
                        {props.debateDetailValues && props.debateDetailValues.debateDetail && props.debateDetailValues.debateDetail.title}
                    </h2>
                } */}
        {props.debateDetailValues &&
        props.debateDetailValues.debateDetail &&
        props.debateDetailValues.debateDetail.is_blocked === 1 ? (
          <h2
            title={
              props.debateDetailValues &&
              props.debateDetailValues.debateDetail &&
              props.debateDetailValues.debateDetail.title
            }
          >
            {props.debateDetailValues &&
              props.debateDetailValues.debateDetail &&
              props.debateDetailValues.debateDetail.title}
          </h2>
        ) : (
          <>
            {props.debateDetailValues &&
            props.debateDetailValues.debateDetail ? (
              <>
                {props.debateDetailValues.debateDetail.status === 0 ||
                props.debateDetailValues.debateDetail.status === 1 ||
                props.debateDetailValues.debateDetail.status === 2 ? (
                  <h2
                    style={{ cursor: "pointer" }}
                    onClick={toLiveDebate}
                    title={
                      props.debateDetailValues &&
                      props.debateDetailValues.debateDetail &&
                      props.debateDetailValues.debateDetail.title
                    }
                  >
                    {props.debateDetailValues &&
                      props.debateDetailValues.debateDetail &&
                      props.debateDetailValues.debateDetail.title}
                  </h2>
                ) : (
                  <h2
                    title={
                      props.debateDetailValues &&
                      props.debateDetailValues.debateDetail &&
                      props.debateDetailValues.debateDetail.title
                    }
                  >
                    {props.debateDetailValues &&
                      props.debateDetailValues.debateDetail &&
                      props.debateDetailValues.debateDetail.title}
                  </h2>
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}

        <div className={classes.debateSubHeader}>
          <span>
            <PublicIcon />
            {props.debateDetailValues &&
              props.debateDetailValues.debateDetail &&
              props.debateDetailValues &&
              props.debateDetailValues.debateDetail.debate_type}
          </span>
          <span>
            Hosted by
            <span
              style={{ color: "#1d6cba", marginLeft: "5px", cursor: "pointer" }}
              onClick={redirectToHostURL.bind(this, hostedByURL)}
            >
              {props.debateDetailValues &&
                props.debateDetailValues.debateDetail &&
                props.debateDetailValues.debateDetail.created_by &&
                props.debateDetailValues.debateDetail.created_by.full_name}
            </span>
          </span>
          {props.debateDetailValues &&
          props.debateDetailValues.UTC === false ? (
            ""
          ) : (
            <span className={classes.timeUTC}>UTC +05:30 </span>
          )}
          <span>
            <ScheduleIcon />
            {startTime}
          </span>
        </div>
        {props.debateDetailValues &&
        props.debateDetailValues.category === false ? (
          ""
        ) : (
          <DebateCategory categoryValue={props.debateDetailValues} />
        )}
      </div>
    </>
  );
};

export default DebateDetail;
