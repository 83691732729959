import React, { useState } from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ConfirmationModal from '../ConfirmationModal';

const ImageCropper = ({ openDialog, handleDialogClose, imagePreviewUrl, setOpenDialog, croppedResult, ratio, ...props }) => {

    const [cropper, setCropper] = useState();

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            croppedResult(cropper);
            // setOpenDialog(false);
        }
    };

    const cropperSection = (
        <div style={{ width: "100%" }}>
            <Cropper
                style={{ height: 250, width: "100%"}}
                aspectRatio={ ratio }
                preview=".img-preview"
                src={imagePreviewUrl}
                guides={false}
                viewMode={1}
                dragMode="move"
                cropBoxMovable={false}
                cropBoxResizable={false}
                onInitialized={(instance) => {
                    setCropper(instance);
                }}
            />
        </div>
    )
    return (
        <div>
            <ConfirmationModal openDialog={openDialog} modalHeading="Image Crop" handleDialogClose={handleDialogClose} handleDelete={getCropData} modalContent={true} modalMessage={cropperSection} btnCancelName="No" btnNoConfirmName="Crop Image" maxWidth="sm" />
        </div>
    )
}

export default ImageCropper
