import React,{useEffect, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios'
import { API_Path } from "../../Common/Const";
import CustomSearch from "../../Common/CustomSearch";
import CustomPagination from "../../Common/CommonPagination";
import CustomDebateCard from "../../Common/CustomDebateCard";

const useStyles = makeStyles((theme) => ({
    flexJCSB: {
        display: "flex",
        justifyContent: "space-between",
        "&.column-down-lg": {
          [theme.breakpoints.down("md")]: {
            flexFlow: "column",
          },
        },
      },
      contentSection: {
        width: "100%",
        padding: "16px 18px 0",
      },
}));

const DebateTab = (props) => {
    const classes = useStyles();
    const [debateList, setDebateList] = useState([]);
    const [debateTotalCount, setDebateTotalCount] = useState(0);
    const [searchVal, setSearchVal] =useState("");
    const [page, setPage] = useState(1)
    const [debateObject, setDebateObject] =useState(null);
    const pageTotalCount = Math.ceil(debateTotalCount / 10);
    useEffect(() => {
        getDebateDetails();
    },[])
    const getDebateDetails = async (currentPageVal) => {
        var DebatesDetails = API_Path + "api/debate/list";
        if (
          props.auth &&
          props.auth.accessToken &&
          ((searchVal && searchVal.length > 1) || searchVal === "")
        ) {
          let inputObj = {
            inputObject: {
              type: "myDebates",
              userId: props.friendId,
              // categoryId: catId.current,
              searchText: searchVal,
              sortBy: "Relevant",
              pagination: {
                currentpage: currentPageVal ? currentPageVal : 1,
                recordsperpage: 10,
              },
              allBlockedUsers:props.allBlockedUsers?props.allBlockedUsers:[]
            },
          };
          axios
            .post(DebatesDetails, inputObj, {
              headers: {
                Authorization: "Basic " + props.auth.accessToken,
              },
            })
            .then((response) => {
              setDebateList(response.data.result.data);
              setDebateTotalCount(response.data.result.totalCount);
              // setDebateListLoaded(true)
            })
            .catch((error) => {
              console.error(error);
            });
        }
      };
      const handlePaginationChange = (event, value) => {
        setPage(value);
        getDebateDetails(value);
      };
      const handleSearch = (event) => {
        setSearchVal(event.target.value);
        // getDebateDetails();
        setPage(1);
      };
      const handleUpdateDebate = (debateObject) => {
        setDebateObject(debateObject);
      };
      const handleDeleteDebate = () => {
        getDebateDetails(0, "");
      };
  return (
    <>
      {props.blockedUser !== true ? (
        <div className={classes.contentSection}>
          <div className={classes.flexJCSB}>
            <CustomSearch
              className={classes.searchWhiteBg}
              handleSearch={handleSearch}
            />
          </div>

          {debateList && debateList.length > 0 ? (
            debateList.map((list, index) => {
              return (
                <CustomDebateCard
                  key={index}
                  debateDetail={list}
                  userId={props.friendId}
                  getDebateDetails={getDebateDetails}
                  screen="MyDebates"
                  editDebate={handleUpdateDebate.bind(this, list)}
                  handleDeleteDebate={handleDeleteDebate}
                />
              );
            })
          ) : (
            <Box mt={3}>
              <Typography align="center" variant="h6">
                No Debates Available
              </Typography>
            </Box>
          )}
          <CustomPagination
            page={page}
            count={pageTotalCount}
            handleChange={handlePaginationChange}
            JusCont="flex-end"
          />
        </div>
      ) : (
        "-"
      )}
    </>
  );
};
export default DebateTab;
