import { Box, CircularProgress, Typography } from "@material-ui/core";
import axios from "axios";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import { API_Path } from "../../Common/Const";
import AddList from "./AddList";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Advertisement = forwardRef((props, ref) => {
  const [advertisementData, setAdvertisementData] = useState([]);
  const [classificationKeyword, setClassificationKeyword] = useState("");
  const [loading, setLoader] = useState();
  const[data,setData]=useState(true)
  const[gethashTage,setHashTag]=useState("")
  const observer = useRef();
  const location =useLocation();
  const dispatch =useDispatch();
  const {adHashtag} =props
  const lastAds = useCallback((node) => {
     if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (data) {
          getAdvertisementList(null,null,adHashtag)
        }
      }
    });
    if (node) observer.current.observe(node);
  });
  const getAdvertisementList = async (val,type=false,hashTag) => {
    var AdvertisementDetails = API_Path + "api/ads/filter_ad";
      const ids = advertisementData.length > 0 ? advertisementData.map(item => item._id):[];
    let inputObj =  {
       "limit": "3",
       "classification": val ? val : val === "All" ? "All" : null,
       "screen_name":props.type ? props.type :"vurbs",
       "screen_device":"Web",
       "hash_tag":hashTag ? hashTag:gethashTage,
       "ads_id": type ? [] : ids ,
    }
    setLoader(true);
    await axios
      .post(AdvertisementDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (
          response !== null &&
          response.data !== null &&
          response.data.result !== null
        ) {
          if( !type && response.data.result.data.length > 0){
            setAdvertisementData((p) => [...p, ...response.data.result.data]);
            setData(true)   
          }else{
            if(adHashtag || val || hashTag){
              setAdvertisementData(response.data.result.data);
            }
            setData(false)            
          }
          // setAdvertisementData(response.data.result.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };
  useImperativeHandle(props.innerRef, () => ({
    getAdvertisementList: (val,type,hashTag) => {
      setClassificationKeyword(val);
      getAdvertisementList(val,type,hashTag);
    },
    // getcategoryList: (val) => {
    //     getcategoryList(val);
    // }
  }));

  // const getcategoryList = async (val) => {
  //     var categoryDetails = API_Path + "api/ads/filter";
  //     let inputObj = ''
  //     if (val === undefined) {
  //         inputObj = {
  //         }
  //     } else {
  //         inputObj = {
  //             categoryId: val,
  //         }
  //     }
  //     await axios
  //         .post(categoryDetails, inputObj, {
  //             headers: {
  //     'Authorization': "Basic " + props.auth.accessToken
  // }
  //         })
  //         .then((response) => {
  //             setAdvertisementData(response.data.result)
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }
  useEffect(() => {
    if(location.pathname !="/vurbs"){
      dispatch({
        type: "AD_HASHTAG_SEARCH",
        data: "",
      });
    }
    if(adHashtag){
      getAdvertisementList(null,true,adHashtag);
    }else{
      getAdvertisementList();
    }
   
  }, [adHashtag]);
  return (
    <div ref={ref}>
      <h6
        style={{
          margin: "10px 0 30px 0",
          color: "#758194",
          fontWeight: "normal",
        }}
      >
        Advertisement
      </h6>
      {advertisementData && advertisementData.length > 0 ? (
        <>
          {advertisementData.map((advData, index) => {
            return (
              <div ref={advertisementData.length - 1 === index ? lastAds : null} >
              <AddList
                key={index}
                advData={advData}
                url={advData.link_to_your_file}
                // link={advData.share_ad_url}
                link={advData.redirect_click_url}
                classificationKeyword={classificationKeyword}
              />
               {loading && advertisementData.length-1===index && <div style={{display:"flex", justifyContent:"center",width:"100%"}}><CircularProgress /></div>}
              </div>
              
            );
          })}
        </>
      ) : (
        <Box mt={3}>
          <Typography align="center" variant="h6">
            No Ads Available
          </Typography>
        </Box>
      )}
    </div>
  );
});

const mapStateToProps = ({ loginDetails: { user, auth },
  GlobalSearch: {adHashtag,globalSearch} }) => {
  return {
    user,
    auth,
    adHashtag,
    globalSearch
  };
};
export default connect(mapStateToProps)(Advertisement);
