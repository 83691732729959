import { Box, Button, Grid } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HeadsCoin from "../../../assets/images/heads.png";
import TailsCoin from "../../../assets/images/taills.png";
import TossGIF from "../../../assets/images/tossgif.gif";
import { API_Path } from "../../Common/Const";
const TossBanner = (props) => {
  const classes = props.classes;
  const [debateObject, setDebateObject] = useState(null);
  const propUserName =
    debateObject &&
    debateObject.proposition_users.length > 0 &&
    debateObject.proposition_users[0].user.full_name;
  const oppoUserName =
    debateObject &&
    debateObject.opposition_users.length > 0 &&
    debateObject.opposition_users[0].user.full_name;
  useEffect(() => {
    setDebateObject(props.debateObject);
  }, [props.debateObject]);

  const updateTossSelected = async (tossoption) => {
    var inputObj = {
      inputObject: {
        debateId: props.debateObject._id,
        toss_selected: tossoption,
      },
    };
    let tossSelectionAPI = API_Path + "api/auth/update_debate_selectedtoss";
    axios
      .post(tossSelectionAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  var loggedinusers = "audience";
  if (
    debateObject !== null &&
    debateObject.proposition_users !== null &&
    debateObject.proposition_users.length > 0 &&
    debateObject.proposition_users[0].user._id === props.user._id
  ) {
    loggedinusers = "proposition";
  } else if (
    debateObject !== null &&
    debateObject.opposition_users !== null &&
    debateObject.opposition_users.length > 0 &&
    debateObject.opposition_users[0].user._id === props.user._id
  ) {
    loggedinusers = "opposition";
  }

  var toss_won_message = null;
  if (debateObject && debateObject.toss_won && debateObject.toss_won.won_by) {
    if (loggedinusers === "proposition") {
      if (debateObject.toss_won.won_by === "proposition") {
        toss_won_message = "You won the toss. You are going to speak first.";
      } else {
        toss_won_message =
          oppoUserName +
          " won the toss. " +
          oppoUserName +
          " are going to speak first.";
      }
    } else if (loggedinusers === "opposition") {
      if (
        debateObject &&
        debateObject.toss_won &&
        debateObject.toss_won.won_by === "opposition"
      ) {
        toss_won_message = "You won the toss. You are  going to speak first.";
      } else {
        toss_won_message =
          propUserName +
          " won the toss. " +
          propUserName +
          " are going to speak first.";
      }
    } else {
      if (
        debateObject &&
        debateObject.toss_won &&
        debateObject.toss_won.won_by === "proposition"
      ) {
        toss_won_message =
          propUserName +
          " won the toss. " +
          propUserName +
          " are going to speak first.";
      } else {
        toss_won_message =
          oppoUserName +
          " won the toss. " +
          oppoUserName +
          " are going to speak first.";
      }
    }
  }
  var proposition_toss = null;
  var opposition_toss = null;
  if (
    debateObject !== null &&
    debateObject.toss_chooser !== null &&
    debateObject.toss_selected !== null &&
    debateObject.toss_chooser === "proposition"
  ) {
    if (debateObject.toss_selected === "head") {
      proposition_toss = "head";
      opposition_toss = "tail";
    }
    if (debateObject.toss_selected === "tail") {
      proposition_toss = "tail";
      opposition_toss = "head";
    }
  }
  if (
    debateObject !== null &&
    debateObject.toss_chooser !== null &&
    debateObject.toss_selected !== null &&
    debateObject.toss_chooser === "opposition"
  ) {
    if (debateObject.toss_selected === "head") {
      opposition_toss = "head";
      proposition_toss = "tail";
    }
    if (debateObject.toss_selected === "tail") {
      opposition_toss = "tail";
      proposition_toss = "head";
    }
  }
  return (
    <Box>
      <Grid container spacing={3} className={classes.tossField}>
        <Grid item xs={12} sm={6}>
          <Box className={classes.anyOneSlide}>
            <div className="avatarImage">
              {/* <img src={debateObject && debateObject.proposition_users.length > 0 ?
                                debateObject.proposition_users[0].user.profile_image : ""} alt='' /> */}

              {debateObject &&
              debateObject.proposition_users.length > 0 &&
              debateObject.proposition_users[0].user.profile_image ? (
                <img
                  src={debateObject.proposition_users[0].user.profile_image}
                  alt=""
                />
              ) : (
                <AccountCircle
                  style={{
                    width: "200px",
                    height: "198px",
                    margin: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    opacity: ".25",
                  }}
                />
              )}
              <h5>
                {debateObject && debateObject.proposition_users.length > 0
                  ? debateObject.proposition_users[0].user.full_name
                  : "No Proposition users"}
              </h5>
            </div>
            {debateObject !== null &&
            debateObject.toss_chooser !== null &&
            debateObject.toss_chooser === "proposition" &&
            debateObject.toss_selected === null ? (
              <>
                {loggedinusers === debateObject.toss_chooser ? (
                  <p style={{ margin: "0 0 10px 0", textAlign: "center" }}>
                    Choose Your Option
                  </p>
                ) : (
                  ""
                )}
                <Box className={classes.askToss}>
                  <Box className="tossContainer">
                    <img
                      src={HeadsCoin}
                      disabled={loggedinusers !== debateObject.toss_chooser}
                      onClick={
                        loggedinusers === debateObject.toss_chooser
                          ? updateTossSelected.bind(this, "head")
                          : ""
                      }
                      alt=""
                    />
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={loggedinusers !== debateObject.toss_chooser}
                      onClick={
                        loggedinusers === debateObject.toss_chooser
                          ? updateTossSelected.bind(this, "head")
                          : ""
                      }
                    >
                      {" "}
                      Heads{" "}
                    </Button>
                  </Box>
                  <Box className="tossContainer">
                    <img
                      src={TailsCoin}
                      disabled={loggedinusers !== debateObject.toss_chooser}
                      onClick={
                        loggedinusers === debateObject.toss_chooser
                          ? updateTossSelected.bind(this, "tail")
                          : ""
                      }
                      alt=""
                    />
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      disabled={loggedinusers !== debateObject.toss_chooser}
                      onClick={
                        loggedinusers === debateObject.toss_chooser
                          ? updateTossSelected.bind(this, "tail")
                          : ""
                      }
                    >
                      {" "}
                      Tails{" "}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : proposition_toss ? (
              <Box className={classes.askToss}>
                <Box className="tossContainer">
                  <img
                    src={proposition_toss === "head" ? HeadsCoin : TailsCoin}
                    disabled
                    alt=""
                  />
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disabled
                  >
                    {" "}
                    {proposition_toss === "head" ? "Heads" : "Tails"}{" "}
                  </Button>
                </Box>
              </Box>
            ) : (
              ""
            )}
            {debateObject &&
            debateObject.toss_won &&
            debateObject.toss_won.won_by &&
            debateObject.toss_won.won_by === "proposition" ? (
              <p style={{ textAlign: "center" }}>{toss_won_message}</p>
            ) : (
              ""
            )}
          </Box>
        </Grid>
        <Box className="coinSpinner">
          {debateObject &&
          debateObject.toss_selected &&
          debateObject.toss_won &&
          debateObject.toss_won.wonned_toss &&
          (debateObject.toss_won.wonned_toss === "head" ||
            debateObject.toss_won.wonned_toss === "tail") ? (
            <img
              src={
                debateObject.toss_won.wonned_toss === "head"
                  ? HeadsCoin
                  : TailsCoin
              }
              alt=""
            />
          ) : debateObject !== null && debateObject.toss_selected !== null ? (
            <img src={TossGIF} alt="" />
          ) : (
            "Vs"
          )}
        </Box>
        <Grid item xs={12} sm={6}>
          <Box className={classes.anyOneSlide}>
            <div className="avatarImage">
              {debateObject &&
              debateObject.opposition_users.length > 0 &&
              debateObject.opposition_users[0].user.profile_image ? (
                <img
                  src={debateObject.opposition_users[0].user.profile_image}
                  alt=""
                />
              ) : (
                <AccountCircle
                  style={{
                    width: "200px",
                    height: "198px",
                    margin: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    opacity: ".25",
                  }}
                />
              )}

              <h5>
                {debateObject && debateObject.opposition_users.length > 0
                  ? debateObject.opposition_users[0].user.full_name
                  : "No Opposition users"}
              </h5>
            </div>
            {debateObject !== null &&
            debateObject.toss_chooser !== null &&
            debateObject.toss_chooser === "opposition" &&
            debateObject.toss_selected === null ? (
              <>
                {loggedinusers === debateObject.toss_chooser ? (
                  <p style={{ margin: "0 0 10px 0", textAlign: "center" }}>
                    Choose Your Option
                  </p>
                ) : (
                  ""
                )}
                <Box className={classes.askToss}>
                  <Box className="tossContainer">
                    <img
                      src={HeadsCoin}
                      disabled={loggedinusers !== debateObject.toss_chooser}
                      onClick={
                        loggedinusers === debateObject.toss_chooser
                          ? updateTossSelected.bind(this, "head")
                          : ""
                      }
                      alt=""
                    />
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={loggedinusers !== debateObject.toss_chooser}
                      onClick={
                        loggedinusers === debateObject.toss_chooser
                          ? updateTossSelected.bind(this, "head")
                          : ""
                      }
                    >
                      {" "}
                      Heads{" "}
                    </Button>
                  </Box>
                  <Box className="tossContainer">
                    <img
                      src={TailsCoin}
                      disabled={loggedinusers !== debateObject.toss_chooser}
                      onClick={
                        loggedinusers === debateObject.toss_chooser
                          ? updateTossSelected.bind(this, "tail")
                          : ""
                      }
                      alt=""
                    />
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      disabled={loggedinusers !== debateObject.toss_chooser}
                      onClick={
                        loggedinusers === debateObject.toss_chooser
                          ? updateTossSelected.bind(this, "tail")
                          : ""
                      }
                    >
                      {" "}
                      Tails{" "}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : opposition_toss !== null ? (
              <Box className={classes.askToss}>
                <Box className="tossContainer">
                  <img
                    src={opposition_toss === "head" ? HeadsCoin : TailsCoin}
                    disabled
                    alt=""
                  />
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disabled
                  >
                    {" "}
                    {opposition_toss === "head" ? "Heads" : "Tails"}{" "}
                  </Button>
                </Box>
              </Box>
            ) : (
              ""
            )}
            {debateObject &&
            debateObject.toss_won &&
            debateObject.toss_won.won_by &&
            debateObject.toss_won.won_by === "opposition" ? (
              <p style={{ textAlign: "center" }}>{toss_won_message}</p>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

// export default TossBanner;
const mapStateToProps = ({ loginDetails: { user, auth, currentPackage } }) => {
  return {
    user,
    auth,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(TossBanner));
