import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";
import { getUserAuthenticated } from "../../redux/reducers/loginDetails";
import routes from "../../routes/routesConst";
import { useIdleTimer } from "react-idle-timer";

const SESSION_IDEL_MINUTES = 30;
const AppBody = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isUserAuthenticated = useSelector(getUserAuthenticated);
  const checkIsShareDebate = () => {
    var totalURL = window.location.href;
    var urlparam = totalURL.split("/sharedebate/");
    if (urlparam !== null && urlparam.length > 1) {
      sessionStorage.setItem(
        "redirectDashboardURL",
        "/sharedebate/" + urlparam[1]
      );
    } else {
      var vurburlparam = totalURL.split("/sharevurb/");
      if (vurburlparam !== null && vurburlparam.length > 1) {
        sessionStorage.setItem(
          "redirectShareVurbURL",
          "/sharevurb/" + vurburlparam[1]
        );
      }
    }
    return "";
  };

  const handleOnIdle = (event) => {
    // SHOW YOUR MODAL HERE AND LOGOUT
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    alert("Session has expired");
    dispatch({
      type: "LOGOUT",
      data: {},
    });
    history.push("/signin");
  };

  // const { getLastActiveTime } =
  useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <>
      <Switch>
        {isUserAuthenticated
          ? routes.map((route, index) => {
              const Component = route.component;
              return (
                <Route
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  key={index}
                >
                  <Component />
                </Route>
              );
            })
          : checkIsShareDebate()}
        <Route extact path={"/"} render={() => <Redirect to={"/signin"} />} />
      </Switch>
    </>
  );
};

export default AppBody;
