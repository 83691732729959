import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BadgeImage from '../../../assets/images/filled.svg'
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { API_Path } from '../../Common/Const/index';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import { Box } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
    dropdownMenuSelect: {
        "& button": {
            padding: '2px 5px',
            marginLeft: '5px',
            fontSize: '12px',
            color: '#fff',
            backgroundColor: '#ffa5a5',
            minWidth: 'auto',
            lineHeight: '1',
            borderRadius: '20px',
            "& svg": {
                width: '16px',
                height: '16px',
            }
        }
    }
}))

// const boostDebateDatas = [
//     {
//         expireIn: 'Expires in 60 days',
//     },
//     {
//         expireIn: 'Expires in 3 months',
//     }
// ]

const BoostDebate = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentPurchasedPlans, setCurrentPurchasedPlans] = React.useState([]);
    const [currentPurchasedFirstPlans, setCurrentPurchasedFirstPlans] = React.useState([]);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        handlePayment()
    }, [])
    const handlePayment = () => {
        var ClearNotification = API_Path + "api/paymenthistory/activeandfutureplans";
        let inputObj = {
            "inputObject": {
                userId: props.user._id,
            }
        };
        axios
            .post(ClearNotification, inputObj, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                if (response !== null && response.data !== null && response.data.result !== null && response.data.result.length > 0) {
                    setCurrentPurchasedPlans(response.data.result[0].current_purchased_plans);
                    setCurrentPurchasedFirstPlans(response.data.result[0].current_purchased_plans[0]);
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <React.Fragment>
            {props.showIcons === true ?
                <Box width="136px" textAlign="center">
                    {currentPurchasedPlans.findIndex((e) => { return e.package_name === "Boost Debate" }) > -1 ?
                        <img src={BadgeImage} alt="" style={{ marginRight: '5px' }} />
                        :
                        ""
                    }
                    {currentPurchasedPlans.findIndex((e) => { return e.package_name === "Boost User Profile" }) > -1 ?
                        <StarIcon style={{ color: '#ff1919'}} />
                        :
                        ""
                    }
                </Box>
                :
                <>
                    {currentPurchasedPlans.length > 0 &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={BadgeImage} alt="" style={{ marginRight: '5px' }} />
                            <div style={{ display: 'flex', flexFlow: 'column' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h5 className="m-0">{currentPurchasedFirstPlans.package_name}</h5>
                                    <div className={classes.dropdownMenuSelect}>
                                        <Button aria-controls="boostDebateMenu" aria-haspopup="true" onClick={handleClick}>
                                            {currentPurchasedPlans.length}
                                            <ArrowDropDownIcon />
                                        </Button>
                                        <Menu
                                            id="boostDebateMenu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            {
                                                currentPurchasedPlans.map((boostDebateData, index) => {
                                                    return (
                                                        <MenuItem key={index} onClick={handleClose}>
                                                            <img src={BadgeImage} alt="" style={{ width: '14px', margin: '-5px 5px 0 0' }} />
                                                            <div>
                                                                <h6 className="m-0">{boostDebateData.package_name}</h6>
                                                                <h6 className="m-0" style={{ fontSize: '10px', color: '#758194', fontWeight: 'normal', lineHeight: '1' }}>
                                                                    Expires {moment(boostDebateData.expiry_date).fromNow()}
                                                                </h6>
                                                            </div>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Menu>
                                    </div>
                                </div>
                                <h6 className="m-0" style={{ fontWeight: 'normal', lineHeight: 1 }}>Expires {moment(currentPurchasedFirstPlans.expiry_date).fromNow()}</h6>
                            </div>
                        </div>
                    }
                </>
            }
        </React.Fragment>
    )
}

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(BoostDebate));