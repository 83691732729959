import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { getDecryptedHashWithSecret } from "../../Common/Const";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
const useDebateSocket = (props) => {
  const location_state=getDecryptedHashWithSecret(localStorage.getItem("location.state"));
  const roomId = props.roomName ? props.roomName : (location_state.roomName ? location_state.roomName : null)
  const debateObj =(location_state.debateObj ? location_state.debateObj : null);
  const [upordownvoteObj, setUpordownvoteObj] = useState(null);
  const [hlsURL, setHlsURL] = useState(null);
  const [micMuteAndUnMuteTimerObj, setMicMuteAndUnMuteTimerObj] = useState(null);
  const [updatedDebateObj, setUpdatedDebateObj] = useState(null);
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL+"/", {
      query: { 
               roomId:roomId,
               type:"Debate",
               debateId:debateObj?debateObj._id:null,
               loggedInUserId:props.user._id,
               is_ios:false
             }
    });

    socketRef.current.on('resetUpvoteAndDownvote', function (data) {
      if (data !== null && data.length > 0) {
        setUpordownvoteObj(data[0]);
      }
    });

    // socketRef.current.on('resetDebateTimer', function (data) {
    //     setDebateTimerObj(data);
    // });

    socketRef.current.on('resetMicMuteAndUnMuteTimer', function (data) {
      setMicMuteAndUnMuteTimerObj(data);
    });
    
    socketRef.current.on('sendHlsURL', function (response) {
      setHlsURL(response);
    });

    socketRef.current.on('resetDebateObj', function (data) {
      setUpdatedDebateObj(data);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);
  
  const upordownVoteClick = (data) => {
    socketRef.current.emit('update_vote', data);
  }
  
  const likes_Click = (data) => {
        socketRef.current.emit('update_likes', data);
  }
           
  return { hlsURL, upordownvoteObj, micMuteAndUnMuteTimerObj,updatedDebateObj,upordownVoteClick, likes_Click };
};

export default useDebateSocket;
