import NewDebateProfile from "../NewDebateProfile";
import React from "react";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";

const FriendsProfilePage = (props) => {
  const params = useParams(),
    { allBlockedUsers } = props;
  let BlockedUserEnable = false;
  // this condition used for when login user view friend profile that time only we are checking condtion user are blocked or not
  if (allBlockedUsers && allBlockedUsers.length > 0) {
    const filterLength = allBlockedUsers.filter(
      (val) => val === params.friendListID
    );
    BlockedUserEnable = filterLength.length > 0 ? true : false;
  }
  return (
    <>
      {" "}
      {params.friendListID !== props.user._id ? (
        <NewDebateProfile
          friendProfile={true}
          friendId={params.friendListID}
          BlockedUserEnable={BlockedUserEnable}
        />
      ) : (
        <NewDebateProfile />
      )}
    </>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth, allBlockedUsers } }) => {
  return {
    user,
    auth,
    allBlockedUsers,
  };
};
export default withRouter(connect(mapStateToProps)(FriendsProfilePage));
