import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;

export default function useVurbsSocket(props) {
  // const location_state=getDecryptedHashWithSecret(localStorage.getItem("location.state"));
  const roomId = props.roomName ? props.roomName : null;
  const socketRef = useRef();
  const [vurb_socket_data, SetData] = useState({});
  const [commentDataList, setCommentlist] = useState(null);
  const [reload, SetReload] = useState(0);
  useEffect(() => {
    if (roomId) {
      socketRef.current = socketIOClient(SOCKET_SERVER_URL + "/", {
        query: {
          roomId: roomId,
          type: "vurbs",
          // loggedInUserId: props.user._id,
        },
      });
      socketRef.current.on("resetLikeAndDislkeCount", function (data) {
        SetData(data);
      });
      socketRef.current.on("resetcommentlist", function (data) {
        // console.log('resetcommentlist',data)

        setCommentlist(data);
        SetReload(Math.random() * 10);
      });
    }
    return () => {
      socketRef.current && socketRef.current.disconnect();
    };
  }, [roomId]);
  const UpdateLike = (data) => {
    socketRef.current.emit("update_vurb_like", data);
  };
  const UpdateComment = (data) => {
    socketRef.current.emit("update_comment", data);
  };
  return {
    UpdateLike,
    vurb_socket_data,
    UpdateComment,
    commentDataList,
    reload,
  };
}
