import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Chat from "../../Common/Chat/Chat";
import { API_Path } from "../../Common/Const/index";
import AccountCircle from "@material-ui/icons/AccountCircle";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
const useStyles = makeStyles((theme) => ({
  friendsChat: {
    position: "fixed",
    // right: '15px',
    bottom: "68px",
    width: "350px",
    // height: '410px',
    height: "62vh",
    right: 370,
    backgroundColor: "#fff",
    boxShadow: "-3px 9px 18px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "6px 6px 0 0",
    zIndex: 9,
    "& .friendsChatHeader": {
      padding: "18px 12px",
      backgroundColor: "#ffc003",
      display: "flex",
      alignItems: "center",
      borderRadius: "6px 6px 0 0",
      "& a": {
        color: "#fff",
        fontSize: "18px",
        textDecoration: "none",
      },
      "& .userImg": {
        width: 35,
        height: 35,
        borderRadius: "50%",
        objectFit: "cover",
        marginRight: 15,
      },
    },
    "& .friendsChatContent": {
      padding: "24px 18px",
      overflow: "auto",
      "& .chatSection": {
        marginBottom: "10px",
        "& .chatImage": {
          flex: "0 0 34px",
          maxWidth: "34px",
          width: "34px",
          height: "34px",
        },
        "& h5, & h6, & .MuiSvgIcon-root": {
          display: "none",
        },
        "& p": {
          fontSize: "12px",
          color: "#121213",
          backgroundColor: "#dce7ff",
          padding: "6px 10px",
          borderRadius: "20px",
          minWidth: "60px",
          position: "relative",
          "&:after": {
            content: '""',
            position: "absolute",
            bottom: "-5px",
            left: "8px",
            backgroundColor: "#dce7ff",
            width: "10px",
            height: "10px",
            borderRadius: "0px 0px 10px 0px",
            transform: "rotate(15deg)",
          },
        },
        "&.sender": {
          display: "flex",
          flexFlow: "row-reverse",
          "& .chatImage": {
            margin: "0 0 0 12px",
          },
          "& p": {
            // backgroundColor: '#ffc003',
            // color: '#fff',
            "&:after": {
              left: "auto",
              right: 8,
              borderRadius: "0px 0px 0px 10px",
              // backgroundColor: '#ffc003',
              transform: "rotate(-15deg)",
            },
          },
        },
      },
      "& .MuiOutlinedInput-multiline": {
        minHeight: "36px",
        backgroundColor: "#f6f7f8",
        border: "none",
        boxShadow: "none",
        "& .MuiOutlinedInput-inputMultiline": {
          fontSize: "10px",
          color: "#758194",
        },
      },
      "& .chatSend": {
        "& img": {
          width: "18px",
        },
      },
      "& .hideEmoji": {
        height: "0",
      },
    },
  },
  activeStatus: {
    position: "absolute",
    right: "10px",
    top: "-8px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "14px",
    },
  },
}));

const ChatWindow = (props) => {
  const classes = useStyles();
  const [chatEnable, setChatEnable] = useState(false);
  const [blockedUser, setBlockedUser] = useState(null);
  const [chatRoom, setChatRoom] = useState(null);
  const [chatType, setChatType] = useState(null);

  const handleChat = useCallback(() => {
    var ratindDetails = API_Path + "api/friendschatroom/create";
    var chat_type =
      (props.friendObj && props.friendObj.isSupport) || props.user.isSupport
        ? "support"
        : "friends";
    let inputObj = {
      inputObject: {
        user1_id: props.user._id,
        user2_id: props.friendObj._id,
        chat_type: chat_type,
      },
    };
    axios
      .post(ratindDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setBlockedUser(
          response.data.result.user2_id.blocked_users.includes(props.user._id)
        );
        setChatRoom(response.data.result.room_name);
        setChatType(response.data.result.chat_type);
        setChatEnable((chatEnable) => !chatEnable);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [
    props.auth.accessToken,
    props.friendObj,
    props.user._id,
    props.user.isSupport,
  ]);
  useEffect(() => {
    if (!chatEnable) handleChat();
  }, [chatEnable]);
  return (
    <>
      {chatEnable !== null && chatEnable !== "" && (
        <Box
          className={classes.friendsChat}
          display="flex"
          flexDirection="column"
        >
          <Box className="friendsChatHeader" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              target="_blank"
              component={Link}
              to={`/friendsProfilePage/${props.friendObj._id}`}
            >
              {props.friendObj.profile_image !== null ? (
                <div style={{position:"relative"}}>
                <img
                  src={props.friendObj.profile_image}
                  alt=""
                  className="userImg"
                />
                {!!props.friendObj.badge_status && (
                  <span
                    className={classes.activeStatus}
                    title="Verified User/Public Figure"
                  >
                    <VerifiedUserRoundedIcon />
                  </span>
                )}
                </div>
              ) : (
                <AccountCircle
                  style={{
                    width: "40",
                    height: "40",
                    marginRight: "10px",
                    backgroundColor: "none",
                  }}
                />
              )}

              {props.friendObj.full_name}
            </Box>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={props.closeFriendChat}
            />
          </Box>
          <Box
            className="friendsChatContent"
            display="flex"
            flexDirection="column"
            height="100%"
            style={{ overflow: "hidden", padding: "0 8px" }}
          >
            {chatRoom !== null && chatType ? (
              <Chat
                roomName={chatRoom}
                chatType={chatType}
                toUserId={props.friendObj._id}
                blockedUser={blockedUser}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(ChatWindow));
