import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";
import upgrade from '../../../assets/images/friendsProfile/upgrade.svg'

const useStyles = makeStyles((theme) => ({
    upgradeBtn: {
        color: '#fff',
        fontWeight: '500',
        padding: '6px 15px',
        borderRadius:"20px",
    },
}))

const UpgradeButton = () => {
    const classes = useStyles();
    const history = useHistory();
    let location = useLocation();
    const handleRedirect = () => {
        localStorage.setItem('backTo', location.pathname);
        history.push("/payment")
    }
    return (
        <>
            <Button onClick={handleRedirect} variant="contained" color="primary" className={classes.upgradeBtn}><img src={upgrade} alt="" style={{ marginRight: '5px' }} /> Upgrade</Button>
        </>
    )
}

export default UpgradeButton
