let initialState = {
  vurbApiLoading: false,
};

export default (state = initialState, action) => {
  // let { auth, user } = action.data;

  switch (action.type) {
    case "LOGIN_GLOBAL_DETAILS":
      const { allBlockedUsers, vurbApiLoading } = action.data;
      return {
        ...state,
        authenticated: action.data.authenticated,
        auth: action.data.auth,
        user: action.data.user,
        currentPackage: action.data.currentPackage,
        is_disable_ads: action.data.is_disable_ads,
        is_disable_broadcast: action.data.is_disable_broadcast,
        logo: action.data.logo,
        reminder_content: action.data.reminder_content,
        allBlockedUsers:
          allBlockedUsers && allBlockedUsers.length ? allBlockedUsers : [],
        vurbApiLoading: vurbApiLoading,
      };
    case "AWS_DETAILS":
      return {
        ...state,
        hashed: action.data.hashed,
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export const getLoginDetails = (state) => state.loginDetails.data;
export const getUserAuthenticated = (state) => {
  return state.loginDetails.authenticated
    ? state.loginDetails.authenticated
    : false;
};
