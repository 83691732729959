import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { API_Path } from "../../Common/Const";
import CustomDropdown from "../../Common/CustomDropdown";
import CustomSearch from "../../Common/CustomSearch";
import MembersCard from "./MembersCard";

const options = [
  "",
  "All Members",
  "My Vurbers",
  "Invited",
  "Requested",
  "Blocked",
];

const MyVurbers = (props) => {
  const [myVurbersList, setMyVurbersList] = useState([]);
  const [loading, setLoader] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [type, setType] = useState("My Vurbers");
  const [vurbersTotalCount, setVurbersTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIndex, setSelectedIndex] = React.useState(2);
  const observer = useRef();
  const lastVurber = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (vurbersTotalCount > myVurbersList.length) {
          setLoader(true);
          friendListDatas(searchVal, type, currentPage + 1);
          setCurrentPage(currentPage + 1);
        }
      }
    });
    if (node) observer.current.observe(node);
  });

  useEffect(() => {
    if (!props.viewAll) {
      friendListDatas(searchVal, type, currentPage);
    }
  }, []);
  useEffect(() => {
    if (props.viewAll) {
      setType("All Members");
      setSelectedIndex(1);
      setLoader(true);
      friendListDatas(searchVal, "All Members", 1);
      setCurrentPage(1);
    }
  }, [props.viewAll]);
  const friendListDatas = (searchText, type, currentPageVal) => {
    let friendListAPI = API_Path + "api/friends/search";
    if (searchText.length >= 3 || searchText === "") {
      let inputObj = {
        inputObject: {
          type: type,
          userId: props.user._id,
          searchText: searchText,
          pagination: {
            currentpage: currentPageVal ? currentPageVal : 1,
            recordsperpage: 10,
          },
        },
      };
      axios
        .post(friendListAPI, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (currentPageVal !== 1) {
            setMyVurbersList((p) => [...p, ...response.data.result.data]);
            setVurbersTotalCount(response.data.result.totalCount);
          } else {
            setMyVurbersList(response.data.result.data);
            setVurbersTotalCount(response.data.result.totalCount);
          }

          setLoader(false);
          // setVurbersTotalCount(response.data.result.totalCount);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleSearch = (event) => {
    setCurrentPage(1);
    setSearchVal(event.target.value);
    // getUserViewdata(props.user._id);
    friendListDatas(event.target.value, type, 1);
    // if (event.target.value === "") {
    //   setPage(1);
    // }
  };
  const handleMenuItemClick = (event, index, val) => {
    setType(val);
    setSelectedIndex(index);
    setLoader(true);
    setMyVurbersList([]);
    friendListDatas(searchVal, val, 1);
    setCurrentPage(1);
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <Grid container spacing={2} style={{ position: "relative" }}>
        <Grid item xs={12} md={6}>
          <CustomSearch handleSearch={handleSearch} />
          {/* <TextField id="filled-basic" placeholder="Search" variant="filled" onChange={handleSearch} /> */}
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <CustomDropdown
            options={options}
            selectedIndex={selectedIndex}
            handleMenuItemClick={handleMenuItemClick}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        {myVurbersList.length > 0
          ? myVurbersList.map((list, index) => {
              const blockedUser =
                list &&
                list.blocked_users &&
                list.blocked_users.includes(props.user._id);
              const blockedByMe =
                props.userData &&
                props.userData.blocked_users &&
                props.userData.blocked_users.includes(list._id);

              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  ref={myVurbersList.length - 1 === index ? lastVurber : null}
                >
                  <MembersCard
                    list={list}
                    blockedUser={blockedUser}
                    blockedByMe={blockedByMe}
                    user={props.user}
                    auth={props.auth}
                    refresh={() => friendListDatas(searchVal, type, 1)}
                    // ref={
                    //   myVurbersList.length - 1 === index
                    //     ? { ref: lastVurber }
                    //     : {}
                    // }
                  />
                </Grid>
              );
            })
          : !loading && (
              <Paper>
                <Box p={3}>
                  <Typography align="center" variant="body1">
                    No Vurbers Available
                  </Typography>
                </Box>
              </Paper>
            )}
      </Grid>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export default MyVurbers;
