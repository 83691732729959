import React from "react"
import { Box, makeStyles } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
    parentContainer: {
        display: 'flex',
        cursor:'pointer',
        backgroundColor:"#f0f2f5",
        padding:"4px 0",
        margin:'8px 0'
    },
    ImageContainer:{
        width:'184px',
        "& img":{
            width:'100%',
            height:'100%'
        }
    },
    ContentContainer:{
        display:'flex',
        flexDirection:'column',
        marginLeft:'8px',
        "& h6":{
            margin:'0'
        },
        "& h3":{
            margin:"0"
        },
        "& p":{
            margin:'0'
        }
    }
}))

const CommonMeta = ({data,redirect}) => {
    const classes = useStyles();
return  (
    <Box className={classes.parentContainer} onClick={() => redirect(data.url)}>
        {data.images && data.images.length > 0 && data.images[0].includes('https') && <div className={classes.ImageContainer}>
            <img src={data.images[0]} alt="meta-image" />
        </div>}
        <div className={classes.ContentContainer}>
            {!!data?.domain && <h6>{data.domain}</h6>}
            {!!data?.title && <h3>{data.title}</h3>}
            {!!data?.description && <p>{data.description}</p>}
        </div>
    </Box>
)
}
export default CommonMeta