/* Let CRA handle linting for sample app */
import axios from 'axios';
// import Spinner from 'react-spinner';
import classNames from 'classnames';
import AccCore from 'opentok-accelerator-core';
import 'opentok-solutions-css';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { API_Path } from '../../Common/Const/index';
import Loader from "../../Common/Loader";
import './App.scss';

let otCore;
// var opentok = new OpenTok("47052404", "09b6be77c47e4f16de402cef1400a1e05137a206");
const otCoreOptions = {
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: '#cameraPublisherContainer',
        screen: '#screenPublisherContainer',
      },
      subscriber: {
        camera: '#cameraSubscriberContainer',
        screen: '#screenSubscriberContainer',
      },
    }[pubSub][type];
  },
  controlsContainer: '#controls',
  packages: ['textChat', 'screenSharing', 'annotation'],
  communication: {
    callProperties: null, // Using default
  },
  textChat: {
    name: ['David', 'Paul', 'Emma', 'George', 'Amanda'][Math.random() * 5 | 0], // eslint-disable-line no-bitwise
    waitingMessage: 'Messages will be delivered when other users arrive',
    container: '#chat',
  },
  screenSharing: {
    extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
      style: {
        buttonDisplayMode: 'off',
      },
      videoSource: 'screen',
      fitMode: 'contain' // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: '.App-video-container',
      subscriber: '.App-video-container'
    }
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames('App-control-container', { hidden: !active }),
    localAudioClass: classNames('ots-video-control circle audio', { hidden: !active, muted: !localAudioEnabled }),
    localVideoClass: classNames('ots-video-control circle video', { hidden: !active, muted: !localVideoEnabled }),
    localCallClass: classNames('ots-video-control circle end-call', { hidden: !active }),
    cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }),
    screenPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
    cameraSubscriberClass: classNames('video-container', { hidden: !active || !activeCameraSubscribers },
      { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive },
      { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames('video-container', { hidden: !viewingSharedScreen || !active }),
  };
};

const connectingMask = () =>
  <div className="App-mask">
    {/* <Spinner /> */}
    <div className="message with-spinner">Connecting</div>
  </div>;

class OpenTokVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      broadCastId: null,
      loader: false,
      videoEnd: false,
      displayLive: false,
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.startBroadCast = this.startBroadCast.bind(this);
    this.stopBroadCast = this.stopBroadCast.bind(this);
  }

  componentDidMount() {
    this.initSession(this.props.debateObj.ot_sessionId, this.props.debateObj.ot_sessionToken);
  }
  componentWillUnmount() {
    if (this.state.connected) {
      otCore.endCall();
      otCore.disconnect();
      this.setState({ active: false });
    }
  }

  componentDidUpdate(prevProps) {
    var nextProps = this.props;

    if(this.state.displayLive == false && (!nextProps.micmuteunmuteobj.count || nextProps.micmuteunmuteobj<=0)){
      this.setState({ displayLive: true });
    }

    if (nextProps.micmuteunmuteobj !== prevProps.micmuteunmuteobj) {
      if (nextProps.isProposition === true) {
        if (nextProps.micmuteunmuteobj !== null) {
          if (nextProps.micmuteunmuteobj.type === "fix") {
            if (nextProps.micmuteunmuteobj.proposition === 'unmute') {
              otCore.toggleLocalAudio(true);
              this.setState({ localAudioEnabled: true });
            } else {
              otCore.toggleLocalAudio(false);
              this.setState({ localAudioEnabled: false });
            }
          }
        }
      }
      if (nextProps.isOpposition === true) {
        if (nextProps.micmuteunmuteobj !== null) {
          if (nextProps.micmuteunmuteobj.type === "fix") {
            if (nextProps.micmuteunmuteobj.opposition === 'unmute') {
              otCore.toggleLocalAudio(true);
              this.setState({ localAudioEnabled: true });
            } else {
              otCore.toggleLocalAudio(false);
              this.setState({ localAudioEnabled: false });
            }
          }
        }
      }
      if (nextProps.micmuteunmuteobj.type === 'endcallalert' && (nextProps.micmuteunmuteobj.alertSeconds === 0 || nextProps.micmuteunmuteobj.alertSeconds === '0')) {
        // this.endCall(); // end call it will call
      }
      if (nextProps.micmuteunmuteobj.type === 'endcall') {
        this.endCall();
      }
    }


  }

  initSession = (sessionId, token) => {
    if (sessionId !== undefined && sessionId !== "") {
      otCoreOptions.credentials = {
        apiKey: process.env.REACT_APP_API_KEY,
        sessionId: sessionId,
        token: token,
      }

      otCore = new AccCore(otCoreOptions);

      otCore.connect().then(() => {
        this.setState({ connected: true })
        this.startCall();
      });
      const events = [
        'subscribeToCamera',
        'unsubscribeFromCamera',
        'subscribeToScreen',
        'unsubscribeFromScreen',
        'startScreenShare',
        'endScreenShare',
      ];

      events.forEach(event => otCore.on(event, ({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta });

      }));
    }


  }

  startBroadCast() {
    var startBroadCastAPI = API_Path + "startBroadCast";
    let streamId='';
    for (let key in this.state.publishers.camera) {
      if (this.state.publishers.camera.hasOwnProperty(key)) {
        console.log((this.state.publishers.camera[key].streamId));
           streamId = this.state.publishers.camera[key].streamId;
      }
    } 
    // console.log(this.state.subscribers);
    const stream = {user_id: this.props.user._id,stream_id: streamId};
    var inputObj = { debateId: this.props.debateObj._id,stream_data:stream };
   
    axios
      .post(startBroadCastAPI, inputObj, {
        headers: {
          'Authorization': "Basic " + this.props.auth.accessToken
        }
      })
      .then((response) => { 
        // if(response && response.data && response.data.result && response.data.result.status === 2) {
        //   window.location.href = '/recorded';
        // }
      });
  }

  stopBroadCast(str) {
    
    var stopBroadCastAPI = API_Path + "stopBroadCast";
    var inputObj = { debateId: this.props.debateObj._id };

    axios
      .post(stopBroadCastAPI, inputObj, {
        headers: {
          'Authorization': "Basic " + this.props.auth.accessToken
        }
      })
      .then((response) => {
        this.setState({ loader: false })
        // window.location.href = '/activeDebateList';
        // this.setState({ videoEnd: true })
        if(str==="activeDebate"){
          window.location.href = '/activeDebateList';
        }else if(str==="recorded"){
          window.location.href = '/recorded';
        }
      });
  }

  startCall() {
    var self = this;
    otCore.startCall()
      .then(({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta, active: true }, () => {
          self.startBroadCast();
        });
      }).catch(error => console.error(error));
  }

  endCall() {
    var self = this;
    otCore.endCall();
    this.setState({ active: false, loader: true }, () => {
      self.stopBroadCast('recorded');
    });
  }
  endCallwithRedirect = () => {
    // window.location.href='/activeDebateList';
    this.stopBroadCast('activeDebate');
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }


  render() {
    const { connected, active, loader, videoEnd } = this.state;
    const {
      localAudioClass,      
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);
    return (
      <div className="App" style={this.state.displayLive ? {}:{display: 'none'}}>
        {videoEnd ? <Redirect to="/activeDebateList" /> : ""}
        <div className="App-main">
          <div className="App-video-container">
            {/* { !connected && connectingMask() } */}
            {/* { connected && !active && startCallMask(this.startCall)} */}
            {!connected && !active && connectingMask(this.state.resolution)}
            <div id="cameraPublisherContainer" className={cameraPublisherClass} />
            <div id="screenPublisherContainer" className={screenPublisherClass} />
            <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />
            <div id="screenSubscriberContainer" className={screenSubscriberClass} />
          </div>
          <div id="controls" className={controlClass}>
            <div className={localAudioClass}
            // onClick={this.toggleLocalAudio} 
            />
            <div className={localCallClass}
              onClick={this.endCallwithRedirect}
            />
            {/* <div className={localVideoClass} onClick={this.toggleLocalVideo} /> */}
          </div>
        </div>
        {loader && <Loader />}
      </div>
    );
  }
}

// export default OpenTokVideo;
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user, auth
  };
};
export default connect(mapStateToProps)(OpenTokVideo);

