import { TextField, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import axios from "axios";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import s3FileUpload from "react-s3";
import docicon from "../../../assets/images/Doc.png";
import docmenticon from "../../../assets/images/docment.png";
import imgSend from "../../../assets/images/Icon-feather-send.svg";
import imageicon from "../../../assets/images/image-24.png";
import imgEmoji from "../../../assets/images/smile.svg";
import { API_Path, getDecryptedHashWithSecret } from "../../Common/Const/index";
//import playButton from "../../../assets/images/play-button.png";
// import './css/main.css';
import useChatSocket from "../Sockets/useChatSocket";
//import AccountCircle from "@material-ui/icons/AccountCircle";
import { Box, InputBase } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Cancel, Gif } from "@material-ui/icons";
import ReactPlayer from "react-player";
import defaultavatar from "../../../assets/images/default-avatar.png";
import SnackBar from "../../../components/SnackBar";
import CommonGif from "../../Common/CommonGif";
import Report from "../ReportAbuse/Report";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import { decrypt } from "../../../lib/uriUtils";
let { REACT_APP_AWS_S3_CHAT_BUGKET_NAME, REACT_APP_AWS_S3_REGINE, REACT_APP_AWS_S3_ACCESSID, REACT_APP_AWS_S3_SECRET_ACCESSID } = process.env
// const config = {
//   bucketName: REACT_APP_AWS_S3_CHAT_BUGKET_NAME,
//   region: REACT_APP_AWS_S3_REGINE,
//   accessKeyId: REACT_APP_AWS_S3_ACCESSID,
//   secretAccessKey:REACT_APP_AWS_S3_SECRET_ACCESSID,
// };
const config = {
  bucketName: "",
  region: REACT_APP_AWS_S3_REGINE,
  accessKeyId: "",
  secretAccessKey:"",
};

const useStyles = makeStyles((theme) => ({
  searchWhiteBg: {
    width: "50%",
    border: "1px solid #d9d9d9",
    borderRadius: "30px",
    margin: "8px 0",
    "& input": {
      backgroundColor: "#fff",
      width: "49%",
    },
  },
  chatText: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
    "& .MuiFormControl-root": {
      maxHeight: "inherit",
      "& .MuiOutlinedInput-multiline": {
        marginTop: "0",
        minHeight: "44px",
        borderRadius: "22px",
        backgroundColor: "#fff",
        color: "#758194",
        paddingRight: "130px",
        fontSize: "14px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        borderColor: "transparent",
      },
    },
    "& .chatSend": {
      position: "absolute",
      right: "0",
      lineHeight: "1",
      "& img": {
        marginRight: "15px",
      },
    },
  },
  InputDoc: {
    minWidth: "34px",
    opacity: "0",
    "& input": {
      width: "35px",
      height: "25px",
      // top: "-180px",
      // position: "absolute",
      // left: "227px",
    },
    "& button": {
      cursor: "pointer",
    },
  },
  gifBtn: {
    border: "1px solid #d3d8dd",
    borderRadius: "6px",
    color: "#d3d8dd",
    // backgroundColor:'#d3d8dd',
    fontSize: "20px",
    marginRight: "10px",
    cursor: "pointer",
  },
  VideoName: {
    position: "relative",
    // right: "94px",
    right: "0px",
    display: "inline-block",
    overflow: " hidden",
    width: "150px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    top: "0px",
    //  top:"24px"
  },
  docName: {
    position: "relative",
    right: "58px",
    top: "20px",
    display: "inline-block",
    width: "180px",
    overflow: " hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  InputAttach: {
    minWidth: "34px",
    opacity: "0",
    "& input": {
      width: "35px",
      height: "25px",
      // top: "-90px",
      // bottom: "60px",
      // position: "absolute",
      // left: "227px",
    },
    "& button": {
      cursor: "pointer",
    },
  },
  inputImage: {
    // position: "absolute",
    // color: "#ffc003",
    top: "2px",
    color: "#ffc003",
    right: "6px",
    position: "absolute",
    fontSize: "14px",
    borderRadius: "14px",
  },
  activeStatus: {
    position: "absolute",
    right: "0px",
    top: "-4px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "12px",
      display:"block !important"
    },
  },
}));
function Chat(props) {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const { send_text_message } = useChatSocket(props);
  const [addTextEmoji, setAddTextEmoji] = useState(false);
  const myRef = useRef(null);
  const [attach, setAttach] = useState(false);
  const [message, setMessage] = useState("");
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [messageType, setmessageType] = useState("text");
  const [gifModal, setGifModal] = useState(false);
  const [snackbarState, setSnackbarState] = React.useState({ open: false });

  const [loader, Setloader] = React.useState(false);
  const [profileKey, setProfileKey] = useState("")
  const [profileSecret, setProfileSecret] =useState("")
  const [bucket, setBucket] = useState("")

  useEffect(() => {
    reloadMessages();
    let data = getDecryptedHashWithSecret(props.hashed)
    setProfileKey(data.profileApiKey)
    setProfileSecret(data.profileApiSecret)
    setBucket(data.vurbChatBucket)
  }, []);
  useEffect(() => {
    if(messageType&&(messageType==='Doc'||messageType==="gif"||messageType==="video")&&attachmentFile.length>0){
      onPressSend()
    }
    // eslint-disable-next-line
  }, [messageType,attachmentFile]);

  const reloadMessages = () => {
    var inputObj = {
      inputObject: {
        room_name: props.roomName,
        chat_type: props.chatType,
      },
    };
    axios
      .post(API_Path + "api/friendschat/get", inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data !== null && response.data.result !== null) {
          let data=response.data.result;
          if(process.env.REACT_APP_CHAT_ENCRYPT_DECRYPT_ENABLE==='ON'){
            data = getDecryptedHashWithSecret(response.data.result);
          }
          setMessages(data);
          setTimeout(() => {
            scrollToBottom();
          }, 1000);
        } else {
          setMessages([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendTextMessage = async () => {
    var userName = props.user.name;
    var userId = props.user._id;
    if ((message, attachmentFile)) {
      var inputObj = {
        fromUserId: userId,
        toUserId: props.toUserId,
        username: userName,
        roomId: props.roomName,
        messageType: messageType,
        attachmentLinks: attachmentFile,
        message: message,
        chatType: props.chatType,
        timestamp: new Date(),
        imgURL: props.user.profile_image,
      };
      send_text_message(inputObj);
      setMessage("");
      Setloader(false)
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
      // await axios
      //     .post(API_Path + 'send_message', inputObj, {
      //         headers: {
      //             'Authorization': "Basic " + props.auth.accessToken
      //         }
      //     })
      //     .then((response) => {
      //         if (response.data.status === 'OK') {
      //             // sendMessage(inputObj);
      //             setMessage('');
      //             setTimeout(() => {
      //                 scrollToBottom();
      //             }, 1000)
      //         }
      //     })
      //     .catch((error) => {
      //         console.error(error);
      //     });
    }
  };
  const handleChange = (event) => {
    setMessage(event.target.value);
    setAttach(false);
  };
  // const customLikesClick = (type, rowobject) => {
  //     var inputObj = { type: type, id: rowobject._id };
  //     likesClick(inputObj);
  // }

  const onPressSend = (e) => {
    if (message.trim() === "" && attachmentFile.length === 0) {
      setSnackbarState({
        open: true,
        message: "invalid Message",
        severity: "error",
      });
    } else {
      sendTextMessage();
      setAddTextEmoji(false);
      setAttachmentFile([]);
      setmessageType("text");
      setAttach(false);
      setGifModal(false);
    }
  };
  const addEmoji = (event) => {
    let emoji = event.native;
    setMessage(message + emoji);
  };

  const addTextEmojiClick = () => {
    setAddTextEmoji(!addTextEmoji);
    setGifModal(false);
  };
  const attachmentClick = () => {
    setAttach(!attach);
  };
  const handleClear = (type, index) => {
    let img = [...attachmentFile];
    let file = type === "image" ? img[index] : " ";
    fileDelete(file, type);
    img.splice(index, 1);
    setAttachmentFile([...img]);
    // type === "video" && setVideo([]);
  };
  const scrollToBottom = () => {
    if (myRef.current) {
      // myRef.current.scrollTo(0, myRef.current.scrollHeight);
      myRef.current.scrollTop = myRef.current.scrollHeight;
      updateChatReadStatus();
    }
  };
  const updateChatReadStatus = () => {
    var fromUserId = props.toUserId;
    var toUserId = props.user._id;

    let inputObj = {
      inputObject: {
        fromUserId: fromUserId,
        toUserId: toUserId,
      },
    };
    axios
      .post(API_Path + "api/auth/updateChatReadStatus", inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };
  const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  const handlePostImage = async (e) => {
    Setloader(true);
    setAttach(false);
    // setImage([window.URL.createObjectURL(e.target.files[0])])
    let tempImage = [...attachmentFile];
    if (
      tempImage.length > 0
        ? tempImage.length + e.target.files.length <= 4
        : e.target.files.length <= 4
    ) {
      for (let file of e.target.files) {
        if (
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/webp"
        ) {
          let result = await fileUpload(file, "image");
          tempImage.push(result.location);
          setmessageType("image");
          setAttachmentFile(tempImage);
        } else {
          if (tempImage.length <= 1 && e.target.files.length <= 1) {
            let video = document.createElement("video");
            video.preload = "metadata";
            video.onloadedmetadata = async () => {
              // window.URL.revokeObjectURL(video.src);
              // console.log(video.duration, video.src, file)
              if (video.duration > 60) {
                setSnackbarState({
                  open: true,
                  message: "Video should be less than 60 Sec",
                  severity: "error",
                });
                return false;
              } else {
                let result = await fileUpload(file, "video");
                tempImage.push(result.location);
                setmessageType("video");
                setAttachmentFile(tempImage);
              }
            };
            video.src = URL.createObjectURL(e.target.files[0]);
          } else {
            setSnackbarState({
              open: true,
              message: "4 Images or 1 Video  is allowed",
              severity: "error",
            });
          }
        }
      }
    } else {
      setSnackbarState({
        open: true,
        message: " 4 Images or 1 Video  is allowed",
        severity: "error",
      });
    }

    // Setloader(false);
  };

  const docments = async (e) => {
    Setloader(true);
    let tempDoc = [...attachmentFile];
    for (let file of e.target.files) {
      let result = await fileUpload(file, "docs");
      tempDoc.push(result.location);
    }
    setmessageType("Doc");
    setAttachmentFile(tempDoc);
    // Setloader(false);
  };
  const handleGif = (gif) => {
    let tempGif = [...attachmentFile];
    if (tempGif.length < 4) {
      tempGif.push(gif);
      setmessageType("gif");
    } else {
      setSnackbarState({
        open: true,
        message: "4 Images or 4 Gif is allowed",
        severity: "error",
      });
    }
    setAttachmentFile(tempGif);
  };
  const handlegifpopup = () => {
    setGifModal(!gifModal);
    setAddTextEmoji(false);
  };
  const fileDelete = async (url, type) => {
    config.bucketName = bucket
    config.accessKeyId = profileKey;
    config.secretAccessKey = profileSecret;
    const fileName = url.split("/")[url.split("/").length - 1];
    await s3FileUpload
      .deleteFile(fileName, config)
      .then((data) => data)
      .catch((e) => console.log("err", e));
  };
  const fileUpload = async (file, type) => {
    config.bucketName = bucket
    config.accessKeyId = profileKey;
    config.secretAccessKey = profileSecret;
    file = new File(
      [file],
      `${type === "image" ? "i" : type === "video" ? "v" : "d"}__${
        props.user._id
      }__${Date.now().toString()}__${file.name}`
    );
    return await s3FileUpload
      .uploadFile(file, config)
      .then((data) => data)
      .catch((e) => console.log("err", e));
  };
  const openWindow = (data) => {
    window.open(data);
  };
  return (
    <React.Fragment>
      {/* {props.enableLikes?
    <div className="userlikes" style={{display:'flex',flexFlow:'row',flexWrap:'wrap'}}>
        {chatters.map((row, index) => {
            var html="";
            if(row.userType==='Debator A'||row.userType==='Debator B'){
                return(
                    <div style={{width:'50%',maxWidth:'50%'}}>
                        {row.username+"("+row.userType+")"}&nbsp;&nbsp;
                        <img alt="" src={(row.likes ? uparrowHighlight : uparrow)} style={{width:'20px'}} onClick={customLikesClick.bind(this,'increment',row)} />&nbsp;
                        {row.totalCount}&nbsp;
                        <img alt="" src={(row.dislikes ? downarrowHighlight : downarrow)} style={{width:'20px'}} onClick={customLikesClick.bind(this,'decrement',row)} /><br/><br/>
                    </div>
                )
            }
                
        })}

    </div>
    :""} */}
      <div className="messages" ref={myRef}>
        {messages.map((row, index) => {
          var classname = "sender";
          if (props.user._id === row.from_user_id._id) {
            classname = "receiver";
          }
          let newDate = new Date(row.timestamp);
          let eventDate = moment([
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate(),
            newDate.getHours(),
            newDate.getMinutes(),
          ]).fromNow();
          return (
            <div key={index} className={"chatSection flexRow msg " + classname}>
              <div
                className={
                  row.from_user_id && row.from_user_id.profile_image !== null
                    ? "chatImage"
                    : "chatdummyImage"
                }
              >
                {row.from_user_id && row.from_user_id.profile_image !== null ? (
                  <div style={{position:"relative"}}>
                  <img alt="" src={row.from_user_id.profile_image} />
                  {row.from_user_id && !!row.from_user_id.badge_status && (
                  <span
                    className={classes.activeStatus}
                    title="Verified User/Public Figure"
                  >
                    <VerifiedUserRoundedIcon />
                  </span>
                )}
                  </div>
                ) : (
                  <img alt="" src={defaultavatar} />
                )}
              </div>
              <div className="chatContainer">
                <div
                  className="flexRow"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <div>
                    <h5 className="user">{row.from_user_id.full_name}</h5>
                    <h6>{eventDate}</h6>
                  </div>
                  {/* <Report
                    showType="Icon"
                    type="Reporting Comment"
                    primaryKey={row._id}
                  /> */}
                </div>

                {row.attachmentLinks.map((data, index) => {
                  let docName = "";
                  if (
                    row.message_type === "Doc" ||
                    row.message_type === "video"
                  ) {
                   let recorded_url = data.split("/")[data.split("/").length - 1];
                    docName = recorded_url.split("__")[recorded_url.split("__").length - 1];
                  }
                  return row.message_type === "video" &&
                    classname === "sender" ? (
                    <div style={{ marginBottom: "20px" }} key={index}>
                      <ReactPlayer
                        className="react-player"
                        url={data}
                        width="50%"
                        height="50%"
                        controls
                        style={{ margin: "0 0 0 auto" }}
                      />
                      {/* <video onClick={ () => openWindow(data)} className="txt" src={playButton} style={{ height: "70px", width: "70px", paddingTop: "10px",cursor:'pointer'}}></video> */}
                      <div style={{ textAlign: "end" }}>
                        {/* <span
                          className={classes.VideoName}
                          style={{ fontSize: "12px" }}
                        >
                          {docName}
                        </span> */}
                      </div>
                    </div>
                  ) : row.message_type === "video" ? (
                    <div style={{ marginBottom: "20px" }} key={index}>
                      <ReactPlayer
                        className="react-player"
                        url={data}
                        width="50%"
                        height="50%"
                        controls
                      />
                      {/* <span
                        className={classes.VideoName}
                        style={{ fontSize: "12px" }}
                      >
                        {docName}
                      </span> */}
                    </div>
                  ) : row.message_type === "Doc" && classname === "sender" ? (
                    <div style={{ marginBottom: "20px" }} key={index}>
                      <div style={{ textAlign: "end" }}>
                        <img
                          alt=""
                          onClick={() => openWindow(data)}
                          className="txt"
                          src={docmenticon}
                          style={{
                            height: "50px",
                            width: "40px",
                            paddingTop: "10px",
                            cursor: "pointer",
                          }}
                        ></img>
                      </div>
                      <div>
                        <span
                          className={classes.docName}
                          style={{
                            fontSize: "12px",
                            right: "0%",
                            top: "0%",
                            textAlign: "end",
                          }}
                        >
                          {docName}
                        </span>
                      </div>
                    </div>
                  ) : row.message_type === "Doc" ? (
                    <div style={{ marginBottom: "20px" }} key={index}>
                      <img
                        alt=""
                        onClick={() => openWindow(data)}
                        className="txt"
                        src={docmenticon}
                        style={{
                          height: "50px",
                          width: "40px",
                          paddingTop: "10px",
                          cursor: "pointer",
                        }}
                      ></img>
                      <span
                        className={classes.docName}
                        style={{ fontSize: "12px" }}
                      >
                        {docName}
                      </span>
                    </div>
                  ) : classname === "sender" ? (
                    <div style={{ textAlign: "end" }} key={index}>
                      <img
                        alt=""
                        className="txt"
                        src={data}
                        onClick={() => openWindow(data)}
                        style={{
                          height: "106px",
                          width: "123px",
                          paddingTop: "10px",
                          cursor: "pointer",
                        }}
                      />
                      <br></br>
                    </div>
                  ) : (
                    <>
                      <img
                        key={index}
                        alt=""
                        className="txt"
                        src={data}
                        onClick={() => openWindow(data)}
                        style={{
                          height: "106px",
                          width: "123px",
                          paddingTop: "10px",
                          cursor: "pointer",
                        }}
                      />
                      <br></br>
                    </>
                  );
                })}
                {row.message === "" ? " " : <p className="txt">{row.message}</p>}
              </div>
            </div>
          );
        })}
        {/* <div ref={myRef}></div> */}
      </div>

      {/* <textarea name="message" id="message" cols="90" rows="5" placeholder="Enter your message..." onChange={handleChange} onKeyDown={onEnterPress} value={message}></textarea> */}
      <Box style={{ display: "flex" }}>
        {attachmentFile.length > 0 &&
          attachmentFile.map((image, index) => {
            // let a = image.split('.')
            // let type = a[a.length -1]
            return (
              <div
                key={index}
                style={{ position: "relative", padding: "0 4px" }}
              >
                <Cancel
                  className={classes.inputImage}
                  style={{ backgroundColor: "white", cursor: "pointer" }}
                  onClick={() => handleClear("image", index)}
                />{" "}
                {messageType === "image" ? (
                  <img
                    alt=""
                    src={image}
                    style={{ width: "55px", height: "60px" }}
                  />
                ) : messageType === "video" ||
                  messageType === "Doc" ||
                  messageType === "gif" ? (
                  ""
                  // onPressSend()
                ) : (
                  ""
                )}
              </div>
            );
          })}
        <div>{loader && <CircularProgress />}</div>
      </Box>
      <div style={{ position: "relative" }}>
        {props.blockedUser !== true ? (
          <>
            <div className={classes.chatText}>
              <TextField
                name="message"
                id="message"
                maxRows={4}
                placeholder="Start typing…"
                fullWidth
                multiline
                variant="outlined"
                onChange={handleChange}
                value={message}
                inputProps={{ maxLength: 200 }}
              />
              <div className="chatSend">
                {/* <img
                  alt=""
                  src={attachment}
                  onClick={attachmentClick}
                  style={{ cursor: "pointer" }}
                /> */}
                {props.chatType !== "debate" && <AttachFileIcon
                  onClick={attachmentClick}
                  style={{
                    cursor: "pointer",
                    fontSize: "18px",
                    color: "#b3b3b3",
                    marginRight: "10px",
                  }}
                />}
                <img
                  alt=""
                  src={imgEmoji}
                  onClick={addTextEmojiClick}
                  style={{ cursor: "pointer" }}
                />
                {props.chatType !== "debate" && <Gif
                  className={classes.gifBtn}
                  onClick={() => handlegifpopup()}
                />}
                <img
                  alt=""
                  src={imgSend}
                  onClick={onPressSend}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            {attach === true ? (
              <>
                <div
                  style={{ position: "absolute", bottom: "100%", right: "28%" }}
                >
                  <img
                    alt=""
                    src={imageicon}
                    style={{
                      cursor: "pointer",
                      width: "40px",
                      padding: "10px 10px",
                      backgroundColor: "#ed92e5",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <InputBase
                  style={{
                    position: "absolute",
                    bottom: "115%",
                    right: "29%",
                    opacity: "0",
                  }}
                  type="file"
                  title="Photos & Videos"
                  onChange={handlePostImage}
                  className={classes.InputAttach}
                  inputProps={{
                    accept:
                      "image/jpeg,image/png,image/webp,video/mp4,video/mkv,video/3gp",
                    multiple: "true",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    right: "28%",
                    marginBottom: "50px",
                  }}
                >
                  <img
                    alt=""
                    src={docicon}
                    style={{
                      cursor: "pointer",
                      width: "40px",
                      padding: "10px 10px",
                      backgroundColor: "rgb(70 82 181)",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <InputBase
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    right: "29%",
                    opacity: "0",
                    marginBottom: "50px",
                  }}
                  type="file"
                  title="Documents"
                  onChange={docments}
                  className={classes.InputDoc}
                  inputProps={{
                    accept: ".pdf,.docx,txt,pptx,xlsx",
                    multiple: "true",
                  }}
                />
              </>
            ) : (
              ""
            )}

            <div className={addTextEmoji === true ? "showEmoji" : "hideEmoji"}>
              <Picker
                onSelect={addEmoji}
                title="Pick your emoji…"
                emoji=""
                search={false}
                showPreview={false}
                style={{ position: "relative", width: "100%" }}
              />
            </div>
            <div>
              {gifModal === true ? (
                <div>
                  <CommonGif
                    className={classes.searchWhiteBg}
                    onSelect={handleGif}
                    style={{ width: "60px", height: "60px", cursor: "pointer" }}
                    type={messageType}
                  />
                </div>
              ) : (
                " "
              )}
            </div>
          </>
        ) : (
          <Typography align="center" variant="h6">
            You are not allowed to the chat
          </Typography>
        )}
      </div>
      <div
        style={{
          willChange: "transform",
          position: "absolute",
          bottom: "0%",
          right: "-100%",
          width: "100%",
        }}
      >
        <SnackBar
          open={snackbarState.open}
          message={snackbarState.message}
          severity={snackbarState.severity}
          onClose={onClose}
        />
      </div>
    </React.Fragment>
  );
}
// export default Chat;
const mapStateToProps = ({ loginDetails: { user, auth, hashed } }) => {
  return {
    user,
    auth,
    hashed
  };
};
export default withRouter(connect(mapStateToProps)(Chat));
