import React from 'react'
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core'

const CreateDebateAutocomplete = (props) => {
    // const classes=props.classes;

    return (
        <Autocomplete
            multiple={props.multiple}
            onChange={(event, newValue) => {
                props.onChangeAutoComplete(newValue);
            }}
            id="tags-standard"
            options={props.filteredList && props.filteredList._id ? props.populateList && props.populateList.filter(item => item._id !== props.filteredList._id) : props.populateList && props.populateList}
            getOptionLabel={props.getOptionLabel}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label?props.label:""}
                    placeholder={props.placeholder?props.placeholder:""}
                    className={clsx(props.classes.textField, props.classes.textFieldAutocomplete)}
                    variant="outlined"
                />
            )}
        />
    )
}

export default CreateDebateAutocomplete
