import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { API_Path} from "../../Common/Const/index";

const ShareVurb = (props) => {
  const params = useParams();
  const history = useHistory();
  useEffect(() => {
    const checkShareVurbToken = () => {
      var checkShareVurbAPI =
        API_Path + "api/vurb/checkShareVurbToken/" + params.vurbToken;
      axios
        .get(checkShareVurbAPI, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (response.data !== null && response.data.result !== null) {
            history.push({
              pathname: "/vurbsDetail",
              state: {
                postObj:
                  response.data.result && response.data.result.length > 0
                    ? { post: response.data.result[0] }
                    : null,
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    checkShareVurbToken();
  }, []);

  return (
    <React.Fragment>

      <div>loading ...</div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(ShareVurb));
