import React from 'react'
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const FormikPassword = ({ type, className, error, placeholder, label, variant, ...props }) => {
    // const classes = useStyles();
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : "";

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <React.Fragment>
            <TextField
                id={props.name}
                type={showPassword ? 'text' : 'password'}
                name={props.name}
                label={label}
                fullWidth
                className={className}
                onChange={props.handleChange}
                value={field.value}
                error={errorText.length > 0}
                helperText={errorText}
                variant={variant}
                disabled={props.disabled}
                {...props}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )

                }}
            />
        </React.Fragment>
    )
}

export default FormikPassword
