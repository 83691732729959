import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  videoStreamOption: {
    marginTop: "15px",
    display: "flex",
    flexWrap: "wrap",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#758194",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
    "& .boxed": {
      padding: "4px 14px",
      borderRadius: "6px",
      border: "solid 1px #cdd1d8",
      marginRight: "10px ",
      [theme.breakpoints.down("md")]: {
        margin: "2px",
      },
    },
  },
}));

const DebateCategory = (props) => {
  const classes = useStyles();
  const debateCategoryList =
    props.categoryValue &&
    props.categoryValue.debateDetail &&
    props.categoryValue.debateDetail.category_id &&
    props.categoryValue.debateDetail.category_id.length > 0
      ? props.categoryValue.debateDetail.category_id
      : [];
  return (
    <>
      {debateCategoryList.length > 0 ? (
        <div className={classes.videoStreamOption}>
          {debateCategoryList.map((ls, index) => {
            return (
              <div key={index} className="boxed">
                {ls.name}
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DebateCategory;
