import { Box, Button, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import loginBg from "../../../assets/images/loginBg.png";
import loginBgDark from "../../../assets/images/darkLogin/dark_background.png";
import logoImage from "../../../assets/images/Vurbil - Final-02.png";
import mobileIllution from "../../../assets/images/mobileglow.png";
import mobileIllutionDark from "../../../assets/images/darkLogin/Dark_illustration.png";
import SnackBar from "../../../components/SnackBar";
import { API_Path, Make_Base_Auth } from "../../Common/Const/index";
import FormikInput from "../../Common/FormikComponents/FormikInput";

const useStyles = makeStyles((theme) => ({
  loginBg: {
    padding: "30px 100px 30px 30px",
    background: "url(" + loginBgDark + ") fixed, #f6f7f9",
    color: "white",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    height: "100%",
    // minHeight: '100vh',
    [theme.breakpoints.down("md")]: {
      padding: "15px",
    },
    // backgroundColor: '#f6f7f9',
    // [theme.breakpoints.up('md')]: {
    //   padding: '30px 100px 30px 30px',
    //   backgroundImage: "url(" + loginBg + ")",
    //   backgroundSize: 'cover',
    // },
    // [theme.breakpoints.down('md')]: {
    //   display: 'flex',
    //   flexFlow: 'column',
    //   justifyContent: 'center',
    // }
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
    },
  },
  mobileGlow: {
    position: "relative",
    "& .logo":{
      position:"absolute",
      top: "0",
      left: "30%",
      maxWidth:'10%',
      "z-index": 100
    },
    "& .illusion": {
      position: "absolute",
      width:'50%',
      top: "64px",
      left: "10%",
    },
  },
  joinChat: {
    maxWidth: "352px",
    // margin: '0 auto',
    boxSizing: "border-box",
    // [theme.breakpoints.up('md')]: {
    //   padding: '29px 45px 39px 42px',
    //   borderRadius: '20px',
    //   boxShadow: '1px 3px 12px 0 rgba(0, 0, 0, 0.06)',
    //   backgroundColor: 'rgba(255, 255, 255, .9)',
    //   margin: '0 0 0 auto',
    // },
    padding: "29px 45px 39px 42px",
    borderRadius: "20px",
    boxShadow: "1px 3px 12px 0 rgba(0, 0, 0, 0.06)",
    // backgroundColor: "rgba(255, 255, 255, .5)",
    backgroundColor: '#CDCDCD29',
    margin: "0 0 0 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto 0",
    },
    "& h1, & h2": {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    "& .logo":{
      maxWidth: '50%',
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
    maxHeight: theme.spacing(6),
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white !important', // Change the bottom border color to white
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white !important', // Change the bottom border color to white
    },
    '& .MuiButtonBase-root': {
      color: 'white !important', // Change the bottom border color to white
    },
    '& .MuiInputBase-root': {
      color: "white"
    },
    '& .MuiFormLabel-root': {
      color: "white"
    },
    '& .MuiIconButton-label': {
      color: "white"
    }
  },
  loginButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.75),
  },
  circleBtn: {
    fontSize: "14px",
    borderRadius: 30,
    fontWeight: "bold",
    color: "#fff",
  },
  appLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h4": {
      margin: "-12px 0 0 0",
      fontWeight: "500",
      letterSpacing: "1px",
      lineHeight: 1,
      textTransform: "uppercase",
    },
  },
}));

const Forgetpassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [submitted, setsubmitted] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  // const [setApplicationLogo] = useState("");
  useEffect(() => {
    // const GetApplicationLogo = () => {
    //   axios
    //     .get(API_Path + "api/auth/get_application_logo", {
    //       headers: {
    //         Authorization: Make_Base_Auth(),
    //       },
    //     })
    //     .then((response) => {
    //       if (
    //         response !== null &&
    //         response.data !== null &&
    //         response.data.result !== null
    //       ) {
    //         // setApplicationLogo(response.data.result);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // };
    // GetApplicationLogo();
  }, []);
  const initState = {
    email: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email")
      .required("Please enter registered email"),
  });

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  const submitForm = async (state) => {
    var ForgetpasswordAPI = API_Path + "api/noauth/resetPasswordByEmail";
    var inputObj = {
      inputObject: {
        email: state.email,
      },
    };
    axios
      .post(ForgetpasswordAPI, inputObj, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setsubmitted(false);
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "success",
          });
          setTimeout(() => {
            history.push("/signin");
          }, 5000);
        } else {
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const logoSection = (
    <img alt="Logo" className={`logo`} src={logoImage} />
  );
  return (
    <div className={classes.loginBg}>
      {/* <Hidden smDown>
        <div className={classes.flexJCSB}>
          {logoSection} */}
          {/* <Box className={classes.appLogo}>
                    {applicationLogo !== null && applicationLogo !== undefined ?
                    <>

                        {applicationLogo.logo !== null && applicationLogo.logo !== undefined ?
                        <img alt="Logo" src={applicationLogo.logo && applicationLogo.logo.url} style={{height: '54px', objectFit: 'contain', marginLeft: '-14px'}} />
                        :
                        <img alt="Logo" src={beeLogo} style={{height: '40px', objectFit: 'contain', marginLeft: '-14px'}} />
                        }
                        <h4>
                        {applicationLogo.application_name !== null && applicationLogo.application_name !== undefined ?
                            applicationLogo.application_name
                            :
                            <img alt="Logo" src={textLogo} style={{width: '140px', height: '15px', objectFit: 'contain'}} />
                        }
                        </h4>
                    </>
                    :
                    <img alt="Logo" src={logoImage} />
                    }
                </Box> */}
        {/* </div>
      </Hidden> */}
      <Hidden smDown>
          <div className={classes.mobileGlow}>
            {logoSection}
            <img className="illusion" src={mobileIllutionDark} alt="mobile-illution" />
          </div>
        </Hidden>
      <div className={classes.joinChat}>
        <Hidden mdUp>
          <Box textAlign="center" mb={2}>
            {logoSection}
          </Box>
        </Hidden>
        <h2 className="font-weight-normal m-0" style={{ marginBottom: "16px" }}>
          Trouble Logging In?
        </h2>
        <p style={{ fontSize: "12px", textTransform: "none" }}>
          Enter Your Registered Email and we'll send you a link to get back to
          your account.
        </p>
        <Formik
          initialValues={{ ...initState }}
          onSubmit={submitForm}
          validationSchema={validationSchema}
          validateOnBlur={true}
        >
          {({ isSubmitting }) => {
            return (
              <Form autoComplete="off">
                <Field
                  name="email"
                  label="Registered Email"
                  placeholder=""
                  type="text"
                  as={FormikInput}
                  className={clsx(classes.textField)}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className={clsx(classes.loginButton, classes.circleBtn)}
                  type="submit"
                  disabled={submitted}
                >
                  Submit
                </Button>
                <Box textAlign="center">
                  <Button
                    component={Link}
                    to="/"
                    style={{ textTransform: "none", color: "white" }}
                  >
                    Back To Login
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </div>
  );
};

export default Forgetpassword;
