import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/css/style.scss";
// import Routes from "./routes";
import { Provider } from "react-redux";
import Layout from "./Layout";
import Login from "./screens/Users/signIn/index";
import UserSignup from "./screens/Users/signIn/UserSignup";
import Forgetpassword from "./screens/Users/signIn/Forgetpassword";
import ResetPassword from "./screens/Users/signIn/ResetPassword";
import ShareAd from "./screens/Users/signIn/ShareAd";
import AdLogin from "./screens/Users/signIn/AdLogin";
import AdEnquiry from "./screens/Users/AdEnquiry/index";
import PrivacyPolicy from "./screens/Users/PrivacyPolicy/index";
import TermsAndConditions from "./screens/Users/TermsAndConditions/index";
import AboutUs from "./screens/Users/AboutUs/index";
import VurbilRules from "./screens/Users/VurbilRules";
import EULA from "./screens/Users/EULA";
import { theme } from "./muiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { persistStore } from "redux-persist";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { GoogleOAuthProvider } from "@react-oauth/google";
import FAQ from "./screens/Users/FAQ";
import VurbsHashTagView from "./screens/Users/Vurbs/VurbsHashTagView";
import Support from "./screens/Users/Support/index";
const persistor = persistStore(store);
function App() {
  useEffect(() => {
    // Disabled Right Click and developer tool
    if (process.env.NODE_ENV !== "development") {
      document.addEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
      document.onkeydown = function (e) {
        // disable F12 key
        if (e.code == "F12" || e.key == "F12") {
          return false;
        }
        // disabled Ctrl+Shift+i Key
        if (
          e.ctrlKey &&
          e.shiftKey &&
          (e.key == "i" || e.key == "i" || e.code == "KeyI")
        ) {
          return false;
        }
      };
    }
  }, []);
  let history = useHistory();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
          <ThemeProvider theme={theme}>
            <BrowserRouter history={history}>
              <Switch>
                <Route path="/adEnquiry" component={AdEnquiry} />
                <Route path="/privacyPolicy" component={PrivacyPolicy} />
                <Route
                  path="/termsAndConditions"
                  component={TermsAndConditions}
                />
                <Route path="/vurbilRules" component={VurbilRules} />
                <Route path="/EULA" component={EULA} />
                <Route path="/aboutUs" component={AboutUs} />
                <Route path="/faq/:type" component={FAQ} />
                <Route path="/forgetpassword" component={Forgetpassword} />
                <Route
                  path="/resetpassword/:resetToken"
                  component={ResetPassword}
                />
                <Route path="/userSignup" component={UserSignup} />
                <Route exact path={"/signin"} component={Login} />
                <Route exact path="/linkedin" component={LinkedInPopUp} />
                <Route exact path="/apple" component={LinkedInPopUp} />
                <Route path="/shareAd/:adToken" component={ShareAd} />
                <Route path="/ad_login/:adToken" component={AdLogin} />
                <Route
                  path="/hashtag/view/:hashtext"
                  component={VurbsHashTagView}
                />
                <Route exact path={"/support"} component={Support} />
                <Route path={"/"} component={Layout} />
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
