import React from 'react'
import { List, ListItem, ListItemText, MenuItem, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
    customDropdownOption: {
        padding: '0',
        "& .MuiListItem-gutters": {
            border: 'solid 1px #acb4c1',
            padding: '11px 35px 11px 20px',
            borderRadius: '20px',
            whiteSpace: 'nowrap',
            textTransform: 'capitalize',
            "& .MuiListItemText-root": {
                margin: '0',
                "& span": {
                    lineHeight: '1',
                    fontSize: '12px',
                    color: '#313236',
                    fontWeight: '500'
                }
            },
            "& svg": {
                position: 'absolute',
                right: '5px',
                color: '#778192',
                width: '30px',
                height: '30px',
                top: 0
                // height: '100%'
            }
        }
    }
}));

const CustomDropdown = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickListItem = (event) => { 
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (

        <React.Fragment>
            <List component="nav" aria-label="Device settings" className={classes.customDropdownOption}>
                <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label=""
                    onClick={handleClickListItem}
                >
                    <ListItemText primary={props.options[props.selectedIndex]} />
                    <ArrowDropDownIcon />
                </ListItem>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.options.map((option, index) => (
                    <MenuItem
                        key={option}
                        // disabled={index === 0}
                        selected={index === props.selectedIndex}
                        onClick={(event) => props.handleMenuItemClick(event, index, option, handleClose())}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}

export default CustomDropdown
