import { Box, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import { redirectTo } from "../../Common/Const";
import CustomImage from "../../Common/CustomImage";
import Loader from "../../Common/Loader";
import TossBanner from "./TossBanner";

const InstantDebateBanner = (props) => {
  const classes = props.classes;
  // const history = useHistory();
  const [debateObject, setDebateObject] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState(null);
  const [redirectToLiveDebate, setRedirectToLiveDebate] = useState(false);
  const [showTossBanner, setShowTossBanner] = useState(false);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  useEffect(() => {
    setDebateObject(props.debateObject);
    if (
      props.debateObject !== null &&
      props.debateObject.status === 1 &&
      props.debateObject.toss_selected !== null &&
      props.debateObject.toss_won !== null
    ) {
      // setRedirectToLiveDebate(true);
    } else if (
      props.debateObject !== null &&
      props.debateObject.status === 1 &&
      props.debateObject.toss_selected === null
    ) {
      setShowTossBanner(true);
    }
  }, [props.debateObject]);

  useEffect(() => {
    setOnlineUsers(props.onlineUsers);
  }, [props.onlineUsers]);

  useEffect(() => {
    if (props.debateTimerObj !== null) {
      if (
        props.debateTimerObj !== null &&
        props.debateTimerObj.status === "debateStart"
      ) {
        //redirect to live debate
        setRedirectToLiveDebate(true);
      } else if (
        props.debateTimerObj !== null &&
        props.debateTimerObj.status === "start" &&
        props.debateObject.toss_selected === null
      ) {
        //have to enable toss button to choose the head or tail
        setShowTossBanner(true);
      }
    }
  }, [props.debateTimerObj]);

  useEffect(() => {
    if (props.extendedTimerObj !== null) {
      if (
        props.extendedTimerObj !== null &&
        props.extendedTimerObj.status === "debateStart" &&
        props.debateObject.toss_selected !== null &&
        props.debateObject.toss_won !== null
      ) {
        //redirect to live debate
        setRedirectToLiveDebate(true);
      } else if (
        props.extendedTimerObj !== null &&
        props.extendedTimerObj.status === "start" &&
        props.debateObject.toss_selected === null
      ) {
        //have to enable toss button to choose the head or tail
        setShowTossBanner(true);
      }
    }
  }, [props.extendedTimerObj]);

  let progressValue = 0;
  let currentProgressValue = 0;
  let circularValue = 0;
  let minutesProgress = 0;
  let secondsProgress = 0;
  if (props.extendedTimerObj !== null) {
    progressValue =
      props &&
      props.extendedTimerObj &&
      props.extendedTimerObj.minutes * 60 + props.extendedTimerObj.seconds;
    currentProgressValue = 1800;
    minutesProgress =
      props && props.extendedTimerObj && props.extendedTimerObj.minutes;
    secondsProgress =
      props && props.extendedTimerObj && props.extendedTimerObj.seconds;
    if (progressValue >= 0) {
      let value = Math.round((progressValue * 100) / currentProgressValue);
      circularValue = value;
    }
  }
  const alertRemainingSeconds = (
    <Box
      //   textAlign="right"
      position="absolute"
      top="20px"
      left="0"
      right="0"
      textAlign={{ xs: "right", sm: "center" }}
      zIndex="2"
    >
      <Box
        position="relative"
        display="inline-flex"
        borderRadius="50%"
        boxShadow="0px 2px 4px 0 rgb(0, 0, 0, .3)"
        style={{ backgroundColor: "#fff" }}
      >
        <CircularProgress
          variant="determinate"
          size={80}
          value={circularValue}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* <p style={{fontSize: '12px', fontWeight: '600'}}>{progressValue}s</p> */}
          <p style={{ fontSize: "12px", fontWeight: "600" }}>
            {minutesProgress > 0 && minutesProgress + "m : "}
            {secondsProgress > 0 && secondsProgress + "s"}
          </p>
        </Box>
      </Box>
    </Box>
  );
  return (
    <React.Fragment>
      {redirectToLiveDebate
        ? redirectTo("/liveDebate", {
            debateObj: debateObject,
            roomName: debateObject._id,
          })
        : ""}

      {showTossBanner === true ? (
        <TossBanner debateObject={debateObject} classes={classes} />
      ) : (
        <Box className={classes.positionRelative}>
          <Loader />
          {alertRemainingSeconds}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.anyOneSlide}>
                {debateObject &&
                debateObject.proposition_type === "Anyone" &&
                debateObject.proposition_users.length === 0 ? (
                  <Slider {...settings}>
                    {onlineUsers !== null &&
                      onlineUsers.map((onlineUser, index) => {
                        return (
                          <div key={index} className="avatarImage">
                            {onlineUser && onlineUser.profile_image ? (
                              <img src={onlineUser.profile_image} alt="" />
                            ) : (
                              <AccountCircle
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  opacity: ".25",
                                }}
                              />
                            )}
                            <h5>{onlineUser && onlineUser.full_name}</h5>
                          </div>
                        );
                      })}
                  </Slider>
                ) : (
                  <>
                    <div className="avatarImage">
                      {/* <img src={debateObject.proposition_users[0].user.profile_image} alt='' /> */}
                      <CustomImage
                        userImage={
                          debateObject &&
                          debateObject.proposition_users.length > 0 &&
                          debateObject.proposition_users[0].user.profile_image
                        }
                        size="200px"
                        marginRight="0"
                      />
                      <h5>
                        {debateObject &&
                        debateObject.proposition_users.length > 0
                          ? debateObject.proposition_users[0].user.full_name
                          : "No Proposition users"}
                      </h5>
                    </div>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.anyOneSlide}>
                {debateObject &&
                debateObject.opposition_type === "Anyone" &&
                debateObject.opposition_users.length === 0 ? (
                  <Slider {...settings}>
                    {onlineUsers
                      .slice(0)
                      .reverse()
                      .map((onlineUser, index) => {
                        return (
                          <div key={index} className="avatarImage">
                            {onlineUser && onlineUser.profile_image ? (
                              <img src={onlineUser.profile_image} alt="" />
                            ) : (
                              <AccountCircle
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  opacity: ".25",
                                }}
                              />
                            )}
                            <h5>{onlineUser && onlineUser.full_name}</h5>
                          </div>
                        );
                      })}
                  </Slider>
                ) : (
                  <>
                    {debateObject &&
                      debateObject.opposition_users.length > 0 &&
                      debateObject.opposition_users[0].user &&
                      debateObject.opposition_users[0].user.profile_image && (
                        <>
                          <div className="avatarImage">
                            {/* <img src={debateObject.opposition_users[0].user.profile_image} alt='' /> */}
                            <CustomImage
                              userImage={
                                debateObject &&
                                debateObject.opposition_users.length > 0 &&
                                debateObject.opposition_users[0].user
                                  .profile_image
                              }
                              size="200px"
                              marginRight="0"
                            />
                            <h5>
                              {debateObject &&
                              debateObject.opposition_users.length > 0
                                ? debateObject.opposition_users[0].user
                                    .full_name
                                : "No Opposition users"}
                            </h5>
                          </div>
                        </>
                      )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

// export default InstantDebateBanner
const mapStateToProps = ({ loginDetails: { user, auth, currentPackage } }) => {
  return {
    user,
    auth,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(InstantDebateBanner));
