import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import axios from "axios";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import defaultavatar from "../../../assets/images/default-avatar.png";
import Thumbs from "../../../assets/images/thumbs.svg";
import { API_Path } from "../Const";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
const useLikeStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "18px",
    position:'relative',
    "& img": {
      borderRadius: "20px",
    },
    "& .userName": {
      // margin: "3px 2px 1px 4px;",
      // height: "20px",
      paddingLeft: "10px",
      textOverflow: "ellipsis",
      width: "180px",
      display: "flex",
      flexDirection:"column",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  norecord: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabImage: {
    position: "relative",
    "& img": {
      position: "absolute",
      left: "-29px",
      width: "22px",
      height: "22px",
    },
  },
  activeStatus: {
    position: "absolute",
    left: "30px",
    top: "-4px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "18px",
    },
  },
  modalBody: { paddingLeft: "0px", paddingRight: "0px", maxHeight: "300px" },
}));
let totalCount = 0,
  limit = 10;
function LikeUserCompoent({ count, active, auth, id,slider,debate,vote }) {
  const classes = useLikeStyles();
  const history = useHistory();
  const [open, SetOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [list, setList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [loading, SetLoading] = useState(false);
  const observer = useRef();
  useEffect(() => {
    setValue(active);
  }, [active]);
  const getVurbList = async (val = 0, type) => {
    let getLike = slider ? "img":"get";
    try {
      let inputObj = { id: id, skip: val, limit: limit, type: type };
      SetLoading(true);
      axios
        .post(API_Path + `api/vurb/${getLike}/like_dislike_user`, inputObj, {
          headers: {
            Authorization: "Basic " + auth.accessToken,
          },
        })
        .then((response) => {
          if (response && response.data && response.data.status === "SUCCESS") {
            val === 0
              ? setList(response.data.result)
              : setList((p) => [...p, ...response.data.result]);
            totalCount = response.data.totalCount;
            setSkip(val);
          } else {
            totalCount = 0;
            setSkip(0);
            setList([]);
          }
          SetLoading(false);
        })
        .catch((error) => {
          SetLoading(false);
          console.error(error);
        });
    } catch (e) {
      console.log("e", e);
    }
  };
  const getDebateList = async (val = 0, type) => {
    try {
      SetLoading(true);
      axios
        .get(API_Path + `get/debate/like_dislike/users?debate_id=${id}&type=${type}&limit=${limit}&skip=${val}`, {
          headers: {
            Authorization: "Basic " + auth.accessToken,
          },
        })
        .then((response) => {
          if (response && response.data && response.data.msg === "success") {
            val === 0
              ? setList(response.data.userList)
              : setList((p) => [...p, ...response.data.userList]);
            totalCount = response.data.totalCount;
            setSkip(val);
          } else {
            totalCount = 0;
            setSkip(0);
            setList([]);
          }
          SetLoading(false);
        })
        .catch((error) => {
          SetLoading(false);
          console.error(error);
        });
    } catch (e) {
      console.log("e", e);
    }
  };
  let linkEnable = count ? true : false;
  const handleChange = (event, newValue) => {
    setList([]);
    setValue(newValue);
    totalCount = 0;
    !!debate?getDebateList(0, !!vote ?newValue ? "opposition" : "proposition":newValue ? "dislike" : "like"):getVurbList(0, newValue ? "dislike" : "like");
  };
  const toggle = () => {
    SetOpen(!open);
    !!debate?getDebateList(0, !!vote ?value ? "opposition" : "proposition":value ? "dislike" : "like"):getVurbList(0, value ? "dislike" : "like");
  };
  const LastScroll = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (totalCount > list.length) {
          !!debate?getDebateList(skip + limit, !!vote ?value ? "opposition" : "proposition":value ? "dislike" : "like"):getVurbList(skip + limit, value ? "dislike" : "like");
        }
      }
    });
    if (node) observer.current.observe(node);
  }, [list]);
  const OnClose = () => {
    SetOpen(!open);
    totalCount = 0;
    setSkip(0);
    setList([]);
    setValue(active);
  };
  return (
    <Fragment>
      <span
        className={linkEnable ? classes.link : ""}
        onClick={() => (linkEnable ? toggle(!open) : null)}
      >
        {count}
      </span>
      <Dialog
        open={open}
        onClose={() => OnClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {!!vote&&<h4 style={{margin:'0',textAlign:'center'}}>Votes</h4>}
          <Tabs
            value={value}
            onChange={(e,value)=>handleChange(e,value)}
            aria-label="disabled tabs example"
            indicatorColor="primary"
          >
            <Tab
              label={
                <div className={classes.tabImage}>
                  <img src={Thumbs} alt="like" />{!!vote?"Team 1" : 'Like'}
                </div>
              }
            />
            <Tab
              label={
                <div className={classes.tabImage}>
                  <img
                    src={Thumbs}
                    alt="like"
                    style={!!vote?{top: "3px"}:{ transform: "rotate(180deg)", top: "3px" }}
                  />{" "}
                  {!!vote?"Team 2" : 'DisLike'}
                </div>
              }
            />
          </Tabs>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          {list.length > 0 && (
            <ul>
              {list.map((val, i) => {
                return (
                  <li
                    key={i}
                    className={classes.list}
                    ref={list.length - 1 === i ? LastScroll : null}
                  >
                    <img
                      width="42px"
                      height="42px"
                      src={
                        val.profile_image ? val.profile_image : defaultavatar
                      }
                      alt={val.full_name}
                    />
                     {!!val.badge_status && (
                    <span
                      className={classes.activeStatus}
                      title="Verified User/Public Figure"
                    >
                      <VerifiedUserRoundedIcon />
                    </span>
                  )}
                    <div className="userName cursor" onClick={() =>
                          history.push(`/friendsProfilePage/${val.user_id}`)
                        }>
                      <p className="m-0">
                        {val.full_name}
                      </p>
                        {val.user_tagging&&<p className="m-0" style={{fontSize:"14px"}}>@{val.user_tagging}</p>}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {!loading && list.length === 0 && (
            <div className={classes.norecord}>
              {!value
                ? "No Liked Users Available"
                : "No Disliked Users Available"}
            </div>
          )}
          {loading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
function RenderComponent(prevprops, nextProps) {
  return prevprops.count === nextProps.count;
}
const MemoizedComp = React.memo(LikeUserCompoent, RenderComponent);
export default MemoizedComp;
