import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import axios from "axios";
import React, { useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import squareShare from '../../../assets/images/actionIcons/squareShare.svg';
import Retweet from '../../../assets/images/retweet.svg';
import Facebook from '../../../assets/images/socialIcons/facebook.svg';
import Linkedin from '../../../assets/images/socialIcons/linkedin.svg';
import Twitter from '../../../assets/images/socialIcons/twitter.svg';
import Whatsapp from '../../../assets/images/socialIcons/whatsapp.svg';
import Thumbs from '../../../assets/images/thumbs.svg';
import { API_Path } from "../../Common/Const";
import Adsinjection from '../AdsInjection';
import DebateDMY from '../CustomDebateHeaderComponents/DebateDMY';
import CustomSocialFab from '../CustomSocialFab';
import Report from '../ReportAbuse/Report';

const useStyles = makeStyles((theme) => ({
    paperWhiteParent: {
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
        // padding: '20px 20px 30px 20px',
        borderRadius: '12px',
        backgroundColor: '#fff',
        margin: '20px 0',
        "& .react-player": {
            // height: '23vw!important',
            background: '#000'
        }
    },
    dateMonthField: {
        borderRight: 'solid 1px #cdd1d8',
        marginRight: '24px',
        paddingRight: '24px',
    },
}))

const CustomDebateVideoContent = (props) => {
    const classes = useStyles();
    const [adsDetails, setadsDetails] = useState(null);
    const actions = [
        { icon: <img alt="" src={Facebook} />, name: 'Facebook', href: 'http://www.facebook.com/sharer/sharer.php?s=100&p[url]=' + encodeURIComponent(props.cardObj.url) },
        { icon: <img alt="" src={Twitter} />, name: 'Twitter', href: 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(props.cardObj.url) },
        { icon: <img alt="" src={Linkedin} />, name: 'Linkedin', href: 'https://www.linkedin.com/cws/share?url=' + encodeURIComponent(props.cardObj.url) },
        { icon: <img alt="" src={Whatsapp} />, name: 'Whatsapp', href: 'whatsapp://send?text=' + props.cardObj.url },
    ];
    const [cardObj, setcardObj] = React.useState({});

    const handleCount = (id, type) => {
        let URL = "";
        if (type === "like") {
            URL = API_Path + "api/broadcastvideo/likes";
        } else if (type === "share") {
            URL = API_Path + "api/broadcastvideo/share";
        }
        let inputObj = {
            "inputObject": {
                "broadCastVideoId": id,
                "userId": props.user._id
            }
        };
        axios
            .post(URL, inputObj, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                setcardObj(response.data.result)
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const getBroadCastVideoadsList = () => {
        let URL = API_Path + "api/broadCastvideoads/list";
        let inputObj = {
            "inputObject": {
                "broadCastId": props.cardObj._id,
            }
        };
        axios
            .post(URL, inputObj, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                setadsDetails(response.data.result[0])
            })
            .catch((error) => {
                console.error(error);
            });
    }
    React.useEffect(() => {
        getBroadCastVideoadsList();
        setcardObj(props.cardObj)
    }, [props.cardObj])

    var currenturl = window.location.href;
    var reportType = ((currenturl !== null && currenturl.indexOf("kpnews") > -1) ? "Reporting Kpnews" : "Reporting Kpshow")
    return (
        <React.Fragment>
            <Box className={classes.paperWhiteParent}>
                <Box component="div" display="flex" justifyContent="space-between" p={2}>
                    <div className={classes.dateMonthField}>
                        <DebateDMY listDMY={cardObj} normalDMY={true} broadCastDate={true} />
                    </div>
                    <Box width="100%" display="flex" justifyContent="space-between">
                        <Box>
                            <Typography variant="h3" gutterBottom>
                                {cardObj.title ? cardObj.title : ""}
                            </Typography>
                            {cardObj.classification && cardObj.classification.length > 0 ?
                                <>
                                    {cardObj.classification.map((ls, index) => {
                                        return (
                                            <Button key={index} variant="outlined" size="small">
                                                {ls.name}
                                            </Button>
                                        )
                                    })}
                                </>
                                :
                                ""
                            }
                        </Box>
                        <Box>
                            <CustomSocialFab handleCount={handleCount} bCId={cardObj._id} Broadcast={props.Broadcast} shareBroadcastLink={props.shareBroadcastLink} actions={actions} direction="down" icon={squareShare} debateId={props.debateId} />
                        </Box>
                    </Box>
                </Box>
                <Adsinjection videoUrl={cardObj} adsList={adsDetails} />
                <Box px={4} py={3}>
                    <Box display="flex" marginBottom={2.5}>
                        <Box display="flex" alignItems="center" marginRight={2}>
                            <img alt="" src={Thumbs} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={handleCount.bind(this, cardObj._id ? cardObj._id : 0, "like")} />
                            <Typography variant="h5">
                                {cardObj.likes_count ? cardObj.likes_count : 0}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <img alt="" src={Retweet} style={{ marginRight: '5px', cursor: 'pointer' }} />
                            <Typography variant="h5">
                                {cardObj.share_count ? cardObj.share_count : 0}
                            </Typography>
                        </Box>
                        <Box ml="auto" display="flex">
                            <Report showType="Icon" type={reportType} primaryKey={cardObj._id} />
                        </Box>
                    </Box>
                    {cardObj && cardObj.description &&
                        <>
                            <Typography variant="h5" gutterBottom>
                                Description
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {cardObj.description}
                            </Typography>
                        </>
                    }
                </Box>
            </Box>
        </React.Fragment>
    )
}


const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(CustomDebateVideoContent));