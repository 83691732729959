import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
// import loaderImage from '../../../assets/video/bee.gif';
import loaderImage from '../../../assets/video/Vurbil.gif';

const useStyles = makeStyles((theme) => ({
    loader: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99
    }
}))
const Loader = () => {
    const classes = useStyles();
    return (
        <div className={classes.loader}>
            {/* <video src={loaderImage1} autoPlay loop playsInline /> */}
            <img src={loaderImage} style={{maxWidth: '420px', width: '100%'}} alt=""/>
        </div>
    )
}

export default Loader
