import React from 'react'
import { useField } from 'formik';
import { TextField, MenuItem } from '@material-ui/core';

const FormikSelect = ({ type, className, error, placeholder, label, variant, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
        <React.Fragment>
            <TextField
                id="standard-select-currency"
                select
                name={props.name}
                label={label}
                fullWidth
                className={className}
                value={field.value}
                error={errorText.length > 0}
                helperText={errorText}
                variant={variant}
                {...props}
            // value={currency}
            // onChange={handleChange}
            // helperText="Please select your currency"
            >
                {props.options.map((option) => {
                    return (
                        <MenuItem key={option.label} value={option.id||option.value}>
                            {option.value}
                        </MenuItem>
                    )
                })}


            </TextField>
        </React.Fragment>
    )
}

export default FormikSelect
