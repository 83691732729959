import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomImage from "../CustomImage";
import ChatWindow from "./ChatWindow";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";

const useStyles = makeStyles((theme) => ({
  userState: {
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    cursor: "pointer",
    "& .userCount": {
      position: "relative",
      "& .chatCount": {
        top: "-6px",
        right: "-14px",
        position: "absolute",
        background: "#ffc003",
        color: "#fff",
        padding: "2px",
        borderRadius: "50%",
        display: "inline-block",
        lineHeight: "1",
        fontSize: "10px",
        fontWeight: "600",
        minWidth: "14px",
        textAlign: "center",
      },
    },
  },
  chatImage: {
    width: 30,
    height: 30,
    marginRight: 15,
    borderRadius: "50%",
    position: "relative",
    "& img": {
      width: 30,
      height: 30,
      objectFit: "cover",
      borderRadius: "50%",
    },
    "& span": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
      marginLeft: 5,
      position: "absolute",
      right: 0,
      bottom: 3,
      border: "1px solid #fff",
    },
  },
  activeColor: {
    backgroundColor: "#4dd965",
  },
  inActiveColor: {
    backgroundColor: "#b9b9b9",
  },
  activeStatus: {
    position: "absolute",
    right: "8px",
    top: "-4px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "12px",
    },
  },
}));

const GetFriendfriendObj = (props) => {
  const classes = useStyles();
  const [friendObj, setFriendObj] = useState(null);
  const [isChat, setIsChat] = useState(props.isChat);

  useEffect(() => {
    setFriendObj(props.list);
  }, [props.list]);

  useEffect(() => {
    setIsChat(props.isChat);
  }, [props.isChat]);

  const handleChat = () => {
    props.enableFriendsChat(friendObj, null);
  };

  const closeFriendChat = () => {
    props.enableFriendsChat(friendObj, true);
  };
  return (
    <>
      <div onClick={() => handleChat()}>
        {
          <Box className={classes.userState}>
            <Box className={classes.chatImage}>
              {friendObj !== null && friendObj.profile_image !== "" ? (
                // <img src={friendObj.profile_image} />
                <div  style={{position:"relative"}}>
                <CustomImage
                  userImage={friendObj.profile_image}
                  size="30px"
                  marginRight="0"
                />
                {!!friendObj.badge_status && (
                  <span
                    className={classes.activeStatus}
                    title="Verified User/Public Figure"
                  >
                    <VerifiedUserRoundedIcon />
                  </span>
                )}</div>
              ) : (
                ""
              )}
              <span 
                className={
                  friendObj !== null && friendObj.login_status === 1
                    ? classes.activeColor
                    : classes.inActiveColor
                }
              ></span>
            </Box>
            <span className="userCount">
              {friendObj !== null && friendObj.full_name}
              {/* {friendObj!==null&&friendObj.chat_count>0?"("+friendObj.chat_count+")":""} */}
              {friendObj !== null && friendObj.chat_count > 0 ? (
                <span className="chatCount">{friendObj.chat_count}</span>
              ) : (
                ""
              )}
            </span>
          </Box>
        }
      </div>
      {friendObj !== null && isChat && (
        <ChatWindow friendObj={friendObj} closeFriendChat={closeFriendChat} />
      )}
    </>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(GetFriendfriendObj));
