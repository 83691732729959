import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import DataGridTable from "../../../components/Table/DataGridTable";
import { makeStyles } from "@material-ui/core";
import CommonPagination from "../../Common/CommonPagination";
import Loader from "../../Common/Loader";
import EditIcon from "@material-ui/icons/Edit";
import {
  Tooltip,
  IconButton,
  Button,
  Modal,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import Filter from "../../../assets/images/filter.svg";
import Filtered from "../../../assets/images/filtered.svg";
import { Field, Form, Formik } from "formik";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import clsx from "clsx";
import FormikSelect from "../../Common/FormikComponents/FormikSelect";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import SnackBar from "../../../components/SnackBar";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  link_to_your_file: {
    display: "inline-block",
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    justifyContent: "center",
  },
  filterFrom: {
    marginTop: "30px",
    "& >div": {
      padding: "14px 0px",
    },
  },
  adsHeader: {
    // padding: "20px 232px 20px 35px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    textAlign: "left",
    color: "#313236",
    paddingTop: "20px",
    margin: "0px",
    paddingLeft: "22px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "17px",
      whiteSpace: "nowrap",
    },
  },
  filterIcon: {
    border: "1px solid #fbdd88",
    borderRadius: "0 !important",
    backgroundColor: "white",
    margin: "13px 25px",
  },

  advertisement: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  pauseButton: {
    color: "#212529",
    backgroundColor: "white",
    borderRadius: "10rem",
    border: "1px solid #aeaeae",
    textTransform: "none",
    width: "100px",
  },
  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "7px",
    borderRadius: "14px",
    fontSize: "30px",
    right: "7px",
  },
  textField: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    "& p": {
      margin: "8px 0 0 0",
      fontSize: "13px",
    },
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      // marginTop: "8px",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
    "& .MuiFormLabel-filled": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& .MuiInput-underline": {
      border: "1px solid #d6d6d6",
      padding: "8px",
      margin: 0,
      borderRadius: "4px",
    },
  },
  pause: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "120px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "56px",
    },
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  textAlign: "center",
  height: 400,
};

let listData = [
  { label: "Waiting for Approval", value: "Waiting for Approval" },
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
  { label: "Running", value: "Running" },
  { label: "Pause", value: "Pause" },
  { label: "Expired", value: "Expired" },
];

const AdsList = (props) => {
  const classes = useStyles();
  const [adList, setAdList] = useState([]);
  const [loading, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecords, settotalRecords] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [pause, setPause] = useState([]);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [initState, setinitState] = useState({
    ad_title: "",
    advertisement_date: null,
    status: "",
  });
  useEffect(() => {
    getAdList(1);
  }, []);

  const linkTemplate = (row) => {
    return (
      <div>
        <Tooltip title={row.link_to_your_file} placement="top">
          <a
            target="_blank"
            className={classes.link_to_your_file}
            href={row.link_to_your_file}
          >
            {row.link_to_your_file}
          </a>
        </Tooltip>
      </div>
    );
  };

  const StartDateTemplate = (row) => {
    let start_date = moment(row.start_date.split("T")[0]).format("DD-MM-YYYY");
    return (
      <div>
        <span>{start_date}</span>
      </div>
    );
  };
  const EndDateTemplate = (row) => {
    let end_date = moment(row.expiry_date.split("T")[0]).format("DD-MM-YYYY");
    return (
      <div>
        <span>{end_date}</span>
      </div>
    );
  };
  const CreateDateTemplate = (row) => {
    let originalMoment = moment.utc(row.created_at);
    let formattedDateTime = originalMoment.format("DD-MM-YYYY HH:mm");
    return (
      <div>
        <span>{formattedDateTime} UTC</span>
      </div>
    );
  };
  const ClassificationTemplate = (row) => {
    const concatenatedString = row.target_intrest_tag_names.join(", ");
    return (
      <div>
        <Tooltip title={concatenatedString} placement="top">
          <span className={classes.link_to_your_file}>
            {concatenatedString}
          </span>
        </Tooltip>
      </div>
    );
  };
  const RenewalEndTemplate = (row) => {
    let end_date = row.is_auto_renew
      ? moment(row.firstAdHistory.end_date.split("T")[0]).format("DD-MM-YYYY")
      : "";
    return (
      <div>
        <span>{end_date}</span>
      </div>
    );
  };
  const RenewalStartTemplate = (row) => {
    let start_date = row.is_auto_renew
      ? moment(row.firstAdHistory.start_date.split("T")[0]).format("DD-MM-YYYY")
      : "";
    return (
      <div>
        <span>{start_date}</span>
      </div>
    );
  };
  const RemarkTemplate = (row) => {
    return (
      <div>
        <Tooltip title={row.last_reject_reason} placement="top">
          <span className={classes.link_to_your_file}>
            {row.main_status === "Rejected" && row.last_reject_reason}
          </span>
        </Tooltip>
      </div>
    );
  };
  const SpendAmountTemplate = (row) => {
    return (
      <div>
          <span >
             ${row.spend_amount}({row.ad_budget})
          </span>
        
      </div>
    );
  };
  const RenewalTemplate = (row) => {
    return (
      <div>
        <span>{row.is_auto_renew ? "Yes" : "No"}</span>
      </div>
    );
  };
  const ImpressionTemplate =(row)=>{
    return (
      <div>
          <span >
          {row.total_impression_count}({row.ad_impression})
          </span>
        
      </div>
    );
  }
  const DurationTemplate =(row)=>{
    let days = row.ad_duration === 1 ? " Day" :" Days"
    return (
      <div>
          <span >
          {row.ad_duration}{days} 
          </span>
        
      </div>
    );
  }
  const StatusTemplate = (row) => {
    let { main_status } = row;
    let Status =
      main_status === "Running"
        ? "blue"
        : main_status === "Rejected"
        ? "red"
        : main_status === "Waiting for Approval"
        ? "purple"
        : main_status === "Pause"
        ? "orange" :
        main_status === "Expired"
        ? "black"
        : "green";
    return (
      <div>
        <span style={{ color: `${Status}` }}>{main_status}</span>
        {(main_status === "Waiting for Approval" ||
          main_status === "Rejected") && (
          <Tooltip title={"Edit"} placement="top">
            <IconButton onClick={() => handleEdit(row)}>
              <EditIcon style={{ fontSize: "18px" }} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };
  const DescriptionTemplate = (row) => {
    return (
      <div>
        <Tooltip title={row.description} placement="top">
          <span className={classes.link_to_your_file}>
            {row.description}
          </span>
        </Tooltip>
      </div>
    );
  };
  const ApproveDateTemplate = (d) => {
    let date = d.approved_date
      ? moment(d.approved_date).utc().format("DD-MM-YYYY")
      : "";
    return (
      <div>
        <span>{date}</span>
      </div>
    );
  };
  const ApproveTemplate =(row)=>{
    let data =row.ad_created_type ==="admin" ?"Auto-Approved" : row.approved_by_name
    return (
      <div>
          <span >
          {data}
          </span>
        
      </div>
    );
  }
  const IdTemplate =(row)=>{
    return (
      <div>
          <span >
          AD{row._id}
          </span>
        
      </div>
    );
  }
  const column = [
    {
      field: "id",
      header: "Ads Unique ID",
      body: IdTemplate,
    },
    { field: "ad_title", header: "Ad Title" },
    { field: "description", header: "Ad Description", body: DescriptionTemplate,},

    {
      field: "start_date",
      header: "Start Date",
      body: StartDateTemplate,
    },

    {
      field: "expiry_date",
      header: "Expiry Date",
      body: EndDateTemplate,
    },
    {
      field: "created_at",
      header: "Created On",
      body: CreateDateTemplate,
    },
    { field: "approved_date", header: "Approved Date", body: ApproveDateTemplate },
    { field: "approved_by", header: "Approved By", body: ApproveTemplate },
    
    {
      field: "link_to_your_file",
      header: "Link to Your Ad File",
      body: linkTemplate,
    },
    {
      field: "target_intrest_tag_names",
      header: "Classification",
      body: ClassificationTemplate,
    },
    { field: "total_impression_count", header: "No of Impressions", body:ImpressionTemplate },
    { field: "total_click_count", header: "No of Clicks" },
    {
      field: "spend_amount",
      header: "Spend Amount",
      body: SpendAmountTemplate,
    },
    { field: "ad_duration", header: "Duration", body:DurationTemplate },
    { field: "is_auto_renew", header: "Is Renewal", body: RenewalTemplate },
    {
      field: "total_click_count",
      header: "Renewal Start Date",
      body: RenewalStartTemplate,
    },
    {
      field: "total_click_count",
      header: "Renewal End Date",
      body: RenewalEndTemplate,
    },

    { field: "last_reject_reason", header: "Remark", body: RemarkTemplate },
    {
      field: "approval_status",
      headerStyle: { width: "100px" },
      header: "Status",
      body: StatusTemplate,
    },
  ];
  const getAdList = (val, filterData = null) => {
    let api = API_Path + `api/ads/user_adsList`;

    let page = {
      page: val,
      perPage: 10,
    };
    if (
      filterData != null &&
      (filterData.ad_title.trim() != "" ||
        filterData.date != "" ||
        filterData.status.trim() != "")
    ) {
      setinitState({
        ad_title: filterData.ad_title,
        advertisement_date: filterData.advertisement_date,
        status: filterData.status,
      });
      page.ad_title = filterData.ad_title;
      page.filter_date = filterData.advertisement_date;
      page.main_status = filterData.status;
    } else {
      setinitState({
        ad_title: "",
        advertisement_date: null,
        status: "",
      });
    }
    setLoader(true);
    axios
      .post(api, page, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setAdList(response.data.result.ads);
          settotalRecords(response.data.result.pagination);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const onRowSelect = (e) => {};
  const handlePaginationChange = (event, value) => {
    if (value !== page) {
      getAdList(value);
      setPage(value);
    }
  };
  const handleEdit = (value) => {
    props.onEdit(value);
    // console.log(value)
  };
  const onClose = () => {
    setSnackbarState({ open: false });
  };
  const Clear = () => {
    getAdList(1);
    setinitState({
      ad_title: "",
      advertisement_date: null,
      status: "",
    });
    setOpenModal(false);
  };
  const handlePause = (data) => {
    let sendIds = {
      ad_id: [],
    };
    for (let i = 0; i < pause.length; i++) {
      sendIds.ad_id.push(pause[i]._id);
    }
    setLoader(true);
    let api =
      data === "pause"
        ? API_Path + "api/ads/pause"
        : API_Path + "api/ads/unpause";
    if (pause.length > 0) {
      axios
        .post(api, sendIds, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            setPause([]);
            setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "success",
            });
            getAdList(page);
          } else {
            setLoader(false);
            setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          console.error(error);
        });
    } else {
      setSnackbarState({
        open: true,
        message: "Please select the advertisement",
        severity: "error",
      });
    }
    setLoader(false);
  };

  const tableSelectionChange = (e) => {
    const filteredData = e.value.filter(
      (item) =>
        item.main_status !== "Waiting for Approval" &&
        item.main_status !== "Rejected" &&
        item.main_status !== "Expired" &&
        item.ad_created_type !=="admin"
    );
    const filtered = e.value.filter(
      (item) =>
        item.main_status === "Waiting for Approval" ||
        item.main_status === "Rejected" ||
        item.main_status === "Expired" ||
        item.ad_created_type === "admin"
    );
    if (filtered.length > 0) {
      setSnackbarState({
        open: true,
        message:
          "Pause/UnPause can be changed only for the user created Approved/Running Ads",
        severity: "error",
      });
    }
    setPause(filteredData);
  };
  return (
    <>
      {loading && <Loader />}
      <div className="client-table-grid">
        <div className="scroll-container">
          <div className={classes.advertisement}>
            <div>
              <h1 className={classes.adsHeader}>Manage Advertisement</h1>
            </div>
            <div className={classes.pause}>
              <Button
                className={classes.pauseButton}
                onClick={() => handlePause("pause")}
                disabled={adList.length > 0 ? false : true}
              >
                Pause
              </Button>
              <Button
                className={classes.pauseButton}
                onClick={() => handlePause("unpause")}
                disabled={adList.length > 0 ? false : true}
              >
                Un Pause
              </Button>
              <IconButton
                size="medium"
                className={classes.filterIcon}
                onClick={() => setOpenModal(true)}
              >
                {initState.ad_title != "" ||
                initState.advertisement_date != null ||
                initState.status != "" ? (
                  <img alt="" width="18px" src={Filtered}></img>
                ) : (
                  <img alt="" width="18px" src={Filter}></img>
                )}
              </IconButton>
            </div>
          </div>
          <div className="payment-list">
            <DataGridTable
              selection={pause}
              onSelectionChange={tableSelectionChange}
              selectionHeaderStyle={{ width: "6em" }}
              columns={column}
              values={adList}
              page={0}
              rows={5}
              dataKey="_id"
              selectionMode="multiple"
              onRowSelect={onRowSelect}
              emptyMessage="No Ads Found"
            ></DataGridTable>
            <div style={{ padding: "20px" }}>
              <CommonPagination
                JusCont="end"
                page={page}
                count={totalRecords.totalPages}
                handleChange={handlePaginationChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Cancel
            className={classes.closeButton}
            style={{
              backgroundColor: "white",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(false)}
          />
          <Typography variant="h4" component="h1">
            Ad Filter
          </Typography>
          <Formik
            initialValues={initState}
            onSubmit={async (values) => {
              getAdList(1, values);
              setOpenModal(false);
            }}
          >
            <Form autoComplete="off" className={classes.filterFrom}>
              <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12} sm={12}>
                  <Field
                    name="ad_title"
                    label="Advertisement Title"
                    placeholder="Advertisement Title"
                    type="text"
                    variant="outlined"
                    as={FormikInput}
                    className={clsx(classes.textField)}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ textAlign: "left", paddingTop: "0px" }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#313236",
                  }}
                >
                  Date
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Field
                    fullWidth
                    variant="inline"
                    component={KeyboardDatePicker}
                    format="dd/MM/yyyy"
                    type="text"
                    name="advertisement_date"
                    placeholder="DD/MM/YYYY"
                    as={FormikInput}
                    autoOk
                    className={clsx(classes.textField)}
                    style={{ width: "267px", marginTop: "0px" }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                <Field
                  name="status"
                  label="Select Status"
                  placeholder="Select Status"
                  type="select"
                  variant="outlined"
                  options={listData}
                  as={FormikSelect}
                  className={clsx(classes.textField)}
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                style={{ paddingLeft: "15px" }}
              >
                <Button
                  variant="contained"
                  color="white"
                  className={clsx(classes.circleBtn)}
                  onClick={() => Clear()}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={clsx(classes.circleBtn)}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item container xs={6} sm={6} justifyContent="center"></Grid>
            </Form>
          </Formik>
        </Box>
      </Modal>

      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </>
  );
};
export default AdsList;
