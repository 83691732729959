import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  Button,
  Modal,
  Typography,
  Box,
  Grid,
  Paper,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import { Cancel } from "@material-ui/icons";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import SnackBar from "../../../components/SnackBar";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../../Common/Loader";

const useStyles = makeStyles((theme) => ({
  AddFundModal: {
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  closeButton: {
    color: "#ffc003",
    position: "absolute",
    top: "10px",
    right: "9px",
    borderRadius: "14px",
  },
  filterFrom: {
    marginTop: "20px",
    "& >div": {
      padding: "14px 0px",
    },
    "& .MuiGrid-container": {
      justifyContent: "center",
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    backgroundColor: "white",
    borderRadius: "10px",
    "& p": {
      margin: "8px 0 0 0",
      fontSize: "13px",
    },
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      // marginTop: "8px",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 22px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
    "& .MuiFormLabel-filled": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    padding: "9px 32px !important",
    margin: "16px 0",
  },
  dollerSymbol: {
    position: "absolute",
    top: "43%",
    left: "7%",
    color: "#758194",
    zIndex: "1",
  },
}));
const AddFundModal = ({ openModal, setOpenModal, auth, page }) => {
  const classes = useStyles();
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [amount, setAmount] = useState("");
  const [loading, setLoader] = useState(false);
  const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const onClose = () => {
    setSnackbarState({ open: false });
  };
  const handlePayment = async () => {
    let api = API_Path + "api/wallet/create_stripe_session";
   
    if (amount.length > 0 && amount != 0 ) {
      setOpenModal(false);
      setLoader(true);
      let sendAmount = {
        amount: Number(amount),
        page:page
      };
      axios
        .post(api, sendAmount, {
          headers: {
            Authorization: "Basic " + auth.accessToken,
          },
        })
        .then(async (response) => {
          if (response.data.status === "SUCCESS") {
            let sessionId = response.data.result.session_id;
            let stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
            const { error } = await stripe.redirectToCheckout({
              sessionId,
            });
        
          } else {
            setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
          }
          setLoader(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSnackbarState({
        open: true,
        message: "Please Enter the Valid Amount",
        severity: "error",
      });
    }
  };

  const handleAmountChange = (event) => {
   
    const newValue = event.target.value.replace(/\D/g, "");
    if(newValue.length <= 6){
      // setSnackbarState({
      //   open: true,
      //   message: "Maximum amount allowed is $999999",
      //   severity: "error",
      // });
      setAmount(newValue);
    }
    
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.AddFundModal}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 300,
                height: 200,
                position: "relative",
                padding: "10px",
              },
            }}
          >
            <Paper elevation={3}>
              <div>
                <Cancel
                  className={classes.closeButton}
                  style={{
                    backgroundColor: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenModal(false)}
                />
                <Typography variant="h4" componeTypographynt="h1">
                  Add Funds
                </Typography>
              </div>

              <Formik initialValues={{amount}}>
                <Form autoComplete="off" className={classes.filterFrom}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{ position: "relative" }}>
                      <span className={classes.dollerSymbol}>$</span>
                      <Field
                        name="amount"
                        label="Enter Amount to Add"
                        placeholder="Enter Amount to Add"
                        type="text"
                        variant="outlined"
                        as={FormikInput}
                        onInput={handleAmountChange}
                        step="1"
                        className={clsx(classes.textField)}
                        value={amount}
                        
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={clsx(classes.circleBtn)}
                      onClick={() => {
                        handlePayment();
                      }}
                    >
                      ADD
                    </Button>
                  </Grid>
                </Form>
              </Formik>
            </Paper>
          </Box>
        </div>
      </Modal>

      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </>
  );
};

export default AddFundModal;
