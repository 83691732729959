import React, { useState, useEffect, useRef } from "react";
import { Box, Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import Star from '../../../assets/images/star.svg';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Retweet from "../../../assets/images/retweet.svg";
import ExpandImage from "../../../assets/images/Expand.png";
import ShareImage from "../../../assets/images/Share.png";
import Facebook from "../../../assets/images/socialIcons/facebook.svg";
import Twitter from "../../../assets/images/socialIcons/twitter.svg";
import Linkedin from "../../../assets/images/socialIcons/linkedin.svg";
import Whatsapp from "../../../assets/images/socialIcons/whatsapp.svg";
import CustomSocialFab from "../CustomSocialFab";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import CustomRating from "../CustomRating";
import ShowMoreLessText from "../../Common/ShowMoreLessText/index";
import VideoPlayer from "../VideoPlayer";
import CopyLink from "../CopyLink";
import SnackBar from "../../../components/SnackBar";
import Report from "../ReportAbuse/Report";

const useStyles = makeStyles((theme) => ({
  imageCard: {
    display: "flex",
    flexFlow: "column",
    position: "relative",
    overflow: "hidden",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    "& > div": {
      height: "auto",
      "& > div": {
        paddingTop: "0!important",
        "& video": {
          position: "relative!important",
        },
      },
    },
  },
  imageCardBody: {
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    backgroundColor: "#fff",
    marginBottom: "35px",
    padding: "14px 24px",
    "& h5": {
      marginBottom: "3px",
    },
    "& p": {
      fontSize: "12px",
      color: "#758194",
    },
    "& .smallButton": {
      padding: "4px 9px",
      fontSize: "10px",
      lineHeight: 1,
      minWidth: "auto",
      "& + &.smallButton": {
        marginLeft: "5px",
      },
    },
    "& .seperatorImageCard": {
      borderBottom: "1px solid #cdd1d8",
      margin: "12px 0",
    },
    "& .iconBtn": {
      padding: "3px",
      minWidth: "auto",
    },
    "& .MuiSpeedDial-directionUp": {
      zIndex: 8,
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  userRating: {
    margin: "0",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    zIndex: 0,
    "& .rated": {
      fill: "#ffc003",
    },
    "& .applyRating": {
      display: "none",
      position: "absolute",
      top: "-35px",
      left: "0",
      width: "154px",
      height: "29px",
      margin: "0 0 8px",
      padding: "8px 12px",
      borderRadius: "8px",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff",
      "&:after": {
        content: '""',
        width: "14px",
        height: "12px",
        margin: "25px 133px 0 7px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#ffffff",
        position: "absolute",
        left: "5px",
        bottom: "-5px",
        transform: "rotate(-45deg)",
        zIndex: "-1",
      },
    },
    "&:hover": {
      "& .applyRating": {
        display: "block",
      },
    },
  },
  btnwrap: {
    alignItems: "center",
    "& div:first-child": {
      overflow: "hidden",
      // width: "calc(100% - 45px)",
      // margin: "0 -5px",
      "& button": {
        margin: "0 5px",
      },
    },
    "& .MuiSpeedDial-actions": {
      "& .MuiFab-sizeSmall": {
        width: 30,
        height: 30,
        minHeight: 30,
        margin: 4,
        "& img": {
          width: 14,
        },
      },
    },
  },
  userRatedCount: {
    width: "100%",
    fontSize: "12px",
  },
}));

const AddList = ({ key, url, link, ...props }) => {
  const classes = useStyles();
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  const actions = [
    {
      icon: <img alt="" src={Facebook} />,
      name: "Facebook",
      href:
        "http://www.facebook.com/sharer/sharer.php?s=100&p[url]=" +
        encodeURIComponent(link),
    },
    {
      icon: <img alt="" src={Twitter} />,
      name: "Twitter",
      href: "https://twitter.com/intent/tweet?url=" + encodeURIComponent(link),
    },
    {
      icon: <img alt="" src={Linkedin} />,
      name: "Linkedin",
      href:
        "https://www.linkedin.com/cws/share?url=" + encodeURIComponent(link),
    },
    {
      icon: <img alt="" src={Whatsapp} />,
      name: "Whatsapp",
      href: "https://web.whatsapp.com://send?text=" + link,
    },
  ];

  const [advData, setadvData] = React.useState({});
  const [disableRating, setDisableRating] = useState(false);
  const [ratingCount, setRatingCount] = useState(0);
  let getUrl = props.location.pathname.replace("/", "");
  const getScreenName = useRef(getUrl);

  useEffect(() => {
    // getShowCLickCount(props.advData._id, "Viewed");
  }, [props.advData._id]);
  const handleAdsShareCount = (id, type, starRating) => {
    let URL = "";
    let inputObj = {};
    if (type === "start") {
      URL = API_Path + "api/ads/rating";
      inputObj = {
        inputObject: {
          advertisementId: id,
          ratingUserId: props.user._id,
          rating: starRating,
        },
      };
    } else if (type === "share") {
      URL = API_Path + "api/ads/share";
      inputObj = {
        inputObject: {
          advertisementId: id,
          userId: props.user._id,
        },
      };
    }
    axios
      .post(URL, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setadvData(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  React.useEffect(() => {
    setadvData(props.advData);
  }, [props.advData]);
  React.useEffect(() => {
    advData &&
      advData.rating_users &&
      advData.rating_users.length > 0 &&
      advData.rating_users.find(function (id) {
        // if (id.userId === props.user._id) {
        //     setDisableRating(true);
        //     setRatingCount(advData.rating)
        // } else {
        //     setDisableRating(false);
        //     setRatingCount(0)
        // }
        if (id.userId === props.user._id) {
          setRatingCount(id.rating);
          setDisableRating(true);
          return true;
        }
      });
  }, [advData]);
  const handleRedirect = async (data, e) => {
    window.open(data.redirect_click_url, "_blank");
    // getShowCLickCount(data._id, "Clicked");
  };

  // const getShowCLickCount = async (adsid, forCount) => {
  //   let screen = getUrl.current;
  //   if (getScreenName.current === "activeDebateList") {
  //     screen = "Dashboard - Live Debate";
  //   } else if (getScreenName.current === "recorded") {
  //     screen = "Dashboard - Recorded";
  //   } else if (getScreenName.current === "broadcasts") {
  //     screen = "Dashboard - Broadcasts";
  //   } else if (getScreenName.current === "myDebates") {
  //     screen = "Dashboard - My Debates";
  //   } else if (getScreenName.current === "completedDebate") {
  //     screen = "Completed Debate - Debate Details";
  //   } else if (getScreenName.current === "activeDebate") {
  //     screen = "Live Debate - Debate Details";
  //   }

  //   var AdvertisementDetails = API_Path + "api/ad_metrics/add";
  //   let inputObj = {
  //     inputObject: {
  //       adsId: adsid,
  //       screenName: screen,
  //       keyword:
  //         props.classificationKeyword !== null
  //           ? props.classificationKeyword
  //           : null,
  //       status: forCount,
  //       created_by: props.user._id,
  //     },
  //   };
  //   await axios
  //     .post(AdvertisementDetails, inputObj, {
  //       headers: {
  //         Authorization: "Basic " + props.auth.accessToken,
  //       },
  //     })
  //     .then((response) => {
  //       // setAdvertisementData(response.data.result)
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  const handleCopyLink = () => {
    setSnackbarState({
      open: true,
      message: "Link Copied!",
      severity: "success",
    });
  };
  let adCategoryList =
    advData && advData.target_intrest_tags && advData.target_intrest_tags.length > 0
      ? advData.target_intrest_tags
      : [];
  return (
    <>
      <div key={key} className={classes.imageCard} ref={props.ref}>
        {advData && advData.type_of_ad_file === "Image" ? (
          <img
            alt=""
            src={url}
            style={{ maxWidth: "100%", cursor: "pointer" }}
            onClick={handleRedirect.bind(this, advData)}
          />
        ) : (
          <VideoPlayer
            videoURL={url}
            videoThumbnail={false}
            playing={true}
            muted={true}
            loop={true}
            hideWaterMark={true}
          />
        )}

        <div className={classes.imageCardBody}>
          <h5
            className="m-0"
            style={{ cursor: "pointer" }}
            onClick={handleRedirect.bind(this, advData)}
          >
            {advData && advData.ad_title}
          </h5>
          <div className={clsx(classes.flexJCSB, classes.btnwrap)}>
            <div>
              {adCategoryList.length > 0 ? (
                <>
                  {adCategoryList &&
                    adCategoryList.map((classifi, index) => {
                      return (
                        <Button
                          key={index}
                          variant="outlined"
                          className="smallButton"
                        >
                          {classifi.name}
                        </Button>
                      );
                    })}
                </>
              ) : (
                ""
              )}
            </div>
            <Box display="flex" position="relative">
              <CopyLink
                icon={ExpandImage}
                // value={advData && advData.share_ad_url}
                value={advData && advData.redirect_click_url}
                handleCopyLink={handleCopyLink}
                tooltipPlacement={"bottom"}
              />
              <span style={{ marginRight: "5px" }}></span>
              <CustomSocialFab
                handleAdsShareCount={handleAdsShareCount}
                advId={advData && advData._id}
                advertisement={true}
                actions={actions}
                direction="up"
                icon={ShareImage}
                tooltipPlacement={"bottom"}
              />
              <Box ml={1} display="flex">
                <Report
                  showType="Icon"
                  type="Reporting Advertisement"
                  primaryKey={advData._id}
                />
              </Box>
              {/* <input ref={myRef} value={advData && advData.link} className={classes.copyClipboard} />
                        <Button link="#" size="small" className="iconBtn" style={{ marginRight: '5px' }}
                            onClick={copyClipboard}>
                            <img alt="" src={ExpandImage} />
                        </Button> */}
              {/* <Button link="#" size="small" className="iconBtn">
                                        <img alt="" src={ShareImage} />
                                    </Button> */}
              {/* <Button link="#" size="small" className="iconBtn">
                                        <img alt="" src={MenuImage} />
                                    </Button> */}
            </Box>
          </div>
          <div className="seperatorImageCard"></div>
          <div>
            {}
            {advData && advData.description ? (
              <ShowMoreLessText description={advData && advData.description} />
            ) : (
              ""
            )}
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap", marginBottom: "12px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "18px",
                cursor: "pointer",
              }}
            >
              <h6 style={{ margin: "0" }} className={classes.userRating}>
                {/* <img alt="" src={Star} style={{ margin: '0 10px 0 0' }} />  */}
                <StarBorderIcon
                  style={{ margin: "0 5px 0 0" }}
                  className={disableRating ? "rated" : ""}
                />
                {advData && advData.rating ? advData.rating : 0} Star
                {!disableRating && (
                  <div className="applyRating">
                    <CustomRating
                      name="advertismentRating"
                      rating={ratingCount}
                      numberOfStars={5}
                      changeRating={handleAdsShareCount.bind(
                        this,
                        advData && advData._id,
                        "start"
                      )}
                      starRatedColor="#ffc003"
                    />
                  </div>
                )}
              </h6>
              {/* <h6 style={{ margin: '0 0 0 10px' }} onClick={handleAdsShareCount.bind(this, advData && advData._id, "start")}>{advData && advData.rating ? advData.rating : 0} Stars</h6> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "18px",
              }}
            >
              <img alt="" src={Retweet} />{" "}
              <h6 style={{ margin: "0 0 0 10px" }}>
                {advData && advData.share_count} Share
              </h6>
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(AddList));
