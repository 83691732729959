import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from "clsx";
import React from 'react';
import { withRouter } from "react-router-dom";
import squareExpand from '../../../assets/images/actionIcons/squareExpand.svg';
import Share from '../../../assets/images/shareBoxed.svg';
import Facebook from '../../../assets/images/socialIcons/facebook.svg';
import Linkedin from '../../../assets/images/socialIcons/linkedin.svg';
import Twitter from '../../../assets/images/socialIcons/twitter.svg';
import Whatsapp from '../../../assets/images/socialIcons/whatsapp.svg';
import UserGroup from '../../../assets/images/userGroup.svg';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import CopyLink from '../CopyLink';
import DebateCategory from '../CustomDebateHeaderComponents/DebateCategory';
import DebateDetail from '../CustomDebateHeaderComponents/DebateDetail';
import DebateDMY from '../CustomDebateHeaderComponents/DebateDMY';
import DebateStatus from '../CustomDebateHeaderComponents/DebateStatus';
import CustomSocialFab from '../CustomSocialFab';
import { connect } from "react-redux";
import SnackBar from '../../../components/SnackBar';
import Report from '../ReportAbuse/Report';

const useStyles = makeStyles((theme) => ({
    flexRow: {
        display: 'flex',
        flexFlow: 'row'
    },
    flexJCSB: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    headingContainer: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column'
        },
    },
    maxShare: {
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: 0,
            right: 0
        },
    },
    catAtteSection: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column'
        },
    },
    activeStatus: {
        position: 'absolute',
        right: '22px',
        top: '0px',
        "& svg": {
          boxShadow: '-1px 1px 10px -3px rgba(0, 0, 0, .5)',
          borderRadius: '50%',
          color: '#06c',
          backgroundColor: '#fff',
          fontSize: '26px'
        }
      },
}));


const CustomDebateHeaderImageContent = (props) => {
    const classes = useStyles();
    const [snackbarState, setSnackbarState] = React.useState({ open: false })
    const [open] = React.useState(false);
    const anchorRef = React.useRef(null);
    const path = props.shareLink;
    const actions = [
        { icon: <img alt="" src={Facebook} />, name: 'Facebook', href: 'http://www.facebook.com/sharer/sharer.php?s=100&p[url]=' + encodeURIComponent(path) },
        { icon: <img alt="" src={Twitter} />, name: 'Twitter', href: 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(path) },
        { icon: <img alt="" src={Linkedin} />, name: 'Linkedin', href: 'https://www.linkedin.com/cws/share?url=' + encodeURIComponent(path) },
        { icon: <img alt="" src={Whatsapp} />, name: 'Whatsapp', href: 'whatsapp://send?text=' + path },
    ];


    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);
    // let time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const onClose = (event, reason) => {
        setSnackbarState({ open: false });
    };
    const handleCopyLink = () => {
        setSnackbarState({
            open: true,
            message: "Link Copied!",
            severity: "success",
        });
    }
    return (
        <>
            <div className={clsx(classes.flexRow, classes.headingContainer)}>
                <div style={{ position: 'relative' }}>
                    <DebateDMY listDMY={props} normalDMY={false} />
                    {props.debateDetail && 
                    props.debateDetail.created_by && !!props.debateDetail.created_by.badge_status &&<span className={classes.activeStatus} title="Verified User/Public Figure">
                      <VerifiedUserRoundedIcon />
                    </span>
                    }
                </div>
                <Box width="100%">
                    <div className={classes.flexJCSB} style={{ width: '100%' }}>
                        <DebateDetail debateDetailValues={props} />
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                            <div className={classes.maxShare}>
                                <CopyLink icon={squareExpand} value={props.shareLink} handleCopyLink={handleCopyLink} />
                                <CustomSocialFab actions={actions} debateId={props.debateDetail && props.debateDetail._id} direction="down" icon={Share} />
                                <Report showType="Icon Square" type="Reporting Debate" primaryKey={props.debateDetail && props.debateDetail._id} />
                            </div>
                        </div>
                    </div>
                    <Box className={classes.catAtteSection}>
                        <DebateCategory categoryValue={props} />
                        <div style={{ display: 'flex', marginTop: 'auto', alignItems: 'center' }}>
                            <h5 className="m-0 font-weight-normal" style={{ color: '#758194', marginRight: '18px' }}>
                                <img alt="" src={UserGroup} /> Total Attendees <span style={{ fontWeight: '500' }}>{props.audianceAttended}</span>
                            </h5>
                            <DebateStatus debateBlocked={props.is_blocked} debateStatusList={props.status} />
                        </div>
                    </Box>
                </Box>
            </div>
            <SnackBar open={snackbarState.open} message={snackbarState.message} severity={snackbarState.severity} onClose={onClose} />
        </>
    )
}

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(CustomDebateHeaderImageContent));
