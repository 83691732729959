import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import {
  API_Path,
  Make_Base_Auth,
} from "../../../../src/screens/Common/Const/index";
import "../../../assets/css/quill.css";
// import Logo from '../../../assets/images/Rectangle.jpg'
import { Box } from "@material-ui/core";
import logoImage from "../../../assets/images/Vurbil - Final-02.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "center",
    padding: "30px 15px",

    [theme.breakpoints.up("md")]: {
      padding: "30px",
    },
    "& > .Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },
  headerWrapper: {
    backgroundColor: theme.palette.primary.main,
    "& svg": {
      color: "#fff",
    },
    "& .heading": {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: "#000000",
      textAlign:'left'
    },
    "&.Mui-expanded": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #f4f4f4",
      "& svg": {
        color: theme.palette.primary.main,
      },
      "& .heading": {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.main,
      },
    },
  },
}));

const FAQ = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  var historyinquiry = useParams().type;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    historyinquiry === "Adenquiry" ? getAdInquiryPage() : getStaticPage();
  }, []);
  const getStaticPage = () => {
    // let getStaticPageData = API_Path + "api/faqCategory/filter/Ad Inquiry"
    let getStaticPageData = API_Path + "api/faq/get";

    axios
      .get(getStaticPageData, {
        headers: {
          // 'Authorization': "Basic " + props.auth.accessToken
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        if (
          response !== null &&
          response.data !== null &&
          response.data.result !== null
        ) {
          // setPage(response.data.result.html_content);
          setTimeout(() => {
            setLoaded(true);
          }, 500);
          setPage(response.data.result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getAdInquiryPage = () => {
    // https://dev-api.vurbil.com/api/faqCategory/filter/:type
    let getStaticPageData = API_Path + "api/faqCategory/filter/Ad Inquiry";
    // let getStaticPageData = API_Path + "api/faq/get"

    axios
      .get(getStaticPageData, {
        headers: {
          Authorization: "Basic " + Make_Base_Auth(),
          // 'Authorization': Make_Base_Auth()
        },
      })
      .then((response) => {
        if (
          response !== null &&
          response.data !== null &&
          response.data.result !== null
        ) {
          // setPage(response.data.result.html_content);
          setTimeout(() => {
            setLoaded(true);
          }, 500);
          setPage(response.data.result[0].faqList);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      {/* <div className="ql-editor" style={{ opacity: (loaded ? '1' : '0') }} dangerouslySetInnerHTML={{ __html: page }}></div> */}
      <div className={classes.root}>
        <img src={logoImage} alt="Logo" style={{ width: "200px" }} />
        <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>FAQ</h2>
        {loaded ? (
          <>
            {page.length > 0 ? (
              <>
                {page.map((pageContent, index) => {
                  return (
                    <Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === `panel${index}` ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )
                        }
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                        className={classes.headerWrapper}
                      >
                        <Typography className={"heading"}>
                          {pageContent.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="ql-editor">
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: pageContent.answer,
                          }}
                        >
                          {/* {pageContent.answer} */}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Typography variant="h5">No Faq Available...</Typography>
              </Box>
            )}
          </>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Typography variant="h5">Loading...</Typography>
          </Box>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(FAQ));
