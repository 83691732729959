import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  Box,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import * as yup from "yup";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import clsx from "clsx";
import EyeSlash from "../../../assets/images/eyeSlash.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { DatePicker } from "formik-material-ui-pickers";
import TimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
// import CustomDebateContent from "../../Common/CustomDebateContent";
// import Advertisement from '../../Common/Advertisement';
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_Path, redirectTo, UI_DateFormat } from "../../Common/Const/index";
import CreateDebateAutocomplete from "./CreateDebateAutocomplete";

const useStyles = makeStyles((theme) => ({
  contentRightSection: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > div": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 364px)",
      },
      "&:only-child": {
        [theme.breakpoints.up("md")]: {
          width: "100%",
          "&.leftCreateSection": {
            width: "calc(100% + 36px)",
            margin: "-18px -18px -40px -18px",
          },
        },
      },
    },
  },
  leftCreateSection: {
    width: "100%",
    padding: "36px",
    backgroundColor: "#fff",
    marginTop: "-18px",
    [theme.breakpoints.up("md")]: {
      margin: "-18px 20px -40px -18px",
    },
  },
  headingWithBorderBottom: {
    margin: "0 0 20px 0",
    paddingBottom: "14px",
    borderBottom: "solid 1px #cdd1d8",
  },
  textField: {
    marginTop: theme.spacing(5),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    width: "100%",
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
  },
  textFieldArea: {
    maxHeight: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: "inherit",
    },
  },
  textFieldAutocomplete: {
    marginTop: "0",
    maxHeight: "inherit",
    minHeight: "inherit",
    "& .MuiAutocomplete-inputRoot": {
      padding: "0",
      backgroundColor: "#fff",
      maxHeight: "inherit",
    },
  },
  centerDiv: {
    maxWidth: 425,
    margin: "auto",
  },
  instantDebate: {
    display: "flex",
    margin: "40px 0 0px 0",
    padding: "15px 0 30px",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "transparent",
      padding: "15px 25px 0px",
      borderRadius: "12px",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& legend": {
        fontSize: "14px",
        color: "#313236",
        fontWeight: "500",
        position: "absolute",
        top: "-35px",
        [theme.breakpoints.up("md")]: {
          left: "-25px",
        },
      },
    },

    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      color: "#758194",
    },
  },
  creatPOMembers: {
    margin: "20px 0 35px 0",
    padding: "15px 0 30px",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#f6f7f8",
      padding: "15px 25px 30px",
      borderRadius: "12px",
    },
    "& .MuiFormControl-root": {
      "& legend": {
        fontSize: "14px",
        color: "#313236",
        fontWeight: "500",
        position: "absolute",
        top: "-35px",
        [theme.breakpoints.up("md")]: {
          left: "-25px",
        },
      },
    },

    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      color: "#758194",
    },
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  timeField: {
    "& .timeLabel": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      margin: "15px 0 4px 0",
    },
    "& .react-datepicker-wrapper": {
      "& input": {
        color: "#758194",
        height: "40px",
        padding: "10.5px 14px",
        fontSize: "12px",
        boxSizing: "border-box",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
      },
    },
  },
}));

// const top100Films = [
//     { title: 'The Shawshank Redemption', year: 1994 },
//     { title: 'The Godfather', year: 1972 },
//     { title: 'The Godfather: Part II', year: 1974 },
//     { title: 'The Dark Knight', year: 2008 },
// ]

const CreateDebate = (props) => {
  const classes = useStyles();
  const [instantVal, setInstant] = useState("No");
  const [selfParticipation, setSelfParticipation] = useState("Yes");
  const [switchVal, setSwitchVal] = useState(false);
  const [propositionVal, setproposition] = useState("Self");
  const [OppositionVal, setOpposition] = useState("Anyone");
  const [propositionList, setpropositionList] = useState();
  const [oppositionList, setoppositionList] = useState();
  const [getFriendList, setGetFriendList] = useState([]);
  const [getInvitedUsers, setGetInvitedUsers] = useState([]);
  const [privateAction, setprivateAction] = useState(false);
  const [error, setError] = useState(false);
  const [propositioninvitedList, setpropositioninvitedList] = useState();
  const [oppositioninvitedList, setoppositioninvitedList] = useState();
  const [debateCategory, setdebateCategory] = useState(
    props.BtnName === "Update" ? props.debateObject.category_id : ""
  );
  const [debateCategoryError, setdebateCategoryError] = useState(false);
  const [timeVal, setTimeVal] = useState(new Date());
  const [instantproposAnyone, setInstantproposAnyone] = useState("");
  const [instantopposAnyone, setInstantopposAnyone] = useState("");
  const source = axios.CancelToken.source();

  const [initState, setinitState] = useState({
    debate_title: "",
    debate_category: "",
    dob: null,
    time: "",
    time_zone: "",
    proposition_details: "",
    opposition_details: "",
  });

  const [helperTextProp, setHelperTextProp] = useState("");
  const [helperTextOppo, setHelperTextOppo] = useState("");
  const [helperTextPropositionDetails, setHelperTextPropositionDetails] =
    useState("");
  const [helperTextOppositionDetails, setHelperTextOppositionDetails] =
    useState("");
  const [propositionDescription, setPropositionDescription] = useState("");
  const [oppositionDescription, setOppositionDescription] = useState("");
  const [id, setId] = useState("");
  const [activeDebateObject, setActiveDebateObject] = useState({});
  const [activeDebateRoomName, setActiveDebateRoomName] = useState({});
  const [redirectToLiveDebate, setRedirectToLiveDebate] = useState(false);
  const [submitted, setsubmitted] = useState(false);

  let inactiveDebate = props.debateObject !== null && props.debateObject.status;

  useEffect(() => {
    if (props.BtnName === "Create") {
      setInstant("No");
      setinitState({
        debate_title: "",
        debate_category: "",
        dob: moment(new Date()).format("YYYY-MM-DD"),
        time: "",
        time_zone: "",
        proposition_details: "",
        opposition_details: "",
      });
      setproposition("Self");
      setOpposition("Anyone");
      setSwitchVal(false);
      setId("");
      setError(false);
      setHelperTextProp("");
      setHelperTextOppo("");
    } else if (props.BtnName === "Update") {
      const debateObj = props.debateObject;
      let dob = new Date(debateObj.debate_planned_start_datetime);
      let tm = new Date(debateObj.debate_planned_start_datetime);

      setinitState({
        debate_title: debateObj.title,
        dob: dob,
        time: tm,
        proposition_details: debateObj.proposition_details,
        opposition_details: debateObj.opposition_details,
      });
      let proposition_user = "";
      let opposition_user = "";
      setTimeVal(tm);
      if (inactiveDebate === 5) {
        setPropositionDescription("");
        setOppositionDescription("");
      } else {
        setPropositionDescription(debateObj.proposition_details);
        setOppositionDescription(debateObj.opposition_details);
      }

      if (debateObj.is_instant_debate === 1) {
        setInstant("Yes");
        setprivateAction(true);
      } else {
        setInstant("No");
      }
      if (!debateObj.is_participate_in_this_debate) {
        setSelfParticipation("No");
      } else {
        setSelfParticipation("Yes");
      }
      if (debateObj.proposition_type === "Anyone") {
        setInstantproposAnyone(
          debateObj.proposition_users.length > 0
            ? debateObj.proposition_users
            : ""
        );
      }
      if (debateObj.opposition_type === "Anyone") {
        setInstantopposAnyone(
          debateObj.opposition_users.length > 0
            ? debateObj.opposition_users
            : ""
        );
      }
      if (debateObj.proposition_type === "Invited") {
        setpropositioninvitedList(debateObj.proposition_users[0].user);
      } else if (debateObj.proposition_type === "My Friend list") {
        //proposition_user = { "friend_id": debateObj.proposition_users && debateObj.proposition_users[0].user }
        proposition_user =
          debateObj.proposition_users && debateObj.proposition_users[0].user;
      }

      if (debateObj.opposition_type === "Invited") {
        setoppositioninvitedList(debateObj.opposition_users[0].user);
      } else if (debateObj.opposition_type === "My Friend list") {
        //opposition_user = { "friend_id": debateObj.opposition_users && debateObj.opposition_users[0].user }
        opposition_user =
          debateObj.opposition_users && debateObj.opposition_users[0].user;
      }

      setproposition(debateObj.proposition_type);
      setdebateCategory(debateObj.category_id);
      setSwitchVal(debateObj.debate_type === "Private" ? true : false);
      setOpposition(debateObj.opposition_type);
      setId(debateObj._id);
      props.myRef.current.scrollIntoView();
      setpropositionList(proposition_user);
      setoppositionList(opposition_user);
    }
    return () => {
      source.cancel();
    };
  }, []);

  const validationSchema = yup.object({
    debate_title: yup.string().trim().required("Debate Title is Required"),
    // debate_category: yup.array()
    //     .of(yup.object().shape({
    //         name: yup.string().required("Name required"),
    //         // Rest of your amenities object properties
    //     })),
    // instantDebate: yup.string().required(),
    // proposition_details: yup.string().when('instantDebate', {
    //     is: val => (val === "No" ? true : false),
    //     then: yup.string().required('Proposition Details is Required')
    // }),
    // opposition_details: yup.string().when('instantDebate', {
    //     is: val => (val === "No" ? true : false),
    //     then: yup.string().required('Opposition Details is Required')
    // }),
    // privatePublicDebate: yup.string().required(),
    // propositionMemberTeam: yup.string().when('privatePublicDebate', {
    //     is: val => (val === "No" ? true : false),
    //     then: yup.string().required('proposition Member is Required')
    // }),
  });

  const handleInstant = async (event) => {
    setInstant(event.target.value);
    // setSwitchVal(event.target.value === "Yes" ? true : false);
    setSwitchVal(false);
    setprivateAction(event.target.value === "Yes" ? true : false);
    setproposition("Anyone");
    setOpposition("Anyone");

    // await handleFriendSearch("My Friend list");
    // await handleFriendSearchInvited("Invited");
  };

  const handleParticipation = (event) => {
    setSelfParticipation(event.target.value);
    setproposition("Anyone");
    setOpposition("Anyone");
  };

  const handleSwitch = (event) => {
    setSwitchVal(event.target.checked);
  };

  const handleProposition = (event) => {
    setproposition(event.target.value);
    if (event.target.value === "Self" && OppositionVal === "Self") {
      setOpposition(!switchVal ? "Anyone" : "Invited");
    }

    let type =
      event.target.value === "My Friend list" ? "My Friends" : "Invited";

    // if (event.target.value === "My Friend list" || event.target.value === "Invited") {
    //     handleFriendSearch(type);
    // }
    if (event.target.value === "My Friend list") {
      handleFriendSearch(type);
    }

    if (event.target.value === "Invited") {
      handleFriendSearchInvited(type);
    }
  };

  const handleOpposition = (event) => {
    setOpposition(event.target.value);
    if (event.target.value === "Self" && propositionVal === "Self") {
      setproposition(!switchVal ? "Anyone" : "Invited");
    }

    let type =
      event.target.value === "My Friend list" ? "My Friends" : "Invited";

    // if (event.target.value === "My Friend list" || event.target.value === "Invited") {
    //     handleFriendSearch(type);
    // }
    if (event.target.value === "My Friend list") {
      handleFriendSearch(type);
    }
    if (event.target.value === "Invited") {
      handleFriendSearchInvited(type);
    }
  };

  const handleFriendSearch = async (type) => {
    let friendsearchAPI = API_Path + "api/friends/search";
    let inputObj = {
      inputObject: {
        type: type,
        userId: props.user._id,
        listType: "dropdown",
        isOnline: instantVal === "Yes" ? 1 : 0,
      },
    };
    await axios
      .post(friendsearchAPI, inputObj, {
        cancelToken: source.token,
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (type === "My Friends") {
          setGetFriendList(response.data.result);
        } else {
          setGetInvitedUsers(response.data.result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFriendSearchInvited = async () => {
    let friendsearchAPI = API_Path + "api/users/getInvitedUsers";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
        isOnline: instantVal === "Yes" ? 1 : 0,
      },
    };
    await axios
      .post(friendsearchAPI, inputObj, {
        cancelToken: source.token,
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setGetInvitedUsers(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCategory = (newValue) => {
    if (newValue && newValue.length > 3) {
      newValue.splice(2, 1);
      props.setSnackbarState({
        open: true,
        message: "Maximum 3 category only ",
        severity: "error",
      });
    }
    setdebateCategory(newValue);
    setdebateCategoryError(false);
  };

  const handlePropositionDetails = (event) => {
    setPropositionDescription(event.target.value);
  };
  const handleOppositionDetails = (event) => {
    setOppositionDescription(event.target.value);
  };

  const triggerDebateSubmit = (state) => {
    let inputObj = {};
    let proposition_users = [];
    let propositionObj = {};
    // let proposition_details = ""; let opposition_details = "";
    setHelperTextProp("");
    setHelperTextOppo("");
    setHelperTextPropositionDetails("");
    setHelperTextOppositionDetails("");
    if (instantVal === "No" && selfParticipation === "Yes") {
      if (propositionDescription.trim() === "" && propositionVal === "Self") {
        setHelperTextPropositionDetails("Proposition Details is Required");
        return false;
      }
      if (oppositionDescription.trim() === "" && OppositionVal === "Self") {
        setHelperTextOppositionDetails("Opposition Details is Required");
        return false;
      }
    }

    if (switchVal) {
      if (propositionVal === "Anyone") {
        setHelperTextProp("Please select an option.");
        setError(true);
        return false;
      }
      if (OppositionVal === "Anyone") {
        setHelperTextOppo("Please select an option.");
        setError(true);
        return false;
      }
    }
    if (propositionVal === "My Friend list") {
      if (propositionList === undefined || propositionList === "") {
        setHelperTextProp("Please select an option.");
        setError(true);
        return false;
      }
    } else if (propositionVal === "Invited") {
      if (
        propositioninvitedList === undefined ||
        propositioninvitedList === ""
      ) {
        setHelperTextProp("Please select an option.");
        setError(true);
        return false;
      }
    }
    if (OppositionVal === "My Friend list") {
      if (oppositionList === undefined || oppositionList === "") {
        setHelperTextOppo("Please select an option.");
        setError(true);
        return false;
      }
    } else if (OppositionVal === "Invited") {
      if (oppositioninvitedList === undefined || oppositioninvitedList === "") {
        setHelperTextOppo("Please select an option.");
        setError(true);
        return false;
      }
    }

    if (
      debateCategory !== "" &&
      debateCategory !== {} &&
      debateCategory.length !== 0
    ) {
      setdebateCategoryError(false);
    } else {
      setdebateCategoryError(true);
      return true;
    }
    let opposition_users = [];
    let oppositionObj = {};
    if (propositionVal === "Self") {
      propositionObj = {
        email: props.user.email,
        user: props.user._id,
        proposedDate: null,
        activeStatus: 0,
      };
      proposition_users.push(propositionObj);
    } else if (propositionVal === "My Friend list") {
      propositionObj = {
        // "email": "",
        // "user": propositionList.friend_id._id,
        email: propositionList.email,
        user: propositionList._id,
        proposedDate: null,
        activeStatus: 0,
      };
      proposition_users.push(propositionObj);
    } else if (propositionVal === "Invited") {
      propositionObj = {
        email: propositioninvitedList.email,
        user: propositioninvitedList._id,
        proposedDate: null,
        activeStatus: 0,
      };
      proposition_users.push(propositionObj);
    } else if (propositionVal === "Anyone") {
      if (instantproposAnyone !== "") {
        propositionObj = {
          email: instantproposAnyone[0].email,
          user: instantproposAnyone[0].user._id,
          proposedDate: null,
          activeStatus: 0,
        };
        proposition_users.push(propositionObj);
      }
    }
    if (OppositionVal === "Self") {
      oppositionObj = {
        email: props.user.email,
        user: props.user._id,
        proposedDate: null,
        activeStatus: 0,
      };
      opposition_users.push(oppositionObj);
    } else if (OppositionVal === "My Friend list") {
      oppositionObj = {
        // "email": "",
        //"user": oppositionList.friend_id._id,
        email: oppositionList.email,
        user: oppositionList._id,
        proposedDate: null,
        activeStatus: 0,
      };
      opposition_users.push(oppositionObj);
    } else if (OppositionVal === "Invited") {
      propositionObj = {
        email: oppositioninvitedList.email,
        user: oppositioninvitedList._id,
        proposedDate: null,
        activeStatus: 0,
      };
      opposition_users.push(propositionObj);
    } else if (OppositionVal === "Anyone") {
      if (instantopposAnyone !== "") {
        oppositionObj = {
          email: instantopposAnyone[0].email,
          user: instantopposAnyone[0].user._id,
          proposedDate: null,
          activeStatus: 0,
        };
        opposition_users.push(oppositionObj);
      }
    }
    let startDate = "",
      endDate = "",
      tm = "";
    if (timeVal) {
      tm = timeVal.getHours() + ":" + timeVal.getMinutes();
    } else {
      tm = new Date().getHours() + ":" + new Date().getMinutes();
    }
    var d;
    if (instantVal === "Yes") {
      let dt =
        moment(new Date()).format("YYYY/MM/DD") +
        " " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes();
      d = new Date(dt);
    } else if (instantVal === "No") {
      let dt = moment(timeVal).format("YYYY/MM/DD") + " " + tm;
      d = new Date(dt);
    }

    // let dt = moment(state.dob).format("YYYY-MM-DD") + " " + tm; var d = new Date(dt);

    let milli = d.getTime() + 1200000;
    var de = new Date(milli);

    startDate = moment([
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
    ])
      .utc()
      .format();
    endDate = moment([
      de.getFullYear(),
      de.getMonth(),
      de.getDate(),
      de.getHours(),
      de.getMinutes(),
    ])
      .utc()
      .format();

    if (props.BtnName === "Create") {
      inputObj = {
        inputObject: {
          title: state.debate_title,
          debate_type: switchVal ? "Private" : "Public",
          status: 0,
          description: "BMK vs FPK",
          thumbnails: null,
          category_id: debateCategory,
          proposition_details: propositionDescription,
          opposition_details: oppositionDescription,
          proposition_type: propositionVal,
          proposition_users: proposition_users,
          opposition_type: OppositionVal,
          opposition_users: opposition_users,
          created_by: props.BtnName === "Create" ? props.user._id : null,
          debate_owner: 0,
          debate_planned_start_datetime: startDate,
          debate_planned_end_datetime: endDate,
          is_instant_debate: instantVal === "Yes" ? 1 : 0,
          is_participate_in_this_debate:
            selfParticipation === "Yes" ? true : false,
        },
      };
      createNewDebate(inputObj);
    } else if (props.BtnName === "Update") {
      if (instantVal === "Yes" && inactiveDebate === 5) {
        proposition_users = [];
        opposition_users = [];
      }

      inputObj = {
        inputObject: {
          _id: id,
          title: state.debate_title,
          debate_type: switchVal ? "Private" : "Public",
          category_id: debateCategory,
          proposition_details: propositionDescription,
          opposition_details: oppositionDescription,
          proposition_type: propositionVal,
          proposition_users: proposition_users,
          opposition_type: OppositionVal,
          opposition_users: opposition_users,
          updated_at: new Date(),
          updated_by: props.BtnName === "Update" ? props.user._id : null,
          debate_planned_start_datetime: startDate,
          debate_planned_end_datetime: endDate,
          is_instant_debate: instantVal === "Yes" ? 1 : 0,
          is_participate_in_this_debate:
            selfParticipation === "Yes" ? true : false,
        },
      };
      updateNewDebate(inputObj);
    }
  };

  const createNewDebate = async (inputObj) => {
    setsubmitted(true);
    var DebateAPI = API_Path + "debates/create";
    props.setLoader(true);
    await axios
      .post(DebateAPI, inputObj, {
        cancelToken: source.token,
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          if (response.data.result.is_instant_debate === 1) {
            setActiveDebateObject(response.data.result);
            setActiveDebateRoomName(response.data.result._id);
            setRedirectToLiveDebate(true);
            // props.toggle(false);
          } else {
            props.toggle(false);
            props.reloadDebateDetails();
            props.myRef.current.scrollIntoView();
          }
          props.setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "success",
          });
          props.setLoader(false);
        } else {
          if (
            response.data.message ===
            "Both Proposition and opposition user not available for this slot"
          ) {
            props.setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
            props.setLoader(false);
          }
          if (
            response.data.message ===
            "Proposition user not available for this slot"
          ) {
            props.setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
            props.setLoader(false);
          }
          if (
            response.data.message ===
            "Opposition user not available for this slot"
          ) {
            props.setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
            props.setLoader(false);
          }
        }
        setsubmitted(false);
      })
      .catch((error) => {
        props.setLoader(false);
        console.error(error);
      });
    setsubmitted(false);
  };

  const filterPassedTime = (dob) => {
    const currentDate = new Date();
    const selectedDate = new Date(dob);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const updateNewDebate = async (inputObj) => {
    setsubmitted(true);
    var DebateAPI = API_Path + "debates/update/" + id;
    props.setLoader(true);
    await axios
      .put(DebateAPI, inputObj, {
        cancelToken: source.token,
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          if (props && props.closeModal) {
            props.closeModal("Success");
          }
          if (response.data.result.is_instant_debate === 1) {
            setActiveDebateObject(response.data.result);
            setActiveDebateRoomName(response.data.result._id);
            setRedirectToLiveDebate(true);
            props.toggle(false);
          } else {
            props.reloadDebateDetails();
            props.toggle(false);
            props.myRef.current.scrollIntoView();
          }
          props.setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "success",
          });
          props.setLoader(false);
        } else {
          if (
            response.data.message ===
            "Both Proposition and opposition user not available for this slot"
          ) {
            props.setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
            props.setLoader(false);
          }
          if (
            response.data.message ===
            "Proposition user not available for this slot"
          ) {
            props.setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
            props.setLoader(false);
          }
          if (
            response.data.message ===
            "Opposition user not available for this slot"
          ) {
            props.setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
            props.setLoader(false);
          }
        }
        setsubmitted(false);
      })
      .catch((error) => {
        props.setLoader(false);
        console.error(error);
      });
    setsubmitted(false);
  };
  let timeZone = new Date().getTimezoneOffset() / 60;
  let temVal = timeZone.toString();
  temVal = temVal.replace("-5.5", "+5:30");
  timeZone = "UTC " + temVal;

  return (
    <div
      className={classes.contentRightSection}
      style={props.style ? props.style : {}}
    >
      {redirectToLiveDebate
        ? redirectTo("/activeDebate", {
            debateObj: activeDebateObject,
            roomName: activeDebateRoomName,
          })
        : ""}
      <div className={"leftCreateSection " + classes.leftCreateSection}>
        <Formik
          enableReinitialize
          initialValues={{ ...initState }}
          onSubmit={triggerDebateSubmit}
          validationSchema={validationSchema}
          validateOnBlur={true}
        >
          {({ isSubmitting }) => {
            return (
              <Form autoComplete="off">
                {!props.closeModal ? (
                  <h2 className={classes.headingWithBorderBottom}>
                    {" "}
                    {props.BtnName} Debate
                  </h2>
                ) : (
                  ""
                )}

                <div className={classes.centerDiv}>
                  {!props.closeModal ? (
                    <>
                      <Field
                        name="debate_title"
                        label="Debate Title"
                        placeholder=""
                        type="text"
                        as={FormikInput}
                        className={classes.textField}
                        variant="outlined"
                      />
                      <div style={{ marginTop: "10%" }}>
                        <Autocomplete
                          multiple
                          onChange={(event, newValue) => {
                            handleCategory(newValue);
                          }}
                          id="tags-standard"
                          options={props.classificationDetails}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) =>
                            value.name === option.name
                          }
                          defaultValue={
                            props.BtnName === "Update" &&
                            debateCategory !== null &&
                            debateCategory !== ""
                              ? debateCategory
                              : []
                          }
                          renderInput={(params) => (
                            <Field
                              {...params}
                              name="debate_category"
                              label="Debate Category (Maximum 3 category only)"
                              placeholder="Search"
                              type="text"
                              as={FormikInput}
                              className={clsx(
                                classes.textField,
                                classes.textFieldAutocomplete
                              )}
                              variant="outlined"
                            />
                          )}
                        />
                        {debateCategoryError ? (
                          <div
                            style={{ fontSize: "0.75rem", color: "#f44336" }}
                          >
                            {"Debate Category is Required"}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={top100Films}
                                            getOptionLabel={(option) => option.title}
                                            defaultValue={[top100Films[1]]}
                                            renderInput={(params) => (
                                                <Field {...params} name="debate_category" label="Debate Category" placeholder="Search" type="text" as={FormikInput} className={clsx(classes.textField, classes.textFieldAutocomplete)} variant="outlined" />
                                            )}
                                        /> */}
                      <div className={classes.instantDebate}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Instant Debate
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="instantDebate"
                            name="instantDebate"
                            value={instantVal}
                            onChange={handleInstant}
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio color="secondary" />}
                              label="Yes"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio color="secondary" />}
                              label="No"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                        {instantVal === "No" && (
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Are you participating in this debate?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-label="selfParticipation"
                              name="selfParticipation"
                              value={selfParticipation}
                              onChange={handleParticipation}
                            >
                              <FormControlLabel
                                value="Yes"
                                control={<Radio color="secondary" />}
                                label="Yes"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="No"
                                control={<Radio color="secondary" />}
                                label="No"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </div>
                      {instantVal === "No" && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              {/* <Field name="dob" label="Date" placeholder="" type="date" as={FormikInput} className={clsx(classes.textField)} variant="outlined" /> */}
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Field
                                  component={DatePicker}
                                  format={UI_DateFormat}
                                  name="dob"
                                  label="Date"
                                  placeholder=""
                                  value={timeVal}
                                  disabled={privateAction}
                                  onChange={(date) => setTimeVal(date)}
                                  as={FormikInput}
                                  className={clsx(
                                    classes.textField,
                                    classes.labelShrink
                                  )}
                                  autoOk
                                  inputVariant="outlined"
                                  variant="inline"
                                  minDate={new Date()}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              {/* <Field name="time" label="Time" placeholder="" type="time" as={FormikInput} className={clsx(classes.textField)} variant="outlined" /> */}
                              <div className={classes.timeField}>
                                <div className="timeLabel">Time</div>
                                <TimePicker
                                  selected={timeVal}
                                  onChange={(date) => setTimeVal(date)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  filterTime={filterPassedTime}
                                  timeIntervals={5}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  showDisabledMonthNavigation
                                  onKeyDown={(e) => e.preventDefault()}
                                  disabled={privateAction}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Field
                                name="time_zone"
                                label="Time Zone"
                                placeholder=""
                                disabled
                                value={timeZone}
                                type="text"
                                as={FormikInput}
                                className={clsx(classes.textField)}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          <FormControl
                            error
                            fullWidth
                            className="propositionMemberTeam"
                          >
                            <TextField
                              name="proposition_details"
                              label="Proposition Details"
                              multiline
                              fullWidth
                              row={4}
                              rowsMax={4}
                              variant="outlined"
                              value={propositionDescription}
                              onChange={handlePropositionDetails}
                              className={clsx(
                                classes.textField,
                                classes.textFieldArea
                              )}
                            />
                            <FormHelperText>
                              {helperTextPropositionDetails}
                            </FormHelperText>
                          </FormControl>
                          <FormControl error fullWidth>
                            <TextField
                              name="opposition_details"
                              label="Opposition Details"
                              multiline
                              fullWidth
                              row={4}
                              rowsMax={4}
                              variant="outlined"
                              value={oppositionDescription}
                              onChange={handleOppositionDetails}
                              className={clsx(
                                classes.textField,
                                classes.textFieldArea
                              )}
                            />
                            <FormHelperText>
                              {helperTextOppositionDetails}
                            </FormHelperText>
                          </FormControl>
                          <div style={{ display: "flex", marginTop: "15px" }}>
                            <img src={EyeSlash} alt="" />
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={switchVal}
                                  onChange={handleSwitch}
                                  name="privatePublicDebate"
                                  color="secondary"
                                />
                              }
                              label="Private Debate"
                              labelPlacement="start"
                            />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {instantVal === "No" && props.closeModal ? (
                    <>
                      <FormControl
                        error
                        fullWidth
                        className="propositionMemberTeam"
                      >
                        <TextField
                          name="proposition_details"
                          label="Proposition Details"
                          multiline
                          fullWidth
                          row={4}
                          rowsMax={4}
                          variant="outlined"
                          value={propositionDescription}
                          onChange={handlePropositionDetails}
                          className={clsx(
                            classes.textField,
                            classes.textFieldArea
                          )}
                        />
                        <FormHelperText>
                          {helperTextPropositionDetails}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        error
                        fullWidth
                        className="oppositionMemberTeam"
                      >
                        <TextField
                          name="opposition_details"
                          label="Opposition Details"
                          multiline
                          fullWidth
                          row={4}
                          rowsMax={4}
                          variant="outlined"
                          value={oppositionDescription}
                          onChange={handleOppositionDetails}
                          className={clsx(
                            classes.textField,
                            classes.textFieldArea
                          )}
                        />
                        <FormHelperText>
                          {helperTextOppositionDetails}
                        </FormHelperText>
                      </FormControl>
                    </>
                  ) : (
                    ""
                  )}

                  {instantVal === "No" && (
                    <>
                      <div
                        className={
                          classes.creatPOMembers + " propositionMemberTeam"
                        }
                      >
                        <FormControl component="fieldset" error={error}>
                          <FormLabel component="legend">
                            Proposition Member
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="propositionMemberTeam"
                            name="propositionMemberTeam"
                            value={propositionVal}
                            onChange={handleProposition}
                          >
                            {selfParticipation === "Yes" && (
                              <FormControlLabel
                                value="Self"
                                control={<Radio color="secondary" />}
                                label="Self"
                                labelPlacement="end"
                              />
                            )}
                            <FormControlLabel
                              value="My Friend list"
                              control={<Radio color="secondary" />}
                              label="My Vurber list"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="Invited"
                              control={<Radio color="secondary" />}
                              label="Invite"
                              labelPlacement="end"
                            />
                            {switchVal === false && (
                              <FormControlLabel
                                value="Anyone"
                                control={<Radio color="secondary" />}
                                label="Anyone"
                                labelPlacement="end"
                              />
                            )}
                          </RadioGroup>
                          {switchVal === false &&
                          propositionVal === "Anyone" &&
                          instantproposAnyone &&
                          instantproposAnyone.length > 0 &&
                          instantproposAnyone !== false ? (
                            <TextField
                              disabled
                              label=""
                              id="outlined-margin-dense"
                              defaultValue={
                                instantproposAnyone &&
                                instantproposAnyone.length > 0 &&
                                instantproposAnyone[0].user &&
                                instantproposAnyone[0].user.full_name
                              }
                              margin="dense"
                              variant="outlined"
                            />
                          ) : (
                            ""
                          )}
                          {propositionVal === "My Friend list" && (
                            <CreateDebateAutocomplete
                              onChangeAutoComplete={setpropositionList}
                              filteredList={oppositionList}
                              populateList={getFriendList}
                              defaultValue={
                                props.BtnName === "Update"
                                  ? propositionList
                                  : ""
                              }
                              getOptionLabel={(option) =>
                                option && option.first_name
                                  ? option.first_name + " " + option.last_name
                                  : ""
                              }
                              multiple={false}
                              placeholder={"Search"}
                              classes={classes}
                            />
                          )}
                          {propositionVal === "Invited" && (
                            <CreateDebateAutocomplete
                              onChangeAutoComplete={setpropositioninvitedList}
                              filteredList={oppositioninvitedList}
                              populateList={getInvitedUsers}
                              defaultValue={
                                props.BtnName === "Update"
                                  ? propositioninvitedList
                                  : ""
                              }
                              getOptionLabel={(option) =>
                                option && option.first_name
                                  ? option.first_name + " " + option.last_name
                                  : ""
                              }
                              multiple={false}
                              placeholder={"Search"}
                              classes={classes}
                            />
                          )}
                          <FormHelperText>{helperTextProp}</FormHelperText>
                        </FormControl>
                      </div>
                      <div
                        className={
                          classes.creatPOMembers + " oppositionMemberTeam"
                        }
                      >
                        <FormControl component="fieldset" error={error}>
                          <FormLabel component="legend">
                            Opposition Member
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="OppositionMemberTeam"
                            name="oppositionMemberTeam"
                            value={OppositionVal}
                            onChange={handleOpposition}
                          >
                            {selfParticipation === "Yes" && (
                              <FormControlLabel
                                disabled={privateAction}
                                value="Self"
                                control={<Radio color="secondary" />}
                                label="Self"
                                labelPlacement="end"
                              />
                            )}
                            <FormControlLabel
                              disabled={privateAction}
                              value="My Friend list"
                              control={<Radio color="secondary" />}
                              label="My Vurber list"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              disabled={privateAction}
                              value="Invited"
                              control={<Radio color="secondary" />}
                              label="Invite"
                              labelPlacement="end"
                            />
                            {switchVal === false && (
                              <FormControlLabel
                                disabled={privateAction}
                                value="Anyone"
                                control={<Radio color="secondary" />}
                                label="Anyone"
                                labelPlacement="end"
                              />
                            )}
                          </RadioGroup>
                          {switchVal === false &&
                          OppositionVal === "Anyone" &&
                          instantopposAnyone &&
                          instantopposAnyone.length > 0 &&
                          instantopposAnyone !== false ? (
                            <TextField
                              disabled
                              label=""
                              id="outlined-margin-dense"
                              defaultValue={
                                instantopposAnyone &&
                                instantopposAnyone.length > 0 &&
                                instantopposAnyone[0].user &&
                                instantopposAnyone[0].user.full_name
                              }
                              margin="dense"
                              variant="outlined"
                            />
                          ) : (
                            ""
                          )}
                          {OppositionVal === "My Friend list" && (
                            <CreateDebateAutocomplete
                              onChangeAutoComplete={setoppositionList}
                              filteredList={propositionList}
                              populateList={getFriendList}
                              defaultValue={
                                props.BtnName === "Update" ? oppositionList : ""
                              }
                              getOptionLabel={(option) =>
                                option && option.first_name
                                  ? option.first_name + " " + option.last_name
                                  : ""
                              }
                              multiple={false}
                              placeholder={"Search"}
                              classes={classes}
                            />
                          )}
                          {OppositionVal === "Invited" && (
                            <CreateDebateAutocomplete
                              onChangeAutoComplete={setoppositioninvitedList}
                              filteredList={propositioninvitedList}
                              populateList={getInvitedUsers}
                              defaultValue={
                                props.BtnName === "Update"
                                  ? oppositioninvitedList
                                  : ""
                              }
                              getOptionLabel={(option) =>
                                option && option.first_name
                                  ? option.first_name + " " + option.last_name
                                  : ""
                              }
                              multiple={false}
                              placeholder={"Search"}
                              classes={classes}
                            />
                          )}
                          <FormHelperText>{helperTextOppo}</FormHelperText>
                        </FormControl>
                      </div>
                    </>
                  )}
                  <Box display="flex" justifyContent="flex-end">
                    {!props.closeModal && (
                      <Button onClick={props.handleBack}>Back</Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      className={clsx(classes.circleBtn)}
                      type="submit"
                      disabled={submitted}
                    >
                      {props.BtnName}
                    </Button>
                  </Box>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth, currentPackage } }) => {
  return {
    user,
    auth,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(CreateDebate));
