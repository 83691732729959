import UserSignup from "../screens/Users/signIn/UserSignup";
import UserDashboard from "../screens/Users/Dashboard/index";
import AdminSignin from "../screens/Admin/signIn/AdminSignin";
// import AdminDashboard from "../screens/Admin/Dashboard/index";
import AccountProfile from "../screens/Users/AccountProfile/index";
import LiveDebate from "../screens/Users/LiveDebate/index";
import ActiveDebate from "../screens/Users/ActiveDebate/index";
import ActiveDebateList from "../screens/Users/ActiveDebateList/index";
import Recorded from "../screens/Users/Recorded/index";
import My24Videos from "../screens/Users/My24Videos/index";
import MyDebates from "../screens/Users/MyDebates/index";
import CompletedDebate from "../screens/Users/CompletedDebate";
import Broadcast from "../screens/Users/Broadcast/index";
import FriendsProfilePage from "../screens/Users/FriendsProfilePage/index";
import ChangePassword from "../screens/Users/ChangePassword/index";
import DebateProfile from "../screens/Users/DebateProfile/index";
import ShareDebate from "../screens/Users/ShareDebate/index";
import Payment from "../screens/Users/Payment/index";
import Notification from "../screens/Users/Notification/index";
import PaymentFailure from "../screens/Users/Payment/PaymentFailure";
import PaymentSuccess from "../screens/Users/Payment/PaymentSuccess";
import ContactUs from "../screens/Users/ContactUs/index";
import KpNewsTips from "../screens/Users/KpNewsTips/index";
import Vurbs from "../screens/Users/Vurbs/index";
import VurbsDetail from "../screens/Users/VurbsDetail/index";
import ShareVurb from "../screens/Users/ShareVurb/index";
import AdEnquiry from "../screens/Users/AdEnquiry/index";
import NewDebateProfile from "../screens/Users/NewDebateProfile";
import VurbilAds from "../screens/Users/VurbilAds";
// import PrivacyPolicy from "../screens/Users/PrivacyPolicy/index"
// import TermsAndConditions from "../screens/Users/TermsAndConditions/index"
// import AboutUs from "../screens/Users/AboutUs/index"

const routes = [
  {
    path: "/userSignup",
    name: "User Signup",
    exact: false,
    component: UserSignup,
  },
  {
    path: "/userDashboard",
    name: "UserDashboard",
    exact: true,
    component: NewDebateProfile,
  },
  {
    path: "/accountProfile",
    name: "Account Profile",
    exact: true,
    component: AccountProfile,
  },
  {
    path: "/adminSignin",
    name: "Admin Signin",
    exact: false,
    component: AdminSignin,
  },
  // {
  //   path: "/adminDashboard",
  //   name: "Admin Dashboard",
  //   exact: false,
  //   component: AdminDashboard,
  // },
  {
    path: "/liveDebate",
    name: "Live Debate",
    exact: false,
    component: LiveDebate,
  },
  {
    path: "/activeDebate",
    name: "Live Debate",
    exact: false,
    component: ActiveDebate,
  },
  {
    path: "/activeDebateList",
    name: "Live Debate List",
    exact: false,
    component: ActiveDebateList,
  },
  {
    path: "/recorded",
    name: "Recorded",
    exact: false,
    component: Recorded,
  },
  {
    path: "/my24Videos",
    name: "My 24 Videos",
    exact: false,
    component: My24Videos,
  },

  {
    path: "/myDebates",
    name: "My Debates",
    exact: false,
    component: MyDebates,
  },
  {
    path: "/completedDebate",
    name: "Completed Debate",
    exact: false,
    component: CompletedDebate,
  },
  {
    path: "/kpnews",
    name: "KP News",
    exact: false,
    component: Broadcast,
  },
  {
    path: "/kpnewstips",
    name: "KP News Tips",
    exact: false,
    component: KpNewsTips,
  },
  {
    path: "/kpshow",
    name: "KP Show",
    exact: false,
    component: Broadcast,
  },
  {
    path: "/friendsProfilePage/:friendListID?",
    name: "FriendsProfilePage",
    exact: false,
    component: FriendsProfilePage,
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    exact: false,
    component: ChangePassword,
  },
  {
    path: "/debateProfile",
    name: "DebateProfile",
    exact: false,
    component: DebateProfile,
  },
  {
    path: "/sharedebate/:debateToken",
    name: "Share Debate",
    exact: false,
    component: ShareDebate,
  },
  {
    path: "/sharevurb/:vurbToken",
    name: "Share Vurb",
    exact: false,
    component: ShareVurb,
  },
  {
    path: "/payment",
    name: "Payment",
    exact: false,
    component: Payment,
  },
  {
    path: "/notification",
    name: "Notification",
    exact: false,
    component: Notification,
  },
  {
    path: "/successPayment",
    name: "success",
    exact: false,
    component: PaymentSuccess,
  },
  {
    path: "/failurePayment",
    name: "failure",
    exact: false,
    component: PaymentFailure,
  },
  {
    path: "/contactus",
    name: "ContactUs",
    exact: false,
    component: ContactUs,
  },
  {
    path: "/vurbs",
    name: "Vurbs",
    exact: false,
    component: Vurbs,
  },
  {
    path: "/vurbsDetail",
    name: "vurbDetail",
    exact: false,
    component: VurbsDetail,
  },
  {
    path: "/vurbilAds",
    name: "vurbilAds",
    exact: false,
    component: VurbilAds,
  },
  {
    path: '/adEnquiryPage',
    name: 'Ad Inquiry',
    exact: false,
    component: AdEnquiry
  },
  {
    path:'/newProfile',
    name: 'New Profile',
    exact: false,
    component: UserDashboard
  }
  // {
  //   path: '/privacyPolicy',
  //   name: 'PrivacyPolicy',
  //   exact: false,
  //   component: PrivacyPolicy
  // },
  // {
  //   path: '/termsAndConditions',
  //   name: 'TermsAndConditions',
  //   exact: false,
  //   component: TermsAndConditions
  // },
  // {
  //   path: '/aboutUs',
  //   name: 'AboutUs',
  //   exact: false,
  //   component: AboutUs
  // },
];

export default routes;
