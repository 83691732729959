import { Box, Hidden, IconButton, Tooltip } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import squareDelete from "../../../assets/images/actionIcons/squareDelete.svg";
import squareEdit from "../../../assets/images/actionIcons/squareEdit.svg";
import squareExpand from "../../../assets/images/actionIcons/squareExpand.svg";
import squareShare from "../../../assets/images/actionIcons/squareShare.svg";
import CalendarWhite from "../../../assets/images/activeDebate/calendarWhite.svg";
import Facebook from "../../../assets/images/socialIcons/facebook.svg";
import Linkedin from "../../../assets/images/socialIcons/linkedin.svg";
import Twitter from "../../../assets/images/socialIcons/twitter.svg";
import Whatsapp from "../../../assets/images/socialIcons/whatsapp.svg";
import SnackBar from "../../../components/SnackBar";
import { API_Path, redirectTo } from "../../Common/Const/index";
import ConfirmationModal from "../ConfirmationModal";
import CopyLink from "../CopyLink";
import DebateDetail from "../CustomDebateHeaderComponents/DebateDetail";
import DebateDMY from "../CustomDebateHeaderComponents/DebateDMY";
import DebateStatus from "../CustomDebateHeaderComponents/DebateStatus";
import CustomSocialFab from "../CustomSocialFab";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexFlow: "row",
    [theme.breakpoints.down("md")]: {
      flexFlow: "column",
      "& > div": {
        display: "flex",
      },
    },
  },
  dateMonthField: {
    [theme.breakpoints.up("lg")]: {
      borderRight: "solid 1px #cdd1d8",
      marginRight: "24px",
      paddingRight: "24px",
    },
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiSpeedDial-directionDown": {
      padding: 3,
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  addToCalendar: {
    display: "flex",
    border: "1px solid #313236",
    borderRadius: "6px",
    fontSize: "12px",
    color: "#313236",
    paddingRight: 10,
    margin: 3,
    whiteSpace: "nowrap",
    alignItems: "center",
    cursor: "pointer",
    "& img": {
      backgroundColor: "#313236",
      padding: 6,
      // height: "100%",
      marginRight: 10,
    },
  },
}));

const CustomDebateHeaderATC = (props) => {
  const classes = useStyles();
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  const [open, setOpen] = React.useState(false);
  const [redirectToMyDebate, setredirectToMyDebate] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const path = props.shareLink;
  const actions = [
    {
      icon: <img alt="" src={Facebook} />,
      name: "Facebook",
      href:
        "http://www.facebook.com/sharer/sharer.php?s=100&p[url]=" +
        encodeURIComponent(path),
    },
    {
      icon: <img alt="" src={Twitter} />,
      name: "Twitter",
      href: "https://twitter.com/intent/tweet?url=" + encodeURIComponent(path),
    },
    {
      icon: <img alt="" src={Linkedin} />,
      name: "Linkedin",
      href:
        "https://www.linkedin.com/cws/share?url=" + encodeURIComponent(path),
    },
    {
      icon: <img alt="" src={Whatsapp} />,
      name: "Whatsapp",
      href: "whatsapp://send?text=" + path,
    },
  ];

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleEdit = () => {
    setredirectToMyDebate(true);
  };

  const addToCalendar = () => {
    // alert("inside");
    var data = getIcsFile();
    if (data !== null && data !== "") {
      var isIE = !!document.documentMode;
      if (isIE || (!isIE && !!window.StyleMedia)) {
        var blob = new Blob([data], { type: "text/calendar" });
        window.navigator.msSaveOrOpenBlob(blob, "download.ics");
      } else {
        window.open("data:text/calendar;charset=utf8," + escape(data));
      }
    }
  };
  const getIcsFile = () => {
    var icsData =
      "BEGIN:VCALENDAR\r\n" +
      "VERSION:2.0\r\n" +
      "BEGIN:VEVENT\r\n" +
      "DTSTART:" +
      props.debateDetail.debate_planned_start_datetime +
      "\r\n" +
      "DTEND:" +
      props.debateDetail.debate_planned_end_datetime +
      "\r\n" +
      "DESCRIPTION:" +
      props.debateDetail.description +
      "\r\n" +
      "STATUS:CONFIRMED\r\n" +
      "SUMMARY:Vurbil Meeting - " +
      props.debateDetail.title +
      "\r\n" +
      "ORGANIZER;CN=Vurbil:MAILTO:info@vurbil.com" +
      "\r\n" +
      "ORGANIZER:Vurbil" +
      "\r\n" +
      "SUMMARY:" +
      props.debateDetail.description +
      "\r\n" +
      "BEGIN:VALARM\r\n" +
      "TRIGGER:-PT15M\r\n" +
      "ACTION:DISPLAY\r\n" +
      "DESCRIPTION:Reminder\r\n" +
      "END:VALARM\r\n" +
      "END:VEVENT\r\n" +
      "END:VCALENDAR";

    return icsData;
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  // const [time, settime] = React.useState("")
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  //let time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const handleDelete = () => {
    var Deletedebates = API_Path + "debates/delete/" + props.debateDetail._id;
    axios
      .delete(Deletedebates, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setOpen(false);
        setOpenDialog(false);
        history.push(`/activeDebateList`);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const mydebateObj = {
    debateEdit: true,
    debateObj: props.debateDetail,
  };
  const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  const handleCopyLink = () => {
    setSnackbarState({
      open: true,
      message: "Link Copied!",
      severity: "success",
    });
  };
  const actionSectionHeader = (
    <div style={{ display: "flex", flexFlow: "column", width: "300px" }}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="flex-end"
        alignItems={{ xs: "flex-end", sm: "center" }}
      >
        <Box className={classes.addToCalendar} onClick={addToCalendar}>
          <img src={CalendarWhite} alt="" /> Add to Calendar
        </Box>
        <Box display="flex">
          {props.showEditDelete && (
            <>
              <Tooltip title="Delete" placement="top">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={handleClickOpen}
                >
                  <img src={squareDelete} alt="" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" placement="top">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={handleEdit}
                >
                  <img src={squareEdit} alt="" />
                </IconButton>
              </Tooltip>
            </>
          )}
          <CopyLink
            icon={squareExpand}
            value={props.shareLink}
            handleCopyLink={handleCopyLink}
          />
          <CustomSocialFab
            actions={actions}
            direction="down"
            icon={squareShare}
            debateId={props.debateId}
          />
        </Box>
      </Box>
      <DebateStatus
        debateBlocked={props.is_blocked}
        debateStatusList={props.status}
      />
    </div>
  );
  return (
    <>
      <div className={classes.headerContainer}>
        {redirectToMyDebate &&
          redirectTo("/myDebates", { mydebateObj: mydebateObj })}

        <div className={classes.dateMonthField}>
          <DebateDMY listDMY={props} normalDMY={true} />
          <Hidden lgUp>{actionSectionHeader}</Hidden>
        </div>
        <div className={classes.flexJCSB} style={{ width: "100%" }}>
          <DebateDetail debateDetailValues={props} />
          <Hidden mdDown>{actionSectionHeader}</Hidden>
        </div>
        <ConfirmationModal
          openDialog={openDialog}
          modalHeading="Message"
          handleDialogClose={handleDialogClose}
          handleDelete={handleDelete}
          modalMessage="Are you sure to delete the debate?"
          btnCancelName="No"
          btnNoConfirmName="Yes"
          maxWidth="xs"
        />
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(CustomDebateHeaderATC));
