import { CircularProgress } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
export default function VurbsHashTagView() {
    let { hashtext } = useParams()
    const dispatch = useDispatch(), history = useHistory()
    useEffect(() => {
         console.log('useHistory', hashtext)
        dispatch({
            type: "GLOBAL_SEARCH_VAL",
            data: hashtext,
            // data: `#${hashtext}`,
        })
        dispatch({
            type: "AD_HASHTAG_SEARCH",
            //data: hashtext,
             data: `#${hashtext}`,
        })
        history.push('/vurbs')
    }, [hashtext]);
    const divStyle = {
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
    }
    return (
        <div style={divStyle}><CircularProgress /></div>
    )
}
