import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import clsx from "clsx";
import logo from "../../../assets/images/Vurbil - Final-02.png";
import nourl from "../../../assets/images/nourl.png";
import ExpandImage from "../../../assets/images/Expand.png";
import ShareImage from "../../../assets/images/Share.png";
import AndroidHeader from "../../../assets/images/androidHeader.png";
import Retweet from "../../../assets/images/retweet.svg";
import VideoPlayer from "../../Common/VideoPlayer";
// import ShowMoreLessText from "../../Common/ShowMoreLessText";
import ConfirmationModal from "../../Common/ConfirmationModal";
const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 8px 32px 0px #0100212B",
    borderRadius: "12px",
    backgroundColor: "#fff",
    margin: "0 auto",
    position: "relative",
    display: "flex",
    // padding: "24px 16px",
    flexDirection: "column",
    width: "626px",
  },
  android: {
    width: "200px",
    margin: "0 auto",
    border: "5px solid #666155",
    borderRadius: "14px",
    overflow: "hidden",
    "& .empty-1": {
      width: "176px",
      height: "18px",
      background: "white",
      margin: "6px auto",
      borderRadius: "12px",
      boxShadow: "0px 1px 11px 0px #0000002E",
    },
    "& .empty-2": {
      width: "100%",
      height: "32px",
      background: "white",
      margin: "8px 0",
      boxShadow: "0px 1px 11px 0px #0000002E",
    },
    "& .empty-3": {
      width: "100%",
      height: "32px",
      background: "white",
      marginTop: "8px 0",
      boxShadow: "0px 1px 11px 0px #0000002E",
    },
    "& .content-right": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      margin: "10px 0",
      "& .title": { margin: 0, fontSize: "10px", paddingLeft: "4px" },
      "& .right-1": {
        boxShadow: "0px 1px 4px 0px #00000026",
        width: "174.33px",
        minHeight: "182px",
        display: "flex",
        flexFlow: "column",
        position: "relative",
        // overflow: "hidden",
        borderRadius: "8px",
        background: "white",
        margin: "0 auto",
      },
    },
    "& .empty-4": {
      width: "100%",
      height: "56px",
      background: "white",
      marginTop: "8px",
      boxShadow: "0px 1px 11px 0px #0000002E",
    },
    "& .detail-video": {
      padding: "4px 12px",
      borderRadius: "8px",
    },
    "& .details": {
      margin: "18px 12px",
      padding: "2px",
      background: "white",
      borderRadius: "4px",
      position: "relative",
      "& p": {
        position: "absolute",
        top: "10%",
        right: "45%",
      },
    },
  },
  header: {
    boxShadow: "0px 1px 4px 0px #00000026",
    zIndex: "2",
    padding: "8px 26px",
    borderRadius: "14px 14px 0 0",
  },
  footer: {
    height: "42px",
    background: "#F6F7F8",
    borderTop: "1px solid #dddfe5",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px 0px #00000026",
    display: "flex",
    justifyContent: "space-between",
    zIndex: 200,
  },
  closeButton: {
    width: "24px",
    height: "24px",
    background: "#ffc003",
    color: "#fff",
    "&:hover": {
      background: "#ffc003",
    },
    "& svg": {
      width: 20,
      height: 20,
    },
  },
  card: {
    marginTop: "24px",
    borderRadius: "8px",
    "& .MuiCardHeader-root": {
      boxShadow: "0px 1px 4px 0px #00000026",
    },
    "& .MuiCardHeader-title": {
      textAlign: "center",
    },
    "& .MuiCardContent-root": {
      background: "#F6F7F8",
    },
  },
  content: {
    // padding: "16px 14px",
    display: "flex",
    flexDirection: "row",
    background: "#F6F7F8",
    maxHeight: "336px",
    overflow: "hidden",
    "& .content-detail": {
      width: "452px",
      background: "white",
      marginRight: "14px",
      "& .detail-header": {
        display: "flex",
        padding: "10px 12px 0px",
        columnGap: "8px",
      },
      "& .detail-video": {
        padding: "4px 12px",
        borderRadius: "8px",
      },
      "& .detail-footer": {
        display: "flex",
        flexDirection: "column",
        rowGap: "4px",
        padding: "0 12px",
      },
    },
    "& .content-left": {
      boxShadow: "0px 1px 4px 0px #00000026",
      width: "144.33px",
      height: "169.77px",
      background: "#FEFEFE",
      borderRadius: "8px",
      marginTop: "16px",
      marginLeft: "14px",
    },
    "& .content-middle": {
      display: "flex",
      flexDirection: "column",
      padding: "0 14px",
      rowGap: "10px",
      marginTop: "16px",
      "& .middle-1": {
        boxShadow: "0px 1px 4px 0px #00000026",
        width: "281.7px",
        height: "20.95px",
        background: "#FEFEFE",
        borderRadius: "16px",
      },
      "& .middle-2": {
        boxShadow: "0px 1px 4px 0px #00000026",
        width: "281.7px",
        height: "104.75px",
        background: "#FEFEFE",
        borderRadius: "8px",
      },
      "& .middle-3": {
        boxShadow: "0px 1px 4px 0px #00000026",
        width: "281.7px",
        height: "27.93px",
        background: "#FEFEFE",
        borderRadius: "8px",
      },
      "& .middle-4": {
        boxShadow: "0px 1px 4px 0px #00000026",
        width: "281.7px",
        height: "140px",
        background: "#FEFEFE",
        // borderRadius: "8px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      },
    },
    "& .content-right": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      marginTop: "16px",
      "& .title": { margin: 0 },
      "& .right-1": {
        boxShadow: "0px 1px 4px 0px #00000026",
        width: "144.33px",
        display: "flex",
        flexFlow: "column",
        position: "relative",
        // overflow: "hidden",
        borderRadius: "8px",
        background: "white",
      },
      "& .right-2": {
        boxShadow: "0px 1px 4px 0px #00000026",
        width: "144.33px",
        height: "140px",
        background: "#FEFEFE",
        // borderRadius: "8px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      },
    },
  },
  imageCardBody: {
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    backgroundColor: "#fff",
    padding: "8px",
    "& h5": {
      marginBottom: "3px",
    },
    "& p": {
      fontSize: "10px",
      color: "#758194",
    },
    "& .smallButton": {
      padding: "2px",
      fontSize: "6px",
      lineHeight: 1,
      minWidth: "auto",
      borderRadius: "4px",
      "& + &.smallButton": {
        marginLeft: "1px",
      },
    },
    "& .seperatorImageCard": {
      borderBottom: "1px solid #cdd1d8",
      margin: "4px 0",
    },
    "& .iconBtn": {
      padding: "3px",
      minWidth: "auto",
    },
    "& .MuiSpeedDial-directionUp": {
      zIndex: 8,
    },
    "& .icons": {
      "& img": {
        width: "14px",
        height: "14px",
        padding: "2px",
      },
      "& .MuiSvgIcon-root": {
        padding: "2px",
      },
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnwrap: {
    alignItems: "center",
    "& div:first-child": {
      // overflow: "hidden",
      // width: "calc(100% - 45px)",
      // margin: "0 -5px",
      "& button": {
        margin: "0 1px",
      },
    },
    "& .MuiSpeedDial-actions": {
      "& .MuiFab-sizeSmall": {
        width: 30,
        height: 30,
        minHeight: 30,
        margin: 4,
        "& img": {
          width: 14,
        },
      },
    },
  },
  userRating: {
    display: "flex",
    fontSize: "8px",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  activeStatus: {
    position: "absolute",
    right: "0px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "12px",
    },
  },
}));

const AdPreview = ({
  modalHeading,
  openDialog,
  handleDialogClose,
  formData,
  url,
  tags,
  modalMessage,
  maxWidth,
  ...props
}) => {
  const classes = useStyles();
  const vurbeAds =[
    "vurbs_page_web",
    "debate_page_web",
    "other_page_web"
  ];
  const MobilevurbeAds =[
    "debate_page_mobile",
    "vurbs_page_mobile"
]
  const isSubset = vurbeAds.some(element => formData.specific_area_ad_placement.includes(element));
  const mobileIsSubset = MobilevurbeAds.some(element => formData.specific_area_ad_placement.includes(element));
  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  };
  // let isVideo = /\.(mp4|avi|mov)$/i.test(url);
  let isVideo = formData.type_of_ad_file.toLowerCase() === "video";

  // let isVideo = formData.type_of_ad_file.toLowerCase() === "video";
  // console.log(formData, tags);
  return (
    <Dialog
      open={openDialog}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleDialogClose(event);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth={true}
      disableEscapeKeyDown
      onKeyDown={stopPropagationForTab}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h2">Preview</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleDialogClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {/* <DialogHeaderTitle onClose={handleDialogClose} /> */}
      <DialogContent>
        <DialogContentText component={"div"}>
        { isSubset &&  <Card variant="outlined" className={classes.card}>
            <CardHeader title="Web right side Ad Preview" />
            <CardContent style={{ overflowX: "scroll" }}>
              <Paper className={classes.paper}>
                <Box className={classes.header}>
                  <img src={logo} alt="logo" width="33.66px" height="27.06px" />
                </Box>
                <Box className={classes.content}>
                  <Box className="content-left" />
                  <div className="content-middle">
                    <div className="middle-1"></div>
                    <div className="middle-2"></div>
                    <div className="middle-3"></div>
                    <div className="middle-4"></div>
                  </div>
                  <div className="content-right">
                    <p className="title">Advertisement</p>
                    <div className="right-1">
                      {url ? (
                        !isVideo ? (
                          <img
                            alt=""
                            src={url}
                            style={{ maxWidth: "100%", cursor: "pointer" }}
                            // onClick={handleRedirect.bind(this, advData)}
                          />
                        ) : (
                          <VideoPlayer
                            videoURL={url}
                            videoThumbnail={false}
                            playing={true}
                            muted={true}
                            loop={true}
                            hideWaterMark={true}
                            controls={false}
                          />
                        )
                      ) : (
                        <img src={nourl} alt="no_url" />
                      )}

                      <div className={classes.imageCardBody}>
                        <h5 className="m-0 mb-1" style={{ fontSize: "12px" }}>
                          {formData.ad_title ? formData.ad_title : "Title..."}
                        </h5>
                        <div
                          className={clsx(classes.flexJCSB, classes.btnwrap)}
                        >
                          <div>
                            {tags.length > 0 ? (
                              <>
                                {tags &&
                                  tags.map((classifi, index) => {
                                    return (
                                      <Button
                                        key={index}
                                        variant="outlined"
                                        className="smallButton"
                                      >
                                        {classifi.name}
                                      </Button>
                                    );
                                  })}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <Box
                            display="flex"
                            position="relative"
                            className="icons"
                          >
                            <img src={ExpandImage} alt="copy" />
                            <img src={ShareImage} alt="share" />
                            <ReportProblemOutlinedIcon
                              style={{
                                fill: "rgba(151, 160, 174, 1)",
                                fontSize: "14px",
                              }}
                            />
                          </Box>
                        </div>
                        <div className="seperatorImageCard"></div>
                        <div>
                          {/* <ShowMoreLessText
                            description={
                              formData && formData.description
                                ? formData.description
                                : "Description...."
                            }
                          /> */}
                          <p>
                            {formData && formData.description
                              ? formData.description
                              : "Description...."}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: "12px",
                            // justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "8px",
                            }}
                          >
                            <h6
                              style={{ margin: "0" }}
                              className={classes.userRating}
                            >
                              <StarBorderIcon
                                style={{
                                  margin: "0 4px 0 0",
                                  fontSize: "12px",
                                }}
                              />
                              0 Star
                            </h6>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // marginRight: "18px",
                            }}
                          >
                            <img
                              alt=""
                              width="12px"
                              height="12px"
                              src={Retweet}
                            />{" "}
                            <h6
                              style={{ margin: "0 0 0 4px", fontSize: "8px" }}
                            >
                              0 Share
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right-2"></div>
                  </div>
                </Box>
                <Box className={classes.footer} />
              </Paper>
            </CardContent>
          </Card> }
          { formData.specific_area_ad_placement.includes("debate_pre_roll_web") &&
          <Card variant="outlined" className={classes.card}>
            <CardHeader title="Web Debate Ad Preview" />
            <CardContent style={{ overflowX: "scroll" }}>
              <Paper className={classes.paper}>
                <Box className={classes.header}>
                  <img src={logo} alt="logo" width="33.66px" height="27.06px" />
                </Box>
                <Box className={classes.content}>
                  <div className="content-detail">
                    <div className="detail-header">
                      <div style={{ position: "relative" }}>
                        <Skeleton
                          variant="circle"
                          width={40}
                          height={40}
                          animation={false}
                        />
                        <span
                          className={classes.activeStatus}
                          title="Verified User/Public Figure"
                        >
                          <VerifiedUserRoundedIcon />
                        </span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Skeleton
                            variant="text"
                            width={200}
                            height={18}
                            animation={false}
                          />

                          <div style={{ display: "flex", columnGap: "1px" }}>
                            <Skeleton
                              variant="text"
                              width={10}
                              height={18}
                              animation={false}
                            />
                            <Skeleton
                              variant="text"
                              width={10}
                              height={18}
                              animation={false}
                            />
                            <Skeleton
                              variant="text"
                              width={10}
                              height={18}
                              animation={false}
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex", columnGap: "4px" }}>
                          <Skeleton
                            variant="text"
                            width={18}
                            height={10}
                            animation={false}
                            style={{ borderRadius: "2px" }}
                          />
                          <Skeleton
                            variant="text"
                            width={1}
                            height={10}
                            animation={false}
                            style={{ borderRadius: "2px" }}
                          />
                          <Skeleton
                            variant="text"
                            width={58}
                            height={10}
                            animation={false}
                            style={{ borderRadius: "2px" }}
                          />
                          <Skeleton
                            variant="text"
                            width={28}
                            height={10}
                            animation={false}
                            style={{ borderRadius: "2px" }}
                          />
                          <Skeleton
                            variant="text"
                            width={24}
                            height={10}
                            animation={false}
                            style={{ borderRadius: "2px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Skeleton
                            variant="text"
                            width={34}
                            height={24}
                            animation={false}
                          />
                          <div style={{ display: "flex", columnGap: "4px" }}>
                            <Skeleton
                              variant="text"
                              width={50}
                              height={24}
                              animation={false}
                            />
                            <Skeleton
                              variant="text"
                              width={36}
                              height={24}
                              animation={false}
                              style={{ borderRadius: "12px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail-video">
                      {url ? (
                        !isVideo ? (
                          <img
                            alt=""
                            src={url}
                            style={{
                              maxWidth: "100%",
                              height: "100%",
                              borderRadius: "4px",
                            }}
                            // onClick={handleRedirect.bind(this, advData)}
                          />
                        ) : (
                          <VideoPlayer
                            videoURL={url}
                            videoThumbnail={false}
                            playing={true}
                            muted={true}
                            loop={true}
                            hideWaterMark={true}
                            controls={false}
                          />
                        )
                      ) : (
                        <img
                          src={nourl}
                          alt="no_url"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "4px",
                          }}
                        />
                      )}
                    </div>
                    <div className="detail-footer">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "4px",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width={14}
                          height={24}
                          animation={false}
                        />
                        <span>0</span>
                        <Skeleton
                          variant="text"
                          width={14}
                          height={24}
                          animation={false}
                        />
                        <span>0</span>
                        <Skeleton
                          variant="text"
                          width={14}
                          height={24}
                          animation={false}
                        />
                        <span>0</span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="content-right">
                    <p className="title">Advertisement</p>
                    <div
                      className="right-1"
                      style={{
                        minHeight: "182px",
                      }}
                    ></div>
                    <div className="right-2"></div>
                  </div>
                </Box>
                <Box className={classes.footer} />
              </Paper>
            </CardContent>
          </Card>}
          {mobileIsSubset && <Card variant="outlined" className={classes.card}>
            <CardHeader title="App Feed Ad Preview" />
            <CardContent>
              <Box className={classes.android}>
                <img
                  src={AndroidHeader}
                  style={{ width: "100%", height: "100%", marginTop: "-2px" }}
                  alt="header"
                />
                <div className="empty-1"></div>
                <div className="empty-2"></div>
                <div className="empty-3"></div>
                <div className="content-right">
                  <p className="title">Advertisement</p>
                  <div className="right-1">
                    {url ? (
                      !isVideo ? (
                        <img
                          alt=""
                          src={url}
                          style={{ maxWidth: "100%", cursor: "pointer" }}
                          // onClick={handleRedirect.bind(this, advData)}
                        />
                      ) : (
                        <VideoPlayer
                          videoURL={url}
                          videoThumbnail={false}
                          playing={true}
                          muted={true}
                          loop={true}
                          hideWaterMark={true}
                          controls={false}
                        />
                      )
                    ) : (
                      <img src={nourl} alt="no_url" />
                    )}

                    <div className={classes.imageCardBody}>
                      <h5 className="m-0 mb-1" style={{ fontSize: "12px" }}>
                        {formData.ad_title ? formData.ad_title : "Title..."}
                      </h5>
                      <div className={clsx(classes.flexJCSB, classes.btnwrap)}>
                        <div>
                          {tags.length > 0 ? (
                            <>
                              {tags &&
                                tags.map((classifi, index) => {
                                  return (
                                    <Button
                                      key={index}
                                      variant="outlined"
                                      className="smallButton"
                                    >
                                      {classifi.name}
                                    </Button>
                                  );
                                })}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <Box
                          display="flex"
                          position="relative"
                          className="icons"
                        >
                          <img src={ExpandImage} alt="copy" />
                          <img src={ShareImage} alt="share" />
                          <ReportProblemOutlinedIcon
                            style={{
                              fill: "rgba(151, 160, 174, 1)",
                              fontSize: "14px",
                            }}
                          />
                        </Box>
                      </div>
                      <div className="seperatorImageCard"></div>
                      <div>
                        {/* <ShowMoreLessText
                          description={
                            formData && formData.description
                              ? formData.description
                              : "Description...."
                          }
                        /> */}
                        <p>
                          {formData && formData.description
                            ? formData.description
                            : "Description...."}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: "12px",
                          // justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "8px",
                          }}
                        >
                          <h6
                            style={{ margin: "0" }}
                            className={classes.userRating}
                          >
                            <StarBorderIcon
                              style={{
                                margin: "0 4px 0 0",
                                fontSize: "12px",
                              }}
                            />
                            0 Star
                          </h6>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginRight: "18px",
                          }}
                        >
                          <img
                            alt=""
                            width="12px"
                            height="12px"
                            src={Retweet}
                          />{" "}
                          <h6 style={{ margin: "0 0 0 4px", fontSize: "8px" }}>
                            0 Share
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="empty-4"></div>
              </Box>
            </CardContent>
          </Card>}
          {formData.specific_area_ad_placement.includes("debate_pre_roll_mobile") && <Card variant="outlined" className={classes.card}>
            <CardHeader title="App Debate Ad Preview" />
            <CardContent>
              <Box className={classes.android}>
                <img
                  src={AndroidHeader}
                  style={{ width: "100%", height: "100%", marginTop: "-2px" }}
                  alt="header"
                />
                <div
                  style={{
                    display: "flex",
                    columnGap: "5px",
                    padding: "10px 17px 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "4px",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      width={22}
                      height={10}
                      animation={false}
                      style={{ borderRadius: "8px", transform: "none" }}
                    />
                    <Skeleton
                      variant="text"
                      width={22}
                      height={19}
                      animation={false}
                      style={{ transform: "none", borderRadius: "0" }}
                    />
                  </div>
                  <Skeleton
                    variant="text"
                    width={1}
                    height={36}
                    animation={false}
                    style={{ borderRadius: "2px", transform: "none" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "6px",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      width={64}
                      height={6}
                      animation={false}
                      style={{ transform: "none" }}
                    />
                    <div style={{ display: "flex", columnGap: "5px" }}>
                      <Skeleton
                        variant="text"
                        width={29}
                        height={6}
                        animation={false}
                        style={{ transform: "none" }}
                      />
                      <Skeleton
                        variant="text"
                        width={1}
                        height={6}
                        animation={false}
                        style={{ transform: "none" }}
                      />
                      <Skeleton
                        variant="text"
                        width={72}
                        height={6}
                        animation={false}
                        style={{ transform: "none" }}
                      />
                    </div>
                    <div style={{ display: "flex", columnGap: "4px" }}>
                      <Skeleton
                        variant="text"
                        width={41}
                        height={6}
                        animation={false}
                        style={{ transform: "none", borderRadius: "16px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={37}
                        height={6}
                        animation={false}
                        style={{ transform: "none" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    columnGap: "4px",
                    justifyContent: "center",
                    padding: "6px 0",
                  }}
                >
                  <Skeleton
                    variant="text"
                    width={34}
                    height={10}
                    animation={false}
                    style={{ transform: "none", borderRadius: "1px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={58}
                    height={10}
                    animation={false}
                    style={{ transform: "none", borderRadius: "1px" }}
                  />
                </div>
                <div className="detail-video">
                  {url ? (
                    !isVideo ? (
                      <img
                        alt=""
                        src={url}
                        style={{
                          maxWidth: "100%",
                          height: "100%",
                          borderRadius: "4px",
                        }}
                        // onClick={handleRedirect.bind(this, advData)}
                      />
                    ) : (
                      <VideoPlayer
                        videoURL={url}
                        videoThumbnail={false}
                        playing={true}
                        muted={true}
                        loop={true}
                        hideWaterMark={true}
                        controls={false}
                      />
                    )
                  ) : (
                    <img
                      src={nourl}
                      alt="no_url"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                    padding: "6px 12px",
                  }}
                >
                  <Skeleton
                    variant="circle"
                    width={21}
                    height={21}
                    animation={false}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "6px",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      width={23}
                      height={5}
                      animation={false}
                      style={{ transform: "none", borderRadius: "1px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={27}
                      height={5}
                      animation={false}
                      style={{ transform: "none", borderRadius: "1px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      columnGap: "6px",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      width={12}
                      height={13}
                      animation={false}
                      style={{ transform: "none", borderRadius: "1px" }}
                    />
                    <span>0</span>
                    <Skeleton
                      variant="text"
                      width={12}
                      height={13}
                      animation={false}
                      style={{ transform: "none", borderRadius: "1px" }}
                    />
                    <span>0</span>
                    <Skeleton
                      variant="text"
                      width={12}
                      height={13}
                      animation={false}
                      style={{ transform: "none", borderRadius: "1px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={12}
                      height={13}
                      animation={false}
                      style={{ transform: "none", borderRadius: "1px" }}
                    />
                    <span>0</span>
                  </div>
                </div>
                <div className="details">
                  <div style={{ display: "flex", columnGap: "2px" }}>
                    <div style={{ width: "80px" }}>
                      <div
                        style={{ position: "relative", margin: "10px auto" }}
                      >
                        <Skeleton
                          variant="circle"
                          width={21}
                          height={21}
                          animation={false}
                          style={{ margin: "0 auto" }}
                        />
                        <span
                          className={classes.activeStatus}
                          style={{ right: "26px", top: "-4px" }}
                          title="Verified User/Public Figure"
                        >
                          <VerifiedUserRoundedIcon
                            style={{ fontSize: "10px" }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width={55}
                          height={5}
                          animation={false}
                          style={{ transform: "none", borderRadius: "1px" }}
                        />
                        <Skeleton
                          variant="text"
                          width={27}
                          height={5}
                          animation={false}
                          style={{ transform: "none", borderRadius: "1px" }}
                        />
                        <Skeleton
                          variant="text"
                          width={80}
                          height={17}
                          animation={false}
                          style={{ transform: "none", borderRadius: "1px" }}
                        />
                        <Skeleton
                          variant="text"
                          width={37}
                          height={9}
                          animation={false}
                          style={{ transform: "none", borderRadius: "1px" }}
                        />
                      </div>
                    </div>
                    <div style={{ width: "80px" }}>
                      <div
                        style={{ position: "relative", margin: "10px auto" }}
                      >
                        <Skeleton
                          variant="circle"
                          width={21}
                          height={21}
                          animation={false}
                          style={{ margin: "0 auto" }}
                        />
                        <span
                          className={classes.activeStatus}
                          style={{ right: "26px", top: "-4px" }}
                          title="Verified User/Public Figure"
                        >
                          <VerifiedUserRoundedIcon
                            style={{ fontSize: "10px" }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width={55}
                          height={5}
                          animation={false}
                          style={{ transform: "none", borderRadius: "1px" }}
                        />
                        <Skeleton
                          variant="text"
                          width={27}
                          height={5}
                          animation={false}
                          style={{ transform: "none", borderRadius: "1px" }}
                        />
                        <Skeleton
                          variant="text"
                          width={80}
                          height={17}
                          animation={false}
                          style={{ transform: "none", borderRadius: "1px" }}
                        />
                        <Skeleton
                          variant="text"
                          width={37}
                          height={9}
                          animation={false}
                          style={{ transform: "none", borderRadius: "1px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <Skeleton
                    variant="text"
                    width={124}
                    height={4}
                    animation={false}
                    style={{
                      transform: "none",
                      borderRadius: "1px",
                      margin: "12px auto",
                    }}
                  />
                  <p>VS</p>
                </div>
              </Box>
            </CardContent>
          </Card>}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default AdPreview;
