import React from 'react'
import moment from "moment";
import makeStyles from '@material-ui/core/styles/makeStyles';
import CustomImage from '../CustomImage';

const useStyles = makeStyles((theme) => ({
    dmyField: {
        '& span': {
            borderRadius: '20px',
            color: '#313236',
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: '600',
            padding: '0 10px',
            display: 'block',
            textAlign: 'center',
            "&.month": {
                backgroundColor: '#ffc003',
            },
            "&.year": {
                top: '-10px',
                position: 'relative',
            }
        },
        '& h1': {
            lineHeight: '46px',
            margin: '0',
            textAlign: 'center'
        }
    },
    imageWithDate: {
        marginRight: '24px',
        "& .imageDebaters": {
            width: 90,
            height: 90,
            borderRadius: '50%',
            overflow: 'hidden',
            backgroundColor: '#333',
            margin: '0 auto 7px',
            "& img": {
                maxWidth: '100%',
                maxHeight: '100%',
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            },
            "& svg": {
                fill: '#fff',
                opacity: '1!important',
            }
        },
        "& h5": {
            margin: '0',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            "& svg": {
                verticalAlign: 'text-top',
                width: '18px',
                height: '18px',
            }
        }
    },
}))
const DebateDMY = (props) => {
    const classes = useStyles();
    return (
        <>
            {!!props.normalDMY ? props.normalDMY === true ?
                <div className={classes.dmyField}>
                    <span className="month">
                        {props.broadCastDate ?
                            props.listDMY && props.listDMY.start_date !== null ? moment(props.listDMY.start_date).format("MMM") : "-"
                            :
                            props.listDMY && props.listDMY.debateDetail && props.listDMY.debateDetail.debate_planned_start_datetime !== null ? moment(props.listDMY.debateDetail.debate_planned_start_datetime).format("MMM") : "-"
                        }
                    </span>
                    <h1>
                        {props.broadCastDate ?
                            props.listDMY && props.listDMY.start_date !== null ? moment(props.listDMY.start_date).format("D") : "-"
                            :
                            props.listDMY && props.listDMY.debateDetail && props.listDMY.debateDetail.debate_planned_start_datetime !== null ? moment(props.listDMY.debateDetail.debate_planned_start_datetime).format("D") : "-"
                        }
                    </h1>
                    <span className="year">
                        {props.broadCastDate ?
                            props.listDMY && props.listDMY.start_date !== null ? moment(props.listDMY.start_date).format("YYYY") : "-"
                            :
                            props.listDMY && props.listDMY.debateDetail && props.listDMY.debateDetail.debate_planned_start_datetime !== null ? moment(props.listDMY.debateDetail.debate_planned_start_datetime).format("YYYY") : "-"
                        }
                    </span>
                </div>
                :
                <div>
                    <span>
                        {!!props.listDMY ? moment(props.listDMY).format("MMM D, YYYY") : "-"}
                    </span>
                    <span> at</span>
                    <span>{!!props.listDMY ? moment(props.listDMY).format(" h:mm a") : "-"}</span>
                </div>
                :
                <div className={classes.imageWithDate}>
                    <div className="imageDebaters">
                        {/* <img alt="" src={props.listDMY && props.listDMY.image} /> */}
                        <CustomImage userImage={props.listDMY && props.listDMY.image} size="90px" />
                    </div>
                    {/* <h5><CalendarTodayOutlinedIcon /> {props.listDMY && props.listDMY.date}</h5> */}
                </div>
            }
        </>
    )
}

export default DebateDMY
