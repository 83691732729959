import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useState } from "react";
import AppBody from "./AppBody";
import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";


const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexFlow: "column",
  },

  navBar: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    overflow:"auto"
  },
}));

const Layout = () => {
  const classes = useStyles();
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const [side, setSideBarOpened] = useState(false);
  return (
    <div className={classes.container}>
      <Fragment>
        <AppHeader leftDrawerOpen={leftDrawerOpen} setLeftDrawerOpen={setLeftDrawerOpen} setSideBarOpened={setSideBarOpened} />
        <Box className={classes.navBar}>
          <AppBody leftDrawerOpen={leftDrawerOpen} />
        </Box>
        <AppFooter />
      </Fragment>
    </div>
  );
};

export default Layout;
