import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  adsContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    "& .adsSection": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#000",
      zIndex: 1,
      display: "none",
      "&.d-block": {
        display: "block",
      },
      "& button": {
        position: "absolute",
        bottom: "15%",
        right: 0,
        zIndex: 2,
        padding: "7px 25px",
        border: 0,
        backgroundColor: "#ccc",
      },
    },
  },
}));

const AudienceAdInjection = (props) => {
  const classes = useStyles();
  const [playing, SetPlaying] = useState(false);
  //const [totalSeconds, SetTotalSeconds] = useState(false);
  const [SetIsAdPlayed] = useState(false);
  const [showAdSkip, SetShowAdSkip] = useState(false);
  const [videoURL, SetVideoURL] = useState(null);
  const myVideo = useRef();
  useEffect(() => {
    fetchAdsList(props.adsList);
  }, [props.adsList]);

  useEffect(() => {
    SetVideoURL(props.videoUrl);
  }, [props.videoUrl]);

  const fetchAdsList = (adsList) => {
    myVideo.current.src = adsList.url;
    myVideo.current.type = "video/mp4";
  };

  // const handlePlay = () => {
  //     // mainPlayer.playing=true;
  //     SetPlaying(true);
  // }

  // const playPause=()=>{
  //     if (myVideo&&myVideo.paused) {
  //         SetIsAdPlayed(true);
  //         myVideo.play();
  //     }
  //     else{
  //         myVideo.pause();
  //     }
  // }

  // const handleDuration = (duration) => {
  //     // console.log("handleDuration",duration);
  //     // SetTotalSeconds(duration);
  // }

  const onAdProgress = () => {
    if (myVideo.current.currentTime >= 5) {
      SetShowAdSkip(true);
    }
  };
  // const handleProgress = (state) => {
  //     // console.log("handle Progress==",state);
  // }
  // const handleProgress = (state) => {
  //     // var halfinterval = parseInt(totalSeconds / 2);
  //     var halfinterval = '';
  //     if (props.adsList.ad_show_in === 'Start') {
  //         halfinterval = parseInt(1);
  //     } else if (props.adsList.ad_show_in === 'Middle') {
  //         halfinterval = parseInt(totalSeconds / 2);
  //     } else if (props.adsList.ad_show_in === 'End') {
  //         halfinterval = parseInt(totalSeconds - 1);
  //     }
  //     if (halfinterval <= state.playedSeconds && !isAdPlayed) {
  //         SetPlaying(false);
  //         myVideo.current.play();
  //     }
  // }
  const advertisementEnded = () => {
    console.log("advertisement ended");
    myVideo.current.pause();
    SetIsAdPlayed(true);
    SetPlaying(true);
  };
  const skipAdvertiseMent = () => {
    myVideo.current.pause();
    SetIsAdPlayed(true);
    SetPlaying(true);
  };

  return (
    <div className={classes.adsContainer}>
      {videoURL ? (
        <React.Fragment>
          <ReactPlayer
            className="react-player"
            url={videoURL ? videoURL : ""}
            playing={playing}
            width="100%"
            // height="100%"
            style={{ maxWidth: "100%" }}
            controls
            // light={true}
            config={{
              file: {
                forceHLS: false,
              },
            }}
            // onStart={handlePlay}
            // onPlay={handlePlay}
            // onDuration={handleDuration}
            // onEnded={props.endedaudienceview}
            // onProgress={handleProgress}
            // onBufferEnd={()=>{
            //     console.log("This is onBufferEnd function");
            // }}
            onError={(e, f, g, h) => {
              if (f.type === "networkError") {
                props.endedaudienceview();
              }
            }}
            // onBuffer={(e)=>{
            //     console.log("This is onBuffer function",e);
            // }}

            // onDuration={handleDuration}
          />
        </React.Fragment>
      ) : (
        ""
      )}
      <div
        className={
          // (!videoURL&&props.adsList&&props.adsList.url) ||
          props.adsList &&
          props.adsList.url &&
          myVideo.current &&
          myVideo.current.paused === false
            ? "d-block adsSection"
            : "adsSection"
        }
        // style={{ display: (myVideo.current && myVideo.current.paused===false ? 'block':'none'), position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: '#000', zIndex: 1 }}
      >
        <button
          onClick={skipAdvertiseMent}
          style={{
            display: videoURL && showAdSkip ? "block" : "none",
            position: "absolute",
            bottom: 15,
            right: 15,
            zIndex: 2,
          }}
        >
          Skip
        </button>
        <video
          id="advplayer"
          onEnded={advertisementEnded}
          ref={myVideo}
          width="100%"
          height="100%"
          // onProgress={onAdProgress}
          onTimeUpdate={onAdProgress}
          loop={!videoURL ? true : false}
          autoPlay={!videoURL ? true : false}
        />
      </div>
    </div>
  );
};

export default AudienceAdInjection;
