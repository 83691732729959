import { Box, Button, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import loginBg from "../../../assets/images/loginBg.png";
import logoImage from "../../../assets/images/Vurbil - Final-02.png";
import mobileIllution from "../../../assets/images/mobileglow.png";
import SnackBar from "../../../components/SnackBar";
import ConfirmationModal from "../../Common/ConfirmationModal";
import { API_Path, Make_Base_Auth } from "../../Common/Const/index";
import FormikInput from "../../Common/FormikComponents/FormikInput";

const useStyles = makeStyles((theme) => ({
  loginBg: {
    padding: "30px 100px 30px 30px",
    background: "url(" + loginBg + ") fixed, #f6f7f9",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    height: "100%",
    // minHeight: '100vh',
    [theme.breakpoints.down("md")]: {
      padding: "15px",
    },
    // backgroundColor: '#f6f7f9',
    // [theme.breakpoints.up('md')]: {
    //   padding: '30px 100px 30px 30px',
    //   backgroundImage: "url(" + loginBg + ")",
    //   backgroundSize: 'cover',
    // },
    // [theme.breakpoints.down('md')]: {
    //   display: 'flex',
    //   flexFlow: 'column',
    //   justifyContent: 'center',
    // }
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
    },
  },
  mobileGlow: {
    position: "relative",
    "& .logo":{
      maxWidth:'10%',
      position:"absolute",
      top: "0",
      left: "30%",
    },
    "& .illusion": {
      position: "absolute",
      width:'50%',
      top: "64px",
      left: "10%",
    },
  },
  joinChat: {
    maxWidth: "352px",
    boxSizing: "border-box",
    // [theme.breakpoints.up('md')]: {
    //   padding: '29px 45px 39px 42px',
    //   borderRadius: '20px',
    //   boxShadow: '1px 3px 12px 0 rgba(0, 0, 0, 0.06)',
    //   backgroundColor: 'rgba(255, 255, 255, .9)',
    //   margin: '0 0 0 auto',
    // },
    padding: "29px 45px 39px 42px",
    borderRadius: "20px",
    boxShadow: "1px 3px 12px 0 rgba(0, 0, 0, 0.06)",
    backgroundColor: "rgba(255, 255, 255, .5)",
    margin: "0 0 0 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto 0",
    },
    "& h1, & h2": {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    "& .logo":{
      maxWidth: '50%',
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
    maxHeight: "inherit",
    // maxHeight: theme.spacing(6),
  },
  loginButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.75),
  },
  circleBtn: {
    fontSize: "14px",
    borderRadius: 30,
    fontWeight: "bold",
    color: "#fff",
  },
  appLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h4": {
      margin: "-12px 0 0 0",
      fontWeight: "500",
      letterSpacing: "1px",
      lineHeight: 1,
      textTransform: "uppercase",
    },
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [enableEdit, setEnableEdit] = useState(false);
  const [submitted, setsubmitted] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [redirectLoginPopup, setRedirectLoginPopup] = useState(false);
  const [tokenExpiredPopup, setTokenExpiredPopup] = useState(false);
  // const [applicationLogo, setApplicationLogo] = useState("");
  // const handleClickOpen = () => {
  //     setOpenDialog(true);
  // };
  // const handleDialogClose = () => {
  //     setOpenDialog(false);
  // };
  const initState = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = yup.object({
    // password: yup.string().required('Please Enter Password').min(6, 'Password is too short').max(25, 'Password is too long'),
    // confirmPassword: yup.string().required('Please Enter Password').when("password", {
    //     is: val => (val && val.length > 0 ? true : false),
    //     then: yup.string().oneOf(
    //         [yup.ref("password")],
    //         "Password does not match"
    //     )
    // }),
    password: yup
      .string()
      .required("New Password is Required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must contain 6 character, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character"
      ),
    confirmPassword: yup
      .string()
      .required("Confirm Password is Required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Password does not match"),
      }),
  });
  useEffect(() => {
    // GetApplicationLogo();
    const checkValidateToken = () => {
      // alert(props.match.params.resetToken);
      var ResetpasswordAPI = API_Path + "api/noauth/validateResetToken";
      var inputObj = {
        inputObject: {
          resetToken: props.match.params.resetToken,
        },
      };
      axios
        .post(ResetpasswordAPI, inputObj, {
          headers: {
            Authorization: Make_Base_Auth(),
          },
        })
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            setEnableEdit(false);
          } else if (response.data.message === "Token Expired") {
            setTokenExpiredPopup(true);
            setOpenDialog(true);
          } else {
            history.push("/signin");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    checkValidateToken();
  }, []);
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  const submitForm = async (state) => {
    var ForgetpasswordAPI = API_Path + "api/noauth/resetPassword";
    var inputObj = {
      inputObject: {
        resetToken: props.match.params.resetToken,
        password: state.password,
      },
    };
    axios
      .post(ForgetpasswordAPI, inputObj, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "success",
          });
          setsubmitted(true);
          setOpenDialog(true);
          setTimeout(() => {
            setRedirectLoginPopup(true);
          }, 2000);
        } else {
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleForgetPassword = () => {
    history.push("/forgetpassword");
  };
  const handleLogin = () => {
    history.push("/signin");
  };
  // const GetApplicationLogo = () => {
  //     axios
  //         .get(API_Path + 'api/auth/get_application_logo', {
  //             headers: {
  //                 'Authorization': Make_Base_Auth()
  //             }
  //         })
  //         .then((response) => {
  //             if (response !== null && response.data !== null && response.data.result !== null) {
  //                 setApplicationLogo(response.data.result)
  //             }
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }
  const logoSection = <img className="logo" alt="Logo" src={logoImage} />;
  return (
    <div className={classes.loginBg}>
      {/* <Hidden smDown>
        <div className={classes.flexJCSB}>
          {logoSection} */}
          {/* <Box className={classes.appLogo}>
                    {applicationLogo !== null && applicationLogo !== undefined ?
                    <>

                        {applicationLogo.logo !== null && applicationLogo.logo !== undefined ?
                        <img alt="Logo" src={applicationLogo.logo && applicationLogo.logo.url} style={{height: '54px', objectFit: 'contain', marginLeft: '-14px'}} />
                        :
                        <img alt="Logo" src={beeLogo} style={{height: '40px', objectFit: 'contain', marginLeft: '-14px'}} />
                        }
                        <h4>
                        {applicationLogo.application_name !== null && applicationLogo.application_name !== undefined ?
                            applicationLogo.application_name
                            :
                            <img alt="Logo" src={textLogo} style={{width: '140px', height: '15px', objectFit: 'contain'}} />
                        }
                        </h4>
                    </>
                    :
                    <img alt="Logo" src={logoImage} />
                    }
                </Box> */}
        {/* </div>
      </Hidden> */}
      <Hidden smDown>
          <div className={classes.mobileGlow}>
            {logoSection}
            <img className="illusion" src={mobileIllution} alt="mobile-illution" />
          </div>
        </Hidden>
      <div className={classes.joinChat}>
        <Hidden mdUp>
          <Box textAlign="center" mb={2}>
            {logoSection}
          </Box>
        </Hidden>
        <h2 className="font-weight-normal m-0" style={{ marginBottom: "16px" }}>
          Reset Password
        </h2>
        <Formik
          initialValues={{ ...initState }}
          onSubmit={submitForm}
          validationSchema={validationSchema}
          validateOnBlur={true}
        >
          {({ isSubmitting }) => {
            return (
              <Form autoComplete="off">
                <Field
                  disabled={enableEdit}
                  name="password"
                  label="New Password"
                  placeholder=""
                  type="password"
                  as={FormikInput}
                  className={clsx(classes.textField)}
                />
                <Field
                  disabled={enableEdit}
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder=""
                  type="password"
                  as={FormikInput}
                  className={clsx(classes.textField)}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className={clsx(classes.loginButton, classes.circleBtn)}
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
                <Box textAlign="center">
                  <Button
                    component={Link}
                    to="/"
                    style={{ textTransform: "none" }}
                    disabled={submitted}
                  >
                    Back To Login
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />

      {tokenExpiredPopup && (
        <ConfirmationModal
          openDialog={openDialog}
          modalHeading="Message"
          handleDelete={handleForgetPassword}
          modalMessage="Forgot password link has expired"
          btnNoConfirmName="Ok"
          maxWidth="xs"
        />
      )}
      {redirectLoginPopup && (
        <ConfirmationModal
          openDialog={openDialog}
          modalHeading="Message"
          handleDelete={handleLogin}
          modalMessage="You have successfully reset the password"
          btnNoConfirmName="Ok"
          maxWidth="xs"
        />
      )}
    </div>
  );
};

export default ResetPassword;
