import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import profileimg from "../../../assets/images/avatarimg.png";

const useStyles = makeStyles((theme) => ({
    profileImageHolder: {
        width: '46px',
        height: '46px',
        marginRight: '14px',
        border: '1px solid #cdd1d8',
        borderRadius: '50%',
        overflow: 'hidden',
        "& img": {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            backgroundColor: 'white'
        }
    }
}))
const CustomImage = (props) => {
    const classes = useStyles();
    const linkActive = props.link ? true : false;
    return (
        <div className={classes.profileImageHolder + ' imgHolder'} style={{ width: props.size, minWidth: props.size, maxWidth: props.size, height: props.size, marginRight: props.marginRight, margin: props.margin }}>
            {props.userImage ?
                <img id="profile-img" alt="" src={props.userImage} style={{ maxWidth: '100%', maxHeight: '100%', cursor: linkActive ? 'pointer' : '' }} onClick={() => linkActive ? props.onRedirect(props.link) : null} />
                :
                <img id="profile-img" alt="" src={profileimg} style={{ maxWidth: '100%', maxHeight: '100%', }} />
            }
        </div>
    )
}

export default CustomImage
