import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_Path } from "../../Common/Const/index";
import ReactPlayer from "react-player";
import defaultavatar from "../../../assets/images/default-avatar.png";
import SnackBar from "../../../components/SnackBar";
import Report from "../ReportAbuse/Report";
import CommentEditor from "./CommentEditor";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";

const useStyles = makeStyles((theme) => ({
  searchWhiteBg: {
    width: "50%",
    border: "1px solid #d9d9d9",
    borderRadius: "30px",
    margin: "8px 0",
    "& input": {
      backgroundColor: "#fff",
      width: "49%",
    },
  },
  chatText: {
    position: "relative",
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    marginTop: "auto",
    "& .commentEditor":{
      width:'100%',
      marginTop: "0",
        minHeight: "44px",
        borderRadius: "22px",
        backgroundColor: "#fff",
        color: "#758194",
        fontSize: "14px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        borderColor: "transparent",
        "& .quill":{
          border:"none"
        }
    },
  },
  InputDoc: {
    minWidth: "34px",
    opacity: "0",
    "& input": {
      width: "35px",
      height: "25px",
      // top: "-180px",
      // position: "absolute",
      // left: "227px",
    },
    "& button": {
      cursor: "pointer",
    },
  },
  gifBtn: {
    border: "1px solid #d3d8dd",
    borderRadius: "6px",
    color: "#d3d8dd",
    // backgroundColor:'#d3d8dd',
    fontSize: "20px",
    marginRight: "10px",
    cursor: "pointer",
  },
  VideoName: {
    position: "relative",
    // right: "94px",
    right: "0px",
    display: "inline-block",
    overflow: " hidden",
    width: "150px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    top: "0px",
    //  top:"24px"
  },
  docName: {
    position: "relative",
    right: "58px",
    top: "20px",
    display: "inline-block",
    width: "180px",
    overflow: " hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  InputAttach: {
    minWidth: "34px",
    opacity: "0",
    "& input": {
      width: "35px",
      height: "25px",
      // top: "-90px",
      // bottom: "60px",
      // position: "absolute",
      // left: "227px",
    },
    "& button": {
      cursor: "pointer",
    },
  },
  inputImage: {
    // position: "absolute",
    // color: "#ffc003",
    top: "2px",
    color: "#ffc003",
    right: "6px",
    position: "absolute",
    fontSize: "14px",
    borderRadius: "14px",
  },
  activeStatus: {
    position: "absolute",
    right: "-6px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "16px",
    },
  },
}));
function LiveComment(props) {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const myRef = useRef(null);
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  useEffect(() => {
    postComment("");
  }, []);
  useEffect(() => {
    let commentDataList = props.commentDataList;
    if (
      commentDataList &&
      commentDataList.length > 0 &&
      commentDataList[0].parent_comment_id === null
    ) {
      let arraylist = [...messages];
      let index = arraylist.findIndex((a) => a._id === commentDataList[0]._id);
      if (index < 0) {
        arraylist.push({ ...commentDataList[0], child_comments: [] });
        setMessages(arraylist);
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      }
    }
  }, [
    props.reload || (props.commentDataList && props.commentDataList.length > 0),
  ]);

  const addComment = async (obj) => {
    props.UpdateComment({
      inputObject: {
        vurb_id: props.vurbId,
        device: "web",
        comment: obj.content,
        user_id: props.user._id,
        parent_comment_id: null,
        gif: obj.gif,
        video: obj.video,
        mentionIds: obj.mentionIds,
      },
    });
    props.onSuccess("Commented Successfully");
  };
  const postComment = (id) => {
    var VurbDetails = API_Path + "vurbs/comments/list";
    let inputObj = {
      inputObject: {
        vurb_id: props.vurbId,
        parent_comment_id: id ? id : null,
      },
    };
    axios
      .post(VurbDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        let data = [...response.data.result].reverse();
        setMessages(data ? data : []);
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

 
  const scrollToBottom = () => {
    if (myRef.current) {
      myRef.current.scrollTop = myRef.current.scrollHeight;
    }
  };
    const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  
  const openWindow = (data) => {
    window.open(data);
  };
  return (
    <React.Fragment>
      <div className="messages" ref={myRef}>
        {messages.map((row, index) => {
          var classname = "sender";
          if (props.user._id === row.created_by._id) {
            classname = "receiver";
          }
          let newDate = new Date(row.created_at);
          let eventDate = moment([
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate(),
            newDate.getHours(),
            newDate.getMinutes(),
          ]).fromNow();
          return (
            <div key={index} className={"chatSection flexRow msg " + classname}>
              <div
                className={
                  row.created_by && row.created_by.profile_image !== null
                    ? "chatImage"
                    : "chatdummyImage"
                }
              >
                {row.created_by && row.created_by.profile_image !== null ? (
                  <div style={{position:'relative'}}><img alt="" src={row.created_by.profile_image} />{!!row.created_by &&
                    !!row.created_by.badge_status && (
                      <span
                        className={classes.activeStatus}
                        title="Verified User/Public Figure"
                      >
                        <VerifiedUserRoundedIcon />
                      </span>
                    )}</div>
                ) : (
                  <div style={{position:'relative'}}><img alt="" src={defaultavatar} />{!!row.created_by &&
                    !!row.created_by.badge_status && (
                      <span
                        className={classes.activeStatus}
                        title="Verified User/Public Figure"
                      >
                        <VerifiedUserRoundedIcon />
                      </span>
                    )}</div>
                )}
              </div>
              <div className="chatContainer">
                <div
                  className="flexRow"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <div>
                    <h5 className="user">{row.created_by.full_name}</h5>
                    <h6>{eventDate}</h6>
                  </div>
                  {row.created_by._id !== props.user._id &&<Report
                    showType="Icon"
                    type="Reporting Comment"
                    primaryKey={row._id}
                  />}
                </div>
                {!!row.video && <div style={{ marginBottom: "20px" }}>
                      <ReactPlayer
                        className="react-player"
                        url={row.video}
                        width="50%"
                        height="50%"
                        controls
                      />
                    </div>}
                  {!!row.gif && <>
                      <img
                        alt=""
                        className="txt"
                        src={row.gif}
                        onClick={() => openWindow(row.gif)}
                        style={{
                          height: "106px",
                          width: "123px",
                          paddingTop: "10px",
                          cursor: "pointer",
                        }}
                      />
                      <br></br>
                    </>}

                {row.comment === "" ? " " : <div className="txt" dangerouslySetInnerHTML={{ __html: row.comment }}></div>}
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ position: "relative" }}>
        {props.blockedUser !== true ? (
          <>
            <div className={classes.chatText}>
              <CommentEditor user = {props.user} auth = {props.auth} result = {addComment} setSnackbarState={setSnackbarState} host= {props.host}/>           
            </div>
          </>
        ) : (
          <Typography align="center" variant="h6">
            You are not allowed to the chat
          </Typography>
        )}
      </div>
      <div
        style={{
          willChange: "transform",
          position: "absolute",
          bottom: "0%",
          right: "-100%",
          width: "100%",
        }}
      >
        <SnackBar
          open={snackbarState.open}
          message={snackbarState.message}
          severity={snackbarState.severity}
          onClose={onClose}
        />
      </div>
    </React.Fragment>
  );
}
// export default Chat;
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(LiveComment));
