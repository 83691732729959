
let initialState = {
    reportList:[],
    adReportList:[],
    DebateReportList:[],
};

export default (state = initialState, action) => {

    switch (action.type) {
        case "SET_REPORT_USER":
            return {
                ...state,
                reportList: action.data.reportList,
              
            };
       case "SET_AD_REPORT_LIST":
            return {
                ...state,
                adReportList: action.data.adReportList,
                  
            };
        case "SET_DEBATE_REPORT_LIST":
             return {
                 ...state,
                 DebateReportList: action.data.DebateReportList,
                      
            };
       case "ClearReportList":
            return {
                ...state,
                reportList: [],
                adReportList: [],
                DebateReportList:[]
            };
        default:
            return state;
    }
};