import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as yup from "yup";
import SnackBar from "../../../components/SnackBar";
import { API_Path } from "../../Common/Const/index";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import FormikSelect from "../../Common/FormikComponents/FormikSelect";
import Loader from "../../Common/Loader";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  leftSection: {
    width: "100%",
    padding: "36px",
    backgroundColor: "#fff",
  },
  rightSection: {
    padding: "24px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 400px",
      maxWidth: "400px",
      padding: "36px",
    },
  },
  headingWithBorderBottom: {
    margin: "0 0 20px 0",
    paddingBottom: "14px",
    borderBottom: "solid 1px #cdd1d8",
  },
  centerDiv: {
    maxWidth: 425,
    margin: "auto",
  },
  textField: {
    marginTop: theme.spacing(5),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  textFieldArea: {
    maxHeight: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: "inherit",
      "& .MuiOutlinedInput-input": {
        height: "inherit",
      },
    },
  },
  friendsChat: {
    position: "fixed",
    right: "15px",
    bottom: "68px",
    width: "350px",
    height: "410px",
    backgroundColor: "#fff",
    boxShadow: "-3px 9px 18px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "6px 6px 0 0",
    zIndex: 1000,
    "& .friendsChatHeader": {
      padding: "18px 12px",
      backgroundColor: "#ffc003",
      color: "#fff",
      fontSize: "18px",
      display: "flex",
      alignItems: "center",
      borderRadius: "6px 6px 0 0",
      textAlign: "center",
      justifyContent: "space-between",
    },
    "& .friendsChatContent": {
      padding: "24px 18px",
      overflow: "auto",
      "& .chatSection": {
        marginBottom: "10px",
        "& .chatImage": {
          flex: "0 0 34px",
          maxWidth: "34px",
          width: "34px",
          height: "34px",
        },
        "& h5, & h6, & .MuiSvgIcon-root": {
          display: "none",
        },
        "& p": {
          fontSize: "12px",
          color: "#121213",
          backgroundColor: "#dce7ff",
          padding: "6px 10px",
          borderRadius: "20px",
          minWidth: "60px",
          position: "relative",
          "&:after": {
            content: '""',
            position: "absolute",
            bottom: "-5px",
            left: "8px",
            backgroundColor: "#dce7ff",
            width: "10px",
            height: "10px",
            borderRadius: "0px 0px 10px 0px",
            transform: "rotate(15deg)",
          },
        },
        "&.sender": {
          display: "flex",
          flexFlow: "row-reverse",
          "& .chatImage": {
            margin: "0 0 0 12px",
          },
          "& p": {
            // backgroundColor: '#ffc003',
            // color: '#fff',
            "&:after": {
              left: "auto",
              right: 8,
              borderRadius: "0px 0px 0px 10px",
              // backgroundColor: '#ffc003',
              transform: "rotate(-15deg)",
            },
          },
        },
      },
      "& .MuiOutlinedInput-multiline": {
        minHeight: "36px",
        backgroundColor: "#f6f7f8",
        border: "none",
        boxShadow: "none",
        "& .MuiOutlinedInput-inputMultiline": {
          fontSize: "10px",
          color: "#758194",
        },
      },
      "& .chatSend": {
        "& img": {
          width: "18px",
        },
      },
      "& .hideEmoji": {
        height: "0",
      },
    },
  },
}));

const Support = (props) => {
  const classes = useStyles();
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [loader, setLoader] = useState(false);
  const [categoryList, SetcategoryList] = useState([
    {
        "label": "60feed6e1e96653e4ce6c1cd",
        "value": "General",
        "description": "General Category",
        "created_at": "2021-07-26T17:14:22.009Z",
        "__v": 0
    },
    {
        "label": "6103ba4b7cf60b619a3ff9aa",
        "value": "Issues",
        "description": "Issue Category",
        "created_at": "2021-07-26T17:14:22.009Z",
        "__v": 0
    },
    {
        "label": "64e74bdc5bb8aa60e6aa018c",
        "value": "Suggest Improvements",
        "description": "Suggest Improvements",
        "created_at": "2023-08-24T17:14:22.009Z",
        "__v": 0
    },
    {
        "label": "64e74c0a5bb8aa60e6aa018f",
        "value": "Feedback",
        "description": "Feedback",
        "created_at": "2023-08-24T17:14:22.009Z",
        "__v": 0
    },
    {
        "label": "64e74c5f5bb8aa60e6aa0191",
        "value": "Press/Media – Request for Interview",
        "description": "Press/Media – Request for Interview",
        "created_at": "2023-08-24T17:14:22.009Z",
        "__v": 0
    },
    {
        "label": "64e74c7c5bb8aa60e6aa0193",
        "value": "Partnership Inquiry",
        "description": "Partnership Inquiry",
        "created_at": "2023-08-24T17:14:22.009Z",
        "__v": 0
    },
    {
        "label": "64e74ca25bb8aa60e6aa0195",
        "value": "3rd Party App Developer/API",
        "description": "3rd Party App Developer/API",
        "created_at": "2023-08-24T17:14:22.009Z",
        "__v": 0
    }
]);
  const [chatEnable, setChatEnable] = useState(false);
  const [chatRoom, setChatRoom] = useState(null);
  const [customerSupportId, setCustomerSupportId] = useState(null);
  const initState = {
    category: "",
    subject: "",
    message: "",
    email: "",
  };
  const validationSchema = yup.object({
    category: yup.string().required("Category is Required"),
    email: yup.string().required("Please Enter Message"),
    subject: yup.string().required("Please Enter Subject"),
    message: yup.string().required("Please Enter Message"),
  });
  useEffect(() => {
    // GetCategoryList();
  }, []);
  const GetCategoryList = async () => {
    setLoader(true);
    var CategoryApi = API_Path + "api/ticketCategory/get";
    await axios
      .get(CategoryApi, {
        headers: {
          // 'Authorization': Make_Base_Auth()
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setLoader(false);
          SetcategoryList(
            response.data.result.map((val) => {
              val.value = val.category;
              val.label = val._id;
              val.id = val._id;
              return val;
            })
          );
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  const submitSignIn = async (state, { resetForm }) => {
    setSnackbarState({
        open: true,
        message: "Your Request Submitted Successfully.",
        severity: "success",
      });
      resetForm({ state: {} });
      return false
    setLoader(true);
    let ticketCreationApi = API_Path + "api/tickets/create";
    let inputObj = {
      inputObject: {
        category: state.category,
        subject: state.subject,
        description: state.message,
        created_by: props.user._id,
      },
    };
    await axios
      .post(ticketCreationApi, inputObj, {
        headers: {
          // 'Authorization': Make_Base_Auth()
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setLoader(false);
          setSnackbarState({
            open: true,
            message: "Inquiry Submission Successfully",
            severity: "success",
          });
          resetForm({ state: {} });
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const handleChat = (friendId) => {
    // setChatEnable((chatEnable) => !chatEnable)
    var ratindDetails = API_Path + "api/friendschatroom/create";
    let inputObj = {
      inputObject: {
        user1_id: props.user._id,
        user2_id: friendId,
        chat_type: "support",
      },
    };
    axios
      .post(ratindDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setChatRoom(response.data.result.room_name);
        setChatEnable((chatEnable) => !chatEnable);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className={classes.pageContainer}>
      <div className={classes.leftSection}>
        <Formik
          initialValues={{ ...initState }}
          onSubmit={submitSignIn}
          validationSchema={validationSchema}
          validateOnBlur={true}
        >
          {({ isSubmitting }) => {
            return (
              <Form autoComplete="off">
                <h2 className={classes.headingWithBorderBottom}>Contact Us</h2>
                <div className={classes.centerDiv}>
                <Field
                    name="email"
                    label="Email"
                    placeholder=""
                    type="text"
                    as={FormikInput}
                    className={clsx(classes.textField)}
                    variant="outlined"
                  />
                  <Field
                    name="category"
                    label="Category"
                    placeholder=""
                    type="select"
                    as={FormikSelect}
                    className={clsx(classes.textField)}
                    variant="outlined"
                    options={categoryList}
                  />
                  <Field
                    name="subject"
                    label="Subject"
                    placeholder=""
                    type="text"
                    as={FormikInput}
                    className={clsx(classes.textField)}
                    variant="outlined"
                  />
                  <Field
                    name="message"
                    label="Message"
                    placeholder=""
                    type="textarea"
                    as={FormikInput}
                    className={clsx(classes.textField, classes.textFieldArea)}
                    variant="outlined"
                    multiline={true}
                    rows={4}
                  />
                  <Box mt={2} textAlign="right">
                    <Button
                      variant="contained"
                      color="primary"
                      className={clsx(classes.loginButton, classes.circleBtn)}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Box>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      {loader && <Loader />}
    </div>
  );
};

const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage },
}) => {
  return {
    user,
    auth,
    is_disable_ads,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(Support));
