import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    width: "30px",
    height: "30px",
    background: "#ffc003",
    color: "#fff",
    "&:hover": {
      background: "#ffc003",
    },
    "& svg": {
      width: 20,
      height: 20,
    },
    // color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(({ handleDialogClose, ...props }) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2">{children}</Typography>
      {onClose || handleDialogClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ConfirmationModal = ({
  modalHeading,
  openDialog,
  handleDialogClose,
  handleDelete,
  modalMessage,
  maxWidth,
  ...props
}) => {
  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleDialogClose(event);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth={true}
      disableEscapeKeyDown
      onKeyDown={stopPropagationForTab}
    >
      {props.dialogButtons === false ? (
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          {modalHeading}
        </DialogTitle>
      ) : (
        <DialogTitle id="customized-dialog-title">{modalHeading}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText component={"div"} id="alert-dialog-description">
          {props.modalContent === true ? (
            <>{modalMessage}</>
          ) : (
            <h5 className="m-0" style={{ fontWeight: "500" }}>
              {modalMessage}
            </h5>
          )}
        </DialogContentText>
      </DialogContent>
      {props.dialogButtons === false ? (
        ""
      ) : (
        <DialogActions>
          {handleDialogClose ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDialogClose}
              size="small"
            >
              {props.btnCancelName}
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            size="small"
            autoFocus
          >
            {props.btnNoConfirmName}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmationModal;
