import React from 'react'
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core'
import logoImage from '../../../assets/images/VurbilBlue.png';
// import logoImage from '../../../assets/images/videoLogo.png';

const useStyles = makeStyles((theme) => ({
    videoPlayer: {
        position: 'relative',
        // height: '25vw',
        overflow: 'hidden',
        paddingTop: '56.25%',
        "& .react-player": {
            backgroundColor: "#333",
            position: 'absolute',
            top: 0,
            objectFit: 'cover'
        },
        "&.noVideo": {
            backgroundColor: "#333",
            "& .react-player:before": {
                content: '"No video to play"',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                color: '#fff',
                fontSize: '24px',
                opacity: '.25',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        "&.videoGeneration:not(.noVideo)": {
            backgroundColor: "#333",
            "& .react-player:before": {
                content: '"Please wait. Recording is in-progress..."',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                color: '#fff',
                fontSize: '24px',
                opacity: '.25',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        }
    },
    videoLogo: {
        position: 'absolute',
        right: '5%',
        bottom: '20%',
        width: '6em',
        opacity: '.75',
    }
}))

const VideoPlayer = ({ videoURL, videoThumbnail,controls=true, playing, muted, loop, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.videoPlayer + " videoPlayer" + (videoURL ? "" : " noVideo") + (videoThumbnail === null && " videoGeneration")}>
            <ReactPlayer className="react-player"
                url={(videoURL && videoThumbnail !== null) ? videoURL : null}
                //url="https://kp-develop.s3-us-west-2.amazonaws.com/47052404/606a0d40-4e78-43cc-8daa-07ae87ba92d6/archive.mp4"
                // playing={playing}
                playing
                muted={muted}
                light={videoThumbnail}
                loop={loop}
                width='100%'
                height='100%'
                style={{ maxWidth: '100%' }}
                controls={controls}
                config={{
                    file: {
                        forceHLS: false,
                        // attributes: { disablepictureinpicture: 'true'}
                    },
                }}
            />
            {!props.hideWaterMark && <img alt="Logo" src={logoImage} className={classes.videoLogo} />}
        </div>
    )
}

export default VideoPlayer
