import { Box, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomDebateContent from '../CustomDebateContent';
import CustomDebateHeader from '../CustomDebateHeader';
import VideoPlayer from '../VideoPlayer';

const useStyles = makeStyles((theme) => ({
    paperWhiteParent: {
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
        // padding: '20px 20px 30px 20px',
        borderRadius: '12px',
        backgroundColor: '#fff',
        margin: '20px 0',
        position: 'relative',
        // zIndex: 0,
        "& .videoPlayer": {
            "&.noVideo": {
                height: 220,
            }
        },
    },
    seperator: {
        borderBottom: 'solid 1px #cdd1d8;',
        margin: '10px 0px',
    },
}))
const baseUrl = process.env.REACT_APP_VIDEO_BASEURL;

const CustomDebateCard = (props) => {
    const classes = useStyles();
    const [debateObject, refreshDebateCardObject] = useState(props.debateDetail);
    useEffect(() => {
        refreshDebateCardObject(props.debateDetail)
    }, [props.debateDetail])
    return (
        <Paper key={props.index} className={classes.paperWhiteParent}>

            <Box p={2.25}>
                <CustomDebateHeader
                    debateDetail={debateObject}
                    getDebateDetails={props.getDebateDetails}
                    screen={props.screen}
                    userId={props && props.userId}
                    refreshDebateCardObject={refreshDebateCardObject}
                    editDebate={props.editDebate}
                    handleDeleteDebate={props.handleDeleteDebate}
                />
            </Box>
            {debateObject && debateObject.status && debateObject.status === 2 ?
                <Box mt={1}>
                    <VideoPlayer videoURL={debateObject && (!!debateObject.meta_info?baseUrl+debateObject.meta_info.key :debateObject.video_history_url)} videoThumbnail={debateObject && debateObject.thumbnails} playing={false} hideWaterMark={debateObject&&!!debateObject.meta_info?true:false}/>
                </Box>
                :
                <div className={classes.seperator}></div>
            }
            <Box p={2.25}>
                <CustomDebateContent key={props.index} debateDetail={debateObject} refreshDebateCardObject={refreshDebateCardObject} />
            </Box>
        </Paper>
    )
}

export default CustomDebateCard
