import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer } from "redux-persist";
import { PERSIST, PURGE } from "redux-persist/es/constants";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import rootReducer from "./reducers/root";
import crossBrowserListener from "./reduxpersist-listener";

// const middlewares=[thunk];
const config = { blacklist: [PERSIST, PURGE] };
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [createStateSyncMiddleware(config)];

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
// This supports https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
// const loadState = () => {
//       try {
//         const serializedState = sessionStorage.getItem('state');
//         if(serializedState === null) {
//           return undefined;
//         }
//         return JSON.parse(serializedState);
//       } catch (e) {
//         return undefined;
//       }
//     };
// const saveState = (state) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     sessionStorage.setItem('state', serializedState);
//   } catch (e) {
//     // Ignore write errors;
//   }
// };

// const store = createStore(rootReducer,peristedState,composeEnhancers(applyMiddleware(...middlewares)))
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

window.addEventListener("storage", crossBrowserListener(store, persistConfig));
initStateWithPrevTab(store);

export default store;
