import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PrivacyPolicy from "../../screens/Users/PrivacyPolicy";
import TermsAndConditions from "../../screens/Users/TermsAndConditions";
import MyDebates from "../../screens/Users/MyDebates";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  agreePopupContainer: {
    position: "relative",
    "& > div": {
      padding: "0",
      // "& h3": {
      //   display: 'none',
      // },
      // "& h4": {
      //   marginTop: '0',
      // }
    },
    "& .leftCreateSection": {
      margin: "0!important",
      "& h2": {
        display: "none",
      },
    },
    "&.opposition": {
      "& .propositionMemberTeam": {
        display: "none",
      },
    },
    "&.proposition": {
      "& .oppositionMemberTeam": {
        display: "none",
      },
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Modal = ({
  maxWidth,
  open,
  handleClose,
  selectedValue,
  selectedUser,
  children,
  mydebateObj,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Dialog
      {...rest}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {selectedValue === "Privacy Policy" && "Privacy Policy"}
        {selectedValue === "Terms and conditions" && "Terms of Service"}
        {selectedValue === "Update Debate" && "Update Debate"}
      </DialogTitle>
      <DialogContent
        dividers
        className={classes.agreePopupContainer + " " + selectedUser}
      >
        {/* {children} */}
        {selectedValue === "Privacy Policy" && <PrivacyPolicy />}
        {selectedValue === "Terms and conditions" && <TermsAndConditions />}
        {selectedValue === "Update Debate" && (
          <MyDebates mydebateObj={mydebateObj} closeModal={handleClose} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
