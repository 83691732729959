import { Button } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import clsx from 'clsx';
import React, { useEffect, useReducer } from 'react';
import { connect } from "react-redux";
import { API_Path } from '../../Common/Const/index';
const STRIPE_PUBLISHABLE_KEY=process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

function reducer(state, action) {
  switch (action.type) {
    case 'useEffectUpdate':
      return {
        ...state,
        ...action.payload,
        price: formatPrice({
          amount: action.payload.unitAmount,
          currency: action.payload.currency,
          quantity: state.quantity,
        }),
      };
    // case 'increment':
    //   return {
    //     ...state,
    //     quantity: state.quantity + 1,
    //     price: formatPrice({
    //       amount: state.unitAmount,
    //       currency: state.currency,
    //       quantity: state.quantity + 1,
    //     }),
    //   };
    // case 'decrement':
    //   return {
    //     ...state,
    //     quantity: state.quantity - 1,
    //     price: formatPrice({
    //       amount: state.unitAmount,
    //       currency: state.currency,
    //       quantity: state.quantity - 1,
    //     }),
    //   };
    case 'setLoading':
      return { ...state, loading: action.payload.loading };
    case 'setError':
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const Checkout = (props) => {
  const classes = props.classes;
  const [state, dispatch] = useReducer(reducer, {
    quantity: 1,
    price: null,
    loading: false,
    error: null,
    stripe: null,
  });

  useEffect(() => {
    async function fetchConfig() {
      // var configUrl=API_Path+"config";
      // Fetch config from our backend.
      // const { publicKey, unitAmount, currency } = await 
      // axios.get(configUrl)
      //   .then(res => res.data)        
      
      dispatch({
        type: 'useEffectUpdate',
        payload: {unitAmount:props.checkoutObj.checkoutPrice,currency:props.checkoutObj.currency, stripe: await loadStripe(STRIPE_PUBLISHABLE_KEY) },
      });
    }
    if(!!props.checkoutObj){fetchConfig();}
  }, [props.checkoutObj]);

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    dispatch({ type: 'setLoading', payload: { loading: true } });
    // const { sessionId } = await fetchCheckoutSession({
    //   quantity: state.quantity,
    // });
    const { sessionId } = await fetchCheckoutSession({
      quantity: state.quantity,
    });

    // When the customer clicks on the button, redirect them to Checkout.
    const { error } = await state.stripe.redirectToCheckout({
      sessionId,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    if (error) {
      dispatch({ type: 'setError', payload: { error } });
      dispatch({ type: 'setLoading', payload: { loading: false } });
    }
  };
  const fetchCheckoutSession = async ({ quantity }) => {
    var checkoutsessionurl=API_Path+"api/stripe/subscription";
    // var checkoutsessionurl=API_Path+"api/auth/create-checkout-session";
    
    return await axios.post(checkoutsessionurl, {
                          packageId:props.checkoutObj.packageId,
                          packageName:props.checkoutObj.packageName,              
                          planId:props.checkoutObj.planId,
                          planName:props.checkoutObj.planName,
                          debateId:props.checkoutObj.debateId,
                          vurbId:props.checkoutObj.vurbId,
                          user_id:props.user._id
                        }, {
                          headers: {
                      'Content-Type': 'application/json',
                      'Authorization':"Basic " + props.auth.accessToken
                    }
                  })
                  .then((res) =>res.data);
  };
  

  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
        </header>
        <section className="container">
        {/* <Button variant="contained" color="primary" className={clsx(classes.circleBtn)}>
                            Check out
                        </Button> */}
        <Button variant="contained" color="primary" className={clsx(classes.circleBtn)}
            role="link"
            onClick={handleClick}
            disabled={props.disabled || !state.stripe || state.loading}
          >
            {state.loading 
              ? `Loading...`
              : `Check out`
              //`Buy for ${state.price}`
            }
          </Button>
          <div className="sr-field-error">{state.error?.message}</div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
      user, auth
  };
};

export default connect(mapStateToProps)(Checkout);

// export default Checkout;
