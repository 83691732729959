import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MoreHoriz } from "@material-ui/icons";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_Path } from "../../Common/Const";
import MemberStatus from "../../Common/MemberStatus";
import ConfirmationModal from "../../Common/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    boxShadow: "none",
    border: "solid 1px #cdd1d8",
    borderRadius: "8px",
    height: "100%",
    "& > div": {
      "& [class*='makeStyles-root'], & > div:first-child": {
        width: "85px",
        flex: "0 0 85px",
        maxWidth: "85px",
        marginRight: "5px",
        textAlign: "center",
        height: "auto",
        "& img": {
          width: "60px",
          height: "60px",
        },
        "& .userStatus": {
          fontSize: "10px",
          lineHeight: "1.6",
          padding: "0 5px",
          whiteSpace: "nowrap",
        },
        "& .userRating": {
          margin: "0",
          "& svg": {
            width: "9px",
            height: "9px",
          },
        },
        "& span": {
          "& svg": {
            width: ".75em",
            height: ".75em",
          },
        },
      },
      "& button, & a": {
        fontSize: "10px",
        padding: "4px 10px",
        lineHeight: "1",
        minWidth: "inherit",
        borderRadius: "5px",
        boxShadow: "none",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        "&.btnwhite": {
          color: "#fff",
        },
        "&.statusFrientBtn": {
          padding: "7px 14px",
          minWidth: "102px",
          margin: "0",
          boxShadow: "none !important",
        },
      },
      "& .btnCenter": {
        textAlign: "center",
      },
      "& .unstringBtnShow": {
        position: "relative",
        "& .showOnHoverBtn": {
          backgroundColor: "#f57777!important",
        },
        "&:hover": {
          "& .hideOnHover": {
            opacity: "0",
            visibility: "hidden",
            position: "absolute",
            top: "0",
            left: "0",
          },
        },
        "&:not(:hover)": {
          "& .showOnHoverBtn": {
            opacity: "0",
            height: "0",
            visibility: "hidden",
            position: "absolute",
            left: "0",
          },
        },
      },
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
    // "&.column-down-lg": {
    //   [theme.breakpoints.down("md")]: {
    //     flexFlow: "column",
    //   },
    // },
  },
  headerActionSection: {
    display: "flex",
    flex: "0 0 105px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
  },
  classifyBtn: {
    margin: "0 -2px",
    "& button": {
      margin: "2px !important",
    },
  },
}));

const MembersCard = (props, ref) => {
  const { list, blockedUser, blockedByMe } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [blockOpen, setBlockOpen] = useState(false);
  const [userVal, setUserVal] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleFriend = (lst) => {
    const { _id, friendStatus } = lst ? lst : [];
    // console.log(_id, friendStatus)
    let friendListAPI = API_Path + "api/friends/sting";
    let statusAction = false;

    friendStatus === "Already Friends" || friendStatus === "Invited"
      ? (statusAction = false)
      : (statusAction = true);
    var inputObj = {
      inputObject: {
        userId: props.user._id,
        friendId: _id,
        sting: statusAction,
      },
    };
    axios
      .post(friendListAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          props.refresh();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const acceptDeclineFriendsRequest = (adRequest, list) => {
    let statusAction = false;
    if (adRequest === "Accept") {
      statusAction = true;
    } else {
      statusAction = false;
    }
    let adFriendsRequestAPI = API_Path + "api/friends/approve";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
        friendId: list._id,
        approve: statusAction,
      },
    };
    axios
      .post(adFriendsRequestAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          props.refresh();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleReport = (val) => {
    setBlockOpen(true);
    setUserVal(val);
  };
  const handleClose = () => {
    setBlockOpen(false);
  };
  const handleBlockedUser = () => {
    let friendListAPI = API_Path + "api/user/updateUserBlockStatus";
    var inputObj = {
      inputObject: {
        fromUserId: props.user._id,
        toUserId: userVal._id,
        status: blockedByMe === true ? "unblock" : "block",
      },
    };
    axios
      .post(friendListAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setBlockOpen(false);
        setUserVal([]);
        props.refresh();
        // getUserViewdata(user._id);
        // setSnackbarState({
        //   open: true,
        //   message: response.data.message,
        //   severity: "success",
        // });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const actionSectionHeader = (
    <div className={classes.headerActionSection}>
      <Box>
        <IconButton aria-controls="simple-menu" aria-haspopup="true"  onClick={handleClick}>
          <MoreHoriz
            // style={{ position: "absolute", top: "-3px" }}
           
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          { !blockedByMe && <MenuItem
            component={Link}
            to={`/friendsProfilePage/${list && list._id}`}
            target="_blank"
            onClick={() => handleCloseMenu()}
          >
            View Profile
          </MenuItem>}
          {blockedUser !== true &&
            (blockedByMe ? (
              <MenuItem onClick={handleReport.bind(this, list)}>Unblock</MenuItem>
            ) : list.friendStatus === "Sting" ? (
              <MenuItem onClick={handleFriend.bind(this, list)}>
                Sting Friend
              </MenuItem>
            ) : (
              (list.friendStatus === "Invited" ||
                list.friendStatus === "Already Friends") && (
                <MenuItem onClick={handleFriend.bind(this, list)}>
                  Unsting Friend
                </MenuItem>
              )
            ))}
        </Menu>
      </Box>
    </div>
  );
  return (
    <>
    <Paper
      className={classes.paper}
      //  ref={ref ? ref.ref : null}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "row" }}
        textAlign={{ xs: "left" }}
        alignItems={{ xs: "" }}
        width="100%"
      >
        <MemberStatus
          list={list}
          editIconShow={false}
          ratingED={true}
          uploadDisable={false}
          blockedUser={blockedUser}
        />
        <div style={{ width: "100%", overflow: "hidden" }}>
          <Box
            flexDirection={{ xs: "row" }}
            className={classes.flexJCSB}
            style={{ marginBottom: "8px" }}
          >
            <Box
              width={{
                xs: "100%",
                md: `${blockedUser !== true ? "calc(100% - 102px)" : "100%"}`,
              }}
              mb={{ xs: 2, md: 0 }}
            >
              <h5
                style={{
                  margin: "0",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {list.first_name + " " + list.last_name}
              </h5>
              {list && list.user_tagging && (
                <h6
                  style={{ margin: "0", color: "#758194", fontWeight: "700" }}
                >
                  @ {list.user_tagging}
                </h6>
              )}
            </Box>
            {actionSectionHeader}
          </Box>

          {/* {list.category_id.map((category) => {
                                                    return (
                                                        <Button variant="outlined" size="small">{category}</Button>
                                                    )
                                                })} */}

          {blockedUser !== true && (
            <>
              <div className={classes.classifyBtn}>
                {list &&
                  list.tags &&
                  list.tags.length > 0 &&
                  list.tags !== null &&
                  list.tags.map((tag, index) => {
                    return (
                      <Button key={index} variant="outlined" size="small">
                        {tag.name}
                      </Button>
                    );
                  })}
              </div>

              {!blockedByMe && list.friendStatus === "Accept" ? (
                <Box marginTop="10px">
                  <h6
                    className="m-0"
                    style={{
                      fontWeight: "500",
                      lineHeight: "1",
                      fontSize: "11px",
                      marginBottom: "5px",
                    }}
                  >
                    Would you like to add has Friend?
                  </h6>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={acceptDeclineFriendsRequest.bind(
                      this,
                      "Decline",
                      list
                    )}
                  >
                    {"Decline"}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={acceptDeclineFriendsRequest.bind(
                      this,
                      "Accept",
                      list
                    )}
                  >
                    {"Accept"}
                  </Button>
                </Box>
              ) : (
                ""
              )}

            </>
          )}
        </div>
      </Box>
    </Paper>
    <ConfirmationModal
        openDialog={blockOpen}
        modalHeading={blockedByMe === true ? "Unblock User" : "Block User"}
        handleDialogClose={handleClose}
        handleDelete={handleBlockedUser}
        modalMessage={`Are you sure you want to ${
          blockedByMe === true ? "Unblock" : "Block"
        } this user`}
        btnCancelName={"Cancel"}
        btnNoConfirmName={"Submit"}
        maxWidth="xs"
      />
    </>
  );
};
export default MembersCard;
