import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Paper,
  Radio,
  RadioGroup,
  makeStyles,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import clsx from "clsx";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import FormikSelect from "../../Common/FormikComponents/FormikSelect";
import {
  API_Path,
  DB_DateFormat,
  Make_Base_Auth,
  dateConvertor,
  numberFormatter,
} from "../../Common/Const";
import CommonSlider from "../../Common/CommonSlider";
import ConfirmationModal from "../../Common/ConfirmationModal";
import { addDays } from "date-fns";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import AddFundModal from "./AddFundModal";
import Loader from "../../Common/Loader";
import AdPreview from "./AdPreview";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { getAdFormDetails } from "../../../redux/reducers/adDetails";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "880px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  paperInput: {
    // boxShadow: "0px 3px 8px -1px #3232470D",
    // boxShadow: "0px 0px 1px 0px #0C1A4B3D",
    boShadow: "0px 2px 24px -1px #32324712",
    // boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.13)",//vurbil's shadow
    borderRadius: "12px",
    backgroundColor: "#fff",
    margin: "24px 40px",
    position: "relative",
    display: "flex",
    padding: "24px 16px",
    flexDirection: "column",
    // width: "880px",
    [theme.breakpoints.down("xs")]: {
      margin: "24px",
    },
    "& .title": {
      margin: "0",
      color: "#FFC003",
      padding: "0 0 16px 16px",
    },
    "& .subtitle": {
      margin: "0",
      padding: "0 0 16px 16px",
    },
  },
  header: {
    backgroundColor: "#FFF8E6",
    padding: "16px 40px",
    "& h4": {
      margin: "0",
      color: "#FFC003",
    },
    "& p": {
      width: "880px",
      fontSize: "13px",
      fontWeight: "400",
      color: "#646770",
      margin: "0",
      paddingTop: "8px",
    },
    "& button": {
      color: "blue",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px 24px",
      "& p": {
        width: "100%",
      },
    },
  },
  root: {
    padding: "0 16px",
    rowGap: "8px",
  },
  textLabel: {
    fontSize: "14px",
    color: "#313236",
    fontWeight: "500",
  },

  textField: {
    // marginTop: theme.spacing(2),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 8px !important",
    },
    "& p": {
      margin: "8px 0 0 0",
      fontSize: "13px",
    },
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      // marginTop: "8px",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-20px",
    },
    "& .MuiFormLabel-filled": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& .MuiInput-underline": {
      border: "1px solid #d6d6d6",
      padding: "8px",
      margin: 0,
      borderRadius: "4px",
    },
  },
  catagoryField: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "64px !important",
    },
  },
  textFieldAutocomplete: {
    maxHeight: "inherit",
    minHeight: "inherit",
    "& .MuiAutocomplete-inputRoot": {
      padding: "0",
      backgroundColor: "#fff",
      maxHeight: "inherit",
    },
    "& .Mui-focused": {
      zIndex: "1000",
    },
  },
  textFieldArea: {
    maxHeight: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: "inherit",
      "& .MuiOutlinedInput-input": {
        height: "inherit",
      },
    },
  },
  flexJCSB: {
    display: "flex",
    // borderTop: "solid 1px #cdd1d8",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  footerBtn: {
    margin: "24px 40px",
    float: "right",
    [theme.breakpoints.down("xs")]: {
      margin: "24px",
    },
  },
  fundError: {
    margin: "4px 0 0",
    fontSize: "12px",
    color: "red",
  },
  Space:{
    borderLeft: "1px solid #dddfe4",
    height: "85px",
    margin: "0px",
    [theme.breakpoints.down("xs")]: {
      border: "none",
      // borderTop:"1px solid #dddfe4",
      height: "0px",
    },
  },
  walletAmount:{
    fontSize:"12px",
    "& p":{
      margin:"0px"
    },
    "& span":{
      margin: "4px 0px 0px",
      fontSize: "14px",
    }
  },
  blanceClass:{
    marginTop: "6px",
    display:"flex",
    gap:"10px" ,
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  }
}));
export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;
const adTypeList = [
  {
    label: "1",
    value: "Non-Profit",
  },
  {
    label: "2",
    value: "Business",
  },
  {
    label: "3",
    value: "Political",
  },
];
const businessTypeList = [
  {
    label: "1",
    value: "Public Company",
  },
  {
    label: "2",
    value: "Private Company",
  },
  {
    label: "3",
    value: "Political Organization",
  },
  {
    label: "4",
    value: "Non-Profit",
  },
];
const adFileTypeList = [
  {
    label: "1",
    value: "Video",
  },
  {
    label: "2",
    value: "Image",
  },
];
const deviceList = [
  {
    label: "1",
    value: "Web",
  },
  {
    label: "2",
    value: "Mobile(Android and iOS)",
  },
  {
    label: "3",
    value: "Web and Mobile",
  },
];
const adPlacementSpecific = [
  {
    name: "Right Side bar Vurbil Pages (Web Only)",
    value: "vurbs_page_web",
    device: "Web",
  },
  {
    name: "Right Side Bar Debate Page (Web Only)",
    value: "debate_page_web",
    device: "Web",
  },
  {
    name: "Right Side Bar Other Page (Web Only)",
    value: "other_page_web",
    device: "Web",
  },
  {
    name: "Debate pre roll (Web Only)",
    value: "debate_pre_roll_web",
    device: "Web",
  },
  {
    name: "Vurbs Feed (Android and iOS)",
    value: "vurbs_page_mobile",
    device: "Mobile",
  },
  {
    name: "Debate Feed - Live debate, Recorded debate, my debate (Android and iOS)",
    value: "debate_page_mobile",
    device: "Mobile",
  },
  {
    name: "Debate pre roll video ad before debate (Android and iOS)",
    value: "debate_pre_roll_mobile",
    device: "Mobile",
  },
];

const adShowInList = [
  {
    label: "1",
    value: "Pre-roll (before) the Debate video",
    // value: "start",
  },
  {
    label: "2",
    value: "The middle of the Debate video",
    // value: "middle",
  },
  {
    label: "3",
    value: "At the end of the Debate video",
    // value: "last",
  },
];
const CreateAd = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const formData = useSelector(getAdFormDetails);
  const formData = props.adDetails.form || props.editData;
  const [initState, setinitState] = useState({
    ad_title: "",
    type_of_advertisement: "",
    description: "",
    company_name: "",
    type_of_business: "",
    type_of_ad_file: "",
    link_to_your_file: "",
    call_to_action_url: "",
    country: [],
    states: [],
    cities: [],
    target_intrest_tags: [],
    hash_tags: [],
    ad_placement: "",
    specific_area_ad_placement: "",
    category: [],
    ad_show_in: "",
    // ad_priority: "",
    ad_impression: null,
    ad_duration: null,
    ad_budget: null,
    start_date: addDays(new Date(), 1),
    expiry_date: addDays(new Date(), 1),
    is_auto_renew: false,
    is_vurbil_authorization: true,
    author_name: "",
    position_at_company: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [country, setCountry] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [states, setStates] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [cities, setCities] = useState([]);
  const [classificationDetails, setclassificationDetails] = useState([]);
  const [selectedTags, setselectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [hashtagList, setHashtagList] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [hashLoading, setHashLoading] = useState(false);
  const [adPlacementList, setAdPlacementList] = useState([]);
  const [selectedAdPlacementArea, setSelectedAdPlacementArea] = useState([]);
  const [adPlacementArea, setAdPlacementArea] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [adShowIn, setAdShowIn] = useState("");
  const [impressionVal, setImpressionVal] = useState(1000);
  const [durationVal, setDurationVal] = useState(1);
  const [budgetVal, setBudgetVal] = useState(10);
  const [config, setConfig] = useState();
  const [maxBudget, setMaxBudget] = useState();
  const [maxImpression, setMaxImpression] = useState();
  const [maxDuration, setMaxDuration] = useState();
  const [wallet, setWallet] = useState(null);
  const [addFundModal, setAddFundModal] = useState(false);
  const [authHelper, setAuthHelper] = useState("");
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [schema, setSchema] = useState();
  const [previewModal, setPreviewModal] = useState(false);
  const history =useHistory()
 
  // const [snackbarState, setSnackbarState] = useState({ isOpen: false });
  // youtube validation
  const videoUrlPattern = /^(https):\/\/([\w-]+\.)+[\w-]+\/(?:[\w-]+\/)*[^\/\s]+\.(?:mp4|avi|mov|flv|wmv|webm)$/i;
  const youtubeUrlPattern = /^(https:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  const vimeoUrlPattern = /^(https:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
  const dropboxUrlPattern = /^https:\/\/(www\.)?dropbox\.com\/scl\/fi\/[a-zA-Z0-9]+\/[^\/\s]+\.(mp4|avi|mov|flv|wmv|webm)(\?[^#\s]*)?$/

  let pageName = "createAd",
    hashTimeout;
  let isDebateAd =
    adPlacementArea.length > 0 &&
    adPlacementArea.some((obj) => obj.includes("debate_pre_roll"));
  const validationSchema = yup.object({
    ad_title: yup
      .string()
      .trim()
      .required("Ad Title is Required")
      .max(25, "Maximum 25 characters"),
    type_of_advertisement: yup
      .string()
      .required("Advertisement type is Required"),
    description: yup
      .string()
      .trim()
      .required("Description is Required")
      .max(100, "Maximum 100 characters"),
    company_name: yup
      .string()
      .trim()
      .required("Company Name is Required")
      .max(50, "Maximum 50 characters"),
    type_of_business: yup.string().required("Advertiser type is Required"),
    type_of_ad_file: yup.string().required("File type is Required"),
    link_to_your_file: yup
      .string()
      .trim()
      .required("Link is Required")
      .when("type_of_ad_file", {
        is: "Image",
        then: yup
          .string()
          .matches(
            /^(https):\/\/([\w-]+\.)+[\w-]+\/(?:[\w-]+\/)*[^\/\s]+\.(?:jpg|jpeg|png|gif|bmp|tiff|webp|heif)$/i,
            "Enter Valid Link e.g., https://example.com/image.jpeg"
          )
          .required("Link to Image is Required"),
        otherwise: yup
          .string()
          // .matches(
          //   /^(https):\/\/([\w-]+\.)+[\w-]+\/(?:[\w-]+\/)*[^\/\s]+\.(?:mp4|avi|mov|flv|wmv|webm)$/i,
          //   "Enter Valid Link e.g., https://example.com/video.mp4"
          // )
          // .required("Link to Video is Required"),
          .test(
            "is-valid-video-url",
            "Enter a valid .mp4 URL or a valid YouTube, or Dropbox link",
            (value) =>
              videoUrlPattern.test(value) ||
              youtubeUrlPattern.test(value) ||
              dropboxUrlPattern.test(value)
          )
          .required("Link to Video is Required"),
      }),
    call_to_action_url: yup
      .string()
      .trim()
      .required("Action Url Required")
      .matches(
        /^(https):\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/,
        "Enter Valid URL"
      ),
    // country: yup
    //   .array()
    //   .min(1, "Country must have at least 1 item")
    //   .required("Country is Required"),
    // states: yup
    //   .array()
    //   .min(1, "State must have at least 1 item")
    //   .required("State is Required"),
    // cities: yup
    //   .array()
    //   .min(1, "City must have at least 1 item")
    //   .required("City is Required"),
    // target_intrest_tags: yup
    //   .array()
    //   .min(1, "Interest Tag must have at least 1 item")
    //   .required("Interest Tag is Required"),
    ad_placement: yup.string().required("Field is Required"),
    specific_area_ad_placement: yup
      .array()
      .min(1, "Ad Placement Area must have at least 1 item")
      .required("Field is Required"),

    author_name: yup
      .string()
      .trim()
      .required("Name is required")
      .max(30, "Maximum 30 characters"),
    position_at_company: yup
      .string()
      .trim()
      .required("Position is required")
      .max(50, "Maximum 50 characters"),
  });
  useEffect(() => {
    setLoader(true);
    getCountryList();
    getclassification();
    getHashTag();
    getConfig();
    getWallet();
    setSchema(validationSchema);

    setTimeout(() => {
      if (formData !== null && formData !== undefined) {
        if (formData.hash_tags.length > 0) {
          setHashtags(formData.hash_tags);
        }
        if (formData.ad_placement.length > 0) {
          let tempArray = [];
          if (formData.ad_placement === "Mobile(Android and iOS)") {
            tempArray = adPlacementSpecific.filter(
              (obj) => obj.device === "Mobile"
            );
          } else if (formData.ad_placement === "Web") {
            tempArray = adPlacementSpecific.filter(
              (obj) => obj.device === "Web"
            );
          } else {
            tempArray = [...adPlacementSpecific];
          }
          setAdPlacementList(tempArray);
        }
        if (formData.specific_area_ad_placement.length > 0) {
          let tempArray = adPlacementSpecific.filter((obj1) =>
            formData.specific_area_ad_placement.some(
              (obj2) => obj2 === obj1.value
            )
          );
          setSelectedAdPlacementArea(tempArray);
          setAdPlacementArea(formData.specific_area_ad_placement);
        }
        if (formData.ad_show_in.length > 0) {
          if (formData.ad_show_in === "start") {
            formData.ad_show_in = "Pre-roll (before) the Debate video";
            setAdShowIn(formData.ad_show_in);
          } else if (formData.ad_show_in === "middle") {
            formData.ad_show_in = "The middle of the Debate video";
            setAdShowIn(formData.ad_show_in);
          } else if (formData.ad_show_in === "last") {
            formData.ad_show_in = "At the end of the Debate video";
            setAdShowIn(formData.ad_show_in);
          }
        }
        if(formData.ad_placement === "Both"){
          formData.ad_placement = "Web and Mobile"
        }
        if (formData.start_date.includes("T")) {
          let date = dateConvertor(formData.start_date.split("T")[0]);
          formData.start_date = date;
        } else {
          let date = dateConvertor(formData.start_date);
          formData.start_date = date;
        }
        if (formData.expiry_date.includes("T")) {
          let date = dateConvertor(formData.expiry_date.split("T")[0]);
          formData.expiry_date = date;
        } else {
          let date = dateConvertor(formData.expiry_date);
          formData.expiry_date = date;
        }
        if (formData.hash_tags.length > 0) {
          setHashtags(formData.hash_tags);
        }
        setinitState({ ...initState, ...formData });
      }
      setLoader(false);
      dispatch({
        type: "AD_CLOSE",
      });
    }, 4000);

      }, []);
    
  useEffect(() => {

    if (isDebateAd) {
      let obj = yup.object({
        ad_title: yup
          .string()
          .trim()
          .required("Ad Title is Required")
          .max(25, "Maximum 25 characters"),
        type_of_advertisement: yup
          .string()
          .required("Advertisement type is Required"),
        description: yup
          .string()
          .trim()
          .required("Description is Required")
          .max(100, "Maximum 100 characters"),
        company_name: yup
          .string()
          .trim()
          .required("Company Name is Required")
          .max(50, "Maximum 50 characters"),
        type_of_business: yup.string().required("Business type is Required"),
        type_of_ad_file: yup.string().required("File type is Required"),
        link_to_your_file: yup
          .string()
          .trim()
          .required("Link is Required")
          .when("type_of_ad_file", {
            is: "Image",
            then: yup
              .string()
              .matches(
                /^(https):\/\/([\w-]+\.)+[\w-]+\/(?:[\w-]+\/)*[^\/\s]+\.(?:jpg|jpeg|png|gif|bmp|tiff|webp|heif)$/i,
                "Enter Valid Link e.g., https://example.com/image.jpeg"
              )
              .required("Link to Image is Required"),
            otherwise: yup
              .string()
              // .matches(
              //   /^(https):\/\/([\w-]+\.)+[\w-]+\/(?:[\w-]+\/)*[^\/\s]+\.(?:mp4|avi|mov|flv|wmv|webm)$/i,
              //   "Enter Valid Link e.g., https://example.com/video.mp4"
              // )
              // .required("Link to Video is Required"),
              .test(
                "is-valid-video-url",
                "Enter a valid .mp4 URL or a valid YouTube, or Dropbox link",
                (value) =>
                  videoUrlPattern.test(value) ||
                  youtubeUrlPattern.test(value) ||
                  dropboxUrlPattern.test(value)
              )
              .required("Link to Video is Required"),
          }),
        call_to_action_url: yup
          .string()
          .trim()
          .required("Action Url Required")
          .matches(
            /^(https):\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/,
            "Enter Valid URL"
          ),
        ad_placement: yup.string().required("Field is Required"),
        specific_area_ad_placement: yup
          .array()
          .min(1, "Ad Placement Area must have at least 1 item")
          .required("Field is Required"),
        category: yup
          .array()
          .min(1, "Category must have at least 1 item")
          .required("Field is Required"),
        ad_show_in: yup.string().required("Field is Required"),
        author_name: yup
          .string()
          .trim()
          .required("Name is required")
          .max(30, "Maximum 30 characters"),
        position_at_company: yup
          .string()
          .trim()
          .required("Position is required")
          .max(50, "Maximum 50 characters"),
      });
      setSchema(obj);
    } else {
      setSchema(validationSchema);
      setSelectedCategory([]);
      setCategory([]);
      setAdShowIn("");
      setinitState({ ...initState, category: [], ad_show_in: "" });
    }
  }, [isDebateAd]);
  // console.log(schema)
  const getCountryList = async () => {
    var countryListApi = API_Path + "api/country/get";
    await axios
      .get(countryListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then(async (response) => {
        setCountryList(response.data.result);
        if (formData !== null && formData.country.length > 0) {
          // let tempSelectedCountryArray =
          //   response.data.result.length > 0 &&
          //   response.data.result.filter((obj1) =>
          //     formData.country.some((obj2) => obj2 === obj1.name)
          //   );
          setSelectedCountry(formData.country);
          setCountry(formData.country);
          let tempStateList = [],
            tempCityList = [];
          for (let obj of formData.country) {
            let list = await getStateList(obj.name);
            tempStateList = [...tempStateList, ...list];
            // console.log(list, tempStateList, "State")
          }
          setStateList(tempStateList);
          if (formData.states.length > 0) {
            // let tempSelectedStateArray =
            //   tempStateList.length > 0 &&
            //   tempStateList.filter((obj1) =>
            //     formData.states.some((obj2) => obj2 === obj1.name)
            //   );
            setSelectedStates(formData.states);
            setStates(formData.states);
            for (let data of formData.states) {
              let cityList = await getCityList(data.country, data.name);
              tempCityList = [...tempCityList, ...cityList];
            }
            setCityList(tempCityList);
            if (formData.cities.length > 0) {
              // let tempSelectedCityArray =
              //   tempCityList.length > 0 &&
              //   tempCityList.filter((obj1) =>
              //     formData.cities.some((obj2) => obj2 === obj1.name)
              //   );
              setSelectedCity(formData.cities);
              setCities(formData.cities);
            }
          }
        }
        // setTimeout(() => {
        //   setLoader(false);
        // }, 1000);
      })
      .catch((error) => {
        // setLoader(false);
        console.error(error);
      });
  };
  const getStateList = async (country) => {
    var stateListApi = API_Path + `api/country/getState/${country}`;
    try {
      const response = await axios.get(stateListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      });
      if (response.data.result.length > 0) {
        let tempArray,
          data = await response.data.result
            .filter((val) => val._id && val.name)
            .map((obj) => ({ ...obj, country }));
        tempArray = [...stateList, ...data];
        // console.log(tempArray);
        setStateList(tempArray);
        return data;
      }
    } catch (e) {
      console.error(e);
    }
    // await axios
    //   .get(stateListApi, {
    //     headers: {
    //       Authorization: Make_Base_Auth(),
    //     },
    //   })
    //   .then((response) => {
    //     let tempArray,
    //       data = response.data.result
    //         .filter((val) => val._id && val.name)
    //         .map((obj) => ({ ...obj, country }));
    //     tempArray = [...stateList, ...data];
    //     // console.log(tempArray);
    //     setStateList(tempArray);
    //     return data;
    //   })
    //   .catch((error) => {
    //     // setLoader(false);
    //     console.error(error);
    //   });
  };
  const getCityList = async (country, state) => {
    var cityListApi = API_Path + `api/country/getCity/${country}/${state}`;
    try {
      const response = await axios.get(cityListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      });
      if (response.data.result.length > 0) {
        let tempArray,
          data = await response.data.result
            .filter((val) => val._id && val.name)
            .map((obj) => ({ ...obj, country, state }));
        tempArray = [...cityList, ...data];
        // console.log(tempArray);
        setCityList(tempArray);
        return data;
      }
    } catch (e) {
      console.error(e);
    }
    // await axios
    //   .get(cityListApi, {
    //     headers: {
    //       Authorization: Make_Base_Auth(),
    //     },
    //   })
    //   .then((response) => {
    //     let tempArray,
    //       data = response.data.result
    //         .filter((val) => val._id && val.name)
    //         .map((obj) => ({ ...obj, country, state }));
    //     tempArray = [...cityList, ...data];
    //     // console.log(tempArray);
    //     setCityList(tempArray);
    //     if (formData.cities.length > 0) {
    //       let tempSelectedCityArray =
    //         tempArray.length > 0 &&
    //         tempArray.filter((obj1) =>
    //           formData.cities.some((obj2) => obj2 === obj1.name)
    //         );
    //       setSelectedCity(tempSelectedCityArray);
    //       setCities(formData.tempSelectedCityArray);
    //     }
    //     return data;
    //   })
    //   .catch((error) => {
    //     // setLoader(false);
    //     console.error(error);
    //   });
  };
  const getclassification = () => {
    const classificationDetails = API_Path + "debateClassification/get";
    axios
      .get(classificationDetails, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        // props.handleClassificationList(response.data.result)
        // const classificationList = response.data.result.map(v => ({ label: v._id, value: v.name }));
        setclassificationDetails(response.data.result.sort((a, b) => a.name.localeCompare(b.name)));
        if (formData !== null) {
          if (formData.target_intrest_tags.length > 0) {
            let tempSelected = response.data.result.filter((obj1) =>
              formData.target_intrest_tags.some((obj2) => obj1._id === obj2)
            );
            setselectedTags(tempSelected);
            setTags(formData.target_intrest_tags);
          }
          if (formData.category.length > 0) {
            let tempSelected = response.data.result.filter((obj1) =>
              formData.category.some((obj2) => obj1._id === obj2)
            );
            setSelectedCategory(tempSelected);
            setCategory(formData.category);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getHashTag = async (searchText) => {
    const hastTagApi = API_Path + "api/wallet/get_hashtag";
    // setHashLoading(true);
    await axios
      .post(
        hastTagApi,
        { searchText },
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((response) => {
        setHashtagList(response.data.result);
        setHashLoading(false);
        clearTimeout(hashTimeout);
        // return response.data.result;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getConfig = async () => {
    const configApi = API_Path + "api/wallet/get_ad_config";
    await axios
      .post(
        configApi,
        {},
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((response) => {
        // console.log(response.data.result);
        setConfig(response.data.result);
        setImpressionVal(response.data.result.impression);
        setBudgetVal(response.data.result.budget);
        setDurationVal(response.data.result.duration);
        setMaxBudget(response.data.result.maxBudget);
        setMaxImpression(response.data.result.maxImpression);
        setMaxDuration(response.data.result.maxDuration);
        setinitState({
          ...initState,
          ad_impression: response.data.result.impression,
          ad_budget: response.data.result.budget,
          ad_duration: response.data.result.duration,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getWallet = async () => {
    const hastTagApi = API_Path + "api/wallet/get_user_amount";
    await axios
      .post(
        hastTagApi,
        {},
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((response) => {
        setWallet(response.data.result);
        // return response.data.result;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const removeState = (country) => {
    let tempStateArray = stateList.filter((obj) => obj.country !== country);
    let tempCityArray = cityList.filter((obj) => obj.country !== country);
    let tempSelectedStateArray = selectedStates.filter(
      (obj) => obj.country !== country
    );
    let tempSelectedCityArray = selectedCity.filter(
      (obj) => obj.country !== country
    );
    setStateList(tempStateArray);
    setCityList(tempCityArray);
    setSelectedStates(tempSelectedStateArray);
    setSelectedCity(tempSelectedCityArray);
    return { tempSelectedStateArray, tempSelectedCityArray };
    // removeCity(country)
  };
  const removeCity = (state) => {
    let tempArray = cityList.filter((obj) => obj.state !== state);
    let tempSelectedCityArray = selectedCity.filter(
      (obj) => obj.state !== state
    );
    setCityList(tempArray);
    setSelectedCity(tempSelectedCityArray);
    return { tempSelectedCityArray };
  };
  const handleCountry = (val) => {
    let tempArray = [...country],
      tempState = [...states],
      tempCity = [...cities],
      countryVal,
      index;
    setSelectedCountry(val);
    if (val.length > 0) {
      if (val.length > country.length) {
        countryVal = val.filter((obj1) => !country.includes(obj1.name));
        tempArray.push(countryVal[0].name);
        getStateList(countryVal[0].name);
        setCountry(tempArray);
      } else {
        countryVal = tempArray.filter(
          (obj1) => !val.some((obj2) => obj2.name === obj1)
        );
        index = tempArray.findIndex((obj) => obj === countryVal[0]);
        // console.log(countryVal, index);
        tempArray.splice(index, 1);
        setCountry(tempArray);
        let { tempSelectedStateArray, tempSelectedCityArray } = removeState(
          countryVal[0]
        );
        tempState = tempSelectedStateArray.map((obj) => obj.name);
        tempCity = tempSelectedCityArray.map((obj) => obj.name);
        setStates(tempState);
        setCities(tempCity);
      }
    } else {
      tempArray = [];
      tempState = [];
      tempCity = [];
      setCountry([]);
      setStates([]);
      setCities([]);
      setStateList([]);
      setCityList([]);
      setSelectedStates([]);
      setSelectedCity([]);
    }
    // setinitState({
    //   ...initState,
    //   country: tempArray,
    //   states: tempState,
    //   cities: tempCity,
    // });
  };
  const handleState = (val) => {
    let tempArray = [...states],
      tempCity = [...cities],
      stateVal,
      index;
    setSelectedStates(val);
    if (val.length > 0) {
      if (val.length > states.length) {
        stateVal = val.filter((obj1) => !states.includes(obj1.name));
        tempArray.push(stateVal[0].name);
        getCityList(stateVal[0].country, stateVal[0].name);
        setStates(tempArray);
      } else {
        stateVal = tempArray.filter(
          (obj1) => !val.some((obj2) => obj2.name === obj1)
        );
        index = tempArray.findIndex((obj) => obj === stateVal[0]);
        tempArray.splice(index, 1);
        setStates(tempArray);
        let { tempSelectedCityArray } = removeCity(stateVal[0]);
        tempCity = tempSelectedCityArray.map((obj) => obj.name);
        setCities(tempCity);
      }
    } else {
      tempArray = [];
      tempCity = [];
      setStates([]);
      setCities([]);
      setCityList([]);
    }
    // setinitState({ ...initState, states: tempArray, cities: tempCity });
  };
  const handleCity = (val) => {
    let tempArray = [...cities],
      cityVal,
      index;
    setSelectedCity(val);
    if (val.length > 0) {
      if (val.length > cities.length) {
        cityVal = val.filter((obj1) => !cities.includes(obj1.name));
        tempArray.push(cityVal[0].name);
        setCities(tempArray);
      } else {
        cityVal = tempArray.filter(
          (obj1) => !val.some((obj2) => obj2.name === obj1)
        );
        index = tempArray.findIndex((obj) => obj === cityVal[0]);
        tempArray.splice(index, 1);
        setCities(tempArray);
      }
    } else {
      tempArray = [];
      setCities([]);
      setSelectedCity(val);
    }
    // setinitState({ ...initState, cities: tempArray });
  };
  const handleTags = (val) => {
    let tempArray = [...tags],
      tagVal,
      index;
    setselectedTags(val);
    if (val.length > 0) {
      if (val.length > tags.length) {
        tagVal = val.filter((obj1) => !tags.includes(obj1._id));
        tempArray.push(tagVal[0]._id);
        setTags(tempArray);
      } else {
        tagVal = tempArray.filter(
          (obj1) => !val.some((obj2) => obj2._id === obj1)
        );
        index = tempArray.findIndex((obj) => obj === tagVal[0]);
        tempArray.splice(index, 1);
        setTags(tempArray);
      }
    } else {
      tempArray = [];
      setTags([]);
    }
    // setinitState({ ...initState, target_intrest_tags: tempArray });
  };
  const handleHashtag = (val) => {
    setHashtags(val);
    // setinitState({ ...initState, hash_tags: val });
  };
  const tagFilterOptions = (options, { inputValue }) => {
    // console.log(options,inputValue);
    if (inputValue === "") {
      // setHashLoading(true)
      // handleHastagSearch(inputValue)
      return options;
    }
    let filteredOptions = options.filter((data) =>
      data.tag.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (filteredOptions.length === 0) {
      setHashLoading(true);
      handleHastagSearch(inputValue);
      // getHashTag(inputValue);
    }
    return filteredOptions;
  };
  const handleChange = (e, obj) => {
    let {
      target: { name, value },
    } = e;
    // value = value.trim();
    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }
    let data = { ...initState, ...obj, [name]: value };
    setinitState(data);
    if (name === "ad_placement") {
      let tempArray = [];
      if (value === "Mobile(Android and iOS)") {
        tempArray = adPlacementSpecific.filter(
          (obj) => obj.device === "Mobile"
        );
        checkAdPlacement("Mobile");
      } else if (value === "Web") {
        tempArray = adPlacementSpecific.filter((obj) => obj.device === "Web");
        checkAdPlacement("Web");
      } else {
        tempArray = [...adPlacementSpecific];
      }
      setAdPlacementList(tempArray);
    } else if (name === "ad_show_in") {
      setAdShowIn(value);
    } else if (name === "is_vurbil_authorization") {
      // console.log("auth",value)
      if (!value) {
        // console.log("auth",value)
        setAuthHelper(
          "Authorized person from Company/Organization only allowed to Create Ad"
        );
        setError(true);
      } else {
        setAuthHelper("");
        setError(false);
      }
    }
  };
  const handleDateChange = (date, type, obj) => {
    if (type === "start_date") {
      setinitState({
        ...initState,
        ...obj,
        start_date: date,
        expiry_date: addDays(date, initState.ad_duration - 1),
      });
    } else if (type === "expiry_date") {
      let tempImp,
        tempBud,
        value,
        start = moment(
          moment(initState.start_date).format("DD/MM/YYYY"),
          "DD/MM/YYYY"
        ),
        expiry = moment(moment(date).format("DD/MM/YYYY"), "DD/MM/YYYY");
      value = expiry.diff(start, "days") + 1;
      tempImp = value * impressionVal;
      tempBud = value * budgetVal;
      setinitState({
        ...initState,
        ...obj,
        expiry_date: date,
        ad_budget: tempBud,
        ad_impression: tempImp,
        ad_duration: value,
      });
    }
  };
  const checkAdPlacement = (device) => {
    let tempArray = [...selectedAdPlacementArea],
      filteredArray = tempArray.filter((obj) => obj.device === device);
    setSelectedAdPlacementArea(filteredArray);
    setAdPlacementArea(filteredArray.map((obj) => obj.value));
  };
  const handlePlacement = (val, obj) => {
    setSelectedAdPlacementArea(val);
    setAdPlacementArea(val.map((obj) => obj.value));
    setinitState({
      ...initState,
      ...obj,
      specific_area_ad_placement: val.map((obj) => obj.value),
    });
  };
  const handleCategory = (val, obj) => {
    let tempArray = [...category],
      tagVal,
      index;
    setSelectedCategory(val);
    if (val.length > 0) {
      if (val.length > category.length) {
        tagVal = val.filter((obj1) => !category.includes(obj1._id));
        tempArray.push(tagVal[0]._id);
        setCategory(tempArray);
      } else {
        tagVal = tempArray.filter(
          (obj1) => !val.some((obj2) => obj2._id === obj1)
        );
        index = tempArray.findIndex((obj) => obj === tagVal[0]);
        tempArray.splice(index, 1);
        setCategory(tempArray);
      }
    } else {
      tempArray = [];
      setCategory([]);
    }
    setinitState({ ...initState, ...obj, category: tempArray });
  };
  const handleAddFund = (data) => {
    const {
      ad_title,
      description,
      company_name,
      link_to_your_file,
      call_to_action_url,
      author_name,
      position_at_company,
    } = data;
    let ad_show = adShowIn;
    if (adShowIn === "Pre-roll (before) the Debate video") {
      ad_show = "start";
    } else if (adShowIn === "The middle of the Debate video") {
      ad_show = "middle";
    } else if (adShowIn === "At the end of the Debate video") {
      ad_show = "last";
    }
    let obj = {
      ...initState,
      ad_title: ad_title.trim(),
      description: description.trim(),
      company_name: company_name.trim(),
      link_to_your_file: link_to_your_file.trim(),
      call_to_action_url: call_to_action_url.trim(),
      country: selectedCountry,
      states: selectedStates,
      cities: selectedCity,
      target_intrest_tags: tags,
      hash_tags: hashtags,
      specific_area_ad_placement: adPlacementArea,
      category,
      author_name: author_name.trim(),
      position_at_company: position_at_company.trim(),
      start_date: moment(initState.start_date).format(DB_DateFormat),
      expiry_date: moment(initState.expiry_date).format(DB_DateFormat),
      ad_show_in: ad_show,
    };
    dispatch({
      type: "AD_POPUP",
      data: { isOpen: true },
    });
    dispatch({
      type: "AD_DETAILS",
      data: obj,
    });
    setAddFundModal(true);
  };
  const handleAdFaq = () => {
    console.log("Ad Faq");
    window.open("/faq/list", "_blank");
  };
  const handleClose = () => {
    props.handleClose();
  };
  const handleSubmit = async (state) => {
    setLoader(true);
    const {
      ad_title,
      description,
      company_name,
      link_to_your_file,
      call_to_action_url,
      author_name,
      position_at_company,
    } = state;
    let ad_placement = initState.ad_placement === "Web and Mobile" ? "Both" : initState.ad_placement ;
    let obj = {
      ...initState,
      ad_title: ad_title.trim(),
      description: description.trim(),
      company_name: company_name.trim(),
      link_to_your_file: link_to_your_file.trim(),
      call_to_action_url: call_to_action_url.trim(),
      country: selectedCountry,
      states: selectedStates,
      cities: selectedCity,
      target_intrest_tags: tags,
      hash_tags: hashtags,
      specific_area_ad_placement: adPlacementArea,
      ad_placement: ad_placement,
      category,
      author_name: author_name.trim(),
      position_at_company: position_at_company.trim(),
    };
    // let isVideo = /\.(mp4|avi|mov)$/i.test(obj.link_to_your_file);
    let url = obj.link_to_your_file;
    const videoFilePattern = /\.(mp4|mov|webm|mkv|avi|flv|wmv|mpeg|mpg|3gp|vob)(\?[^#\s]*)?$/i;
    // const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+$/i;
    const youtubePattern =  /^(https:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    const vimeoPattern = /^(https:\/\/)?(www\.)?vimeo\.com\/\d+(\?[^\s]*)?$/i;
    const dropboxPattern = /^https:\/\/(www\.)?dropbox\.com\/scl\/fi\/[a-zA-Z0-9]+\/[^\/\s]+\.(mp4|mov|webm|mkv|avi|flv|wmv|mpeg|mpg|3gp|vob)(\?[^#\s]*)?$/

    // Check if URL is a video file
    let isVideoFile = videoFilePattern.test(url);

    // Check if URL is a YouTube video
    let isYouTube = youtubePattern.test(url);

    // Check if URL is a Vimeo video
    let isVimeo = vimeoPattern.test(url);

    // Check if URL is a Dropbox video
    let isDropbox = dropboxPattern.test(url);

    // Determine if URL matches any of the conditions
    let isVideo = isVideoFile || isYouTube || isDropbox;
    
    if (!obj.is_vurbil_authorization) {
      return false;
    }
    if (
      (isVideo && obj.type_of_ad_file.toLowerCase() === "image") ||
      (!isVideo && obj.type_of_ad_file.toLowerCase() === "video")
    ) {
      setLoader(false);
      props.onError("Ad File type and Ad URL link is mismatch");
      return false;
    } else {
      if (obj.type_of_ad_file.toLowerCase() === "video") {
        // const videoEle = document.createElement("video");
        // videoEle.src = obj.link_to_your_file;
        // videoEle.onerror = () => {
        //   setLoader(false);
        //   props.onError("Video URL is not Valid");
        //   // videoEle.remove()
        //   return false;
        // };
        // videoEle.oncanplay = () => {
        onSubmit(obj);
        //   videoEle.remove();
        // };
      } else if (obj.type_of_ad_file.toLowerCase() === "image") {
        const imgEle = new Image();
        imgEle.src = obj.link_to_your_file;
        imgEle.onload = () => {
          onSubmit(obj);
        };
        imgEle.onerror = () => {
          setLoader(false);
          props.onError("Image URL is not Valid");
          return false;
        };
      }
    }
  };
  const onSubmit = async (obj) => {
    const create = "api/ads/create";
    const update = `api/ads/update/${initState._id ? initState._id : ""}`;
    let apiUrl = `${API_Path}${initState._id ? update : create}`;
    let ad_show = adShowIn;
    if (adShowIn === "Pre-roll (before) the Debate video") {
      ad_show = "start";
    } else if (adShowIn === "The middle of the Debate video") {
      ad_show = "middle";
    } else if (adShowIn === "At the end of the Debate video") {
      ad_show = "last";
    }
    const inputObj = {
      ...obj,
      ad_show_in: ad_show,
      start_date: moment(initState.start_date).format(DB_DateFormat),
      expiry_date: moment(initState.expiry_date).format(DB_DateFormat),
    };
    await axios
      .post(apiUrl, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          props.onSuccess(res.data.message);
          setLoader(false);
        } else {
          props.onError(res.data.message);
          setLoader(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleSlider = (name, value, obj, type) => {
    let tempImp, tempDur, tempBud;
    if (type === "slide") {  
      if (name === "impression") {
        setinitState({
          ...initState,
          ...obj,
          ad_impression: value,
        });
      } else if (name === "duration") {
        setinitState({
          ...initState,
          ...obj,
          ad_duration: value,
        });
      } else if (name === "budget") {
        setinitState({
          ...initState,
          ...obj,
          ad_budget: value,
        });
      }
    } else {
      if (name === "impression") {
        tempImp = value;
        tempDur = (value * durationVal) / impressionVal;
        tempBud = (value * budgetVal) / impressionVal;
      } else if (name === "duration") {
        tempDur = value;
        tempImp = value * impressionVal;
        tempBud = value * budgetVal;
      } else if (name === "budget") {
        tempBud = value;
        tempImp = (value * impressionVal) / budgetVal;
        tempDur = (value * durationVal) / budgetVal;
      }
      setinitState({
        ...initState,
        ...obj,
        ad_impression: tempImp,
        ad_duration: tempDur,
        ad_budget: tempBud,
        expiry_date: addDays(initState.start_date, tempDur - 1),
      });
    }
    // console.log(e, value)
  };
  const handlePreview = (e) => {
    setPreviewModal(false);
  };
  const valueLable = (val) => {
    return numberFormatter(val);
  };
  const handleHastagSearch = (searchText) => {
    clearTimeout(hashTimeout);
    // setHashLoading(true)
    hashTimeout = setTimeout(() => {
      // console.log(searchText)
      getHashTag(searchText);
    }, 2000);
    // console.log(hashTimeout,searchText)
  };
  // const handleTooltip = () => {
  //   const keyReplacements = {
  //     blocked_amount: "Blocked Amount",
  //     wallet_current_balance: "Current Balance",
  //     total_wallet_amount: "Total Amount",
  //   };
  //   let tip;
  //   if (wallet) {
  //     delete wallet["_id"];

  //     // Create a new object with replaced keys
  //     const replacedItems = Object.fromEntries(
  //       Object.entries(wallet).map(([key, value]) => [
  //         keyReplacements[key] || key,
  //         value,
  //       ])
  //     );

  //     // Generate the tooltip content
  //     tip = Object.entries(replacedItems)
  //       .map(([key, value]) => `${key}: $ ${value}`)
  //       .join("\n");
  //   } else {
  //     tip = "";
  //   }
  //   return tip;
  // };
  // const onClose = () => {
  //   setSnackbarState({ open: false });
  // };
  // const handleError = (error) => {
  //   let errorVal = Object.entries(error)[0][1];
  //   console.log(typeof errorVal, errorVal);
  //   // console.log(error)
  //   setSnackbarState({
  //     open: true,
  //     message: "Error at start",
  //     severity: "error",
  //   });
  // };
  const PreviewAds = (formprops)=>{
    setinitState({
      ...initState,
      ad_title: formprops.values.ad_title,
      description: formprops.values.description,
      link_to_your_file:
        formprops.values.link_to_your_file,
    });
    if(initState.ad_placement !=="" ){
      if(initState.specific_area_ad_placement.length > 0){
        setPreviewModal(true);
      }else{
        props.onError("Plese Select Specific Area for Ad Placement");
      }
     
    }else{
      props.onError("Plese Select the Placement");
    }
   
  }
  return (
    <div>
      {loader && <Loader />}
      <div className={classes.header}>
        <h4>Create Advertisement</h4>
        <p>
          All businesses big and small can advertise on Vurbil. We use
          proprietary non-evasive tools to match up our Vurbil users to your
          specific ads. For more information about Vurbil ads and guidelines,
          view{" "}
          <Link component="button" underline="always" onClick={handleAdFaq}>
            Ad FAQs.
          </Link>
        </p>
      </div>
      <Box>
        <Formik
          enableReinitialize
          initialValues={{ ...initState }}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnBlur={true}
        >
          {(formprops) => {
            // console.log(formprops,Object.entries(formprops.errors));
            // if (formprops.submitCount > 0 && !formprops.isSubmitting) {
            //   // console.log(formprops,Object.entries(formprops.errors),!formprops.isSubmitting);
            //   if (Object.entries(formprops.errors).length > 0) {
            //     handleError(formprops.errors);
            //   }
            // }
            return (
              <Form autoComplete="off" className={classes.form}>
                <Paper className={classes.paperInput}>
                  <Box>
                    <h4 className="title">Advertisement Details</h4>
                    <Grid container className={classes.root} spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Advertisement Title
                          </label>
                          <Field
                            name="ad_title"
                            // label="Advertisement Title"
                            placeholder="Advertisement Title"
                            type="text"
                            variant="outlined"
                            as={FormikInput}
                            className={clsx(classes.textField)}
                            // handleChange={handleChange}
                            // onChange={handleChange}
                            // onBlur={handleChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Type of Advertisement
                          </label>
                          <Field
                            name="type_of_advertisement"
                            label=""
                            placeholder="Type of Advertisement"
                            type="select"
                            variant="outlined"
                            options={adTypeList}
                            as={FormikSelect}
                            className={clsx(classes.textField)}
                            onChange={(e) => handleChange(e, formprops.values)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0 0 16px" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Text to be Displayed with Ad
                          </label>
                        </div>
                        <p style={{ fontSize: "13px", marginTop: "0" }}>
                          Please include text (up to 100 characters) to
                          accompany your ad. (e.g., "Introducing the Audi
                          e-tron...")
                        </p>
                        <Field
                          fullWidth
                          name="description"
                          label=""
                          placeholder="Text to be Displayed with Ad"
                          type="textarea"
                          variant="outlined"
                          as={FormikInput}
                          className={clsx(
                            classes.textField,
                            classes.textFieldArea
                          )}
                          multiline={true}
                          minRows={4}
                          // onChange={handleChange}
                          // onBlur={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Company Name
                          </label>
                          <Field
                            name="company_name"
                            // label="Company Name"
                            placeholder="Company Name"
                            type="text"
                            variant="outlined"
                            as={FormikInput}
                            className={clsx(classes.textField)}
                            // onChange={handleChange}
                            // onBlur={handleChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Type of Advertiser
                          </label>
                          <Field
                            name="type_of_business"
                            // label="Type of Business"
                            placeholder="Type of Advertiser"
                            type="select"
                            variant="outlined"
                            options={businessTypeList}
                            as={FormikSelect}
                            className={clsx(classes.textField)}
                            onChange={(e) => handleChange(e, formprops.values)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Type of Ad File
                          </label>
                          <Field
                            name="type_of_ad_file"
                            // label="Type of Ad File"
                            placeholder="Type of Ad File"
                            type="select"
                            variant="outlined"
                            options={adFileTypeList}
                            as={FormikSelect}
                            className={clsx(classes.textField)}
                            onChange={(e) => handleChange(e, formprops.values)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Link to Your Ad File
                          </label>
                          <Field
                            name="link_to_your_file"
                            // label="Link to Your Ad File"
                            placeholder="Link to Your Ad File"
                            type="text"
                            variant="outlined"
                            as={FormikInput}
                            className={clsx(classes.textField)}
                            // onChange={handleChange}
                            // onBlur={handleChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Call to Action URL
                          </label>
                          <Field
                            name="call_to_action_url"
                            // label="Call to Action URL"
                            placeholder="Call to Action URL"
                            type="text"
                            variant="outlined"
                            as={FormikInput}
                            className={clsx(classes.textField)}
                            // onChange={handleChange}
                            // onBlur={handleChange}
                          />
                        </div>
                        <p style={{ margin: "44px 0 16px", fontSize: "13px" }}>
                          Provide URL for Company or Promotional Link (e.g.,
                          https://www.abc.com)
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper className={classes.paperInput}>
                  <Box>
                    <h4 className="title">Target Audience</h4>
                    <h4 className="subtitle">By Location</h4>
                    <Grid container className={classes.root} spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Country
                          </label>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            onChange={(event, newValue) => {
                              handleCountry(newValue);
                            }}
                            id="country"
                            options={countryList}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            value={
                              selectedCountry && selectedCountry
                                ? selectedCountry
                                : []
                            }
                            getOptionSelected={(option, value) =>
                              value.name === option.name
                            }
                            // defaultValue={ptc && ptc ? ptc : []}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <Field
                                {...params}
                                name="country"
                                // label="Country"
                                placeholder="Country"
                                type="text"
                                as={FormikInput}
                                className={clsx(
                                  classes.textField,
                                  classes.textFieldAutocomplete
                                )}
                                variant="outlined"
                              />
                            )}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 4 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            disableCloseOnSelect={true}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            State
                          </label>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            onChange={(event, newValue) => {
                              handleState(newValue);
                            }}
                            id="state"
                            options={stateList}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            value={
                              selectedStates && selectedStates
                                ? selectedStates
                                : []
                            }
                            getOptionSelected={(option, value) =>
                              value.name === option.name
                            }
                            // defaultValue={ptc && ptc ? ptc : []}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <Field
                                {...params}
                                name="states"
                                // label="State"
                                placeholder="State"
                                type="text"
                                as={FormikInput}
                                className={clsx(
                                  classes.textField,
                                  classes.textFieldAutocomplete
                                )}
                                variant="outlined"
                              />
                            )}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 4 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            disableCloseOnSelect={true}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">City</label>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            onChange={(event, newValue) => {
                              handleCity(newValue);
                            }}
                            id="city"
                            options={cityList}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            value={
                              selectedCity && selectedCity ? selectedCity : []
                            }
                            getOptionSelected={(option, value) =>
                              value.name === option.name
                            }
                            // defaultValue={ptc && ptc ? ptc : []}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <Field
                                {...params}
                                name="cities"
                                label=""
                                placeholder="City"
                                type="text"
                                as={FormikInput}
                                className={clsx(
                                  classes.textField,
                                  classes.textFieldAutocomplete
                                )}
                                variant="outlined"
                              />
                            )}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 4 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            disableCloseOnSelect={true}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <h4
                      className="subtitle"
                      style={{ padding: "24px 0 8px 16px", marginTop: "16px" }}
                    >
                      By Topic Interest or Hashtag
                    </h4>
                    <p
                      style={{
                        margin: "0",
                        paddingLeft: "16px",
                        paddingBottom: "24px",
                        fontSize: "13px",
                      }}
                    >
                      Select as many tags as you would like to target your ad to
                      the audience.
                    </p>
                    <Grid
                      container
                      className={classes.root}
                      spacing={4}
                      style={{ paddingBottom: "16px" }}
                    >
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Target Interest Tags
                          </label>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            onChange={(event, newValue) => {
                              handleTags(newValue);
                            }}
                            id="tags-standard"
                            options={classificationDetails}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            value={
                              selectedTags && selectedTags ? selectedTags : []
                            }
                            getOptionSelected={(option, value) =>
                              value.name === option.name
                            }
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <Field
                                {...params}
                                name="target_intrest_tags"
                                // label="Target Interest Tags"
                                placeholder="Target Interest Tags"
                                type="text"
                                as={FormikInput}
                                className={clsx(
                                  classes.textField,
                                  classes.textFieldAutocomplete
                                )}
                                variant="outlined"
                              />
                            )}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            disableCloseOnSelect={true}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0 0 16px" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Hash Tags
                          </label>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            onChange={(event, newValue) => {
                              handleHashtag(newValue);
                            }}
                            id="hashTag"
                            options={hashtagList}
                            groupBy={(option) => option.type}
                            getOptionLabel={(option) =>
                              option ? option.tag : ""
                            }
                            value={hashtags && hashtags ? hashtags : []}
                            getOptionSelected={(option, value) =>
                              value.tag === option.tag
                            }
                            filterOptions={tagFilterOptions}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <Field
                                {...params}
                                name="hash_tags"
                                // label="Hash Tags"
                                placeholder="Hash Tags"
                                type="text"
                                as={FormikInput}
                                className={clsx(
                                  classes.textField,
                                  classes.textFieldAutocomplete
                                )}
                                variant="outlined"
                              />
                            )}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 4 }}
                                  checked={selected}
                                />
                                {option.tag}
                              </React.Fragment>
                            )}
                            disableCloseOnSelect={true}
                            loading={hashLoading}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper className={classes.paperInput}>
                  <Box>
                    <h4 className="title">Ad Placement</h4>
                    <Grid container className={classes.root} spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0 0 16px" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            On which Vurbil channel would you like your ad to be
                            placed?
                          </label>
                        </div>
                        <Field
                          name="ad_placement"
                          label=""
                          placeholder="On which Vurbil channel would you like your ad to be placed?"
                          type="select"
                          variant="outlined"
                          options={deviceList}
                          onChange={(e) => handleChange(e, formprops.values)}
                          as={FormikSelect}
                          className={clsx(classes.textField)}
                        />
                        <p style={{ margin: "16px 0 0", fontSize: "13px" }}>
                          Vurbil is available on Web, Android and iOS. Please
                          select the channel where you’d like to advertise
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{ marginBottom: "80px" }}
                      >
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Select Specific Area for Ad Placement
                          </label>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            onChange={(event, newValue) => {
                              handlePlacement(newValue, formprops.values);
                            }}
                            id="specific_area_ad_placement"
                            options={adPlacementList}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            value={
                              selectedAdPlacementArea && selectedAdPlacementArea
                                ? selectedAdPlacementArea
                                : []
                            }
                            getOptionSelected={(option, value) =>
                              value.name === option.name
                            }
                            // defaultValue={ptc && ptc ? ptc : []}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <Field
                                {...params}
                                name="specific_area_ad_placement"
                                // label="Select Specific Area for Ad Placement"
                                placeholder="Select Specific Area for Ad Placement"
                                type="text"
                                as={FormikInput}
                                className={clsx(
                                  classes.textField,
                                  classes.textFieldAutocomplete
                                )}
                                style={{ margin: "31px 0" }}
                                variant="outlined"
                              />
                            )}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            disableCloseOnSelect={true}
                          />
                        </div>
                      </Grid>
                      {isDebateAd && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <div
                              className={classes.textField}
                              style={{ margin: "0" }}
                            >
                              <label className="MuiInputLabel-outlined">
                                If you chose to advertise in the Debate section,
                                in which debate categories do you want your ad
                                to appear?
                              </label>
                            </div>
                            <Autocomplete
                              multiple
                              className={classes.catagoryField}
                              limitTags={1}
                              onChange={(event, newValue) => {
                                handleCategory(newValue, formprops.values);
                              }}
                              id="ad_category"
                              options={classificationDetails}
                              getOptionLabel={(option) =>
                                option ? option.name : ""
                              }
                              value={
                                selectedCategory && selectedCategory
                                  ? selectedCategory
                                  : []
                              }
                              getOptionSelected={(option, value) =>
                                value.name === option.name
                              }
                              // defaultValue={ptc && ptc ? ptc : []}
                              // filterSelectedOptions
                              renderInput={(params) => (
                                <Field
                                  {...params}
                                  name="category"
                                  label=""
                                  placeholder="If you chose to advertise in the Debate section, in
                              which debate categories do you want your ad to
                              appear?"
                                  type="text"
                                  as={FormikInput}
                                  className={clsx(
                                    classes.textField,
                                    classes.textFieldAutocomplete
                                  )}
                                  style={{ marginTop: "40px" }}
                                  variant="outlined"
                                />
                              )}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </React.Fragment>
                              )}
                              disableCloseOnSelect={true}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ marginBottom: "80px" }}
                          >
                            <div
                              className={classes.textField}
                              style={{ margin: "0" }}
                            >
                              <label className="MuiInputLabel-outlined">
                                Select ad placement for a recorded Debate video
                              </label>
                              <Field
                                name="ad_show_in"
                                // label="Select ad placement for a recorded Debate video"
                                placeholder="Select ad placement for a recorded Debate video"
                                type="select"
                                variant="outlined"
                                options={adShowInList}
                                onChange={(e) =>
                                  handleChange(e, formprops.values)
                                }
                                as={FormikSelect}
                                className={clsx(classes.textField)}
                                style={{ marginTop: "56px" }}
                              />
                            </div>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            borderRadius: "24px",
                            padding: "9px 24px",
                            float: "right",
                          }}
                          // disabled={true}
                          onClick={() => PreviewAds(formprops)}
                        >
                          Preview
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper className={classes.paperInput}>
                  <Box>
                    <h4 className="title">Select Ad Budget & Duration</h4>

                    <Grid container className={classes.root} spacing={4}>
                      {/* <Grid item xs={12} sm={6}>
                        <Field
                          name="ad_priority"
                          label="Ad Priority"
                          placeholder="Ad Priority"
                          type="select"
                          variant="outlined"
                          options={adShowInList}
                          onChange={handleChange}
                          as={FormikSelect}
                          className={clsx(classes.textField)}
                        />
                      </Grid> */}
                      {/* <Grid item xs={12} sm={6}></Grid> */}
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Start Date
                          </label>
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Field
                            fullWidth
                            variant="inline"
                            component={KeyboardDatePicker}
                            format="MM/dd/yyyy"
                            type="text"
                            name="start_date"
                            // label="Start Date"
                            placeholder="MM/DD/YYYY"
                            as={FormikInput}
                            className={clsx(classes.textField)}
                            autoOk
                            minDate={addDays(new Date(), 1)}
                            onChange={(date) =>
                              handleDateChange(
                                date,
                                "start_date",
                                formprops.values
                              )
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Expiry Date
                          </label>
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Field
                            fullWidth
                            variant="inline"
                            component={KeyboardDatePicker}
                            format="MM/dd/yyyy"
                            type="text"
                            name="expiry_date"
                            // label="Expiry Date"
                            placeholder="MM/DD/YYYY"
                            as={FormikInput}
                            className={clsx(classes.textField)}
                            autoOk
                            minDate={initState.start_date}
                            maxDate={addDays(
                              new Date(),
                              initState.ad_duration + 365
                            )}
                            onChange={(date) =>
                              handleDateChange(
                                date,
                                "expiry_date",
                                formprops.values
                              )
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <div
                            className={clsx(
                              classes.textField,
                              classes.flexJCSB
                            )}
                            style={{ margin: "0" }}
                          >
                            <p className={classes.textLabel}>
                              Ad Impression (Approx)
                            </p>
                            <p className={classes.textLabel}>
                              {numberFormatter(initState.ad_impression)}
                            </p>
                          </div>
                          <CommonSlider
                            name="ad_impression"
                            valueLabelDisplay="auto"
                            // defaultValue={initState.ad_impression}
                            value={initState.ad_impression}
                            min={impressionVal}
                            max={maxImpression}
                            step={impressionVal}
                            onChangeCommitted={(e, value) =>
                              handleSlider(
                                "impression",
                                value,
                                formprops.values
                              )
                            }
                            onChange={(e, value) =>
                              handleSlider(
                                "impression",
                                value,
                                formprops.values,
                                "slide"
                              )
                            }
                            valueLabelFormat={valueLable}
                          />
                        </Box>
                        <Box>
                          <div
                            className={clsx(
                              classes.textField,
                              classes.flexJCSB
                            )}
                            style={{ margin: "0" }}
                          >
                            <p className={classes.textLabel}>Ad Duration</p>
                            <p className={classes.textLabel}>{`${
                              initState.ad_duration
                            } Day${initState.ad_duration > 1 ? "s" : ""}`}</p>
                          </div>
                          <CommonSlider
                            name="ad_duration"
                            valueLabelDisplay="auto"
                            // defaultValue={initState.ad_duration}
                            value={initState.ad_duration}
                            min={durationVal}
                            max={maxDuration}
                            step={durationVal}
                            onChangeCommitted={(e, value) =>
                              handleSlider("duration", value, formprops.values)
                            }
                            onChange={(e, value) =>
                              handleSlider(
                                "duration",
                                value,
                                formprops.values,
                                "slide"
                              )
                            }
                          />
                        </Box>
                        <Box>
                          <div
                            className={clsx(
                              classes.textField,
                              classes.flexJCSB
                            )}
                            style={{ margin: "0" }}
                          >
                            <p className={classes.textLabel}>Total Ad Budget</p>
                            <p
                              className={classes.textLabel}
                            >{`$ ${numberFormatter(initState.ad_budget)}`}</p>
                          </div>
                          <CommonSlider
                            name="ad_budget"
                            valueLabelDisplay="auto"
                            // defaultValue={initState.ad_budget}
                            value={initState.ad_budget}
                            min={budgetVal}
                            max={maxBudget}
                            step={budgetVal}
                            onChange={(e, value) =>
                              handleSlider(
                                "budget",
                                value,
                                formprops.values,
                                "slide"
                              )
                            }
                            onChangeCommitted={(e, value) =>
                              handleSlider("budget", value, formprops.values)
                            }
                            // valueLabelFormat={valueLable}
                            valueLabelFormat={(value) =>
                              `$${valueLable(value)}`
                            }
                          />
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}></Grid> */}
                     
                      <Grid
                        item
                        xs={12}
                        style={{ borderBottom: "1px solid #DDDFE4" }}
                      >
                        <div
                          // className={clsx(classes.textField)}
                          style={{ margin: "0" }}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              margin: "4px 0",
                            }}
                          >
                            Do you want to Auto-renew your Ad Campaign after the
                            expiry of the Ad duration?
                          </p>
                        </div>
                        <FormControl>
                          <RadioGroup
                            className={classes.flexJCSB}
                            name="is_auto_renew"
                            value={initState.is_auto_renew}
                            onChange={(e) => handleChange(e, formprops.values)}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        <p style={{ margin: "4px 0 0", fontSize: "13px" }}>
                          We'll deduct funds when the ad runs. If funds run out,
                          your ads will be paused.
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          // className={clsx(classes.textField,classes.textLabel)}
                          style={{ margin: "0" }}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              margin: "4px 0",
                            }}
                          >
                            Budget & Duration
                          </p>
                        </div>
                        <p style={{ margin: "4px 0 0", fontSize: "14px" }}>
                          Total No of days Selected{" "}
                          <span style={{ marginLeft: "8px" }}>
                            : {initState.ad_duration}
                          </span>
                        </p>
                        <p style={{ margin: "4px 0 0", fontSize: "14px" }}>
                          Cost per Day{" "}
                          <span style={{ marginLeft: "96px" }}>
                            : $ {config && config.cost_per_day}
                          </span>
                        </p>
                        <p style={{ margin: "4px 0 0", fontSize: "14px" }}>
                          Total Budget{" "}
                          <span style={{ marginLeft: "98px" }}>
                            : $ {initState.ad_budget}
                          </span>
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper className={classes.paperInput}>
                  <Box>
                    <Grid container className={classes.root} spacing={4}>
                      <Grid item xs={12}>
                        <div className={classes.flexJCSB}>
                          <div className={classes.blanceClass}>
                            <div>
                              <p style={{ margin: "0px", fontSize: "14px" }}>
                                Prepaid Funds
                              </p>
                              {/* <LightTooltip
                              title={
                                <div style={{ whiteSpace: "pre-line" }}>
                                  {handleTooltip()}
                                </div>
                              }
                              placement="top-end"
                            > */}
                              <h4 style={{ margin: 0 }}>
                                $ {wallet && wallet.total_wallet_amount}
                              </h4>
                            </div>
                            {/* </LightTooltip> */}
                            {wallet && (
                              <>
                                {" "}
                                <p className={classes.Space}></p>
                                <div className={classes.walletAmount}>
                                  <span>Fund Summary</span>
                                  <p>
                                    Current Balance : $
                                    {wallet.wallet_current_balance}
                                  </p>
                                  <p>
                                    Scheduled Ad Amount : $
                                    {wallet.blocked_amount}
                                  </p>
                                  <p>
                                    Remaining Amount : $
                                    {wallet.total_wallet_amount}
                                  </p>
                                </div>{" "}
                              </>
                            )}
                          </div>

                          <Button
                            variant="contained"
                            color="primary"
                            className={clsx(classes.circleBtn)}
                            style={{
                              margin: "auto 0",
                            }}
                            onClick={() => handleAddFund(formprops.values)}
                          >
                            Add Funds
                          </Button>
                        </div>
                        {!!initState.ad_budget &&
                          wallet &&
                          initState.ad_budget >
                            wallet?.total_wallet_amount +
                              (props.editData?.ad_budget
                                ? props.editData?.ad_budget
                                : 0) && (
                            <p className={classes.fundError}>
                              Insufficient Fund Balance to Create Ad. Please Add
                              Funds to Wallet
                            </p>
                          )}
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper className={classes.paperInput}>
                  <Box>
                    <h4 className="title">Authorization to place ad</h4>
                    <Grid
                      container
                      className={classes.root}
                      spacing={4}
                      style={{ marginBottom: "32px" }}
                    >
                      <Grid item xs={12}>
                        <div
                          // className={clsx(classes.textField)}
                          style={{ margin: "0" }}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              margin: "4px 0",
                            }}
                          >
                            Do you have authorization on behalf of the Company /
                            Organization to place this ad on Vurbil?
                          </p>
                        </div>
                        <FormControl error={error}>
                          <RadioGroup
                            className={classes.flexJCSB}
                            name="is_vurbil_authorization"
                            value={initState.is_vurbil_authorization}
                            onChange={(e) => handleChange(e, formprops.values)}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormHelperText>
                          <span style={{ color: "red" }}>{authHelper}</span>
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.textField}
                          style={{ margin: "0" }}
                        >
                          <label className="MuiInputLabel-outlined">
                            Your Name
                          </label>
                          <Field
                            name="author_name"
                            // label="Your Name"
                            placeholder="Your Name"
                            type="text"
                            variant="outlined"
                            as={FormikInput}
                            className={clsx(classes.textField)}
                            // onChange={handleChange}
                            // onBlur={handleChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className={classes.textField}>
                          <label className="MuiInputLabel-outlined">
                            Position/Title at Company or Organization
                          </label>
                          <Field
                            name="position_at_company"
                            // label="Position/Title at Company or Organization"
                            placeholder="Position/Title at Company or Organization"
                            type="text"
                            variant="outlined"
                            as={FormikInput}
                            className={clsx(classes.textField)}
                            // onChange={handleChange}
                            // onBlur={handleChange}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <div className={classes.footerBtn}>
                  <Button style={{ color: "#8a847b" }} onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(classes.circleBtn)}
                    type="submit"
                    // onClick={() => {
                    //   setpostBtn(false);
                    //   handlePost();
                    // }}
                    // disabled={disabledBtn}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Box>
      {addFundModal && (
        <AddFundModal
          openModal={addFundModal}
          setOpenModal={setAddFundModal}
          auth={props.auth}
          page={pageName}
        />
      )}
      {previewModal && (
        <AdPreview
          openDialog={previewModal}
          handleDialogClose={handlePreview}
          formData={initState}
          url={initState.link_to_your_file}
          tags={selectedTags}
        />
      )}
    </div>
  );
};
export default CreateAd;
