import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  Fragment,
} from "react";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import { Box, Grid, Modal, Paper, Typography,CircularProgress } from "@material-ui/core";
import ReactPlayer from "react-player";
import moment from "moment";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import CallMadeIcon from "@material-ui/icons/CallMade";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { Cancel } from "@material-ui/icons";
const limit = 12;
let totalCount = 0;

export default function   MediaVurbs(props) {

  const [mediaList, setmedia] = useState([]);
  const [loading, setLoader] = useState(true);
  const [skip, setSkip] = useState(0);
  const [model, setModel] = useState(false);
  const [slider, setSlider] = useState([]);
  const [sliderindex, setSliderIndex] = useState(null);
  const history = useHistory();
  const settings = {
    loop: false,
    infinite: false,
    autoplay: false,
    speed: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:sliderindex,
  };
  useEffect(() => {
    getUserVurb(0);
  }, []);
  const observer = useRef();
  const lastMedia = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (totalCount > mediaList.length) {
          setLoader(true);
          getUserVurb(skip + limit);
        }
      }
    });
    if (node) observer.current.observe(node);
  });
  const getUserVurb = (val) => {
    axios
      .get(
        API_Path +
          `api/vurb/user?user_id=${props.user._id}&limit=${limit}&skip=${val}`,
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          val === 0
            ? setmedia(response.data.result)
            : setmedia((p) => [...p, ...response.data.result]);
          totalCount = response.data.totalCount;
          setSkip(val);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const SliderImage = (data) => {
    var filterimage = mediaList.filter(function (el) {
      return el.image_url;
    });
    let index = filterimage.findIndex((x) => x.image_url === data);
    setSliderIndex(index)
    setTimeout(() => {
      setModel(true);
      sliderref && sliderref.current.slickGoTo(index);
    }, 1);
  
    setSlider(filterimage);
  };
  const redirectDetailPage = (post) => {
    history.push({
      pathname: "/vurbsDetail",
      state: { postObj: post },
    });
  };
  const modalOpen = Boolean(model);
  const sliderref = useRef();

  // console.log(sliderref&&sliderref.current.slickGoTo())
  return (
    <Grid container direction="row" style={{ justifyContent: "center", marginTop:"20px" }}>
      <Grid container className={props.classes.root} spacing={2}>
        {mediaList && mediaList.length > 0 ? (
          mediaList.map((list, index) => {
            return (
              <Fragment key={index}>
                {!list.image_url && !list.video_url ? (
                  ""
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      ref={mediaList.length - 1 === index ? lastMedia : null}
                    >
                      <Paper
                        className={props.classes.paper}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          rowGap: "4px",
                          height: "180px",
                        }}
                      >
                        {list.image_url && (
                          <img
                            src={list.image_url}
                            style={{
                              width: "160px",
                              height: "140px",
                              cursor: "pointer",
                              objectFit:"cover"
                            }}
                            onClick={() => SliderImage(list.image_url)}
                            alt=""
                          />
                        )}
                        {list.video_url && (
                          <ReactPlayer
                            url={list.video_url}
                            playing={false}
                            controls={true}
                            muted={false}
                            width="160px"
                            height="140px"
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "90%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              maxWidth: "100%",
                            }}
                          >
                            <CalendarTodayOutlinedIcon />
                            <span style={{ width: "100px" }}>
                              {" "}
                              {list.date &&
                                moment(list.date).format("DD MMM YYYY")}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              redirectDetailPage({ post: { _id: list.id } })
                            }
                          >
                            {/* <img alt="" src={CommentImg} />{" "} */}
                            <CallMadeIcon />
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    {loading && mediaList.length-1===index && <div style={{display:"flex", justifyContent:"center",width:"100%"}}><CircularProgress /></div>}
                  </>
                )}
              </Fragment>
            );
          })
        ) : (
          <Paper style={{ width: "400px", margin: "0 auto" }}>
            <Box p={3}>
              {mediaList.length === 0 && !loading ?(
                <Typography align="center" variant="body1">
                  {" "}
                  No Media Available{" "}
                </Typography>
              ):<div style={{display:"flex", justifyContent:"center"}}><CircularProgress /></div>}
            </Box>
          </Paper>
        )}
      </Grid>
      {/* {loading  && <Typography justifyContent='center' variant="body1" style={{textAlign: "center !important"}}><CircularProgress /></Typography>} */}
      <Modal open={modalOpen} style={{ border: "none" }}>
        <div className={props.classes.slider}>
          <Cancel
            className={props.classes.closeButton}
            style={{
              backgroundColor: "white",
              cursor: "pointer",
            }}
            onClick={() => setModel(false)}
          />

          <Slider
            {...settings}
            className={props.classes.ModelBackground}
            ref={sliderref}
          >
            {slider.map((image, i) => {
              return (
                <Fragment key={i}>
                  <img src={image.image_url} alt="" />
                </Fragment>
              );
            })}
          </Slider>
        </div>
      </Modal>
    </Grid>
  );
}
