let initialState = {
    isOpen:false,
};

export default (state = initialState, action) => {
    switch(action.type){
        case "AD_POPUP":
            return {
                ...state,
                isOpen: action.data.isOpen
            }
        case "AD_DETAILS":
            return {
                ...state,
                form:{...action.data}
            }
        case "AD_CLOSE":
            return initialState
        default:
            return state
    }
}

export const getAdPopupDetails = (state) => state.adDetails;
export const getAdFormDetails = (state) => {return state.adDetails.form? state.adDetails.form : null}