import { useEffect, useRef, useState } from 'react';
import socketIOClient from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
export default function useCommonVurbsSocket(props) {
    // const location_state=getDecryptedHashWithSecret(localStorage.getItem("location.state"));
    const roomId = props.roomName ? props.roomName : 'VurbsGlobal'
    const socketRef = useRef();
    const [vurb_like_data, SetData] = useState({})
    useEffect(() => {
        if (roomId) {
            socketRef.current = socketIOClient(SOCKET_SERVER_URL + "/", {
                query: {
                    roomId: roomId,
                    type: "vurbs-global",
                    loggedInUserId: props.user._id,
                },
            });
            socketRef.current.on('resetLikeAndDislkeCountSingle', function (data) {
                SetData(data)
            });
        }
        return () => {
            socketRef.current.disconnect();
        };
    }, [roomId]);
    const UpdateLike = (data) => {
        socketRef.current.emit('update_vurb_like_single', data);
    };
    return { UpdateLike, vurb_like_data };
}
