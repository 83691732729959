import { Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import StarIcon from "@material-ui/icons/Star";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import changePassword from "../../../assets/images/friendsProfile/changePassword.svg";
import suspendedAccount from "../../../assets/images/friendsProfile/suspendedAccount.svg";
import SnackBar from "../../../components/SnackBar";
import BoostDebate from "../../Common/BoostDebate";
import CustomPagination from "../../Common/CommonPagination";
import ConfirmationModal from "../../Common/ConfirmationModal";
import { API_Path } from "../../Common/Const/index";
import CustomDropdown from "../../Common/CustomDropdown/index";
import CustomSearch from "../../Common/CustomSearch";
import CustomUserDebateProfile from "../../Common/CustomUserDebateProfile";
import MemberStatus from "../../Common/MemberStatus";
import OtherActivePlans from "../../Common/OtherActivePlans";
import UpgradeButton from "../../Common/UpgradeButton";
import VurbsProfile from "./Vurbs";
import MediaVurbs from "./MediaVurbs";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: "46px 35px",
  },
  profileDetails: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
    "&.column-down-lg": {
      [theme.breakpoints.down("md")]: {
        flexFlow: "column",
      },
    },
  },
  slider: {
    " & .slick-prev:before": {
      fontSize: "50px",
      position: "absolute",
      right: "0%",
    },

    " & .slick-next:before": {
      fontSize: "50px",
    },
    " & .slick-track": {
      display: "flex",
      alignItems: "center",
    },
  },
  closeButton: {
    position: "absolute",
    top: "7%",
    left: "85%",
    cursor: "pointer",
    fontSize: "25px",
    color: "white",
    "& svg": {
      width: 20,
      height: 20,
      color: "white",
    },
    // color: theme.palette.grey[500],
  },

  listType1: {
    margin: "20px -5px",
    padding: "0",
    display: "flex",
    flexWrap: "wrap",
    // [theme.breakpoints.down('sm')]: {
    //     flexFlow: 'column'
    // },
    "& li": {
      padding: "0 5px",
      listStyleType: "none",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#313236",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: "20px",
      },
      "& .listIcon": {
        width: "36px",
        height: "36px",
        borderRadius: "50%",
        backgroundColor: "#ffc003",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          width: "20px",
          height: "20px",
          color: "#313236",
        },
      },
    },
  },
  badgeList: {
    margin: "20px 0",
    padding: "0",
    display: "flex",
    "& li": {
      listStyleType: "none",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#758194",
      marginRight: "5px",
      border: "1px solid #758194",
      borderRadius: "6px",
      padding: "5px 15px",
    },
  },
  tabsNav: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #cdd1d8",
    "& button": {
      padding: "0",
      minWidth: "inherit",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#758194",
      fontWeight: "600",
      fontFamily: "Poppins",
      marginRight: "42px",
      textTransform: "capitalize",
      "&.Mui-selected": {
        color: "#313236",
      },
    },
    "& .MuiTabs-indicator": {
      height: "4px",
    },
  },

  paper: {
    padding: theme.spacing(1),
    boxShadow: "none",
    border: "solid 1px #cdd1d8",
    borderRadius: "8px",
    height: "100%",
    "& > div": {
      "& [class*='makeStyles-root'], & > div:first-child": {
        width: "85px",
        flex: "0 0 85px",
        maxWidth: "85px",
        marginRight: "5px",
        textAlign: "center",
        height: "auto",
        "& img": {
          width: "60px",
          height: "60px",
        },
        "& .userStatus": {
          fontSize: "10px",
          lineHeight: "1.6",
          padding: "0 5px",
          whiteSpace: "nowrap",
        },
        "& .userRating": {
          margin: "0",
          "& svg": {
            width: "9px",
            height: "9px",
          },
        },
        "& span": {
          "& svg": {
            width: ".75em",
            height: ".75em",
          },
        },
      },
      "& button, & a": {
        fontSize: "10px",
        padding: "4px 10px",
        lineHeight: "1",
        minWidth: "inherit",
        borderRadius: "5px",
        boxShadow: "none",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        "&.btnwhite": {
          color: "#fff",
        },
        "&.statusFrientBtn": {
          padding: "7px 14px",
          minWidth: "102px",
          margin: "0",
          boxShadow: "none !important",
        },
      },
      "& .btnCenter": {
        textAlign: "center",
      },
      "& .unstringBtnShow": {
        position: "relative",
        "& .showOnHoverBtn": {
          backgroundColor: "#f57777!important",
        },
        "&:hover": {
          "& .hideOnHover": {
            opacity: "0",
            visibility: "hidden",
            position: "absolute",
            top: "0",
            left: "0",
          },
        },
        "&:not(:hover)": {
          "& .showOnHoverBtn": {
            opacity: "0",
            height: "0",
            visibility: "hidden",
            position: "absolute",
            left: "0",
          },
        },
      },
    },
  },
  chatBtn: {
    fontWeight: "500",
  },
  userRating: {
    margin: "0",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& svg": {
      color: "#ffc003",
    },
    "& .applyRating": {
      display: "none",
      position: "absolute",
      top: "-30px",
      left: "0",
      width: "154px",
      height: "29px",
      margin: "0 0 8px",
      padding: "8px 12px",
      borderRadius: "8px",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff",
      "&:after": {
        content: '""',
        width: "14px",
        height: "12px",
        margin: "25px 133px 0 7px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#ffffff",
        position: "absolute",
        left: "5px",
        bottom: "-5px",
        transform: "rotate(-45deg)",
        zIndex: "-1",
      },
    },
    "&:hover": {
      "& .applyRating": {
        display: "block",
      },
    },
  },
  userImageField: {
    flex: "0 0 170px",
    maxWidth: "170px",
    position: "relative",
    marginRight: "36px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "15px",
    },
  },
  editProfile: {
    color: "#61cbc9",
    fontWeight: "normal",
    margin: "0 0 0 5px",
    padding: "0",
    textTransform: "capitalize",
    minWidth: "auto",
  },
  stingBtns: {
    margin: "-5px",
    "& button, & a": {
      margin: "5px!important",
    },
    "& a": {
      lineHeight: "21px",
    },
  },
  classifyBtn: {
    margin: "0 -2px",
    "& button": {
      margin: "2px !important",
    },
  },
  capitalizeText: {
    textTransform: "lowercase",
    "&:first-letter, &:first-line": {
      textTransform: "capitalize",
    },
    "& span": {
      "&.text-uppercase": {
        textTransform: "uppercase",
      },
    },
  },
  ImgBack: {
    border: "15px solid white",
    width: "400px",
    height: "262px",
  },
  PopImg: {
    width: "674px",
    height: "423px",
    left: "450px",
    position: "absolute",
    top: "117px",
  },

  ModelBackground: {
    width: "60%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    "& img": {
      width: "100%",
      maxHeight: "700px",
    },
  },
}));

const options = [
  "",
  "All Members",
  "My Vurbers",
  "Invited",
  "Requested",
  "Blocked",
];

// const debateProfiles = [
//     {
//         header: 'My Debate',
//         value: '56',
//     },
//     {
//         header: 'Debate Won',
//         value: '1009',
//     },
//     {
//         header: 'Debate Attended',
//         value: '1689',
//     },
//     {
//         header: 'Debate Hours',
//         value: '5004',
//     },
//     {
//         header: 'Debate Views',
//         value: '2M +',
//     },
//     {
//         header: 'Debate Votes',
//         value: '4M +',
//     },
//     {
//         header: 'Debate Likes',
//         value: '10M +',
//     },
//     {
//         header: 'Debate Comments',
//         value: '4M +',
//     },
//     {
//         header: 'Debate Report Abuse',
//         value: '50',
//     },
//     {
//         header: 'Blocked Debate',
//         value: '2',
//     }
// ];

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "50px 0" }} className="boxPanel">
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Dashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [friendProfile] = React.useState(false);
  const [friendList, setfriendList] = React.useState(0);
  const [searchVal, setSearchVal] = React.useState("");
  const [type, setType] = React.useState("All Members");
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [rating, setRating] = useState(0);
  const [friendsStatus, setFriendsStatus] = useState(0);
  const [userDatas, setUserDatas] = useState([]);
  const [metrics, setmetrics] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [debateTotalCount, setDebateTotalCount] = useState(0);
  const [page, setPage] = React.useState(1);
  const pageTotalCount = Math.ceil(debateTotalCount / 10);
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const redirectTo = params.get("redirectTo");
  const handlePaginationChange = (event, value) => {
    setPage(value);
    friendListDatas(searchVal, type, value);
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDeleteClickOpen = () => {
    setOpenDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleMenuItemClick = (event, index, val) => {
    setType(val);
    setSelectedIndex(index);
    friendListDatas(searchVal, val);
  };

  React.useEffect(() => {
    let urlType = "";
    if (localStorage["handleDashboard"]) {
      if (localStorage["handleDashboard"] === "friendgroupprofile") {
        urlType = "My Vurbers";
        setSelectedIndex(2);
      }
    }

    friendListDatas(searchVal, urlType || type);
    if (redirectTo === "FriendsGroups") {
      setValue(1);
    }
  }, [friendsStatus, redirectTo]);

  React.useEffect(() => {
    if (props && props.user._id) {
      getUserViewdata(props.user._id);
    }
    handleProfile();
  }, []);

  // React.useEffect(() => {

  //     if (props && props.history.location && props.history.location.state.name && props.history.location.state.name === "debateprofile") {
  //         setValue(0)

  //     }
  //     if (props && props.history.location && props.history.location.state.name && props.history.location.state.name === "friendgroupprofile") {
  //         setValue(1)
  //     }
  // }, [props.history.location.state.name]);

  React.useEffect(() => {
    if (localStorage["handleDashboard"]) {
      if (localStorage["handleDashboard"] === "debateprofile") {
        setValue(0);
      } else if (localStorage["handleDashboard"] === "friendgroupprofile") {
        setValue(1);
      }
      // localStorage.getItem("handleDashboard");
      localStorage.removeItem("handleDashboard");
    }
  }, [localStorage["handleDashboard"]]);

  const handleSearch = (event) => {
    setSearchVal(event.target.value);
    getUserViewdata(props.user._id);
    friendListDatas(event.target.value, type);
    if (event.target.value === "") {
      setPage(1);
    }
  };

  const friendListDatas = (searchText, type, currentPageVal) => {
    let friendListAPI = API_Path + "api/friends/search";
    if (searchText.length >= 3 || searchText === "") {
      let inputObj = {
        inputObject: {
          type: type,
          userId: props.user._id,
          searchText: searchText,
          pagination: {
            currentpage: currentPageVal ? currentPageVal : 1,
            recordsperpage: 10,
          },
        },
      };
      axios
        .post(friendListAPI, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          setfriendList(response.data.result.data);
          setDebateTotalCount(response.data.result.totalCount);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleFriend = (lst) => {
    const { _id, friendStatus } = lst ? lst : [];
    let friendListAPI = API_Path + "api/friends/sting";
    let statusAction = false;

    {
      friendStatus === "Already Friends" || friendStatus === "Invited"
        ? (statusAction = false)
        : (statusAction = true);
    }
    // if (friends && friends.length > 0) {
    //     friends[0].status === 1 || friends[0].status === 0 ? statusAction = false : statusAction = true
    // } else {
    //     statusAction = true
    // }
    var inputObj = {
      inputObject: {
        userId: props.user._id,
        friendId: _id,
        sting: statusAction,
      },
    };
    axios
      .post(friendListAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        friendListDatas(searchVal, type);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const acceptDeclineFriendsRequest = (adRequest, list) => {
    let statusAction = false;
    setFriendsStatus(list);
    if (adRequest === "Accept") {
      statusAction = true;
    } else {
      statusAction = false;
    }
    let adFriendsRequestAPI = API_Path + "api/friends/approve";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
        friendId: list._id,
        approve: statusAction,
      },
    };
    axios
      .post(adFriendsRequestAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        const newFriendList = friendList.map((v) => {
          if (v.id === list._id) {
            v.friendStatus = statusAction ? "Already Friends" : "Sting Friend";
            return v;
          }
          return v;
        });
        setFriendsStatus(newFriendList);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const handleDropDown = (selectType) => {
  //     friendListDatas(searchVal, selectType)
  // }

  const getUserViewdata = (id) => {
    axios
      .get(API_Path + `api/auth/user/${id}`, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setUserDatas(response.data.result);
        setRating(response.data.result.rating);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleBlockedUser = (lst) => {
    let BlockedUserAPI = API_Path + "api/user/updateUserBlockStatus";
    var inputObj = {
      inputObject: {
        fromUserId: props.user._id,
        toUserId: lst._id,
        status: "unblock",
      },
    };
    axios
      .post(BlockedUserAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        getUserViewdata(props.user._id);
        friendListDatas(searchVal, type);
        setSnackbarState({
          open: true,
          message: response.data.message,
          severity: "success",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSuspendedAccount = async () => {
    var DebatesDetails = API_Path + "api/auth/suspendUser";
    let inputObj = {
      email: props.user.email,
    };
    await axios
      .post(DebatesDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setSnackbarState({
          open: true,
          message: "Accounts Deactivated successfully",
          severity: "success",
        });
        setTimeout(() => {
          dispatch({
            type: "LOGOUT",
            data: {},
          });
          history.push("/signin");
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDeleteAccount = async () => {
    var DebatesDetails = API_Path + "user/delete/" + props.user._id;

    await axios
      .delete(DebatesDetails, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setSnackbarState({
          open: true,
          message: "Accounts Deleted successfully",
          severity: "success",
        });
        setTimeout(() => {
          dispatch({
            type: "LOGOUT",
            data: {},
          });
          history.push("/signin");
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleProfile = () => {
    let myDebateView = API_Path + "api/auth/debateProfile";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
      },
    };
    axios
      .post(myDebateView, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setmetrics(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  return (
    <div className={classes.pageContainer}>
      <Box className={classes.profileDetails} paddingBottom="30px">
        <div className={classes.userImageField}>
          <MemberStatus list={userDatas} uploadDisable={false} />
          <h5
            style={{
              textAlign: "center",
              fontWeight: "500",
              margin: "5px 0 0 0",
              width: "136px",
            }}
          >
            Followers: {userDatas && userDatas.followerCount}
          </h5>
          <div style={{ marginTop: "10px" }}>
            <BoostDebate showIcons={true} />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            className={classes.flexJCSB + " column-down-lg"}
            style={{ width: "100%" }}
          >
            <div>
              <Box display="flex" alignItems="center">
                <h4 className={classes.capitalizeText} style={{ margin: "0" }}>
                  {userDatas && userDatas.full_name}
                </h4>
                <h6 className={classes.userRating}>
                  <StarIcon /> {rating}
                </h6>
                {/* <h5 style={{ color: '#61cbc9', fontWeight: 'normal', margin: '0 0 0 5px' }}>Edit</h5> */}
                <Button
                  size="small"
                  component={Link}
                  to={"/accountProfile"}
                  className={classes.editProfile}
                >
                  Edit
                </Button>
              </Box>
              {userDatas && userDatas.user_tagging && (
                <h6
                  style={{ margin: "0", color: "#758194", fontWeight: "700" }}
                >
                  @ {userDatas.user_tagging}
                </h6>
              )}
              <h6
                style={{ margin: "0", color: "#758194", fontWeight: "normal" }}
              >
                Member since{" "}
                {userDatas && moment(userDatas.created_at).format("MMM YYYY")}
              </h6>

              {userDatas && userDatas.full_address && (
                <h6
                  className={classes.capitalizeText}
                  style={{
                    margin: "0",
                    color: "#758194",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    {userDatas.full_address.city &&
                      userDatas.full_address.city + ", "}
                  </span>
                  <span
                    className={`${
                      userDatas.full_address.state.length <= 3
                        ? "text-uppercase"
                        : ""
                    }`}
                  >
                    {userDatas.full_address.state &&
                      userDatas.full_address.state + ", "}
                  </span>
                  <span>
                    {userDatas.full_address.country &&
                      userDatas.full_address.country}
                  </span>
                </h6>
              )}
            </div>
            <Box className={classes.stingBtns}>
              {/* <Button variant="contained" color="primary" component={Link} to="/payment" className={classes.upgradeBtn}><img src={upgrade} alt="" style={{ marginRight: '5px' }} /> Upgrade</Button> */}
              {/* <Button variant="outlined"><img src={otherActivePlans} alt="" style={{ marginRight: '5px' }} /> Other Active Plans</Button> */}
              <UpgradeButton />
              <OtherActivePlans />
              <Button variant="outlined" component={Link} to="/changePassword">
                <img
                  src={changePassword}
                  alt=""
                  style={{ marginRight: "5px" }}
                />{" "}
                Change Password
              </Button>
              <Button variant="outlined" onClick={handleClickOpen}>
                <img
                  src={suspendedAccount}
                  alt=""
                  style={{ marginRight: "5px" }}
                />{" "}
                Deactivate Account
              </Button>
              <Button variant="outlined" onClick={handleDeleteClickOpen}>
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
                Account
              </Button>
            </Box>
          </div>

          <ul className={classes.listType1}>
            <li>
              <span className="listIcon">
                <EmailOutlinedIcon />
              </span>
              {userDatas && userDatas.email}
            </li>
            {userDatas && userDatas.mobile && (
              <li>
                <span className="listIcon">
                  <CallOutlinedIcon />
                </span>{" "}
                {userDatas && userDatas.mobile}
              </li>
            )}

            {/* <li><span className="listIcon"><ScheduleOutlinedIcon /></span> UTC 05:30</li> */}
          </ul>

          <ul className={classes.badgeList}>
            {userDatas && userDatas.tags && userDatas.tags.length > 0
              ? userDatas.tags.map((list, index) => {
                  return <li key={index}>{list.name}</li>;
                })
              : " "}
          </ul>
        </div>
      </Box>

      <AppBar position="static" className={classes.tabsNav}>
        <Tabs
          value={value}
          indicatorColor="primary"
          onChange={handleChange}
          // onClick={getUserImg}
          aria-label="disabled tabs example"
        >
          <Tab label="Debate Profile" {...a11yProps(0)} />
          {friendProfile === false ? (
            <Tab label="My Vurbers" {...a11yProps(1)} /> //label="Friends"
          ) : (
            ""
          )}
          <Tab label="My Bio" {...a11yProps(2)} />
          <Tab label="My Vurbs" {...a11yProps(3)} />
          <Tab label="My Media" {...a11yProps(4)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <CustomUserDebateProfile debateProfiles={metrics} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid
          container
          spacing={2}
          style={{ position: "relative", top: "-25px" }}
        >
          <Grid item xs={12} md={6}>
            <CustomSearch handleSearch={handleSearch} />
            {/* <TextField id="filled-basic" placeholder="Search" variant="filled" onChange={handleSearch} /> */}
          </Grid>
          <Grid container item xs={12} md={6} justify="flex-end">
            <CustomDropdown
              options={options}
              selectedIndex={selectedIndex}
              handleMenuItemClick={handleMenuItemClick}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.root} spacing={2}>
          {friendList && friendList.length > 0 ? (
            friendList.map((list, index) => {
              const blockedUser =
                list &&
                list.blocked_users &&
                list.blocked_users.includes(props.user._id);
              const blockedByMe =
                userDatas &&
                userDatas.blocked_users &&
                userDatas.blocked_users.includes(list._id);
              return (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <Paper className={classes.paper}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }}
                      textAlign={{ xs: "center", md: "left" }}
                      alignItems={{ xs: "center", md: "" }}
                      width="100%"
                      className={classes.smallUserStatus}
                    >
                      <MemberStatus
                        list={list}
                        editIconShow={false}
                        ratingED={true}
                        uploadDisable={false}
                        blockedUser={blockedUser}
                      />
                      <div style={{ width: "100%", overflow: "hidden" }}>
                        <Box
                          flexDirection={{ xs: "column", md: "row" }}
                          className={classes.flexJCSB}
                          style={{ marginBottom: "8px" }}
                        >
                          <Box
                            width={{
                              xs: "100%",
                              md: `${
                                blockedUser !== true
                                  ? "calc(100% - 102px)"
                                  : "100%"
                              }`,
                            }}
                            mb={{ xs: 2, md: 0 }}
                          >
                            <h5
                              style={{
                                margin: "0",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {list.first_name + " " + list.last_name}
                            </h5>
                            {list && list.user_tagging && (
                              <h6
                                style={{
                                  margin: "0",
                                  color: "#758194",
                                  fontWeight: "700",
                                }}
                              >
                                @ {list.user_tagging}
                              </h6>
                            )}

                            {blockedUser === true && (
                              <Button
                                size="small"
                                component={Link}
                                to={`/friendsProfilePage/${list && list._id}`}
                                target="_blank"
                                style={{ paddingLeft: 0 }}
                              >
                                View profile
                              </Button>
                            )}
                          </Box>
                          {blockedUser !== true && (
                            <div
                              style={{ display: "flex", flexFlow: "column" }}
                            >
                              {blockedByMe ? (
                                <>
                                  <Button
                                    variant="outlined"
                                    color="inherit"
                                    size="small"
                                    onClick={handleBlockedUser.bind(this, list)}
                                  >
                                    Unblock
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {list.friendStatus !== "Accept" ? (
                                    <div
                                      className={
                                        list.friendStatus === "Invited" ||
                                        list.friendStatus === "Already Friends"
                                          ? "unstringBtnShow btnCenter"
                                          : "btnCenter"
                                      }
                                    >
                                      <Button
                                        variant={
                                          list.friendStatus === "Sting"
                                            ? "outlined"
                                            : "contained"
                                        }
                                        color={
                                          list.friendStatus === "Sting"
                                            ? "inherit"
                                            : "secondary"
                                        }
                                        size="small"
                                        className={
                                          list.friendStatus === "Sting"
                                            ? "statusFrientBtn hideOnHover"
                                            : "statusFrientBtn btnwhite hideOnHover"
                                        }
                                        onClick={handleFriend.bind(this, list)}
                                      >
                                        {list.friendStatus === "Invited"
                                          ? "Invited"
                                          : list.friendStatus ===
                                            "Already Friends"
                                          ? "Already Friends"
                                          : list.friendStatus === "Sting"
                                          ? "Sting Friend"
                                          : ""}
                                      </Button>
                                      {list.friendStatus === "Invited" ||
                                      list.friendStatus ===
                                        "Already Friends" ? (
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          size="small"
                                          className="statusFrientBtn btnwhite showOnHoverBtn"
                                          onClick={handleFriend.bind(
                                            this,
                                            list
                                          )}
                                        >
                                          Unsting Friend
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}

                              <Button
                                size="small"
                                component={Link}
                                to={`/friendsProfilePage/${list && list._id}`}
                                target="_blank"
                              >
                                View profile
                              </Button>
                            </div>
                          )}
                        </Box>

                        {/* {list.category_id.map((category) => {
                                                    return (
                                                        <Button variant="outlined" size="small">{category}</Button>
                                                    )
                                                })} */}

                        {blockedUser !== true && (
                          <>
                            <div className={classes.classifyBtn}>
                              {list &&
                                list.tags &&
                                list.tags.length > 0 &&
                                list.tags !== null &&
                                list.tags.map((tag, index) => {
                                  return (
                                    <Button
                                      key={index}
                                      variant="outlined"
                                      size="small"
                                    >
                                      {tag.name}
                                    </Button>
                                  );
                                })}
                            </div>

                            {!blockedByMe && list.friendStatus === "Accept" ? (
                              <Box marginTop="10px">
                                <h6
                                  className="m-0"
                                  style={{
                                    fontWeight: "500",
                                    lineHeight: "1",
                                    fontSize: "11px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Would you like to add{" "}
                                  {list.first_name + " " + list.last_name}?
                                </h6>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={acceptDeclineFriendsRequest.bind(
                                    this,
                                    "Decline",
                                    list
                                  )}
                                >
                                  {"Decline"}
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                  onClick={acceptDeclineFriendsRequest.bind(
                                    this,
                                    "Accept",
                                    list
                                  )}
                                >
                                  {"Accept"}
                                </Button>
                              </Box>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </Box>
                  </Paper>
                </Grid>
              );
            })
          ) : (
            <Box mt={3} width="100%">
              <Typography align="center" variant="h6">
                No Vurbers Available
              </Typography>
            </Box>
          )}
          <Grid item xs={12}>
            <CustomPagination
              page={page}
              count={pageTotalCount}
              handleChange={handlePaginationChange}
              JusCont="flex-end"
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container direction="row" style={{ justifyContent: "center" }}>
          <Grid item xs={6}>
            <Paper>
              <Box p={3}>
                <Typography align="center" variant="body1">
                  {userDatas && userDatas.user_bio !== ""
                    ? userDatas.user_bio
                    : "No bio Available"}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <VurbsProfile auth={props.auth} user={props.user} hashed={props.hashed} active={value} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <MediaVurbs classes={classes} auth={props.auth} user={props.user} />
      </TabPanel>
      <ConfirmationModal
        openDialog={openDialog}
        modalHeading="Message"
        handleClickOpen={handleClickOpen}
        handleDialogClose={handleDialogClose}
        handleDelete={handleSuspendedAccount}
        modalMessage="Are you sure, you want to deactivate your account?"
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
      <ConfirmationModal
        openDialog={openDeleteDialog}
        modalHeading="Message"
        handleClickOpen={handleDeleteClickOpen}
        handleDialogClose={handleDeleteDialogClose}
        handleDelete={handleDeleteAccount}
        modalMessage="Are you sure, you want to delete your account? Doing so will erase all your data, debates, stats, chats, etc."
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </div>
  );
};
const mapStateToProps = ({ loginDetails: { user, auth, hashed } }) => {
  return {
    user,
    auth,
    hashed
  };
};
export default withRouter(connect(mapStateToProps)(Dashboard));
