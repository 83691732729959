import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomImage from "../CustomImage";
import LikeDislikeReply from "./LikeDislikeReply";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  commentSection: {
    marginTop: "20px",
  },
  subCommentAccordion: {
    backgroundColor: "#fff",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      margin: "0 7% 0 8%!important",
    },
    "&:before": {
      opacity: "0",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      justifyContent: "flex-start",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      flexGrow: "inherit",
      "& .hideAll": {
        display: "none",
      },
      "&.Mui-expanded": {
        "& .hideAll": {
          display: "block",
        },
        "& .viewAll": {
          display: "none",
        },
      },
      "& p": {
        color: "#758194",
        fontSize: "12px",
      },
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0",
    },
  },
  subCommentSection: {
    backgroundColor: "#f6f7f8",
    padding: "16px 20px",
    borderRadius: "8px",
    width: "100%",
  },
  comments: {
    "&.mainComments, &.subComments": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexFlow: "column",
      },
    },
    "&.subComments": {
      "& + &": {
        marginTop: 5,
        paddingTop: 15,
        borderTop: "solid 1px #e2e2e2;",
      },
    },
    "& .mainCommentsContainer": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "20px",
        width: "100%",
      },
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    marginBottom: theme.spacing(3),
    maxHeight: theme.spacing(6),
    "& .MuiInputBase-input": {
      padding: "14px 0",
      fontSize: "12px",
      color: "#758194",
    },
  },
  activeStatus: {
    position: "absolute",
    right: "4px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "18px",
    },
  },
}));
const ChildComments = (props) => {
  const classes = useStyles();
  // const location_state = getDecryptedHashWithSecret(localStorage.getItem("location.state"));
  const like =
    props.childObj &&
    props.childObj.like_users !== null &&
    props.childObj.like_users.length > 0
      ? props.childObj.like_users.length
      : "0";
  const disLike =
    props.childObj &&
    props.childObj.dislike_users !== null &&
    props.childObj.dislike_users.length > 0
      ? props.childObj.dislike_users.length
      : "0";
  // const [commentDuration, setCommentDuration] = useState(0);

  //var historyDebateObj = location_state.debateObj;
  // const getTimeDifference = () => {
  //     let newDate = new Date(props.childObj.created_at);
  //     let eventDate = historyDebateObj && moment([newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newDate.getHours(), newDate.getMinutes()]).fromNow();
  //     setCommentDuration(eventDate)
  // }

  // useEffect(() => {
  //     getTimeDifference();
  //     setInterval(() => getTimeDifference(), 1000);
  // }, []);
  let commentDuration = "";
  if (props.childObj && props.childObj.created_at) {
    let newDate = new Date(props.childObj.created_at);
    let eventDate = moment([
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
    ]).fromNow();
    commentDuration = eventDate;
  }
  return (
    <div className={classes.comments + " subComments"}>
      {/* <div style={{ width: '40px', height: '40px', flex: '0 0 40px', maxWidth: '40px', borderRadius: '50%', overflow: 'hidden' }}>
                <img alt="" src={props.childObj.created_by.full_name && props.childObj.created_by.profile_image} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div> */}
      <div style={{ position: "relative" }}>
        <CustomImage
          userImage={
            props.childObj.created_by.full_name &&
            props.childObj.created_by.profile_image
              ? props.childObj.created_by.full_name &&
                props.childObj.created_by.profile_image
              : ""
          }
          size="40px"
          marginRight="0"
        />
        {props.childObj &&
          props.childObj.created_by &&
          !!props.childObj.created_by.badge_status && (
            <span
              className={classes.activeStatus}
              title="Verified User/Public Figure"
            >
              <VerifiedUserRoundedIcon />
            </span>
          )}
      </div>
      <div className=" mainCommentsContainer">
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
          <h5 className="m-0">
            {props.childObj.created_by.full_name &&
              props.childObj.created_by.full_name}
          </h5>
          {props.childObj.created_by.user_tagging && (
          <span
                  style={{ fontWeight: "500", paddingLeft: "4px" }}
                  className="userTagging"
                >
                  @{_.get(props.childObj, "created_by.user_tagging", "")}
                </span>
              )}
          </div>
          <h6
            className="m-0"
            style={{
              whiteSpace: "nowrap",
              fontWeight: "normal",
              color: "#758194",
              marginLeft: "auto",
            }}
          >
            {/* {props.childObj && props.childObj !== null ? props.childObj.created_at : ""} */}
            {commentDuration}
          </h6>
          {/* <img alt="" src={hMenu} style={{ marginLeft: '18px' }} /> */}
        </div>
        <p
          className="m-0"
          style={{ fontSize: "14px", color: "#313236", wordBreak: "break-all" }}
        >
          {props.commentObj && props.commentObj.is_blocked === 1 ? (
            <span>***</span>
          ) : (
            props.childObj.comments && props.childObj.comments
          )}
        </p>
        <LikeDislikeReply
          commentId={props.childObj._id}
          like={like}
          disLike={disLike}
          reloadComments={props.reloadComments}
          childRating={true}
          commentCreatedById={
            props.childObj &&
            props.childObj.created_by &&
            props.childObj.created_by._id
          }
        />
      </div>
    </div>
  );
};

// export default ChildCommentList;
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(ChildComments));
