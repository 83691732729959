import axios from "axios";
import $ from "jquery";
import moment from "moment";
import docmenticon from "../../../assets/images/docment.png";
import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { API_Path, generateEncryptedHashWithSecret, getDecryptedHashWithSecret } from "../../Common/Const/index";
import defaultavatar from "../../../assets/images/default-avatar.png";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
const useChatSocket = (props) => {
  let roomId = props.roomName;
  if (props.chatType !== "friends" && props.chatType !== "support") {
    const location_state = getDecryptedHashWithSecret(
      localStorage.getItem("location.state")
    );
    roomId = props.roomName
      ? props.roomName
      : location_state.roomName
        ? location_state.roomName
        : null;
  }
  const [messageObj, setMessageObj] = useState(null);
  const [chatters] = useState([]);
  const [enableChat, setEnableChat] = useState(null);
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId: roomId, type: "Chat" },
    });
    socketRef.current.on("send", function (data) {
      if (process.env.REACT_APP_CHAT_ENCRYPT_DECRYPT_ENABLE === 'ON') {
        data = getDecryptedHashWithSecret(data);
      }
      let profileImg = data.imgURL && data.imgURL ? data.imgURL : defaultavatar;
      setEnableChat(true);
      const image = data.attachmentLinks?data.attachmentLinks:[];
      let text = "";
      for (let i = 0; i < image.length; i++) {
        if (data.messageType === "video") {
          text += " <div  className='txt' ><a href=" + image[i] + " target='_blank'><video className='react-player' src=" + image[i] + " width='50%' height='50%' controls></video></a></div>"
        } else if (data.messageType === "Doc") {
          let docName = image[i].split('__')[image[i].split('__').length - 1]
          text += `<a href="${image[i]}" target='_blank'><img width='40px' height='40px' src= ${docmenticon}   /></a><div text-align='center' style='font-size: 12px;'>${docName}</div><br>`;
        } else {
          text += `<a href="${image[i]}" target='_blank'><img width='123px' height='106px' src=${image[i]} /></a><br>`;
        }
      }
      let message = "";
      if (!(data.message === '')) {
        message += "<p className='txt'>" + data.message + "</p>";
      }
      var classname = "sender";
      if (props.user._id === data.fromUserId) {
        classname = "receiver";
      }
      let newDate = new Date(data.timestamp);
      let eventDate = moment([
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newDate.getHours(),
        newDate.getMinutes(),
      ]).fromNow();
      var html =
        "<div class='chatSection flexRow msg " +
        classname +
        "'> <div class='chatImage'> <img alt='' src=" +
        profileImg +
        " /> </div><div class='chatContainer'> <div class='flexRow'> <h5 class='user'>" +
        data.username +
        "</h5> <h6>" +
        eventDate +
        "</h6> </div>" +
        text +
        message +
        "</div></div>";
      $(".messages").append(html);
      setMessageObj(data);
      if (props.user._id !== data.fromUserId) {
        // $(".messages").animate({ scrollTop: $(document).height() + $(window).height() });
        var wtf = $(".messages");
        var height = wtf[0].scrollHeight;
        wtf.scrollTop(height);
        updateChatReadStatus(data);
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);

  const updateChatReadStatus = (obj) => {
    var fromUserId = obj.fromUserId;
    var toUserId = props.user._id;
    let inputObj = {
      inputObject: {
        fromUserId: fromUserId,
        toUserId: toUserId,
      },
    };
    axios
      .post(API_Path + "api/auth/updateChatReadStatus", inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => { })
      .catch((error) => {
        console.error(error);
      });
  };
  const get_reload_chatters = (data) => {
    socketRef.current.emit("get_reload_chatters", data);
  };
  const send_text_message = (data) => {
    if (process.env.REACT_APP_CHAT_ENCRYPT_DECRYPT_ENABLE === 'ON') {
      data = generateEncryptedHashWithSecret(data)
    }
    socketRef.current.emit("send_text_message", data);
  };
  return {
    enableChat,
    messageObj,
    chatters,
    get_reload_chatters,
    send_text_message,
  };
};

export default useChatSocket;
