import { Box, TextField, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter,useHistory } from "react-router-dom";
import chatHeader from "../../../assets/images/friendsProfile/chatHeader.png";
import { API_Path } from "../../Common/Const/index";
import useFriendListTrackingSocket from "../../Common/Sockets/useFriendListTrackingSocket";
import GetFriendList from "./GetFriendList";

const useStyles = makeStyles((theme) => ({
  friendsChat: {
    position: "fixed",
    right: "15px",
    bottom: "68px",
    width: "350px",
    // height: '410px',
    height: "62vh",
    backgroundColor: "#fff",
    boxShadow: "-3px 9px 18px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "6px 6px 0 0",
    zIndex: 999,
    "& .friendsChatHeader": {
      padding: "18px 12px",
      backgroundColor: "#ffc003",
      color: "#fff",
      fontSize: "18px",
      display: "flex",
      alignItems: "center",
      borderRadius: "6px 6px 0 0",
    },
    "& .friendsChatContent": {
      padding: "0 18px 18px",
      overflow: "auto",
      "& .chatSection": {
        marginBottom: "10px",
        "& .chatImage": {
          flex: "0 0 34px",
          maxWidth: "34px",
          width: "34px",
          height: "34px",
        },
        "& h5, & h6, & .MuiSvgIcon-root": {
          display: "none",
        },
        "& p": {
          fontSize: "12px",
          color: "#121213",
          backgroundColor: "#dce7ff",
          padding: "6px 10px",
          borderRadius: "20px",
          minWidth: "60px",
          position: "relative",
          "&:after": {
            content: '""',
            position: "absolute",
            bottom: "-5px",
            left: "8px",
            backgroundColor: "#dce7ff",
            width: "10px",
            height: "10px",
            borderRadius: "0px 0px 10px 0px",
            transform: "rotate(15deg)",
          },
        },
        "&.sender": {
          display: "flex",
          flexFlow: "row-reverse",
          "& .chatImage": {
            margin: "0 0 0 12px",
          },
          "& p": {
            // backgroundColor: '#ffc003',
            // color: '#fff',
            "&:after": {
              left: "auto",
              right: 8,
              borderRadius: "0px 0px 0px 10px",
              // backgroundColor: '#ffc003',
              transform: "rotate(-15deg)",
            },
          },
        },
      },
      "& .MuiOutlinedInput-multiline": {
        minHeight: "36px",
        backgroundColor: "#f6f7f8",
        border: "none",
        boxShadow: "none",
        "& .MuiOutlinedInput-inputMultiline": {
          fontSize: "10px",
          color: "#758194",
        },
      },
      "& .chatSend": {
        "& img": {
          width: "18px",
        },
      },
      "& .hideEmoji": {
        height: "0",
      },
    },
  },
  activeColor: {
    color: "#4dd965",
  },
  inActiveColor: {
    color: "#b9b9b9",
  },
  textField: {
    margin: theme.spacing(2),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
  },

  textFieldAutocomplete: {
    width: '322px',
    maxHeight: "inherit",
    minHeight: "inherit",
    "& .MuiAutocomplete-inputRoot": {
      padding: "0",
      backgroundColor: "#fff",
      maxHeight: "inherit",
    },
  },
}));

const ChatFriendsList = (props) => {
  const source = axios.CancelToken.source();
  const classes = useStyles();
  const [friendList, setFriendList] = React.useState([]);
  //   const [enableChatUserId, setEnableChatUserId] = React.useState(null);
  const [enableChatUserObj, setEnableChatUserObj] = React.useState(null);

  const { resetUserOnlineFriendList } = useFriendListTrackingSocket(props);
  const [loader, Setloader] = React.useState(true);
  const [friendsFiltered, setFriendsFiltered] = React.useState(false);
  const history = useHistory();
  useEffect(() => {
    friendListDatas();
    return () => {
      source.cancel();
    };
  }, []);
  useEffect(() => {
    if (!friendsFiltered) {
      setFriendList(resetUserOnlineFriendList);
    }
  }, [resetUserOnlineFriendList]);
  // Notification Friend Chat Open
  useEffect(() => {
    if (props.chatId) {
      setEnableChatUserObj({ _id: props.chatId });
      history.replace({
        search: '',
      })
    }
  }, [props.chatId])
  const friendListDatas = () => {
    const friendListAPI = API_Path + "api/auth/getChatFriendList";
    if (props.user && props.user._id) {
      let inputObj = {
        inputObject: {
          userId: props.user._id,
        },
      };
      axios
        .post(friendListAPI, inputObj, {
          cancelToken: source.token,
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          setFriendList(response.data.result);
          Setloader(false);
        })
        .catch((error) => {
          if (error.message) {
            console.error(error);
          }
          Setloader(false);
        });
    }
  };

  const enableFriendsChat = (friendId, isClose) => {
    if (isClose !== null && isClose === true) {
      setEnableChatUserObj(null);
    } else {
      setEnableChatUserObj(friendId);
    }
  };
  const handleFriendsList = (value) => {
    if (value !== null && value !== undefined) {
      setFriendList([value]);
      setFriendsFiltered(true);
    } else {
      friendListDatas();
      setFriendsFiltered(false);
    }
  };
  // useEffect(() => {
  //     if (friendList.length === 0)
  //         friendListDatas()
  // }, [friendList]);

  return (
    <Box className={classes.friendsChat} display="flex" flexDirection="column">
      <Box
        className="friendsChatHeader"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={chatHeader} alt="" style={{ marginRight: "10px" }} />
          <span>Vurbil Chat</span>
        </div>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={props.closeChatWindow}
        />
      </Box>
      <Box display="flex" flexDirection="column" height="calc(100% - 75px)">
        {loader && (
          <div style={{ textAlign: "center", margin: "auto" }}>
            <CircularProgress />
          </div>
        )}
        {!loader && friendList && friendList.length > 0 ? (
          <>
            {/* <TextField id="outlined-basic" label="" variant="outlined" placeholder="Search for friend" onChange={handleFriendsList} /> */}
            <Autocomplete
              freeSolo={false}
              popupIcon={""}
              id="combo-box-demo"
              options={friendList}
              getOptionLabel={(option) => option.full_name}
              onChange={(e, value) => handleFriendsList(value)}
              fullWidth
              noOptionsText={"No Vurbers"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search for a Vurber"
                  variant="outlined"
                  className={clsx(
                    classes.textField,
                    classes.textFieldAutocomplete
                  )}
                />
              )}
            />
            <Box className="friendsChatContent">
              {friendList.map((friendObj, index) => {
                return (
                  <GetFriendList
                    key={index}
                    list={friendObj}
                    isChat={
                      enableChatUserObj !== null &&
                        friendObj._id === enableChatUserObj._id &&
                        friendObj.isSupport === enableChatUserObj.isSupport
                        ? true
                        : false
                    }
                    enableFriendsChat={enableFriendsChat}
                  />
                );
              })}
            </Box>
          </>
        ) : (
          <Box textAlign="center">
            {!loader && (
              <Typography variant="p" component="p">
                No Vurbers Added Yet
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(ChatFriendsList));
