import React, { Fragment, useEffect, useState,useCallback,useRef } from "react";
import Slider from "react-slick";
import { Cancel } from "@material-ui/icons";
import { Modal, makeStyles, Paper, Box, Typography, CircularProgress } from "@material-ui/core";
// import Unlockicon from "../../../assets/images/unlock.png";
import CustomImage from "../CustomImage";
import DebateDMY from "../CustomDebateHeaderComponents/DebateDMY";
import Thumbs from "../../../assets/images/thumbs.svg";
import CommentImg from "../../../assets/images/comment.svg";
import LikeUserCompoent from "./LikeDisLikeUsers";
import _ from "lodash";
import ThumbsYellow from "../../../assets/images/thumbs-yellow.svg";
import VurbCommentList from "../VurbComment/VurbCommentList";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import Lockicon from "../../../assets/images/lock.png";
import CommentEditor from "../VurbComment/CommentEditor";
import { API_Path } from "../../Common/Const/index";
import axios from "axios";
import SnackBar from "../../../components/SnackBar";
import { numberFormatter } from "../../Common/Const/index";
import publicIcon from "../../../assets/images/public.svg"
// import Loader from "../Loader";
const useStyles = makeStyles((theme) => ({
  chatText: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "auto",
    "& .commentEditor": {
      width: "100%",
      marginTop: "0",
      minHeight: "44px",
      borderRadius: "28px",
      border: "1px solid #a4a7ad",
      backgroundColor: "#fff",
      color: "#758194",
      fontSize: "14px",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      "& .quill": {
        border: "none",
      },
    },
  },
  slider: {
    " & .slick-prev:before": {
      fontSize: "50px",
      position: "absolute",
      right: "0%",
    },

    " & .slick-next:before": {
      fontSize: "50px",
    },
    " & .slick-track": {
      display: "flex",
      alignItems: "center",
    },
  },
  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "4px",
    borderRadius: "14px",
    fontSize: "30px",
    right: "0px",
  },
  ModelBackground: {
    width: "60%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    "& img": {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "63%",
    },
  },
  commentList: {
    width: "440px",
    padding: "10px",
    height: "100%",
    backgroundColor: "white",
  },
  profile: {
    display: "flex",
    borderBottom: "solid 1px #cdd1d8",
    height: "70px",
  },
  activeStatus: {
    position: "absolute",
    right: "0px",
    top: "-6px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "18px",
    },
  },
  SubHeader: {
    marginTop: "-3px",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#758194",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > span": {
      margin: "2px 7px",
      display:"flex",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        marginBottom: "6px",
      },
      "&:first-child": {
        paddingRight: "14px",
        borderRight: "1px solid #cdd1d8",
        marginLeft: "0px",
      },
      "& svg": {
        verticalAlign: "text-top",
        width: "15px",
        height: "15px",
        marginRight: "5px",
      },
    },
  },
  header: {
    display: "flex",
    borderBottom: "solid 1px #cdd1d8;",
    height: "12%",
    columnGap: "4px",
    "& div": {
      "& h4": {
        margin: "0",
      },
      "& div": {
        margin: "0",
        fontSize: "12px",
        color: "#868686",
      },
    },
  },
  profilename: {
    marginTop: "3px",
    marginBottom: "0px",
  },
  userhandle: {
    fontWeight: "500",
    paddingLeft: "4px",
    marginTop: "5px",
  },
  Thumbicons: {
    display: "flex",
    alignItems: "center",
    marginRight: "18px",
    cursor: "pointer",
  },
  likeImages: {
    display: "flex",
    margin: "12px",
    justifyContent: "space-around",
    width: "100%",
  },
  footer: {
    display: "flex",
    borderTop: "solid 1px #cdd1d8;",
    borderBottom: "solid 1px #cdd1d8;",
  },
  textField: {
    marginBottom: theme.spacing(3),
    // maxHeight: theme.spacing(6),
    "& .MuiInputBase-input": {
      padding: "14px 0",
      fontSize: "12px",
      color: "#758194",
    },
  },
}));
const limit = 5;
let totalCount = 0;
const CustomImageSlider = (props) => {
  let { open, sliderref, sliderindex, handleClose } = props;
  const { profile_image, full_name, user_tagging, badge_status } =
    props.post && (props.post.user_id || {});
  const { _id, type, comment_show, created_at, vurb_imgs } =
    props && (props.post || {});
  const classes = useStyles();
  const [imgIndex, setImgIndex] = useState(sliderindex);
  const [commentList, setCommentList] = useState([]);
  const [likeArray, setLikeArray] = useState({});
  const [loader, setLoader] = useState(true);
  const [skip, setSkip] = React.useState(0);
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  const settings = {
    loop: false,
    infinite: false,
    autoplay: false,
    speed: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: sliderindex,
  };
  useEffect(() => {
    GetComments();
    Vurb_likeCount();
  }, [imgIndex]);
  const Vurb_like = async (type) => {
    let data = vurb_imgs[imgIndex];
    let inputObj = {
      type: type,
      user_id: props.user._id,
      vurbImage_id: data._id,
    };
    var like = API_Path + "api/vurb/img/like_dislike";
    axios
      .post(like, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setLikeArray({
          ...likeArray,
          like: response.data.result.like,
          dislike: response.data.result.dislike,
          dislike_users: response.data.result.dislike_users,
          like_users: response.data.result.like_users,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const observer = useRef()
  const lastImgCommentElement = useCallback(node => {
    if (loader) return
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        console.log("working",entries,totalCount,commentList.length)
        if (totalCount > commentList.length) {
          setLoader(true)
          GetComments(skip)
        }
      }
    })
    if (node) observer.current.observe(node)
  })
  const Vurb_likeCount = async () => {
    let vurbImageId = vurb_imgs && vurb_imgs[imgIndex] && vurb_imgs[imgIndex]._id;
    axios
      .get(API_Path + `api/vurb/img/details/${vurbImageId}`, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setLikeArray(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const addComment = async (obj) => {
    let data = vurb_imgs[imgIndex];
    let inputObj = {
      inputObject: {
        vurb_id: _id,
        device: "web",
        comment: obj.content,
        user_id: props.user._id,
        parent_comment_id: null,
        gif: obj.gif,
        video: obj.video,
        mentionIds: obj.mentionIds,
        vurbImage_id: data._id,
      },
    };
    var VurbImage = API_Path + "api/vurb/img/comment";
    axios
      .post(VurbImage, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((res) => {
        let tempList = [...commentList]
        let tempData = {...res.data.result,child_comments:[]}
        setSnackbarState({
          open: true,
          message: "Commented Successfully",
          severity: "success",
        });
        tempList.unshift(tempData)
        setCommentList(tempList)
        setSkip(skip + 1)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const GetComments = async (skipCount = 0) => {
    let data = vurb_imgs[imgIndex];
    var VurbImage = API_Path + "api/vurb/img/comment/list";
    let inputObj = {
      inputObject: {
        vurb_id: _id,
        vurbImage_id: data && data._id ? data._id : null,
        parent_comment_id: null,
        limit:limit,
        skip:skipCount
      },
    };
    axios
      .post(VurbImage, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        const { data: { result = [], totalCount: totalRecord } } = response;
        (skipCount === 0) ? setCommentList(result) : setCommentList(p => [...p, ...result]);
        totalCount = totalRecord;
        setSkip(skipCount + limit);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onClose = () => {
    setSnackbarState({ open: false });
  };
  const reloadComments = (type, index, data) => {
    let tempCommentList = [...commentList]
    if (type === 'Delete') {
      tempCommentList.splice(index, 1);
      setCommentList(tempCommentList)
      setSkip(skip - 1)
    } else if(type === 'Edit'){
      let editedComment = {...tempCommentList[index],video:data.video,gif:data.gif,comment:data.comment}
      tempCommentList.splice(index,1,editedComment);
      setCommentList(tempCommentList)
    } else if(type ==="Add") {
      let editedComment = tempCommentList[index]
      editedComment.comments_count += 1;
      tempCommentList.splice(index,1,editedComment)
      setCommentList(tempCommentList)
    }else if(type ==="Like"){
      let editedComment = {...tempCommentList[index],dislike_users:data.dislike_users,like_users:data.like_users}
      tempCommentList.splice(index,1,editedComment)
      setCommentList(tempCommentList)
    }else if(type ==="UpdateChild"){
      let editedComment = {...tempCommentList[index],comments_count:data}
      tempCommentList.splice(index,1,editedComment)
      setCommentList(tempCommentList)
    }else{
      console.log('else')
    }
  }
  const commentAddCount = (type) => {
    setLikeArray({
      ...likeArray,
      comment_count:
        type === "add"
          ? likeArray.comment_count + 1
          : likeArray.comment_count - 1,
    });
  };
  return (
    <Modal open={open} style={{ border: "none" }}>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div className={classes.slider}>
            <Cancel
              className={classes.closeButton}
              style={{
                backgroundColor: "white",
                cursor: "pointer",
              }}
              onClick={() => handleClose()}
            />
            <Slider
              {...settings}
              className={classes.ModelBackground}
              ref={sliderref}
              afterChange={(e) => setImgIndex(e)}
              style={vurb_imgs.length > 1 && { left: "34%" }}
            >
              {vurb_imgs &&
                vurb_imgs.map((image, i) => {
                  let imgUrl =
                  image.url.split("/").length > 4 && image.url.includes("tenor")
                      ? image.url
                      : `${process.env.REACT_APP_VIDEO_BASEURL}${
                        image.url.split("/")[image.url.split("/").length - 1]
                        }`;
                  return (
                    <div key={i}>
                      <img alt="" src={imgUrl} />
                    </div>
                  );
                })}
            </Slider>
          </div>
          {vurb_imgs && vurb_imgs.length > 1 && (
            <Paper className={classes.commentList}>
              <Box p={2} className={classes.header}>
                <div style={{ position: "relative" }}>
                  <CustomImage
                    userImage={profile_image}
                    size="52px"
                    marginRight="0"
                  />
                  {!!badge_status && (
                    <span
                      className={classes.activeStatus}
                      title="Verified User/Public Figure"
                    >
                      <VerifiedUserRoundedIcon />
                    </span>
                  )}
                </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <h4>{full_name}</h4>
                    {user_tagging && (
                      <span className={classes.userhandle}>
                        @{user_tagging}
                      </span>
                    )}
                  </div>
                  <div className={classes.SubHeader}>
                    <span>
                      {!type ? (
                        <img
                          src={publicIcon}
                          style={{ width: "15px", marginRight: "5px" }}
                          alt=""
                        />
                      ) : (
                        <img
                          alt=""
                          src={Lockicon}
                          style={{ width: "15px", marginRight: "5px" }}
                        />
                      )}
                      {!type ? "Public" : "Private"}
                    </span>
                    <span>
                      <DebateDMY listDMY={created_at} normalDMY={"post"} />
                    </span>
                  </div>
                </div>
              </Box>
              <div
                style={{ maxHeight: "16%", overflow:"auto" }}
                dangerouslySetInnerHTML={{
                  __html: props.post.content,
                }}
              ></div>
              <Box className={classes.footer}>
                <div className={classes.likeImages}>
                  <div className={classes.Thumbicons}>
                    <img
                      alt=""
                      src={
                        _.get(likeArray, "like_users", []).includes(
                          props.user._id
                        )
                          ? ThumbsYellow
                          : Thumbs
                      }
                      onClick={() => Vurb_like("like")}
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />{" "}
                    <h5 style={{ margin: "0 0 0 10px" }}>
                      <LikeUserCompoent
                        count={numberFormatter(_.get(likeArray, "like", 0))}
                        active={0}
                        auth={props.auth}
                        id={vurb_imgs[imgIndex]&&vurb_imgs[imgIndex]._id}
                        slider={true}
                      />
                    </h5>
                  </div>
                  <div className={classes.Thumbicons}>
                    <img
                      alt=""
                      src={
                        _.get(likeArray, "dislike_users", []).includes(
                          props.user._id
                        )
                          ? ThumbsYellow
                          : Thumbs
                      }
                      onClick={() => Vurb_like("dislike")}
                      style={{
                        transform: "rotate(180deg)",
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />{" "}
                    <h5 style={{ margin: "0 0 0 10px" }}>
                      <LikeUserCompoent
                        count={numberFormatter(_.get(likeArray, "dislike", 0))}
                        active={1}
                        auth={props.auth}
                        id={vurb_imgs[imgIndex]&&vurb_imgs[imgIndex]._id}
                        slider={true}
                      />
                    </h5>
                  </div>
                  <div className={classes.Thumbicons}>
                    <img
                      alt=""
                      src={CommentImg}
                      style={{ width: "20px", height: "20px" }}
                    />{" "}
                    <h5 style={{ margin: "0 0 0 10px" }}>
                      {numberFormatter(likeArray.comment_count)}
                    </h5>
                  </div>
                  {/* {loader && <Loader />} */}
                </div>
              </Box>
              <Box style={{ height: "55%", width: "100%" }}>
                {commentList && commentList.length > 0 ? (
                  <div
                    style={{
                      overflow: "auto",
                      overflowX: "hidden",
                      // width: "435px",
                      height: "100%",
                    }}
                  >
                    {commentList.map((commentObj, i) => {
                      return (
                        <VurbCommentList
                          sliderImage={true}
                          setLoader={props.setLoader}
                          key={i}
                          commentObj={commentObj}
                          index={i}
                          reloadComments={reloadComments}
                          UpdateComment={addComment}
                          comment_show={comment_show}
                          creator={props.creator}
                          vurb_imgs={vurb_imgs[imgIndex]}
                          commentDelete={commentAddCount}
                          ref={commentList.length - 1 === i ? lastImgCommentElement : null}
                        />
                      );
                    })}
                    {loader && <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}
                  </div>
                ) : (
                  <Box p={3}>
                    <Typography align="center" variant="body1">
                      {!loader?"No comments Available":""} 
                    </Typography>
                  </Box>
                )}
                {loader && commentList && commentList.length === 0 && <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}
              </Box>
              <Box display="flex" alignItems="center" height="37px">
                <div className={classes.chatText}>
                  {comment_show === true && (
                    <CommentEditor
                      setLoader={setLoader}
                      setSnackbarState={setSnackbarState}
                      sliderImage={true}
                      user={props.user}
                      auth={props.auth}
                      result={addComment}
                      commentAddCount={commentAddCount}
                    />
                  )}
                </div>
              </Box>
            </Paper>
          )}
        </div>
        <SnackBar
          open={snackbarState.open}
          message={snackbarState.message}
          severity={snackbarState.severity}
          onClose={onClose}
        />
      </Fragment>
    </Modal>
  );
};
export default CustomImageSlider;
