import React from "react";
import ReactQuill from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import { API_Path } from "../../Common/Const";
import axios from "axios";
import { useMemo } from "react";
import UserBlankImage from '../../../assets/images/user_blank.png'

function CommentBox({ onChange, newref, theme, className, placeholder, auth, user,commentEdit,id }) {
    const modules = useMemo(() => {
        return {
            allowedChars: /^[A-Za-zÅÄÖåäö]*$/,
            linkTarget: "_blank",
            mentionDenotationChars: commentEdit ?["@"]: ["@","#"],
            isolateCharacter:true,
            spaceAfterInsert:true,
            source: async function (searchTerm, renderList, mentionChar) {
                let values = [];
                if (mentionChar === "@") {
                    values = await getFriendList(searchTerm);
                } else {
                    if (searchTerm) {
                        values = await getHastTagList(searchTerm);
                    }
                }
                if (searchTerm.length === 0) {
                    renderList(values, searchTerm);
                } else {

                    // const matches = [];
                    // for (let i = 0; i < values.length; i++)
                    //     if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                    //         matches.push(values[i]);
                    renderList(values, searchTerm);
                }
            },
            onSelect: (data, insertItem, a) => {
                insertItem(data)
            },
            renderItem: (item, searchTerm, a, b, c) => {
                if (item.type === 'hash') {
                    return `<li><div class='hashdiv'>
                    <div class='hashflex'>
                    <p class='hashname'>${item.tag}</p>
                    <p class='posthashcount'>${item.count} vurbs</p>
                    </div>
                    </div>
                    </li>`
                } else {
                    return `<li><div class='maindiv'>
            <img class='userimg' width='40px' height='40px' src=${item.profile_image || UserBlankImage} alt='userImage'/>
            <div class='d-flex flex-column userInfo'>
            <p title='${item.value}' class='userName'>@${item.value}</p>
            </div>
            </div>
            </li>`
            // <p class='userName'>${item.full_name}</p>
                }
            }
        };
    }, []);
    const getFriendList = async (val = '') => {
        return await axios
            .get(API_Path + `api/friendlist/get/user?user_id=${user._id}&search=${val}`, {
                headers: {
                    Authorization: "Basic " + auth.accessToken,
                },
            })
            .then((response) => {
                if (response.data.status === 1) {
                    let mentionIds = [user._id];
                    newref && newref.current.unprivilegedEditor.getContents().ops
                        .map((op) => {
                            if (typeof op.insert === 'string') {
                                return op.insert;
                            }
                            if (op.insert.mention) {
                                if (op.insert.mention.denotationChar === '@') mentionIds.push(op.insert.mention.id);
                                return op.insert.mention.id;
                            }
                            return null
                        })
                    return response.data.data.map((val) => {
                        return { ...val, link: `${process.env.REACT_APP_API_PATH}deeplink?type=friendsProfile&token=${val._id}`, value: val.user_tagging ? val.user_tagging : val.full_name }
                    }).filter((val) => !mentionIds.includes(val._id))
                } else {
                    return []
                }

            })
    }
    const getHastTagList = async (val = '') => {
        return await axios
            .get(API_Path + `api/hashtag/search?search=${val}`, {
                headers: {
                    Authorization: "Basic " + auth.accessToken,
                },
            })
            .then((response) => {
                if (response.data.status === 1) {
                    let hasttags = [];
                    newref && newref.current.unprivilegedEditor.getContents().ops
                        .map((op) => {
                            if (typeof op.insert === 'string') {
                                return op.insert;
                            }
                            if (op.insert.mention) {
                                if (op.insert.mention.denotationChar === '#') hasttags.push(op.insert.mention.id);
                                return op.insert;
                            }
                            return null;
                        });
                    return response.data.data.map((val) => {
                        let newtext = val.tag.substring(1)
                        return { ...val, type: 'hash', value: newtext, id: val.tag, link: `${process.env.REACT_APP_API_PATH}deeplink?type=hashtag&token=${newtext}` }
                    }).filter((val) => !hasttags.includes(val.value))
                } else {
                    return []
                }

            })
    }
    const handleChange = (content, delta, source, editor) => {
        onChange(content)
    };
    return (
        <ReactQuill
            theme={theme}
            className={className}
            placeholder={placeholder}
            //   value={value}
            onChange={handleChange}
            modules={{
                mention: modules
            }}
            id={id?id:'vurbil-editer'}
            ref={newref}
        />
    );
}

export default CommentBox;
