import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect,useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { API_Path } from "../../../../src/screens/Common/Const/index";
// import RadioButtonCheckedIcon from "../../../assets/images/checkedIcon.svg"
import ActivePlanImg from "../../../assets/images/activePlanImg.png";
import Rounded2 from "../../../assets/images/rounded2.png";
import Rounded3 from "../../../assets/images/rounded3.png";
import TickYellow from "../../../assets/images/tic-yellow.png";
import TickWhite from "../../../assets/images/tick-white.png";
import Checkout from "./Checkout";
import ConfirmationModal from "../../Common/ConfirmationModal";
import SnackBar from "../../../components/SnackBar";
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    position: "relative",
    backgroundColor: "#f6f7f8",
    padding: "30px 30px 90px",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    overflow: "auto",
    "& .active": {
      backgroundColor: "#FCDC7D",
    },
    "& .inActive": {
      opacity: "0.5",
    },
  },
  headingWithBorderBottom: {
    margin: "0 0 20px 0",
    paddingBottom: "14px",
    textAlign: "center",
    color: "#313236",
  },
  header: {
    padding: "0 8%",
    alignItems: "center",
    position: "absolute",
    top: "94px",
    left: "0%",
    zIndex: "10",
    "& .inActiveLine": {
      opacity: "0.1",
      border: "2px dashed #000",
    },
  },
  activePlan: {
    display: "flex",
    border: "4px solid",
    borderRadius: "6px",
    borderImage:
      " linear-gradient(180deg, rgba(245,143,50,1) 0%, rgba(250,171,33,1) 25%, rgba(255,196,19,1) 50%, rgba(250,171,33,1) 75%, rgba(250,168,35,1) 100%)1",
    alignItems: "center",
    justifyContent: "space-around",
  },
  circleBtn: {
    fontSize: 14,
    borderRadius: 30,
    fontWeight: 600,
    padding: "8px 20px",
    position: "relative",
    "& button": {
      position: "absolute",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      opacity: "0",
      cursor: "pointer",
    },
  },
  planPaper: {
    minHeight: "498px",
    padding: "14px 0px",
    boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.16)",
    "& .planBody": {
      cursor: "pointer",
      padding: "10px 0 10px 26px",
      margin: "20px 0",
    },
    "& .active": {
      // border: "solid 1px #ffc003",
      backgroundColor: "#FCDC7D",
      position: "relative",
      "&:after": {
        content: '""',
        position: "absolute",
        right: "-22px",
        top: "18px",
        borderLeft: "30px solid #FCDC7D",
        borderRight: "0px solid transparent",
        borderTop: "16px solid transparent",
        borderBottom: "16px solid transparent",
        zIndex: "100",
      },
      // backgroundImage:url(paymentBg),
      // "&.disabled":{
      //   backgroundColor: "dimgrey",
      //   color: "linen",
      //   opacity: "1",

      // "& .MuiButton-root": {
      //   backgroundColor: "#ffc003",
      //   color: "rgba(0, 0, 0, 0.87)",
      // },
      // },
    },

    // "& .MuiFormControlLabel-label": {
    //     width: '100%',
    // },
    // "& .MuiFormControlLabel-root": {
    //     margin: '0',
    //     position: 'relative',
    //     width: '100%',
    //     "& .MuiRadio-root": {
    //         position: 'absolute',
    //         opacity: '0',
    //         "&.Mui-checked": {
    //             "& + span": {
    //                 "& .paper": {
    //                     border: 'solid 1px #ffc003',
    //                     "& .MuiButton-root": {
    //                         backgroundColor: '#ffc003',
    //                         color: 'rgba(0, 0, 0, 0.87)'
    //                     }
    //                 },
    //             }
    //         }
    //     }
    // },
    "& p": {
      fontSize: "13px",
      color: "#758194",
      margin: "0",
    },
  },
  planSelector: {
    // position: "absolute",
    // top: "-20px",
    padding: "4px",
    backgroundColor: "white",
    // left: "148px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    columnGap: "4px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.16)",
  },
  planOffer: {
    height: "100%",
    boxShadow: "0 0px 0px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "0",
    padding: "18px 32px 0",
  },
  planDiscount: {
    maxHeight: "356px",
    overflow: "auto",
    padding: "1px 2px",
  },
  planOfferPaper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px 15px 20px",
    borderRadius: "12px",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.16)",
    cursor: "pointer",
    marginBottom: "14px",
    "& p": {
      fontSize: "13px",
      color: "#758194",
      margin: "0",
    },
    "& h2": {
      color: "#758194",
      fontWeight: "500",
      "& svg": {
        marginLeft: 10,
      },
    },
    "& h4": {
      margin: "14px 0",
    },
  },
  PaymentSummaryPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: "28px 32px 24px 32px",
    boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.16)",
    "& p": {
      fontSize: "13px",
      color: "#758194",
      margin: "0",
    },
    "& h1": {
      fontWeight: "500",
    },
    "& h4": {
      color: "#758194",
    },
    "& sup": {
      fontSize: "18px",
    },
    "& .priceSection": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // [theme.breakpoints.up("sm")]: {
      //   width: "150px",
      //   maxWidth: "150px",
      //   flex: "0 0 150px",
      //   textAlign: "right",
      // },
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        margin: "auto",
      },
      "& h5": {
        fontWeight: "500",
        "& svg": {
          width: "16px",
          height: "16px",
        },
      },
    },
  },
  discountBox: {
    padding: "16px 28px 15px 15px",
    borderRadius: "12px",
    backgroundColor: "#fff5d8",
    maxWidth: "460px",
  },
  plannOfferContainer: {
    position: "relative",
    width: "100%",
    "& .MuiIconButton-root": {
      position: "absolute",
      right: "10px",
    },
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  line: {
    width: "100%",
    border: "3px solid #FCDC7D",
    // position: "absolute",
    // top: "-8px",
    // right: "-232px",
  },
}));
const Payment = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [valuePlanOffer, setValuePlanOffer] = useState([]);
  const [valuePaymentSummary, setValuePaymentSummary] = useState([]);
  // const [planOffer, setPlanOffer] = useState("");
  const [discount, setDiscount] = useState("");
  const [totalAmount, setTotalAmount] = useState(false);
  const [paymentSummary, setPaymentSummary] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState(null);
  const [selectedOffers, setSelectedOffers] = useState(null);
  const [boostDebateOptions, setBoostDebateOptions] = React.useState([]);
  const [debateList, setDebateList] = useState([]);
  const history = useHistory();
  const { state: { id, type } = {} } = useLocation() || {};
  const { currentPackage = [] } = props || {};
  const [openModal, setOpenModal] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState();
  const [snackbarState, setSnackbarState] = useState({ open: false });
  useEffect(() => {
    getDebateDetails();
    GetUserAdminSettings()
  }, []);
  useEffect(() => {
    handlePayment();
  }, [props]);
  const handlePaymentChange = (list) => {
    setSelectedPlans(list);
    setValuePlanOffer(list.paymentdetails);
    // setPlanOffer(true);
    setPaymentSummary(false);
    setSelectedOffers(null);
    setBoostDebateOptions("");
  };

  const handlePlanOfferChange = (list) => {
    setValuePaymentSummary(list);
    setDiscount(list.offerAmount);
    setTotalAmount(list.totalAmount);
    setSelectedOffers(list);
    setPaymentSummary(true);
    // setBoostDebateOptions("");
  };
  const handleCancel = () => {
    setPaymentSummary(false);
    setValuePaymentSummary([]);
    setDiscount("");
    setTotalAmount(false);
    setSelectedOffers(null);
  };
  const handlePayment = () => {
    var ClearNotification = API_Path + "api/paymentplan/activelist";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
      },
    };
    axios
      .post(ClearNotification, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data && response.data.result) {
          let data = response.data.result.map((val) => {
            if (
              currentPackage.findIndex((e) => {
                return e.package_name === val.plan_type;
              }) > -1 &&
              (val.plan_type !== "Promote Vurb" ||
                val.plan_type !== "Boost Debate")
            ) {
              return { ...val, disabled: true };
            }
            return { ...val, disabled: false };
          });
          setPlans(data);
          if (type) {
            let obj = data.find((o) => o.plan_type === type);
            handlePaymentChange(obj);
          } else {
            let obj = data[0];
            handlePaymentChange(obj);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getDebateDetails = async () => {
    var DebatesDetails = API_Path + "api/debate/paymentdebatelist";
    let inputObj = {
      inputObject: {
        // type: "upcoming",
        userId: props.user._id,
        // searchText: "",
        // sortBy: "Relevant",
      },
    };
    axios
      .post(DebatesDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setDebateList(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleChange = (event) => {
    setBoostDebateOptions(event.target.value);
  };
  const handleAutoSubscription = (data) => {
    if (
      data.transaction_from === "Web" ||
      data.transaction_from === "Android"
    ) {
      if (!!data.stripe_subscription_id) {
        setOpenModal(true);
        setCancelSubscription(data);
      }
    } else {
      setSnackbarState({
        open: true,
        message:
          "Auto subscription is already enabled in iOS device, Please cancel it from iOS.",
        severity: "error",
      });
    }
  };
  const cancelAutoSubscription = async () => {
    let CancleApi = API_Path + "api/stripe/subscription/cancel";
    let inputObj = {
      subscription_id: cancelSubscription.stripe_subscription_id,
      user_id: props.user._id,
    };
    await axios
      .post(CancleApi, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((res) => {
        // console.log(res)
        setOpenModal(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };
  const GetUserAdminSettings = async () => {
    var ValidateGetUserAdminSettings = API_Path + "api/user/admin_settings";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
      },
    };
    dispatch({
      type: "LOGIN_GLOBAL_DETAILS",
      data: { ...props.loginDetails, vurbApiLoading: false },
    });
    axios
      .post(ValidateGetUserAdminSettings, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        var loginDetailsObject = props.loginDetails;
        loginDetailsObject["application_name"] =
          response.data.result.application_name;
        loginDetailsObject["currentPackage"] =
          response.data.result.currentPaidPackage;
        loginDetailsObject["is_disable_ads"] =
          response.data.result.is_disable_ads;
        loginDetailsObject["is_disable_broadcast"] =
          response.data.result.is_disable_broadcast;
        loginDetailsObject["logo"] = response.data.result.logo;
        loginDetailsObject["allBlockedUsers"] = response.data.result.allBlockedUsers;
        loginDetailsObject["vurbApiLoading"] = true;
        dispatch({
          type: "LOGIN_GLOBAL_DETAILS",
          data: loginDetailsObject,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const handleRedirect = () => {
  //   history.goBack();
  // };
  return (
    <div className={classes.pageContainer}>
      <h2 className={classes.headingWithBorderBottom}>
        Choose a Plan and Upgrade
      </h2>
      <Grid container className={classes.header}>
        <Grid item md={2} className={classes.planSelector + " active"}>
          <img alt="" src={TickWhite} width={"22px"} height={"22px"} />
          <span>Choose Feature</span>
        </Grid>
        <Grid item md={3} className={classes.line}></Grid>
        <Grid
          item
          md={2}
          className={classes.planSelector + " active "}
          style={{ left: "230px" }}
        >
          {paymentSummary || (!!selectedPlans && selectedPlans.disabled) ? (
            <img alt="" src={TickWhite} width={"22px"} height={"22px"} />
          ) : (
            <img alt="" src={Rounded2} width={"22px"} height={"22px"} />
          )}
          <span>{`${
            !!selectedPlans && selectedPlans.disabled ? "Active" : "choose"
          } Plan`}</span>
        </Grid>
        {!!selectedPlans && !selectedPlans.disabled && (
          <>
            <Grid
              item
              md={3}
              className={classes.line + (paymentSummary ? "" : " inActiveLine")}
            ></Grid>
            <Grid
              item
              md={2}
              className={
                classes.planSelector +
                (paymentSummary ? " active" : " inActive")
              }
            >
              <img alt="" src={Rounded3} width={"22px"} height={"22px"} />
              <span>Checkout</span>
            </Grid>
          </>
        )}
      </Grid>
      <div style={{ display: "flex", columnGap: "2px" }}>
        <Box mt={1.5} mb={3} style={{ width: "30%" }}>
          <Paper className={classes.planPaper}>
            {plans &&
              plans
                .filter((val) => {
                  if (type === "Promote Vurb") {
                    return val.plan_type === "Promote Vurb";
                  } else {
                    return val.plan_type !== "Promote Vurb";
                  }
                })
                .map((planData, index) => {
                  return (
                    <Box
                      key={index}
                      className={
                        "planBody " +
                        (selectedPlans !== null &&
                        selectedPlans._id === planData._id
                          ? "active"
                          : "")
                      }
                      onClick={handlePaymentChange.bind(this, planData)}
                    >
                      <div>
                        <h4
                          // className="m-0"
                          style={{ textTransform: "capitalize",margin:"12px 0" }}
                        >
                          {planData.plan_type === "Ad free payment"
                            ? "Ad Free Subscription"
                            : planData.plan_type}
                        </h4>
                        {/* <p style={{ maxWidth: "255px" }}>
                          {planData.plan_description ===
                          "Ad free payment description"
                            ? "Ad Free Subscription description"
                            : planData.plan_description}
                        </p> */}
                      </div>
                      {/* <Box textAlign="right">
                      <Button
                        variant="outlined"
                        color="primary"
                        selected={true}
                        className={clsx(classes.circleBtn)}
                        // onClick={handlePaymentChange.bind(this, planData)}
                        disabled={type ? (type === planData.plan_type ? false : true) : planData.disabled?true:false}
                      >
                        Select
                      </Button>
                    </Box> */}
                    </Box>
                  );
                })}
          </Paper>
        </Box>
        {/* {planOffer && ( */}
        <Box mt={1.5} mb={3} style={{ width: "40%" }}>
          <Paper className={classes.planOffer}>
            <div style={{ marginBottom: "24px", paddingTop: "14px" }}>
              <h2 className="m-0" style={{ textTransform: "capitalize" }}>
                {!!selectedPlans &&
                  (selectedPlans.plan_type === "Ad free payment"
                    ? "Ad Free Subscription"
                    : selectedPlans.plan_type)}
              </h2>
              <p style={{ fontSize:'12px', margin: "0"}}>
                {!!selectedPlans &&
                  (selectedPlans.plan_description ===
                  "Ad free payment description"
                    ? "Ad Free Subscription description"
                    : selectedPlans.plan_description)}
              </p>
            </div>
            {selectedPlans && !selectedPlans.disabled && selectedPlans.plan_type === "Boost Debate" && (
              <Box
                my={{ xs: 2, md: 0 }}
                width={{ xs: "100%", md: "100%" }}
                style={{ marginBottom: "10px" }}
              >
                <TextField
                  id="Select-Debate"
                  select
                  fullWidth
                  label="Choose your Debate"
                  value={boostDebateOptions}
                  onChange={handleChange}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {debateList.map((debateName) => (
                    <MenuItem key={debateName._id} value={debateName._id}>
                      {debateName.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
            {selectedPlans &&
            !selectedPlans.disabled &&
            valuePlanOffer.length > 0 ? (
              <div className={classes.planDiscount}>
                {valuePlanOffer.map((planOfferData, index) => {
                  return (
                    <Paper
                      key={index}
                      className={classes.planOfferPaper}
                      onClick={handlePlanOfferChange.bind(this, planOfferData)}
                    >
                      <Box>
                        <h4>
                          <span style={{ fontWeight: "400" }}>
                            {planOfferData.payment_term === "Minutes"
                              ? "Boost Time : "
                              : "Term : "}
                          </span>
                          {planOfferData.payment_term === "Minutes" ||
                          planOfferData.payment_term === "Days"
                            ? planOfferData.payment_term_unit +
                              " " +
                              planOfferData.payment_term
                            : planOfferData.payment_term}
                        </h4>
                        <p>{`Expires on ${moment(planOfferData.expiry_date)
                          .subtract(1, "day")
                          .format("L")}`}</p>
                      </Box>
                      <Box
                        position="relative"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "24px",
                        }}
                      >
                        {!!planOfferData.offer && (
                          <p
                            className="m-0"
                            style={{
                              backgroundColor: "#FCDC7D",
                              borderRadius: "10px",
                              padding: "0 4px",
                              color: "#000",
                            }}
                          >
                            {planOfferData.offer}% Off
                          </p>
                        )}
                        <h2 className="m-0" style={{ color: "#000" }}>
                          <sup style={{ fontSize: "16px" }}>$</sup>
                          {planOfferData.totalAmount}
                          {selectedOffers !== null &&
                          selectedOffers._id === planOfferData._id ? (
                            <img
                              alt=""
                              src={TickYellow}
                              style={{
                                width: "22px",
                                height: "22px",
                                marginLeft: "12px",
                              }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon color="primary" />
                          )}
                        </h2>
                      </Box>
                    </Paper>
                  );
                })}
              </div>
            ) : (
              selectedPlans &&
              (selectedPlans.disabled ? (
                currentPackage &&
                currentPackage
                  .filter((val) => val.package_name === selectedPlans.plan_type)
                  .map((data, index) => {
                    let expireDate = new Date(data.expiry_date);
                    return (
                      <Box className={classes.activePlan} key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "12px",
                            margin: "12px 0",
                          }}
                        >
                          <img
                            alt=""
                            src={ActivePlanImg}
                            width="40px"
                            height="40px"
                          />
                          <div>
                            <h4 className="m-0">Active Plan</h4>
                            <h6 className="m-0">
                              Expires in {moment(expireDate).fromNow(true)}
                            </h6>
                          </div>
                        </div>
                        {!!data.subscription_status && (
                          <div>
                            <Button
                              variant="text"
                              color="primary"
                              style={{
                                borderRadius: "30px",
                                textDecoration: "underline",
                              }}
                              onClick={() => handleAutoSubscription(data)}
                              size="small"
                            >
                              Cancel Subscription
                            </Button>
                          </div>
                        )}
                      </Box>
                    );
                  })
              ) : (
                // <p>Already has a Plan Subscription</p>
                <p style={{ margin: "0" }}>No Upgrade Available</p>
              ))
            )}
          </Paper>
        </Box>
        {/* )} */}
        {!!selectedPlans && !selectedPlans.disabled && (
          <>
            <Box mt={1.5} mb={3} style={{ width: "30%" }}>
              <Paper
                className={
                  classes.PaymentSummaryPaper +
                  (paymentSummary ? "" : " inActive")
                }
              >
                <div>
                  <h2 className="m-0">Payment Summary</h2>
                  {/* <Box
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    width={{ xs: "100%", md: "75%" }}
                  >
                    <Box>
                      <h4 className="m-0">{selectedPlans.plan_type}</h4>
                      <p>
                        {valuePaymentSummary.payment_term === "Minutes"
                          ? "Boost Time: "
                          : "Term: "}
                        {valuePaymentSummary.payment_term === "Minutes" ||
                        valuePaymentSummary.payment_term === "Days"
                          ? valuePaymentSummary.payment_term_unit +
                            " " +
                            valuePaymentSummary.payment_term
                          : valuePaymentSummary.payment_term}
                      </p>
                    </Box>
                  </Box> */}
                  <Box className="priceSection">
                    <h4 className="m-0">Price</h4>
                    <h1 className="m-0">
                      <sup>$</sup>
                      {!!paymentSummary ? valuePaymentSummary.amount : "0.00"}
                    </h1>
                  </Box>

                  {/* <Box className={classes.discountBox}>
                                    <p>Discount in push notifications, Lorem ipsum dolor sit amet,
                                adipiscing elit, sed do eiusmod tempor.</p>
                                </Box> */}
                  <Box className="priceSection">
                    <div
                      style={{
                        display: "flex",
                        columnGap: "4px",
                        alignItems: "center",
                      }}
                    >
                      <h4 className="m-0">Discount</h4>
                      {!!paymentSummary && !!valuePaymentSummary.offer && (
                        <p
                          className="m-0"
                          style={{
                            backgroundColor: "#FCDC7D",
                            borderRadius: "10px",
                            padding: "0 4px",
                            color: "#000",
                          }}
                        >
                          {valuePaymentSummary.offer}% Off
                        </p>
                      )}
                    </div>
                    <h1 className="m-0">
                      <sup>$</sup>
                      {!!paymentSummary
                        ? valuePaymentSummary.offerAmount
                        : "0.00"}
                    </h1>
                  </Box>
                </div>
                <div>
                  {!!paymentSummary &&
                    !!valuePaymentSummary.auto_subscription && (
                      <Box display="flex" justifyContent="center">
                        <p style={{ color: "gray" }}>
                          <b>Note:</b> Plan is under auto subscription, you can
                          cancel your subscriptions at any time from your
                          account
                        </p>
                      </Box>
                    )}
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginBottom: "16px" }}
                  >
                    <Box>
                      <h4 className="m-0">Total Amount</h4>
                    </Box>
                    <Box>
                      <h1 className="m-0">
                        <sup>$</sup>
                        {!!paymentSummary ? totalAmount : "0.00"}
                      </h1>
                    </Box>
                  </Box>
                  {!!selectedPlans &&
                  selectedPlans._id !== null &&
                  selectedOffers &&
                  selectedOffers._id !== null ? (
                    <Box display="flex" justifyContent="flex-end">
                      <Button onClick={handleCancel}> Cancel</Button>

                      <Checkout
                        classes={classes}
                        disabled={
                          selectedPlans.plan_type === "Boost Debate" &&
                          boostDebateOptions.length === 0
                        }
                        checkoutObj={{
                          checkoutPrice: totalAmount,
                          discount: discount,
                          amountWithoutDiscount: valuePaymentSummary.amount,
                          currency: "USD",
                          packageId: selectedPlans._id,
                          packageName: selectedPlans.plan_type,
                          planId: selectedOffers._id,
                          planName: selectedOffers.offer,
                          debateId:
                            selectedPlans.plan_type === "Boost Debate"
                              ? boostDebateOptions
                              : null,
                          vurbId: id || null,
                        }}
                      />
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="flex-end">
                      <Button disabled={true}> Cancel</Button>

                      <Checkout classes={classes} disabled={true} />
                    </Box>
                  )}
                </div>
              </Paper>
            </Box>
          </>
        )}
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      <ConfirmationModal
        openDialog={openModal}
        modalMessage="Are you sure do you want to cancel auto subscription?"
        handleDialogClose={() => {
          setOpenModal(false);
        }}
        handleDelete={cancelAutoSubscription}
        btnCancelName="No"
        btnNoConfirmName="Yes"
      />
    </div>
  );
};

// export default Payment
const mapStateToProps = ({
  loginDetails: { user, auth, currentPackage },
  GlobalSearch: globalSearch,
  loginDetails
}) => {
  return {
    user,
    auth,
    globalSearch,
    currentPackage,
    loginDetails,
  };
};

export default connect(mapStateToProps)(Payment);
