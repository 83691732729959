import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import axios from "axios";
import React, { useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_Path } from "../../Common/Const/index";
import CustomSearch from "../CustomSearch";

const useStyles = makeStyles((theme) => ({
  searchWhiteBg: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  classificationList: {
    margin: "24px 0 10px 0",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    "& li": {
      listStyle: "none",
      marginBottom: "20px",
      "& a": {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#758194",
        [theme.breakpoints.down("md")]: {
          flexFlow: "column",
          margin: "0 10px",
        },
        "&.active": {
          color: "#313236",
          "& svg, & img": {
            backgroundColor: "#ffc003",
          },
        },
      },
      "& svg, & img": {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundColor: "#fff",
        padding: "8px",
        boxShadow: "0 2px 11px -6px #000000",
        [theme.breakpoints.up("lg")]: {
          marginRight: "12px",
        },
      },
      "& span": {
        backgroundColor: "#758194",
        color: "#fff",
        padding: "3px 6px",
        borderRadius: 5,
        lineHeight: 1,
        [theme.breakpoints.up("lg")]: {
          marginLeft: "12px",
        },
      },
      "&.collapseClasification": {
        "&:not(.active)": {
          display: "none",
        },
      },
    },
  },
  viewAll: {
    padding: "0",
    fontSize: "12px",
    color: "#758194",
  },
}));

const ClassificationList = (props) => {
  const classes = useStyles();
  const [classificationDetails, setclassificationDetails] = React.useState([]);
  const [tempArray, settempArray] = React.useState([]);
  const [active, setActive] = React.useState(false);
  const [selectedclassification, setSelectedclassification] =
    React.useState(undefined);
  const [collapseClasification, setCollapseClasification] =
    React.useState(false);

  let ret = props.location.pathname.replace("/", "");
  const getUrl = useRef(ret);

  // const getclassification = async () => {
  //     var classificationDetails = API_Path + "debateClassification/get";
  //     await axios
  //         .get(classificationDetails, {
  //             headers: {
  //                 'Authorization': "Basic " + props.auth.accessToken
  //             }
  //         })
  //         .then((response) => {
  //             // setclassificationDetails(response.data.result);
  //             handleClassificationCount();
  //             settempArray(response.data.result);
  //             props.classifyLoaded(true)
  //             if(props.handleClassificationList){
  //                 props.handleClassificationList(response.data.result)
  //             }
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }

  const handleClassificationCount = useCallback(() => {
    let screen = getUrl.current;
    if (getUrl.current === "activeDebateList") {
      screen = "upcoming";
    } else if (getUrl.current === "recorded") {
      screen = "completed";
    }
    let URL = API_Path + "api/debateClassification/getbypage";
    let inputObj = {
      inputObject: {
        screen: screen,
        userId: props.user._id,
      },
    };
    axios
      .post(URL, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setclassificationDetails(response.data.result);
        settempArray(response.data.result);
        props.classifyLoaded(true);
        if (props.handleClassificationList) {
          props.handleClassificationList(response.data.result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props]);

  useEffect(() => {
    // getclassification();
    handleClassificationCount();
  }, []);
  // }, [handleClassificationCount]);

  const handleClassificationFilter = (list) => {
    if (list === "All") {
      props.handleDebateDetails("All");
      setSelectedclassification("All");
    } else {
      props.handleDebateDetails(list._id);
      setSelectedclassification(list.name);
    }
    setActive(!active);
    props.classifyLoaded(true);
  };

  const handleCollapseClasification = () => {
    setCollapseClasification(!collapseClasification);
  };

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (event.target.value) {
      const filtered = classificationDetails.filter((lst) =>
        lst.name.toLowerCase().includes(value)
      );
      setclassificationDetails(filtered);
    } else {
      handleClassificationCount();
      setclassificationDetails(tempArray);
    }
  };

  return (
    <React.Fragment>
      <h5 style={{ margin: "10px 0 24px 0" }}>Classification</h5>
      <CustomSearch
        className={classes.searchWhiteBg}
        handleSearch={handleSearch}
      />
      <ul className={classes.classificationList}>
        {classificationDetails && classificationDetails.length > 0 ? (
          <li onClick={handleClassificationFilter.bind(this, "All")}>
            <a
              className={selectedclassification === "All" ? "active" : ""}
              style={{ cursor: "pointer" }}
            >
              <PlaylistAddCheckIcon />
              All
            </a>
          </li>
        ) : (
          <Box mb={3}>
            <Typography align="center" variant="h6">
              No records found
            </Typography>
          </Box>
        )}

        {classificationDetails &&
          classificationDetails.map((list, i) => {
            if (i <= 2) {
              return (
                <li
                  key={list._id}
                  onClick={handleClassificationFilter.bind(this, list)}
                  // className={active === list._id ? 'active' : ''}
                >
                  <a
                    className={
                      selectedclassification === list.name ? "active" : ""
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {/* {i === 0 && <GroupIcon />}
                                        {i === 1 && <TvIcon />}
                                        {i === 2 && <DirectionsRunIcon />} */}
                    {list.file !== null ? (
                      <>
                        <img
                          src={list.file && list.file.url}
                          alt={list.file && list.file.name}
                        />
                      </>
                    ) : (
                      <DehazeIcon />
                    )}
                    {list.name}
                    <span>{list.count}</span>
                  </a>
                </li>
              );
            } else if (i > 2) {
              return (
                <li
                  className={
                    collapseClasification
                      ? "collapseClasification active"
                      : "collapseClasification"
                  }
                  key={list._id}
                  onClick={handleClassificationFilter.bind(this, list)}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    className={
                      selectedclassification === list.name ? "active" : ""
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {list.file !== null ? (
                      <>
                        <img
                          src={list.file && list.file.url}
                          alt={list.file && list.file.name}
                        />
                      </>
                    ) : (
                      <DehazeIcon />
                    )}
                    {list.name}
                    <span>{list.count}</span>
                  </a>
                </li>
              );
            } else {
              return "";
            }
          })}
      </ul>
      {classificationDetails && classificationDetails.length > 3 ? (
        <div>
          <Button
            className={classes.viewAll}
            onClick={handleCollapseClasification}
          >
            {collapseClasification === true ? (
              <>
                Show Less <ExpandLessIcon />
              </>
            ) : (
              <>
                View All <ExpandMoreIcon />
              </>
            )}
          </Button>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(ClassificationList));
