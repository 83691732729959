import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box,CircularProgress, Typography,IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import CustomSearch from "../CustomSearch";

const useStyles = makeStyles((theme) => ({ 
    searchWhiteBg: {
        width:'50%',
        border:'1px solid #d9d9d9',
        borderRadius:'30px',
        margin:'8px 0',
        "& input": {
          backgroundColor: "#fff",
        },
      },
      slidercommentGif:{
        width:'100%',
        border:'1px solid #d9d9d9',
        borderRadius:'30px',
        margin:'8px 0',
        "& input": {
          backgroundColor: "#fff",
        },
      },
    PaperGif : {
        border:'1px solid #d9d9d9',
        borderRadius: "6px",
        backgroundColor: "#fff",
    },
    gifContainer : {
        display:'flex',
        flexWrap: 'wrap',
        columnGap: '8px',
        rowGap:'8px',
        justifyContent:'center',
        margin:'8px 0'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.5),
        top: theme.spacing(1.5),
        width: '30px',
        height: '30px',
        background: '#ffc003',
        color: '#fff',
        "&:hover": {
            background: '#ffc003',
        },
        "& svg": {
            width: 20,
            height: 20,
        }
        // color: theme.palette.grey[500],
    },
}));
const key = process.env.REACT_APP_GIF_KEY;
// const key = "AIzaSyAIFLDtIG3KDjnEdepPpqKBWsck9cRdhkw";
let tempGif = [];
const CommonGif = (props) => {
    const classes = useStyles();
    const [gif,setGif] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {featuredGif()},[])
const featuredGif = () => {
    let featuredUrl = `https://tenor.googleapis.com/v2/featured?key=${key}&client_key=${key}&limit=50`;
    axios.get(featuredUrl).then((res)=> {if(res.data && res.data.results){setGif(res.data.results);tempGif =res.data.results; }}).catch((e)=> {console.log(e)})
}
const searchGif = async(e) => {
    setLoading(true)
    if(e.target.value.length > 0){
        let searchUrl =  `https://tenor.googleapis.com/v2/search?q=${e.target.value}&key=${key}&client_key=${key}&limit=20&contentfilter=high`;
        await axios.get(searchUrl).then((res)=> {if(res.data && res.data.results){setGif(res.data.results)}}).catch((e)=> {console.log(e)})
        setLoading(false)
    }else{
        setGif(tempGif)
        setLoading(false)
    }
}
return(
    <>
        <Box className={classes.PaperGif}>
            <Box style={{borderBottom:'1px solid #d9d9d9',display:'flex',justifyContent:'start',paddingLeft:'4px'}}>
                {props.onClose && <IconButton   aria-label="close"  className={classes.closeButton} onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>}
                <CustomSearch className={props.className ? props.className : props.slidercommentGif ? classes.slidercommentGif : classes.searchWhiteBg} handleSearch={searchGif} />
            </Box>   
            <div style={{height: props.height?props.height:'200px', overflow:'auto'}}>
                <div className={classes.gifContainer}>
                    {
                    !loading && gif.map ((gifObj,index) => (
                        <div key={index}>
                            <img src={gifObj.media_formats.gif.url} onClick={() =>props.onSelect(gifObj.media_formats.gif.url)} style={props.style ? props.style : { width:'120px',height:'160px',cursor:'pointer'}} alt={gifObj.content_description} />  
                        </div>
                    )) 
                    }
                    {loading && <Typography justifyContent='center' variant="body1"><CircularProgress /></Typography>}
                </div>
            </div>

        </Box>
       
    </>

)
}
export default CommonGif; 