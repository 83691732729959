import React from 'react'

const DebateProfile = () => {
    return (
        <div style={{padding: '50px', textAlign: 'center'}}>
            Work In Progress
        </div>
    )
}

export default DebateProfile
