import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const DataGridTable = ({
  columns,
  globalFilter,
  rowClassName,
  values,
  selectionMode,
  selectionHeaderStyle,
  rows,
  dataKey,
  defaultSelection,
   onRowSelect,
  loading,
  filterObj,
  rowClass,
  emptyMessage,
  rowExpansionTemplate,
  onRowExpand,
  totalRecords,
  onPageChange,
  page,
  selection,
  onSelectionChange
}) => {
  // const [selection, onSelectionChange] = useState(defaultSelection || []);
  const [tableData, setTableData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [totalRecord, setTotalRecords] = useState(totalRecords || 0);
  const [tableEmptyMessage, setTableEmptyMessage] = useState(emptyMessage);
  const [first, setFirst] = useState(page);
  const [pages, setPages] = useState(rows);
   const dt = useRef(null);
   
  useEffect(() => {
    setTableData(values);
    setTotalRecords(totalRecords || 0);
  }, [values]);

  useEffect(() => {
    setTotalRecords(totalRecords || 0);
    setDynamicTableData(columns);
  }, [columns]);

  const onPage = (e) => {
    setFirst(e.first);
    setPages(e.rows);
    if (onPageChange && typeof onPageChange === "function") {
      onPageChange(e);
    }
  };

  const onSetExpandedRows = (e) => {
    setExpandedRows(e.data);
    if (onRowExpand && typeof onRowExpand === "function") {
      onRowExpand(e.data);
    }
  };

  const setDynamicTableData = (col) => {
    const dynamicColumns = col.map((col, i) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={col.body}
          sortable={col.sortable}
          headerStyle={col.headerStyle}
          bodyStyle={col.bodyStyle}
          headerClassName={col.headerClassName}
        />
      );
    });
    if (selectionMode === "multiple") {
      dynamicColumns.unshift(
        <Column
          styleClass="custom-checkbox"
          selectionMode="multiple"
          headerStyle={selectionHeaderStyle}
        ></Column>
      );
    }
    if (rowExpansionTemplate) {
      dynamicColumns.unshift(<Column expander style={{ width: "1em" }} />);
    }

    setTableColumn(dynamicColumns);
  };
  return (
    <div>
      

      <div className="client-table-grid">
        <DataTable
          ref={dt}
          rowClassName={rowClassName ? rowClassName : () => {}}
          onPage={onPage}
          rows={pages}
          lazy
          totalRecords={totalRecord}
          first={first}
          onRowToggle={onSetExpandedRows}
          expandedRows={expandedRows}
          selection={selection}
          rowExpansionTemplate={rowExpansionTemplate}
          loading={loading}
          dataKey={dataKey}
          onSelectionChange={onSelectionChange}
          value={tableData}
          globalFilter={globalFilter}
          onRowClick={onRowSelect}
          selectionMode={"checkbox"}
          emptyMessage={tableEmptyMessage || "No Data Found"}
          
        >
          {tableColumn}
        </DataTable>
      </div>
    </div>
  );
};

export default DataGridTable;
