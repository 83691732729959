import React,{useState,useEffect} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  snackbarAlign: {
    "& .MuiAlert-message": {
      fontSize: '12px',
      fontWeight: '500',
    }
  }
}))

const Alert = (props) => {
  return <MuiAlert elevation={1} variant="filled" {...props} />;
};

const SnackBar = ({ open, message, onClose, severity}) => {
  const classes = useStyles();
  const [snackBarOpen, setSnackBarOpen] = useState(open);

  useEffect(() => {
    setSnackBarOpen(open);
  }, [open]);
  const defaultclose=()=>{
    setSnackBarOpen(false);
  }
  

  if (message && open) {
    return (  
      <Snackbar className={classes.snackbarAlign}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() =>{if(onClose!==null){onClose();}else{defaultclose();}} }
      >
        <Alert onClose={() => onClose()} severity={severity ? severity:"error"}>
          {message}
        </Alert>
      </Snackbar>
    );
  }

  return null;
};

export default SnackBar;
