import axios from "axios";
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { API_Path, redirectTo } from '../../Common/Const/index';


const ShareDebate = (props) => {
    const params = useParams();
    const history = useHistory();
    const [debateObj, setDebateObj] = React.useState(null); 
    const [redirectToLiveDebate, setRedirectToLiveDebate] = React.useState(false); 
    const [redirectToCompletedDebate, setRedirectToCompletedDebate] = React.useState(false);
    useEffect(()=>{
        checkShareDebateToken();
    },[])

    const checkShareDebateToken = () => {
        var checkShareDebateAPI = API_Path + "api/debate/checkShareDebateToken/"+params.debateToken;
        axios
            .get(checkShareDebateAPI, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                if(response.data!==null&&response.data.result!==null){
                    setDebateObj(response.data.result);
                    if(response.data.result.status===0){
                        //active Debate page
                        setRedirectToLiveDebate(true);
                    }else if(response.data.result.status===1){
                        //live Debate page
                        setRedirectToLiveDebate(true);
                    }else if(response.data.result.status===2){
                        //completed Debate page
                        setRedirectToCompletedDebate(true);
                    }else if(response.data.result.status===3 || response.data.result.status===4 || response.data.result.status===5){
                        //cancelled Debate  //blocked Debate  //InActive debate
                        if(response.data.result.status===3){
                            alert("Your shared debate is Cancelled status");
                        }else if(response.data.result.status===4){
                            alert("Your shared debate is Blocked status");
                        }else if(response.data.result.status===5){
                            alert("Your shared debate is InActive status");
                        }
                        history.push(`/activeDebateList`);
                    }else{
                        history.push(`/activeDebateList`);
                    }

                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    
    return (
        <React.Fragment>
                {redirectToLiveDebate ? 
                redirectTo('/activeDebate',{
                    debateObj: debateObj,
                    roomName: debateObj._id
                }): ""}
                {redirectToCompletedDebate&&debateObj!==null? redirectTo('/completedDebate',{
                        debateObj: debateObj,
                        roomName: debateObj._id
                    }): ""}
                <div >
                    loading ...
                </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(ShareDebate));
