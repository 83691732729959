import React, { useEffect, useState } from "react";
import {
  IconButton,
  Button,
  Modal,
  Typography,
  Box,
  Grid,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Filter from "../../../assets/images/filter.svg";
import Filtered from "../../../assets/images/filtered.svg";
import { API_Path } from "../../Common/Const";
import DataGridTable from "../../../components/Table/DataGridTable";
import CommonPagination from "../../Common/CommonPagination";
import Loader from "../../Common/Loader";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { BorderLeft, Cancel } from "@material-ui/icons";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import FormikSelect from "../../Common/FormikComponents/FormikSelect";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import AddFundModal from "./AddFundModal";
import { addDays } from "date-fns";
import { LightTooltip } from "./CreateAd";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  historyPage: {
    width: "90%",
    padding: "14px",
  },
  borderStyle: {
    paddingTop: "25px",
    paddingBottom: "25px",
  },
  wallet: {
    borderBottom: "1px solid  #dddfe4",
    width: "100%",
    height: "34px",
    fontSize: "medium",
    fontWeight: "500",
    color: "#ffc003",
  },
  walletText: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#758194",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    padding: "9px 32px !important",
    margin: "16px 0",
  },
  blance: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterIcon: {
    border: "1px solid #fbdd88",
    borderRadius: "0 !important",
    backgroundColor: "white",
    with: "35px",
    height: "35px",
    marginRight: "40px",
  },
  paymentHistory: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  paymentList: {
    "& tr": {
      boxShadow:
        " 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .p-datatable-table": {
      padding: "0 18px",
    },
  },
  pagination: {
    marginRight: "40px",
  },
  textField: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    backgroundColor: "white",
    borderRadius: "10px",
    "& p": {
      margin: "8px 0 0 0",
      fontSize: "13px",
    },
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      // marginTop: "8px",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
    "& .MuiFormLabel-filled": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& .MuiInput-underline": {
      border: "1px solid #d6d6d6 !important",
      padding: "8px",
      margin: 0,
      borderRadius: "4px",
    },
    "& .MuiInput-underline:before,& .MuiInput-underline:hover": {
      borderBottom: "none",
    },
  },
  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "7px",
    borderRadius: "14px",
    fontSize: "30px",
    right: "7px",
  },
  filterFrom: {
    marginTop: "30px",
    "& >div": {
      padding: "14px 0px",
    },
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    justifyContent: "center",
  },
  dateClass: {
    backgroundColor: "#f6f6f6",
    width: "100%",
    padding: "5px !important",
  },
  dateRange: {
    textAlign: "left",
    width: "100%",
    display: "block",
    color: "#313236",
    fontSize: "14px",
    fontWeight: "500",
  },
  labelShrink: {
    "& .MuiInputLabel-root": {
      transform: "translate(0, 1.5px) scale(0.75)",
      transformOrigin: "top left",
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
    },
  },
  Space:{
    borderLeft: "1px solid #dddfe4",
    height: "85px",
    margin: "0px",
    [theme.breakpoints.down("xs")]: {
      border: "none",
      // borderTop:"1px solid black",
      height: "0px",
    },
  },
  walletAmount:{
    fontSize:"12px",
    "& p":{
      margin:"0px"
    },
    "& span":{
      color: "#758194",
      fontSize: "13px",
      fontWeight: "500",
    }
  },
  blanceClass:{
    marginTop: "6px",
    display:"flex",
    gap:"10px" ,
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  }
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  textAlign: "center",
  height: 500,
};

let listData = [
  { label: "Spend on Ad Renewal", value: "Spend on Ad Renewal" },
  { label: "Spend on Advertisement", value: "Spend on Advertisement" },
  { label: "Top up", value: "Top up" },
];

const PaymentHistory = (props) => {
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoader] = useState(false);
  const [totalRecords, settotalRecords] = useState({});
  const [page, setPage] = useState(1);
  const [walletAmount, setWalletAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [addFundModal, setAddFundModal] = useState(false);
  const [initState, setinitState] = useState({
    ad_title: "",
    from_date: null,
    to_date: null,
    transaction_type: "",
  });
  let pageName = "wallet";
  const classes = useStyles();
  useEffect(() => {
    getPaymentList(1);
    getAmount();
  }, []);
  const CreateDateTemplate = (row) => {
    let originalMoment = moment.utc(row.created_at);
    let formattedDateTime = originalMoment.format("DD-MM-YYYY HH:mm");
    return (
      <div>
        <span>{formattedDateTime} UTC</span>
      </div>
    );
  };
  const AmountTemplate = (row) => {
    let amount =
      row.transaction_type === "Top up"
        ? `+ $${row.amount}`
        : `- $${row.amount}`;

    return (
      <div>
        <span
          style={{
            color: `${row.transaction_type === "Top up" ? "green" : "red"}`,
          }}
        >
          {amount}
        </span>
      </div>
    );
  };
  const column = [
    {
      field: "created_at",
      header: "Created On",
      body: CreateDateTemplate,
      headerStyle: { minWidth: "200px" },
    },
    { field: "transaction_type", header: "Transaction Type" },
    { field: "transaction_description", header: "Transaction Description" },
    {
      field: "amount",
      header: "Amount",
      body: AmountTemplate,
    },
  ];
  const getPaymentList = (val, filterData = null) => {
    let api = API_Path + `api/wallet/get_user_wallet_transaction`;

    let page = {
      page: val,
      perPage: 10,
    };
    if (
      filterData != null &&
      (filterData.ad_title.trim() != "" ||
        filterData.from_date != null ||
        filterData.to_date != null ||
        filterData.transaction_type.trim() != "")
    ) {
      setinitState({
        ad_title: filterData.ad_title,
        from_date: filterData.from_date,
        transaction_type: filterData.transaction_type,
        to_date: filterData.to_date,
      });
      page.ad_title = filterData.ad_title;
      page.start_date = filterData.from_date;
      page.transaction_type = filterData.transaction_type;
      page.end_date = filterData.to_date;
    } else {
      setinitState({
        ad_title: "",
        from_date: null,
        to_date: null,
        transaction_type: "",
      });
    }
    setLoader(true);
    axios
      .post(api, page, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setPaymentList(response.data.result.transactions);
          settotalRecords(response.data.result.pagination);
          setPage(response.data.result.pagination.page);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const getAmount = () => {
    let api = API_Path + `api/wallet/get_user_amount`;
    setLoader(true);
    axios
      .post(
        api,
        {},
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setWalletAmount(response.data.result);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const handlePaginationChange = (event, value) => {
    if (value !== page) {
      getPaymentList(value, initState);
      setPage(value);
    }
  };
  const Clear = () => {
    getPaymentList(1);
    setinitState({
      ad_title: "",
      from_date: null,
      to_date: null,
      transaction_type: "",
    });
    setOpenModal(false);
  };

  const handleTooltip = (data) => {
    const keyReplacements = {
      blocked_amount: "Blocked Amount",
      wallet_current_balance: "Current Balance",
      total_wallet_amount: "Total Amount",
    };
    delete walletAmount["_id"];
    const replacedItems = Object.fromEntries(
      Object.entries(walletAmount).map(([key, value]) => [
        keyReplacements[key] || key,
        value,
      ])
    );

    const tip = Object.entries(replacedItems)
      .map(([key, value]) => `${key}: $ ${value}`)
      .join("\n");
    return tip;
  };

  return (
    <>
      {loading && <Loader />}
      <div className={classes.borderStyle}>
        <Box
          sx={{
            display: "flex",
            whiteSpace: "nowrap",
            justifyContent: "center",
            "& > :not(style)": {
              m: 1,
            },
          }}
        >
          <Paper elevation={1} className={classes.historyPage}>
            <div className={classes.wallet}>Wallet</div>

            <div className={classes.blance}>
              <div className={classes.blanceClass}>
                <div>
                  <span className={classes.walletText}>Prepaid Funds</span>
                  {/* <LightTooltip
                  placement="right"
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {handleTooltip(walletAmount)}
                    </div>
                  }
                > */}
                  <h2 style={{ margin: "2px" }}>
                    ${walletAmount.wallet_current_balance}
                  </h2>
                </div>
                <p className={classes.Space}></p>
                <div className={classes.walletAmount}>
                  <span>Fund Summary</span>
                  <p>
                    Current Balance : ${walletAmount.wallet_current_balance}
                  </p>
                  <p>Scheduled Ad Amount : ${walletAmount.blocked_amount}</p>
                  <p>Remaining Amount : ${walletAmount.total_wallet_amount}</p>
                </div>
                {/* </LightTooltip> */}
              </div>
              <div style={{ paddingTop: "15px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx(classes.circleBtn)}
                  onClick={() => {
                    setAddFundModal(true);
                  }}
                >
                  Add Funds
                </Button>
              </div>
            </div>
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "25px",
            whiteSpace: "nowrap",
            "& > :not(style)": {
              m: 1,
            },
          }}
        >
          <Paper elevation={4} className={classes.historyPage}>
            <div className={classes.blance}>
              <span className={classes.paymentHistory}>Payment History</span>
              <IconButton
                size="medium"
                className={classes.filterIcon}
                onClick={() => setOpenModal(true)}
              >
                {initState.ad_title != "" ||
                initState.from_date != null ||
                initState.to_date != null ||
                initState.transaction_type != "" ? (
                  <img alt="" width="18px" src={Filtered}></img>
                ) : (
                  <img alt="" width="18px" src={Filter}></img>
                )}
              </IconButton>
            </div>

            <div className={classes.paymentList}>
              <DataGridTable
                // selectionHeaderStyle={{ width: "6em" }}
                columns={column}
                values={paymentList}
                page={0}
                rows={5}
                dataKey="_id"
              ></DataGridTable>
            </div>
            <div className={classes.pagination}>
              <CommonPagination
                JusCont="end"
                page={page}
                count={totalRecords.totalPages}
                handleChange={handlePaginationChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </Paper>
        </Box>
      </div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Cancel
            className={classes.closeButton}
            style={{
              backgroundColor: "white",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(false)}
          />
          <Typography variant="h4" componeTypographynt="h1">
            Ad Payment Filter
          </Typography>
          <Formik
            initialValues={{ ...initState }}
            onSubmit={async (values) => {
              setinitState(values);
              getPaymentList(1, values);
              setOpenModal(false);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form autoComplete="off" className={classes.filterFrom}>
                <Grid container className={classes.root} spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <Field
                      name="ad_title"
                      label="Advertisement Title"
                      placeholder="Advertisement Title"
                      type="text"
                      variant="outlined"
                      as={FormikInput}
                      className={clsx(classes.textField)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                  <Field
                    name="transaction_type"
                    label="Transaction Type"
                    placeholder="Transaction Type"
                    type="select"
                    variant="outlined"
                    options={listData}
                    as={FormikSelect}
                    className={clsx(classes.textField)}
                  />
                </Grid>
                <label className={classes.dateRange}>Date Range</label>
                <div className={classes.dateClass}>
                  <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                    <label style={{ fontWeight: "500", fontSize: "14px" }}>
                      From
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Field
                        fullWidth
                        variant="inline"
                        component={KeyboardDatePicker}
                        format="dd/MM/yyyy"
                        type="text"
                        name="from_date"
                        placeholder="DD/MM/YYYY"
                        as={FormikInput}
                        onChange={(date) => {
                          setFieldValue("from_date", date);
                          if (
                            values.to_date &&
                            new Date(values.to_date) < date
                          ) {
                            setFieldValue("to_date", null);
                          }
                        }}
                        autoOk
                        className={clsx(classes.textField)}
                        style={{ width: "260px", marginTop: "0px" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                    <label style={{ fontWeight: "500", fontSize: "14px" }}>
                      To
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Field
                        fullWidth
                        variant="inline"
                        component={KeyboardDatePicker}
                        format="dd/MM/yyyy"
                        type="text"
                        name="to_date"
                        placeholder="DD/MM/YYYY"
                        as={FormikInput}
                        autoOk
                        className={clsx(classes.textField, classes.labelShrink)}
                        style={{ width: "260px", marginTop: "0px" }}
                        minDate={addDays(values.from_date, 1)}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </div>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  style={{ paddingLeft: "15px" }}
                >
                  <Button
                    variant="contained"
                    color="white"
                    className={clsx(classes.circleBtn)}
                    onClick={() => Clear()}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={clsx(classes.circleBtn)}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  sm={6}
                  justifyContent="center"
                ></Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      <AddFundModal
        openModal={addFundModal}
        setOpenModal={setAddFundModal}
        auth={props.auth}
        page={pageName}
      />
    </>
  );
};

export default PaymentHistory;
