import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import s3FileUpload from "react-s3";
import * as yup from "yup";
import SnackBar from "../../../components/SnackBar";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import FormikSelect from "../../Common/FormikComponents/FormikSelect";
import MemberStatus from "../../Common/MemberStatus/index";
// import BoostDebate from "../../Common/BoostDebate";
import Switch from "@material-ui/core/Switch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_Path, Make_Base_Auth, getDecryptedHashWithSecret } from "../../Common/Const/index";
import Loader from "../../Common/Loader";
// import OtherActivePlans from "../../Common/OtherActivePlans";
// import UpgradeButton from "../../Common/UpgradeButton";
import editIcon from "../../../assets/images/editIcon.svg";
import banner from "../../../assets/images/profile/banner.png";
import DeleteIcon from "../../../assets/images/delete.svg";
import VDotsMenu from "../../../assets/images/dotMenu.svg";
import ImageCropper from "../../Common/ImageCropper";
import { ImageSizecompress } from "../../../lib/uriUtils";

const useStyles = makeStyles((theme) => ({
  accountProfile: {
    maxWidth: "730px",
    margin: "auto",
    padding: "45px 30px",
    "& .userRating": {
      display: "flex",
      alignItems: "center",
      paddingLeft: "8px",
      "& svg": {
        color: "#ffc003",
      },
    },
  },
  banner: {
    position: "relative",
    "& .imgBanner": {
      width: "100%",
      height: "250px",
      objectFit:'cover'
    },
    "& .bannerEdit": {
      position: "absolute",
      right: "6px",
      bottom: "2px",
      width: "48px",
      // backgroundColor: "rgba(255, 255, 255, 0.35)",
      // boxShadow: "0 2px 4px 0 rgb(0 0 0 / 13%)",
    },
    // "& .bannerImage":{
    //   objectFit:'cover'
    // },
    // "& .bannerImage:hover":{
    //   objectFit:'contain',
    //   transform: 'scale(1)'
    // },
    "& .bannerDefault":{
      width: "100%",
      height: "250px",
      objectFit:'fill'
    }
  },
  headerActionSection: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    // width: '105px',
    // maxWidth: "64px",
    // flex: '0 0 105px',
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
  },
  profileImage: {
    position: "absolute",
    top: "166px",
    left: "10px",
    "& img:first-child": {
      border: "3px solid #ffc003",
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
  },
  textFieldUsertag: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        // padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
        paddingLeft: "26px",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
  },

  textFieldAutocomplete: {
    maxHeight: "inherit",
    minHeight: "inherit",
    "& .MuiAutocomplete-inputRoot": {
      padding: "0",
      backgroundColor: "#fff",
      maxHeight: "inherit",
    },
  },
  switchPosition: {
    position: "relative",
    "& .MuiFormControlLabel-root": {
      position: "absolute",
      bottom: "38px",
      right: "0",
    },
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  textFieldArea: {
    maxHeight: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: "inherit",
      "& .MuiOutlinedInput-input": {
        height: "inherit",
      },
    },
  },
}));

const listData = [
  {
    label: "1",
    value: "Male",
  },
  {
    label: "2",
    value: "Female",
  },
  {
    label: "3",
    value: "Non Binary",
  },
];
let {  REACT_APP_AWS_S3_REGINE, REACT_APP_AWS_S3_ACCESSID, REACT_APP_AWS_S3_SECRET_ACCESSID,REACT_APP_AWS_S3_USER_PROFILE_BUGKET_NAME } = process.env
// const config = {
//   bucketName: REACT_APP_AWS_S3_USER_PROFILE_BUGKET_NAME,
//   region: REACT_APP_AWS_S3_REGINE,
//   accessKeyId: REACT_APP_AWS_S3_ACCESSID,
//   secretAccessKey:REACT_APP_AWS_S3_SECRET_ACCESSID,
// };
const config = {
  bucketName: REACT_APP_AWS_S3_USER_PROFILE_BUGKET_NAME,
  region: REACT_APP_AWS_S3_REGINE,
  accessKeyId: "",
  secretAccessKey:"",
};

const AccountProfile = (props) => {
  const classes = useStyles();
  const [snackbarState, setSnackbarState] = useState({
    open: false,
  });
  const [classificationDetails, setclassificationDetails] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [userDatas, setUserDatas] = useState([]);
  const [initState, setinitState] = useState({
    first_name: "",
    last_name: "",
    user_tag: "",
    gender: "",
    country: "",
    state: "",
    city: "",
    email: "",
    phone: "",
    bio: "",
    account_type:''
  });
  const [userId, setUserId] = useState();
  const [switchValue, setSwitchValue] = useState({
    citySwitch: true,
    stateSwitch: true,
    countrySwitch: true,
    emailSwitch: true,
    phoneSwitch: true,
    DisableEmailNotification: true,
  });
  const [ptc, setPtc] = React.useState("");
  const [country, setCountry] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [cityyn, setCityyn] = React.useState(0);
  const [stateyn, setStateyn] = React.useState(0);
  const [countryyn, setCountryyn] = React.useState(0);
  const [emailyn, setEmailyn] = React.useState(0);
  const [mobileyn, setmobileyn] = React.useState(0);
  const [emailNotificationyn, setEmailNotificationyn] = React.useState(0);
  const [ptcError, setPtcError] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [profileKey, setProfileKey] = useState("")
  const [profileSecret, setProfileSecret] =useState("")
  let src = userDatas && !!userDatas.banner ? userDatas.banner : banner;

  // const [emailNotification, setEmailNotification] = React.useState({
  //     DisableEmailNotification: false,
  // });

  // const handleCheckboxChange = (event) => {
  //     setEmailNotification({ ...emailNotification, [event.target.name]: event.target.checked });
  // };

  useEffect(() => {
    setLoader(true);
    getclassification();
    getCountryList();
    if (props && props.user._id) {
      let data = getDecryptedHashWithSecret(props.hashed)
      getUserViewdata(props.user._id);
      setUserId(props.user._id);
      setProfileKey(data.profileApiKey)
      setProfileSecret(data.profileApiSecret)
    }
  }, []);

  useEffect(() => {
    if (userDatas) {
      setinitState({
        first_name:
          userDatas && userDatas.first_name ? userDatas.first_name : "",
        last_name: userDatas && userDatas.last_name ? userDatas.last_name : "",
        user_tag:
          userDatas && userDatas.user_tagging ? userDatas.user_tagging : "",
        gender: userDatas && userDatas.gender ? userDatas.gender : "",
        country:
          userDatas && userDatas.full_address && userDatas.full_address.country
            ? userDatas.full_address.country
            : "",
        state:
          userDatas && userDatas.full_address && userDatas.full_address.state
            ? userDatas.full_address.state
            : "",
        city:
          userDatas && userDatas.full_address && userDatas.full_address.city
            ? userDatas.full_address.city
            : "",
        email: userDatas && userDatas.email ? userDatas.email : "",
        phone: userDatas && userDatas.mobile ? userDatas.mobile : "",
        bio: userDatas && userDatas.user_bio ? userDatas.user_bio : "",
        account_type: userDatas && (userDatas.account_type!==null&&userDatas.account_type!==undefined) ? userDatas.account_type : "",
      });
      setPtc(userDatas.tags ? userDatas.tags : "");
      setCityyn(userDatas.city_yn);
      setStateyn(userDatas.state_yn);
      setCountryyn(userDatas.country_yn);
      setEmailyn(userDatas.email_yn);
      setmobileyn(userDatas.mobile_yn);
      setEmailNotificationyn(userDatas.is_disable_notification_email);
      setSwitchValue({
        citySwitch: userDatas.city_yn === 1 ? true : false,
        stateSwitch: userDatas.state_yn === 1 ? true : false,
        countrySwitch: userDatas.country_yn === 1 ? true : false,
        emailSwitch: userDatas.email_yn === 1 ? true : false,
        phoneSwitch: userDatas.mobile_yn === 1 ? true : false,
        DisableEmailNotification:
          userDatas.is_disable_notification_email === 1 ? true : false,
      });
      // setEmailNotification({
      //     DisableEmailNotification: userDatas.is_disable_notification_email === 1 ? true : false,
      // })
      if (
        userDatas &&
        userDatas.full_address &&
        userDatas.full_address.country
      ) {
        getStateList(userDatas.full_address.country);
        if (userDatas && userDatas.full_address && userDatas.full_address.state)
          getCityList(
            userDatas.full_address.country,
            userDatas.full_address.state
          );
      }
    }
  }, [userDatas]);

  const submitSignUp = async () => {
    setSnackbarState({
      open: true,
      message: " Account updated successfully",
      severity: "success",
    });
  };

  const getCountryList = () => {
    setLoader(true);
    var countryListApi = API_Path + "api/country/get";
    axios
      .get(countryListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        setCountryList(response.data.result);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const getclassification = () => {
    setLoader(true);
    var classificationDetails = API_Path + "debateClassification/get";
    axios
      .get(classificationDetails, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        // props.handleClassificationList(response.data.result)
        // const classificationList = response.data.result.map(v => ({ label: v._id, value: v.name }));
        setclassificationDetails(response.data.result);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const getUserViewdata = (id) => {
    setLoader(true);
    axios
      .get(API_Path + `api/auth/user/${id}`, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setUserDatas(response.data.result);
        setProfileImage(response.data.result.profile_image);
        let full_address_obj = response.data.result?.full_address;
        setCountry(
          full_address_obj && full_address_obj.country
            ? {
                name: full_address_obj.country,
                code: full_address_obj.country_code,
              }
            : null
        );
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const updateUserdata = async (state) => {
    let usertag =
    userDatas&& (!userDatas.user_tagging||userDatas.user_tagging === "") && state.user_tag ? false : true;
    try {
      if (ptc !== "" && ptc !== {} && ptc.length !== 0) {
        setPtcError(false);
        setLoader(true);
      } else {
        setPtcError(true);
        return true;
      }
      let userUpdateAPI = API_Path + "api/auth/user/" + userId;
      let userTagCheck = API_Path + "api/user/validate/usertagging";
      let tagobj = {
        tag: state.user_tag ? state.user_tag : "",
      };
      let inputObj = {
        inputObject: {
          _id: userId,
          first_name: state.first_name ? state.first_name : "",
          last_name: state.last_name ? state.last_name : "",
          user_tagging: state.user_tag ? state.user_tag : "",
          gender: state.gender ? state.gender : "",
          tags: ptc ? ptc : "",
          full_address: {
            country: country ? country.name : "",
            country_code: country ? country.code : "",
            state: state.state ? state.state : "",
            city: state.city ? state.city : "",
          },
          email: state.email ? state.email : "",
          mobile: state.phone ? state.phone : "",
          user_bio: state.bio ? state.bio : "",
          account_type: state.account_type,
          city_yn: cityyn,
          state_yn: stateyn,
          country_yn: countryyn,
          email_yn: emailyn,
          mobile_yn: mobileyn,
          is_disable_notification_email: emailNotificationyn,
          // "is_disable_notification_email": emailNotification.DisableEmailNotification === true ? 1 : 0,
        },
      };
      if (!usertag) {
        await axios
          .post(userTagCheck, tagobj, {
            headers: {
              Authorization: "Basic " + props.auth.accessToken,
            },
          })
          .then((res) => {
            usertag = res.data.status === 1 ? true : false;
            res.data.status === 0 &&
              setSnackbarState({
                open: true,
                message: "User handle already selected by another Vurbil user. Please select a different user handle.",
                severity: "error",
              });
            setTimeout(() => {
              setLoader(false);
            }, 1000);
          })
          .catch((error) => {
            setLoader(false);
            console.error(error);
          });
      }

      if (usertag) {
        await axios
          .put(userUpdateAPI, inputObj, {
            headers: {
              Authorization: "Basic " + props.auth.accessToken,
            },
          })
          .then((response) => {
            getUserViewdata(props.user._id);
            //setProfileImage(response.data.result.profile_image)
            submitSignUp();
            setTimeout(() => {
              setLoader(false);
            }, 1000);
          })
          .catch((error) => {
            setLoader(false);
            console.error(error);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  const validationSchema = yup.object({
    first_name: yup.string().required("First Name is Required"),
    last_name: yup.string().required("Last Name is Required"),
    gender: yup.string().required("gender is Required"),
    //ptc: yup.string().required('ptc is Required'),
    // country: yup.string().required('country is Required'),
    // state: yup.string().required('state is Required'),
    // city: yup.string().required('city is Required'),
    email: yup.string().email("Invalid email").required("Email is Required"),
    phone: yup
      .string()
      .matches(/^[0-9]*$/, "Invalid Number")
      .min(10, "Number should be atleast 10 digits")
      .max(11, "Number should be atleast 10 digits"),
    bio: yup.string(),
    user_tag: yup
      .string()
      .required("User Handle Required ")
      .matches(/^[a-zA-Z0-9_]+$/, "space and special character not allowed")
      .max(30, "Maximum 30 characters"),
  });

  const handleChange = (event) => {
    setSwitchValue({
      ...switchValue,
      [event.target.name]: event.target.checked,
    });
    if (event.target.name === "emailSwitch") {
      event.target.checked === true ? setEmailyn(1) : setEmailyn(0);
    } else if (event.target.name === "phoneSwitch") {
      event.target.checked === true ? setmobileyn(1) : setmobileyn(0);
    } else if (event.target.name === "DisableEmailNotification") {
      event.target.checked === true
        ? setEmailNotificationyn(1)
        : setEmailNotificationyn(0);
    } else if (event.target.name === "citySwitch") {
      event.target.checked === true ? setCityyn(1) : setCityyn(0);
    } else if (event.target.name === "stateSwitch") {
      event.target.checked === true ? setStateyn(1) : setStateyn(0);
    } else if (event.target.name === "countrySwitch") {
      event.target.checked === true ? setCountryyn(1) : setCountryyn(0);
    }
  };

  const handlePtc = (newValue) => {
    if (newValue) {
      setPtc(newValue);
    }
    setPtcError(false);
  };
  const handleCountry = (obj) => {
    if (obj) {
      setCountry(obj);
    } else {
      setCountry(null);
    }
    setState(null);
    setCity(null);
    setStateList([]);
    setCityList([]);
    getStateList(obj ? obj.name : "");
  };

  const handleProfile = (img) => {
    setProfileImage(img);
  };
  const handleMobileNumber = (event) => {
    // event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57
    if (event.which > 57) {
      event.preventDefault();
    }
  };
  const getStateList = async (country) => {
    if (!country) return false;
    setLoader(true);
    var countryListApi = API_Path + `api/country/getState/${country}`;
    axios
      .get(countryListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        const data = response.data.result.filter((val) => val._id && val.name);
        setStateList(data);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
        //selected state object creation
        if (
          userDatas &&
          userDatas.full_address &&
          userDatas.full_address.state
        ) {
          let selectState = response.data.result.filter(
            (val) => val.name === userDatas.full_address.state
          );
          setState(selectState.length > 0 ? selectState[0] : null);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const getCityList = async (country, state) => {
    if (!country || !state) return false;
    setLoader(true);
    var countryListApi = API_Path + `api/country/getCity/${country}/${state}`;
    axios
      .get(countryListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        // console.log(response)
        const data = response.data.result.filter((val) => val._id && val.name);
        setCityList(data);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
        // selected city object creation
        if (
          userDatas &&
          userDatas.full_address &&
          userDatas.full_address.city
        ) {
          let selectedCity = response.data.result.filter(
            (val) => val.name === userDatas.full_address.city
          );
          setCity(selectedCity.length > 0 ? selectedCity[0] : null);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const handleDialogClose = () => {
    setImagePreviewUrl("");
    document.getElementById("uploadBannerFile").value = "";
    setOpenDialog(false);
  };
  const fileDelete = async (url) => {
    const fileName = url.split("/")[url.split("/").length - 1];
    config.accessKeyId = profileKey;
    config.secretAccessKey = profileSecret;
    await s3FileUpload
      .deleteFile(fileName, config)
      .then((data) => console.log(data))
      .catch((e) => console.log("err", e));
  };
  const handleBannerImage = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
      reader.onload = () => {
        setImagePreviewUrl(reader.result);
        setOpenDialog(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    // let file = new File(
    //   [e.target.files[0]],
    //   `b__${props.user._id}__${Date.now().toString()}__${
    //     e.target.files[0].name
    //   }`
    // );
    // userDatas &&
    //   userDatas.banner &&
    //   fileDelete(bannerImg ? bannerImg : userDatas.banner);
    // setLoader(true);
    // let result = await s3FileUpload
    //   .uploadFile(file, config)
    //   .then((data) => data)
    //   .catch((e) => console.log("err", e));
    //   uploadBanner(result.location)
  };
  const uploadImage = async(file) => {
    userDatas &&
      userDatas.banner &&
      fileDelete(userDatas.banner);
      setLoader(true);
    config.accessKeyId = profileKey;
    config.secretAccessKey = profileSecret;
    const compressorfile=await ImageSizecompress(file);
    let result = await s3FileUpload
      .uploadFile(compressorfile, config)
      .then((data) => data)
      .catch((e) => console.log("err", e));
      uploadBanner(result.location)
  }
  const croppedResult =  (croppedCanvasobj) => {
    if (croppedCanvasobj) {
      croppedCanvasobj.getCroppedCanvas().toBlob(function (blob) {
        blob.lastModifiedDate = new Date();
        blob.name = `b__${props.user._id}__${Date.now().toString()}`;
        //setFile(blob)
        // setFile(blob)
        //setImagePreviewUrl(imagePreviewUrl)
        uploadImage(blob);
        
        document.getElementById("uploadBannerFile").value = "";
        setOpenDialog(false);
        // if (document.getElementById("profile-img")) {
        //   document.getElementById("profile-img").src =
        //     URL.createObjectURL(blob);
        // }
        // setSnackbarState({
        //   open: true,
        //   message: "User Image Updated",
        //   severity: "success",
        // });
      });
    }
  };
  const uploadBanner = async(location) => {
    let Debatebanner = API_Path + `api/user/update/banner`;
    await axios
      .post(
        Debatebanner,
        { banner:location , id: props.user._id },
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((response) => {
        setSnackbarState({
          open: true,
          message: "Banner Updated successfully",
          severity: "success",
        });
        // setBannerImg({result.location});
        setUserDatas({...userDatas,banner:location})
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const setBannerOnError = () => {
    setUserDatas({...userDatas,banner:""})
  }
    const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const actionSectionHeader = (
    <div className={classes.headerActionSection}>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          className="bannerEdit"
          onClick={handleClick}
        >
          <img alt="" src={VDotsMenu} />
        </IconButton>
         
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleCloseMenu}>
            <IconButton
            aria-label="upload picture"
            component="label"
            style={{padding:"0",fontSize:"12px",transition:"none",color:"#313236"}}
          > <input
          id="uploadBannerFile"
          hidden
          accept="image/jpeg,image/png,image/webp"
          type="file"
          onChange={handleBannerImage}
        />Upload</IconButton>
           </MenuItem>
            {userDatas && userDatas.banner&&<MenuItem onClick={() => {fileDelete(userDatas.banner);uploadBanner("");handleCloseMenu()}}>
              Reset</MenuItem>}       
          </Menu>
        
      </Box>
    </div>
  );
  return (
    <div className={classes.accountProfile}>
      <Box style={{ position: "relative" }}>
        <div className={classes.banner}>
          <img src={src} className={userDatas && !!userDatas.banner ?"imgBanner":"bannerDefault"} alt="bannerImage" onError={setBannerOnError} />
          {/* <p style={{position: 'absolute', fontSize: '11px', color: 'rgb(117,129,148)', margin: '0',right: "8px",bottom: "-10px" }}>
              Banner Image - Best fit:  750 X 250
            </p> */}
          {/* <IconButton
            aria-label="upload picture"
            component="label"
            className="bannerEdit"
          >
            <input
              hidden
              accept="image/jpeg,image/png,image/webp"
              type="file"
              onChange={handleBannerImage}
            />
            <img src={editIcon} alt="" />
          </IconButton> */}
          {actionSectionHeader}
        </div>
        {/* <Grid container alignItems="center" style={{ marginBottom: "24px" }}> */}
        {/* <Grid item style={{ marginRight: "36px" }}> */}
        <div className={classes.profileImage}>
          <MemberStatus
            list={userDatas}
            editIconShow={true}
            ratingED={true}
            profileImage={profileImage}
            handleProfile={handleProfile}
            showMembersince={true}
          />
        </div>
        <h6
          style={{
            color: "#758194",
            fontWeight: "normal",
            display: "flex",
            alignItems: "center",
            margin: "6px 0 40px 160px",
          }}
        >
          Member since{" "}
          {userDatas && moment(userDatas.created_at).format("MMM YYYY")}
          <span className="userRating">
            <StarIcon />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {" "}
              {userDatas && userDatas.rating ? userDatas.rating : "0"}
            </span>
          </span>
        </h6>
        {/* </Grid> */}
        {/* <Grid item> */}
        {/* <Box style={{display:'flex',justifyContent:'flex-end',marginBottom:'80px'}}>
              <BoostDebate />
              <div style={{ marginTop: "16px" }}>
                <UpgradeButton />
                <OtherActivePlans />
              </div>
            </Box> */}
        {/* </Grid> */}
        {/* </Grid> */}
      </Box>
      <Formik
        enableReinitialize
        initialValues={{ ...initState }}
        onSubmit={updateUserdata}
        validationSchema={validationSchema}
        validateOnBlur={true}
      >
        {(formprops) => {
          return (
            <Form autoComplete="off">
              <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="first_name"
                    label="First Name"
                    placeholder=""
                    type="text"
                    variant="outlined"
                    as={FormikInput}
                    className={clsx(classes.textField)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="last_name"
                    label="Last Name"
                    placeholder=""
                    type="text"
                    variant="outlined"
                    as={FormikInput}
                    className={clsx(classes.textField)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ position: "relative" }}>
                  <span
                    style={{
                      position: "absolute",
                      top: "43%",
                      left: "6%",
                      color: "#758194",
                    }}
                  >
                    @
                  </span>
                  <Field
                    name="user_tag"
                    label="User Handle"
                    placeholder=""
                    type="text"
                    variant="outlined"
                    as={FormikInput}
                    className={clsx(classes.textFieldUsertag)}
                    disabled={userDatas.user_tagging ? true : false}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Field
                    name="gender"
                    label="Gender"
                    placeholder=""
                    type="select"
                    variant="outlined"
                    options={listData}
                    as={FormikSelect}
                    className={clsx(classes.textField)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    onChange={(event, newValue) => {
                      handlePtc(newValue);
                    }}
                    id="tags-standard"
                    options={classificationDetails && classificationDetails}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    value={ptc && ptc ? ptc : []}
                    getOptionSelected={(option, value) =>
                      value.name === option.name
                    }
                    // defaultValue={ptc && ptc ? ptc : []}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <Field
                        {...params}
                        name="ptc"
                        label="Preferable topic classification"
                        placeholder="Search"
                        type="text"
                        as={FormikInput}
                        className={clsx(
                          classes.textField,
                          classes.textFieldAutocomplete
                        )}
                        variant="outlined"
                      />
                    )}
                  />
                  {ptcError ? (
                    <div style={{ fontSize: "0.75rem", color: "#f44336" }}>
                      {"Ptc is Required"}
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.switchPosition}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchValue.countrySwitch}
                          onChange={handleChange}
                          name="countrySwitch"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      }
                      label={switchValue.countrySwitch ? "Show" : "Hide"}
                      labelPlacement="start"
                    />
                    <Autocomplete
                      id="country-combo-box"
                      options={countryList}
                      getOptionLabel={(option) => option.name}
                      value={country}
                      getOptionSelected={(option, value) => {
                        return value.name === option.name;
                      }}
                      onChange={(e, value) => {
                        handleCountry(value);
                        formprops.setFieldValue(
                          "country",
                          value ? value.name : ""
                        );
                        formprops.setFieldValue("state", "");
                        formprops.setFieldValue("city", "");
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <Field
                          {...params}
                          name="country"
                          label="Country"
                          placeholder=""
                          type="text"
                          variant="outlined"
                          as={FormikInput}
                          className={clsx(
                            classes.textField,
                            classes.textFieldAutocomplete
                          )}
                        />
                      )}
                    />
                    {/* <Field name="country" label="Country" placeholder="" type="text" variant="outlined" as={FormikInput} className={clsx(classes.textField)} /> */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.switchPosition}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchValue.stateSwitch}
                          onChange={handleChange}
                          name="stateSwitch"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      }
                      label={switchValue.stateSwitch ? "Show" : "Hide"}
                      labelPlacement="start"
                    />
                    <Autocomplete
                      id="state-combo-box"
                      options={stateList}
                      getOptionLabel={(option) => option.name}
                      value={state}
                      getOptionSelected={(option, value) => {
                        return value.name === option.name;
                      }}
                      onChange={(e, value) => {
                        if (value) setState(value);
                        else setState(null);
                        getCityList(
                          country ? country.name : "",
                          value ? value.name : ""
                        );
                        formprops.setFieldValue(
                          "state",
                          value ? value.name : ""
                        );
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <Field
                          {...params}
                          name="state"
                          label="State"
                          placeholder=""
                          type="text"
                          variant="outlined"
                          as={FormikInput}
                          className={clsx(
                            classes.textField,
                            classes.textFieldAutocomplete
                          )}
                        />
                      )}
                    />
                    {/* <Field
                      name="state"
                      label="State"
                      placeholder=""
                      type="text"
                      variant="outlined"
                      as={FormikInput}
                      className={clsx(classes.textField)}
                    /> */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.switchPosition}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchValue.citySwitch}
                          onChange={handleChange}
                          name="citySwitch"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      }
                      label={switchValue.citySwitch ? "Show" : "Hide"}
                      labelPlacement="start"
                    />
                    <Autocomplete
                      id="state-combo-box"
                      options={cityList}
                      getOptionLabel={(option) => option.name}
                      value={city}
                      getOptionSelected={(option, value) => {
                        return value.name === option.name;
                      }}
                      onChange={(e, value) => {
                        if (value) setCity(value);
                        else setCity(null);
                        formprops.setFieldValue(
                          "city",
                          value ? value.name : ""
                        );
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <Field
                          {...params}
                          name="city"
                          label="City"
                          placeholder=""
                          type="text"
                          variant="outlined"
                          as={FormikInput}
                          className={clsx(
                            classes.textField,
                            classes.textFieldAutocomplete
                          )}
                        />
                      )}
                    />
                    {/* <Field
                      name="city"
                      label="City"
                      placeholder=""
                      type="text"
                      variant="outlined"
                      as={FormikInput}
                      className={clsx(classes.textField)}
                    /> */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.switchPosition}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchValue.emailSwitch}
                          onChange={handleChange}
                          name="emailSwitch"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      }
                      label={switchValue.emailSwitch ? "Show" : "Hide"}
                      labelPlacement="start"
                    />
                    <Field
                      name="email"
                      label="Email"
                      placeholder=""
                      type="text"
                      variant="outlined"
                      disabled
                      as={FormikInput}
                      className={clsx(classes.textField)}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.switchPosition}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchValue.phoneSwitch}
                          onChange={handleChange}
                          name="phoneSwitch"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      }
                      label={switchValue.phoneSwitch ? "Show" : "Hide"}
                      labelPlacement="start"
                    />
                    <Field
                      name="phone"
                      label="Phone"
                      placeholder=""
                      type="number"
                      variant="outlined"
                      as={FormikInput}
                      className={clsx(classes.textField)}
                      onKeyPress={handleMobileNumber}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="bio"
                    label="Bio"
                    placeholder=""
                    type="textarea"
                    variant="outlined"
                    as={FormikInput}
                    className={clsx(classes.textField, classes.textFieldArea)}
                    multiline={true}
                    minRows={4}
                  />
                </Grid>
                <Grid container xs={12} sm={6} display='flex' alignContent="center" alignItems="center">
                  <FormControlLabel
                    control={
                      <Field
                        label="Account Type"
                        name="account_type"
                        component={Switch}
                        onChange={(e) => formprops.setFieldValue(
                          "account_type", e.target.checked ? 1 : 0)}
                        checked={formprops.values.account_type === 1 ? true : false} // can't set/get state here
                        value={formprops.values.account_type} // or here
                      />
                    }
                    label={'Automatically accept the Sting request'}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={switchValue.DisableEmailNotification}
                        onChange={handleChange}
                        name="DisableEmailNotification"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Disable Email Notification"
                    labelPlacement="end"
                  />
                  {/* <FormControlLabel
                                    control={<Checkbox checked={emailNotification.DisableEmailNotification} onChange={handleCheckboxChange} name="DisableEmailNotification" color="primary" />}
                                    label="Disable Email Notification"
                                /> */}
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={formprops.isSubmitting}
                    className={clsx(classes.circleBtn)}
                  >
                    Save
                  </Button>
                  {/*  <Button size="small"href='#'> Cancel</Button> */}
                </Grid>
              </Grid>
              <SnackBar
                open={snackbarState.open}
                message={snackbarState.message}
                severity={snackbarState.severity}
                onClose={onClose}
              />
            </Form>
          );
        }}
      </Formik>
      {loader && <Loader />}
      <ImageCropper
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        imagePreviewUrl={imagePreviewUrl}
        setOpenDialog={setOpenDialog}
        croppedResult={croppedResult}
        ratio={750 / 250}
       />
    </div>
  );
};
const mapStateToProps = ({ loginDetails: { user, auth,hashed } }) => {
  return {
    user,
    auth,
    hashed
  };
};
export default withRouter(connect(mapStateToProps)(AccountProfile));
