import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import loginBg from "../../../assets/images/loginBg.png";
import logoImage from "../../../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  loginBg: {
    backgroundSize: "cover",
    height: "100vh",
    padding: "30px 100px 100px 30px",
    display: "flex",
    flexFlow: "column",
    boxSizing: "border-box",
  },
  adminLoginContainer: {
    width: "300px",
    margin: "auto 0 auto auto",
    backgroundColor: "#fff",
    "& h1": {
      fontSize: "26px",
    },
    "& h4": {
      fontSize: "16px",
      color: "#758194",
      marginBottom: "8px",
    },
  },
  adminLogo: {
    width: "120px",
    marginBottom: "10px",
  },
  textField: {
    marginTop: theme.spacing(6),
    maxHeight: theme.spacing(5.5),
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5.5),
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "12px",
      color: "#758194",
      transform: "translate(0px, -20px) scale(1)",
    },
    "& legend": {
      display: "none",
    },
  },
  rememberFP: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1.75),
    "& a, & label": {
      fontSize: "10px",
      color: "#758194",
      textDecoration: "none",
    },
    "& label": {
      "& .MuiFormControlLabel-label": {
        fontSize: "11px",
      },
      "& svg": {
        width: "16px",
        height: "16px",
      },
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffc003",
    },
    secondary: {
      light: "#0066ff",
      main: "#61cbc9",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30,
        fontWeight: "400",
        textTransform: "none",
        padding: "13px 36px!important",
        fontSize: "14px",
        lineHeight: "21px!important",
        boxShadow: "none!important",
        color: "#fff!important",
      },
    },
  },
});

const AdminSignin = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.loginBg}
      style={{ backgroundImage: "url(" + loginBg + ")" }}
    >
      <div className={classes.adminLoginContainer}>
        <img alt="" src={logoImage} className={classes.adminLogo} />
        <h1 className="m-0">Welcome Back!!!</h1>
        <h4 className="m-0 font-weight-normal">
          Dignissimos ducimus qui blanditiis
        </h4>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            required
            id="outlined-required"
            label="User ID"
            defaultValue=""
            variant="outlined"
            fullWidth
            className={clsx(classes.textField)}
          />
          <TextField
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            fullWidth
            className={clsx(classes.textField)}
          />
          <div className={classes.rememberFP}>
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" />}
              label="Remember me"
              labelPlacement="end"
            />
            <a href={() => false}>Forgot password?</a>
          </div>
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={clsx(classes.loginButton)}
            >
              Sign in
            </Button>
          </ThemeProvider>
        </form>
      </div>
    </div>
  );
};

export default AdminSignin;
