import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    streamVideo: {
        margin: 'auto 0 0 0',
        textAlign: 'right',
        "& .debateStatus": {
            padding: '4px 10px 4px 10px',
            borderRadius: '13px',
            backgroundColor: '#e8eaed',
            color: '#313236',
            fontSize: '12px',
            lineHeight: '1.5',
            position: 'relative',
            display: 'inline-block',
            maxWidth: '110px',
            '& span': {
                width: '6px',
                height: '6px',
                boxShadow: '0 0 0 3px #fff',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '10px',
                padding: '0',
                "&.Active": {
                    backgroundColor: '#29c93c',
                },
                "&.Live": {
                    backgroundColor: '#f52f2f',
                },
                "&.Completed": {
                    backgroundColor: '#50b5ff',
                },
                "&.Cancelled": {
                    backgroundColor: '#ccc',
                },
                "&.Blocked": {
                    backgroundColor: '#1a1a1a',
                },
                "&.Inactive": {
                    backgroundColor: '#ccc',
                }
            }
        }
    },
}))
const DebateStatus = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.streamVideo}>
            {props.debateBlocked === 1 ?
                <div className="debateStatus">
                    <><span className='Blocked'></span>Blocked</>
                </div>
                :
                <div className="debateStatus">
                    {props.debateStatusList === 0 && <><span className='Active'></span>Active</>}
                    {props.debateStatusList === 1 && <><span className='Live'></span>Live</>}
                    {props.debateStatusList === 2 && <><span className='Completed'></span>Completed</>}
                    {props.debateStatusList === 3 && <><span className='Cancelled'></span>Cancelled</>}
                    {props.debateStatusList === 4 && <><span className='Blocked'></span>Blocked</>}
                    {props.debateStatusList === 5 && <><span className='Inactive'></span>Inactive</>}
                </div>
            }

        </div>
    )
}

export default DebateStatus
