import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import chatHeader from '../../../assets/images/friendsProfile/floatingchat.png';
import useUserTrackingSocket from "../../Common/Sockets/useUserTrackingSocket";
import ChatFriendsList from "./ChatFriendsList";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    chatBubble: {
        // right: '40px',
        // bottom: '75px',
        position: 'relative',
        // zIndex: '9',
        cursor: 'pointer',
        "& img": {
            // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
            
            borderRadius: '50%',
        },
        "& span": {
            top: '-5px',
            right: '-13px',
            position: 'absolute',
            background: '#ffc003',
            padding: '5px',
            borderRadius: '50%',
            display: 'inline-block',
            lineHeight: '1',
            fontSize: '12px',
            fontWeight: '600',
            minWidth: '22px',
            textAlign: 'center',
        }
    }
}))

const ChatBubble = (props) => {
    const classes = useStyles();
    const [isShowChat, setIsShowChat] = useState(false)
    const { resetUserChatNotificationCount } = useUserTrackingSocket(props);
    let params = new URLSearchParams(window.location.search);
    let chatId = params.get('chatId');
    const closeChatWindow = () => {
        setIsShowChat(!isShowChat)
    }
    useEffect(() => {
        setIsShowChat(false)
    }, [])
    // OverAll Notification Chat Open
    useEffect(()=>{
        if (chatId) {
            setIsShowChat(true)
        }
    },[chatId])
    return (
        <Box display="flex" justifyContent="center">
            <Tooltip title="Chat" placement="top">
                <Box className={classes.chatBubble} onClick={closeChatWindow}>
                    <img src={chatHeader} width='35px' alt="" />
                    {resetUserChatNotificationCount !== null && <span>{resetUserChatNotificationCount}</span>}
                </Box>
            </Tooltip>
            {isShowChat && <ChatFriendsList closeChatWindow={closeChatWindow} chatId={chatId}/>}
        </Box>
    )
}


const mapStateToProps = ({ loginDetails: { user, auth }, GlobalSearch: globalSearch }) => {
    return {
        user, auth
    };
};

export default connect(mapStateToProps)(ChatBubble);
// export default ChatBubble
