import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import maskImage from "../../../assets/images/activeDebate/maskImage.png";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import Modal from "../../../components/Modal";
import SnackBar from "../../../components/SnackBar";
import Advertisement from "../../Common/Advertisement";
import ConfirmationModal from "../../Common/ConfirmationModal";
import {
  API_Path,
  getDecryptedHashWithSecret,
  redirectTo,
} from "../../Common/Const";
import CustomComments from "../../Common/CustomComments";
import CustomDebateHeaderATC from "../../Common/CustomDebateHeaderATC";
import CustomImage from "../../Common/CustomImage";
import Loader from "../../Common/Loader";
import ShowMoreLessText from "../../Common/ShowMoreLessText";
import useDebateExtendedTimerSocket from "../../Common/Sockets/useDebateExtendedTimerSocket";
import useDebateTimerSocket from "../../Common/Sockets/useDebateTimerSocket";
import InstantDebateBanner from "./InstantDebateBanner";
import ScheduleDebateBanner from "./ScheduleDebateBanner";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > div": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 400px)",
      },
      "&:only-child": {
        [theme.breakpoints.up("md")]: {
          width: "100%",
        },
      },
    },
  },
  leftSection: {
    width: "100%",
    padding: "36px",
    backgroundColor: "#fff",
  },
  rightSection: {
    padding: "24px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 400px",
      maxWidth: "400px",
      padding: "36px",
    },
  },
  anyOneSlide: {
    borderRadius: "12px",
    padding: "36px 36px 16px",
    backgroundColor: "#f6f7f8",
    position: "relative",
    height: "100%",
    "& .avatarImage": {
      // height: '250px',
      textAlign: "center",
      display: "flex!important",
      flexFlow: "column",
      alignItems: "center",
      "& > div": {
        borderRadius: "0",
      },
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
        margin: "auto",
        width: 200,
        height: 200,
        objectFit: "cover",
      },
    },
  },
  positionRelative: {
    position: "relative",
    margin: "30px 0 18px 0",
    "& > div:first-child": {
      position: "absolute",
      "& img": {
        marginLeft: "15%",
      },
    },
  },
  startIn: {
    color: "#fff",
    backgroundImage:
      "url(" +
      maskImage +
      "), linear-gradient(to right, #2272bd 0%,#274688 100%)",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    borderRadius: "12px",
    margin: "30px 0 18px 0",
    padding: "36px 36px 16px",
    position: "relative",
    zIndex: "0",
    overflow: "hidden",
    "&:before": {
      content: '""',
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      backgroundColor: "rgba(50,93,124,.65)",
      zIndex: "-1",
    },
  },
  robotText: {
    "&.robotTextVideo": {
      display: "flex",
      alignItems: "center",
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    boxShadow: "none",
    border: "solid 1px #cdd1d8",
    padding: "24px 25px 20px",
    borderRadius: "12px",
    width: "100%",
    marginBottom: "24px",
  },
  seperator: {
    borderBottom: "solid 1px #cdd1d8;",
    margin: "18px 0 18px 0",
  },
  circlePOT: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "8px auto 0",
    color: "#fff",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    "&.disabled": {
      pointerEvents: "none",
      cursorPointer: "none",
      opacity: ".5",
    },
  },
  viewProfileBtn: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#758194",
    textTransform: "capitalize",
  },
  robotInfo: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      textTransform: "capitalize",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
    "&+.Mui-error": {
      textTransform: "capitalize",
    },
  },
  textFieldArea: {
    maxHeight: "inherit",
    "& .MuiInputBase-formControl": {
      maxHeight: "inherit",
    },
  },
  askToss: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "& .tossContainer": {
      display: "flex",
      flexFlow: "column",
      "& img": {
        width: 75,
        marginBottom: 10,
        "&:not([disabled])": {
          cursor: "pointer",
        },
        "&[disabled]": {
          opacity: ".5",
        },
      },
    },
  },
  tossField: {
    position: "relative",
    "& > .MuiGrid-item:first-child": {
      position: "relative",
    },
    "& .coinSpinner": {
      zIndex: "9",
      fontSize: "40px",
      fontWeight: "600",
      lineHeight: 1,
      [theme.breakpoints.up("sm")]: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        textAlign: "center",
      },
      "& img": {
        width: 100,
        borderRadius: "50%",
      },
    },
  },
  activeStatus: {
    position: "absolute",
    right: "6px",
    top: "-2px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "20px",
    },
  },
}));

const options = ["Newest First", "Top Comments"];

const ActiveDebates = (props) => {
  const classes = useStyles();
  const location_state = getDecryptedHashWithSecret(
    localStorage.getItem("location.state")
  );
  // const [days, setDays] = useState(0);
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  // const [seconds, setSeconds] = useState(0);
  const [redirectToLiveDebate, setRedirectToLiveDebate] = useState(false);
  const [debateObject, setDebateObject] = useState(null);
  // const [redirectToMyDebate, setredirectToMyDebate] = useState(false);
  const { debateTimerObj, onlineUsersObj } = useDebateTimerSocket(props);
  const { extendedTimerObj, updatedDebateObj, isReloadActiveDebateUI } =
    useDebateExtendedTimerSocket(props);
  const history = useHistory();
  const accessToken = props.auth.accessToken;
  const [openDialog, setOpenDialog] = useState(false);
  const [stateVal, setStateVal] = useState("");
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [loader, setLoader] = useState(false);
  const [debateLoaded, setDebateLoaded] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState(false);
  const [debateDescription, setDebateDescription] = useState("");
  const [debateDescriptionDialog, setDebateDescriptionDialog] = useState(false);
  const [helperTextDebateDescription, setHelperTextDebateDescription] =
    useState("");
  const [DDDTitle, setDDDTitle] = useState("");
  const [joinMove, setJoinMove] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedValue, setselectedValue] = React.useState("");
  const [selectedUser, setselectedUser] = React.useState("");
  const [outUser, setOutUser] = useState(false);

  const handleClickOpen = (val) => {
    setOpenDialog(true);
    setStateVal(val);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDDDialogClose = () => {
    setDebateDescriptionDialog(false);
  };
  const handleClose = (status) => {
    if (status && status === "Success") {
      getDebateList();
    }
    setOpenModal(false);
  };
  // ,roomName: props.debateDetail._id
  // const getTimeDifference = (eventDate) => {
  //     if (eventDate) {

  //         const time = Date.parse(eventDate) - Date.parse(new Date());
  //         let days = Math.floor(time / (1000 * 60 * 60 * 24));
  //         const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
  //         const minutes = Math.floor((time / 1000 / 60) % 60);
  //         const seconds = Math.floor((time / 1000) % 60);

  //         if (days > 0) {
  //             setDays(days);
  //         }
  //         if (hours > 0) {
  //             setHours(hours);
  //         }
  //         if (minutes > 0) {
  //             setMinutes(minutes);
  //         }
  //         if (seconds > 0) {
  //             setSeconds(seconds);
  //         }
  //     }

  // }
  var historyDebateObj = location_state.debateObj;
  // let eventDate = historyDebateObj && moment(historyDebateObj.debate_planned_start_datetime).format("MMM DD ,YYYY");
  useEffect(() => {
    getDebateList();
    // getTimeDifference(eventDate);
    // setInterval(() => getTimeDifference(eventDate), 1000);
  }, []);
  useEffect(() => {
    setLoader(true);
    if (debateLoaded === true) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [debateLoaded]);

  useEffect(() => {
    if (debateTimerObj !== null && debateTimerObj.status === "inActive") {
      //redirect to Active debate screen because of status changed to inactive
      history.push(`/activeDebateList`);
    }
    // if (debateTimerObj !== null && debateTimerObj.status === 'start') {
    //     setRedirectToLiveDebate(true);
    // }
  }, [debateTimerObj]);
  useEffect(() => {
    if (extendedTimerObj !== null && extendedTimerObj.status === "inActive") {
      // redirect to Active debate screen because of status changed to inactive
      history.push(`/activeDebateList`);
    }
    // if (extendedTimerObj !== null && extendedTimerObj.status === 'start') {
    //     setRedirectToLiveDebate(true);
    // }
  }, [extendedTimerObj]);
  useEffect(() => {
    if (isReloadActiveDebateUI !== null) {
      //   window.location.reload();
      getDebateList();
    }
  }, [isReloadActiveDebateUI]);
  useEffect(() => {
    setOnlineUsers(onlineUsersObj);
  }, [onlineUsersObj]);
  useEffect(() => {
    if (updatedDebateObj !== null) {
      setDebateObject(updatedDebateObj);
    }
  }, [updatedDebateObj]);

  const getDebateList = async () => {
    var debateId = historyDebateObj._id;
    axios
      .get(API_Path + `api/debate/get/${debateId}`, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        var responseObj = response.data;
        if (
          responseObj !== null &&
          responseObj.result !== null &&
          responseObj.result.status === 1 &&
          responseObj.result.toss_selected !== null &&
          responseObj.result.toss_won !== null
        ) {
          setDebateObject(responseObj.result);
          setDebateLoaded(true);
          setRedirectToLiveDebate(true);
        } else if (
          responseObj !== null &&
          responseObj.result !== null &&
          responseObj.result.status > 1
        ) {
          history.push(`/activeDebateList`);
        } else {
          setDebateObject(responseObj.result);
          setDebateLoaded(true);
          if (
            responseObj.result &&
            responseObj.result.proposition_details === "" &&
            responseObj.result.proposition_users.length > 0 &&
            responseObj.result.proposition_users[0] &&
            responseObj.result.proposition_users[0].user &&
            responseObj.result.proposition_users[0].user._id === props.user._id
          ) {
            handleDebatePopup("proposition", "Join");
            setOutUser(true);
          } else if (
            responseObj.result &&
            responseObj.result.opposition_details === "" &&
            responseObj.result.opposition_users.length > 0 &&
            responseObj.result.opposition_users[0] &&
            responseObj.result.opposition_users[0].user &&
            responseObj.result.opposition_users[0].user._id === props.user._id
          ) {
            handleDebatePopup("opposition", "Join");
            setOutUser(true);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemove = (action) => {
    let activeDebateRmoveAPI = API_Path + "api/debate/remove";

    let inputObj = {
      inputObject: {
        debateId: debateObject && debateObject._id,
        from: action,
      },
    };
    axios
      .post(activeDebateRmoveAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        getDebateList();
        setOpenDialog(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleMove = (action) => {
    let activeDebateRmoveAPI = API_Path + "api/debate/move";

    let inputObj = {
      inputObject: {
        debateId: debateObject && debateObject._id,
        from: action,
        description: debateDescription,
      },
    };
    axios
      .post(activeDebateRmoveAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        getDebateList();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleJoinDebate = (action) => {
    let activeDebateRmoveAPI = API_Path + "api/debate/join";
    let description = debateDescription
      ? debateDescription
      : action === "proposition"
      ? debateObject.proposition_details
      : action === "opposition"
      ? debateObject.opposition_details
      : "";

    let inputObj = {
      inputObject: {
        debateId: debateObject && debateObject._id,
        from: action,
        userId: props.user._id,
        email: props.user.email,
        description,
      },
    };
    axios
      .post(activeDebateRmoveAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          getDebateList();
        } else {
          if (response.data.message === "You are not available for this slot") {
            setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleUpdateDebate = (action) => {
    let activeDebateRmoveAPI = API_Path + "api/debate/UpdateDescription";

    let inputObj = {
      inputObject: {
        debateId: debateObject && debateObject._id,
        from: action,
        userId: props.user._id,
        email: props.user.email,
        description: debateDescription,
      },
    };
    axios
      .post(activeDebateRmoveAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          getDebateList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDebatePopup = (action, actionFiels) => {
    setHelperTextDebateDescription("");
    setDebateDescription("");
    setDDDTitle(action);
    if (actionFiels === "Move") {
      if (action === "proposition") {
        setDDDTitle("Opposition");
      } else {
        setDDDTitle("Proposition");
      }
    }
    setJoinMove(actionFiels);
    setDebateDescriptionDialog(true);
  };
  const handleDebateDescription = (event) => {
    setDebateDescription(event.target.value);
  };
  const handleCheckDDD = () => {
    setHelperTextDebateDescription("");
    if (debateDescription.trim() === "") {
      // if(joinMove === "Move") {
      //     if(DDDTitle === 'proposition') {
      //         setHelperTextDebateDescription('Opposition Details is Required');
      //     } else {
      //         setHelperTextDebateDescription('Proposition Details is Required');
      //     }
      // } else {
      //     setHelperTextDebateDescription(DDDTitle + ' Details is Required');
      // }
      setHelperTextDebateDescription(DDDTitle + " Details is Required");
    } else {
      if (joinMove === "Join") {
        if (
          DDDTitle === "proposition"
            ? debateObject !== null &&
              debateObject.proposition_users.length > 0 &&
              debateObject.proposition_users[0].user &&
              debateObject.proposition_users[0].user._id === props.user._id
            : DDDTitle === "opposition" &&
              debateObject !== null &&
              debateObject.opposition_users.length > 0 &&
              debateObject.opposition_users[0].user &&
              debateObject.opposition_users[0].user._id === props.user._id
        ) {
          handleUpdateDebate(DDDTitle);
        } else {
          handleJoinDebate(DDDTitle);
        }
      } else {
        if (DDDTitle === "Proposition") {
          handleMove("opposition");
        } else {
          handleMove("proposition");
        }
      }
      setDebateDescriptionDialog(false);
    }
  };
  // const toLiveDebate = () => {
  //     setRedirectToLiveDebate(true);
  // }

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  const handleEdit = (value, user) => {
    // setredirectToMyDebate(true)
    setselectedValue(value);
    setselectedUser(user);
    setOpenModal(true);
  };

  const viewOtherDebates = (email) => {
    sessionStorage.setItem("email", email);
    history.push(`/activeDebateList`);
  };

  const mydebateObj = {
    debateEdit: true,
    debateObj: debateObject,
  };
  //proposition
  let is_show_proposition_users = false;
  let is_enable_proposition_invite_button = false;
  let isenable_proposition_join_button = false;
  let isenable_proposition_remove_button = false;
  let isenable_proposition_move_button = false;
  let isproposition_join_button_disabled = false;

  //opposition
  let is_show_opposition_users = false;
  let is_enable_opposition_invite_button = false;
  let isenable_opposition_join_button = false;
  let isenable_opposition_remove_button = false;
  let isenable_opposition_move_button = false;
  let isopposition_join_button_disabled = false;

  // Proposition condition
  if (
    debateObject !== null &&
    debateObject.proposition_type === "Anyone" &&
    (debateObject.proposition_users === null ||
      debateObject.proposition_users.length === 0)
  ) {
    if (debateObject.created_by._id === props.user._id) {
      is_enable_proposition_invite_button = true;
      if (
        debateObject !== null &&
        debateObject.opposition_users !== null &&
        debateObject.opposition_users.length > 0 &&
        debateObject.is_instant_debate === 1 &&
        debateObject.opposition_users[0].user._id === props.user._id
      ) {
        isproposition_join_button_disabled = true;
      }
    } else if (
      debateObject !== null &&
      debateObject.opposition_users !== null &&
      debateObject.opposition_users.length > 0 &&
      debateObject.opposition_users[0].user._id === props.user._id
    ) {
      isenable_proposition_join_button = true;
      isproposition_join_button_disabled = true;
    } else {
      isenable_proposition_join_button = true;
    }
  } else {
    is_show_proposition_users = true;
    if (
      debateObject !== null &&
      debateObject.proposition_users !== null &&
      debateObject.proposition_users.length > 0 &&
      debateObject.proposition_users[0].user._id === props.user._id &&
      debateObject.status === 0
    ) {
      isenable_proposition_remove_button = true;
    }
    if (
      debateObject !== null &&
      debateObject.proposition_users !== null &&
      debateObject.proposition_users.length > 0 &&
      debateObject.proposition_users[0].user._id === props.user._id &&
      (debateObject.opposition_users === null ||
        debateObject.opposition_users.length === 0)
    ) {
      isenable_proposition_move_button = true;
    }
  }

  // Opposition condition

  if (
    debateObject !== null &&
    debateObject.opposition_type === "Anyone" &&
    (debateObject.opposition_users === null ||
      debateObject.opposition_users.length === 0)
  ) {
    if (debateObject.created_by._id === props.user._id) {
      is_enable_opposition_invite_button = true;
      if (
        debateObject !== null &&
        debateObject.proposition_users !== null &&
        debateObject.proposition_users.length > 0 &&
        debateObject.is_instant_debate === 1 &&
        debateObject.proposition_users[0].user._id === props.user._id
      ) {
        isopposition_join_button_disabled = true;
      }
    } else if (
      debateObject !== null &&
      debateObject.proposition_users !== null &&
      debateObject.proposition_users.length > 0 &&
      debateObject.proposition_users[0].user._id === props.user._id
    ) {
      isenable_opposition_join_button = true;
      isopposition_join_button_disabled = true;
    } else {
      isenable_opposition_join_button = true;
    }
  } else {
    is_show_opposition_users = true;
    if (
      debateObject !== null &&
      debateObject.opposition_users !== null &&
      debateObject.opposition_users.length > 0 &&
      debateObject.opposition_users[0].user._id === props.user._id &&
      debateObject.status === 0
    ) {
      isenable_opposition_remove_button = true;
    }
    if (
      debateObject !== null &&
      debateObject.opposition_users !== null &&
      debateObject.opposition_users.length > 0 &&
      debateObject.opposition_users[0].user._id === props.user._id &&
      (debateObject.proposition_users === null ||
        debateObject.proposition_users.length === 0)
    ) {
      isenable_opposition_move_button = true;
    }
  }

  let isPropositionUser = false;
  let isOppositionUser = false;
  let message = "Debate will start in";
  if (extendedTimerObj !== null && extendedTimerObj.status === "delay_timer") {
    if (
      debateObject !== null &&
      debateObject.proposition_users &&
      debateObject.proposition_users.length > 0
    ) {
      debateObject.proposition_users.forEach((opu) => {
        if (opu.user._id === props.user._id) {
          isOppositionUser = true;
        }
      });
      if (isPropositionUser) {
        message = `Waiting for ${debateObject.proposition_users[0].user.full_name}. Debate will start shortly..`;
      }
    }
    if (
      debateObject !== null &&
      debateObject.opposition_users &&
      debateObject.opposition_users.length > 0
    ) {
      debateObject.opposition_users.forEach((opu) => {
        if (opu.user._id === props.user._id) {
          isPropositionUser = true;
        }
      });
      if (isOppositionUser) {
        message = `Waiting for ${debateObject.opposition_users[0].user.full_name}. Debate will start shortly..`;
      }
    }
    if (!isPropositionUser && !isOppositionUser) {
      message = "Waiting for debaters..  Debate will start shortly..";
    }
  }

  const propositionDescription = (
    <>
      <div className={classes.seperator}></div>
      <h5 className="m-0">Description</h5>
      <div style={{ margin: "6px 0 12px 0" }}>
        {debateObject && debateObject.proposition_details ? (
          <ShowMoreLessText description={debateObject.proposition_details} />
        ) : (
          ""
        )}
      </div>
    </>
  );

  const oppositionDescription = (
    <>
      <div className={classes.seperator}></div>
      <h5 className="m-0">Description</h5>
      <div style={{ margin: "6px 0 12px 0" }}>
        {debateObject && debateObject.opposition_details ? (
          <ShowMoreLessText description={debateObject.opposition_details} />
        ) : (
          ""
        )}
      </div>
    </>
  );

  const AdvertisementSection = (
    <div className={classes.rightSection}>
      <Advertisement type="debate_details_page"/>
    </div>
  );
  const debaterDescriptionDetails = (
    <>
      <FormControl error fullWidth>
        <TextField
          id="debaterDescription"
          label={DDDTitle + " Details"}
          multiline
          fullWidth
          row={4}
          rowsMax={4}
          variant="outlined"
          onChange={handleDebateDescription}
          className={clsx(classes.textField, classes.textFieldArea)}
        />
        <FormHelperText>{helperTextDebateDescription}</FormHelperText>
      </FormControl>
    </>
  );
  return (
    <div className={classes.pageContainer}>
      {redirectToLiveDebate
        ? redirectTo("/liveDebate", {
            debateObj: debateObject,
            roomName: debateObject._id,
          })
        : ""}
      {/* {redirectToMyDebate &&
        redirectTo("/myDebates", { mydebateObj: mydebateObj })} */}
      <div className={classes.leftSection}>
        <CustomDebateHeaderATC
          debateDetail={debateObject}
          liveBtn={true}
          screen="ActiveDebateList"
          shareLink={debateObject && debateObject.share_link}
          debateId={debateObject && debateObject._id}
          status={debateObject && debateObject.status}
          showEditDelete={
            debateObject !== null &&
            debateObject.created_by._id === props.user._id &&
            debateObject &&
            debateObject.status === 0
          }
          UTC={false}
        />

        {
          debateObject && debateObject.is_instant_debate === 1 ? (
            <InstantDebateBanner
              debateObject={debateObject}
              debateTimerObj={debateTimerObj}
              extendedTimerObj={extendedTimerObj}
              onlineUsers={onlineUsersObj}
              classes={classes}
            />
          ) : (
            // <Box className={classes.positionRelative}>
            //     <Loader />
            //     <Grid container spacing={2}>
            //         <Grid item xs={6}>
            //             <Box className={classes.anyOneSlide}>
            //                 {debateObject && debateObject.proposition_type === "Anyone" && debateObject.proposition_users.length === 0 ?
            //                     <Slider {...settings}>
            //                         {onlineUsers.map((onlineUser) => {
            //                             return (
            //                                 <div className="avatarImage">
            //                                     {onlineUser && onlineUser.profile_image ?
            //                                         <img src={onlineUser.profile_image} alt="" />
            //                                         :
            //                                         <AccountCircle style={{ width: '200px', height: '200px', opacity: '.25' }} />
            //                                     }
            //                                     <h5>{onlineUser && onlineUser.full_name}</h5>
            //                                 </div>
            //                             )
            //                         })}
            //                     </Slider>
            //                     :
            //                     <>
            //                         <div className="avatarImage">
            //                             {/* <img src={debateObject.proposition_users[0].user.profile_image} alt='' /> */}
            //                             <CustomImage userImage={debateObject && debateObject.proposition_users.length > 0 ?
            //                                 debateObject.proposition_users[0].user.profile_image : ""} size="200px" marginRight="0" />
            //                             <h5>{debateObject && debateObject.proposition_users.length > 0 ?
            //                                 debateObject.proposition_users[0].user.full_name : "No Proposition users"}</h5>
            //                         </div>
            //                         {/* <Box className={classes.askToss}>
            //                             <Box className="tossContainer">
            //                                 <img src={HeadsCoin} />
            //                                 <Button variant="contained" size="small" color="primary"> Heads </Button>
            //                             </Box>
            //                             <Box className="tossContainer">
            //                                 <img src={TailsCoin} />
            //                                 <Button variant="contained" size="small" color="secondary"> Tails </Button>
            //                             </Box>
            //                         </Box> */}
            //                     </>
            //                 }
            //             </Box>
            //         </Grid>
            //         <Grid item xs={6}>
            //             <Box className={classes.anyOneSlide}>
            //                 {debateObject && debateObject.opposition_type === "Anyone" && debateObject.opposition_users.length === 0 ?
            //                     <Slider {...settings}>
            //                         {onlineUsers.map((onlineUser) => {
            //                             return (
            //                                 <div className="avatarImage">
            //                                     {onlineUser && onlineUser.profile_image ?
            //                                         <img src={onlineUser.profile_image} alt="" />
            //                                         :
            //                                         <AccountCircle style={{ width: '200px', height: '200px', opacity: '.25' }} />
            //                                     }
            //                                     <h5>{onlineUser && onlineUser.full_name}</h5>
            //                                 </div>
            //                             )
            //                         })}
            //                     </Slider>
            //                     :
            //                     <>
            //                         {debateObject && debateObject.opposition_users.length > 0 && debateObject.opposition_users[0].user && debateObject.opposition_users[0].user.profile_image &&
            //                             <>
            //                                 <div className="avatarImage">
            //                                     {/* <img src={debateObject.opposition_users[0].user.profile_image} alt='' /> */}
            //                                     <CustomImage userImage={debateObject && debateObject.opposition_users.length > 0 ?
            //                                         debateObject.opposition_users[0].user.profile_image : ""} size="200px" marginRight="0" />
            //                                     <h5>
            //                                         {debateObject && debateObject.opposition_users.length > 0 ?
            //                                             debateObject.opposition_users[0].user.full_name : "No Opposition users"}
            //                                     </h5>
            //                                 </div>
            //                                 {/* <Box className={classes.askToss}>
            //                                     <Box className="tossContainer">
            //                                         <img src={HeadsCoin} />
            //                                         <Button variant="contained" size="small" color="primary"> Heads </Button>
            //                                     </Box>
            //                                     <Box className="tossContainer">
            //                                         <img src={TailsCoin} />
            //                                         <Button variant="contained" size="small" color="secondary"> Tails </Button>
            //                                     </Box>
            //                                 </Box> */}
            //                             </>
            //                         }
            //                     </>
            //                 }
            //             </Box>
            //         </Grid>
            //         <Grid md={12}>
            //             <Box mt={2} textAlign="center">
            //                 <h4 style={{margin: '0'}}>Toss Time</h4>
            //                 <p style={{margin: '0'}}>Proposition spins the coin</p>
            //             </Box>
            //         </Grid>
            //     </Grid>
            // </Box>
            <ScheduleDebateBanner
              debateObject={debateObject}
              classes={classes}
              debateTimerObj={debateTimerObj}
              extendedTimerObj={extendedTimerObj}
              message={message}
            />
          )
          // <div className={classes.startIn}>

          //     <Grid container direction="column" alignItems="center">
          //         <h4 className="m-0 font-weight-normal" style={{ marginBottom: '20px' }}>
          //             {message}
          //         </h4>
          //         <Box mb={1.5}>
          //             <Grid container justify="center" spacing={5}>
          //                 <Grid item direction="column">
          //                     <h1 className="m-0" style={{ textAlign: 'center' }}>{leadingZero(days)} </h1>
          //                     <h4 className="m-0 font-weight-normal">{days === 1 ? 'Day' : 'Days'}</h4>
          //                 </Grid>
          //                 <Grid item direction="column">
          //                     <h1 className="m-0" style={{ textAlign: 'center' }}>{leadingZero(hours)} </h1>
          //                     <h4 className="m-0 font-weight-normal">{hours === 1 ? 'Hour' : 'Hours'}</h4>
          //                 </Grid>
          //                 <Grid item direction="column">
          //                     <h1 className="m-0" style={{ textAlign: 'center' }}>{leadingZero(minutes)} </h1>
          //                     <h4 className="m-0 font-weight-normal">{minutes === 1 ? 'Minute' : 'Minutes'}</h4>
          //                 </Grid>
          //                 <Grid item direction="column">
          //                     <h1 className="m-0" style={{ textAlign: 'center' }}>{leadingZero(seconds)} </h1>
          //                     <h4 className="m-0 font-weight-normal">{seconds === 1 ? 'Second' : 'Seconds'}</h4>
          //                 </Grid>
          //             </Grid>
          //         </Box>

          //         {/* <Button variant="contained" color="primary" style={{ marginTop: '34px' }}
          //             onClick={toLiveDebate}
          //         // className={(days === 0 && hours === 0 &&  minutes=== 0 && seconds === 0) ? classes.circleBtn : 'disabled ' + classes.circleBtn}
          //         >
          //             Join Debate
          //         </Button> */}

          //     </Grid>
          //     <Box className={classes.robotInfo}>
          //         <div className={classes.robotText + " robotTextVideo"}>
          //             <div style={{ width: '45px', height: '45px', margin: '0 10px 0 0' }}>
          //                 <img alt="" src={robotImage} />
          //             </div>
          //             <div>
          //                 <h6 className="font-weight-normal" style={{ margin: '0' }}>Judge</h6>
          //                 <h5 style={{ margin: '0' }}>AI ROBOT</h5>
          //             </div>
          //         </div>
          //         <p style={{ fontSize: '11px', color: 'rgba(255,255,255, .6)', margin: '0', }}>
          //             Note: Please prepare camera. headset or microphone.
          //         </p>
          //     </Box>
          // </div>
        }

        <Grid
          container
          justify="flex-end"
          spacing={4}
          style={{ marginBottom: "2px" }}
        >
          <Grid item>
            <h6
              className="m-0"
              style={{ fontWeight: "normal", color: "#758194" }}
            >
              Proposition Team
            </h6>
            <div
              className={classes.circlePOT}
              style={{ backgroundColor: "#ffc003" }}
            >
              {debateObject && debateObject.proposition_users.length > 0
                ? "1"
                : "0"}
            </div>
          </Grid>
          <Grid item>
            <h6
              className="m-0"
              style={{ fontWeight: "normal", color: "#758194" }}
            >
              Opposition Team
            </h6>
            <div
              className={classes.circlePOT}
              style={{ backgroundColor: "#61cbc9" }}
            >
              {debateObject && debateObject.opposition_users.length > 0
                ? "1"
                : "0"}
            </div>
          </Grid>
        </Grid>
        {/* debateObject && debateObject.created_by */}

        <Grid container spacing={3}>
          <Grid container item xs={12} md={6}>
            <Paper className={classes.paper}>
              {!is_show_proposition_users ? (
                <div>
                  <div style={{ height: "110px" }}>
                    <h5 className="m-0" style={{ marginBottom: "18px" }}>
                      Proposition Details
                    </h5>
                    {debateObject && debateObject.is_instant_debate === 1 ? (
                      <Button
                        onClick={handleDebatePopup.bind(
                          this,
                          "proposition",
                          "Join"
                        )}
                        disabled={isproposition_join_button_disabled}
                        variant="contained"
                        color="primary"
                        className={classes.circleBtn}
                      >
                        Join
                      </Button>
                    ) : (
                      <>
                        {isenable_proposition_join_button ? (
                          <Button
                            // onClick={handleJoinDebate.bind(this, "proposition")}

                            onClick={
                              debateObject && debateObject.proposition_details
                                ? handleJoinDebate.bind(this, "proposition")
                                : handleDebatePopup.bind(
                                    this,
                                    "proposition",
                                    "Join"
                                  )
                            }
                            disabled={isproposition_join_button_disabled}
                            variant="contained"
                            color="primary"
                            className={classes.circleBtn}
                          >
                            Join
                          </Button>
                        ) : (
                          ""
                        )}
                        {is_enable_proposition_invite_button ? (
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.circleBtn}
                            onClick={() =>
                              handleEdit("Update Debate", "proposition")
                            }
                          >
                            Invite
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {propositionDescription}
                </div>
              ) : debateObject !== null ? (
                <div>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent={{ xs: "", sm: "space-between" }}
                    alignItems={{ xs: "center", sm: "flex-start" }}
                  >
                    <Box width={{ xs: "auto", sm: "100%" }}>
                      <h5 className="m-0" style={{ marginBottom: "18px" }}>
                        Proposition Details
                      </h5>
                      <Box display="flex" alignItems="center">
                        <div style={{ position: "relative" }}>
                          {" "}
                          <CustomImage
                            userImage={
                              debateObject &&
                              debateObject.proposition_users.length > 0
                                ? debateObject.proposition_users[0].user
                                    .profile_image
                                : ""
                            }
                            size="46px"
                          />
                          {debateObject &&
                            debateObject.proposition_users.length > 0 &&
                            debateObject.proposition_users[0].user &&
                            !!debateObject.proposition_users[0].user
                              .badge_status && (
                              <span
                                className={classes.activeStatus}
                                title="Verified User/Public Figure"
                              >
                                <VerifiedUserRoundedIcon />
                              </span>
                            )}
                        </div>
                        <div>
                          <h5 className="m-0">
                            {debateObject &&
                            debateObject.proposition_users.length > 0
                              ? debateObject.proposition_users[0].user.full_name
                              : "No Proposition users"}
                          </h5>
                          {debateObject &&
                            debateObject.proposition_users.length > 0 &&
                            debateObject.proposition_users[0].user
                              .user_tagging &&
                            debateObject.proposition_users[0].user.user_tagging
                              .length > 0 && (
                              <span
                                style={{
                                  margin: "0",
                                  color: "#758194",
                                  fontWeight: "700",
                                  fontSize: "12px",
                                }}
                              >
                                @{""}
                                {
                                  debateObject.proposition_users[0].user
                                    .user_tagging
                                }
                              </span>
                            )}
                          {/* <h6 className="m-0 font-weight-normal" style={{ color: '#758194' }}>UTC : 16 30 PM</h6> */}
                        </div>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems={{ xs: "center", sm: "flex-end" }}
                      pt={{ xs: 1, sm: 0 }}
                    >
                      {debateObject &&
                      debateObject.proposition_users !== null &&
                      debateObject.proposition_users.length > 0 &&
                      debateObject.proposition_users[0].user !== null ? (
                        <>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{ whiteSpace: "nowrap" }}
                            onClick={viewOtherDebates.bind(
                              this,
                              debateObject.proposition_users[0].email
                            )}
                            disabled={
                              debateObject &&
                              debateObject.proposition_users &&
                              debateObject.proposition_users[0].user &&
                              debateObject.proposition_users[0].user.blocked_users.includes(
                                props.user._id
                              )
                            }
                          >
                            View other Debates
                          </Button>
                          {debateObject.proposition_users[0].user._id ===
                          props.user._id ? (
                            <Button
                              className={classes.viewProfileBtn}
                              size="small"
                              component={Link}
                              to={`/userDashboard`}
                              target="_blank"
                            >
                              View profile
                            </Button>
                          ) : (
                            <Button
                              className={classes.viewProfileBtn}
                              size="small"
                              component={Link}
                              to={`/friendsProfilePage/${debateObject.proposition_users[0].user._id}`}
                              target="_blank"
                            >
                              View profile
                            </Button>
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {/* <Button href="#">View profile</Button> */}
                    </Box>
                  </Box>
                  {propositionDescription}

                  <span>
                    {isenable_proposition_remove_button ? (
                      <Button
                        onClick={handleClickOpen.bind(this, "proposition")}
                        variant="outlined"
                        size="small"
                      >
                        Remove
                      </Button>
                    ) : (
                      ""
                    )}
                    {isenable_proposition_move_button ? (
                      <Button
                        onClick={
                          debateObject && debateObject.is_instant_debate === 1
                            ? handleDebatePopup.bind(
                                this,
                                "proposition",
                                "Move"
                              )
                            : handleMove.bind(this, "proposition")
                        }
                        // onClick={handleMove.bind(this, 'proposition')}
                        variant="outlined"
                        size="small"
                      >
                        Move
                      </Button>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
            </Paper>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Paper className={classes.paper}>
              {!is_show_opposition_users ? (
                <div>
                  <div style={{ height: "110px" }}>
                    <h5 className="m-0" style={{ marginBottom: "18px" }}>
                      Opposition Details
                    </h5>
                    {debateObject && debateObject.is_instant_debate === 1 ? (
                      <Button
                        onClick={handleDebatePopup.bind(
                          this,
                          "opposition",
                          "Join"
                        )}
                        disabled={isopposition_join_button_disabled}
                        variant="contained"
                        color="primary"
                        className={classes.circleBtn}
                      >
                        Join
                      </Button>
                    ) : (
                      <>
                        {isenable_opposition_join_button ? (
                          <Button
                            // onClick={handleJoinDebate.bind(this, "opposition")}
                            onClick={
                              debateObject && debateObject.opposition_details
                                ? handleJoinDebate.bind(this, "opposition")
                                : handleDebatePopup.bind(
                                    this,
                                    "opposition",
                                    "Join"
                                  )
                            }
                            disabled={isopposition_join_button_disabled}
                            variant="contained"
                            color="primary"
                            className={classes.circleBtn}
                          >
                            Join
                          </Button>
                        ) : (
                          ""
                        )}
                        {is_enable_opposition_invite_button ? (
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.circleBtn}
                            onClick={() =>
                              handleEdit("Update Debate", "opposition")
                            }
                          >
                            Invite
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {oppositionDescription}
                </div>
              ) : debateObject !== null ? (
                <div>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent={{ xs: "", sm: "space-between" }}
                    alignItems={{ xs: "center", sm: "flex-start" }}
                  >
                    <Box width={{ xs: "auto", sm: "100%" }}>
                      <h5 className="m-0" style={{ marginBottom: "18px" }}>
                        Opposition Details
                      </h5>
                      <Box display="flex" alignItems="center">
                        <div style={{ position: "relative" }}>
                          <CustomImage
                            userImage={
                              debateObject &&
                              debateObject.opposition_users.length > 0
                                ? debateObject.opposition_users[0].user
                                    .profile_image
                                : ""
                            }
                            size="46px"
                          />
                          {debateObject &&
                            debateObject.opposition_users.length > 0 &&
                            debateObject.opposition_users[0].user &&
                            !!debateObject.opposition_users[0].user
                              .badge_status && (
                              <span
                                className={classes.activeStatus}
                                title="Verified User/Public Figure"
                              >
                                <VerifiedUserRoundedIcon />
                              </span>
                            )}
                        </div>
                        <div>
                          <h5 className="m-0">
                            {debateObject &&
                            debateObject.opposition_users.length > 0
                              ? debateObject.opposition_users[0].user.full_name
                              : "No Opposition users"}
                          </h5>
                          {debateObject &&
                            debateObject.opposition_users.length > 0 &&
                            debateObject.opposition_users[0].user
                              .user_tagging &&
                            debateObject.opposition_users[0].user.user_tagging
                              .length > 0 && (
                              <span
                                style={{
                                  margin: "0",
                                  color: "#758194",
                                  fontWeight: "700",
                                  fontSize: "12px",
                                }}
                              >
                                @{""}
                                {
                                  debateObject.opposition_users[0].user
                                    .user_tagging
                                }
                              </span>
                            )}
                          {/* <h6 className="m-0 font-weight-normal" style={{ color: '#758194' }}>UTC : 16 30 PM</h6> */}
                        </div>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems={{ xs: "center", sm: "flex-end" }}
                      pt={{ xs: 1, sm: 0 }}
                    >
                      {debateObject &&
                      debateObject.opposition_users !== null &&
                      debateObject.opposition_users.length > 0 &&
                      debateObject.opposition_users[0].user !== null ? (
                        <>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{ whiteSpace: "nowrap" }}
                            onClick={viewOtherDebates.bind(
                              this,
                              debateObject.opposition_users[0].email
                            )}
                            disabled={
                              debateObject &&
                              debateObject.opposition_users &&
                              debateObject.opposition_users[0].user &&
                              debateObject.opposition_users[0].user.blocked_users.includes(
                                props.user._id
                              )
                            }
                          >
                            View other Debates
                          </Button>
                          {debateObject.opposition_users[0].user._id ===
                          props.user._id ? (
                            <Button
                              className={classes.viewProfileBtn}
                              size="small"
                              component={Link}
                              to={`/userDashboard`}
                              target="_blank"
                            >
                              View profile
                            </Button>
                          ) : (
                            <Button
                              className={classes.viewProfileBtn}
                              size="small"
                              component={Link}
                              to={`/friendsProfilePage/${debateObject.opposition_users[0].user._id}`}
                              target="_blank"
                            >
                              View profile
                            </Button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                  {oppositionDescription}
                  <span>
                    {isenable_opposition_remove_button ? (
                      <Button
                        onClick={handleClickOpen.bind(this, "opposition")}
                        variant="outlined"
                        size="small"
                      >
                        Remove
                      </Button>
                    ) : (
                      ""
                    )}
                    {isenable_opposition_move_button ? (
                      <Button
                        onClick={
                          debateObject && debateObject.is_instant_debate === 1
                            ? handleDebatePopup.bind(this, "opposition", "Move")
                            : handleMove.bind(this, "opposition")
                        }
                        // onClick={handleMove.bind(this, 'opposition')}
                        variant="outlined"
                        size="small"
                      >
                        Move
                      </Button>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
            </Paper>
          </Grid>
        </Grid>
        {debateObject !== null ? (
          <CustomComments
            accessToken={accessToken}
            debateObject={debateObject}
            options={options}
          />
        ) : (
          ""
        )}
        <ConfirmationModal
          openDialog={openDialog}
          modalHeading="Message"
          handleDialogClose={handleDialogClose}
          handleDelete={handleRemove.bind(this, stateVal)}
          modalMessage={`Are you sure to remove ${stateVal} user? `}
          btnCancelName="No"
          btnNoConfirmName="Yes"
          maxWidth="xs"
        />
      </div>
      {props.is_disable_ads !== 1 &&
      props.currentPackage &&
      props.currentPackage.length === 0 ? (
        <>{AdvertisementSection}</>
      ) : (
        <>
          {props.is_disable_ads === 1 ||
          (props.currentPackage &&
            props.currentPackage.length > 0 &&
            props.currentPackage.findIndex((e) => {
              return e.package_name === "Ad free payment";
            }) > -1) ? (
            ""
          ) : (
            <>{AdvertisementSection}</>
          )}
        </>
      )}
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      {debateObject && debateObject.is_instant_debate === 1 ? (
        ""
      ) : (
        <>{loader && <Loader />}</>
      )}
      <ConfirmationModal
        openDialog={debateDescriptionDialog}
        modalHeading=""
        handleDialogClose={outUser ? false : handleDDDialogClose}
        handleDelete={handleCheckDDD}
        modalContent={true}
        modalMessage={debaterDescriptionDetails}
        btnCancelName="Cancel"
        btnNoConfirmName="Submit"
        maxWidth="sm"
      />
      <Modal
        open={openModal}
        selectedValue={selectedValue}
        selectedUser={selectedUser}
        handleClose={handleClose}
        mydebateObj={mydebateObj}
        maxWidth="sm"
      />
    </div>
  );
};

const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage },
}) => {
  return {
    user,
    auth,
    is_disable_ads,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(ActiveDebates));

//export default ActiveDebates
