import { makeStyles, Tooltip } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_Path } from "../../Common/Const/index";

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: "relative",
    zIndex: 999,
    "& .MuiSpeedDial-fab": {
      backgroundColor: "transparent",
      width: "auto",
      height: "auto",
      minHeight: "auto",
      padding: "3px 0",
      boxShadow: "none",
    },
    "&.MuiSpeedDial-directionDown": {
      "& .MuiSpeedDial-actions": {
        paddingTop: "30px",
        top: "0",
      },
    },
    "&.MuiSpeedDial-directionUp": {
      "& .MuiSpeedDial-actions": {
        paddingBottom: "30px",
        bottom: "0",
      },
    },
    "& .MuiSpeedDial-actions": {
      position: "absolute",
      margin: "0",
      "& .MuiSpeedDialAction-fab": {
        "& img": {
          width: "20px",
        },
      },
      "& button": {
        "&[title='Whatsapp']": {
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
      },
    },
  },
}));

const CustomSocialFab = (props) => {
  const classes = useStyles();
  const [openSF, setOpenSF] = React.useState(false);
  // let newPageUrl = ""
  const handleSFClose = () => {
    setOpenSF(false);
  };

  const handleSFOpen = () => {
    setOpenSF(true);
  };
  const handlePage = async (val) => {
    setOpenSF(false);
    window.open(val, "_blank");
  };
  const shareLink = (href, e) => {
    setOpenSF(false);
    !!href && handlePage(href);
    if (!!props.debateId || !!props.vurbId) {
      let activeDebateRmoveAPI = API_Path + "api/debate/share";
      let inputObj = {
        inputObject: {
          debateId: props.debateId,
          userId: props.user._id,
        },
      };
      axios
        .post(activeDebateRmoveAPI, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleBroadCast = async (id, type, href, e) => {
    setOpenSF(false);
    window.open(href, "_blank");
    await props.handleCount(id, type);
  };
  const handleAds = async (id, type, href, e) => {
    setOpenSF(false);
    window.open(href, "_blank");
    await props.handleAdsShareCount(id, type);
  };
  const handleVurbs = async (href) => {
    setOpenSF(false);
    window.open(href, "_blank");
    await props.handleShareCount(props.vurbId);
  };
  return (
    <Tooltip
      title="Share"
      placement={props.tooltipPlacement === "bottom" ? "bottom" : "top"}
    >
      <SpeedDial
        onClick={handleSFOpen}
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        hidden={false}
        icon={<img alt="" src={props.icon} />}
        onClose={handleSFClose}
        open={openSF}
        direction={props.direction}
      >
        {props.actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            // href={action.href}
            onClick={
              props.Broadcast === true
                ? handleBroadCast.bind(this, props.bCId, "share", action.href)
                : props.advertisement === true
                ? handleAds.bind(this, props.advId, "share", action.href)
                : props.vurbs
                ? handleVurbs.bind(this, action.href)
                : shareLink.bind(this, action.href)
            }
            // component={Link}
            // target="_blank"
          />
        ))}
      </SpeedDial>
    </Tooltip>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(CustomSocialFab));
