import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import ShowMoreText from 'react-show-more-text';

const useStyles = makeStyles((theme) => ({
    contentCss: {
        maxHeight: 100,
        overflow: 'auto',
        color: '#758194',
        fontSize: '12px',
        "&::-webkit-scrollbar": {
            width: 5
        },
        "&::-webkit-scrollbar-track": {
            background: '#f1f1f1'
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#888'
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: '#555'
        }
    }
}))

const ShowMoreLessText = (props) => {
    const classes = useStyles();
    return (
        <ShowMoreText
            component="div"
            lines={3}
            more='Show more'
            less='Show less'
            className={classes.contentCss}
            anchorClass='my-anchor-css-class'
            expanded={false}
            width={0}
        >
            {props.description}
        </ShowMoreText>
    )
}

export default ShowMoreLessText
