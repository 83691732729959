import React, { useEffect, useState, useCallback, useRef, Fragment } from "react";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import { Box, CircularProgress, Paper, Typography } from "@material-ui/core";
import CommonPostCard from "../../Common/CommonPostCard";
import useCommonVurbsSocket from "../Vurbs/useCommonVurbsSocket";

const limit = 5;
let totalCount = 0;
export default function VurbsProfile(props) {
    const [vurbList, setVurbList] = useState([]);
    const [loading, setLoader] = useState(true);
    const [skip, setSkip] = useState(0);
    const { UpdateLike, vurb_like_data } = useCommonVurbsSocket({ user: props.user })
    useEffect(() => {
        if (Object.keys(vurb_like_data).length > 0) {
        let temparray = [...vurbList]
        let index = temparray.findIndex(val => val._id === vurb_like_data._id);
        if (index > -1) {
            temparray.splice(index, 1, {
            ...temparray[index], dislike_users: vurb_like_data.dislike_users, dislike: vurb_like_data.dislike, like: vurb_like_data.like, like_users: vurb_like_data.like_users
            })
            setVurbList(temparray)
        }
        }
    }, [vurb_like_data])
    useEffect(() => {
        getUserVurb(0);
    }, []);
    const observer = useRef()
    const lastNotification = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                if (totalCount > vurbList.length) {
                    setLoader(true)
                    getUserVurb(skip + limit)
                }
            }
        })
        if (node) observer.current.observe(node)
    })
    const getUserVurb = (val) => {
        let api =props.userData._id === props.user._id ? API_Path + `api/vurb/user?user_id=${props.userData._id}&limit=${limit}&skip=${val}&type=vurbs`: API_Path + `api/vurb/user?user_id=${props.userData._id}&loggedInUserId=${props.user._id}&limit=${limit}&skip=${val}&type=vurbs`;
        axios
            .get(api, {
                headers: {
                    Authorization: "Basic " + props.auth.accessToken,
                },
            })
            .then((response) => {
                if (response.data.status === "SUCCESS") {
                    (val === 0) ? setVurbList(response.data.result) : setVurbList(p => [...p, ...response.data.result]);
                    totalCount= response.data.totalCount
                    setSkip(val)
                }
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false)
                console.error(error);
            });
    };
    const onDelete = (index) =>{
        // console.log(index)
        let tempPost = [...vurbList]
        tempPost.splice(index,1);
        setVurbList(tempPost)
        setSkip(skip - 1)
      }
      const UpdatePostLike=(data)=>{
        UpdateLike(data)
      }
    return <div>
                <Box>
                {vurbList && vurbList.length > 0 ?
                    (vurbList
                        // .filter((data) => data.post_img.length > 0 || !!data.content || data.video.length > 0)
                        .map((data, index) => {
                            return(
                                <Fragment key={index}>
                                    <CommonPostCard index ={index}  post={data} profile={true} ref={(vurbList.length-1===index)?{ref:lastNotification,UpdatePostLike,onDelete}:{ref:null,UpdatePostLike,onDelete}} user={props.user} auth={props.auth} hashed={props.hashed}/>
                                    {loading && vurbList.length-1===index && <div style={{display:"flex", justifyContent:"center"}}><CircularProgress /></div>}
                                </Fragment>
                            )
                        }))
                    : <Paper>
                        <Box p={3}>
                            {!loading ? vurbList.length === 0 && <Typography align="center" variant="body1">
                                No Vurbs Available
                            </Typography>:<div style={{display:"flex", justifyContent:"center"}}><CircularProgress /></div>}
                        </Box>
                    </Paper>
                }
             </Box>   

    </div>;
}
