import {
  Box,
  makeStyles,
  Paper,
  Modal,
  IconButton,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import React, {
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import CustomImage from "../CustomImage";
import DebateDMY from "../CustomDebateHeaderComponents/DebateDMY";
import CommentImg from "../../../assets/images/comment.svg";
import Retweet from "../../../assets/images/retweet.svg";
import Thumbs from "../../../assets/images/thumbs.svg";
import Lockicon from "../../../assets/images/lock.png";
import ThumbsYellow from "../../../assets/images/thumbs-yellow.svg";
import VDotsMenu from "../../../assets/images/VDotsMenu.svg";
import { API_Path } from "../Const/index";
import axios, { CancelToken, isCancel } from "axios";
import SnackBar from "../../../components/SnackBar";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import AudienceAdInjection from "../../Users/LiveDebate/AudienceAdInjection";
import _ from "lodash";
import PdfIcon from "../../../assets/images/pdf.png";
import WordIcon from "../../../assets/images/word.png";
import ExcelIcon from "../../../assets/images/excel.png";
import PptIcon from "../../../assets/images/ppt.png";
// import logoImage from "../../../assets/images/videoLogo.png";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import LikeUserCompoent from "./LikeDisLikeUsers";
import s3FileUpload from "react-s3";
import CreateVurb from "../../Users/Vurbs/CreateVurb";
import CustomImageSlider from "./CustomImageSlider";
import Report from "../ReportAbuse/Report";
import CommonMeta from "../CommonMeta";
import { numberFormatter } from "../Const/index";
import publicIcon from "../../../assets/images/public.svg";
import { AWSfileDelete } from "../../../lib/uriUtils";
const useStyles = makeStyles((theme) => ({
  paperWhiteParent: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.13)",
    // padding: '20px 20px 30px 20px',
    borderRadius: "12px",
    backgroundColor: "#fff",
    margin: "20px 0",
    position: "relative",
    // zIndex: 0,
    "& .videoPlayer": {
      "&.noVideo": {
        height: 220,
      },
    },
    "& a": {
      color: "blue",
    },
  },
  seperator: {
    borderBottom: "solid 1px #cdd1d8;",
    margin: "10px 0px",
  },
  header: {
    display: "flex",
    borderBottom: "solid 1px #cdd1d8;",
    columnGap: "4px",
    "& div": {
      "& h4": {
        margin: "0",
      },
      "& div": {
        margin: "0",
        fontSize: "12px",
        color: "#868686",
      },
    },
  },
  content: {
    margin: "0 14px",
    wordBreak: "break-word",
    // "& div":{

    // },
    "& .profileVurb": {
      objectFit: "cover",
      padding: "4px",
      width: "256px",
      maxHeight: "300px",
    },
    "& .vurbImg": {
      objectFit: "cover",
      padding: "4px",
      width: "300px",
      maxHeight: "300px",
    },
  },
  footer: {
    display: "flex",
    borderTop: "solid 1px #cdd1d8;",
  },
  SubHeader: {
    margin: "8px -7px",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#758194",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > span": {
      margin: "2px 6px",
      display: "flex",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        marginBottom: "6px",
      },
      "&:first-child": {
        paddingRight: "14px",
        borderRight: "1px solid #cdd1d8",
        marginLeft: "0px",
      },
      "& svg": {
        verticalAlign: "text-top",
        width: "15px",
        height: "15px",
        marginRight: "5px",
      },
    },
  },
  boosted: {
    right: "0px",
    top: "61px",
    position: "absolute",
    background: "#ffc003",
    padding: "5px 15px 5px 5px",
    fontSize: "12px",
    lineHeight: "1",
    fontWeight: "500",
    animation: `$evenEffect 2000ms infinite`,
    "&:before": {
      content: '""',
      position: "absolute",
      left: "-22px",
      top: 0,
      border: "11px solid #ffc003",
      borderLeft: "11px solid transparent",
    },
  },
  boostedPromoted: {
    right: "0px",
    top: "61px",
    position: "absolute",
    background: "#ffc003",
    padding: "5px 15px 5px 5px",
    fontSize: "12px",
    lineHeight: "1",
    fontWeight: "500",
    animation: `$evenEffect 2000ms infinite`,
    "&:before": {
      content: '""',
      position: "absolute",
      left: "-22px",
      top: 0,
      border: "11px solid #ffc003",
      borderLeft: "11px solid transparent",
    },
  },
  liveStatus: {
    padding: "4px 10px 4px 10px",
    borderRadius: "13px",
    backgroundColor: "#e8eaed",
    color: "#313236",
    fontSize: "12px",
    lineHeight: "1.5",
    position: "relative",
    display: "flex",
    alignItems: "center",
    maxWidth: "110px",
    "& span": {
      width: "6px",
      height: "6px",
      boxShadow: "0 0 0 3px #fff",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "10px",
      padding: "0",
      "&.Live": {
        backgroundColor: "#f52f2f",
      },
    },
  },
  headerActionSection: {
    display: "flex",
    flexFlow: "column",
    // width: '105px',
    maxWidth: "105px",
    // flex: '0 0 105px',
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
  },
  paper: {
    width: "30%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    "& img": {
      width: "100%",
      maxHeight: "700px",
    },
  },
  videoLogo: {
    position: "absolute",
    right: "5%",
    bottom: "20%",
    width: "6em !important",
    opacity: ".75",
  },
  btnCenter: {
    border: "1px solid black",
    backgroundColor: "white",
    color: "#758194",
    padding: " 3px 10px",
    fontSize: "8px",
    cursor: "pointer",
    borderRadius: "5px",
    marginLeft: "10px",
  },
  activeStatus: {
    position: "absolute",
    right: "0px",
    top: "-6px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "18px",
    },
  },
  downloadSection: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    background: "rgba(255, 255, 255, .95)",
    zIndex: "1",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
}));
let {
  REACT_APP_AWS_S3_REGINE,
  REACT_APP_AWS_S3_ACCESSID,
  REACT_APP_AWS_S3_SECRET_ACCESSID,
  REACT_APP_AWS_S3_IMAGE_BUGKET_NAME,
  REACT_APP_AWS_S3_VIDEO_BUGKET_NAME,
} = process.env;
const config = {
  bucketName: REACT_APP_AWS_S3_IMAGE_BUGKET_NAME,
  region: REACT_APP_AWS_S3_REGINE,
  accessKeyId: REACT_APP_AWS_S3_ACCESSID,
  secretAccessKey: REACT_APP_AWS_S3_SECRET_ACCESSID,
};
const CommonPostCard = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const [playing, SetPlaying] = useState(false);
  const [open, setOpen] = useState(false);
  const [img, setImage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [userpost, setUserpost] = useState(false);
  const [isfriend, setIsFriend] = useState(false);
  const [sliderindex, setSliderIndex] = useState(null);
  const [edit, setEdit] = useState(false);
  const [postData, setPostData] = useState(null);
  const [snackbarState, setSnackbarState] = React.useState({ open: false });
  const [progressValue, setProgressValue] = useState(0);
  const [enableDownload, setEnableDownload] = useState(false);
  const sliderref = useRef();
  const videoRef = useRef(null);
  const cancelFileUpload = useRef(null);
  // const [content,setContent] = useState('')
  // const [images,setImages] = useState([])
  const {
    profile_image = "",
    full_name = "",
    user_tagging = "",
    badge_status = 0,
  } = !!props.post.user_id ? props.post.user_id : {};
  const { _id, type, comment_show } = props.post;
  const [tempCommentshow, settempCommentshow] = useState(comment_show);
  let expiredLive =
    props.post.post_type === 4 &&
    props.post.live.status === 0 &&
    new Date(props.post.live.end_time) < new Date();
  useEffect(() => {
    setIsFriend(props.post.isfriend);
  }, [props.post.isfriend]);
  useEffect(() => {
    if (props.post) {
      setPostData(props.post);
    }
  }, [props.post]);
  const videoObserver = useCallback((node) => {
    if (videoRef.current) videoRef.current.disconnect();
    videoRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          SetPlaying(true);
        } else {
          SetPlaying(false);
        }
      });
    });
    if (node) videoRef.current.observe(node);
  });
  const redirectDetailPage = () => {
    try {
      let { post = {}, user = {} } = props;
      if (post && post.user_id && post.user_id._id !== user._id) {
        axios
          .post(
            API_Path + "api/vurb/view",
            {
              inputObject: {
                id: post._id,
                user_id: user._id,
              },
            },
            {
              headers: {
                Authorization: "Basic " + props.auth.accessToken,
              },
            }
          )
          .then((res) => {
            // console.log('res',res)
          })
          .catch((error) => {
            console.error(error);
          });
      }

      history.push({
        pathname: "/vurbsDetail",
        state: { postObj: props },
      });
    } catch (e) {
      console.log("error", e);
    }
  };
  const expired = () => {
    setSnackbarState({
      open: true,
      message: `${props.post.live.title} is Expired`,
      severity: "error",
    });
  };
  const onSuccess = (data) => {
    setPostData({
      ...postData,
      content: data.result.content,
      type: data.result.type,
      post_type: data.result.post_type,
      origin_content: data.result.origin_content,
      docs: data.result.docs,
      post_img: data.result.post_img,
      comment_show: data.result.comment_show,
      vurb_imgs: data.result.vurb_imgs,
      vurb_video: data.result.vurb_video,
      comments_count: data.result.comments_count,
      video: data.result.video,
      location: data.result.location,
      view_count: data.result.view_count,
      dislike: data.result.dislike,
      like: data.result.like,
      hashtags: data.result.hashtags,
      share_url_meta_info: data.result.share_url_meta_info,
      device: "web",
    });
    setSnackbarState({
      open: true,
      message: data.message,
      severity: "success",
    });
    setEdit(false);
  };
  const handlePlay = () => {
    SetPlaying(true);
  };
  const handleOpen = (src) => {
    let index = postData.post_img.findIndex((x) => x === src);
    setSliderIndex(index);
    setTimeout(() => {
      setOpen(true);
      sliderref && sliderref.current.slickGoTo(index);
    }, 1);

    setImage(props.post.post_img);
  };
  const DocumentOpen = (data) => {
    window.open(data);
  };
  const handleClick = (event) => {
    setUserpost(false);
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpen = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };
  const handleReportOption = () => {
    setAnchorEl(null);
  };
  const handleSnackbarReport = () => {
    setSnackbarState({
      open: true,
      message: "Reported Successfully",
      severity: "success",
    });
  };

  const handleTurn = async () => {
    await axios
      .post(
        API_Path + "api/vurb/comment/show_or_hide",
        {
          id: _id,
        },
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((res) => {
        if (res && res.data && res.data.status === "SUCCESS") {
          setPostData({
            ...postData,
            comment_show: res.data.result.comment_show,
          });
          if (tempCommentshow) {
            setOpenDialog(false);
            setSnackbarState({
              open: true,
              message: " Turned Off Comments Successfully",
              severity: "success",
            });
          } else {
            setOpenDialog(false);
            setSnackbarState({
              open: true,
              message: " Turned On Comments Successfully",
              severity: "success",
            });
          }
        }
        settempCommentshow(!tempCommentshow);
        setAnchorEl(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const fileDelete = async () => {
    // let type = props.post.post_type;
    // let url =
    //   type === 1
    //     ? props.post.post_img[0]
    //     : type === 3
    //     ? props.post.video[0]
    //     : " ";
    // config.bucketName =
    //   type === 1
    //     ? REACT_APP_AWS_S3_IMAGE_BUGKET_NAME
    //     : type === 3
    //     ? REACT_APP_AWS_S3_VIDEO_BUGKET_NAME
    //     : "";
        if (
          props.post.vurb_imgs &&
          props.post.vurb_imgs.length > 0
        ) {
          for (const data of props.post.vurb_imgs) {
            AWSfileDelete(data.url, "images",props.hashed);
          }
        }
        // Vurbs Video Delete
        if (props.post.video && props.post.video.length > 0) {
          for (const video_url of props.post.video) {
            // console.log("video_url", video_url);
            AWSfileDelete(video_url, "video",props.hashed);
          }
        }
    // const fileName = url.split("/")[url.split("/").length - 1];
    // if (config.bucketName) {
    //   await s3FileUpload
    //     .deleteFile(fileName, config)
    //     .then((data) => data)
    //     .catch((e) => console.log("err", e));
    // }
  };
  const handleDelete = () => {
    if (userpost === false) {
      var deletePost = API_Path + "api/vurb/delete?id=" + _id;
      axios
        .delete(deletePost, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            setOpenDialog(false);
            setUserpost(true);
            setSnackbarState({
              open: true,
              message: "Deleted Successfully",
              severity: "success",
            });
            ref.onDelete(props.index);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    fileDelete();
  };
  const onClose = () => {
    setSnackbarState({ open: false });
  };
  const handleClose = () => {
    setOpen(false);
    setImage("");
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const Vurb_like = (type) => {
    ref.UpdatePostLike({
      _id: _id,
      type,
      user_id: props.user._id,
    });
  };
  const onRedirect = (url) => {
    let link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };
  const handleButtonCancel = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("Download Canceled");
      setProgressValue(0);
      setEnableDownload(false);
    }
  };
  const handleSaveVideo = async () => {
    try {
      handleCloseMenu();
      SetPlaying(false);
      let { post = {} } = props;
      let inputObj = {
        inputObject: {
          vurb_id: _id,
          s3_input_url: post.vurb_video.sourceURL,
          user_id: props.user._id,
        },
      };
      // setEnableDownload(true);

      // var savePost = process.env.REACT_APP_SAVE_VIDEO_BASEURL+"/getVideo";
      // let path =
      //   post.vurb_video.outputObj !== null
      //     ? `${process.env.REACT_APP_VIDEO_BASEURL}${post.vurb_video.outputObj.key}`
      //     : post.vurb_video.sourceURL;
      // await axios({
      //   method: "post",
      //   url: savePost,
      //   data: {
      //     inputPath: path,
      //   },
      //   responseType: "blob",
      //   onDownloadProgress: (progressEvent) => {
      //     let percentCompleted = Math.floor(
      //       (progressEvent.loaded / progressEvent.total) * 100
      //     );
      //     // console.log(percentCompleted)
      //     setProgressValue(percentCompleted);
      //   },
      //   cancelToken: new CancelToken(
      //     (cancel) => (cancelFileUpload.current = cancel)
      //   ),
      // })
      await axios
        .post(API_Path + "api/video/request", inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (response !== null && response.data !== null) {
            // const url = window.URL.createObjectURL(
            //   new Blob([response.data], { type: "video/mp4" })
            // );
            // let title =
            //   post.vurb_video.outputObj !== null
            //     ? post.vurb_video.outputObj.key
            //     : post.vurb_video.sourceURL.split("/")[
            //         post.vurb_video.sourceURL.split("/").length - 1
            //       ];
            // const link = document.createElement("a");
            // link.style = "display: none";
            // document.body.appendChild(link);
            // link.href = url;
            // link.download = title;
            // link.click();
            setSnackbarState({
              open: true,
              message:
                "Your Download Request is Processing. Once it's ready you will receive a notification to download",
              severity: "Success",
            });
            // setEnableDownload(false);
          } else {
            setSnackbarState({
              open: true,
              message: "Error in file download",
              severity: "error",
            });
            // setEnableDownload(false);
          }
        })
        .catch((error) => {
          // if (isCancel(error)) {
          //   setSnackbarState({
          //     open: true,
          //     message: error.message,
          //     severity: "error",
          //   });
          // } else {
          setSnackbarState({
            open: true,
            message: "Error in file download",
            severity: "error",
          });
          // }
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const modalClose = () => {
    setEdit(false);
  };
  const renderPDF = (docs) => {
    let docment = docs[0].split("/")[docs[0].split("/").length - 1];
    let docmentname = docment.split("__")[docment.split("__").length - 1];
    let type = docmentname.split(".")[docmentname.split(".").length - 1];
    let src =
      type === "pdf"
        ? PdfIcon
        : type === "docx"
        ? WordIcon
        : type === "pptx"
        ? PptIcon
        : ExcelIcon;
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            {
              <img
                src={src}
                onClick={() => DocumentOpen(docs)}
                alt=""
                style={{ width: "80px", cursor: "pointer" }}
              />
            }
          </div>
          <div>
            <span>{docmentname}</span>
          </div>
        </div>
      </Fragment>
    );
  };
  const actionSectionHeader = (
    <div className={classes.headerActionSection}>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img alt="" src={VDotsMenu} />
        </IconButton>
        {props.user._id === (!!props.post.user_id && props.post.user_id._id) ? (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {(!!props.post.isPromoted
              ? props.post.promotionExpiryDate &&
                new Date(props.post.promotionExpiryDate) < new Date()
              : true) &&
              props.post.post_type !== 4 && (
                <MenuItem
                  onClick={() => {
                    history.push({
                      pathname: "/payment",
                      state: { type: "Promote Vurb", id: props.post._id },
                    });
                  }}
                  style={{ color: "#FFC003" }}
                >
                  {" "}
                  Promote Vurb{" "}
                </MenuItem>
              )}
            {!expiredLive && (
              <MenuItem onClick={redirectDetailPage}>View</MenuItem>
            )}
            {props.post.live.status !== 2 ? (
              <MenuItem
                onClick={() => {
                  setEdit(true);
                  handleCloseMenu();
                }}
              >
                {" "}
                Edit
              </MenuItem>
            ) : (
              ""
            )}
            {/* {props.post &&
              ((props.post.vurb_video && props.post.vurb_video.sourceURL) ||
                (props.post.post_type === 4 &&
                  props.post.live.status === 2)) && (
                <MenuItem onClick={handleSaveVideo.bind(this, props.post)}>
                  Save Video
                </MenuItem>
              )} */}
            {props.post.post_type === 3 && (
              <MenuItem onClick={handleSaveVideo}>Save Video</MenuItem>
            )}
            <MenuItem onClick={handleTurn}>
              {" "}
              {tempCommentshow ? "Turn off commenting" : "Turn on commenting"}
            </MenuItem>
            <MenuItem onClick={handleClickOpen}> Delete</MenuItem>
            {/* <Report showType="Menu"
                type="Reporting Vurb"
                primaryKey={props.post && props.post._id}
                handleReportOption={handleReportOption}
                handleSnackbarReport={handleSnackbarReport}
                /> */}
          </Menu>
        ) : (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={redirectDetailPage}>View</MenuItem>
            {/* {props.post &&
              ((props.post.vurb_video && props.post.vurb_video.sourceURL) ||
                (props.post.post_type === 4 &&
                  props.post.live.status === 2)) && (
                <MenuItem onClick={handleSaveVideo.bind(this, props.post)}>
                  Save Video
                </MenuItem>
              )} */}
            {props.post.post_type === 3 && (
              <MenuItem onClick={handleSaveVideo}>Save Video</MenuItem>
            )}
            <Report
              showType="Menu"
              type="Reporting Vurb"
              primaryKey={props.post && props.post._id}
              handleReportOption={handleReportOption}
              handleSnackbarReport={handleSnackbarReport}
            />
          </Menu>
        )}
      </Box>
    </div>
  );
  let { user_id } = props.post ? props.post : {},
    Userurl =
      !!user_id && user_id._id ? `/friendsProfilePage/${user_id._id}` : "";
  const handleFriend = (lst) => {
    let friendListAPI = API_Path + "api/friends/sting";
    let statusAction = true;

    var inputObj = {
      inputObject: {
        userId: props.user._id,
        sting: statusAction,
        friendId: lst,
      },
    };
    axios
      .post(friendListAPI, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setIsFriend(!isfriend);
        setSnackbarState({
          open: true,
          message: "Friend Request Send Successfully",
          severity: "success",
        });
        ref.refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // String Friend Componet
  const StringFriend = useMemo(() => {
    return (
      <Fragment>
        {props.post.post_type !== 4 &&
          props.post.isfriend !== undefined &&
          props.post.isfriend !== "" &&
          !isfriend &&
          (!!user_id && user_id._id) !== props.user._id && (
            <Button
              className={classes.btnCenter}
              onClick={() => handleFriend(props.post.user_id._id)}
            >
              {" "}
              String Friend
            </Button>
          )}
      </Fragment>
    );
  }, [isfriend]);

  // const OnAutoVideoNext = () => {
  //   let next = ReactDOM.findDOMNode(document.getElementById('post-next' + props.index)).nextSibling;
  //   if (next) {
  //     next.scrollIntoView({ behaviour: 'smooth' });
  //     // e.target.muted = true;
  //   }
  // }
  return (
    <>
      <Paper
        id={`post-next${props.index}`}
        className={classes.paperWhiteParent}
        ref={ref ? ref.ref : null}
      >
        {enableDownload === true ? (
          <Box className={classes.downloadSection}>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                size={80}
                value={progressValue}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <h3>{progressValue}</h3>
              </Box>
            </Box>
            <p>Video Downloading...</p>
            <Button
              variant="contained"
              color="primary"
              className={classes.circleBtn}
              onClick={handleButtonCancel}
            >
              Cancel Download
            </Button>
          </Box>
        ) : (
          ""
        )}
        <Box p={2} className={classes.header}>
          <div style={{ position: "relative" }}>
            <CustomImage
              userImage={profile_image}
              size="52px"
              marginRight="0"
            />
            {!!badge_status && (
              <span
                className={classes.activeStatus}
                title="Verified User/Public Figure"
              >
                <VerifiedUserRoundedIcon />
              </span>
            )}
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={
                  postData &&
                  postData.post_type !== 4 &&
                  postData.location &&
                  Object.keys(postData.location).length > 0 &&
                  postData.location.name.length > 28
                    ? { display: "flex", flexDirection: "column" }
                    : { display: "contents", cursor: "pointer" }
                }
                onClick={expiredLive ? expired : redirectDetailPage}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4>
                    {props.post.post_type !== 4
                      ? full_name
                      : props.post.live.title || "Title"}
                  </h4>
                  {props.post.post_type !== 4 && user_tagging && (
                    <span
                      style={{ fontWeight: "500", paddingLeft: "4px" }}
                      className="userTagging"
                    >
                      @{user_tagging}
                    </span>
                  )}
                </div>
                {postData && postData.post_type !== 4 && postData.location && (
                  <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                    {" "}
                    is in {postData.location.name}
                  </span>
                )}
              </div>
              {StringFriend}
            </div>

            <div className={classes.SubHeader}>
              <span>
                {!type ? (
                  <img
                    src={publicIcon}
                    style={{ width: "15px", marginRight: "5px" }}
                    alt=""
                  />
                ) : (
                  <img
                    alt=""
                    src={Lockicon}
                    style={{ width: "15px", marginRight: "5px" }}
                  />
                )}
                {!type ? "Public" : "Private"}
              </span>
              {props.post.post_type === 4 && (
                <span>
                  Hosted by{" "}
                  <span
                    style={{
                      color: "#1d6cba",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => onRedirect(Userurl)}
                  >
                    {full_name}
                  </span>
                  {postData && postData.location && (
                    <span style={{ paddingLeft: "4px" }}>
                      {" "}
                      is in {postData.location.name}
                    </span>
                  )}
                </span>
              )}
              <span>
                <DebateDMY listDMY={props.post.created_at} normalDMY={"post"} />
              </span>
            </div>
          </div>
          {props.post.post_type === 4 && props.post.live.status === 1 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.liveStatus}>
                <>
                  <span className="Live"></span>LIVE
                </>
              </div>
            </div>
          )}
          {actionSectionHeader}
          {/* {props.post.post_type===4 && props.post.live.status === 1 &&  */}

          {props.post.post_type === 4 &&
            props.post.live.status === 0 &&
            new Date(props.post.live.start_time) > new Date() && (
              <div className={classes.boosted}>Scheduled</div>
            )}
          {expiredLive && <div className={classes.boosted}>Expired</div>}
          {props.post.post_type === 4
            ? " "
            : props.post.isPromoted &&
              new Date(props.post.promotionExpiryDate) > new Date() && (
                <div className={classes.boosted}>Promoted</div>
              )}
        </Box>
        <Box className={classes.content}>
          <div
            style={props.post.live.description ? { margin: "14px 0px" } : {}}
            dangerouslySetInnerHTML={{
              __html:
                (postData && postData.content) ||
                (postData && postData.live.description),
            }}
          ></div>
          {/* {postData.location && Object.keys(postData.location).length > 0 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img style={{ width: "24px" }} src={Location} alt="" />
              <span>{postData.location.name}</span>
            </div>
          )} */}
          <div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {postData &&
                postData.vurb_imgs &&
                postData.vurb_imgs.length > 0 &&
                postData.vurb_imgs.map((img, index) => {
                  let imgUrl =
                    img.url.split("/").length > 4 && img.url.includes("tenor")
                      ? img.url
                      : `${process.env.REACT_APP_VIDEO_BASEURL}${
                          img.url.split("/")[img.url.split("/").length - 1]
                        }`;
                  return (
                    <img
                      alt=""
                      className={!!props.profile ? "profileVurb" : "vurbImg"}
                      key={index}
                      src={imgUrl}
                      onClick={() => handleOpen(img.url)}
                      style={{ cursor: "pointer" }}
                    />
                  );
                })}
            </div>
            {postData &&
              postData.vurb_video &&
              postData.vurb_video.sourceURL && (
                // {postData && !!postData.video.length && (
                <div style={{ position: "relative" }} ref={videoObserver}>
                  <ReactPlayer
                    className="react-player"
                    // url={postData.video[0]}
                    url={
                      postData.vurb_video.outputObj !== null
                        ? `${process.env.REACT_APP_VIDEO_BASEURL}${postData.vurb_video.outputObj.key}`
                        : postData.vurb_video.sourceURL
                    }
                    // url={postData.video[0]}
                    playing={playing}
                    width="100%"
                    // height='100%'
                    controls
                    light={false}
                    config={{
                      file: {
                        forceHLS: false,
                      },
                    }}
                    onStart={handlePlay}
                    onPlay={handlePlay}
                    muted
                    // onEnded={()=>OnAutoVideoNext()}
                  />
                  {/* <img alt="Logo" src={logoImage} className={classes.videoLogo} /> */}
                </div>
              )}
            {props.post &&
              props.post.post_type === 4 &&
              new Date(props.post.live.start_time) <= new Date() &&
              new Date(props.post.live.end_time) >= new Date() &&
              props.post.live.broadcast_channel_url != null &&
              props.post.live.status === 1 && (
                <AudienceAdInjection
                  endedaudienceview={() => {
                    ref.refresh();
                  }}
                  videoUrl={props.post.live.broadcast_channel_url}
                  adsList={[]}
                  // adsList={audience_ads}
                />
              )}
            {props.post &&
              props.post.post_type === 4 &&
              props.post.live.status === 2 && (
                <div style={{ position: "relative" }} ref={videoObserver}>
                  <ReactPlayer
                    className="react-player"
                    url={
                      !!props.post.live.meta_info
                        ? `${process.env.REACT_APP_VIDEO_BASEURL}${props.post.live.meta_info.key}`
                        : props.post.live.recorded_url
                    }
                    // light={props.post.live.thumbnailImg}
                    playing={playing}
                    width="100%"
                    // height='100%'
                    controls
                    config={{
                      file: {
                        forceHLS: false,
                      },
                    }}
                    muted
                    // onStart={handlePlay}
                    // onPlay={handlePlay}
                    // onEnded={()=>OnAutoVideoNext()}
                  />
                  {/* <VideoPlayer videoURL={props.post.live.recorded_url} videoThumbnail={props.post.live.thumbnailImg} playing={false} /> */}
                  {/* <img
                    alt="Logo"
                    src={logoImage}
                    className={classes.videoLogo}
                  /> */}
                </div>
              )}

            {postData &&
              postData.docs &&
              !!postData.docs.length &&
              renderPDF(postData.docs)}
            {postData &&
              postData.location &&
              Object.keys(postData.location).length > 0 &&
              !(
                (postData.vurb_imgs && postData.vurb_imgs.length > 0) ||
                !!postData.video.length ||
                (postData.docs && !!postData.docs.length) ||
                postData.post_type === 4
              ) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <img
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${postData.location.lat},${postData.location.lng}&zoom=13&size=520x300&markers=color:red%7C${postData.location.lat},${postData.location.lng}&key=AIzaSyCsXeKdTbHEM-GC36fI5dqrBqVBAhiKpc4`}
                    alt="staticmap"
                  />
                </div>
              )}
            {postData &&
              postData.share_url_meta_info &&
              postData.share_url_meta_info !== null && (
                <CommonMeta
                  data={postData.share_url_meta_info}
                  redirect={onRedirect}
                />
              )}
          </div>
        </Box>
        <Box className={classes.footer}>
          <div
            style={{
              display: "flex",
              margin: "12px",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "18px",
              }}
            >
              <img
                alt=""
                src={
                  _.get(props.post, "like_users", []).includes(props.user._id)
                    ? ThumbsYellow
                    : Thumbs
                }
                onClick={() => Vurb_like("like")}
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
              />{" "}
              <h5 style={{ margin: "0 0 0 10px" }}>
                {/* {props.post && !!props.post.like ? props.post.like : 0} */}
                {/* {_.get(props.post, 'like', 0)} */}
                <LikeUserCompoent
                  count={numberFormatter(_.get(props.post, "like", 0))}
                  active={0}
                  auth={props.auth}
                  id={props.post._id}
                />
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "18px",
              }}
            >
              <img
                alt=""
                src={
                  _.get(props.post, "dislike_users", []).includes(
                    props.user._id
                  )
                    ? ThumbsYellow
                    : Thumbs
                }
                onClick={() => Vurb_like("dislike")}
                style={{
                  transform: "rotate(180deg)",
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
              />{" "}
              <h5 style={{ margin: "0 0 0 10px" }}>
                {/* {props.post && props.post.dislike_users && !!props.post.dislike_users.length ? props.post.dislike_users.length : 0} */}
                {/* {_.get(props.post, 'dislike', 0)} */}
                <LikeUserCompoent
                  count={numberFormatter(_.get(props.post, "dislike", 0))}
                  active={1}
                  auth={props.auth}
                  id={props.post._id}
                />
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "18px",
                cursor: "pointer",
              }}
              onClick={expiredLive ? expired : redirectDetailPage}
            >
              <img
                alt=""
                src={CommentImg}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              <h5 style={{ margin: "0 0 0 10px" }}>
                {numberFormatter(
                  props.post && !!props.post.comment_count
                    ? props.post.comment_count
                    : !!props.post.comments_count
                    ? props.post.comments_count
                    : 0
                )}
                {/* {props.debateDetail && props.debateDetail.comments_count} */}
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: "18px",
              }}
              onClick={expiredLive ? expired : redirectDetailPage}
            >
              <img
                alt=""
                src={Retweet}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              <h5 style={{ margin: "0 0 0 10px" }}>
                {numberFormatter(_.get(props.post, "share", 0))}
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: "18px",
              }}
            >
              <VisibilityOutlinedIcon />
              <h5 style={{ margin: "0 0 0 10px" }}>
                {numberFormatter(_.get(props.post, "view_count", 0))}
              </h5>
            </div>
          </div>
        </Box>
      </Paper>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      <ConfirmationModal
        openDialog={openDialog}
        modalHeading="Message"
        handleDialogClose={handleDialogClose}
        handleDelete={handleDelete}
        modalMessage="Are you sure to delete the vurb?"
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="xs"
      />
      {open && (
        <CustomImageSlider
          user={props.user}
          auth={props.auth}
          open={open}
          img={img}
          handleClose={handleClose}
          sliderref={sliderref}
          sliderindex={sliderindex}
          post={postData}
        />
      )}
      <Modal open={edit}>
        <CreateVurb
          onSuccess={onSuccess}
          vurbpost={true}
          close={modalClose}
          post={postData}
        />
      </Modal>
    </>
  );
});
export default CommonPostCard;
