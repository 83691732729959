import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import loginBg from "../../../assets/images/loginBg.png";
import loginBgDark from "../../../assets/images/darkLogin/dark_background.png";
import logoImage from "../../../assets/images/Vurbil - Final-02.png";
import mobileIllution from "../../../assets/images/mobileglow.png";
import mobileIllutionDark from "../../../assets/images/darkLogin/Dark_illustration.png";

import Modal from "../../../components/Modal/index";
import SnackBar from "../../../components/SnackBar";
import {
  API_Path,
  DB_DateFormat,
  UI_DateFormat,
  Make_Base_Auth,
  redirectTo,
} from "../../Common/Const/index";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  loginBg: {
    padding: "30px 100px 30px 30px",
    background: "url(" + loginBgDark + ") fixed, #f6f7f9",
    color: "white",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "15px",
    },
    // backgroundColor: '#f6f7f9',
    // [theme.breakpoints.up('md')]: {
    //   padding: '30px 100px 30px 30px',
    //   backgroundImage: "url(" + loginBg + ")",
    //   backgroundSize: 'cover',
    // },
    // [theme.breakpoints.down('md')]: {
    //   display: 'flex',
    //   flexFlow: 'column',
    //   justifyContent: 'center',
    // }
  },
  mobileGlow: {
    position: "relative",
    "& .logo":{
      position:"absolute",
      top: "0",
      left: "30%",
      maxWidth: '10%',
      "z-index": 100
    },
    "& .illusion": {
      position: "absolute",
      width:'50%',
      top: "64px",
      left: "10%",
    },
  },
  joinChat: {
    maxWidth: "500px",
    boxSizing: "border-box",
    // [theme.breakpoints.up('md')]: {
    //     padding: '29px 45px 39px 42px',
    //     borderRadius: '20px',
    //     boxShadow: '1px 3px 12px 0 rgba(0, 0, 0, 0.06)',
    //     backgroundColor: 'rgba(255, 255, 255, .9)',
    //     margin: '0 0 0 auto',
    // },
    padding: "29px 45px 39px 42px",
    borderRadius: "20px",
    boxShadow: "1px 3px 12px 0 rgba(0, 0, 0, 0.06)",
    // backgroundColor: "rgba(255, 255, 255, .5)",
    backgroundColor: "#CDCDCD29", // for dark login
    margin: "0 0 0 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto 0",
    },
    "& h1, & h2": {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    "& .logo":{
      maxWidth: '50%',
    },
  },
  textField: {
    marginBottom: theme.spacing(0),
    maxHeight: "inherit",
    // maxHeight: theme.spacing(6),
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white !important', // Change the bottom border color to white
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white !important', // Change the bottom border color to white
    },
    '& .MuiInputBase-root': {
      color: "white"
    },
    '& .MuiFormLabel-root': {
      color: "white"
    },
    '& .MuiIconButton-label': {
      color: "white"
    }
    
    
  },
  labelShrink: {
    "& .MuiInputLabel-root": {
      transform: "translate(0, 1.5px) scale(0.75)",
      transformOrigin: "top left",
      color: "white"
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
    },
  },
  whiteText:{
    color: "white",
  },
  loginButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.75),
  },
  margin0: {
    margin: "0",
  },
  textCenter: {
    textAlign: "center",
  },
  fontWeightNormal: {
    fontWeight: "normal",
  },
  otherLoginSpacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    "& h6": {
      color: "#758194",
      marginBottom: theme.spacing(1.25),
    },
    "& button": {
      margin: "0 3px",
    },
  },
  signupButton: {
    "& .normalTextBtn": {
      fontWeight: "normal",
      marginRight: "5px",
      color: "#758194",
    },
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    color: "black",
  },
  agreeButton: {
    fontSize: "12px",
    lineHeight: "1.5",
    color: "white",
    "& a": {
      // color: "#1d6cba",
      color: "white",
      "font-weight": "bold",
      textDecoration: "none",
    },
  },
  appLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h4": {
      margin: "-12px 0 0 0",
      fontWeight: "500",
      letterSpacing: "1px",
      lineHeight: 1,
      textTransform: "uppercase",
    },
  },
}));
const initState = {
  first_name: "",
  last_name: "",
  email: "",
  dob: null,
  city: "",
  country: "",
  password: "",
  confirm_password: "",
  accept: false,
  // state: "", mobile: "", address_line1: "", address_line2: "", pincode: "",
};
const UserSignup = () => {
  const classes = useStyles();
  const [country, setCountry] = React.useState(null);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateValue, setStateValue] = React.useState(null);
  const [city, setCity] = React.useState(null);
  // const [initState, setinitState] = useState();
  const [submitted, setsubmitted] = useState(false);
  const [TCError, setTCError] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
  });
  // const [accept, setaccept] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedValue, setselectedValue] = React.useState("");
  const [tcAction, setTCAction] = React.useState(false);
  // const [applicationLogo, setApplicationLogo] = useState("");

  const handleChangeCheck = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    setTCAction(event.target.checked);
    event.target.checked && setTCError(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCountry = (obj) => {
    if (obj) {
      setCountry(obj);
    } else {
      setCountry(null);
    }
    setStateValue(null);setCity(null);
    setStateList([]);setCityList([]);
  };

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  const handlePopup = (value) => {
    setselectedValue(value);
    setOpenModal(true);
  };

  const validationSchema = yup.object({
    first_name: yup.string().required("First Name is Required"),
    last_name: yup.string().required("Last Name is Required"),
    // profile_image: yup.string().required('Profile Image is Required'),
    email: yup.string().email("Invalid email").required("Email is Required"),
    // mobile: yup.string().trim().required('Phone Number is Required').matches(/^[0-9]*$/, 'Invalid Number').min(10, 'Number should be 10 digits').max(10, 'Number should be 10 digits'),
    // dob: yup.date().nullable().max(new Date(), "Current Date is not valid").required('DOB is required'),
    dob: yup
      .date()
      .nullable()
      .max(new Date(), "Current Date is not valid")
      .required("DOB is required")
      .test("DOB", "Age limit should be 13", (value) => {
        return moment().diff(moment(value), "years") >= 13;
      }),
    // address_line1: yup.string().required('Address Line 1 is Required'),
    // address_line2: yup.string().required('Address Line 2 is Required'),
    city: yup.string().required("City is Required"),
    state: yup.string().required('State is Required'),
    country: yup.string().required("Country is Required"),
    // pincode: yup.string().required('Pincode is Required').max(5, 'Pincode should be 5 digits'),
    // user_name: yup.string().required('User Name is Required'),
    password: yup
      .string()
      .required("Password is Required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must contain 6 character, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character"
      ),
    confirm_password: yup
      .string()
      .required("Confirm Password is Required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Both password need to be the same"),
      }),
    // accept: yup.boolean().required('Requis').oneOf([true], "Error")
  });

  const submitSignUp = async (state, { resetForm }) => {
    if (tcAction) {
      setsubmitted(true);
      var SignupAPI = API_Path + "user/create";
      var inputObj = {
        inputObject: {
          first_name: state.first_name,
          last_name: state.last_name,
          profile_image: null,
          email: state.email.toLowerCase(),
          // "mobile": state.mobile,
          mobile: "",
          dob: moment(state.dob).format(DB_DateFormat),
          tags: null,
          active_status: 0,
          full_address: {
            address_line1: state.address_line1,
            address_line2: state.address_line2,
            city: state.city,
            state: state.state,
            country: country.name,
            country_code: country.code,
            pincode: state.pincode,
          },
          user_name: "",
          password: state.password,
          salt: null,
          is_custom_login: null,
          sso_hash_key: null,
          sso_type: null,
          password_expiry_status: null,
          password_attempts: 0,
          last_login: null,
          rating: 0,
          user_type: 1,
          roles: [],
          saved_videos: [],
          created_by: null,
          updated_by: null,
        },
      };
      axios
        .post(SignupAPI, inputObj, {
          headers: {
            Authorization: Make_Base_Auth(),
          },
        })
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            setsubmitted(false);
            resetForm({ state: {} });
            // setSnackbarState({
            //     open: true,
            //     message: "User created Succcessfully",
            //     severity: "success",
            // });
            setSignupSuccess(true);
          } else {
            setSnackbarState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
            setsubmitted(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setSnackbarState({
            open: true,
            message: "Something went Wrong!",
            severity: "error",
          });
          setsubmitted(false);
        });
    } else {
      setTCError(true);
      setsubmitted(false);
    }
  };

  // const handleChange = (event) => {
  //     const name = event.target.name;
  //     setState({
  //         ...state,
  //         [name]: event.target.value,
  //     });
  // };

  useEffect(() => {
    getCountryList();
    // GetApplicationLogo();
  }, []);
  const getCountryList = () => {
    var countryListApi = API_Path + "api/country/get";
    axios
      .get(countryListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        setCountryList(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const GetApplicationLogo = () => {
  //   axios
  //     .get(API_Path + "api/auth/get_application_logo", {
  //       headers: {
  //         Authorization: Make_Base_Auth(),
  //       },
  //     })
  //     .then((response) => {
  //       if (
  //         response !== null &&
  //         response.data !== null &&
  //         response.data.result !== null
  //       ) {
  //         setApplicationLogo(response.data.result);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  // const preventNonNumericalInput = (evt) => {
  //   var key = evt.keyCode;
  //   if (
  //     !(
  //       (key >= 48 && key <= 57) ||
  //       (key >= 96 && key <= 105) ||
  //       key === 37 ||
  //       key === 39 ||
  //       key === 8 ||
  //       key === 9 ||
  //       key === 46
  //     )
  //   ) {
  //     evt.returnValue = false;
  //     if (evt.preventDefault) evt.preventDefault();
  //   }
  // };
  const getStateList = async (country) => {
    if (!country) return false;
    var countryListApi = API_Path + `api/country/getState/${country}`;
    axios
      .get(countryListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        const data = response.data.result.filter((val) => val._id && val.name);
        setStateList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const getCityList = async (country, state) => {
    if (!country || !state) return false;
    var countryListApi = API_Path + `api/country/getCity/${country}/${state}`;
    axios
      .get(countryListApi, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        // console.log(response)
        const data = response.data.result.filter((val) => val._id && val.name);
        setCityList(data);
        // selected city object creation
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const logoSection = <img  alt="Logo"  className={`logo`} src={logoImage} />;
  return (
    <React.Fragment>
      {signupSuccess
        ? redirectTo("/signin", {
          msg: "User account created successfully. Please verify account via email",
        })
        : ""}
      <div className={classes.loginBg}>
        {/* <Hidden smDown>
          <Box
            display="flex"
            justifyContent={{ xs: "center", lg: "space-between" }}
          > */}
            {/* {logoSection} */}
            {/* <Box className={classes.appLogo}>
                            {applicationLogo !== null && applicationLogo !== undefined ?
                            <>

                                {applicationLogo.logo !== null && applicationLogo.logo !== undefined ?
                                <img alt="Logo" src={applicationLogo.logo && applicationLogo.logo.url} style={{height: '54px', objectFit: 'contain', marginLeft: '-14px'}} />
                                :
                                <img alt="Logo" src={beeLogo} style={{height: '40px', objectFit: 'contain', marginLeft: '-14px'}} />
                                }
                                <h4>
                                {applicationLogo.application_name !== null && applicationLogo.application_name !== undefined ?
                                    applicationLogo.application_name
                                    :
                                    <img alt="Logo" src={textLogo} style={{width: '140px', height: '15px', objectFit: 'contain'}} />
                                }
                                </h4>
                            </>
                            :
                            <img alt="Logo" src={logoImage} />
                            }
                        </Box> */}
          {/* </Box>
        </Hidden> */}
        <Hidden smDown>
          <div className={classes.mobileGlow}>
            {logoSection}
            <img className="illusion" src={mobileIllutionDark} alt="mobile-illution" />
          </div>
        </Hidden>
        <div className={classes.joinChat}>
          <Hidden mdUp>
            <Box textAlign="center" mb={2}>
              {logoSection}
            </Box>
          </Hidden>
          <h1 className="m-0">Sign Up</h1>
          <Formik
            initialValues={{ ...initState }}
            onSubmit={submitSignUp}
            validationSchema={validationSchema}
            validateOnBlur={true}
          >
            {(formprops) => {
              return (
                <Form autoComplete="off">
                  <Grid container className={classes.root} spacing={(0, 2)}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="first_name"
                        label="First Name*"
                        placeholder=""
                        type="text"
                        as={FormikInput}
                        className={clsx(classes.textField)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="last_name"
                        label="Last Name*"
                        placeholder=""
                        type="text"
                        as={FormikInput}
                        className={clsx(classes.textField)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Field
                          component={KeyboardDatePicker}
                          format="MM/dd/yyyy"
                          type="text"
                          name="dob"
                          label="DOB*"
                          placeholder="MM/DD/YYYY"
                          as={FormikInput}
                          className={clsx(
                            classes.textField,
                            classes.labelShrink
                          )}
                          autoOk
                          variant="inline"
                          maxDate={new Date()}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="email"
                        label="Email*"
                        placeholder=""
                        type="text"
                        as={FormikInput}
                        className={clsx(classes.textField)}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                                        <Field name="mobile" label="Mobile*" placeholder="" type="number"  as={FormikInput} className={clsx(classes.textField)} />
                                        <Field name="mobile" label="Mobile">
                                            {({
                                            field, // { name, value, onChange, onBlur }
                                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                            meta,
                                            }) => (
                                            <div>
                                                <input type="number" placeholder="" pattern="[0-9]" 
                                                onKeyDown={preventNonNumericalInput}
                                                {...field} 
                                                />
                                                {meta.touched && meta.error && (
                                                <div className="MuiFormHelperText-root Mui-error">{meta.error}</div>
                                                )}
                                            </div>
                                            )}
                                        </Field>
                                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="password"
                        label="Password*"
                        placeholder=""
                        type="password"
                        as={FormikInput}
                        className={clsx(classes.textField)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="confirm_password"
                        label="Confirm Password*"
                        placeholder=""
                        type="password"
                        as={FormikInput}
                        className={clsx(classes.textField)}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                                        <Field name="address_line1" label="Address Line 1*" placeholder="" type="text" as={FormikInput} className={clsx(classes.textField)} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field name="address_line2" label="Address Line 2" placeholder="" type="text" as={FormikInput} className={clsx(classes.textField)} />
                                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        id="country-combo-box"
                        options={countryList}
                        getOptionLabel={(option) => option.name}
                        value={country}
                        getOptionSelected={(option, value) => {
                          return value.name === option.name;
                        }}
                        onChange={(e, value) => {
                          handleCountry(value);
                          getStateList(value ? value.name : "");
                          formprops.setFieldValue(
                            "country",
                            value ? value.name : ""
                          );
                          formprops.setFieldValue(
                            "state", ""
                          );
                          formprops.setFieldValue(
                            "city", ''
                          );
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <Field
                            {...params}
                            name="country"
                            label="Country*"
                            placeholder=""
                            type="text"
                            // variant="outlined"
                            as={FormikInput}
                            className={clsx(
                              classes.textField,
                              classes.textFieldAutocomplete
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {/* <Field
                        name="city"
                        label="City*"
                        placeholder=""
                        type="text"
                        as={FormikInput}
                        className={clsx(classes.textField)}
                      /> */}
                      <Autocomplete
                        id="country-combo-box"
                        options={stateList}
                        getOptionLabel={(option) => option.name}
                        value={stateValue}
                        getOptionSelected={(option, value) => {
                          return value.name === option.name;
                        }}
                        onChange={(e, value) => {
                          getCityList(country?country.name:'',value ? value.name : "");
                          if(value)setStateValue(value);else setStateValue(null);
                          formprops.setFieldValue(
                            "state",
                            value ? value.name : ""
                          );
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <Field
                            {...params}
                            name="state"
                            label="State*"
                            placeholder=""
                            type="text"
                            // variant="outlined"
                            as={FormikInput}
                            className={clsx(
                              classes.textField,
                              classes.textFieldAutocomplete
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {/* <Field
                        name="state"
                        label="State"
                        placeholder=""
                        type="text"
                        as={FormikInput}
                        className={clsx(classes.textField)}
                      /> */}
                      <Autocomplete
                        id="country-combo-box"
                        options={cityList}
                        getOptionLabel={(option) => option.name}
                        value={city}
                        getOptionSelected={(option, value) => {
                          return value.name === option.name;
                        }}
                        onChange={(e, value) => {
                          if(value)setCity(value);else setCity(null);
                          formprops.setFieldValue(
                            "city",
                            value ? value.name : ""
                          );
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <Field
                            {...params}
                            name="city"
                            label="City*"
                            placeholder=""
                            type="text"
                            // variant="outlined"
                            as={FormikInput}
                            className={clsx(
                              classes.textField,
                              classes.textFieldAutocomplete
                            )}
                          />
                        )}
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                                        <Field name="user_name" label="User Name" placeholder="" type="text" as={FormikInput} className={clsx(classes.textField)} />
                                    </Grid> */}

                    {/* <Grid item xs={12} sm={6}>
                                        <Field name="pincode" label="Pin Code*" placeholder="" type="number" as={FormikInput} className={clsx(classes.textField)} />
                                    </Grid> */}
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={accept}
                            onChange={handleChangeCheck}
                            name="accept"
                          />
                        }
                        label={
                          <div className={classes.agreeButton}>
                            <span>
                              By creating account , you agree to accept our{" "}
                            </span>
                            <Link
                              to="/userSignup"
                              onClick={() => handlePopup("Privacy Policy")}
                            >
                              Privacy policy,
                            </Link>
                            <span> and </span>
                            <Link
                              to="/userSignup"
                              onClick={() =>
                                handlePopup("Terms and conditions")
                              }
                            >
                              Terms of service
                            </Link>
                          </div>
                        }
                      />
                      {TCError && (
                        <div style={{ color: "#f44336", fontSize: "0.75rem" }}>
                          {
                            "Please read and accept the Privacy Policy & Terms of Service"
                          }
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={clsx(classes.loginButton, classes.circleBtn)}
                        type="submit"
                        disabled={submitted}
                      >
                        Sign Up
                      </Button>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          // color: "#758194",
                          color: "white",
                          fontSize: "12px",
                        }}
                      >
                        Already have an account? &nbsp;
                        <Link
                          to="/"
                          style={{
                            // color: "#333",
                            color: "white",
                            textDecoration: "none",
                            fontWeight: "500",
                          }}
                        >
                          Sign In
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      <Modal
        open={openModal}
        selectedValue={selectedValue}
        handleClose={handleClose}
        maxWidth="sm"
      />
    </React.Fragment>
  );
};

export default UserSignup;
