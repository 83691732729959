import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FiberManualRecord, Cancel as CancelIcon } from "@material-ui/icons";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Stop from "../../../assets/images/stop.png";
import fixWebmDuration from 'fix-webm-duration'
const limit = 60;
let mediaStream;
const useStyles = makeStyles((theme) => ({
  dNone: {
    display: "none",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonSpan: {
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "0px",
    borderRadius: "14px",
    fontSize: "30px",
    right: "0px",
  }
}));

const UploadVideo = ({
  commenttimer,
  isFileUploaded,
  setFieldValue,
  previewVideo,
  setPreviewVideo,
  setIsFileUploaded,
  onClose,
  onSubmit,
  userId,
}) => {
  const classes = useStyles();
  const videoEle = useRef();
  const startButton = useRef();
  const stopButton = useRef();
  const stopLiveButton = useRef();
  const submitButton = useRef();

  const [counterTimer, setCounterTimer] = useState(0);
  const [isLive, setIsLive] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  // const [file] = useState(null);
  const [allowed, setAllowed] = useState(true);
  const [loading, setLoading] = useState(true);

  const callNav = () => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
      navigator.mediaDevices.getUserMedia = function (constraintObj) {
        let getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraintObj, resolve, reject);
        });
      };
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            console.log(device.kind.toUpperCase(), device.label);
            //, device.deviceId
          });
        })
        .catch((err) => {
          if (err.message === "Permission denied") {
            setAllowed(false);
          }
          console.log(err.name, err.message);
        });
    }
  };

  const startNav = () => {
    let startButtonRef = startButton.current;
    let stopButtonRef = stopButton.current;

    let stoplive = stopLiveButton.current;
    let submitButtonRef = submitButton.current;

    let constraintObj = {
      audio: true,
      video: {
        facingMode: "user",
        width: { min: 640, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 720, max: 1080 },
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraintObj)
      .then((mediaStreamObj) => {
        //connect the media stream to the first video element=
        let video = videoEle.current;
        // const options = {
        //   audioBitsPerSecond: 128000,
        //   videoBitsPerSecond: 2500000,
        //   mimeType: 'video/webm;codecs=avc1',
        //   // mimeType:'video/webm;codecs=vp8,vp9,opus',
        //   //vp8,vp9,h265,h264,h261,opus,
        // };
        let mediaRecorder = new MediaRecorder(mediaStreamObj);
        mediaStream = mediaStreamObj;
        let counter = 0, startTime;
        let timer;
        let chunks = [];

        if ("srcObject" in video) {
          video.srcObject = mediaStreamObj;
          setLoading(false);
        } else {
          //old version
          video.src = window.URL.createObjectURL(mediaStreamObj);
        }

        video.onloadedmetadata = function (ev) {
          video.play();
        };

        video.ontimeupdate = function (e) {
          // console.log(counter)
          if (
            !(counter < (commenttimer ? commenttimer : limit)) &&
            mediaRecorder.state === "recording"
          ) {
            counter = 0;
            setCounterTimer(0);
            setIsRecording(false);
            mediaRecorder.stop();
            clearInterval(timer);
          }
        };

        stoplive.addEventListener("click", async () => {
          if (mediaRecorder.state === "recording") {
            counter = 0;
            mediaRecorder.stop();
            setCounterTimer(0);
            setIsRecording(false);
            clearInterval(timer);
          }
          setIsLive(false);
          mediaStreamObj.getTracks().forEach((track) => track.stop());
          onClose();
          // console.log()
        });
        submitButtonRef.addEventListener("click", async () => {
          mediaStreamObj.getTracks().forEach((track) => track.stop());
        });

        startButtonRef.addEventListener("click", async (ev) => {
          if (mediaStreamObj.active) {
            mediaRecorder.start();
            setIsRecording(true);
            startTime = Date.now();
            timer = setInterval(() => {
              counter++;
              setCounterTimer(counter);
            }, 1000);
          }
        });
        stopButtonRef.addEventListener("click", (ev) => {
          if (mediaRecorder.state === "recording") {
            counter = 0;
            mediaRecorder.stop();
            setIsRecording(false);
            setCounterTimer(0);
            clearInterval(timer);
          }
        });

        mediaRecorder.ondataavailable = function (ev) {
          chunks.push(ev.data);
        };
        mediaRecorder.onstop = async (ev) => {
          var duration = Date.now() - startTime;
          let blobnext = Object.assign({ type: chunks.type }, { type: "video/mp4" })
          const blob = new Blob(chunks, blobnext);
          fixWebmDuration(blob, duration, { logger: false })
            .then(function (fixedBlob) {
              fixedBlob.name = `vurbs_${Date.now().toString()}_live.mp4`;
              fixedBlob.lastModifiedDate = new Date();
              // let file = new File(
              //   [fixedBlob],
              //   `vurbs__${userId}__${Date.now().toString()}__live.mp4`,
              //   { type: "video/mp4" }
              // );
              const url = URL.createObjectURL(fixedBlob)
              setIsFileUploaded(true);
              setFieldValue(fixedBlob);
              setPreviewVideo(url);
            });
        };
      })
      .catch((err) => {
        // if (err.message === "Permission denied") {
        //   setAllowed(false);
        // }
        setAllowed(false)
        console.log(err.name, err.message);
      });
  };

  const activate = () => {
    setIsLive(true);
    setPreviewVideo(null);
    callNav();
    startNav();
  };

  useEffect(() => {
    activate();

    return () => {
      setPreviewVideo(null);
      setFieldValue(null);
      setIsFileUploaded(false);
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
      // mediaStreamObj.getTracks().forEach(track => track.stop())
    };

    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-100 liveVideo-wrapper" style={{ width: "100%" }}>
      {allowed && (
        <Fragment>
          <div style={{ position: "relative" }}>
            <video
              className={`${previewVideo && isFileUploaded ? classes.dNone : ""
                }`}
              ref={videoEle}
              width="100%"
              muted="muted"
            />
            {loading && (
              <Typography
                variant="body1"
                style={{ position: "absolute", top: "50%", left: "50%" }}
              >
                <CircularProgress />
              </Typography>
            )}
            <div
              className={`${!isRecording ? classes.dNone : ""}`}
              style={{
                position: "absolute",
                right: "50%",
                bottom: "10%",
                cursor: "pointer",
              }}
              ref={stopButton}
            >
              <div className="d-flex align-items-center">
                <img
                  src={Stop}
                  onClick={() => setPreviewVideo(null)}
                  alt="stop"
                  style={{ border: "4px solid #ff3d00", borderRadius: "30px" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {isRecording && <span className="live">Recording</span>}
              {isRecording && (
                <span className="timer">
                  {(commenttimer ? commenttimer : limit) - counterTimer} secs
                  left
                </span>
              )}
            </div>
          </div>

          {isFileUploaded && previewVideo && (
            <video src={previewVideo} id="vid2" width="100%" controls></video>
          )}

          <div
            className={classes.buttonSection}
            style={isFileUploaded && previewVideo ? { float: "right" } : {}}
          >
            <Button
              className={`${isRecording || !isLive || (isFileUploaded && previewVideo)
                  ? classes.dNone
                  : ""
                }`}
              variant="contained"
              color="primary"
              type="button"
              onClick={() => setPreviewVideo(null)}
              ref={startButton}
            >
              {
                <span className={classes.buttonSpan}>
                  <FiberManualRecord style={{ color: "red" }} />
                  Start Recording
                </span>
              }
            </Button>
            {
              <div className={`${isRecording ? classes.dNone : ""}`}>
                <Button
                  className={`${isLive ? classes.dNone : ""} `}
                  type="button"
                  id="recagain"
                  onClick={activate}
                >
                  Record Again
                </Button>
                <Button
                  className={`${!isLive ? classes.dNone : ""} `}
                  variant="contained"
                  color="secondary"
                  type="button"
                  ref={stopLiveButton}
                >
                  Cancel
                </Button>
                <Button
                  className={`${isFileUploaded && previewVideo ? "" : classes.dNone
                    }`}
                  variant="contained"
                  color="primary"
                  ref={submitButton}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </div>
            }
          </div>
          {/* <button className={`${!isRecording ? "d-none" : ""}`} type="button" role="button" ref={stopButton}>STOP RECORDING</button> */}

          {/* 
                {<div className={`${isRecording ? "d-none" : ""}`}>
                    <button className={`${isLive ? "d-none" : ""}`} type="button" role="button" id="recagain" onClick={activate}>Record Again</button><br />
                    <button className={`${!isLive ? "d-none" : ""}`} type="button" role="button" ref={stopLiveButton}>Stop Live</button>
                </div>} */}
        </Fragment>
      )}

      {!allowed && (
        <div className="permission-denied">
          <IconButton className={classes.closeButton} onClick={() => {
            if (mediaStream) {
              mediaStream.getTracks().forEach((track) => track.stop());
            }
            onClose();
          }}> <CancelIcon /> </IconButton>

          Set Micro Phone and Camera Permission allowed to Record Video
        </div>
      )}
    </div>
  );
};

export default UploadVideo;
