import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { API_Path, Make_Base_Auth } from "../../Common/Const/index";
import { useDispatch } from "react-redux";
import SnackBar from "../../../components/SnackBar";

const AdLogin = (props) => {
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect_to = queryParams.get('redirect_to');
    const dispatch = useDispatch();
    const [snackbarState, setSnackbarState] = useState({ open: false });

    useEffect(() => {
        const checkValidateAdToken = async () => {
            try {
                if (!params.adToken || params.adToken === "") {
                    setSnackbarState({
                        open: true,
                        message: "adToken is required",
                        severity: "error",
                    });
                    window.location.href = "/signin";
                }
                
                const SignupAPI = API_Path + "api/auth/login";
                const inputObj = {
                    ad_token: params.adToken
                };

                const response = await axios.post(SignupAPI, inputObj, {
                    headers: {
                        Authorization: Make_Base_Auth(),
                    },
                });
                
                console.log("adlogin response", response.data);
                
                if(response.data.status == "FAILURE"){
                    setSnackbarState({
                        open: true,
                        message: response.data.message,
                        severity: "error",
                    });
                    window.location.href = "/signin";
                } else {
                    setSnackbarState({
                        open: true,
                        message: response.data.message,
                        severity: "success",
                    });

                    let input = {
                        email: response.data.result.user.email,
                        _id: response.data.result.user._id,
                        name: response.data.result.user.full_name,
                        firstName: response.data.result.user.first_name,
                        lastName: response.data.result.user.last_name,
                        usertagging: response.data.result.user.user_tagging,
                        mobile: response.data.result.user.mobile,
                        profile_image: response.data.result.user.profile_image,
                        badge_status: !!response.data.result.user.badge_status
                          ? true
                          : false,
                        isSupport: response.data.result.isSupport ? true : false,
                    };
                    dispatch({
                        type: "LOGIN_GLOBAL_DETAILS",
                        data: {
                          authenticated: true,
                          auth: response.data.result.jwt,
                          user: input,
                          currentPackage: response.data.result.currentPaidPackage,
                          is_disable_ads: response.data.result.is_disable_ads,
                          is_disable_broadcast: response.data.result.is_disable_broadcast,
                          logo: response.data.result.logo,
                          reminder_content: response.data.result.reminder_content,
                        },
                    });
                   
                    window.location.href = redirect_to;
                }
                
            } catch (error) {
                setSnackbarState({
                    open: true,
                    message: "Not a valid ad url: " +error,
                    severity: "error",
                });
                window.location.href = "/signin";
            }
        };

        checkValidateAdToken();
    }, [params.adToken]);

    return (
        <div style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
            <SnackBar
                open={snackbarState.open}
                message={snackbarState.message}
                severity={snackbarState.severity}
            />
        </div>
        
    );
};

export default AdLogin;
