import {
  Box,
  Grid,
  Modal,
  TextField,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { API_Path } from "../../../Common/Const";
import Loader from "../../../Common/Loader";
import axios from "axios";
import { debounce } from "lodash";
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "7px",
    borderRadius: "14px",
    fontSize: "30px",
    right: "7px",
  },
  adsFilterLable: {
    textAlign: "left",
    float: "left",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    justifyContent: "center",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
  height: 300,
};
let Month = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];
const FilterModal = ({
  open,
  auth,
  getList,
  handleChange,
  filterData,
  performance = false,
  Clear,
  Geographies = false,
  setOpenModal
}) => {
  const [adsOption, setadsOption] = useState([]);
  const [inputValues, setInputValue] = useState("");
  const [yearOption, setyearOptions] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    let active = true;
    // if (inputValues !== "") {
    //   setadsOption([...adsOption,...filterData.adsId]);
    // }

    fetchData({ input: inputValues }, (results) => {
      if (active) {
        let newOptions = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setadsOption(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValues]);
  useEffect(() => {
    YearOptions();
  }, []);

  const fetchData = useMemo(
    () =>
      debounce(async (request, callback) => {
        try {
          const response = await adsList({ searchText: request.input });
          callback(response);
        } catch (error) {
          console.log("Error fetching location data:", error);
          callback([]);
        }
      }, 500),
    []
  );
  const adsList = async (req_data) => {
    let api = API_Path + `api/ads/adsTitleList`;
    const result = await axios
      .post(api, req_data, {
        headers: {
          Authorization: "Basic " + auth.accessToken,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
    return result.data.result;
  };

  const YearOptions = () => {
    const data = [];
    const currentYear = new Date().getFullYear();
    for (let i = 2023; i <= currentYear; i++) {
      const option = {
        label: `${i}`,
        value: i,
      };
      data.push(option);
    }
    setyearOptions(data);
  };
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Cancel
            className={classes.closeButton}
            style={{
              backgroundColor: "white",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(false)}
          />
          <Typography
            variant="h4"
            component="h1"
            style={{ marginBottom: "13px" }}
          >
           {performance ? "Ad Performance Filter" : Geographies ? "Geographies Filter" : "Ad Impression Filter"}
          </Typography>

          <Grid container spacing={3}>
            {performance ? (
              <Grid item xs={12}>
                <label className={classes.adsFilterLable}>Select Month</label>
                <Autocomplete
                  getOptionLabel={(option) => option.label}
                  placeholder="Select Month"
                  onChange={(event, newValue) => {
                    handleChange(newValue, "adsMonth");
                  }}
                  filterOptions={(x) => x}
                  value={filterData.adsMonth}
                  options={Month}
                  getOptionDisabled={(option) =>
                    filterData?.adsYear?.value === new Date().getFullYear() && option.value > new Date().getMonth() + 1
                  } 
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Current"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <label className={classes.adsFilterLable}>
                  Select Advertisement
                </label>
                <Autocomplete
                  id="google-map-demo"
                  getOptionSelected={(option, value) =>
                    option.ad_title === value.ad_title
                  }
                  getOptionLabel={(option) => option.ad_title}
                  options={adsOption}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  noOptionsText="No Ads Available"
                  filterSelectedOptions
                  value={filterData.adsId}
                  onChange={(event, newValue) => {
                    setadsOption(newValue ? [newValue] : adsOption);
                    handleChange(newValue, "adsId");
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="All"
                    />
                  )}
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          <span
                            style={{ fontWeight: 700 }}
                            className="threeDots"
                          >
                            {option.ad_title}
                          </span>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <label className={classes.adsFilterLable}>Select Year</label>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                placeholder="Select Year"
                onChange={(event, newValue) => {
                  handleChange(newValue, "adsYear");
                }}
                filterOptions={(x) => x}
                value={filterData.adsYear}
                options={yearOption}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Year"
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              style={{ paddingLeft: "55px" }}
            >
              <Button
                variant="contained"
                color="white"
                className={classes.circleBtn}
                onClick={() => Clear()}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.circleBtn}
                onClick={() => {
                  getList();
                }}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default FilterModal;
