import { combineReducers } from "redux";
import loginDetailsReducer from "./loginDetails";
import globalSearch from "./GlobalSearch";
import ReportDetails from "./reportDetails";
import adDetails from "./adDetails";
import { withReduxStateSync } from "redux-state-sync";
// import SelectedResourceReducer from "./resourceData";

const allReducers = {
  loginDetails: loginDetailsReducer,
  GlobalSearch: globalSearch,
  reportdetails: ReportDetails,
  adDetails: adDetails,
};

export default withReduxStateSync(combineReducers(allReducers));
