import { IconButton, Paper, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Filter from "../../../../assets/images/filter.svg";
import Filtered from "../../../../assets/images/filtered.svg";
import axios from "axios";
import { API_Path } from "../../../Common/Const";
import Loader from "../../../Common/Loader";
import Tooltips from "@material-ui/core/Tooltip"

import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import FilterModal from "./FilterModal";
const useStyles = makeStyles((theme) => ({
  filterIcon: {
    border: "1px solid #fbdd88",
    borderRadius: "0 !important",
    backgroundColor: "white",
    margin: "0px 13px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "7px",
    borderRadius: "14px",
    fontSize: "30px",
    right: "7px",
  },
  adsFilterLable: {
    textAlign: "left",
    float: "left",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    justifyContent: "center",
  },
  name: {
    margin: "0px",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

const ImpressionByCountry = (props) => {
  const [loading, setLoader] = useState(false);
  const [listImperessien, setImperessienList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [year, setYears] = useState("");
  const [filterData, setFilterData] = useState({
    adsId: null,
    adsYear: null,
  });
  const classes = useStyles();
  useEffect(() => {
    getList();
  }, []);
  const getList = (type = false) => {
    let api = API_Path + `api/dashboard/get_AdGeographies`;
    let req = {
      ad_id: type ? null : filterData?.adsId?._id,
      year: type ? null : filterData?.adsYear?.value,
    };
    setLoader(true);
    setOpenModal(false);
    axios
      .post(api, req, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setImperessienList(response.data.result.response);
          setYears(response.data.result.overallYear);
        } else {
          setImperessienList([]);
          setYears("");
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const handleChange = (event, name) => {
    setFilterData({ ...filterData, [name]: event });
  };
  const Clear = () => {
    setOpenModal(false);
    let clear = true;
    setFilterData({
      adsId: null,
      adsYear: null,
    });

    getList(clear);
  };
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    );
  };
  const formatYAxisTick = (value) => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value;
  };

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={5} textAnchor="end" fill="#666">
          {formatYAxisTick(payload.value)}
        </text>
      </g>
    );
  };
  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    const maxLabelLength = 13; 
  
    let displayedText = payload.value;
    let truncatedText = displayedText.length > maxLabelLength ? `${displayedText.substring(0, maxLabelLength)}...` : displayedText;
    let tooltipText = displayedText.length > maxLabelLength ? displayedText : null;
  
    return (
      <Tooltips title={tooltipText || ""}>
        <text
          x={x}
          y={y}
          dy={16}
          textAnchor="middle"
          fill="#666"
        >
          {truncatedText}
        </text>
      </Tooltips>
    );
  };
  
  
  return (
    <>
      {loading && <Loader />}
      <Paper elevation={3} style={{ padding: "18px" }}>
        <div className={classes.header}>
          <div>
            <h4 className={classes.name}>
              Geographics - Impressions by Country
            </h4>
          </div>
          <div>
            <IconButton
              size="medium"
              className={classes.filterIcon}
              onClick={() => setOpenModal(true)}
            >
              {filterData.adsId !== null || filterData.adsYear !== null ? (
                <img alt="" width="18px" src={Filtered}></img>
              ) : (
                <img alt="" width="18px" src={Filter}></img>
              )}
            </IconButton>
          </div>
        </div>
        <div
          style={{ height: "350px", overflowX: "auto", overflowY: "hidden" }}
        >
          <ResponsiveContainer width={1190}  height={340}>
            <BarChart
              barSize={40}
              data={listImperessien}
              margin={{ top: 25, right: 30, left: 10, bottom: 25 }}
            >
              {listImperessien.length === 0 && (
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize="20"
                  fill="#888"
                >
                  No Data Available
                </text>
              )}
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke="#ffc003"
              />
              <XAxis
                 dataKey="country"
                tickFormatter={(value) => value.slice(0,10) }
                axisLine={false}
                 tick={<CustomXAxisTick />}
                label={{
                  value: "Top 10 Countries in " + year,
                  position: "insideBottom",
                  offset: -10,
                }}
                scale="point"
                padding={{ left: 35, right: 10 }}
              />
              <Tooltip formatter={(value, name) => [value, "View Count "]} />
             
              <YAxis
                axisLine={false}
                tickSize={0}
                label={{ value: "Reach", angle: -90, position: "insideLeft" }}
                tickFormatter={(value) => value.toLocaleString()}
                tick={<CustomYAxisTick />}
              />
              <Bar dataKey="viewedCount" fill="#ffc003" >
                <LabelList
                  dataKey="viewedCount"
                  content={renderCustomizedLabel}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Paper>

      <FilterModal
        open={openModal}
        setOpenModal={setOpenModal}
        auth={props.auth}
        getList={getList}
        handleChange={handleChange}
        filterData={filterData}
        setFilterData={setFilterData}
        Clear={Clear}
        Geographies={true}
      />
    </>
  );
};

export default ImpressionByCountry;
