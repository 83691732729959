import {
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Filter from "../../../../assets/images/filter.svg";
import Filtered from "../../../../assets/images/filtered.svg";
import axios from "axios";
import { API_Path } from "../../../Common/Const";
import Loader from "../../../Common/Loader";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import FilterModal from "./FilterModal";
const useStyles = makeStyles((theme) => ({
  filterIcon: {
    border: "1px solid #fbdd88",
    borderRadius: "0 !important",
    backgroundColor: "white",
    margin: "0px 13px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "7px",
    borderRadius: "14px",
    fontSize: "30px",
    right: "7px",
  },
  adsFilterLable: {
    textAlign: "left",
    float: "left",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
    justifyContent: "center",
  },
  tooltipCircle: {
    borderRadius: 30,
    justifyContent: "center",
  },
  name: {
    margin: "0px",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  clicks: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
}));

const Impression = (props) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoader] = useState(false);
  const [listImperessien, setImperessienList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filterData, setFilterData] = useState({
    adsId: null,
    adsYear: null,
  });
  const classes = useStyles();
  useEffect(() => {
    getList();
  }, [checked]);
  const getList = (type = false) => {
    let api = API_Path + `api/dashboard/get_impressionClickGraph`;
    let req = {
      type: checked ? "click" : "impression",
      ad_id: type ? null : filterData?.adsId?._id,
      year: type ? null : filterData?.adsYear?.value,
    };
    setLoader(true);
    setOpenModal(false);
    axios
      .post(api, req, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setImperessienList(response.data.result);
        } else {
          setImperessienList([]);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const handleChange = (event, name) => {
    setFilterData({ ...filterData, [name]: event });
  };
  const Clear = async () => {
    let clear = true;
    setOpenModal(false);
    setFilterData({
      adsId: null,
      adsYear: null,
    });
    getList(clear);
  };

  const CheckBox = () => {
    setChecked(!checked);
    // setFilterData({
    //   adsId: null,
    //   adsYear: null,
    // });
  };
  const formatYAxisTick = (value) => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value;
  };

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={5} textAnchor="end" fill="#666">
          {formatYAxisTick(payload.value)}
        </text>
      </g>
    );
  };
  return (
    <>
      {loading && <Loader />}
      <Paper elevation={3} style={{ padding: "18px" }}>
        <div className={classes.header}>
          <div>
            <h4 className={classes.name}>Ad Impression</h4>
          </div>
          <div>
            <FormControlLabel
              className={classes.clicks}
              control={
                <Switch
                  checked={checked}
                  onClick={() => CheckBox()}
                  name="checked"
                />
              }
              label="View Click Counts"
              labelPlacement="start"
            />
            <IconButton
              size="medium"
              className={classes.filterIcon}
              onClick={() => setOpenModal(true)}
            >
              {filterData.adsId !== null || filterData.adsYear !== null ? (
                <img alt="" width="18px" src={Filtered}></img>
              ) : (
                <img alt="" width="18px" src={Filter}></img>
              )}
            </IconButton>
          </div>
        </div>
        <div style={{ overflowX: "auto", overflowY: "hidden" }}>
          <ResponsiveContainer minWidth={650} height={350}>
            <LineChart
              data={listImperessien.response}
              width="100%"
              margin={{ top: 25, right: 30, left: 10, bottom: 25 }}
            >
              {listImperessien.length === 0 && (
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize="20"
                  fill="#888"
                >
                  No Data Available
                </text>
              )}

              <XAxis
                dataKey="month"
                axisLine={false}
                tickSize={0}
                padding={{ left: 30 }}
                label={{
                  value: listImperessien.overallYear,
                  position: "insideBottom",
                  offset: -10,
                }}
              />
              <YAxis
                axisLine={false}
                tickSize={0}
                label={{
                  value: checked ? "Click" : "Reach",
                  angle: -90,
                  position: "insideLeft",
                }}
                tick={<CustomYAxisTick />}
              />
              <Tooltip
                cursor={{
                  stroke: checked ? "#ffc00369" : "#75edcd8f",
                }}
                contentStyle={{
                  borderColor: checked ? "#ffc003" : "#00DEA3",
                  height: "50px",
                  padding: "0px",
                  margin: "0px",
                }}
                content={({ active, payload }) => {
                  if (!active || !payload || payload.length === 0) return null;
                  const { value } = payload[0];
                  return (
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "5px",
                        border: `1px solid ${checked ? "#ffc003" : "#00DEA3"}`,
                      }}
                      className={classes.tooltipCircle}
                    >
                      <p style={{ margin: 0 }}>{value}</p>
                    </div>
                  );
                }}
              />
              <Line
                dataKey="value"
                stroke={checked ? "#ffc003" : "#00DEA3"}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Paper>
      <FilterModal
        open={openModal}
        setOpenModal={setOpenModal}
        filterData={filterData}
        setFilterData={setFilterData}
        handleChange={handleChange}
        auth={props.auth}
        getList={getList}
        Clear={Clear}
      />
    </>
  );
};

export default Impression;
