import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { API_Path } from '../../Common/Const/index';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SnackBar from "../../../components/SnackBar";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f6f7f8',
    height: 'calc(100vh - 133px)',
    display: 'flex',
  },
  paymentMessage: {
    width: '100%',
    maxWidth: '480px',
    margin: 'auto',
    textAlign: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
    padding: '20px 20px 30px 20px',
    borderRadius: '12px',
    backgroundColor: '#fff',
    "& svg": {
      width: '4em',
      height: '4em',
      fill: 'green'
    },
    "& h2": {
      margin: '0'
    },
    "& p": {
      margin: '0',
      fontSize: '14px'
    }
  }
}))

const PaymentSuccess = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const history= useHistory()
  let params = new URLSearchParams(location.search);
  const sessionId = params.get("session_id");
  const onClose = () => {
    setSnackbarState({ open: false });
  };

  useEffect(() => {

    async function fetchSession() {
    const planId = params.get("planId");
    const packageId = params.get("packageId");
    const debateId = params.get("debateId");
    const vurbId = params.get("vurbId");
    const type = params.get("type");
    const page = params.get("page");
    var userId=props.user._id;
    var updatePaymentTransactionAPI = planId ? API_Path+'api/stripe/updatePaymentTransaction' : API_Path +'api/wallet/create_fund' ;
    // var updatePaymentTransactionAPI=API_Path+'api/auth/updatePaymentTransaction';
   
   if(type === 'vurbil_ads'){
    await axios.post(updatePaymentTransactionAPI, {transaction_id:sessionId}, 
    {
      headers: {
  'Content-Type': 'application/json',
  'Authorization':"Basic " + props.auth.accessToken
  }
  })
  .then((res) =>{
      if(res.data!==null){
          setTimeout(()=>{
              history.push({
                pathname: "/vurbilAds",
                state: page.toString(),
              });
          },3000);
          // setSnackbarState({
          //   open: true,
          //   message: res.data.message,
          //   severity: res.data.status =="SUCCESS" ?"success":"error",
          // });
          
      }


  });
   }else{
    await axios.post(updatePaymentTransactionAPI, {transactionFrom:"Web",transactionId:sessionId,planId:planId,packageId:packageId,userId:userId,debateId:debateId,vurbId:vurbId}, 
      {
        headers: {
    'Content-Type': 'application/json',
    'Authorization':"Basic " + props.auth.accessToken
    }
    })
    .then((res) =>{
        if(res.data!==null){
            setTimeout(()=>{
              if(type === 'vurbil_ads'){
                history.push({
                  pathname: "/vurbilAds",
                  params: page.toString(),
                });
              }else{
                if(vurbId){
                  window.location.href="/vurbs";
                }else{
                  window.location.href="/activeDebatelist";
                }
              }
            
              
            },3000);            
        }

    });

  }
    }
    fetchSession();
  }, [sessionId]);

  return (
    <>
    
    <Box className={classes.root}>
      <Paper className={classes.paymentMessage}>
        <CheckCircleOutlineIcon />
        <h2>Your payment was successful</h2>
        <p>
          Thank you for your payment. <br /> Please wait while redirecting...
      </p>
      </Paper>
    </Box>
    <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
    </>
  );
};

// export default PaymentSuccess;
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user, auth
  };
};

export default connect(mapStateToProps)(PaymentSuccess);
