import React, { useEffect, useState } from 'react'
import { API_Path, Make_Base_Auth } from '../../../../src/screens/Common/Const/index';
import axios from "axios";
import { Box, Typography } from "@material-ui/core";
import '../../../assets/css/quill.css'
// import Logo from "../../../assets/images/VurbilMainLogo.png"
// import aboutImage from "../../../assets/images/aboutUsImg.jpg"
// import deviceImg from "../../../assets/images/deviceLogo.png"
// import subLogo from "../../../assets/images/VurbilSubLogo.jpg"

// const about = `<h3 class="ql-align-center">
// <img style="max-width: 141px;" src=${Logo}>
// </h3>
// <p class="ql-align-center">
// <br>
// </p>
// <p class="ql-align-center">
// <img src=${aboutImage}>
// </p>
// <h3>
// <span class="ql-size-huge">About Us</span>
// </h3>
// <p>
// <br>
// </p>
// <p>
// <br>
// </p>
// <h6 class="ql-align-center">
// <span class="ql-size-huge"> KillerPolitics Available On Apple, Android, &amp; Web</span>
// </h6>
// <p class="ql-align-center">
// <br>
// </p>
// <p class="ql-align-center">
// <img style="max-width: 100px;" src=${subLogo}>
// </p>
// <p class="ql-align-center">
// <br>
// </p>
// <p class="ql-align-center">
// <br>
// </p>
// <p class="ql-align-center">
// <img src=${deviceImg}>
// </p>
// <p>
// <br>
// </p>
// <p>
// <span class="ql-size-large" style="color: rgb(51, 51, 51);">KillerPolitics&nbsp;Inc is the world’s first video debate platform where its members can debate any topic, in any category, within a safe space. Any debater on the&nbsp;KillerPolitics&nbsp;platform has the option to debate either publicly, or privately. If debating on the public platform, the debater(s) will be rated by the public at large on their arguments. The AI judge will “flip a coin” to determine which debater will go first for their opening remarks; each debater will then have 60 seconds to give their opening remarks. After each debater(s) 60 second opening, then each debater will have 25 seconds of closing remarks while the public actively assess and votes on the current debater(s) arguments.</span>
// </p>
// <p>
// <br>
// </p>
// <p>
// <span class="ql-size-large" style="color: rgb(51, 51, 51);">Users of the&nbsp;KillerPolitics&nbsp;platform will have a large selection of debate topics to choose from, ranging from: current events, politics, music, and etc.&nbsp;The rules, as set by&nbsp;KillerPolitics, establish a&nbsp;safe space for every member to express/argue their position(s). This is meant to encourage civil dialogue and a decorum of respect for one another. Passion is encouraged; however, personal attacks &amp; abusive language will not be tolerated on the platform.&nbsp;</span>
// </p>
// <p>
// <br>
// </p>
// <p>
// <span class="ql-size-large" style="color: rgb(51, 51, 51);">KillerPolitics&nbsp;will also provide unique video news blasts that are customizable to the preference of each member of the platform. There will also be a weekly&nbsp;KillerPolitics&nbsp;Show that will cover trending debates, debaters, current events and other topics as they present themselves.&nbsp;</span>
// </p>
// <p>
// <br>
// </p>
// <p>
// <span class="ql-size-large" style="color: rgb(51, 51, 51);">KillerPolitics&nbsp;was founded by Erik M. Underwood, who is also the founder and CEO of My24HourNews.Com, Inc.</span>
// </p>
// <p class="ql-align-center">
// <br>
// </p>
// <h6 class="ql-align-center">
// <br>
// </h6>`;

const AboutUs = () => {
    const [page, setPage] = useState('')
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        getStaticPage()
    }, [])
    const getStaticPage = () => {
        let getStaticPageData = API_Path + "api/auth/getStaticPage"
        let inputObj = {
            "inputObject": {
                "type": "ABOUT_US"
            }
        }
        axios
            .post(getStaticPageData, inputObj, {
                headers: {
                    'Authorization': Make_Base_Auth()
                },
            })
            .then((response) => {
                if (response !== null && response.data !== null && response.data.result !== null) {
                    setPage(response.data.result.html_content);
                    setTimeout(() => {
                        setLoaded(true)
                    }, 500);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    return (
      <>
      {loaded ? 
        <div className="ql-editor" style={{ opacity: (loaded ? '1' : '0') }} dangerouslySetInnerHTML={{ __html: page }}></div>
        :
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    <Typography variant="h5">Loading...</Typography>
                </Box>
            }
         {/* <div className="ql-editor" style={{whiteSpace: "inherit"}} dangerouslySetInnerHTML={{ __html: page }}></div> */}
        </> )
}

export default AboutUs;

