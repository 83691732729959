import React, { useEffect, useState } from 'react'
import { API_Path, Make_Base_Auth } from '../../../../src/screens/Common/Const/index';
import axios from "axios";
import '../../../assets/css/quill.css';

const KpNewsTips = (props) => {
    const [page, setPage] = useState('')
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        getStaticPage()
    }, [])
    const getStaticPage = () => {
        let getStaticPageData = API_Path + "api/auth/getStaticPage"
        let inputObj = {
            "inputObject": {
                "type": "KP_NEWS_TIPS"
            }
        };
        axios
            .post(getStaticPageData, inputObj, {
                headers: {
                    'Authorization': Make_Base_Auth()
                },
            })
            .then((response) => {
                if (response !== null && response.data !== null && response.data.result !== null) {
                    setPage(response.data.result.html_content);
                    setTimeout(() => {
                        setLoaded(true)
                    }, 500);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    return (
        <div className="ql-editor" style={{ opacity: (loaded ? '1' : '0') }} dangerouslySetInnerHTML={{ __html: page }}></div>
    );
}
export default KpNewsTips;