import { makeStyles, Modal, Box, IconButton } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import WorldSearch from "../../../assets/images/world_search.svg";

const useStyles = makeStyles((theme) => ({
  searchWhiteBg: {
    
    "& input": {
      backgroundColor: "#fff",
      padding: theme.spacing(1.5, 0, 1.5, 2.5),
      border: "1px solid #d9d9d9",
      borderRadius: "30px",
      width:"100%",
    },
  },
  mapModal: {
    width: "560px",
    padding: "24px 36px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "160px",
    left: "26%",
  },
  PaperGif: {
    border: "1px solid #d9d9d9",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  header: {
    position: "relative",
    "& h3": {
      marginTop: "0",
      textAlign: "center",
    },
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "32px",
    "& img": {
      width: "40%",
      opacity:'0.5'
    },
    "& p": {
      textAlign: "center",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(-2),
    top: theme.spacing(-1.5),
    width: "30px",
    height: "30px",
    background: "#ffc003",
    color: "#fff",
    "&:hover": {
      background: "#ffc003",
    },
    "& svg": {
      width: 20,
      height: 20,
    },
    // color: theme.palette.grey[500],
  },
}));
const key = process.env.REACT_APP_GOOGLE_MAP_KEY;
// const key = "AIzaSyCsXeKdTbHEM-GC36fI5dqrBqVBAhiKpc4";
let autoComplete;
function handleScriptLoad(updateQuery, autoCompleteRef) {
  // console.log("autoCompleteRef", autoCompleteRef);
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
  );
  // console.log("autoComplete", autoComplete);
  autoComplete.setFields([
    "address_components",
    "formatted_address",
    "geometry",
    "icon",
    "name",
    "types"
  ]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery({
    name: addressObject.name,
    address: query,
    lat: addressObject.geometry.location.lat(),
    lng: addressObject.geometry.location.lng(),
  });
  // console.log(query);
}
// import React, { useState, useEffect, useRef } from "react";

// let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};
function SearchLocationInput(props) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const classes = useStyles();
  const UpdateLocation = (data) => {
    setQuery("");
    // setlatlong(data);
    props.result(data)
    props.close(false)
  };
  useEffect(() => {
    if (props.isOpen) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`,
        () => handleScriptLoad(UpdateLocation, autoCompleteRef)
      );
    }
  }, [props.isOpen]);
  return (
    <Modal open={props.isOpen}>
      <Box className={classes.mapModal}>
        <Box className={classes.header}>
          <h3>Check-in</h3>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {props.close(false); setQuery("")}}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <div className={classes.searchWhiteBg +" search-location-input"}>
          <input
            ref={autoCompleteRef}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Search for location"
            value={query}
          />
        </div>
        {/* {latlong && Object.keys(latlong).length > 0 && (
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${latlong.lat},${latlong.lng}&zoom=14&size=400x400&key=AIzaSyCsXeKdTbHEM-GC36fI5dqrBqVBAhiKpc4`}
            alt="staticmap"
          />
        )} */}
        {/* </Paper> */}
        <Box style={{ height: "300px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className={classes.modalBody}
          >
            <img src={WorldSearch} alt="" />
            <p>Let your Vurbers know where you are!</p>
          </div>
        </Box>
      </Box>
    </Modal>
  );
}

export default SearchLocationInput;
