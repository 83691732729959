import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import robotImage from '../../../assets/images/AIJudge.png';
import { redirectTo } from "../../Common/Const";
import TossBanner from './TossBanner';

const ScheduleDebateBanner = (props) => {
    const classes = props.classes;
    const [debateObject, setDebateObject] = useState(null);
    const [message, setMessage] = useState("");
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [redirectToLiveDebate, setRedirectToLiveDebate] = useState(false);
    const [showTossBanner, setShowTossBanner] = useState(false);

    useEffect(()=>{
        setDebateObject(props.debateObject);
        if(props.debateObject!==null&&props.debateObject.status ===1
            &&props.debateObject.toss_selected !==null && props.debateObject.toss_won!==null){
            // setRedirectToLiveDebate(true);
        }else if(props.debateObject!==null&&props.debateObject.status ===1 
            && props.debateObject.toss_selected===null){
            setShowTossBanner(true);
        }
    },[props.debateObject]);

    useEffect(()=>{
        setMessage(props.message);
    },[props.message]);

    // useEffect(()=>{
    //     setMessage(props.message);
    // },[props.message]);

    useEffect(() => {
        if (props.debateTimerObj !== null) {
            if(props.debateTimerObj !== null && props.debateTimerObj.status === 'debateStart'){
                //redirect to live debate
                setRedirectToLiveDebate(true);
            }else if(props.debateTimerObj !== null && props.debateTimerObj.status === 'start' && props.debateObject.toss_selected===null){
                //have to enable toss button to choose the head or tail
                setShowTossBanner(true);
            }
        }
        if (props.debateTimerObj !== null) {
            setDays(props.debateTimerObj.days ? props.debateTimerObj.days : 0);
            setHours(props.debateTimerObj.hours ? props.debateTimerObj.hours : 0);
            setMinutes(props.debateTimerObj.minutes ? props.debateTimerObj.minutes : 0);
            setSeconds(props.debateTimerObj.seconds ? props.debateTimerObj.seconds : 0);
        }
    }, [props.debateTimerObj]);

    useEffect(() => {
        if (props.extendedTimerObj !== null ) {
            if(props.extendedTimerObj !== null && props.extendedTimerObj.status === 'debateStart' && props.debateObject.toss_selected !==null && props.debateObject.toss_won!==null){
                //redirect to live debate
                setRedirectToLiveDebate(true);
            }else if(props.extendedTimerObj !== null && props.extendedTimerObj.status === 'start' && props.debateObject.toss_selected===null){
                //have to enable toss button to choose the head or tail
                setShowTossBanner(true);
            }
        }
        if (props.extendedTimerObj !== null) {
            setDays(props.extendedTimerObj.days ? props.extendedTimerObj.days : 0);
            setHours(props.extendedTimerObj.hours ? props.extendedTimerObj.hours : 0);
            setMinutes(props.extendedTimerObj.minutes ? props.extendedTimerObj.minutes : 0);
            setSeconds(props.extendedTimerObj.seconds ? props.extendedTimerObj.seconds : 0);
        }
    }, [props.extendedTimerObj]);

    const leadingZero = (num) => {
        return (num < 10 && num > 0) ? "0" + num : num;
    }
    return(
    <React.Fragment>
        {redirectToLiveDebate ?
        redirectTo('/liveDebate',{
            debateObj: debateObject,
            roomName: debateObject._id
        })
        :""}
        {showTossBanner===true?
            <TossBanner debateObject={debateObject} classes={classes}/>
        :
    <div className={classes.startIn}>

        <Grid container direction="column" alignItems="center">
            <h4 className="m-0 font-weight-normal" style={{ marginBottom: '20px' }}>
                {message}
            </h4>
            <Box mb={1.5} width="100%">
                <Grid container justify="center" spacing={5}>
                    <Grid item>
                        <h1 className="m-0" style={{ textAlign: 'center' }}>{leadingZero(days)} </h1>
                        <h4 className="m-0 font-weight-normal">{days === 1 ? 'Day' : 'Days'}</h4>
                    </Grid>
                    <Grid item>
                        <h1 className="m-0" style={{ textAlign: 'center' }}>{leadingZero(hours)} </h1>
                        <h4 className="m-0 font-weight-normal">{hours === 1 ? 'Hour' : 'Hours'}</h4>
                    </Grid>
                    <Grid item>
                        <h1 className="m-0" style={{ textAlign: 'center' }}>{leadingZero(minutes)} </h1>
                        <h4 className="m-0 font-weight-normal">{minutes === 1 ? 'Minute' : 'Minutes'}</h4>
                    </Grid>
                    <Grid item>
                        <h1 className="m-0" style={{ textAlign: 'center' }}>{leadingZero(seconds)} </h1>
                        <h4 className="m-0 font-weight-normal">{seconds === 1 ? 'Second' : 'Seconds'}</h4>
                    </Grid>
                </Grid>
            </Box>

            {/* <Button variant="contained" color="primary" style={{ marginTop: '34px' }}
                onClick={toLiveDebate}
            // className={(days === 0 && hours === 0 &&  minutes=== 0 && seconds === 0) ? classes.circleBtn : 'disabled ' + classes.circleBtn}
            >
                Join Debate
            </Button> */}

        </Grid>
        <Box className={classes.robotInfo}>
            <div className={classes.robotText + " robotTextVideo"}>
                <div style={{ width: '45px', height: '45px', margin: '0 10px 0 0' }}>
                    <img alt="" src={robotImage}  style={{objectFit: 'contain', width: '100%', height: '100%'}}  />
                </div>
                <div>
                    <h6 className="font-weight-normal" style={{ margin: '0' }}>Judge</h6>
                    <h5 style={{ margin: '0' }}>AI ROBOT</h5>
                </div>
            </div>
            <p style={{ fontSize: '11px', color: 'rgba(255,255,255, .6)', margin: '0', }}>
                Note: Please prepare camera. headset or microphone.
            </p>
        </Box>
    </div>
    }
    </React.Fragment>
    );

}

// export default ScheduleDebateBanner;
const mapStateToProps = ({ loginDetails: { user, auth, currentPackage } }) => {
    return {
        user, auth, currentPackage
    };
};
export default withRouter(connect(mapStateToProps)(ScheduleDebateBanner));
