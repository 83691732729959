/* Let CRA handle linting for sample app */
import axios from "axios";
// import Spinner from 'react-spinner';
import classNames from "classnames";
import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { API_Path } from "../Const/index";
import Loader from "../Loader";
import "./App.scss";

let otCore;
// var opentok = new OpenTok("47052404", "09b6be77c47e4f16de402cef1400a1e05137a206");
const otCoreOptions = {
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: "#cameraPublisherContainer",
        screen: "#screenPublisherContainer",
      },
      subscriber: {
        camera: "#cameraSubscriberContainer",
        screen: "#screenSubscriberContainer",
      },
    }[pubSub][type];
  },
  controlsContainer: "#controls",
  packages: ["textChat", "screenSharing", "annotation"],
  communication: {
    callProperties: null, // Using default
  },
  textChat: {
    name: ["David", "Paul", "Emma", "George", "Amanda"][
      (Math.random() * 5) | 0
    ], // eslint-disable-line no-bitwise
    waitingMessage: "Messages will be delivered when other users arrive",
    container: "#chat",
  },
  screenSharing: {
    extensionID: "plocfffmbcclpdifaikiikgplfnepkpo",
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: "append",
      width: "100%",
      height: "100%",
      showControls: false,
      style: {
        buttonDisplayMode: "off",
      },
      videoSource: "screen",
      fitMode: "contain", // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: ".App-video-container",
      subscriber: ".App-video-container",
    },
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames("App-control-container", { hidden: !active }),
    localAudioClass: classNames("ots-video-control circle audio", {
      hidden: !active,
      muted: !localAudioEnabled,
    }),
    localVideoClass: classNames("ots-video-control circle video", {
      hidden: !active,
      muted: !localVideoEnabled,
    }),
    localCallClass: classNames("ots-video-control circle end-call", {
      hidden: !active,
    }),
    cameraPublisherClass: classNames("video-container", {
      hidden: !active,
      small: !!activeCameraSubscribers || screenshareActive,
      left: screenshareActive,
    }),
    screenPublisherClass: classNames("video-container", {
      hidden: !active || !sharingScreen,
    }),
    cameraSubscriberClass: classNames(
      "video-container",
      { hidden: !active || !activeCameraSubscribers },
      { "active-gt2": activeCameraSubscribersGt2 && !screenshareActive },
      { "active-odd": activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames("video-container", {
      hidden: !viewingSharedScreen || !active,
    }),
  };
};

const connectingMask = () => (
  <div className="App-mask">
    {/* <Spinner /> */}
    <div className="message with-spinner">Connecting</div>
  </div>
);

class OpenTokLiveStreamVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      broadCastId: null,
      loader: false,
      videoEnd: false,
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.startBroadCast = this.startBroadCast.bind(this);
    this.stopBroadCast = this.stopBroadCast.bind(this);
  }

  componentDidMount() {
    this.initSession(
      this.props.vurbObj.live.ot_sessionId,
      this.props.vurbObj.live.ot_sessionToken
    );
  }

  componentWillUnmount() {
    if (this.state.connected) {
      // alert("stop api call");
      this.endCall();
      // otCore.endCall();
      // otCore.disconnect();
      // this.setState({ active: false });
    }
  }

  // componentDidUpdate(prevProps) {
  //   var nextProps = this.props;
  //   if (nextProps.micmuteunmuteobj !== prevProps.micmuteunmuteobj) {
  //     if (nextProps.isProposition === true) {
  //       if (nextProps.micmuteunmuteobj !== null) {
  //         if (nextProps.micmuteunmuteobj.type === "fix") {
  //           if (nextProps.micmuteunmuteobj.proposition === "unmute") {
  //             otCore.toggleLocalAudio(true);
  //             this.setState({ localAudioEnabled: true });
  //           } else {
  //             otCore.toggleLocalAudio(false);
  //             this.setState({ localAudioEnabled: false });
  //           }
  //         }
  //       }
  //     }
  //     if (nextProps.isOpposition === true) {
  //       if (nextProps.micmuteunmuteobj !== null) {
  //         if (nextProps.micmuteunmuteobj.type === "fix") {
  //           if (nextProps.micmuteunmuteobj.opposition === "unmute") {
  //             otCore.toggleLocalAudio(true);
  //             this.setState({ localAudioEnabled: true });
  //           } else {
  //             otCore.toggleLocalAudio(false);
  //             this.setState({ localAudioEnabled: false });
  //           }
  //         }
  //       }
  //     }
  //     if (
  //       nextProps.micmuteunmuteobj.type === "endcallalert" &&
  //       (nextProps.micmuteunmuteobj.alertSeconds === 0 ||
  //         nextProps.micmuteunmuteobj.alertSeconds === "0")
  //     ) {
  //       this.endCall();
  //     }
  //     if (nextProps.micmuteunmuteobj.type === "endcall") {
  //       this.endCall();
  //     }
  //   }
  // }

  initSession = (sessionId, token) => {
    if (sessionId !== undefined && sessionId !== "") {
      otCoreOptions.credentials = {
        apiKey: process.env.REACT_APP_API_KEY,
        sessionId: sessionId,
        token: token,
      };

      otCore = new AccCore(otCoreOptions);

      otCore.connect().then(() => {
        this.setState({ connected: true });
        this.startCall();
      });
      const events = [
        "subscribeToCamera",
        "unsubscribeFromCamera",
        "subscribeToScreen",
        "unsubscribeFromScreen",
        "startScreenShare",
        "endScreenShare",
      ];

      events.forEach((event) =>
        otCore.on(event, ({ publishers, subscribers, meta }) => {
          this.setState({ publishers, subscribers, meta });
          this.startCall();
        })
      );
    }
  };

  startBroadCast() {
    var startBroadCastAPI = API_Path + "api/vurb/start_broad_cast";
    var inputObj = { vurbId: this.props.vurbObj._id };
    axios
      .post(startBroadCastAPI, inputObj, {
        headers: {
          Authorization: "Basic " + this.props.auth.accessToken,
        },
      })
      .then((response) => {
        // if(response && response.data && response.data.result && response.data.result.status === 2) {
        //   window.location.href = '/recorded';
        // }
      });
  }

  stopBroadCast() {
    var stopBroadCastAPI = API_Path + "api/vurb/stop_broad_cast";
    var inputObj = { vurbId: this.props.vurbObj._id };
    axios
      .post(stopBroadCastAPI, inputObj, {
        headers: {
          Authorization: "Basic " + this.props.auth.accessToken,
        },
      })
      .then((response) => {
        this.setState({ loader: false });
        window.location.href = "/vurbs";
      });
  }

  startCall() {
    var self = this;
    otCore
      .startCall()
      .then(({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta, active: true }, () => {
          self.startBroadCast();
        });
      })
      .catch((error) => console.error(error));
  }

  endCall() {
    var self = this;
    otCore.endCall();
    otCore.disconnect();
    self.stopBroadCast();
    this.setState({ active: false, loader: true }, () => {
      // self.stopBroadCast();
      
    });
  }
  endCallwithRedirect = () => {
    this.endCall();
    window.location.href = "/vurbs";
    // window.location.href='/activeDebateList';
    // this.stopBroadCast("activeDebate");
  };

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }

  render() {
    const { connected, active, loader, videoEnd } = this.state;
    const {
      localAudioClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);
    return (
      <div className="App">
        {videoEnd ? <Redirect to="/activeDebateList" /> : ""}
        <div className="App-main">
          <div className="App-video-container">
            {/* { !connected && connectingMask() } */}
            {/* { connected && !active && startCallMask(this.startCall)} */}
            {!connected && !active && connectingMask(this.state.resolution)}
            <div 
              id="cameraPublisherContainer"
              className={cameraPublisherClass}
            />
            <div
              id="screenPublisherContainer"
              className={screenPublisherClass}
            />
            <div
              id="cameraSubscriberContainer"
              className={cameraSubscriberClass}
            />
            <div
              id="screenSubscriberContainer"
              className={screenSubscriberClass}
            />
          </div>
          <div id="controls" className={controlClass}>
            <div
              className={localAudioClass}
              onClick={this.toggleLocalAudio}
            />
            <div
              className={localCallClass}
              onClick={this.endCall}
            />
            {/* <div className={localVideoClass} onClick={this.toggleLocalVideo} /> */}
          </div>
        </div>
        {loader && <Loader />}
      </div>
    );
  }
}

// export default OpenTokLiveStreamVideo;
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default connect(mapStateToProps)(OpenTokLiveStreamVideo);
