import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";
// import { makeStyles, createTheme , ThemeProvider } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({

// }));
const FormikInput = ({
  type,
  className,
  error,
  placeholder,
  label,
  variant,
  ...props
}) => {
  // const classes = useStyles();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <React.Fragment>
      <TextField
        id={props.name}
        type={type}
        name={props.name}
        label={label}
        fullWidth
        className={className}
        onChange={props.handleChange}
        value={field.value}
        error={errorText.length > 0}
        helperText={errorText}
        variant={variant}
        disabled={props.disabled}
        {...props}
        placeholder={placeholder}
      />
    </React.Fragment>
  );
};

export default FormikInput;
