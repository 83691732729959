import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        backgroundColor: '#f6f7f8',
        padding: '30px 40px',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            height: '100%',
        },
    },
}));

const My24Videos = () => {
    const classes = useStyles();
    return (
        <div className={classes.pageContainer}>
            My24Videos
        </div>
    )
}

export default My24Videos
