import { Box, Card, Grid, Paper, makeStyles } from "@material-ui/core";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import * as React from "react";
import Loader from "../../Common/Loader";
import { LineChart } from "recharts";
import Impression from "./Graph/Impression";
import AdsPerformance from "./Graph/Performance";
import ImpressionByCountry from "./Graph/ImperessienByCountry";
const useStyles = makeStyles((theme) => ({
  dashBoard: {
    // backgroundColor: "white",
    padding: "35px",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
    "& .MuiGrid-spacing-xs-5 > .MuiGrid-item": {
      paddingBottom: "0px",
    },
    "& .MuiGrid-spacing-xs-5":{
      width:"calc(100% + 35px) !Important"
      }
  },
  amount: {
    textAlign: "end",
    color: "#ffc003",
    fontSize: "50px",
    margin: "0px",
  },
  paper: {
    padding: "11px 22px 0px 14px",
    fontSize: "14px",
    color: "black",
  },
}));
export default function AdsDashboard(props) {
  const classes = useStyles();
  const [loading, setLoader] = React.useState(false);
  const [countList, setCountList] = React.useState({});
  React.useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    let api = API_Path + `api/dashboard/ads_count`;

    setLoader(true);
    axios
      .post(
        api,
        {},
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setCountList(response.data.result[0]);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  return (
    <>
      {loading && <Loader />}
      <div className={classes.dashBoard}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={3}>
            <Paper elevation={3} className={classes.paper}>
              <strong>Total Ads</strong>
              <h1 className={classes.amount}>{countList.total_counts || 0}</h1>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper elevation={3} className={classes.paper}>
              <strong>Active Ads</strong>
              <h1 className={classes.amount}>{countList.active_counts || 0}</h1>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper elevation={3} className={classes.paper}>
              <strong>Pending Ads</strong>
              <h1 className={classes.amount}>{countList.pending_count || 0}</h1>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper elevation={3} className={classes.paper}>
              <strong>Expired Ads</strong>
              <h1 className={classes.amount}>{countList.expired_count || 0}</h1>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7}>
            <Impression auth={props.auth} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <AdsPerformance auth={props.auth} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ImpressionByCountry auth={props.auth} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
