import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
const useUserTrackingSocket = (props) => {
  const roomId = props.user?props.user._id:null;
  const [updatedNotificationQueueList, setUpdatedNotificationQueueList] = useState(null);
  const [resetUserChatNotificationCount, setResetUserChatNotificationCount] = useState(null);
  // const [resetUserOnlineFriendList, setResetUserOnlineFriendList] = useState(null);
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { 
               roomId:props.user?props.user._id:null,
               type:"User Tracking",
               debateId:null,
              //  debateObj:null,
               loggedInUserId:props.user?props.user._id:null
             },
    });

    socketRef.current.on('resetNotificationQueueListNew', function (data) {
      setUpdatedNotificationQueueList(data);
    });
    //Old
    // socketRef.current.on('resetNotificationQueueList', function (data) {
    //   setUpdatedNotificationQueueList(data);
    // });
  
    socketRef.current.on('resetUserChatNotificationCount', function (data) {
      setResetUserChatNotificationCount(data);
    });

    // socketRef.current.on('resetUserOnlineFriendList', function (data) {
    //   setResetUserOnlineFriendList(data);
    // });

    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);
  
           
  return {updatedNotificationQueueList,resetUserChatNotificationCount};
};

export default useUserTrackingSocket;
