import React, { Fragment, useEffect, useState } from 'react'
import { Box, Button, MenuItem, Tooltip, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ConfirmationModal from '../ConfirmationModal';
import { API_Path } from '../../Common/Const/index';
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import TextField from '@material-ui/core/TextField';
import SnackBar from '../../../components/SnackBar';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    squareIcon: {
        cursor: 'pointer',
        width: '30px',
        height: '30px',
        border: '1.5px solid #98a1af',
        margin: '3px',
        borderRadius: '5px',
        padding: '2px',
        fill: '#788496',
    }
}))
const Report = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [textAreaEnabled, setTextAreaEnabled] = useState(false);
    const [value, setValue] = useState('');
    const [snackbarState, setSnackbarState] = useState({ open: false });
    const [selectedValue, setSelectedValue] = useState('');
    const RedexReportList=useSelector(state=>(state.reportdetails&&state.reportdetails.reportList)?state.reportdetails.reportList:[]);
    const RedexAdReportList=useSelector(state=>state.reportdetails&&state.reportdetails.adReportList?state.reportdetails.adReportList:[]);
    const DebateReportList=useSelector(state=>state.reportdetails&&state.reportdetails.DebateReportList?state.reportdetails.DebateReportList:[]);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleReport = () => {
        setOpen(true);
        if (props.handleReportOption) {
            props.handleReportOption()
        }
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleMessageClose = () => {
        setOpenMessage(false);
    };
    const onClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarState({ open: false });
    };
    useEffect(() => {
        if (props.type === 'Reporting Vurb') {
            if (RedexReportList.length === 0) {
                getReportList();
            } else {
                setReportList(RedexReportList)
            }
        }
        if (props.type === 'Reporting Advertisement') {
            if (RedexAdReportList.length === 0) {
                getReportList();
            } else {
                setReportList(RedexAdReportList)
            }
        }
        if (props.type === 'Reporting Debate') {
            if (DebateReportList.length === 0) {
                getReportList();
            } else {
                setReportList(DebateReportList)
            }
        }
        if (props.type !== 'Reporting Vurb' && props.type !== 'Reporting Advertisement'&&props.type!=='Reporting Debate') {
            getReportList();
        }

    }, [RedexReportList, RedexAdReportList,DebateReportList, props.type])
    const getReportList = async () => {
        var ReportList = API_Path + "api/reportContentOptions/getByType";
        let inputObj = {
            "inputObject": {
                type: props.type
            }
        }
        axios
            .post(ReportList, inputObj, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                if (props.type === 'Reporting Vurb') {
                    dispatch({
                        type: "SET_REPORT_USER",
                        data: { reportList: response.data.result.name },
                    });
                }
                if(props.type === 'Reporting Advertisement'){
                    dispatch({
                        type: "SET_AD_REPORT_LIST",
                        data: { adReportList: response.data.result.name },
                    });
                }
                if(props.type === 'Reporting Debate'){
                    dispatch({
                        type: "SET_DEBATE_REPORT_LIST",
                        data: { DebateReportList: response.data.result.name },
                    });
                }
                setReportList(response.data.result.name)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const ContentReporting = async (val, e) => {
        setSelectedValue(val)
        if (val === 'Something else') {
            setTextAreaEnabled(true)
            return;
        } else {
            setOpenMessage(true)
        }
    }
    const ContentReportingSubmit = async () => {
        var ContentReportingAPI = API_Path + "api/contentReporting/create";
        let inputObj = {
            "inputObject": {
                type: props.type,
                reportingMsg: selectedValue,
                primary_key: props.primaryKey,
                reportingComments: value,
                created_by: props.user._id
            }
        }
        axios
            .post(ContentReportingAPI, inputObj, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => { 
                if (props.handleSnackbarReport) {
                    props.handleSnackbarReport()
                }
                setSnackbarState({
                    open: true,
                    message:"Reported Success",
                    severity: "success",
                });
                setValue('');
                setTextAreaEnabled(false)
                setOpenMessage(false);
                setOpen(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const backToList = () => {
        setTextAreaEnabled(false)
    }

    const ReportSection = (
        <>
             <h5 style={{ margin: '0' }}>{`Why are you reporting this ${props.type ==='Reporting Account'?'account':props.type === 'Reporting Comment'?'comment':props.type === 'Reporting Vurb'?'vurb':props.type === 'Reporting Advertisement'?'advertisement':'debate'}?`}</h5> 
            <p>
                Your report is anonymous, except if you're reporting an intellectual property infringement. If someone is in immediate danger, call the local emergency service - don't wait.
            </p>
            {textAreaEnabled ?
                <>
                    <TextField
                        // autoFocus
                        id="standard-multiline-flexible"
                        label="Something else"
                        multiline
                        rows={4}
                        maxRows={4}
                        value={value}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                        <Button size="small" variant="contained" color="secondary" onClick={backToList}>Back</Button>
                        <Button size="small" variant="contained" color="primary" onClick=
                            {() => ContentReportingSubmit('Something else')}>Submit</Button>
                    </Box>
                </>
                :
                <List component="nav" aria-label="main mailbox folders">
                    {reportList.map((list, i) => {
                        return (
                            <Fragment key={i} >
                                <ListItem button onClick=
                                    {ContentReporting.bind(this, list)}>
                                    <ListItemText primary={list} />
                                </ListItem>
                                <Divider />
                            </Fragment>
                        )
                    })}

                </List>
            }

        </>
    )

    let title = '';
    if (props.type === 'Reporting Debate') {
        title = 'Report Debate'
    } else if (props.type === 'Reporting Advertisement') {
        title = 'Report Advertisement'
    } else if (props.type === 'Reporting Comment') {
        title = 'Report Comment'
    } else if (props.type === 'Reporting Account') {
        title = 'Report Account'
    } else if (props.type === 'Reporting Kpshow') {
        title = 'Report KP Show'
    } else if (props.type === 'Reporting Kpnews') {
        title = 'Report KP Newscast'
    }else if (props.type === 'Reporting Vurb') {
        title = 'Report Vurb'
    }
    return (
        <>
            {props.showType === 'Menu' && <MenuItem onClick={handleReport}>{!!props.icon&&<ReportProblemOutlinedIcon style={{color:'#758194',fontSize:'20px',marginRight:'5px'}} />}{title}</MenuItem>}
            {props.showType === 'Icon' && <Tooltip title={title}>
                <ReportProblemOutlinedIcon onClick={handleReport} style={{ fill: `${props.type === 'Reporting Advertisement' && "rgba(151, 160, 174, 1)"}`, cursor: 'pointer' }} />
            </Tooltip>
            }
            {props.showType === "Icon Square" && <Tooltip title={title} placement="top">
                <ReportProblemOutlinedIcon onClick={handleReport} className={classes.squareIcon} />
            </Tooltip>
            }
            {props.showType === 'Button' &&
                <Button variant="outlined" onClick={handleReport} startIcon={<ReportProblemOutlinedIcon />}>
                    {title}
                </Button>
            }
            
            <ConfirmationModal openDialog={open} modalHeading={title} handleDialogClose={handleClose} handleDelete={handleClose} modalMessage={ReportSection} dialogButtons={false} maxWidth="xs" />
            <ConfirmationModal openDialog={openMessage} modalHeading={'Message'} handleDialogClose={handleMessageClose} handleDelete={ContentReportingSubmit} modalMessage={`Are you sure want to ${title}`} btnCancelName="Cancel" btnNoConfirmName="Submit" maxWidth="xs" />
            <SnackBar open={snackbarState.open} message={snackbarState.message} severity={snackbarState.severity} onClose={onClose} />
        </>
    )
}

// export default Report;
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(Report));